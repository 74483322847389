<div *ngIf="configurationSettings && tabs.items.length > 0">
  <dx-popup [(visible)]="showWizard" [title]="configurationSettings.title" [height]="maxHeight" (onHidden)="onHidden()">
    <div *dxTemplate="let data of 'content'">
      <dx-box direction="col" width="100%" [height]="maxHeight * 0.8">
        <dxi-item [ratio]="0.5" [height]="75">
          <dx-tabs [dataSource]="tabs.items" valueExpr="tabKeyExpr" [showNavButtons]="false" [visible]="false" [selectedIndex]="currentTabIndex"></dx-tabs>
          <h3 class="stage-title">{{ currentStage.name }}</h3>
        </dxi-item>
        <dxi-item [ratio]="2">
          <div class="stage">
            <app-configuration-wizard-stage [stage]="currentStage" (actionsValidated)="actionsValidated($event)"></app-configuration-wizard-stage>
          </div>
        </dxi-item>
        <dxi-item class="footer" [ratio]="0" [height]="75">
          <dx-box direction="row" width="100%" align="space-between" crossAlign="center">
            <dxi-item [baseSize]="180" [ratio]="0">
              <dx-button icon="fas fa-arrow-left" [visible]="currentTabIndex > 0" [text]="language.wizardPreviousStage" (onClick)="goToPreviousStage()"></dx-button>
            </dxi-item>
            <!--
            <dxi-item [baseSize]="600" [ratio]="0">
              <dx-progress-bar
                #progressBar
                id="progress-bar-status"
                width="100%"
                [class.complete]="progressBar.value == maxValue"
                [min]="0"
                [max]="maxValue"
                [statusFormat]="format"
                [value]="currentTabIndex"
              ></dx-progress-bar
            ></dxi-item>
            -->
            <dxi-item [baseSize]="180" [ratio]="0">
              <dx-button
                class="nextButton"
                icon="fas fa-arrow-right"
                [visible]="!isFinalStage"
                [disabled]="!nextStageAllowed"
                [text]="language.wizardNextStage"
                (onClick)="goToNextStage()"
              ></dx-button>
              <dx-button icon="fas fa-check" [visible]="isFinalStage" [disabled]="!nextStageAllowed" [text]="language.wizardFinalize" (onClick)="saveResults()"></dx-button>
            </dxi-item>
          </dx-box>
        </dxi-item>
      </dx-box>
    </div>
  </dx-popup>
</div>
