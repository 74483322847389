import { Directive, Input, OnChanges } from "@angular/core";
import { DataFilter, DataFilterList } from "../../services/filter";

import DevExpress from "devextreme/bundles/dx.all";
import { Palettes } from "../../../../../angular-common/components/palettes";
import { DashletWithData } from "../../services/dashletdata";
import { DashletView } from "../../services/dashletview";
import { DashletDataInspection } from "../../services/dashletview/models/dashlet-data-inspection";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { DataSerieItem } from "./dataserie-item";
import { DataSerieItemContent } from "./dataserie-item-content";
import { IPoint } from "./i-point";
import { MenuDataItem } from "./menu-dataitem";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class DashletGraphComponent implements OnChanges {
  @Input() dashletView: DashletView;
  @Input() dashletWithData: DashletWithData;
  @Input() filterList: DataFilterList;

  public seriesData: DataSerieItem[];
  public dataInspection: DashletDataInspection;
  public menuItems: MenuDataItem[];
  public customMenuItems: MenuDataItem[];
  public filterByMenuItem: MenuDataItem;

  public constructor(public palettes: Palettes, public language: ImagineLanguage) {
    this.dataInspection = new DashletDataInspection(undefined);
    this.seriesData = [];
    this.menuItems = [];
  }
  ngOnChanges() {
    this.loadDataFromView();
  }

  public reload() {
    if (this.dashletView) {
      this.dashletView.reloadViewData();
    }
    return false;
  }

  public get noData(): boolean {
    if (this.dashletView.data.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  get divId(): string {
    return "dashlet" + this.dashletView.dashletViewId;
  }

  public getSelectedPoints(series: any) {
    let selectedPoints = [];

    for (const serie of series) {
      const filter = serie.getAllPoints().filter((x) => x.isSelected());
      if (filter.length > 0) {
        selectedPoints = selectedPoints.concat(filter);
      }
    }

    return selectedPoints;
  }

  public adjustFiltersByPoints(activePoints: DevExpress.viz.basePointObject[]) {
    const filter = this.filterByCluster();
    if (filter === undefined || filter.options === undefined || filter.options.options.length === 0) {
      console.log("Could not apply filter because filter was not found");
      return;
    }

    this.clearFilterOptions();

    for (const point of activePoints) {
      const filterOption = filter.options.options.find((x) => x.text === point.originalArgument);
      if (filterOption) {
        filterOption.filterBy = true;
      }
    }
  }

  public clearFilterOptions() {
    const filter = this.filterByCluster();

    if (filter === undefined || filter.options === undefined || filter.options.options.length === 0) {
      return;
    }

    filter.options.options.forEach((x) => (x.filterBy = false));
  }

  private filterByCluster(): DataFilter {
    const activeCluster = this.dashletWithData.clusteredBy.find((x) => x.isDataSourceCluster === false);
    if (activeCluster === undefined) {
      return;
    }
    return this.filterList.filters.find((x) => x.shortName.toLowerCase() === activeCluster.shortName.toLowerCase());
  }

  public loadDataFromView() {
    const self = this;

    this.filterByMenuItem = new MenuDataItem(this.language.dashletBarContextMenuFilterBy, self, this.divId);
    this.filterByMenuItem.disabled = true;

    const removeFilter = new MenuDataItem(this.language.dashletBarContextMenuRemoveFilter, self, this.divId);
    if (this.filterList.filters.filter((x) => x.hasSelectedOptions).length === 0) {
      removeFilter.disabled = true;
    }

    this.menuItems = [this.filterByMenuItem, removeFilter];

    if (this.customMenuItems && this.customMenuItems !== null) {
      this.customMenuItems.forEach((cm) => {
        this.menuItems.push(cm);
      });
    }

    this.dataInspection = new DashletDataInspection(this.dashletView.dashletData);

    const reshaped = this.dataInspection.retrieveDataWithPerVariantData();
    const seriesResult: DataSerieItem[] = [];
    reshaped.forEach((r) => {
      const barItem = new DataSerieItem();
      barItem.context = new DataSerieItemContent();
      barItem.context.dataPoint1 = r.point1;
      if (r.point2) {
        barItem.context.dataPoint2 = r.point2;
      }
      seriesResult.push(barItem);
    });

    this.seriesData = seriesResult;
  }

  toggleClick(point: IPoint, series: any) {
    const sameGroupedPoints = [];

    if (series) {
      series.forEach((x) => {
        const pointOnIndex = x.getAllPoints()[point.index];
        if (pointOnIndex) {
          sameGroupedPoints.push(pointOnIndex);
        }
      });
    }

    if (point.isSelected()) {
      if (sameGroupedPoints.length > 0) {
        sameGroupedPoints.forEach((x) => x.clearSelection());
      } else {
        point.clearSelection();
      }
    } else {
      if (sameGroupedPoints.length > 0) {
        sameGroupedPoints.forEach((x) => x.select());
      } else {
        point.select();
      }
    }
  }
}
