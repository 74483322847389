<div *ngIf="dashboard">
    <dx-button icon="edit" class="button-icon-m" [hint]="language.dashboarddesignEditDashboardHeader"
        (onClick)="changeDashboard()"></dx-button>
</div>

<dx-popup [showTitle]="true" [title]="language.dashboarddesignEditDashboardHeader" [(visible)]="popupVisible"
    [width]="550" [height]="'auto'">
    <div *dxTemplate="let data of 'content'">
        <dx-form id="form">

            <!-- Name -->
            <dxi-item [dataField]="newName">
                <dxo-label text="{{language.nameHeader}}"></dxo-label>
                <div *dxTemplate>
                    <dx-text-box [(value)]="newName"></dx-text-box>
                </div>
            </dxi-item>

            <!-- SortOrder -->
            <dxi-item dataField="newSortOrder">
                <dxo-label text="{{language.sortOrderHeader}}"></dxo-label>
                <div *dxTemplate>
                    <dx-number-box [(value)]="newSortOrder" [min]="-100" [max]="100"></dx-number-box>
                </div>
            </dxi-item>

        </dx-form>
        <div class="button-container bottom-container align-right">
            <dx-button [disabled]="!canConfirmPopup" [text]="language.ButtonOk" (click)="closePopup(true)"></dx-button>
            <dx-button [text]="language.ButtonCancel" (click)="closePopup(false)"></dx-button>
        </div>
    </div>
</dx-popup>