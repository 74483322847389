import { ImportSpecificationFileDto } from "../../../import/dto/ImportSpecificationFile-dto";
import { FileSupplied } from "./file-supplied";
import { IUploadFileContent } from './i-upload-file-content';

export class FileDefinition {
  public name: string;
  public sortOrder: number;
  public allowMultiple: boolean;
  public id: number;
  public supplied: FileSupplied[];
  public filesForUpload: IUploadFileContent[];

  public constructor() {
    this.supplied = [];
    this.filesForUpload = [];
  }

  public copyFrom(dto: ImportSpecificationFileDto) {
    if (dto !== undefined && dto !== null) {
      this.allowMultiple = dto.AllowMultiple;
      this.name = dto.Name;
      this.sortOrder = dto.SortOrder;
      this.id = dto.FileId;
    }
  }
}
