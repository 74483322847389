import { EventTimerDataDto } from "../../../../../common/models/integrationservices/dto/EventTimerDataDto-dto";

interface IDictionary<T> {
  [key: string]: T;
}

export class EventTimerData implements IDictionary<any> {

  public values: IDictionary<string> = {};

  constructor(){
    this.values = {};
  }

  public static toDto(eventData: EventTimerData): EventTimerDataDto {
    const dto = new EventTimerDataDto();
    dto.Values = JSON.parse(JSON.stringify(eventData.values));
    return dto;
  }

  public fromDto(dto: EventTimerDataDto) {    
    this.values = JSON.parse(JSON.stringify(dto.Values));
  }

  private hasValue(value: string) {
    if (value) {
      return value !== null && value.length > 0;
    }
    return false;
  }
}
