import { JsDataTypeDto } from "../../../../../../../common/models/dto/JsDataType-dto";
import { PresentableElement } from "../../../../../../../common/model/models/presentable-element.model";
import { StatisticDto } from "../../../../analysis/dto/StatisticDTO-dto";

export class Statistic extends PresentableElement {
  public static createDefault() {
    const newStat = new Statistic();
    newStat.sortOrder = -1;
    newStat.operatorId = 0;
    newStat.dataType = JsDataTypeDto.Unknown;
    return newStat;
  }

  public operatorId: number;
  public dataType: JsDataTypeDto;

  public constructor() {
    super();
  }

  public static createFromDto(value: StatisticDto) {
    const result = new Statistic();
    result.copyFromDto(value);
    result.operatorId = value.OperatorId;
    result.dataType = value.DataType;
    return result;
  }

  public getVariantCopy(): Statistic {
    const result = new Statistic();
    result.copyFrom(this);
    return result;
  }

  public getCopy(): Statistic {
    return this.getVariantCopy();
  }

  public copyFrom(other: Statistic) {
    if (other) {
      super.copyFrom(other);
      this.operatorId = other.operatorId;
      this.dataType = other.dataType;
    }
  }

  public toDto(): StatisticDto {
    const result = new StatisticDto();
    super.assignDto(result);
    result.OperatorId = this.operatorId;
    result.DataType = this.dataType;
    return result;
  }
}
