import { DateDto } from './DateDto-dto';
import { ElementTypeDto } from './ElementType-dto';
import { IModelWithIdDto } from './IModelWithId-dto';

// t4tsCodeFromCs": "0.8.2.0 for file MutationDto.cs
// Don't adjust manually!

export class MutationDto implements IModelWithIdDto {
    public KeyStartDate: DateDto;
    public KeyVariantId: number;
    public StartDateText: string;
    public DisplayValue: string;
    public KeyId: number;
    public ElementType: ElementTypeDto;
}

