import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
  DxListModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextBoxModule,
} from "devextreme-angular";
import { TextPopupModule } from "../../../../angular-common/";
import { CardViewModule } from "../../../../angular-common/components/cardview";
import { ListSelectorModule } from "../../../../angular-common/components/listselector/listselector.module";
import { ModelTreeModule } from "../../../../angular-common/components/modeltree/modeltree.module";
import { RadioGroupModule } from "../../../../angular-common/components/radiogroup/radiogroup.module";
import { SelectBoxModule } from "../../../../angular-common/components/selectbox/selectbox.module";
import { DashletViewEditModule } from "../dashboarding-screen/dashboard/dashletviewedit/dashlet-edit.module";
import { DashboardingScreenModule } from "../dashboarding-screen/dashboarding-screen.module";
import { ImportFileModule } from "../shared/import-file/import-file.module";
import { DashboardDesignAddDashletComponent } from "./dashboarddesign/dashboard-design-add-dashlet.component";
import { DashboardDesignAuthorizationComponent } from "./dashboarddesign/dashboard-design-authorization.component";
import { DashboardDesignDashboardComponent } from "./dashboarddesign/dashboard-design-dashboard.component";
import { DashboardDesignDashletViewComponent } from "./dashboarddesign/dashboard-design-dashletview.component";
import { DashboardDesignComponent } from "./dashboarddesign/dashboard-design.component";
import { EditDashboardComponent } from "./dashboarddesign/edit-dashboard.component";
import { DashboardingDesignScreenComponent } from "./dashboarding-design-screen.component";
import { DashboardingDesignComponent } from "./dashboarding-design.component";
import { DashletDefinitionDesignListComponent } from "./dashletdefinitiondesign/dashlet-definition-design-list.component";
import { DashletDefinitionDesignComponent } from "./dashletdefinitiondesign/dashlet-definition-design.component";
import { DashletDefinitionDesignerChartComponent } from "./dashletdefinitiondesign/props/dashlet-definition-designer-chart.component";
import { DashletDefinitionDesignerDetailComponent } from "./dashletdefinitiondesign/props/dashlet-definition-designer-detail.component";
import { DashletDefinitionDesignerFactoryComponent } from "./dashletdefinitiondesign/props/dashlet-definition-designer-factory";
import { PropertiesStatisticComponent } from "./dashletdefinitiondesign/props/dashlet-definition-designer-statistic.component";
import { DataSupplyVariantSelectorComponent } from "./dashletdefinitiondesign/props/dashlet-definition-variant-selection.component";
import { StatisticSelectorComponent } from "./dashletdefinitiondesign/props/dashlet-statistic-selector.component";

@NgModule({
  imports: [
    CommonModule,
    DxTabsModule,
    DxTagBoxModule,
    DxListModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxFormModule,
    RadioGroupModule,
    TextPopupModule,
    CardViewModule,
    DxAccordionModule,
    ImportFileModule,
    ListSelectorModule,
    DxCheckBoxModule,
    DxPopupModule,
    ModelTreeModule,
    DashboardingScreenModule,
    DashletViewEditModule,
    SelectBoxModule,
  ],
  declarations: [
    DashboardingDesignScreenComponent,
    DashletDefinitionDesignComponent,
    DashboardDesignComponent,
    DashboardDesignAuthorizationComponent,
    DashboardingDesignComponent,
    DashletDefinitionDesignListComponent,
    DashletDefinitionDesignerFactoryComponent,
    DashletDefinitionDesignerDetailComponent,
    DashletDefinitionDesignerChartComponent,
    PropertiesStatisticComponent,
    DashboardDesignDashboardComponent,
    DashboardDesignDashletViewComponent,
    DashboardDesignAddDashletComponent,
    EditDashboardComponent,
    StatisticSelectorComponent,
    DataSupplyVariantSelectorComponent,
  ],
})
export class DashboardingDesignScreenModule {}
