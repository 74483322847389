import { Component, Input } from "@angular/core";
import { DashletDefinition, DashletDefinitionMetaData, Statistic } from "../../../services/dashletdefinition";

import { ElementHelper } from "../../../../../../angular-common/helpers/element.helper";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";

@Component({
  selector: "app-statistic-selector",
  templateUrl: "dashlet-statistic-selector.component.html",
})
export class StatisticSelectorComponent {
  constructor(public language: ImagineLanguage) {}

  @Input() selectStatistic: Statistic;
  @Input() metaData: DashletDefinitionMetaData;
  @Input() dashletDefinition: DashletDefinition;

  public get shouldShowDeleteButton(): boolean {
    if (ElementHelper.isNullOrUndefined(this.dashletDefinition.statistics)) {
      return false;
    }

    if (this.dashletDefinition.hasChanges) {
      return this.dashletDefinition.changes.statistics.length > 1;
    }

    return this.dashletDefinition.statistics.length > 1;
  }

  get selectedStatistic() {
    const statisticIndex = this.metaData.statistics.findIndex((s) => s.shortName === this.selectStatistic.shortName);

    if (statisticIndex !== undefined) {
      return this.metaData.statistics[statisticIndex];
    }
  }

  get selectedStatisticOperator(): number {
    if (ElementHelper.isNullOrUndefined(this.selectStatistic.operatorId) === true || this.selectStatistic.operatorId === -1) {
      this.selectStatistic.operatorId = this.metaData.defaultOperator.id;
    }

    return this.selectStatistic.operatorId;
  }

  set selectedStatisticOperator(value: number) {
    this.selectStatistic.operatorId = value;
  }

  onSelectionChanged(event: Statistic) {
    if (event !== undefined) {
      this.dashletDefinition.changes.statistic = event;
    }
  }
}
