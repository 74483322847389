import { Component, OnInit } from "@angular/core";
import { ExportDefinitions, ExportingService } from "../../../../angular-common/components/exporting";
import { ImagineLanguage } from "../services/language/imaginelanguage.service";

@Component({
  selector: "app-exporting-screen",
  templateUrl: "./exporting-screen.component.html",
  styleUrls: ["./exporting-screen.component.scss"],
})
export class ExportingScreenComponent implements OnInit {
  public constructor(public language: ImagineLanguage, private exportingService: ExportingService) {}

  ngOnInit(): void {
    this.exportingService.getExportDefinitions().subscribe((ed) => {
      this.exportingDefinitions = ed;
    });
  }

  public get contentLoaded(): boolean {
    return this.exportingDefinitions && this.exportingDefinitions !== null;
  }

  public exportingDefinitions: ExportDefinitions;
}
