import { CompareTypeDto } from './../../../../../common/models/dto/CompareType-dto';

// t4tsCode gen": "0.8.2.0 for file DataSetFilterElementDto.cs
// Don't adjust manually!

export class DataSetFilterElementDto {
    public ShortName: string;
    public CompareType: CompareTypeDto;
    public Value: string;
    public ValueAsText: string;
}

