import { DataSetDefinitionDto } from "../../../../../../common/models/dto/DataSetDefinitionDto-dto";

export class DataSetDefinition {
  public id: number;
  public shortName: string;
  public longName: string;

  public copyFromDto(dto: DataSetDefinitionDto) {
    this.id = dto.KeyId;
    this.shortName = dto.ShortName;
    this.longName = dto.LongName;
  }
}
