import { DateSpanDto } from "../../../../../common/models/dto/DateSpanDto-dto";
import { SDConnectUserDto } from "../dto/SDConnectUserDto-dto";

export class SDConnectUser {
  public PayGroup: string;
  public ShortName: string;
  public FirstName: string;
  public LastName: string;
  public Email: string;
  public StartDate: Date;
  public EndDate: Date;

  public static toDto(model: SDConnectUser): SDConnectUserDto {
    const userDto = new SDConnectUserDto();

    userDto.PayGroup = model.PayGroup;
    userDto.ShortName = model.ShortName;
    userDto.FirstName = model.FirstName;
    userDto.LastName = model.LastName;
    userDto.Email = model.Email;

    userDto.Period = new DateSpanDto();
    userDto.Period.From = model.StartDate;
    userDto.Period.Until = model.EndDate;

    return userDto;
  }
}
