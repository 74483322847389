import { Component, EventEmitter, Output } from "@angular/core";
import { ModelPresentableElement } from "../../../../../common/model/models/model-presentablel-element-model";
import { ElementTypeDto } from "../../../../../common/models/dto/ElementType-dto";
import { ImportLanguage } from "../../services/language/connectorlanguage.service";

@Component({
  selector: "app-supply-variant-selector",
  templateUrl: "data-supply-variantselection.component.html",
  styleUrls: ["data-supply-variantselection.component.scss"],
})
export class DataSupplyVariantSelectorComponent  {
  constructor(public language: ImportLanguage) {}
  @Output()
  private variantIdChanged = new EventEmitter<number>(true);
  
  public variantForEdit: ModelPresentableElement;
  
  public variantForSelected: ModelPresentableElement;

  public allowedElementType = ElementTypeDto.Variant;
  
  public get selectedVariantName() {
      if (this.variantForSelected){
          return this.variantForSelected.displayName;
      }
  return "";
  }  
  
  public saveVariant() {
    this.variantForSelected = this.variantForEdit;
    if(this.variantForSelected) {
      this.variantIdChanged.next(this.variantForSelected.id);
    }
    this.popupVariantVisible = false;
  }

  public popupVariantVisible = false;

  public popupVariantToggle() {
    this.popupVariantVisible = true;
  }
}
