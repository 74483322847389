export class DateFormat {
  // This needs to be actually done under the following issue: 6956
  public static DateByUserLanguage() {
    return "dd/MM/yyyy";
  }

  public static DateTimeByUserLanguage() {
    return "dd/MM/yyyy hh:mm:ss:SSS";
  }
}
