<!-- card -->
<app-cardview class="reset-padding">
  <dx-accordion #accordion [dataSource]="[view]" [collapsible]="true" [multiple]="false" [selectedItems]="0" [animationDuration]="300">
    <div *dxTemplate="let view of 'title'">
      <h3 class="title truncate float-left">{{ view.name }}</h3>
      <div class="float-right">
        <dx-button id="deleteButton" icon="fas fa-trash" class="button-icon-m" [hint]="language.ButtonDelete" (onClick)="buttonClick($event)"></dx-button>
      </div>
    </div>
    <div *dxTemplate="let view of 'item'">
      <dx-form id="form">
        <dxi-item itemType="group" class="form-group">
          <!-- Type -->
          <dxi-item cssClass="alignRadioButtonLabel" dataField="Type" *ngIf="showTypeSelector">
            <dxo-label text="{{ language.typeHeader }}"></dxo-label>
            <div *dxTemplate>
              <app-selectbox [items]="typeSelector"> </app-selectbox>
            </div>
          </dxi-item>

          <!-- Sortorder -->
          <dxi-item dataField="SortOrder">
            <dxo-label text="{{ language.sortOrderHeader }}"></dxo-label>
            <div *dxTemplate>
              <dx-number-box [(value)]="view.changes.sortOrder" [min]="-100" [max]="100"></dx-number-box>
            </div>
          </dxi-item>

          <!-- Chart Selecter -->
          <dxi-item dataField="ShowPivotChart" *ngIf="showPivotChartSelector">
            <dxo-label [text]="language.dashletShowPivotChart"></dxo-label>
            <div *dxTemplate>
              <dx-check-box [(value)]="view.changes.dashletViewSettings.showPivotChart" [min]="-100" [max]="100"></dx-check-box>
            </div>
          </dxi-item>

          <!-- Colors -->
          <dxi-item dataField="Colors" *ngIf="showColorSelector">
            <dxo-label [text]="language.colorHeader"></dxo-label>
            <div *dxTemplate>
              <dx-select-box [items]="palettes.definitions" displayExpr="name" valueExpr="paletteName" [(value)]="view.changes.paletteName" itemTemplate="clrpicker">
                <div *dxTemplate="let data of 'clrpicker'">
                  <div>
                    <div class="paletteName">
                      {{ data.name }}
                    </div>
                    <div *ngFor="let clr of data.colors" class="colorblock" [ngStyle]="{ 'background-color': clr }"></div>
                  </div>
                </div>
              </dx-select-box>
            </div>
          </dxi-item>
        </dxi-item>
      </dx-form>
      <div class="button-container bottom-container align-right">
        <span *ngIf="warningMessage !== null" class="savewarning float-left">
          {{ warningMessage }}
        </span>
        <dx-button [text]="language.dashletViewDesignSaveChanges" icon="save" type="default" [hint]="language.dashletViewDesignSaveChanges" (onClick)="save()" [disabled]="!view.canSave"></dx-button>
        <dx-button [text]="language.Undo" icon="undo" [hint]="language.Undo" (onClick)="view.undoChanges()" [disabled]="!view.hasChanges"></dx-button>
        <dx-button
          [text]="language.dashletViewDesignView"
          icon="fas fa-external-link-alt"
          type="default"
          [hint]="language.dashletViewDesignView"
          (onClick)="goToView()"
          [disabled]="view.canSave"
        ></dx-button>
      </div>
    </div>
  </dx-accordion>
</app-cardview>

<app-dashlet-edit-view *ngIf="shouldShowViewPopup" [dashletView]="view" (dashletViewSaved)="onDashletViewSaved($event)" [(visible)]="shouldShowViewPopup"></app-dashlet-edit-view>
