import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { PlUserStorage } from "../../../../../../angular-common/baseservice/pluserstorage";
import { ElementHelper } from "../../../../../../angular-common/helpers/element.helper";
import { DateFormat } from "../../../../../../angular-common/language/date-format";
import { LongNameShortName } from "../../../../../../angular-common/longnameshortname/longname-shortname";
import { CopyingObjects } from "../../../../../../angular-common/utils/copying-objects";
import { ModelElementIcon } from "../../../../../../common/model/models/model-element-icon";
import { ModelCategory } from "../../../../../../common/modelelements/categories/model-category";
import { DateTranslator } from "../../../../../../common/modelelements/date-translator";
import { TimePeriod } from "../../../../../../common/modelelements/timeperiod-model";
import { DateDto } from "../../../../../../common/models/dto/DateDto-dto";
import { ElementTypeDto } from "../../../../../../common/models/dto/ElementType-dto";
import { FormulaMutationDto } from "../../../../../../common/models/dto/FormulaMutationDto-dto";
import { MutationDto } from "../../../../../../common/models/dto/MutationDto-dto";
import { ParameterMutationDto } from "../../../../../../common/models/dto/ParameterMutationDto-dto";
import { ReferenceCategoriesDto } from "../../../../../../common/models/dto/ReferenceCategoriesDto-dto";
import { StackMutationDto } from "../../../../../../common/models/dto/StackMutationDto-dto";
import { VariantEditInformationDto } from "../../../../../../common/models/dto/VariantEditInformationDto-dto";
import { WellKnownRoleNamesDto } from "../../../../../../common/models/dto/WellKnownRoleNames-dto";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { VariantEditMetaData } from "../../../services/variantedit/models/variantedit-metadata";
import { VariantEditService } from "../../../services/variantedit/variantedit-service";
import { VariantChangeMode } from "../variant-edit-component/variant-changemode";
import { FormulaMutationsComponent } from "./formula-mutations-component/formula-mutations-component";
import { IMutationsCategorized } from "./i-mutations-categorized";
import { IVariantModelElementsInformation } from "./i-variant-model-elements-info";
import { StackMutationsComponent } from "./stack-mutations-component/stack-mutations-component";

@Component({
  selector: "app-variant-mutations-component",
  templateUrl: "./variant-mutations-component.html",
  styleUrls: ["./variant-mutations-component.scss"],
})
export class VariantMutationsComponent implements OnInit {
  public itemToEdit: IVariantModelElementsInformation;
  public newStartDate: any;
  public defaultStartDate: any;
  public dateFormat: any = DateFormat.DateByUserLanguage();
  public stackReferenceCategoriesDto: ReferenceCategoriesDto[];
  public periodsForPeriodSelection: TimePeriod[];
  public disabledPeriods: Date[] = [];
  public mutationDatesInUse: Date[] = [];
  public periodsInUse: TimePeriod[] = [];
  public lastFormulaMutation: FormulaMutationDto;
  public lastStackMutation: StackMutationDto;

  constructor(public language: ImagineLanguage, private variantService: VariantEditService) {
    this.getValuCalculated = this.getValuCalculated.bind(this);
    this.selectTimePeriod = this.selectTimePeriod.bind(this);
  }

  ngOnInit(): void {
    this.mutationsCategorized = this.mutationsGroupedByModelCategory();
    const [foundMutationsWithoutCategories, uncategorizedMutations] = this.includeMutationsWithoutCategoryWhenExists();
    this.stackReferenceCategoriesDto = this.variantEditMetaData.referenceCategories;
    this.periodsForPeriodSelection = this.variantEditMetaData.periods;
    if (ElementHelper.isNullOrUndefined(this.variantEditMetaData.periods) === false) this.periodsForPeriodSelection = this.variantEditMetaData.periods;

    if (foundMutationsWithoutCategories === true) {
      this.mutationsCategorized.push(uncategorizedMutations);
    }
  }

  @ViewChild("formulaMutationsRef") formulaMutationsComponent: FormulaMutationsComponent;
  @ViewChild("stackMutationsRef") stackMutationsComponent: StackMutationsComponent;
  public onPopupHidden() {
    if (this.isFormulaEdit()) {
      this.formulaMutationsComponent.validationMessage = "";
    }
    if (this.isStackEdit()) {
      this.stackMutationsComponent.validationMessage = " ";
    }
  }

  private readonly maxMutationsCountToExpandCategory: number = 5;

  @Input()
  public variantEditMetaData: VariantEditMetaData;

  @Input()
  public variantEditInformation: VariantEditInformationDto;

  popupVisible: boolean = false;
  typeOfPopup: VariantChangeMode = VariantChangeMode.None;

  public mutationsCategorized: IMutationsCategorized[];

  public mutationsNumberIn(category: IMutationsCategorized) {
    return category.items.length;
  }

  public isFormulaEdit(): boolean {
    if (ElementHelper.isNullOrUndefined(this.itemToEdit)) {
      return false;
    }
    return this.itemToEdit.type === ElementTypeDto.Formula;
  }

  public isStackEdit(): boolean {
    if (ElementHelper.isNullOrUndefined(this.itemToEdit)) {
      return false;
    }
    return this.itemToEdit.type === ElementTypeDto.Stack;
  }

  public isParameterEdit(): boolean {
    if (ElementHelper.isNullOrUndefined(this.itemToEdit)) {
      return false;
    }
    return this.itemToEdit.type === ElementTypeDto.Parameter;
  }

  public canEdit(): boolean {
    if (PlUserStorage.hasRole(WellKnownRoleNamesDto.ModelEditing) === false) {
      return false;
    }
    return true;
  }

  selectTimePeriod(gridRow: any) {
    return TimePeriod.TimePeriodDisplayNameSelector(gridRow, this.periodsForPeriodSelection);
  }

  public disablePeriods() {
    this.periodsInUse.length = 0;
    this.periodsInUse = TimePeriod.GetPeriodsInUse(this.mutationsToShow, this.periodsForPeriodSelection);
  }

  public mutationsSummary(modelElement: IVariantModelElementsInformation) {
    if (modelElement) {
      if (modelElement.mutations.length == 0) {
        return " ";
      } else if (modelElement.mutations.length == 1) {
        const singleMutation = modelElement.mutations[0];
        return `${singleMutation.StartDateText} : ${singleMutation.DisplayValue}`;
      } else {
        return modelElement.mutations.length + " " + this.language.mutationHeader;
      }
    } else {
      return "-";
    }
  }

  public mutationsToShow: MutationDto[] = [];
  public mutationsToEdit: MutationDto[] = [];

  public get hasMutations(): boolean {
    return this.mutationsCategorized.length > 0;
  }

  private includeMutationsWithoutCategoryWhenExists(): [boolean, IMutationsCategorized] {
    const uncategorizedMutations: IMutationsCategorized = { key: -1, categoryName: this.language.modelEditElementsWithoutCategory, items: [] };

    const formulasWithoutCategories = this.variantEditInformation.Mutations.Formulas.filter((f) => f.ModelCategoryId === -1);
    uncategorizedMutations.items = [
      ...uncategorizedMutations.items,
      ...formulasWithoutCategories.map((formula) => ({
        id: formula.KeyId,
        elementName: LongNameShortName.NameForDto(formula),
        type: formula.TypeOfElement,
        mutations: [],
        modelCategoryId: formula.ModelCategoryId,
      })),
    ];

    const parametersWithoutCategories = this.variantEditInformation.Mutations.Parameters.filter((f) => f.ModelCategoryId === -1);
    uncategorizedMutations.items = [
      ...uncategorizedMutations.items,
      ...parametersWithoutCategories.map((parameter) => ({
        id: parameter.KeyId,
        elementName: LongNameShortName.NameForDto(parameter),
        type: parameter.TypeOfElement,
        mutations: parameter.ParameterMutations,
        modelCategoryId: parameter.ModelCategoryId,
      })),
    ];

    const stacksWithoutCategories = this.variantEditInformation.Mutations.Stacks.filter((f) => f.ModelCategoryId === -1);
    uncategorizedMutations.items = [
      ...uncategorizedMutations.items,
      ...stacksWithoutCategories.map((stack) => ({
        id: stack.KeyId,
        elementName: LongNameShortName.NameForDto(stack),
        type: stack.TypeOfElement,
        mutations: [],
        modelCategoryId: stack.ModelCategoryId,
        stackType: stack.StackType,
      })),
    ];

    const foundMutationsWithoutCategory = formulasWithoutCategories.length > 0 || parametersWithoutCategories.length > 0 || stacksWithoutCategories.length > 0;

    return [foundMutationsWithoutCategory, uncategorizedMutations];
  }

  private mutationsGroupedByModelCategory(): IMutationsCategorized[] {
    const categoriesWithMutations: IMutationsCategorized[] = [];

    this.variantEditMetaData.modelCategories.forEach((modelCategory) => {
      var formulas = this.variantEditInformation.Mutations.Formulas.filter((f) => f.ModelCategoryId === modelCategory.keyId);
      this.assignMutationsToCategory(
        categoriesWithMutations,
        modelCategory,
        formulas.map((formula) => ({
          id: formula.KeyId,
          elementName: LongNameShortName.NameForDto(formula),
          type: formula.TypeOfElement,
          mutations: formula.FormulaMutations,
          modelCategoryId: formula.ModelCategoryId,
        })),
      );

      var parameters = this.variantEditInformation.Mutations.Parameters.filter((p) => p.ModelCategoryId === modelCategory.keyId);
      this.assignMutationsToCategory(
        categoriesWithMutations,
        modelCategory,
        parameters.map((parameter) => ({
          id: parameter.KeyId,
          elementName: LongNameShortName.NameForDto(parameter),
          type: parameter.TypeOfElement,
          mutations: parameter.ParameterMutations,
          modelCategoryId: parameter.ModelCategoryId,
        })),
      );

      var stacks = this.variantEditInformation.Mutations.Stacks.filter((p) => p.ModelCategoryId === modelCategory.keyId);
      this.assignMutationsToCategory(
        categoriesWithMutations,
        modelCategory,
        stacks.map((stack) => ({
          id: stack.KeyId,
          elementName: LongNameShortName.NameForDto(stack),
          type: stack.TypeOfElement,
          mutations: stack.StackMutations,
          modelCategoryId: stack.ModelCategoryId,
          stackType: stack.StackType,
        })),
      );
    });

    return categoriesWithMutations;
  }

  private assignMutationsToCategory(mutationsWithCategories: IMutationsCategorized[], modelCategory: ModelCategory, mutationInfo: IVariantModelElementsInformation[]) {
    if (ElementHelper.isNullOrUndefined(mutationInfo) || mutationInfo.length === 0) {
      return;
    }

    const newCategoryWithMutations: IMutationsCategorized = { key: modelCategory.keyId, categoryName: modelCategory.shortName, items: [] };
    newCategoryWithMutations.items = mutationInfo;
    mutationsWithCategories.push(newCategoryWithMutations);
  }

  public getIconByElementModelType(type: ElementTypeDto): string {
    return ModelElementIcon.getByType(type);
  }

  public onMutationsListContentReady(event: any) {
    if (ElementHelper.isNullOrUndefined(event)) {
      return;
    }

    const mutationsCategorized: IMutationsCategorized[] = event.component?.option("items") ?? [];

    mutationsCategorized.forEach((category, index) => {
      if (index === 0) {
        if (category.items.length <= this.maxMutationsCountToExpandCategory) {
          return;
        }
      }
      event.component.collapseGroup(index);
    });
  }

  public createChildPopup() {
    this.typeOfPopup = VariantChangeMode.CreateChild;
    this.popupVisible = true;
  }

  public editMutationPopup(value: IVariantModelElementsInformation) {
    this.mutationsToShow = value.mutations;
    this.mutationsToEdit = CopyingObjects.deepCopy(this.mutationsToShow);
    this.typeOfPopup = VariantChangeMode.Edit;
    this.itemToEdit = value;
    this.popupVisible = true;
    this.newStartDate = this.getNewStartDate();
    this.defaultStartDate = this.getNewStartDate();

    if (this.itemToEdit.type === ElementTypeDto.Formula) {
      this.formulaMutationsOfItemToEdit = this.assignCorrectTypeOfMutations(this.itemToEdit.mutations as FormulaMutationDto[], value.mutations) as FormulaMutationDto[];
      let lastFormulaMutationIndex = this.formulaMutationsOfItemToEdit.length - 1;
      this.lastFormulaMutation = this.formulaMutationsOfItemToEdit[lastFormulaMutationIndex];
    } else if (this.itemToEdit.type === ElementTypeDto.Parameter) {
      this.parameterMutationsOfItemToEdit = this.assignCorrectTypeOfMutations(this.itemToEdit.mutations as ParameterMutationDto[], value.mutations) as ParameterMutationDto[];
    } else if (this.itemToEdit.type === ElementTypeDto.Stack) {
      this.stackMutationsOfItemToEdit = this.assignCorrectTypeOfMutations(this.itemToEdit.mutations as StackMutationDto[], value.mutations) as StackMutationDto[];
      let lastStackMutationIndex = this.stackMutationsOfItemToEdit.length - 1;
      this.lastStackMutation = this.stackMutationsOfItemToEdit[lastStackMutationIndex];
    }
    this.disablePeriods();
  }

  formulaMutationsOfItemToEdit: FormulaMutationDto[];
  parameterMutationsOfItemToEdit: ParameterMutationDto[];
  stackMutationsOfItemToEdit: StackMutationDto[];
  assignCorrectTypeOfMutations(mutationsDto: MutationDto[], mutations: any) {
    mutationsDto = mutations;
    return mutationsDto;
  }

  public get popupTitle() {
    if (this.itemToEdit) {
      return this.itemToEdit.elementName;
    }
    return this.language.mutationComponentEditElement;
  }

  get isEditPopup(): boolean {
    return this.typeOfPopup == VariantChangeMode.Edit;
  }

  get isCreatePopup(): boolean {
    return this.typeOfPopup == VariantChangeMode.CreateChild;
  }

  getValuCalculated(gridRow: any) {
    return gridRow.Value;
  }

  onRowSaved(event: any) {
    if (event.changes.length === 0) {
      return;
    }

    const data: ParameterMutationDto = event.changes[0].data;

    if (ElementHelper.isNullOrUndefined(data) === false) {
      this.variantService.saveExistingParameterMutation(data).subscribe((v) => {
        if (v) {
          data.DisplayValue = v.DisplayValue;
          data.Value = v.Value;
          data.StartDateText = v.StartDateText;
        }
      });
    }
    this.refreshListOfMutations();
  }

  selectDate(date: any) {
    this.newStartDate = date;
  }

  getNewStartDate() {
    const lastMutation = this.itemToEdit.mutations[this.itemToEdit.mutations.length - 1];
    let timePeriodTakenByLastMutation = TimePeriod.TimePeriodSelector(lastMutation, this.periodsForPeriodSelection);
    const keyStartDateDto = new DateDto();
    if (ElementHelper.isNullOrUndefined(timePeriodTakenByLastMutation)) {
      keyStartDateDto.DateAsSortableNumber = lastMutation.KeyStartDate.DateAsSortableNumber;
    } else {
      keyStartDateDto.DateAsSortableNumber = timePeriodTakenByLastMutation.enddate;
    }
    const keyStartDate = DateTranslator.fromDateDto(keyStartDateDto);
    return keyStartDate.setDate(keyStartDate.getDate() + 1);
  }

  newMutation: MutationDto;
  newFormulaMutation: FormulaMutationDto;
  newParameterMutation: ParameterMutationDto;
  assignBasicMutationPropsAndPassSpecificProps<T extends MutationDto>(mutation: T, additionalProps: Partial<T>): T {
    mutation = {
      KeyId: this.itemToEdit.mutations[0].KeyId,
      KeyVariantId: this.itemToEdit.mutations[0].KeyVariantId,
      StartDateText: this.newStartDate,
      KeyStartDate: DateTranslator.toDateDto(new Date(this.newStartDate)),
      DisplayValue: "",
      ElementType: this.itemToEdit.type,
    } as T;

    mutation = { ...mutation, ...additionalProps };
    return mutation;
  }

  public addMutation() {
    switch (this.itemToEdit.type) {
      case ElementTypeDto.Formula:
        const formulaMutationDto = new FormulaMutationDto();
        this.newFormulaMutation = this.assignBasicMutationPropsAndPassSpecificProps(formulaMutationDto, {
          FormulaText: "",
          OriginalFormulaText: this.formulaMutationsOfItemToEdit[0].OriginalFormulaText,
        });
        this.variantService.addNewFormulaMutation(this.newFormulaMutation).subscribe(
          () => {
            this.refreshListOfMutations();
          },
          (ex) => {
            if (ex?.error) {
              alert(ex.error);
            } else {
              console.error(ex);
            }
          },
        );
        break;
      case ElementTypeDto.Parameter:
        const parameterMutationDto = new ParameterMutationDto();
        this.newParameterMutation = this.assignBasicMutationPropsAndPassSpecificProps(parameterMutationDto, { Value: this.parameterMutationsOfItemToEdit[0].Value });
        this.variantService.addNewParameterMutation(this.newParameterMutation).subscribe(
          () => {
            this.refreshListOfMutations();
          },
          (ex) => {
            if (ex?.error) {
              alert(ex.error);
            } else {
              console.error(ex);
            }
          },
        );
        break;
    }
  }

  refreshListOfMutations() {
    this.variantService.getVariantState(this.variantEditInformation.VariantUsage.VariantInformation.Variant).subscribe((x) => {
      this.variantEditInformation = x;
      this.mutationsCategorized = this.mutationsGroupedByModelCategory();

      if (ElementHelper.isNullOrUndefined(this.itemToEdit) === false) {
        for (const category of this.mutationsCategorized) {
          if (this.itemToEdit.modelCategoryId === category.key) {
            for (const item of category.items) {
              if (this.itemToEdit.id === item.id) {
                this.editMutationPopup(item);
              }
            }
          }
        }
      }
    });
    this.disablePeriods();
  }

  onRowRemoved(event: any) {
    const data: MutationDto = event.data;
    if (ElementHelper.isNullOrUndefined(data) === false) {
      this.variantService.deleteExistingMutation(data).subscribe(
        () => {
          this.refreshListOfMutations();
        },
        (ex) => {
          if (ex?.error) {
            alert(ex.error);
          } else {
            console.error(ex);
          }
        },
      );
    }
  }
}
