import { DashletDetailDataColumnFormatDto } from "../../analysis/dto/DashletDetailDataColumnFormat-dto";
import { DataPointValueDto } from "../../analysis/dto/DataPointValue-dto";
import { DxGridDataTypeDto } from "../../../../../common/models/dto/DxGridDataType-dto";

export class DashletValueFormatting {
  public static currencyType = "c";
  public static dateTimeType = "d";
  public static numericType = "n";
  public static percentageType = "p";

  public static canCalculateTotals(columnFormat: DashletDetailDataColumnFormatDto): boolean {
    if (columnFormat) {
      const format = columnFormat.FormatSpecifier.toLocaleLowerCase();
      if (format === this.currencyType || format === this.numericType) {
        return true;
      }
    }
    return false;
  }

  public static retrieveFormat(formatSpecifier: string, precision: number): string {
    let format: string;
    formatSpecifier = formatSpecifier;

    switch (formatSpecifier.toLocaleLowerCase()) {
      case this.currencyType:
        format = "€ #,##0";
        break;
      case this.dateTimeType:
        format = "dd-MM-yyyy";
        break;
      case this.numericType:
        format = "#";
        break;
      case this.percentageType:
        format = "#0";
        precision = precision - 2;
        break;
      default:
        format = "0";
    }

    if (precision > 0) {
      format += "." + "0".repeat(precision);
    }

    if (formatSpecifier === this.percentageType) {
      format = format + "%";
    }

    return format;
  }

  public static retrieveFormatByDto(columnFormat: DashletDetailDataColumnFormatDto): string {
    if (columnFormat === null || columnFormat.FormatSpecifier === undefined) {
      return;
    }

    const formatSpecifier = columnFormat.FormatSpecifier.toLocaleLowerCase();
    const precision = columnFormat.Precision;

    return this.retrieveFormat(formatSpecifier, precision);
  }

  public static retrieveDataType(columnFormat: DashletDetailDataColumnFormatDto) {
    if (columnFormat) {
      switch (columnFormat.FormatSpecifier.toLocaleLowerCase()) {
        case this.currencyType:
        case this.percentageType:
        case this.numericType:
          return DxGridDataTypeDto.Numeric;
        case this.dateTimeType:
          return DxGridDataTypeDto.DateTime;
      }
    }
    return DxGridDataTypeDto.String;
  }

  public static retrieveConvertedValue(value: any, columnType: string) {
    if (value && columnType === DxGridDataTypeDto.DateTime) {
      return this.toDate(value);
    }
    return value;
  }

  public static retrieveColumnValueByType(columnType: string, cell: DataPointValueDto) {
    if (columnType === DxGridDataTypeDto.Numeric || columnType === DxGridDataTypeDto.DateTime) {
      return cell.Value;
    }

    return cell.ValueAsText;
  }

  private static toDate(oaDate: number) {
    if (oaDate === null) {
      return null;
    }

    const date = new Date();
    date.setTime((oaDate - 25569) * 24 * 3600 * 1000);

    return date;
  }
}
