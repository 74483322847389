<dx-date-box
  [(value)]="newStartDate"
  [min]="minDate"
  [max]="maxDate"
  (onValueChanged)="onValueChanged($event)"
  type="date"
  displayFormat="{{dateFormat}}"
  [disabledDates]="mutationDatesInUse"
  class="custom-date-box"
>
</dx-date-box>
