import { ElementTypeDto } from './../../../../../common/models/dto/ElementType-dto';

// t4tsCode gen": "0.8.2.0 for file DashletAnalysisSource.cs
// Don't adjust manually!

export class DashletAnalysisSourceDto {
    public KeyId: number;
    public TypeOfElement: ElementTypeDto;
}

