<app-cardview *ngIf="!noData">
  <div class="inner-container fix-height-l">
    <dx-chart
      id="{{ divId }}"
      [palette]="palettes.getPaletteByName(dashletView.paletteName).colors"
      [dataSource]="seriesData"
      (onPointClick)="onPointClick($event)"
      pointSelectionMode="multiple"
      redrawOnResize="true"
      [rotated]="dashletView.viewType === dashletViewType.RotatedBarChart"
    >
      <dxo-argument-axis [visualRange]="chartOptions.scrollRange">
        <dxo-label wordWrap="none" [overlappingBehavior]="chartOptions.rotateAxisValues === true ? 'rotate' : 'none'"> </dxo-label>
      </dxo-argument-axis>
      <dxo-zoom-and-pan argumentAxis="pan"></dxo-zoom-and-pan>
      <dxo-scroll-bar [visible]="chartOptions.showScrollbar" [position]="(dashletView.viewType === dashletViewType.RotatedBarChart) === true ? 'left' : 'bottom'"> </dxo-scroll-bar>
      <dxo-title [text]="dashletView.name" horizontalAlignment="left">
        <dxo-font size="22px"></dxo-font>
      </dxo-title>
      <dxi-series valueField="value1" [name]="dataInspection.variant1Name" tagField="context"></dxi-series>
      <dxi-series valueField="value2" [name]="dataInspection.variant2Name" tagField="context" *ngIf="dataInspection.isVariantComparison"></dxi-series>

      <dxo-common-series-settings argumentField="clusterName" [type]="dxChartType" hoverMode="allArgumentPoints" selectionMode="allArgumentPoints">
        <dxo-point [visible]="showMarkers"></dxo-point>
      </dxo-common-series-settings>

      <dxo-size width="auto" height="350"></dxo-size>
      <dxo-legend [visible]="dataInspection.isVariantComparison" verticalAlignment="bottom" horizontalAlignment="center"> </dxo-legend>

      <dxo-export [enabled]="true"></dxo-export>
      <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
    </dx-chart>
    <dx-context-menu [dataSource]="menuItems" [width]="200" target="#{{ divId }}" (onItemClick)="contextMenuClick($event)"> </dx-context-menu>
  </div>
  <app-dashlet-warning [dashletView]="dashletView"></app-dashlet-warning>
</app-cardview>

<dx-popup [showTitle]="true" [title]="dashletView.name" [width]="450" height="auto" [(visible)]="showChartOptions" [hideOnOutsideClick]="false" [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <div class="chart-option">
      <dx-check-box [(value)]="chartOptions.rotateAxisValues" [text]="language.dashletRotateAxisLabels"></dx-check-box>
    </div>
    <div class="chart-option">
      <dx-check-box [(value)]="chartOptions.showScrollbar" [text]="language.dashletShowScrollbar"></dx-check-box>
    </div>
    <div class="chart-option">
      {{ language.dashletMaxNumberOfBars }}
      <dx-number-box [disabled]="chartOptions.showScrollbar === false" [(value)]="chartOptions.maximumVisibleItems" [min]="0"></dx-number-box>
    </div>
    <div class="buttons">
      <dx-button class="button" icon="fas fa-check" [hint]="language.ButtonApply" [text]="language.ButtonApply" (onClick)="applyChanges()"></dx-button>
      <dx-button class="button" icon="save" [hint]="language.ButtonSave" [text]="language.ButtonSave" (onClick)="applyAndSaveChanges()"></dx-button>
    </div>
  </div>
</dx-popup>
