<div id="filterBar" class="filter dx-toolbar" *ngIf="filterList">
  <dx-button id="filterButton" type="default" class="filter-button" icon="chevrondown" [text]="language.filter"
    [hint]="language.filter" [disabled]="!hasData" (onClick)="toggleFilterPopup()">
  </dx-button>
  <div class="filters-display">
    <span *ngFor="let filter of getFirstFilters()">
      <app-filtersummary [filter]="filter" (filtersRemoved)="filtersRemoved()" (filtersChanged)="applyFilters()">
      </app-filtersummary>
    </span>
  </div>

  <dx-popup class="popup-filter" [maxWidth]="1200" height="auto" [showTitle]="true" [title]="language.filter"
    [dragEnabled]="true" [(visible)]="filterPopupVisible" [position]="{
      my: 'left top',
      at: 'left bottom',
      of: '#filterButton',
      offset: '0 2'
    }">
    <div *dxTemplate="let data of 'content'">
      <dx-scroll-view [height]="400" [showScrollbar]="'always'">
        <div class="widget-container filteroptionlist" *ngFor="let dataFilter of filtersListToShow">
          <app-filteroption-list [filter]="dataFilter"></app-filteroption-list>
        </div>
      </dx-scroll-view>
      <dx-button class="filterButtons" [text]="language.ButtonApply" (click)="applyAllFilters()"></dx-button>
      <dx-button class="filterButtons" [text]="language.ButtonCancel" (click)="undo()"></dx-button>
      <dx-check-box *ngIf="userIsDashletDesigner" id="checkbox-showall" [(value)]="checkboxShowAll"
        [text]="showAllFilterText"> </dx-check-box>
    </div>
  </dx-popup>

  <app-variant-information *ngIf="allowComputeState" class="variant-info"
    [variants]="variantInformations"></app-variant-information>
  <span *ngIf="defaultPeriodFilterEnabled" class="date-period"><span style="font-weight: 700">{{
      language.periodFilterCurrentPeriod }}</span>&nbsp;{{ datePeriod }}</span>

  <a (click)="toggleFilterPopup()">
    <span *ngIf="threshHoldReached()" class="filters-display-limit">...</span>
  </a>
</div>