import { AuthorizationFilter } from "./authorization-filter-model";

export class AuthorizationFilters {
  private _filters: AuthorizationFilter[] = [];

  public constructor() {}

  public get all(): AuthorizationFilter[] {
    return this._filters;
  }

  public add(filter: AuthorizationFilter) {
    this._filters.push(filter);
  }

  public addOrReplace(filter: AuthorizationFilter) {
    const currentFilterIdx = this.findIndex(filter);
    if (currentFilterIdx > -1) {
      this._filters.splice(currentFilterIdx, 1);
    }
    this._filters.push(filter);
  }

  public delete(filter: AuthorizationFilter) {
    const currentFilterIdx = this.findIndex(filter);
    if (currentFilterIdx > -1) {
      this._filters.splice(currentFilterIdx, 1);
    }
  }

  public find(targetId: number, roleId: number): AuthorizationFilter {
    return this._filters.find((x) => x.roleId === roleId && x.targetId === targetId);
  }

  public findIndex(filter: AuthorizationFilter): number {
    return this._filters.findIndex((x) => x.roleId === filter.roleId && x.targetId === filter.targetId);
  }
}
