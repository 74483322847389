import { Injectable } from "@angular/core";
import { PaletteDefinition } from "./palette-definition";
import { Sorter } from "../..";
import { WebLanguage } from "../../../common/language/weblanguage.service";

const globalVariableSpace: any = window;
const clientPalette = (globalVariableSpace || []).clientPalette || [];

@Injectable()
export class Palettes {
  public static readonly defaultPaletteName: string = "Default";

  public constructor(public language: WebLanguage) {
    // DevExpress default palettes
    const pastel = new PaletteDefinition(this.language, "palette.devexpress.pastel", "Pastel", []);
    this.definitions.push(pastel);
    const vintage = new PaletteDefinition(this.language, "palette.devexpress.vintage", "Vintage", []);
    this.definitions.push(vintage);

    Sorter.sortAlphabetically<PaletteDefinition>(this.definitions, (p) => p.name);

    // Client palette
    const client = new PaletteDefinition(this.language, "palette.client", Palettes.defaultPaletteName, clientPalette);
    this.definitions.splice(0, 0, client);
  }

  public definitions: PaletteDefinition[] = [];

  public getPaletteByName(name: string): PaletteDefinition {
    let result: PaletteDefinition = null;

    if (name && name !== null) {
      result = this.definitions.find((p) => p.paletteName.toLocaleLowerCase() === name.toLocaleLowerCase());
    }

    // Return default palette if no palette was found
    if (result === null || result === undefined) {
      result = this.definitions[0];
    }

    return result;
  }
}
