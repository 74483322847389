import { Component, OnInit } from "@angular/core";

import { DashletTypeDto } from "../../../analysis/dto/DashletType-dto";
import { Statistic } from "../../../services/dashletdefinition";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { DashletDefinitionDesignPropertiesBase } from "./dashlet-definition-design-props-base";
import { DashletDefinitionMove } from "./dashlet-definition-designer-list-move";

@Component({
  selector: "app-dashlet-definition-designer-detail",
  templateUrl: "./dashlet-definition-designer-detail.component.html",
  styleUrls: ["./dashlet-definition-designer-detail.component.scss"],
})
export class DashletDefinitionDesignerDetailComponent extends DashletDefinitionDesignPropertiesBase implements OnInit {
  selectAllModeValue: string = "page";
  selectionModeValue: string = "all";
  public displayValue = "longName";
  public searchValue = "longName";
  selectedUsedOptions: Statistic[] = [];
  selectedConfigurableOptions: Statistic[] = [];

  public noneSelectedStatistics: Statistic[] = [];
  public selectedStatistics: Statistic[] = [];

  constructor(language: ImagineLanguage) {
    super(language);
    this.noneSelectedStatistics = [];
    this.selectedStatistics = [];
  }

  ngOnInit(): void {
    this._moveImplent = new DashletDefinitionMove(this.dashletDefinition);
  }

  private _moveImplent: DashletDefinitionMove;
  public get moveImplementation(): DashletDefinitionMove {
    return this._moveImplent;
  }

  public sortOrderChanged(data: Statistic[]) {
    this.dashletDefinition.changes.statistics = data;
  }

  public inputsChanged() {
    this.noneSelectedStatistics = this.filterStatisticsBySelectedInDefinition(false);
    this.selectedStatistics = this.itemsThatAreIncluded();
  }

  private filterStatisticsBySelectedInDefinition(include: boolean): Statistic[] {
    if (this.metaData === undefined || this.dashletDefinition === undefined) {
      return [];
    }
    const allStats = this.metaData.detailStatistics || [];
    const configured = this.dashletDefinition.changes.statistics;

    return allStats.filter((val: Statistic, i) => (configured.find((c) => c.shortName === val.shortName) !== undefined) === include);
  }

  private itemsThatAreIncluded(): Statistic[] {
    if (this.metaData === undefined || this.dashletDefinition === undefined) {
      return [];
    }
    const allStats = this.metaData.detailStatistics || [];
    const configured = this.dashletDefinition.changes.statistics;
    const currentConfiguredItems: Statistic[] = [];

    configured.forEach((stat) => {
      const spec = allStats.find((el) => el.shortName === stat.shortName);
      if (spec !== undefined) {
        spec.sortOrder = stat.sortOrder;
        currentConfiguredItems.push(spec);
      }
    });

    return currentConfiguredItems;
  }

  getCompletionText(cellInfo) {
    return cellInfo.valueText + "%";
  }

  public get enableStatisticSelection(): boolean {
    return this.dashletDefinition.changes.type !== DashletTypeDto.Detail;
  }

  public get enableClusterSelection(): boolean {
    return this.dashletDefinition.changes.type === DashletTypeDto.Chart;
  }

  public get warningMessage(): string {
    if (!this.dashletDefinition.changes.typeIsValid) {
      return this.language.dashletDefinitionDesignSelectType;
    }
    if (!this.dashletDefinition.changes.nameIsValid) {
      return this.language.dashletDefinitionDesignInputName;
    }
    if (!this.dashletDefinition.changes.variantIsValid) {
      return this.language.dashletDefinitionDesignSelectVariant;
    }
    if (!this.dashletDefinition.changes.dataSetTypeIsValid) {
      return this.language.dashletDefinitionDesignSelectDataSetType;
    }
    if (!this.dashletDefinition.changes.statistics || this.dashletDefinition.changes.statistics.length === 0) {
      return this.language.dashletDefinitionDesignSelectStatistic;
    }

    if (this.dashletDefinition.canSave) {
      return this.language.dashletDefinitionDesignSaveChanges;
    }

    return null;
  }

  public undoChanges() {
    this.dashletDefinition.undoChanges();
    this.inputsChanged();
  }
}
