import { ClusterField } from "../../dashletdata/models/cluster-field";
import { DashletDataPoint } from "../../dashletdata/models/dashlet-data-point";
import { DashletWithData } from "../../dashletdata/models/dashlet-data.model";
import { DataItemByNonVariantCluster } from "./data-item-by-non-variant-cluster";

export class DashletDataInspection {
  get variant1Name(): string {
    if (this.variantClusters.length > 0) {
      return this.variantClusters[0].value;
    }
    return "variant1";
  }

  get variant2Name(): string {
    if (this.variantClusters.length > 1) {
      return this.variantClusters[1].value;
    }
    return "variant2";
  }

  private static findVariantClustersPresentInData(variantIndex: number, dataPoint: DashletDataPoint[]): ClusterField[] {
    if (variantIndex < 0) {
      console.log("No variant index passed, so we can not search for variants present in the data");
      return [];
    }

    const clusterMap = {};
    dataPoint.forEach((dp) => {
      const vaCluster = dp.clusteredBy[variantIndex];
      if (vaCluster.isDataSourceCluster) {
        clusterMap[vaCluster.valueId] = vaCluster;
      }
    });
    // Turn back into a normal array;
    const clustersFound: ClusterField[] = [];
    for (const variantClusterId in clusterMap) {
      if (variantClusterId) {
        const cluster = clusterMap[variantClusterId];
        if (cluster !== undefined) {
          clustersFound.push(cluster);
        }
      }
    }
    clustersFound.sort(DashletDataInspection.sortByIsCompareVariant);

    return clustersFound;
  }

  private static sortByIsCompareVariant(a: ClusterField, b: ClusterField): number {
    if (a && b) {
      if (a.isDataSourceClusterComparingTo) {
        return 1;
      } else {
        return -1;
      }
    } else {
      if (a) {
        return 1;
      } else {
        return -1;
      }
    }
  }

  private static sourceClusterIndexIn(clusters: ClusterField[]): number {
    for (let ci = 0; ci < clusters.length; ci++) {
      const ct = clusters[ci];
      if (ct.isDataSourceCluster) {
        return ci;
      }
    }
    return -1;
  }

  private static valueClusterIndexIn(clusters: ClusterField[]): number {
    for (let ci = 0; ci < clusters.length; ci++) {
      const ct = clusters[ci];
      if (ct.isDataSourceCluster === false) {
        return ci;
      }
    }
    return -1;
  }

  private static sortByName(dp1: DataItemByNonVariantCluster, dp2: DataItemByNonVariantCluster) {
    if (dp1.cluster.value < dp2.cluster.value) {
      return -1;
    }
    if (dp1.cluster.value > dp2.cluster.value) {
      return 1;
    }
    return 0;
  }

  private static sortBySortOrder(dp1: DataItemByNonVariantCluster, dp2: DataItemByNonVariantCluster) {
    if (dp1.point1.sortOrder < dp2.point1.sortOrder) {
      return -1;
    }
    if (dp1.point1.sortOrder > dp2.point1.sortOrder) {
      return 1;
    }
    return 0;
  }

  private static sortByOrderThenName(dp1: DataItemByNonVariantCluster, dp2: DataItemByNonVariantCluster) {
    let result = DashletDataInspection.sortBySortOrder(dp1, dp2);
    if (result == 0) {
      result = DashletDataInspection.sortByName(dp1, dp2);
    }
    return result;
  }

  public isVariantComparison: boolean;
  public variantClusterIndex: number;
  public variantClusters: ClusterField[];

  constructor(public dashletWithData: DashletWithData) {
    this.variantClusterIndex = dashletWithData ? DashletDataInspection.sourceClusterIndexIn(dashletWithData.clusteredBy) : -1;
    if (this.variantClusterIndex >= 0) {
      this.isVariantComparison = true;
      this.variantClusters = DashletDataInspection.findVariantClustersPresentInData(this.variantClusterIndex, dashletWithData.data);
    } else {
      this.isVariantComparison = false;
      this.variantClusters = [];
    }
  }

  public retrieveDataWithPerVariantData(): DataItemByNonVariantCluster[] {
    const valueClusterIndex = DashletDataInspection.valueClusterIndexIn(this.dashletWithData.clusteredBy);

    if (this.variantClusters.length < 2) {
      console.log("We require two variants present in the data, we will fall back to single serie generation");
      return this.retrieveDataAsSingle();
    }

    const resharingMap = {};

    this.dashletWithData.data.forEach((dp) => {
      let idForMap = "none";
      let clusterByMap: ClusterField;
      if (!(valueClusterIndex < 0 || this.variantClusterIndex < 0)) {
        clusterByMap = dp.clusteredBy[valueClusterIndex];
        idForMap = clusterByMap.value;
      }

      let serieDataPoint: DataItemByNonVariantCluster = resharingMap[idForMap];
      if (clusterByMap === undefined) {
        clusterByMap = new ClusterField();
        clusterByMap.name = dp.name;
      }

      if (serieDataPoint === undefined) {
        serieDataPoint = new DataItemByNonVariantCluster(clusterByMap);
        resharingMap[idForMap] = serieDataPoint;
      } else {
      }

      const variantIdOfDp = dp.clusteredBy[this.variantClusterIndex].valueId;
      if (this.variantClusters[0].valueId === variantIdOfDp) {
        serieDataPoint.point1 = dp;
        serieDataPoint.variant1 = dp.clusteredBy[this.variantClusterIndex];
      } else if (this.variantClusters[1].valueId === variantIdOfDp) {
        serieDataPoint.point2 = dp;
        serieDataPoint.variant2 = dp.clusteredBy[this.variantClusterIndex];
      } else {
      }
    });

    // Turn back into a normal array;
    const result: DataItemByNonVariantCluster[] = [];
    for (const dataPointId in resharingMap) {
      if (dataPointId) {
        const dataPoint = resharingMap[dataPointId];
        if (dataPoint !== undefined) {
          result.push(dataPoint);
        }
      }
    }
    result.sort(DashletDataInspection.sortByOrderThenName);

    return result;
  }

  public retrieveDataAsSingle(): DataItemByNonVariantCluster[] {
    const result: DataItemByNonVariantCluster[] = [];

    this.dashletWithData.data.forEach((dp) => {
      const dp2 = new DataItemByNonVariantCluster(dp.clusteredBy[0]);
      dp2.point1 = dp;
      result.push(dp2);
    });
    result.sort(DashletDataInspection.sortByOrderThenName);

    return result;
  }
}
