
// t4tsCodeFromCs": "0.8.2.0 for file DataSetType.cs
// Don't adjust manually!
export enum DataSetTypeDto {
        LastPeriod = 0,
        Total = 1,
        PerPeriod = 2,
        Input = 3,
}

