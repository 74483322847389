import { Injectable } from "@angular/core";
import { PlLanguageService } from "../../angular-common/language/pllanguage.service";
import { environment } from "../environments/environment";
import { WebLanguage } from "./weblanguage.service";

@Injectable()
//t4Languages": "0.8.2.0
export class IntegrationServiceLanguage extends WebLanguage {
  public constructor(plLanguageService: PlLanguageService) {
    super(plLanguageService);
  }

  /**
   * eventTimerMenu from EventTimers.csv (in EN: Event Timers  )
   */
  public key_eventTimerMenu = "eventTimerMenu";
  /**
   * eventTimerMenu from EventTimers.csv (in EN: Event Timers  )
   */
  public get eventTimerMenu(): string { return this.getStringDef(this.key_eventTimerMenu, "Event Timers "); }

  /**
   * eventTimerValueUnknown from EventTimers.csv (in EN: Unknown )
   */
  public key_eventTimerValueUnknown = "eventTimerValueUnknown";
  /**
   * eventTimerValueUnknown from EventTimers.csv (in EN: Unknown )
   */
  public get eventTimerValueUnknown(): string { return this.getStringDef(this.key_eventTimerValueUnknown, "Unknown"); }

  /**
   * eventTimerDataLastRun from EventTimers.csv (in EN: Last run )
   */
  public key_eventTimerDataLastRun = "eventTimerDataLastRun";
  /**
   * eventTimerDataLastRun from EventTimers.csv (in EN: Last run )
   */
  public get eventTimerDataLastRun(): string { return this.getStringDef(this.key_eventTimerDataLastRun, "Last run"); }

  /**
   * eventTimerDataNextRun from EventTimers.csv (in EN: Next run )
   */
  public key_eventTimerDataNextRun = "eventTimerDataNextRun";
  /**
   * eventTimerDataNextRun from EventTimers.csv (in EN: Next run )
   */
  public get eventTimerDataNextRun(): string { return this.getStringDef(this.key_eventTimerDataNextRun, "Next run"); }

  /**
   * eventTimerDataTimesRun from EventTimers.csv (in EN: Times run )
   */
  public key_eventTimerDataTimesRun = "eventTimerDataTimesRun";
  /**
   * eventTimerDataTimesRun from EventTimers.csv (in EN: Times run )
   */
  public get eventTimerDataTimesRun(): string { return this.getStringDef(this.key_eventTimerDataTimesRun, "Times run"); }

  /**
   * eventTimerData from EventTimers.csv (in EN: Event data )
   */
  public key_eventTimerData = "eventTimerData";
  /**
   * eventTimerData from EventTimers.csv (in EN: Event data )
   */
  public get eventTimerData(): string { return this.getStringDef(this.key_eventTimerData, "Event data"); }

  /**
   * eventTimerAdjust from EventTimers.csv (in EN: Adjust )
   */
  public key_eventTimerAdjust = "eventTimerAdjust";
  /**
   * eventTimerAdjust from EventTimers.csv (in EN: Adjust )
   */
  public get eventTimerAdjust(): string { return this.getStringDef(this.key_eventTimerAdjust, "Adjust"); }

  /**
   * eventTimerActivate from EventTimers.csv (in EN: Activate )
   */
  public key_eventTimerActivate = "eventTimerActivate";
  /**
   * eventTimerActivate from EventTimers.csv (in EN: Activate )
   */
  public get eventTimerActivate(): string { return this.getStringDef(this.key_eventTimerActivate, "Activate"); }

  /**
   * eventTimerPause from EventTimers.csv (in EN: Pause )
   */
  public key_eventTimerPause = "eventTimerPause";
  /**
   * eventTimerPause from EventTimers.csv (in EN: Pause )
   */
  public get eventTimerPause(): string { return this.getStringDef(this.key_eventTimerPause, "Pause"); }

  /**
   * eventTimerWizardStepType from EventTimers.csv (in EN: Event type )
   */
  public key_eventTimerWizardStepType = "eventTimerWizardStepType";
  /**
   * eventTimerWizardStepType from EventTimers.csv (in EN: Event type )
   */
  public get eventTimerWizardStepType(): string { return this.getStringDef(this.key_eventTimerWizardStepType, "Event type"); }

  /**
   * eventTimerWizardStepNaming from EventTimers.csv (in EN: Event naming )
   */
  public key_eventTimerWizardStepNaming = "eventTimerWizardStepNaming";
  /**
   * eventTimerWizardStepNaming from EventTimers.csv (in EN: Event naming )
   */
  public get eventTimerWizardStepNaming(): string { return this.getStringDef(this.key_eventTimerWizardStepNaming, "Event naming"); }

  /**
   * eventTimerWizardStepExport from EventTimers.csv (in EN: Export settings )
   */
  public key_eventTimerWizardStepExport = "eventTimerWizardStepExport";
  /**
   * eventTimerWizardStepExport from EventTimers.csv (in EN: Export settings )
   */
  public get eventTimerWizardStepExport(): string { return this.getStringDef(this.key_eventTimerWizardStepExport, "Export settings"); }

  /**
   * eventTimerWizardStepImport from EventTimers.csv (in EN: Import settings )
   */
  public key_eventTimerWizardStepImport = "eventTimerWizardStepImport";
  /**
   * eventTimerWizardStepImport from EventTimers.csv (in EN: Import settings )
   */
  public get eventTimerWizardStepImport(): string { return this.getStringDef(this.key_eventTimerWizardStepImport, "Import settings"); }

  /**
   * eventTimerWizardStepMySDWorxActivation from EventTimers.csv (in EN: MySDWorx users activation settings )
   */
  public key_eventTimerWizardStepMySDWorxActivation = "eventTimerWizardStepMySDWorxActivation";
  /**
   * eventTimerWizardStepMySDWorxActivation from EventTimers.csv (in EN: MySDWorx users activation settings )
   */
  public get eventTimerWizardStepMySDWorxActivation(): string { return this.getStringDef(this.key_eventTimerWizardStepMySDWorxActivation, "MySDWorx users activation settings"); }

  /**
   * eventTimerWizardStepMySDWorxSync from EventTimers.csv (in EN: MySDWorx users synchronization settings )
   */
  public key_eventTimerWizardStepMySDWorxSync = "eventTimerWizardStepMySDWorxSync";
  /**
   * eventTimerWizardStepMySDWorxSync from EventTimers.csv (in EN: MySDWorx users synchronization settings )
   */
  public get eventTimerWizardStepMySDWorxSync(): string { return this.getStringDef(this.key_eventTimerWizardStepMySDWorxSync, "MySDWorx users synchronization settings"); }

  /**
   * eventTimerWizardStepComputeVariant from EventTimers.csv (in EN: Compute variant settings )
   */
  public key_eventTimerWizardStepComputeVariant = "eventTimerWizardStepComputeVariant";
  /**
   * eventTimerWizardStepComputeVariant from EventTimers.csv (in EN: Compute variant settings )
   */
  public get eventTimerWizardStepComputeVariant(): string { return this.getStringDef(this.key_eventTimerWizardStepComputeVariant, "Compute variant settings"); }

  /**
   * eventTimerWizardStepRecurrance from EventTimers.csv (in EN: Occurance settings )
   */
  public key_eventTimerWizardStepRecurrance = "eventTimerWizardStepRecurrance";
  /**
   * eventTimerWizardStepRecurrance from EventTimers.csv (in EN: Occurance settings )
   */
  public get eventTimerWizardStepRecurrance(): string { return this.getStringDef(this.key_eventTimerWizardStepRecurrance, "Occurance settings"); }

  /**
   * eventTimerSettings from EventTimers.csv (in EN: Event settings )
   */
  public key_eventTimerSettings = "eventTimerSettings";
  /**
   * eventTimerSettings from EventTimers.csv (in EN: Event settings )
   */
  public get eventTimerSettings(): string { return this.getStringDef(this.key_eventTimerSettings, "Event settings"); }

  /**
   * eventTimerSettingsRecurrenceType from EventTimers.csv (in EN: Selected recurrence type )
   */
  public key_eventTimerSettingsRecurrenceType = "eventTimerSettingsRecurrenceType";
  /**
   * eventTimerSettingsRecurrenceType from EventTimers.csv (in EN: Selected recurrence type )
   */
  public get eventTimerSettingsRecurrenceType(): string { return this.getStringDef(this.key_eventTimerSettingsRecurrenceType, "Selected recurrence type"); }

  /**
   * eventTimerDataNextRunInformation from EventTimers.csv (in EN: The time the next run will  take place will change when the changes are saved. )
   */
  public key_eventTimerDataNextRunInformation = "eventTimerDataNextRunInformation";
  /**
   * eventTimerDataNextRunInformation from EventTimers.csv (in EN: The time the next run will  take place will change when the changes are saved. )
   */
  public get eventTimerDataNextRunInformation(): string { return this.getStringDef(this.key_eventTimerDataNextRunInformation, "The time the next run will  take place will change when the changes are saved."); }

  /**
   * eventTimerDataRunning from EventTimers.csv (in EN: Running )
   */
  public key_eventTimerDataRunning = "eventTimerDataRunning";
  /**
   * eventTimerDataRunning from EventTimers.csv (in EN: Running )
   */
  public get eventTimerDataRunning(): string { return this.getStringDef(this.key_eventTimerDataRunning, "Running"); }

  /**
   * eventTimerEventShortName from EventTimers.csv (in EN: Event shortname )
   */
  public key_eventTimerEventShortName = "eventTimerEventShortName";
  /**
   * eventTimerEventShortName from EventTimers.csv (in EN: Event shortname )
   */
  public get eventTimerEventShortName(): string { return this.getStringDef(this.key_eventTimerEventShortName, "Event shortname"); }

  /**
   * eventTimerEventLongName from EventTimers.csv (in EN: Event longname )
   */
  public key_eventTimerEventLongName = "eventTimerEventLongName";
  /**
   * eventTimerEventLongName from EventTimers.csv (in EN: Event longname )
   */
  public get eventTimerEventLongName(): string { return this.getStringDef(this.key_eventTimerEventLongName, "Event longname"); }

  /**
   * eventTimerEventRecurrence from EventTimers.csv (in EN: Recurrence type )
   */
  public key_eventTimerEventRecurrence = "eventTimerEventRecurrence";
  /**
   * eventTimerEventRecurrence from EventTimers.csv (in EN: Recurrence type )
   */
  public get eventTimerEventRecurrence(): string { return this.getStringDef(this.key_eventTimerEventRecurrence, "Recurrence type"); }

  /**
   * eventTimerEventRecurrence_Hourly from EventTimers.csv (in EN: Hourly )
   */
  public key_eventTimerEventRecurrence_Hourly = "eventTimerEventRecurrence_Hourly";
  /**
   * eventTimerEventRecurrence_Hourly from EventTimers.csv (in EN: Hourly )
   */
  public get eventTimerEventRecurrence_Hourly(): string { return this.getStringDef(this.key_eventTimerEventRecurrence_Hourly, "Hourly"); }

  /**
   * eventTimerEventRecurrence_Daily from EventTimers.csv (in EN: Daily )
   */
  public key_eventTimerEventRecurrence_Daily = "eventTimerEventRecurrence_Daily";
  /**
   * eventTimerEventRecurrence_Daily from EventTimers.csv (in EN: Daily )
   */
  public get eventTimerEventRecurrence_Daily(): string { return this.getStringDef(this.key_eventTimerEventRecurrence_Daily, "Daily"); }

  /**
   * eventTimerEventRecurrence_Weekly from EventTimers.csv (in EN: Weekly )
   */
  public key_eventTimerEventRecurrence_Weekly = "eventTimerEventRecurrence_Weekly";
  /**
   * eventTimerEventRecurrence_Weekly from EventTimers.csv (in EN: Weekly )
   */
  public get eventTimerEventRecurrence_Weekly(): string { return this.getStringDef(this.key_eventTimerEventRecurrence_Weekly, "Weekly"); }

  /**
   * eventTimerEventRecurrence_Monthly from EventTimers.csv (in EN: Monthly )
   */
  public key_eventTimerEventRecurrence_Monthly = "eventTimerEventRecurrence_Monthly";
  /**
   * eventTimerEventRecurrence_Monthly from EventTimers.csv (in EN: Monthly )
   */
  public get eventTimerEventRecurrence_Monthly(): string { return this.getStringDef(this.key_eventTimerEventRecurrence_Monthly, "Monthly"); }

  /**
   * eventTimerEventRecurrence_Yearly from EventTimers.csv (in EN: Yearly )
   */
  public key_eventTimerEventRecurrence_Yearly = "eventTimerEventRecurrence_Yearly";
  /**
   * eventTimerEventRecurrence_Yearly from EventTimers.csv (in EN: Yearly )
   */
  public get eventTimerEventRecurrence_Yearly(): string { return this.getStringDef(this.key_eventTimerEventRecurrence_Yearly, "Yearly"); }

  /**
   * eventTimerEventRecurrence_Unknown from EventTimers.csv (in EN: Unknown )
   */
  public key_eventTimerEventRecurrence_Unknown = "eventTimerEventRecurrence_Unknown";
  /**
   * eventTimerEventRecurrence_Unknown from EventTimers.csv (in EN: Unknown )
   */
  public get eventTimerEventRecurrence_Unknown(): string { return this.getStringDef(this.key_eventTimerEventRecurrence_Unknown, "Unknown"); }

  /**
   * eventTimerEventRecurrence_NotYet from EventTimers.csv (in EN: Not yet )
   */
  public key_eventTimerEventRecurrence_NotYet = "eventTimerEventRecurrence_NotYet";
  /**
   * eventTimerEventRecurrence_NotYet from EventTimers.csv (in EN: Not yet )
   */
  public get eventTimerEventRecurrence_NotYet(): string { return this.getStringDef(this.key_eventTimerEventRecurrence_NotYet, "Not yet"); }

  /**
   * eventTimerEventStartDate from EventTimers.csv (in EN: Start date )
   */
  public key_eventTimerEventStartDate = "eventTimerEventStartDate";
  /**
   * eventTimerEventStartDate from EventTimers.csv (in EN: Start date )
   */
  public get eventTimerEventStartDate(): string { return this.getStringDef(this.key_eventTimerEventStartDate, "Start date"); }

  /**
   * eventTimerEventStartTime from EventTimers.csv (in EN: Start time )
   */
  public key_eventTimerEventStartTime = "eventTimerEventStartTime";
  /**
   * eventTimerEventStartTime from EventTimers.csv (in EN: Start time )
   */
  public get eventTimerEventStartTime(): string { return this.getStringDef(this.key_eventTimerEventStartTime, "Start time"); }

  /**
   * eventTimerEventActionType from EventTimers.csv (in EN: Action type )
   */
  public key_eventTimerEventActionType = "eventTimerEventActionType";
  /**
   * eventTimerEventActionType from EventTimers.csv (in EN: Action type )
   */
  public get eventTimerEventActionType(): string { return this.getStringDef(this.key_eventTimerEventActionType, "Action type"); }

  /**
   * eventTimerNewEvent from EventTimers.csv (in EN: New event )
   */
  public key_eventTimerNewEvent = "eventTimerNewEvent";
  /**
   * eventTimerNewEvent from EventTimers.csv (in EN: New event )
   */
  public get eventTimerNewEvent(): string { return this.getStringDef(this.key_eventTimerNewEvent, "New event"); }

  /**
   * eventTimerEventType from EventTimers.csv (in EN: Event Type )
   */
  public key_eventTimerEventType = "eventTimerEventType";
  /**
   * eventTimerEventType from EventTimers.csv (in EN: Event Type )
   */
  public get eventTimerEventType(): string { return this.getStringDef(this.key_eventTimerEventType, "Event Type"); }

  /**
   * eventTimerEventType_Import from EventTimers.csv (in EN: Import )
   */
  public key_eventTimerEventType_Import = "eventTimerEventType_Import";
  /**
   * eventTimerEventType_Import from EventTimers.csv (in EN: Import )
   */
  public get eventTimerEventType_Import(): string { return this.getStringDef(this.key_eventTimerEventType_Import, "Import"); }

  /**
   * eventTimerEventType_Export from EventTimers.csv (in EN: Export )
   */
  public key_eventTimerEventType_Export = "eventTimerEventType_Export";
  /**
   * eventTimerEventType_Export from EventTimers.csv (in EN: Export )
   */
  public get eventTimerEventType_Export(): string { return this.getStringDef(this.key_eventTimerEventType_Export, "Export"); }

  /**
   * eventTimerEventType_MySdWorxActivation from EventTimers.csv (in EN: MySDWorx users activation )
   */
  public key_eventTimerEventType_MySdWorxActivation = "eventTimerEventType_MySdWorxActivation";
  /**
   * eventTimerEventType_MySdWorxActivation from EventTimers.csv (in EN: MySDWorx users activation )
   */
  public get eventTimerEventType_MySdWorxActivation(): string { return this.getStringDef(this.key_eventTimerEventType_MySdWorxActivation, "MySDWorx users activation"); }

  /**
   * eventTimerEventType_MySdWorxSync from EventTimers.csv (in EN: MySDWorx users synchronization )
   */
  public key_eventTimerEventType_MySdWorxSync = "eventTimerEventType_MySdWorxSync";
  /**
   * eventTimerEventType_MySdWorxSync from EventTimers.csv (in EN: MySDWorx users synchronization )
   */
  public get eventTimerEventType_MySdWorxSync(): string { return this.getStringDef(this.key_eventTimerEventType_MySdWorxSync, "MySDWorx users synchronization"); }

  /**
   * eventTimerEventType_MySdWorxNewHire from EventTimers.csv (in EN: MySDWorx new user activation )
   */
  public key_eventTimerEventType_MySdWorxNewHire = "eventTimerEventType_MySdWorxNewHire";
  /**
   * eventTimerEventType_MySdWorxNewHire from EventTimers.csv (in EN: MySDWorx new user activation )
   */
  public get eventTimerEventType_MySdWorxNewHire(): string { return this.getStringDef(this.key_eventTimerEventType_MySdWorxNewHire, "MySDWorx new user activation"); }

  /**
   * eventTimerEventType_ComputeVariant from EventTimers.csv (in EN: Compute variant )
   */
  public key_eventTimerEventType_ComputeVariant = "eventTimerEventType_ComputeVariant";
  /**
   * eventTimerEventType_ComputeVariant from EventTimers.csv (in EN: Compute variant )
   */
  public get eventTimerEventType_ComputeVariant(): string { return this.getStringDef(this.key_eventTimerEventType_ComputeVariant, "Compute variant"); }

  /**
   * eventTimerEventType_ComputeVariantSelectVariant from EventTimers.csv (in EN: Select variant )
   */
  public key_eventTimerEventType_ComputeVariantSelectVariant = "eventTimerEventType_ComputeVariantSelectVariant";
  /**
   * eventTimerEventType_ComputeVariantSelectVariant from EventTimers.csv (in EN: Select variant )
   */
  public get eventTimerEventType_ComputeVariantSelectVariant(): string { return this.getStringDef(this.key_eventTimerEventType_ComputeVariantSelectVariant, "Select variant"); }

  /**
   * eventTimerEventType_ComputeVariantIncludeChildren from EventTimers.csv (in EN: Include children )
   */
  public key_eventTimerEventType_ComputeVariantIncludeChildren = "eventTimerEventType_ComputeVariantIncludeChildren";
  /**
   * eventTimerEventType_ComputeVariantIncludeChildren from EventTimers.csv (in EN: Include children )
   */
  public get eventTimerEventType_ComputeVariantIncludeChildren(): string { return this.getStringDef(this.key_eventTimerEventType_ComputeVariantIncludeChildren, "Include children"); }

  /**
   * eventTimerEventType_Unknown from EventTimers.csv (in EN: Unknown )
   */
  public key_eventTimerEventType_Unknown = "eventTimerEventType_Unknown";
  /**
   * eventTimerEventType_Unknown from EventTimers.csv (in EN: Unknown )
   */
  public get eventTimerEventType_Unknown(): string { return this.getStringDef(this.key_eventTimerEventType_Unknown, "Unknown"); }

  /**
   * eventTimerEvent_Connector from EventTimers.csv (in EN: Connector )
   */
  public key_eventTimerEvent_Connector = "eventTimerEvent_Connector";
  /**
   * eventTimerEvent_Connector from EventTimers.csv (in EN: Connector )
   */
  public get eventTimerEvent_Connector(): string { return this.getStringDef(this.key_eventTimerEvent_Connector, "Connector"); }

  /**
   * eventTimerDataSelectSpec from EventTimers.csv (in EN: Selected specification )
   */
  public key_eventTimerDataSelectSpec = "eventTimerDataSelectSpec";
  /**
   * eventTimerDataSelectSpec from EventTimers.csv (in EN: Selected specification )
   */
  public get eventTimerDataSelectSpec(): string { return this.getStringDef(this.key_eventTimerDataSelectSpec, "Selected specification"); }

  /**
   * eventTimerDataImportSpec from EventTimers.csv (in EN: Import specification )
   */
  public key_eventTimerDataImportSpec = "eventTimerDataImportSpec";
  /**
   * eventTimerDataImportSpec from EventTimers.csv (in EN: Import specification )
   */
  public get eventTimerDataImportSpec(): string { return this.getStringDef(this.key_eventTimerDataImportSpec, "Import specification"); }

  /**
   * eventTimerSaveSucces from EventTimers.csv (in EN: Save succesfull )
   */
  public key_eventTimerSaveSucces = "eventTimerSaveSucces";
  /**
   * eventTimerSaveSucces from EventTimers.csv (in EN: Save succesfull )
   */
  public get eventTimerSaveSucces(): string { return this.getStringDef(this.key_eventTimerSaveSucces, "Save succesfull"); }

  /**
   * eventTimerSaveFailed from EventTimers.csv (in EN: Save failed )
   */
  public key_eventTimerSaveFailed = "eventTimerSaveFailed";
  /**
   * eventTimerSaveFailed from EventTimers.csv (in EN: Save failed )
   */
  public get eventTimerSaveFailed(): string { return this.getStringDef(this.key_eventTimerSaveFailed, "Save failed"); }

  /**
   * eventTimerDataExportSpec from EventTimers.csv (in EN: Export specification )
   */
  public key_eventTimerDataExportSpec = "eventTimerDataExportSpec";
  /**
   * eventTimerDataExportSpec from EventTimers.csv (in EN: Export specification )
   */
  public get eventTimerDataExportSpec(): string { return this.getStringDef(this.key_eventTimerDataExportSpec, "Export specification"); }

  /**
   * eventTimerSelectedEventType from EventTimers.csv (in EN: Selected event type )
   */
  public key_eventTimerSelectedEventType = "eventTimerSelectedEventType";
  /**
   * eventTimerSelectedEventType from EventTimers.csv (in EN: Selected event type )
   */
  public get eventTimerSelectedEventType(): string { return this.getStringDef(this.key_eventTimerSelectedEventType, "Selected event type"); }

  /**
   * eventTimerWizard_Title from EventTimers.csv (in EN: Event timer configuration wizard )
   */
  public key_eventTimerWizard_Title = "eventTimerWizard_Title";
  /**
   * eventTimerWizard_Title from EventTimers.csv (in EN: Event timer configuration wizard )
   */
  public get eventTimerWizard_Title(): string { return this.getStringDef(this.key_eventTimerWizard_Title, "Event timer configuration wizard"); }

  /**
   * eventTimerWizard_TitleEdit from EventTimers.csv (in EN: Edit {0} )
   */
  public key_eventTimerWizard_TitleEdit = "eventTimerWizard_TitleEdit";
  /**
   * eventTimerWizard_TitleEdit from EventTimers.csv (in EN: Edit {0} )
   */
  public eventTimerWizard_TitleEdit(val1): string { return this.getStringDef("eventTimerWizard_TitleEdit", "Edit {0}").replace("{0}", val1); }

  /**
   * eventTimerExecuteNow from EventTimers.csv (in EN: Execute now )
   */
  public key_eventTimerExecuteNow = "eventTimerExecuteNow";
  /**
   * eventTimerExecuteNow from EventTimers.csv (in EN: Execute now )
   */
  public get eventTimerExecuteNow(): string { return this.getStringDef(this.key_eventTimerExecuteNow, "Execute now"); }

  /**
   * eventTimerQueuedSuccessfully from EventTimers.csv (in EN: Event execution queued successfully )
   */
  public key_eventTimerQueuedSuccessfully = "eventTimerQueuedSuccessfully";
  /**
   * eventTimerQueuedSuccessfully from EventTimers.csv (in EN: Event execution queued successfully )
   */
  public get eventTimerQueuedSuccessfully(): string { return this.getStringDef(this.key_eventTimerQueuedSuccessfully, "Event execution queued successfully"); }

  /**
   * eventTimerQueuingFailed from EventTimers.csv (in EN: Event queuing has failed )
   */
  public key_eventTimerQueuingFailed = "eventTimerQueuingFailed";
  /**
   * eventTimerQueuingFailed from EventTimers.csv (in EN: Event queuing has failed )
   */
  public get eventTimerQueuingFailed(): string { return this.getStringDef(this.key_eventTimerQueuingFailed, "Event queuing has failed"); }

  /**
   * eventTimerRefreshStatus from EventTimers.csv (in EN: Refresh )
   */
  public key_eventTimerRefreshStatus = "eventTimerRefreshStatus";
  /**
   * eventTimerRefreshStatus from EventTimers.csv (in EN: Refresh )
   */
  public get eventTimerRefreshStatus(): string { return this.getStringDef(this.key_eventTimerRefreshStatus, "Refresh"); }

  /**
   * Service_Model_DisplayName from IntegrationServices.csv (in EN: Model from Pointlogic HR )
   */
  public key_Service_Model_DisplayName = "Service_Model_DisplayName";
  /**
   * Service_Model_DisplayName from IntegrationServices.csv (in EN: Model from Pointlogic HR )
   */
  public get Service_Model_DisplayName(): string { return this.getStringDef(this.key_Service_Model_DisplayName, "Model from Pointlogic HR"); }

  /**
   * Service_Model_Description from IntegrationServices.csv (in EN: Services for the model databases )
   */
  public key_Service_Model_Description = "Service_Model_Description";
  /**
   * Service_Model_Description from IntegrationServices.csv (in EN: Services for the model databases )
   */
  public get Service_Model_Description(): string { return this.getStringDef(this.key_Service_Model_Description, "Services for the model databases"); }


}
