import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SDConnectProvisioningConfigurationDto } from "./dto/SDConnectProvisioningConfigurationDto-dto";

@Injectable({ providedIn: "root" })
export class SDWorxProvisioningConfigService extends PlBaseService {
  readonly endpoint = "sdprovisioningconfiguration";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveAllConfigs(): Observable<SDConnectProvisioningConfigurationDto[]> {
    return this.getData<SDConnectProvisioningConfigurationDto[]>(this.endpoint);
  }

  public createConfig(config: SDConnectProvisioningConfigurationDto): Observable<SDConnectProvisioningConfigurationDto> {
    return this.postData<SDConnectProvisioningConfigurationDto>(this.endpoint, config);
  }

  public updateConfig(sa: string, config: SDConnectProvisioningConfigurationDto): Observable<SDConnectProvisioningConfigurationDto> {
    return this.update<SDConnectProvisioningConfigurationDto>(config, this.endpoint + "/" + sa);
  }

  public deleteConfig(sa: string): Observable<any> {
    return this.remove(this.endpoint + "/" + sa);
  }
}
