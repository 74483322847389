<dx-form [formData]="selectStatistic" [colCount]="12">
  <dxi-item [colSpan]="7">
    <div>
      <dx-select-box
        [items]="metaData.statistics"
        searchEnabled="true"
        showClearButton="true"
        displayExpr="displayName"
        [value]="selectedStatistic"
        (onValueChanged)="onSelectionChanged($event.value)"
      ></dx-select-box>
    </div>
  </dxi-item>
  <dxi-item [colSpan]="4">
    <div>
      <dx-select-box [items]="metaData.operators" displayExpr="displayName" valueExpr="id" [(value)]="selectedStatisticOperator"></dx-select-box>
    </div>
  </dxi-item>
  <dxi-item [colSpan]="1">
    <dx-button [hint]="language.ButtonDelete" icon="fas fa-trash" [visible]="shouldShowDeleteButton" (onClick)="dashletDefinition.deleteStatistic(selectStatistic)"></dx-button>
  </dxi-item>
</dx-form>
