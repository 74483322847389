import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxContextMenuModule,
  DxDataGridModule,
  DxDrawerModule,
  DxDropDownBoxModule,
  DxFormModule,
  DxListModule,
  DxNumberBoxModule,
  DxPieChartModule,
  DxPivotGridModule,
  DxPopoverModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxSliderModule,
  DxTabPanelModule,
  DxTemplateModule,
  DxToolbarModule,
  DxTooltipModule,
} from "devextreme-angular";
import { DxoPointModule } from "devextreme-angular/ui/nested";
import { CardViewModule } from "../../../../../angular-common/components/cardview/cardview.module";
import { PlPeriodPickerModule } from "../../../../../angular-common/components/periodpicker/pl-periodpicker.module";
import { PlSliderModule } from "../../../../../angular-common/components/slider/pl-slider.module";
import { FilterServiceModule } from "../../services/filter/filterservice.module";
import { FilterModule } from "../../shared/filter/filter.module";
import { DashletChartComponent } from "./dashlet-chart.component";
import { DashletDataInfoComponent } from "./dashlet-data-info.component";
import { DashletDetailsComponent } from "./dashlet-details.component";
import { DashletGridComponent } from "./dashlet-grid.component";
import { DashletPieComponent } from "./dashlet-pie.component";
import { DashletPivotComponent } from "./dashlet-pivot.component";
import { DashletStatisticComponent } from "./dashlet-statistic.component";
import { DashletWarningComponent } from "./dashlet-warning.component";
import { DashletComponent } from "./dashlet.component";

@NgModule({
  imports: [
    CommonModule,
    DxChartModule,
    DxPieChartModule,
    DxTooltipModule,
    DxListModule,
    DxContextMenuModule,
    DxDataGridModule,
    DxDrawerModule,
    DxToolbarModule,
    DxPopupModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    CardViewModule,
    FilterModule,
    FilterServiceModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxTemplateModule,
    DxSliderModule,
    PlSliderModule,
    DxTabPanelModule,
    DxSelectBoxModule,
    PlPeriodPickerModule,
    DxFormModule,
    DxPopoverModule,
    DxPivotGridModule,
    DxoPointModule,
  ],
  declarations: [
    DashletComponent,
    DashletStatisticComponent,
    DashletChartComponent,
    DashletPieComponent,
    DashletGridComponent,
    DashletDetailsComponent,
    DashletPivotComponent,
    DashletDataInfoComponent,
    DashletWarningComponent,
  ],
  exports: [DashletComponent],
})
export class DashletModule {}
