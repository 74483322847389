import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PlSliderSettings } from "./plslidersettings";

@Component({
  selector: "app-pl-slider",
  templateUrl: "./pl-slider.component.html",
  styleUrls: ["./pl-slider.component.scss"],
})
export class PlSliderComponent implements OnInit {
  @Output() valueChanged = new EventEmitter(true);
  constructor() {}

  ngOnInit() {}

  editMode: boolean = false;

  onValueChanged() {
    this.valueChanged.emit(this.settings);
  }

  @Input() settings: PlSliderSettings;

  toggleEditMode() {
    this.editMode = !this.editMode;
  }
}
