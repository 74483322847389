import { Component, Input, OnInit } from "@angular/core";
import { DashboardDefinitionList, DashboardService } from "../../services/dashboard";
import { DataFilterList } from "../../services/filter";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashboarding-view",
  templateUrl: "./dashboarding-view.component.html",
  styleUrls: ["./dashboarding-view.component.scss"],
})
export class DashboardingViewComponent implements OnInit {
  public dashboards: DashboardDefinitionList;
  constructor(private dashboardService: DashboardService, public language: ImagineLanguage) {}

  ngOnInit() {
    this.dashboardService.getDashboards().subscribe((dashboards) => {
      this.dashboards = dashboards.dashboardDefinitions;
    });
  }

  @Input()
  public filterList: DataFilterList;

  public get dashboardsAvailable() {
    if (this.dashboards) {
      if (this.dashboards.dashboards.length > 0) {
        return true;
      }
    }
    return false;
  }
}
