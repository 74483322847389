import { PlLanguageService } from '../../../../angular-common';
import { Injectable } from '@angular/core';
import { WebLanguage } from '../../../../common/language/weblanguage.service'

@Injectable()
//t4Languages": "0.8.2.0
export class VismaRaetLanguage extends WebLanguage {
  public constructor(plLanguageService: PlLanguageService) {
    super(plLanguageService);
  }

  /**
   * menuVismaRaetFileApiConfiguration from VismaRaetWebApi.csv (in EN: VismaRaet File API configuration )
   */
  public key_menuVismaRaetFileApiConfiguration = "menuVismaRaetFileApiConfiguration";
  /**
   * menuVismaRaetFileApiConfiguration from VismaRaetWebApi.csv (in EN: VismaRaet File API configuration )
   */
  public get menuVismaRaetFileApiConfiguration(): string { return this.getStringDef(this.key_menuVismaRaetFileApiConfiguration, "VismaRaet File API configuration"); }

  /**
   * vismaFileApiConfigurationClientId from VismaRaetWebApi.csv (in EN: Client ID )
   */
  public key_vismaFileApiConfigurationClientId = "vismaFileApiConfigurationClientId";
  /**
   * vismaFileApiConfigurationClientId from VismaRaetWebApi.csv (in EN: Client ID )
   */
  public get vismaFileApiConfigurationClientId(): string { return this.getStringDef(this.key_vismaFileApiConfigurationClientId, "Client ID"); }

  /**
   * vismaFileApiConfigurationClientSecret from VismaRaetWebApi.csv (in EN: Client secret )
   */
  public key_vismaFileApiConfigurationClientSecret = "vismaFileApiConfigurationClientSecret";
  /**
   * vismaFileApiConfigurationClientSecret from VismaRaetWebApi.csv (in EN: Client secret )
   */
  public get vismaFileApiConfigurationClientSecret(): string { return this.getStringDef(this.key_vismaFileApiConfigurationClientSecret, "Client secret"); }

  /**
   * vismaFileApiConfigurationTenantId from VismaRaetWebApi.csv (in EN: Tenant ID )
   */
  public key_vismaFileApiConfigurationTenantId = "vismaFileApiConfigurationTenantId";
  /**
   * vismaFileApiConfigurationTenantId from VismaRaetWebApi.csv (in EN: Tenant ID )
   */
  public get vismaFileApiConfigurationTenantId(): string { return this.getStringDef(this.key_vismaFileApiConfigurationTenantId, "Tenant ID"); }

  /**
   * vismaFileApiConfigurationValidationClientId from VismaRaetWebApi.csv (in EN: Client ID is required )
   */
  public key_vismaFileApiConfigurationValidationClientId = "vismaFileApiConfigurationValidationClientId";
  /**
   * vismaFileApiConfigurationValidationClientId from VismaRaetWebApi.csv (in EN: Client ID is required )
   */
  public get vismaFileApiConfigurationValidationClientId(): string { return this.getStringDef(this.key_vismaFileApiConfigurationValidationClientId, "Client ID is required"); }

  /**
   * vismaFileApiConfigurationValidationClientSecret from VismaRaetWebApi.csv (in EN: Client secret is required )
   */
  public key_vismaFileApiConfigurationValidationClientSecret = "vismaFileApiConfigurationValidationClientSecret";
  /**
   * vismaFileApiConfigurationValidationClientSecret from VismaRaetWebApi.csv (in EN: Client secret is required )
   */
  public get vismaFileApiConfigurationValidationClientSecret(): string { return this.getStringDef(this.key_vismaFileApiConfigurationValidationClientSecret, "Client secret is required"); }

  /**
   * vismaFileApiConfigurationValidationTenantId from VismaRaetWebApi.csv (in EN: Tenant ID is required )
   */
  public key_vismaFileApiConfigurationValidationTenantId = "vismaFileApiConfigurationValidationTenantId";
  /**
   * vismaFileApiConfigurationValidationTenantId from VismaRaetWebApi.csv (in EN: Tenant ID is required )
   */
  public get vismaFileApiConfigurationValidationTenantId(): string { return this.getStringDef(this.key_vismaFileApiConfigurationValidationTenantId, "Tenant ID is required"); }

  /**
   * vismaFileApiConfigurationConfirmDelete from VismaRaetWebApi.csv (in EN: Delete configuration? )
   */
  public key_vismaFileApiConfigurationConfirmDelete = "vismaFileApiConfigurationConfirmDelete";
  /**
   * vismaFileApiConfigurationConfirmDelete from VismaRaetWebApi.csv (in EN: Delete configuration? )
   */
  public get vismaFileApiConfigurationConfirmDelete(): string { return this.getStringDef(this.key_vismaFileApiConfigurationConfirmDelete, "Delete configuration?"); }


}
