<div *ngIf="mutations">
  <dx-button icon="fas fa-plus" (onClick)="newMutationPopup()"></dx-button>
  <dx-tab-panel #tabPanel [dataSource]="mutations" [selectedIndex]="0" [animationEnabled]="true">
    <div *dxTemplate="let slider of 'title'">
      <span> {{ getTitle(slider) }} </span>
    </div>
    <div *dxTemplate="let slider of 'item'">
      <dx-button [disabled]="parameter.mutations.length <= 1" icon="fas fa-trash" (onClick)="removeMutation(slider)">
      </dx-button>
      <app-pl-slider [settings]="slider" (valueChanged)="onValueChanged($event)"></app-pl-slider>
    </div>
  </dx-tab-panel>
  <app-pl-periodpicker [interaction]="datePicker"></app-pl-periodpicker>
</div>