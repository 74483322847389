import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ImportDefinition } from "../../services/import";
import { ImportLanguage } from "../../services/language/connectorlanguage.service";

@Component({
  selector: "app-data-supply-period-selector",
  templateUrl: "data-supply-period-selector.component.html",
  styleUrls: ["data-supply-period-selector.component.scss"],
})
export class DataSupplyPeriodSelectorComponent implements OnInit {
  constructor(public language: ImportLanguage) {}

  public _currentDate: Date;

  @Input() importDefinition: ImportDefinition;

  ngOnInit() {
    this._currentDate = new Date();
  }

  public onDateChange() {
    this.mutationDateChanged.emit(this._currentDate);
  }

  @Output()
  public mutationDateChanged: EventEmitter<Date> = new EventEmitter<Date>(true);
}
