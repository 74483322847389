
// t4tsCodeFromCs": "0.8.2.0 for file StackType.cs
// Don't adjust manually!
export enum StackTypeDto {
        Unknown = -1,
        ExactValue = 0,
        Interval = 1,
        ExactReference = 2,
}

export class StackTypesDto {
    public static ConcreteTypes: StackTypeDto [] = [];
}

