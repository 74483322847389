<div class="content">
  <div class="fileUploaderPerFileDefinition" *ngIf="import">
    <dx-form [colCount]="2">
      <dxi-item [colSpan]="1">
        <div class="filename-container">
          <span class="file-text" (mouseenter)="toggleHover()" (mouseleave)="toggleHover()" id="file-{{ fileId }}">{{ import.name }}</span>
          <dx-popover target="#file-{{ fileId }}" position="top" width="auto" [(visible)]="hoverOver">
            <dxo-animation>
              <dxo-show type="fadeIn" [delay]="500" [from]="{ scale: 0 }" [to]="{ scale: 1 }"></dxo-show>
              <dxo-hide type="fade" [from]="1" [to]="0"></dxo-hide>
            </dxo-animation>
            <div *dxTemplate="let data = model; of: 'content'">
              {{ import.name }}
            </div>
          </dx-popover>
        </div>
      </dxi-item>
      <dxi-item [colSpan]="1">
        <div *dxTemplate>
          <dx-file-uploader
            accept="*"
            [multiple]="import.allowMultiple"
            (onUploaded)="onUploaded($event)"
            (onValueChanged)="onValueChanged($event)"
            uploadMode="useButtons"
            [(value)]="filesForUpload"
            [uploadUrl]="fileSpecification.uploadUri"
            [uploadHeaders]="fileSpecification.header"
            [maxFileSize]="maxFileSizeBytes"
            [invalidMaxFileSizeMessage]="maxFileSizeWarningMessage"
          >
          </dx-file-uploader></div
      ></dxi-item>
    </dx-form>
  </div>
</div>
