import { CompareTypeDto } from "../../../../../../../common/models/dto/CompareType-dto";
import { CompareTypeItemDto } from "../../../../models/dto/CompareTypeItemDto-dto";

export class CompareType {
  public static copyFromDto(compareTypeDto: CompareTypeItemDto): CompareType {
    const compareType = new CompareType();

    compareType.id = compareTypeDto.Id;
    compareType.name = compareTypeDto.Name;

    return compareType;
  }

  public static copyFromDtoEnum(comparDto: CompareTypeDto): CompareType {
    const compareType = new CompareType();

    compareType.id = comparDto;
    compareType.name = "CompareType:" + comparDto;

    return compareType;
  }

  public static toDto(compareType: CompareType): CompareTypeItemDto {
    const newDto = new CompareTypeItemDto();

    newDto.Id = compareType.id;
    newDto.Name = compareType.name;

    return newDto;
  }

  name: string;
  id: number;
}
