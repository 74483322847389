import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DxButtonModule, DxSelectBoxModule } from "devextreme-angular";
import { SelectBoxComponent } from "./selectbox.component";

@NgModule({
  imports: [CommonModule, DxSelectBoxModule, DxButtonModule],
  declarations: [SelectBoxComponent],
  exports: [SelectBoxComponent],
})
export class SelectBoxModule {}
