<app-cardview class="dashlet-details" *ngIf="hasData">
  <div class="inner-container fix-height-l">
    <dx-data-grid
      id="gridContainer"
      [dataSource]="rowData"
      redrawOnResize="true"
      [rowAlternationEnabled]="true"
      height="100%"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      (onInitialized)="onInitialized($event)"
      [customizeColumns]="hideDummyColumn"
      (onExporting)="onExporting($event)"
    >
      <dxi-column dataField="ThisColumnIsOnlyToForceGeneration" caption="Empty"></dxi-column>
      <dxo-header-filter visible="True"></dxo-header-filter>
      <dxo-filter-row visible="true"></dxo-filter-row>
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true" horizontalAlignment="left"></dxo-search-panel>
      <dxo-export [enabled]="true" [fileName]="dashletView.name" [customizeExcelCell]="configureExport" [allowExportSelectedData]="false"></dxo-export>
      <dxo-summary visible="true" [totalItems]="gridTotalColumns"></dxo-summary>
    </dx-data-grid>
  </div>
  <app-dashlet-warning [dashletView]="dashletView"></app-dashlet-warning>
</app-cardview>
