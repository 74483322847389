<div>
  <div class="grid-management-container">
    <div>
      <dx-button [text]="language.sdWorxProvisioningMessageStatusRefreshButton" icon="refresh" (onClick)="refreshMessagesStatus()"></dx-button>
    </div>
    <div>
      <div class="date-selection-container">
        <span class="text">{{language.messageFilterStartDate}}</span>
        <dx-date-box class="date" [(value)]="minDate" type="date"> </dx-date-box>
      </div>
      <div class="spacer"></div>
      <div class="date-selection-container">
        <span class="text">{{language.messageFilterEndDate}}</span>
        <dx-date-box [(value)]="maxDate" type="date"> </dx-date-box>
      </div>
    </div>
  </div>
  <div>
    <app-toaster [model]="toasterInput"></app-toaster>
  </div>
  <div>
    <dx-data-grid [dataSource]="allMessageStatuses" [columnAutoWidth]="true">
      <dxo-editing [allowUpdating]="false" [allowAdding]="false" useIcons="false"></dxo-editing>
      <dxo-filter-row [visible]="true"></dxo-filter-row>

      <dxi-column allowEditing="false" caption="{{ language.sdWorxProvisioningMessageStatusTaskId }}" dataField="TaskId" [sortIndex]="0" sortOrder="asc"> </dxi-column>
      <dxi-column caption="{{ language.sdWorxProvisioningMessageStatusActionType }}" dataField="ActionType"> </dxi-column>
      <dxi-column caption="{{ language.sdWorxProvisioningMessageStatusActionId }}" dataField="ActionId"> </dxi-column>
      <dxi-column caption="{{ language.sdWorxProvisioningMessageStatusCorrelationId }}" dataField="CorrelationId"> </dxi-column>
      <dxi-column caption="{{ language.sdWorxProvisioningMessageStatusLastKnownStatus }}" dataField="LastKnownStatus"> </dxi-column>
      <dxi-column caption="{{ language.sdWorxProvisioningMessageStatusDate }}" dataField="StatusDate"></dxi-column>
    </dx-data-grid>
  </div>
</div>
