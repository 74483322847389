import { ModelTreeChangeType } from "./modeltree-change"


export class ModelTreeChangeModel{
    type: ModelTreeChangeType;
    Id: number | undefined;
    

    constructor(type: ModelTreeChangeType, Id: number | null){
        this.type = type;
        this.Id = Id;
    }
}