import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../..";
import { ModelTreeBranchDto } from "../../../../common/models/dto/ModelTreeBranch-dto";
import { ModelTreeBranch } from "./models/modeltree-branch";
import { ModelTreeBuilder } from "./models/modeltree-builder";

@Injectable()
export class ModelTreeService extends PlBaseService {
  private endPoint: string = "modeltree";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getModelTree(): Observable<ModelTreeBranch> {
    return this.getDataTransformed<ModelTreeBranchDto>(this.endPoint).pipe(map(r => this.transformData(r)));
  }

  private transformData(dto: ModelTreeBranchDto): ModelTreeBranch {
    if (dto) {
      const builder = new ModelTreeBuilder(this);
      return builder.buildTree(dto);
    } else {
      return null;
    }
  }
}
