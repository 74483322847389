<dx-popup [showTitle]="true" [width]="1050" height="auto" [hideOnOutsideClick]="false" [(visible)]="visible" [showCloseButton]="false">
  <app-dashlet [dashletView]="dashletView" [filterList]="filterList"></app-dashlet>

  <dx-form id="form">
    <dxi-item itemType="group" class="form-group">
      <!-- Type -->
      <dxi-item cssClass="alignRadioButtonLabel" dataField="Type" *ngIf="showTypeSelector">
        <dxo-label text="{{ language.typeHeader }}"></dxo-label>
        <div *dxTemplate>
          <app-selectbox [items]="typeSelector"> </app-selectbox>
        </div>
      </dxi-item>

      <!-- Chart Selecter -->
      <dxi-item dataField="ShowPivotChart" *ngIf="showPivotChartSelector">
        <dxo-label [text]="language.dashletShowPivotChart"></dxo-label>
        <div *dxTemplate>
          <dx-check-box [(value)]="dashletView.dashletViewSettings.showPivotChart" [min]="-100" [max]="100"></dx-check-box>
        </div>
      </dxi-item>

      <!-- Colors -->
      <dxi-item dataField="Colors" *ngIf="showColorSelector">
        <dxo-label [text]="language.colorHeader"></dxo-label>
        <div *dxTemplate>
          <dx-select-box [items]="palettes.definitions" displayExpr="name" valueExpr="paletteName" [(value)]="dashletView.paletteName" itemTemplate="clrpicker">
            <div *dxTemplate="let data of 'clrpicker'">
              <div>
                <div class="paletteName">
                  {{ data.name }}
                </div>
                <div *ngFor="let clr of data.colors" class="colorblock" [ngStyle]="{ 'background-color': clr }"></div>
              </div>
            </div>
          </dx-select-box>
        </div>
      </dxi-item>
    </dxi-item>
  </dx-form>

  <div class="button-container bottom-container align-right">
    <dx-button
      [text]="language.dashletViewDesignSaveChanges"
      icon="save"
      type="default"
      [hint]="language.dashletViewDesignSaveChanges"
      (onClick)="save()"
      [disabled]="!canSave"
      class="button"
      [visible]="userWithDataAnalysisDesignRole"
    ></dx-button>
    <dx-button [text]="language.MenuClose" icon="close" type="default" [hint]="language.MenuClose" (onClick)="close()" class="button"></dx-button>
  </div>
</dx-popup>
