import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import { confirm } from "devextreme/ui/dialog";
import { ToasterInput } from "../../../../angular-common/components/toaster/toaster-input-model";
import { ElementHelper } from "../../../../angular-common/helpers/element.helper";
import { DateFormat } from "../../../../angular-common/language/date-format";
import { SDConnectUserDto } from "./dto/SDConnectUserDto-dto";
import { SDConnectUserInformationDto } from "./dto/SDConnectUserInformationDto-dto";
import { SDConnectUserTypeDto } from "./dto/SDConnectUserType-dto";
import { ISDConnectUserTypeSelection } from "./models/ISDConnectUserTypeSelection";
import { SDConnectUser } from "./models/SDConnectUser";
import { SDConnectUserTypeTranslator } from "./models/SDConnectUserTypeTranslator";
import { SDWorxProvisioningUserService } from "./sdworx-provisioning-user.service";
import { SDWorxLanguage } from "./sdworxlanguage.service";

@Component({
  selector: "app-sdworx-provisioning-user-screen",
  templateUrl: "./sdworx-provisioning-user-screen.component.html",
  styleUrls: ["./sdworx-provisioning-user-screen.component.scss"],
})
export class SDWorxProvisioningUserScreenComponent {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  constructor(private route: ActivatedRoute, public language: SDWorxLanguage, private usersService: SDWorxProvisioningUserService) {
    this.sdConnectUserTypeTranslator = new SDConnectUserTypeTranslator(this.language);
    this.route.data.subscribe((data: { users: SDConnectUserInformationDto[] }) => {
      this.users = data.users;
    });

    this.toasterInput = new ToasterInput();
    this.buildUserTypeSelectionItems();

    this.calculateUserTypeCellValue = this.calculateUserTypeCellValue.bind(this);
  }

  public userTypeSelectionItems: ISDConnectUserTypeSelection[] = [];
  public selectedUserType: ISDConnectUserTypeSelection;

  public readonly periodDisplayFormat: string = DateFormat.DateByUserLanguage();
  public readonly statusDateDisplayFormat: string = DateFormat.DateTimeByUserLanguage();
  public readonly dateSerializationFormat: string = "yyyy-MM-dd";

  public sdConnectUserTypeTranslator: SDConnectUserTypeTranslator;

  public addNewUserPopUpVisible: boolean = false;

  public users: SDConnectUserInformationDto[];

  public newUserData: SDConnectUser = new SDConnectUser();

  public onUpdate(userData: SDConnectUserDto) {
    if (ElementHelper.isNullOrUndefined(userData)) {
      return;
    }

    this.usersService.updatePeriod(userData.Id, userData.Period).subscribe((data) => {
      const userIndexInArray = this.users.findIndex((u) => u.User.Id === userData.Id);

      if (userIndexInArray > -1) {
        this.users[userIndexInArray].User = data;
      }
    });
  }

  public onAddUser() {
    this.newUserData = new SDConnectUser();
    this.addNewUserPopUpVisible = true;
  }

  public onStoreUser() {
    this.addNewUserPopUpVisible = false;
    const userDto = SDConnectUser.toDto(this.newUserData);

    this.usersService.storeUser(userDto).subscribe((userData) => {
      const userInformation = new SDConnectUserInformationDto();
      userInformation.User = userData;

      this.users.push(userInformation);
    });
  }

  public onCancel() {
    this.newUserData = new SDConnectUser();
    this.addNewUserPopUpVisible = false;
  }

  public toasterInput: ToasterInput;

  public onSync() {
    const scheduleUsersActivation = confirm(this.language.sdWorxProvisioningUsersScheduleActivationQuestion, this.language.menuSDWorxProvisioningUsers);

    scheduleUsersActivation.then((confirmationResult) => {
      if (confirmationResult) {
        this.usersService.sync().subscribe(() => {
          this.toasterInput.showSucces(this.language.sdWorxProvisioningUsersActivationScheduled);
        });
      }
    });
  }

  public canStoreUser: boolean = false;

  public onFormFieldDataChanged(event: any) {
    let result = event.component.validate();
    if (result.brokenRules.length >= 1) {
      this.canStoreUser = false;
    } else {
      this.canStoreUser = true;
    }
  }

  public buildUserTypeSelectionItems() {
    var userTypeValues = this.sdConnectUserTypeTranslator.values;

    userTypeValues.forEach((userType) => {
      if (userType.value === SDConnectUserTypeDto.Unknown) {
        return;
      }
      this.userTypeSelectionItems.push({ id: userType.value, name: userType.text });
    });

    this.userTypeSelectionItems.push(this.createCustomUserTypeSelectionItem());

    this.setDefaultUserTypeSelection();
  }

  private setDefaultUserTypeSelection() {
    this.selectedUserType = this.userTypeSelectionItems.find((t) => t.id === SDConnectUserTypeDto.User);

    if (ElementHelper.isNullOrUndefined(this.selectedUserType)) {
      this.selectedUserType = this.createCustomUserTypeSelectionItem();
    }
  }

  private createCustomUserTypeSelectionItem(): ISDConnectUserTypeSelection {
    return { id: -1, name: this.language.sdWorxProvisioningUsersUserTypeAll };
  }

  public onUserTypeSelected(selectedType: any) {
    if (selectedType && selectedType.itemData) {
      const userType = selectedType.itemData as ISDConnectUserTypeSelection;

      switch (userType.id) {
        case SDConnectUserTypeDto.User:
          this.usersService.retrieveUsers().subscribe((data) => {
            this.users = data;
          });
          break;

        case SDConnectUserTypeDto.Employee:
          this.usersService.retrievEemployees().subscribe((data) => {
            this.users = data;
          });
          break;

        default:
          this.usersService.retrieveAll().subscribe((data) => {
            this.users = data;
          });
          return;
      }
    }
  }

  public calculateUserTypeCellValue(rowData: SDConnectUserInformationDto) {
    if (ElementHelper.isNullOrUndefined(rowData)) {
      return this.sdConnectUserTypeTranslator.toText(SDConnectUserTypeDto.Unknown);
    }

    return this.sdConnectUserTypeTranslator.toText(rowData.User.UserType);
  }

  public disableEditingForEmployeeTypeOfUser(event: any) {
    if (event.rowType === "data") {
      const userData = event.data as SDConnectUserInformationDto;

      if (ElementHelper.isNullOrUndefined(userData)) {
        return;
      }

      if (userData.User.UserType === SDConnectUserTypeDto.Employee) {
        const gridVisibleColumns = this.dataGrid.instance.getVisibleColumns();
        const editColumnIndex = gridVisibleColumns.findIndex((c) => c.type === "buttons" && (c as any).command === "edit");
        if (editColumnIndex > -1 && event.columnIndex == editColumnIndex) {
          event.cellElement.querySelector(".dx-link-edit").remove();
        }
      }
    }
  }
}
