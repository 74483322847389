import { ClusterFieldDto } from "../../../analysis/dto/ClusterField-dto";
import { ClusterNamingDto } from '../../../analysis/dto/ClusterNaming-dto';
import { DataSourceClusterFieldDto } from '../../../analysis/dto/DataSourceClusterField-dto';

export class ClusterField {
  public constructor() {}

  public name: string;
  public shortName: string;
  public value: string;
  public valueId: number;
  public isDataSourceClusterComparingTo: boolean;
  public isDataSourceCluster: boolean;

  public copyFromDTO(dto: ClusterFieldDto) {
    this.name = dto.Name;
    this.shortName = dto.ShortName;
    this.value = dto.Value;
    this.valueId = dto.ValueId;

    this.isDataSourceCluster = this.shortName === ClusterNamingDto.SourceId;
    
    this.isDataSourceClusterComparingTo = false;
    if (this.isDataSourceCluster) {
      const dataSourceClusterDto = (dto as DataSourceClusterFieldDto);
      if (dataSourceClusterDto && dataSourceClusterDto.IsComparisonSource) {
        this.isDataSourceClusterComparingTo = true;
      }
    }
  }
}
