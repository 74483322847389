import { Component, Input, OnInit } from "@angular/core";

import { confirm } from "devextreme/ui/dialog";
import { PeriodPickerInteraction } from "../../../../../../angular-common/components/periodpicker/periodpicker-interaction";
import { TimePeriodDto } from "../../../../../../common/models/dto/TimePeriodDto-dto";
import { Parameter } from "../../../services/dashboard/models/parameter";
import { ParameterMutation } from "../../../services/dashboard/models/parametermutations";
import { ReferenceCategorie } from "../../../services/dashboard/models/referencecategorie";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { ParameterEditService } from "../../../services/parameteredit/parameteredit.service";

@Component({
  selector: "app-reference-parameter",
  templateUrl: "./reference-parameter.component.html",
  styleUrls: ["./reference-parameter.component.scss"],
})
export class ReferenceParameterComponent implements OnInit {
  public datePicker: PeriodPickerInteraction;

  constructor(private parameterService: ParameterEditService, private language: ImagineLanguage) {}

  ngOnInit(): void {
    this.datePicker = new PeriodPickerInteraction(this.parameter.longName, this.dates);
    this.datePicker.confirmPeriod.subscribe((x) => this.addNewMutation());
  }

  newMutationPopup() {
    this.datePicker.isVisible = true;
  }

  addNewMutation() {
    const date = this.datePicker.selectedPeriod;
    if (this.parameter.mutations.length === 0) {
      this.parameter.mutations.push(ParameterMutation.createDefault());
    }
    const copy = this.parameter.mutations[this.parameter.mutations.length - 1].copyWithNewDate(date);
    this.parameter.mutations.push(copy);
  }

  public removeMutation(mutation: ParameterMutation) {
    if (this.parameter.mutations.length <= 1) {
      return;
    }
    confirm(this.language.confirmDeleteTitle, this.language.confirmDeleteHeader).then((answer) => {
      if (answer === true) {
        this.parameterService.deleteParameterMutation(this.simulationVariantId, this.parameter, mutation).subscribe(() => {
          this.parameter.mutations.splice(
            this.parameter.mutations.findIndex((x) => x.keyStartDate === mutation.keyStartDate),
            1,
          );
        });
      }
    });
  }

  public getValue(data: ParameterMutation) {
    return this.getReferences(this.parameter).find((x) => x.id === data.value);
  }

  public getReferences(parameter: Parameter) {
    const referenceOptions = this.referenceCategories.find((x) => x.categoryId === parameter.referenceCategoryId).options;
    if (referenceOptions !== null) {
      return referenceOptions;
    } else {
      return [];
    }
  }

  @Input() parameter: Parameter;
  @Input() referenceCategories: ReferenceCategorie[];
  @Input() dates: TimePeriodDto[];
  @Input() simulationVariantId: number;
}
