import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IntegrationServiceLanguage } from "../../../../common/language/integrationservicelanguage.service";
import { EventTimerInformationDto } from "../../../../common/models/integrationservices/dto/EventTimerInformationDto-dto";
import { ImagineFeaturesModel } from "../imagine-features-model";
import { EventTimerInformation } from "./models/event-timer-information";

@Injectable({ providedIn: "root" })
export class EventTimerService extends PlBaseService {
  readonly endpoint = "eventtimer";
  readonly endpointExecute = `${this.endpoint}/execute`;

  constructor(
    http: HttpClient,
    envSettings: EnvironmentSettings,
    public loadIndicator: PlLoadIndicator,
    private integrationLanguage: IntegrationServiceLanguage,
    private featureSettings: ImagineFeaturesModel,
  ) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveAllEvents(): Observable<EventTimerInformation[]> {
    return this.getDataTransformed<EventTimerInformationDto[]>(this.endpoint).pipe(map((r) => this.translateAllEvents(r)));
  }

  public retrieveEventTimerDefinition(actionType: string): Observable<EventTimerInformation> {
    const url = `${this.endpoint}/${actionType}`;
    return this.getDataTransformed<EventTimerInformationDto>(url).pipe(map((r) => this.translateSingleEvent(r)));
  }

  public saveEventTimer(eventTimer: EventTimerInformation): Observable<EventTimerInformation> {
    const dto = eventTimer.toDto();
    if (eventTimer.isNew) {
      return this.postDataTransformed<EventTimerInformationDto, EventTimerInformation>(this.endpoint, dto, (r) => this.translateSingleEvent(r.body));
    } else {
      return this.updateEventFrom(dto);
    }
  }

  public executeTimer(eventTimer: EventTimerInformation): Observable<EventTimerInformation> {
    const dto = eventTimer.toDto();
    return this.postDataTransformed<EventTimerInformationDto, EventTimerInformation>(this.endpointExecute, dto, (r) => this.translateSingleEvent(r.body));
  }

  public updateEventFrom(dto: EventTimerInformationDto): Observable<EventTimerInformation> {
    return this.updateTransformedResponse<EventTimerInformationDto>(dto, this.endpoint).pipe(map((r) => this.translateSingleEvent(r)));
  }

  public deleteEventTimer(id: number): Observable<void> {
    const url = `${this.endpoint}/${id}`;
    return this.remove(url);
  }

  public retrieveEventWithId(id: number): Observable<EventTimerInformation> {
    const url = this.endpoint + "/" + id;
    return this.getDataTransformed<EventTimerInformationDto>(url).pipe(map((r) => this.translateSingleEvent(r)));
  }

  translateAllEvents(dto: EventTimerInformationDto[]): EventTimerInformation[] {
    if (dto) {
      const events: EventTimerInformation[] = [];
      dto.forEach((dtoEvent) => {
        const newEvent = this.translateSingleEvent(dtoEvent);
        events.push(newEvent);
      });
      return events;
    }
  }

  translateSingleEvent(dto: EventTimerInformationDto): EventTimerInformation {
    if (dto) {
      const eventInfo = new EventTimerInformation(this.integrationLanguage, this.featureSettings);
      eventInfo.fromDto(dto);
      return eventInfo;
    }
  }
}
