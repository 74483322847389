import { DataMutationsMutationDataDto } from "../../../adapt/dto/DataMutationsMutationData-dto";
import { ColumnItems } from "./column-items.model";
import { MutationDataRow } from "./mutation-data-row.model";

export class DataMutationsMutationData {

  // Creates a new row (without adding it to the array) and initializes it with default values (it takes
  // the row with the highest date and copies its values).
  // This is a static method that takes as input an array of existing rows. This is because we always take
  // the latest values from the DevExpress grid which may have been edited.
  public static createNewRow(
    clientIdentification: any,
    currentRows: MutationDataRow[],
  ): MutationDataRow {
    const result = new MutationDataRow();

    // Find the latest row
    let latest: MutationDataRow;
    currentRows.forEach(r => {
      if (
        r.startdate !== undefined &&
        (latest === undefined || r.startdate > latest.startdate)
      ) {
        latest = r;
      }
    });

    if (latest) {
      MutationDataRow.copyProperties(latest, result);
      let newDate: Date;
      if (result.startdate !== undefined) {
        newDate = new Date(result.startdate);
        newDate.setDate(newDate.getDate() + 1);
      } else {
        newDate = new Date(Date.now());
      }
      result.startdate = new Date(
        Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()),
      );
    }

    result.identification = clientIdentification;
    result.persontype = 1;
    result.canEdit = true;

    return result;
  }
  public constructor() { }

  public copyFromDto(dto: DataMutationsMutationDataDto) {
    const newRows: MutationDataRow[] = [];
    dto.Rows.forEach(r => {
      const newRow = new MutationDataRow();
      newRow.copyFromDto(r);
      newRows.push(newRow);
    });

    this.rows = newRows;
  }

  public rows: MutationDataRow[] = [];

  // Checks each row for invalid reference values
  public adjustInvalidReferences(columns: ColumnItems) {
    this.rows.forEach(row => {
      columns.all.forEach(col => {
        if (col.lookup.isValidLookup) {
          const lookupItem = col.lookup.getLookupItem(row[col.dataField]);
          if (lookupItem === undefined || lookupItem === null) {
            row[col.dataField] = undefined;
          }
        }
      });
    });
  }
}
