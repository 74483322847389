import { IMove } from "../../../../../../angular-common/components/listselector/imove";
import { DashletDefinition } from "../../../services/dashletdefinition/models/dashlet-definition/dashlet-definition.model";
import { Statistic } from "../../../services/dashletdefinition/models/metadata/statistic.model";

export class DashletDefinitionMove implements IMove {
  constructor(private dashletDefinition: DashletDefinition) {}

  moveLeft(selectedItems: Statistic[]): boolean {
    if (selectedItems && selectedItems.length > 0) {
      this.dashletDefinition.changes.statistics = this.dashletDefinition.changes.statistics.filter(stat => {
        const optionIsSelected = selectedItems.filter((newstat: Statistic) => newstat.shortName === stat.shortName);
        return optionIsSelected.length === 0;
      });
      return true;
    }
  }

  moveRight(selectedItems: Statistic[]): boolean {
    if (selectedItems && selectedItems.length > 0) {
      selectedItems.forEach(itemToSelect => {
        if (this.dashletDefinition.changes.statistics.includes(itemToSelect) === false) {
          this.dashletDefinition.changes.statistics.push(itemToSelect);
        }
      });
      return true;
    }
  }
}
