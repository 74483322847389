import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxBoxModule, DxButtonModule, DxFormModule, DxPopupModule, DxProgressBarModule, DxTabsModule } from "devextreme-angular";
import { ConfigWizardStageScreenComponent } from "./screens/config-wizard-stage-screen.component";
import { ConfigurationWizardScreenComponent } from "./screens/configuration-wizard-screen.component";

@NgModule({
  imports: [CommonModule, DxTabsModule, DxFormModule, DxProgressBarModule, DxBoxModule, DxPopupModule, DxButtonModule],
  exports: [ConfigurationWizardScreenComponent],
  declarations: [ConfigurationWizardScreenComponent, ConfigWizardStageScreenComponent],
  providers: [],
})
export class ConfigurationWizardModule {}
