import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { AdaptConfigurationDto } from "./dto/AdaptConfiguration-dto";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ModelGroupDto } from "./dto/ModelGroupDto-dto";
import { ModelsGroup } from "./models/modelgroups-group.model";
import { ModelGrouping as ModelsGrouping } from "./models/modelgroups-grouping.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AdaptGroupsEditService extends PlBaseService {
  private endPoint: string = "adaptgrouping";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getModelGroups(): Observable<ModelsGrouping> {
    const result = this.getDataTransformed<AdaptConfigurationDto>(this.endPoint).pipe(map((r) => this.transformData(r)));
    return result;
  }

  private transformData(modelGroupingDto: AdaptConfigurationDto): ModelsGrouping {
    if (modelGroupingDto) {
      const result = new ModelsGrouping();
      result.copyFromConfigDto(modelGroupingDto);
      return result;
    } else {
      return null;
    }
  }

  private transformDataSingle(groupDto: ModelGroupDto): ModelsGroup {
    if (groupDto) {
      const result = new ModelsGroup();
      result.copyFromDto(groupDto);
      return result;
    } else {
      return null;
    }
  }

  public saveModelGroups(modelGroup: ModelsGroup): Observable<ModelsGroup> {
    const url = this.endPoint + "/" + modelGroup.groupId;
    const dto = modelGroup.toDto();
    return this.update<ModelGroupDto>(dto, url).pipe(map((r) => this.transformDataSingle(r)));
  }
}
