import { ReplaySubject } from "rxjs";
import { TimePeriodDto } from "../../../common/models/dto/TimePeriodDto-dto";

export class PeriodPickerInteraction {
  private _isVisible: boolean = false;
  public confirmed: boolean = false;
  // When true, the confirmedChanged is only fired when the user pressed 'ok'
  public ignoreCancelOnConfirmedChanged = true;
  private _confirmedChangedPeriod = new ReplaySubject<boolean>();
  public confirmPeriod = this._confirmedChangedPeriod.asObservable();

  private _selectedPeriod: TimePeriodDto;
  public get selectedPeriod(): TimePeriodDto {
    return this._selectedPeriod;
  }
  public set selectedPeriod(v) {
    this._selectedPeriod = v;
  }

  constructor(public title: string, public availableDates: TimePeriodDto[]) {
    this.selectedPeriod = this.availableDates[0];
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }
  public set isVisible(newIsVisible: boolean) {
    if (newIsVisible) {
      // Reset status of confirmed property
      this.confirmed = false;
    } else {
      if (this._isVisible) {
        const fire: boolean = this.ignoreCancelOnConfirmedChanged === false || this.confirmed === true;

        // Popup was open, not anymore. Emit new status.
        if (fire) {
          this._confirmedChangedPeriod.next(this.confirmed);
        }
      }
    }
    this._isVisible = newIsVisible;
  }

  public confirmDate(newConfirmed: boolean) {
    this.confirmed = newConfirmed;
    this.isVisible = false;
  }
}
