import { DashletDetailDataColumnDto } from "../../analysis/dto/DashletDetailDataColumn-dto";
import { DashletDetailDataEntryDto } from "../../analysis/dto/DashletDetailDataEntry-dto";

export class PivotDataValues {
  constructor(row: DashletDetailDataEntryDto, columns: DashletDetailDataColumnDto[]) {
    const basic = this as any;
    for (let i = 0; i < columns.length; i++) {
      const value = row.Values[i];
      const column = columns[i];
      if (column.IsNumber == false) {
        basic[column.Name] = value.ValueAsText;
      } else {
        basic[column.Name] = value.Value;
      }
    }
  }
}
