import { Sorter } from "../../../../../../../angular-common";
import { ModelElementFactory } from "../../../../../../../common/model/models/model-element-factory";
import { ModelPresentableElement } from "../../../../../../../common/model/models/model-presentablel-element-model";
import { DashletDefinitionMetaDataDto } from "../../../../analysis/dto/DashletDefinitionMetaDataDTO-dto";
import { ImagineLanguage } from "../../../language/imaginelanguage.service";
import { AvailableToItem } from "./availableToItem.model";
import { Cluster } from "./cluster.model";
import { DataSetTypeItem } from "./dataSetTypeItem";
import { Operator } from "./operator.model";
import { Statistic } from "./statistic.model";

export class DashletDefinitionMetaData {
  public constructor() {}

  public dataSources: ModelPresentableElement[] = [];
  public clusters: Cluster[] = [];
  public numberStatistics: Statistic[] = [];
  public statistics: Statistic[] = [];
  public detailStatistics: Statistic[] = [];
  public operators: Operator[] = [];
  public availableToItems: AvailableToItem[] = [];
  public dataSetTypes: DataSetTypeItem[] = [];
  public defaultOperator: Operator;

  public copyFromDTO(dto: DashletDefinitionMetaDataDto, language: ImagineLanguage) {
    dto.DataSources.forEach((v) => {
      this.dataSources.push(ModelElementFactory.create(v));
    });
    Sorter.sortAlphabetically(this.dataSources, (v) => v.displayName);

    dto.Clusters.forEach((c) => {
      this.clusters.push(Cluster.createFrom(c));
    });
    Sorter.sortSortOrderThenAlpha(this.clusters, (c) => c.displayName);

    dto.Statistics.forEach((s) => {
      this.statistics.push(Statistic.createFromDto(s));
    });
    Sorter.sortAlphabetically(this.statistics, (s) => s.displayName);

    dto.DetailStatistics.forEach((s) => {
      this.detailStatistics.push(Statistic.createFromDto(s));
    });
    Sorter.sortSortOrderThenAlpha(this.detailStatistics, (s) => s.displayName);

    dto.Operators.forEach((o) => {
      this.operators.push(Operator.createFrom(o));
    });
    Sorter.sortSortOrderThenAlpha(this.operators, (o) => o.displayName);

    this.defaultOperator = Operator.createFrom(dto.DefaultOperator);

    dto.AvailableToItems.forEach((i) => {
      const newAvailableToItem = new AvailableToItem(i.Id, i.Name, i.SortOrder);
      this.availableToItems.push(newAvailableToItem);
    });
    Sorter.sortSortOrderThenAlpha(this.availableToItems, (a) => a.name);

    dto.DataSetTypes.forEach((d) => {
      const item = new DataSetTypeItem(d.DataSetType, language.getStringDef(d.LanguageId, d.LanguageId));
      this.dataSetTypes.push(item);
    });
  }
}
