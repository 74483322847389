<div class="data-supply">
  <div>
    <div *ngIf="tabContent.isImportList">
      <div *ngIf="tabContent.imports">
        <div *ngFor="let import of tabContent.imports.imports">
          <app-data-supply-content-item [importDefinition]="import"></app-data-supply-content-item>
        </div>
      </div>
    </div>

    <div *ngIf="tabContent.isImportList === false">
      <app-data-supplies-status></app-data-supplies-status>
    </div>
  </div>
</div>