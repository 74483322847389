import { DataSerieItemContent } from "./dataserie-item-content";

export class DataSerieItem {
  get clusterName() {
    if (this.context && this.context.dataPoint1 && this.context.dataPoint1.clusteredBy && this.context.dataPoint1.clusteredBy.length > 0) {
      const cluster = this.context.dataPoint1.clusteredBy.find((x) => x.isDataSourceCluster === false);
      if (cluster) {
        return cluster.value;
      }
    }
    return "";
  }

  get value1() {
    if (this.context && this.context.dataPoint1) {
      return this.context.dataPoint1.value;
    }
    return "";
  }

  get value2() {
    if (this.context && this.context.dataPoint2) {
      return this.context.dataPoint2.value;
    }
    return "";
  }
  context: DataSerieItemContent;
}
