import { getPalette } from "devextreme/viz/palette";
import { WebLanguage } from "../../../common/language/weblanguage.service";

export class PaletteDefinition {
  public constructor(public language: WebLanguage, public languageKey: string, public paletteName: string, public _colors: string[]) {}

  public get name(): string {
    return this.language.getStringDef(this.languageKey, this.paletteName);
  }

  public get colors(): string[] {
    if (this._colors.length > 0) {
      return this._colors;
    }

    // getPalette and 'simpleSet' come from DevExpress :-)
    const result: string[] = getPalette(this.paletteName)["simpleSet"];
    return result;
  }
}
