import { Component, Input, OnInit } from "@angular/core";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { PeriodPickerInteraction } from "./periodpicker-interaction";

@Component({
  selector: "app-pl-periodpicker",
  templateUrl: "pl-periodpicker.component.html",
})
export class PlPeriodPickerComponent implements OnInit {
  constructor(public language: WebLanguage) {}

  ngOnInit() {}

  public getTitle() {
    if (this.interaction.title !== undefined) {
      return `Please pick a period for: ${this.interaction.title}`;
    } else {
      return "Nice looking title";
    }
  }

  @Input() interaction: PeriodPickerInteraction;
}
