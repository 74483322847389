import { AnalysisClusterService } from "./analysis-cluster.service";
import { ClusterEditScreenComponent } from "./cluster-edit-screen.component";
import { CommonModule } from "@angular/common";
import { DxButtonModule } from "devextreme-angular";
import { ListSelectorModule } from "../../../../../angular-common/components/listselector/listselector.module";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [ListSelectorModule, DxButtonModule, CommonModule],
  declarations: [ClusterEditScreenComponent],
  exports: [ClusterEditScreenComponent],
  providers: [AnalysisClusterService],
})
export class ClusterEditModule {}
