import { Component, Input } from "@angular/core";

import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { DashletTypeDto } from "../../analysis/dto/DashletType-dto";
import { DashboardDefinition } from "../../services/dashboard";
import { DashletDefinitions } from "../../services/dashletdefinition";
import { DashletView } from "../../services/dashletview";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashboard-design-dashboard",
  templateUrl: "./dashboard-design-dashboard.component.html",
  styleUrls: ["./dashboard-design-dashboard.component.scss"],
})
export class DashboardDesignDashboardComponent {
  constructor(public language: ImagineLanguage) {}

  @Input()
  public definitionData: DashletDefinitions;

  @Input()
  public dashboard: DashboardDefinition;

  @Input()
  public dashletTypeFilter: DashletTypeDto[] = [];

  public get dashletTypeOfTypeStatistic(): boolean {
    return ElementHelper.isNullOrUndefined(this.dashletTypeFilter.find((f) => f === DashletTypeDto.Statistic)) === false;
  }

  public get dashletViews(): DashletView[] {
    const result: DashletView[] = [];

    if (this.dashletTypeFilter.length === 0) {
      return this.dashboard.views.views;
    }

    for (const view of this.dashboard.views.views) {
      if (this.dashletTypeFilter.includes(view.dashletType)) {
        result.push(view);
      }
    }

    return result;
  }
}
