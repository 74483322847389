import { Directive, OnInit, ViewChild } from "@angular/core";

import { DxDataGridComponent } from "devextreme-angular";
import { ModelElementFactory } from "../../../../../../common/model/models/model-element-factory";
import { ModelPresentableElement } from "../../../../../../common/model/models/model-presentablel-element-model";
import { Variant } from "../../../../../../common/model/models/variant-model";
import { CompareTypeDto } from "../../../../../../common/models/dto/CompareType-dto";
import { ElementTypeDto } from "../../../../../../common/models/dto/ElementType-dto";
import { RoleDto } from "../../../../../../common/models/dto/RoleDto-dto";
import { DataSetFilterElementDto } from "../../../models/dto/DataSetFilterElementDto-dto";
import { AuthorizationFilterService } from "../../../services/authorization";
import { AuthorizationExpressionModel } from "../../../services/authorization/filters/models/authorization-expression-model";
import { AuthorizationFilterTabContent, IDataSetFilteritem, IDataSetFilteritemContent } from "../../../services/authorization/filters/models/authorization-tabs.tab-content-filter";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { DataSetFilterViewType } from "./dataSet-filterviewtype";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AuthorizationFilterComponent implements OnInit {
  public dataSetFilterViewType = DataSetFilterViewType;
  public compareTypeDto = CompareTypeDto;
  public addingFilter: boolean = false;
  public addingVariant: boolean = false;
  public addingRole: boolean = false;
  public addingBaseModelFilter: boolean = false;
  public elementFormPopupEnabled: boolean = false;
  public editingElement: DataSetFilterElementDto;
  public tabContent: AuthorizationFilterTabContent;

  public expressionModel = new AuthorizationExpressionModel();

  public role: RoleDto;
  public variant: Variant;

  public modelTreeAllowedElementType = ElementTypeDto.Variant;
  public selectedVariant: ModelPresentableElement;

  @ViewChild(DxDataGridComponent, { static: true }) dxGrid: DxDataGridComponent;

  public abstract get addFilterTitle(): string;

  public constructor(public language: ImagineLanguage, private filtersService: AuthorizationFilterService) {}

  ngOnInit() {
    this.tabContent = new AuthorizationFilterTabContent(this.filtersService, this.language);
  }

  public get isFilterWithNoVariant(): boolean {
    if (this.tabContent.metaDataReady && this.tabContent.dataSetFiltersWithoutVariant.length !== 0) {
      return true;
    }
    return false;
  }

  public isPopUpVisible: boolean = true;

  public get isRoleTab() {
    if (this.tabContent) {
      return this.tabContent.dataSetFilterViewType === DataSetFilterViewType.ROLE;
    }
    return false;
  }

  public get isVariantTab() {
    if (this.tabContent) {
      return this.tabContent.dataSetFilterViewType === DataSetFilterViewType.VARIANT;
    }
    return false;
  }

  public get isBaseModelTab() {
    if (this.tabContent) {
      return this.tabContent.dataSetFilterViewType === DataSetFilterViewType.BASEMODEL;
    }
    return false;
  }

  public get dataLoaded(): boolean {
    if (this.tabContent && this.tabContent.metaDataReady) {
      return true;
    }
    return false;
  }

  public clearFields(): void {
    this.variant = undefined;
    this.role = undefined;
    this.selectedVariant = undefined;
    this.expressionModel.clear();
  }

  public addFilter(e): void {
    this.variant = this.selectedVariantOrDefault(this.selectedVariant);
    this.tabContent.saveDataSetFilter(this.variant, this.role);
    this.addingFilter = false;
    this.addingVariant = false;
    this.addingRole = false;
    e.preventDefault();
  }

  public saveElement(e): void {
    const element = this.editingElement || new DataSetFilterElementDto();
    this.expressionModel.assignValueTo(element);

    this.tabContent.saveDataSetFilter(this.variant, this.role, element);
    this.editingElement = undefined;
    this.elementFormPopupEnabled = false;
    e.preventDefault();
  }

  public openFilterModal() {
    this.clearFields();
    this.addingFilter = true;
  }

  protected configureEditorInternal(filterItem: IDataSetFilteritem): void {
    this.clearFields();
    this.variant = <Variant>ModelElementFactory.create(filterItem.variant);
    let datasetToFilterFor = this.variant.dataSetId;
    if (this.isBaseModelTab || this.variant.isBaseModel) {
      datasetToFilterFor = undefined;
    }
    const modelelements = this.tabContent.modelElements.filterFor(datasetToFilterFor);
    this.expressionModel.init(modelelements);
    this.role = filterItem.role;
  }

  private selectedVariantOrDefault(selectedVariant: ModelPresentableElement): Variant {
    if (selectedVariant !== undefined) {
      return <Variant>selectedVariant;
    }

    const defaultVariant = new Variant();
    defaultVariant.id = 0;

    return defaultVariant;
  }

  variantsValidationCallbacks = [];

  variantsValidationAdapterConfig = {
    getValue: () => {
      return this.selectedVariant === undefined ? false : true;
    },
    validationRequestsCallbacks: this.variantsValidationCallbacks,
  };

  revalidateVariantsSelection() {
    this.variantsValidationCallbacks.forEach((func) => {
      func();
    });
  }

  removeFiltersWithNoVariants() {
    if (this.tabContent.dataSetFiltersWithoutVariant.length !== 0) {
      this.tabContent.dataSetFiltersWithoutVariant.forEach((filter) => {
        this.tabContent.deleteDataSetFilter(filter.Id);
      });
    }
    this.isPopUpVisible = false;
  }

  closeFilterWithNoVariantsPopup() {
    this.isPopUpVisible = false;
  }

  public abstract getDescriptions(filter: IDataSetFilteritem): string;

  public abstract openNewTargetModal(filter: IDataSetFilteritem): void;
  public abstract openNewRoleModal(filter: IDataSetFilteritem): void;

  public abstract openNewElementModal(filter: IDataSetFilteritem): void;

  public abstract openEditElementModal(filter: IDataSetFilteritem, element: DataSetFilterElementDto): void;

  public abstract deleteElement(filter: IDataSetFilteritem, element: DataSetFilterElementDto): void;

  public abstract deleteRoleTarget(item: IDataSetFilteritemContent): void;
}
