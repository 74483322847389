import { DashletDefinition } from "..";
import { DashletDefinitionsDto } from "../../../analysis/dto/DashletDefinitionsDTO-dto";
import { DashletTypeDto } from "../../../analysis/dto/DashletType-dto";
import { ImagineLanguage } from "../../language/imaginelanguage.service";
import { DashletDefinitionService } from "../dashlet-definition-service";
import { DashletDefinitionList } from "./dashlet-definition/dashlet-definition-list.model";
import { DashletDefinitionMetaData } from "./metadata/dashlet-definition-metadata.model";

export class DashletDefinitions {
  public constructor(private dashletDefinitionService: DashletDefinitionService, public language: ImagineLanguage) {
    this.list = new DashletDefinitionList(this.dashletDefinitionService, language);
    this.metaData = new DashletDefinitionMetaData();
  }

  public list: DashletDefinitionList;
  public metaData: DashletDefinitionMetaData;

  public copyFromDTO(dashletDefinitionsDto: DashletDefinitionsDto) {
    this.list.copyFromDTO(dashletDefinitionsDto.Definitions);
    this.metaData.copyFromDTO(dashletDefinitionsDto.MetaData, this.language);
  }

  public createFiltered(dashletType: DashletTypeDto) {
    const newDashletDefinitions = new DashletDefinitions(this.dashletDefinitionService, this.language);
    newDashletDefinitions.metaData = this.metaData;

    const newDefinitionList: DashletDefinition[] = [];
    this.list.dashletDefinitions.forEach((definition) => {
      if (definition.type === dashletType) {
        newDefinitionList.push(definition);
      }
    });

    newDashletDefinitions.list.dashletDefinitions = newDefinitionList;

    return newDashletDefinitions;
  }
}
