import { ActivatedRoute } from "@angular/router";
import { Component } from "@angular/core";
import { DataFilterList } from "../services/filter";

@Component({
  selector: "app-dashboarding-screen",
  templateUrl: "./dashboarding-screen.component.html",
  styleUrls: ["./dashboarding-screen.component.scss"],
})
export class DashboardingScreenComponent {
  constructor(private route: ActivatedRoute) {
    this.route.data.subscribe((data) => {
      this.filterList = data.filterList;
    });
  }

  public filterList: DataFilterList;
}
