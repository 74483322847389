import { RoleDto } from "../../../../../../common/models/dto/RoleDto-dto";
import { DashboardsDto } from "../../../analysis/dto/DashboardsDto-dto";
import { DashletViewService } from "../../dashletview";
import { FilterListService } from "../../filter/filter-list-service";
import { DashboardService } from "../dashboard.service";
import { DashboardDefinitionList } from "./dashboard-definition-list.model";
import { Parameter } from "./parameter";
import { ReferenceCategorie } from "./referencecategorie";

export class Dashboards {
  constructor(private dashboardService: DashboardService, private dashletViewService: DashletViewService, private filterListService: FilterListService) {}

  public dashboardDefinitions: DashboardDefinitionList;
  public parameters: Parameter[] = [];
  public referenceCatagories: ReferenceCategorie[] = [];
  public availableRoles: RoleDto[];

  fromDto(dto: DashboardsDto) {
    const newDefinitions = new DashboardDefinitionList(this.dashboardService, this.dashletViewService, this.filterListService);
    newDefinitions.copyFromDTO(dto.DashboardDefinitions, dto.VariantInformations);
    this.dashboardDefinitions = newDefinitions;

    const newParameters: Parameter[] = [];
    dto.Parameters.forEach((parameter) => {
      const newParameter = new Parameter();
      newParameter.fromDto(parameter);
      newParameters.push(newParameter);
    });
    this.parameters = newParameters;

    const newReferences: ReferenceCategorie[] = [];
    dto.ReferenceCategories.forEach((reference) => {
      const newRef = new ReferenceCategorie();
      newRef.fromDto(reference);
      newReferences.push(newRef);
    });

    this.referenceCatagories = newReferences;

    this.availableRoles = dto.AvailableRoles;
  }
}
