<div *ngIf="variantEditMetaData && variantToEdit && variantEditInformation">
  <app-cardview [title]="variant.name">
    <h2 class="dx-modeltree-title">{{ variantToEdit.displayName }}</h2>

    <div class="buttons-bottom-margin">
      <dx-button class="miniButton" icon="edit" [hint]="language.ButtonEdit" (onClick)="editVariantPopup()"> </dx-button>
      <dx-button class="miniButton" [disabled]="!canDelete" icon="fas fa-trash" [hint]="language.ButtonDelete" (onClick)="deleteVariantPopup()"> </dx-button>
    </div>

    <div class="buttonsContainer">
      <dx-button class="variantButtons" [text]="language.addChild" (onClick)="createChildPopup()"> </dx-button>
    </div>

    <dx-form [formData]="variantToEdit" [colCount]="2">
      <dxi-item dataField="Period" [colSpan]="2">
        <div class="editfields">
          <div>
            <dxo-label [text]="language.VariantPeriodsModel" template="dataLabelPeriod"> </dxo-label>
          </div>
          <div class="colAttributes">
            <dx-select-box
              [readOnly]="true"
              [items]="variantPeriodInfo.periods"
              displayExpr="displayName"
              valueExpr="id"
              [(value)]="variantPeriodInfo.variantEditStartPeriodId"
              [disabled]="!variantPeriodInfo.canEditPeriodStart"
            ></dx-select-box>
          </div>

          <div class="colAttributes">
            <dx-select-box
              [readOnly]="true"
              [items]="variantEditMetaData.periods"
              displayExpr="displayName"
              valueExpr="id"
              [(value)]="variantPeriodInfo.variantEditEndPeriodId"
              [disabled]="!variantPeriodInfo.canEditPeriodEnd"
            ></dx-select-box>
          </div>
        </div>
      </dxi-item>

      <div *dxTemplate="let data of 'dataLabelPeriod'">
        <span class="fas fa-calendar"></span>
        <span class="iconSpacing">{{data.text}}</span>
      </div>

      <dxi-item dataField="Data" [colSpan]="2">
        <div class="editfields">
          <dxo-label template="dataLabelData"></dxo-label>
          <dx-select-box [readOnly]="true" [items]="variantEditMetaData.dataSetDefinitions" displayExpr="longName" valueExpr="id" [(value)]="variant.dataSetId"></dx-select-box>
        </div>
      </dxi-item>

      <div *dxTemplate="let data of 'dataLabelData'">
        <span class="fas fa-database"></span>
        <span class="iconSpacing">{{data.text}}</span>
      </div>

      <dxi-item class="flex" [colSpan]="2" *ngIf="shouldShowVariantMutations">
        <div>
          <dxo-label template="mutations" [text]="language.mutationHeader"></dxo-label>
          <app-variant-mutations-component [variantEditMetaData]="variantEditMetaData" [variantEditInformation]="variantEditInformation"></app-variant-mutations-component>
        </div>
        <dx-button class="variantButtons plus-button" icon="plus" (onClick)="addModelElementPopup()" [disabled]="isAddButtonDisabled"> </dx-button>
      </dxi-item>

      <div *dxTemplate="let data of 'mutations'">
        <span class="fas fa-sliders-h"></span>
        <span class="iconSpacing">{{data.text}}</span>
      </div>
    </dx-form>
  </app-cardview>

  <dx-popup [width]="600" [height]="'auto'" [showTitle]="true" [title]="variantToEdit.displayName" [showCloseButton]="true" [(visible)]="popupVisible">
    <div *ngIf="isEditPopup">
      <dx-form [colCount]="2">
        <dxi-item dataField="Data" [colSpan]="2">
          <div class="editfields">
            <dxo-label template="dataLabelData"></dxo-label>
            <dx-select-box [items]="variantEditMetaData.dataSetDefinitions" displayExpr="longName" valueExpr="id" [(value)]="variantToEdit.dataSetId"></dx-select-box>
          </div>
        </dxi-item>
        <div *dxTemplate="let data of 'dataLabelData'">
          <span class="fas fa-database"></span>
          <span class="iconSpacing">{{data.text}}</span>
        </div>

        <dxi-item dataField="Name" [colSpan]="2">
          <div class="editfields">
            <dxo-label [text]="language.Variant" template="dataLabelName"> </dxo-label>
            <dx-text-box [readOnly]="false" [(value)]="variantEditName">
              <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </dxi-item>

        <div *dxTemplate="let data of 'dataLabelName'">
          <span class="fas fa-tag"></span>
          <span class="iconSpacing">{{data.text}}</span>
        </div>

        <dxi-item dataField="Period Type" [colSpan]="2">
          <div class="editfields">
            <dxo-label [text]="language.VariantPeriodModel" template="dataLabelPeriodType"> </dxo-label>
            <dx-select-box [items]="variantEditMetaData.periodTypes" [displayExpr]="periodModeDisplayName" valueExpr="KeyId" [(value)]="variantEditPeriodInfo.variantEditPeriodModeId"> </dx-select-box>
          </div>
        </dxi-item>

        <div *dxTemplate="let data of 'dataLabelPeriodType'">
          <span class="fas fa-calendar-day"></span>
          <span class="iconSpacing">{{data.text}}</span>
        </div>

        <dxi-item dataField="Period" [colSpan]="2">
          <div class="editfields">
            <div>
              <dxo-label [text]="language.VariantPeriodsModel" template="dataLabelPeriod"> </dxo-label>
            </div>
            <div class="colAttributes">
              <dx-select-box
                [items]="variantEditPeriodInfo.periods"
                displayExpr="displayName"
                valueExpr="id"
                [(value)]="variantEditPeriodInfo.variantEditStartPeriodId"
                [disabled]="!variantEditPeriodInfo.canEditPeriodStart"
              ></dx-select-box>
            </div>

            <div class="colAttributes">
              <dx-select-box
                [items]="variantEditPeriodInfo.periods"
                displayExpr="displayName"
                valueExpr="id"
                [(value)]="variantEditPeriodInfo.variantEditEndPeriodId"
                [disabled]="!variantEditPeriodInfo.canEditPeriodEnd"
              ></dx-select-box>
            </div>
          </div>
        </dxi-item>
        <div *dxTemplate="let data of 'dataLabelPeriod'">
          <span class="fas fa-calendar"></span>
          <span class="iconSpacing">{{data.text}}</span>
        </div>
      </dx-form>

      <dx-button class="OkButton" [text]="language.ButtonOk" [disabled]="!canSave" id="saveButton" [hint]="language.ButtonSave" (onClick)="saveVariant()"></dx-button>
    </div>

    <div *ngIf="isCreatePopup">
      <dx-form>
        <dxi-item>
          <dxo-label [text]="language.newVariantName"></dxo-label>
          <dx-text-box #textboxNewVariant></dx-text-box>
        </dxi-item>
      </dx-form>
      <dx-button class="OkButton" [text]="language.ButtonOk" (onClick)="okButtonCreateChild(textboxNewVariant.value)"> </dx-button>
    </div>

    <div *ngIf="isAddModelElementPopup">
      <dx-data-grid [dataSource]="elementNotCurrentVariant" [showBorders]="true" (onRowClick)="selectModelElementToAdd($event)" (onRowPrepared)="onRowPrepared($event)">
        <dxi-column dataField="dataType" caption="{{language.ModelElementCategoryColumnHeader}}" [calculateDisplayValue]="getCategoryDisplayValue" [sortOrder]="'asc'" [sortIndex]="0"></dxi-column>
        <dxi-column dataField="displayName" caption="{{language.ModelElementNameColumnHeader}}" [sortOrder]="'asc'" [sortIndex]="1"></dxi-column>
      </dx-data-grid>
      <dx-button class="OkButton" [text]="language.ModelElementAddButton" [disabled]="!canAdd" (onClick)="addModelElementsToVariant()"> </dx-button>
    </div>
  </dx-popup>
</div>
