import { Component, Input, OnInit } from "@angular/core";
import { DashboardDefinition, DashboardService } from "../../services/dashboard";

import { PaginatedDxData } from "../../../../../angular-common";
import { ParameterTypeDto } from "../../../../../common/models/dto/ParameterType-dto";
import { Parameter } from "../../services/dashboard/models/parameter";
import { ReferenceCategorie } from "../../services/dashboard/models/referencecategorie";
import { DataFilterList } from "../../services/filter";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { ParameterEditService } from "../../services/parameteredit/parameteredit.service";

@Component({
  selector: "app-parameter-tool-bar",
  templateUrl: "./parameter-tool-bar.component.html",
  styleUrls: ["./parameter-tool-bar.component.scss"],
})
export class ParameterToolBarComponent implements OnInit {
  public parameters: Parameter[] = [];
  public referenceCategories: ReferenceCategorie[] = [];
  private _selectedParameters: Parameter[] = [];
  private previouslySelectedParameters: Parameter[] = [];
  public selectedStrings: string[];
  public exactParameters: Parameter[] = [];
  public referenceParameters: Parameter[] = [];
  public isOpen = false;
  public initalizationDone: boolean = false;
  public paginatedParameters: PaginatedDxData = null;
  constructor(public language: ImagineLanguage, private dashboardService: DashboardService, private parameterService: ParameterEditService) {}

  public get enableParameterOptions() {
    if (this.selectedDashboardDefinition) {
      return this.selectedDashboardDefinition.allowSimulation;
    }
    return false;
  }

  public openClose() {
    this.isOpen = !this.isOpen;
  }

  ngOnInit(): void {
    this.initialization();
  }
  selectParameters() {
    this.selectedDashboardDefinition.parameters.forEach((p) => {
      const tempPara = this.parameters.find((x) => x.shortName === p.shortName);
      this.selectedParameters.push(tempPara);
    });
    this.addParameters(this.selectedParameters);
  }

  private addParameters(toAdd: Parameter[]) {
    if (this.selectedDashboardDefinition.simulationVariantId !== null) {
      toAdd.forEach((parameter) => {
        this.parameterService.addNewParameter(this.selectedDashboardDefinition.simulationVariantId, parameter).subscribe((p) => {
          switch (p.parameterType) {
            case ParameterTypeDto.Exact:
              this.exactParameters.push(p);
              break;
            case ParameterTypeDto.Reference:
              this.referenceParameters.push(p);
              break;
            default:
              break;
          }
        });
      });
    }
  }

  private removeParameters(toRemove: Parameter[]) {
    if (this.selectedDashboardDefinition.simulationVariantId !== null) {
      toRemove.forEach((parameter) => {
        this.parameterService.deleteAllParameterMutations(this.selectedDashboardDefinition.simulationVariantId, parameter).subscribe(() => {
          this.removeControl(parameter);
        });
      });
    }
  }

  private removeControl(parameter: Parameter) {
    switch (parameter.parameterType) {
      case ParameterTypeDto.Exact:
        this.exactParameters.splice(
          this.exactParameters.findIndex((x) => x.shortName === parameter.shortName),
          1,
        );
        break;
      case ParameterTypeDto.Reference:
        this.referenceParameters.splice(
          this.referenceParameters.findIndex((x) => x.shortName === parameter.shortName),
          1,
        );
        break;
      default:
        break;
    }
  }

  updateParameters() {
    this.exactParameters.forEach((parameter) => {
      this.parameterService.updateParameter(this.selectedDashboardDefinition.simulationVariantId, parameter).subscribe();
    });
    this.referenceParameters.forEach((parameter) => {
      this.parameterService.updateParameter(this.selectedDashboardDefinition.simulationVariantId, parameter).subscribe();
    });
  }

  private initialization() {
    this.dashboardService.getDashboards().subscribe((d) => {
      this.referenceCategories = d.referenceCatagories;
      this.parameters = d.parameters;
      this.paginatedParameters = new PaginatedDxData(this.parameters);
      this.selectParameters();
      this.updatepreviousSelection();
      this.createStrings();
      this.initalizationDone = true;
    });
  }

  updatepreviousSelection() {
    this.previouslySelectedParameters = [];
    this.selectedParameters.forEach((p) => {
      const newP = p.copyFrom(p);
      this.previouslySelectedParameters.push(newP);
    });
  }

  public updateSelection() {
    const selectedShortnames = this.returnShortnames(this._selectedParameters);
    const previouslySelectedShortname = this.returnShortnames(this.previouslySelectedParameters);

    const toAdd = this._selectedParameters.filter((x) => !previouslySelectedShortname.includes(x.shortName));
    const toRemove = this.previouslySelectedParameters.filter((x) => !selectedShortnames.includes(x.shortName));

    this.addParameters(toAdd);
    this.removeParameters(toRemove);

    this.updatepreviousSelection();
  }

  private returnShortnames(parameters: Parameter[]): string[] {
    const strings: string[] = [];
    parameters.forEach((p) => {
      strings.push(p.shortName);
    });
    return strings;
  }

  createStrings() {
    this.selectedStrings = [];
    this.selectedParameters.forEach((parameter) => {
      this.selectedStrings.push(parameter.longName);
    });
  }

  set selectedParameters(value) {
    this._selectedParameters = value;
    this.createStrings();
  }

  get selectedParameters() {
    return this._selectedParameters;
  }

  @Input() filterList: DataFilterList;
  @Input() selectedDashboardDefinition: DashboardDefinition;
}
