<app-cardview [title]="description" [buttons]="actionButtons" (buttonClicked)="onButtonClicked($event)">
        <div class="import-information-card">
                <p>{{requestedBy}}</p>
                <p *ngIf="importedFilesNotEmpty">{{language.datasupplyDetailsImportedFiles}}:</p>
                <ul>
                        <li *ngFor="let file of importedFiles">{{file.shortname}}: {{file.filename}}</li>
                </ul>
        </div>
        <ul class="order-status">
                <li [ngClass]="{'current': hasQueuedState}">
                        <span
                                [ngClass]="{'spannotcurrent': !hasQueuedState}">{{language.datasupplyStateQueuedHeader}}</span>
                        <!-- <span *ngIf="hasQueuedState" class="spanexplain">item has been queued desc</span> -->
                </li>
                <li [ngClass]="{'current': hasInProgressSate}">
                        <span
                                [ngClass]="{'spannotcurrent': !hasInProgressSate}">{{language.datasupplyStateProcessingHeader}}</span>
                        <!-- <span *ngIf="hasInProgressSate" class="spanexplain">item being processed description</span> -->
                </li>
                <li [ngClass]="{'current': hasFinishedState}">
                        <span
                                [ngClass]="{'spannotcurrent': !hasFinishedState}">{{language.datasupplyStateDoneHeader}}</span>
                        <!-- <span *ngIf="hasFinishedState" class="spanexplain">item has finished</span> -->
                </li>
        </ul>
        <div class="process-step-details">
                <dx-accordion #accordion [dataSource]="stateAsMultiple" [collapsible]="true" [multiple]="false"
                        [animationDuration]="300" [selectedItems]="[]" [repaintChangesOnly]="true">
                        <div *dxTemplate="let step of 'title'">
                                <h1>Details</h1>
                        </div>
                        <div *dxTemplate="let singleItem of 'item'">
                                <div *ngFor="let stateSteps of singleItem.stateSteps">
                                        <h3>{{stateText(stateSteps.state)}}</h3>
                                        <div class="import-information-card-details">
                                                <div *ngFor="let step of stateSteps.steps">
                                                        <h5>{{step.description}}</h5>
                                                        <p>
                                                                <span>{{step.shortDateTimeText}}</span>
                                                                <span>{{step.address}}</span>
                                                        </p>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </dx-accordion>
        </div>
</app-cardview>
<app-toaster [model]="toasterInput"></app-toaster>