import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { IAggregationType } from "./pivot-aggregationtype";

export class AggregationTypes {
  constructor(private language: ImagineLanguage) {
    this.Sum = { type: "sum", translation: this.language.PivotAggregationTypeSum };
    this.Average = { type: "Avg", translation: this.language.PivotAggregationTypeAverage };
    this.Minimum = { type: "min", translation: this.language.PivotAggregationTypeMinimum };
    this.Maximum = { type: "max", translation: this.language.PivotAggregationTypeMaximum };
  }

  public all() {
    return [this.Sum, this.Average, this.Minimum, this.Maximum];
  }

  public Sum: IAggregationType;
  public Average: IAggregationType;
  public Minimum: IAggregationType;
  public Maximum: IAggregationType;
}
