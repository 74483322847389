
// t4tsCode gen": "0.8.2.0 for file SDWorxRoutes.cs
// Don't adjust manually!

export class SDWorxRoutesDto {
    public static SDWorxProvisioningConfiguration: string = "sdworx-provisioning-configuration";
    public static SDWorxProvisioningMessageStatus: string = "sdworx-provisioning-messagestatus";
    public static SDWorxProvisioningUsers: string = "sdworx-provisioning-users";
}

