<div *ngIf="hasMutations; else noMutations">
  <dx-list
    width="100%"
    [dataSource]="mutationsCategorized"
    [grouped]="true"
    [collapsibleGroups]="true"
    [showSelectionControls]="false"
    [selectionMode]="'none'"
    itemTemplate="listItem"
    groupTemplate="group"
    keyExpr="key"
    (onContentReady)="onMutationsListContentReady($event)"
  >
    <div *dxTemplate="let category of 'group'">
      <i class="fas fa-sliders-h"></i>
      <span class="mutations-number-tag">{{mutationsNumberIn(category)}}</span>
      {{category.categoryName}}
    </div>
    <div *dxTemplate="let modelElement of 'listItem'">
      <i [class]="getIconByElementModelType(modelElement.type)"></i>
      {{modelElement.elementName}}, {{modelElement.onMutationsListContentReady}}
      <span class="mutationSummary mutationClick" (click)="editMutationPopup(modelElement)">{{mutationsSummary(modelElement)}}</span>
    </div>
  </dx-list>
</div>

<dx-popup
  [minWidth]="300"
  [minHeight]="200"
  [width]="'auto'"
  [height]="'auto'"
  [showTitle]="true"
  [title]="popupTitle"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
  (onHidden)="onPopupHidden()"
>
  <div *ngIf="isStackEdit()===false && canEdit()" class="add-mutation">
    <app-period-date-picker [(disabledPeriods)]="periodsInUse" [periods]="periodsForPeriodSelection" (selectDateEvent)="selectDate($event)" [newStartDate]="defaultStartDate"></app-period-date-picker>
    <dx-button text="{{language.modelEditAddMutation}}" (onClick)="addMutation()"> </dx-button>
  </div>
  <div class="separator"></div>

  <div *ngIf="isEditPopup">
    <div *ngIf="isParameterEdit()">
      <dx-data-grid id="gridContainer" [dataSource]="mutationsToShow" [showBorders]="true" [width]="600" (onSaved)="onRowSaved($event)" (onRowRemoved)="onRowRemoved($event)">
        <dxo-editing [allowUpdating]="canEdit()" [allowDeleting]="canEdit()" [useIcons]="true"></dxo-editing>

        <dxi-column dataField="KeyStartDate.DateAsSortableNumber" caption="{{language.MutationParamMutationDate}}" [allowEditing]="false" [calculateDisplayValue]="selectTimePeriod"> </dxi-column>

        <dxi-column dataField="Value" dataType="number" [calculateDisplayValue]="getValuCalculated"></dxi-column>
      </dx-data-grid>
      <dx-form [colCount]="2"> </dx-form>
    </div>

    <div *ngIf="isFormulaEdit()">
      <app-formula-mutations-component
        [mutationsToShow]="mutationsToShow"
        [(currentFormulaMutation)]="lastFormulaMutation"
        [canEdit]="canEdit()"
        #formulaMutationsRef
        [periodsForPeriodSelection]="periodsForPeriodSelection"
        [showEditField]="showEditField"
        [(isFormulaChanged)]="isFormulaChanged"
        [(formulaMutationToEdit)]="formulaMutationToEdit"
        (rowRemovedEvent)="onRowRemoved($event)"
        (refreshEvent)="refreshListOfMutations()"
      ></app-formula-mutations-component>
      <dx-form [colCount]="1"> </dx-form>
    </div>

    <div *ngIf="isStackEdit()">
      <app-stack-mutations-component
        [mutationsToShow]="mutationsToShow"
        [mutationsToEdit]="mutationsToEdit"
        [(currentStackMutation)]="lastStackMutation"
        stackType="{{itemToEdit.stackType}}"
        [(stackReferenceCategoriesDto)]="stackReferenceCategoriesDto"
        [canEdit]="canEdit()"
        #stackMutationsRef
        [periodsForPeriodSelection]="periodsForPeriodSelection"
        (refreshEvent)="refreshListOfMutations()"
      ></app-stack-mutations-component>
      <dx-form [colCount]="1"> </dx-form>
    </div>
  </div>

  <div *ngIf="isCreatePopup">
    <dx-form>
      <dxi-item>
        <dxo-label [text]="language.newVariantName"></dxo-label>
        <dx-text-box #textboxNewVariant></dx-text-box>
      </dxi-item>
    </dx-form>
    <dx-button class="OkButton" [text]="language.ButtonOk" (onClick)="okButtonCreateChild(textboxNewVariant.value)"> </dx-button>
  </div>
</dx-popup>

<ng-template #noMutations>
  <div>{{language.modelEditNoMutations}}</div>
</ng-template>
