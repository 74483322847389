<dx-form [colCount]="2">
  <dxi-item *ngIf="importDefinition.options.RequiresDataSetSelection" [colSpan]="2">
    <dxo-label [text]="language.datasupplyDatasetAddToExisting" [showColon]="false"></dxo-label>
    <dx-select-box (onValueChanged)="onDatasetSelected()" [dataSource]="datasets" [(value)]="selectedDataset" displayExpr="longName" valueExpr="keyId" width="300px"></dx-select-box>
  </dxi-item>
  <dxi-item *ngIf="importDefinition.options.CanSupplyDataSetName" [colSpan]="2">
    <dxo-label [text]="language.datasupplyDatasetAddToNew" [showColon]="false"></dxo-label>
    <dx-text-box (onValueChanged)="onDatasetNamed()" [(value)]="datasetName" [placeholder]="language.datasupplyDatasetEnterNewPlaceholder" [showClearButton]="true">
      <dx-validator>
        <dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [min]="7" message="Name must have at least 7 symbols"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </dxi-item>
</dx-form>
