import { DataSetFilterElementDto } from "../../../../models/dto/DataSetFilterElementDto-dto";
import { CompareType } from "./authorization-compareType-model";

export class AuthorizationFilterElement {
  public get shortName(): string {
    return this._shortName;
  }
  public set shortName(newName: string) {
    if (this._shortName !== newName) {
      this.value = null;
    }
    this._shortName = newName;
  }

  public static newEmptyFilterElement(): AuthorizationFilterElement {
    const filter = new AuthorizationFilterElement();
    filter.compareType = new CompareType();
    filter.compareType.id = 0;
    filter.compareType.name = "";
    filter.shortName = "";
    filter.value = 0;

    return filter;
  }
  public compareType: CompareType;
  public value: number;

  public constructor() { }
  private _shortName: string;

  public isValid(): boolean {
    return areNotNullOrUndefined([this.compareType, this.shortName, this.value]) && this.shortName !== "";
  }

  public copyFrom(dto: DataSetFilterElementDto, compareTypes: CompareType[]) {
    if (isNotNullOrUndefined(dto)) {
      this.shortName = dto.ShortName;
      this.compareType = CompareType.copyFromDtoEnum(dto.CompareType);
      this.value = parseFloat(dto.Value);
    }
  }

  public copyTo(): DataSetFilterElementDto {
    const filterElement = new DataSetFilterElementDto();
    filterElement.ShortName = this.shortName;
    filterElement.CompareType = CompareType.toDto(this.compareType).Id;
    filterElement.Value = this.value.toString();
    filterElement.ValueAsText = this.value.toString();
    return filterElement;
  }
}

function isNotNullOrUndefined(value: any): boolean {
  return value !== undefined && value !== null;
}

function areNotNullOrUndefined(values: any[]): boolean {
  let result = true;

  values.forEach(val => {
    if (!isNotNullOrUndefined(val)) {
      result = false;
    }
  });

  return result;
}
