import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { DevExtremeGridNames } from "../../../../../../../angular-common/devextreme/dev-extreme-grid";
import { IDxGridChange } from "../../../../../../../angular-common/devextreme/i-dx-grid-change";
import { ElementHelper } from "../../../../../../../angular-common/helpers/element.helper";
import { DateFormat } from "../../../../../../../angular-common/language/date-format";
import { CopyingObjects } from "../../../../../../../angular-common/utils/copying-objects";
import { TimePeriod } from "../../../../../../../common/modelelements/timeperiod-model";
import { ModelElementOptionDto } from "../../../../../../../common/models/dto/ModelElementOptionDto-dto";
import { MutationDto } from "../../../../../../../common/models/dto/MutationDto-dto";
import { ReferenceCategoriesDto } from "../../../../../../../common/models/dto/ReferenceCategoriesDto-dto";
import { StackCalculationDto } from "../../../../../../../common/models/dto/StackCalculationDto-dto";
import { StackCalculationExactRuleDto } from "../../../../../../../common/models/dto/StackCalculationExactRuleDto-dto";
import { StackCalculationIntervalRuleDto } from "../../../../../../../common/models/dto/StackCalculationIntervalRuleDto-dto";
import { StackMutationDto } from "../../../../../../../common/models/dto/StackMutationDto-dto";
import { StackTypeDto } from "../../../../../../../common/models/dto/StackType-dto";
import { ObjectUtils } from "../../../../../../../common/utils/object-utils";
import { ImagineLanguage } from "../../../../services/language/imaginelanguage.service";
import { VariantEditService } from "../../../../services/variantedit/variantedit-service";
import { IStackRule } from "./i-stack-rule";

@Component({
  selector: "app-stack-mutations-component",
  templateUrl: "./stack-mutations-component.html",
  styleUrls: ["./stack-mutations-component.scss"],
})
export class StackMutationsComponent implements OnChanges, OnInit {
  @Input() mutationsToShow: MutationDto[] = [];
  @Input() mutationsToEdit: MutationDto[] = [];
  @Input() stackReferenceCategoriesDto: ReferenceCategoriesDto[] = [];
  @Input() stackType: StackTypeDto;
  @Input() periodsForPeriodSelection: TimePeriod[];
  @Input() canEdit: boolean;
  @Output() refreshEvent = new EventEmitter<any>();
  @ViewChild("rulesGrid") rulesGrid: DxDataGridComponent;

  dateFormat: any = DateFormat.DateByUserLanguage();
  stackRules: IStackRule[] = [];
  referancesForCurrentCategory: ModelElementOptionDto[] = [];
  referancesForCurrentCategoryToEdit: ModelElementOptionDto[] = [];
  originalRule: any;
  isValidationSuccessfull: boolean;
  validationMessage: string = " ";
  batchEditMode = DevExtremeGridNames.batchEditMode;
  toolbarItems: any[] = [];

  private _currentStackMutation: StackMutationDto = new StackMutationDto();

  @Input() set currentStackMutation(value: StackMutationDto) {
    this._currentStackMutation = value;
  }

  public get currentStackMutation() {
    return this._currentStackMutation;
  }

  constructor(private variantService: VariantEditService, public language: ImagineLanguage) {
    this.stackReferance = this.stackReferance.bind(this);
    this.selectTimePeriod = this.selectTimePeriod.bind(this);
  }

  ngOnInit() {
    this.getRules(this.currentStackMutation.Rules);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateToolbarFromNgOnChangesToForceEnablingButtonsAfterTypeChange();
  }

  updateToolbarFromNgOnChangesToForceEnablingButtonsAfterTypeChange() {
    this.toolbarItems = [
      { widget: "dxButton", options: { text: this.language.MutationStackRulesCreateNewRule, disabled: this.isAddButtonDisabled(), onClick: () => this.addRule() } },
      { widget: "dxButton", options: { icon: "check", onClick: () => this.validateRules() } },
      { name: DevExtremeGridNames.batchEditModeSaveButton },
      { name: DevExtremeGridNames.batchEditModeRevertButton },
    ];
  }

  selectTimePeriod(gridRow: any) {
    return TimePeriod.TimePeriodDisplayNameSelector(gridRow, this.periodsForPeriodSelection);
  }

  get editingMode(): string {
    return this.isReferenceStack() ? DevExtremeGridNames.rowEditMode : DevExtremeGridNames.batchEditMode;
  }

  public stackReferance(r: IStackRule) {
    let referance = this.referancesForCurrentCategory.filter((x) => x.Id === r?.input);
    if (referance.length > 0) {
      return referance[0].Text;
    }
    return;
  }

  isAddButtonDisabled(): boolean {
    if (this.canEdit === false) return true;
    if (this.isReferenceStack()) {
      if (this.referancesForCurrentCategoryToEdit.length > 0) {
        return false;
      } else return true;
    }
    return false;
  }

  isReferenceStack(): boolean {
    return this.stackType == StackTypeDto.ExactReference;
  }

  isValueStack(): boolean {
    return this.stackType == StackTypeDto.ExactValue;
  }

  isIntervalStack(): boolean {
    return this.stackType == StackTypeDto.Interval;
  }

  isLowerBoundInfinite(): boolean {
    if (this.isIntervalStack()) return this.currentStackMutation.Rules.some((x) => x.IntervalRule.LowerBound === -1 || x.IntervalRule.LowerBound === null);
    return false;
  }

  isUpperBoundInfinite(): boolean {
    if (this.isIntervalStack()) return this.currentStackMutation.Rules.some((x) => x.IntervalRule.UpperBound === -1 || x.IntervalRule.UpperBound === null);
    return false;
  }

  onRowPrepared(event) {
    if (event.rowType === "data" && event.data.KeyStartDate === this.currentStackMutation.KeyStartDate) {
      event.rowElement.classList.add("highlighted-row");
    }
  }

  onRowClick(gridRow: any) {
    this.currentStackMutation = gridRow.data;
    gridRow.component.refresh();
    this.clearPopupSettings();
    this.getRules(gridRow.data.Rules);
    this.rulesGrid.instance.cancelEditData();
  }

  getRules(rules: StackCalculationDto[]) {
    this.stackRules.length = 0;
    rules.forEach((rule, index) => {
      this.stackRules.push({
        input: rule.ExactRule?.InputValue ?? -1,
        lowerBound: rule.IntervalRule?.LowerBound ?? null,
        upperBound: rule.IntervalRule?.UpperBound ?? null,
        output: rule?.OutputValue ?? 0,
        index: index,
      });
    });
    this.getReferances();
    this.isAddButtonDisabled();
  }

  getReferances() {
    if (this.currentStackMutation.StackType == StackTypeDto.ExactReference) {
      let currentCategory = this.stackReferenceCategoriesDto.filter((x) => x.CategoryId === this.currentStackMutation.ExactReferanceStackCategoryId);
      if (currentCategory.length > 0) {
        this.referancesForCurrentCategory = currentCategory[0].Options;
        this.referancesForCurrentCategoryToEdit = this.referancesForCurrentCategory.filter((element1) => !this.stackRules.find((element2) => element2.input === element1.Id));
        this.updateToolbarFromNgOnChangesToForceEnablingButtonsAfterTypeChange();
      }
    }
  }

  clearPopupSettings() {
    this.stackRules.length = 0;
    this.validationMessage = " ";
  }

  private assignExactRule(ruleDto: StackCalculationDto, referenceStack: IStackRule) {
    ruleDto.ExactRule = new StackCalculationExactRuleDto();
    ruleDto.ExactRule.InputValue = referenceStack.input;
    ruleDto.IntervalRule = new StackCalculationIntervalRuleDto();
    ruleDto.IntervalRule.LowerBound = null;
    ruleDto.IntervalRule.UpperBound = null;
  }

  private assignIntervalRule(ruleDto: StackCalculationDto, referenceStack: IStackRule) {
    ruleDto.IntervalRule = new StackCalculationIntervalRuleDto();
    ruleDto.IntervalRule.LowerBound = referenceStack.lowerBound;
    ruleDto.IntervalRule.UpperBound = referenceStack.upperBound;
    ruleDto.ExactRule = new StackCalculationExactRuleDto();
    ruleDto.ExactRule.InputValue = -1;
  }

  onRowUpdating(event: any) {
    this.originalRule = CopyingObjects.deepCopy(event.oldData);
  }

  onRowSaved(event: any) {
    if (event.changes.length === 0) {
      return;
    }

    if (ElementHelper.isNullOrUndefined(this.currentStackMutation) === true) {
      return;
    }

    for (let change of event.changes) {
      if (change.type === DevExtremeGridNames.changeTypeRemove) {
        let index = -1;
        if (this.isIntervalStack() === false) index = this.currentStackMutation.Rules.findIndex((x) => x.ExactRule.InputValue === change.key.input);
        if (this.isIntervalStack() === true) index = this.currentStackMutation.Rules.findIndex((x) => x.IntervalRule.LowerBound === change.key.lowerBound);
        this.currentStackMutation.Rules.splice(index, 1);
      }

      if (change.type !== DevExtremeGridNames.changeTypeUpdate || change.type !== DevExtremeGridNames.changeTypeInsert) {
        const eventData = change.data;
        if (ElementHelper.isNullOrUndefined(eventData) === true) {
          continue;
        }
        if (eventData.input === null || eventData.output === null) {
          alert(this.language.MutationStackRulesValueCannotBeEmpty);
          continue;
        }
        const referenceStack: IStackRule = eventData;
        const ruleDto: StackCalculationDto = new StackCalculationDto();

        ruleDto.OutputValue = referenceStack.output;

        if (this.isIntervalStack() === false) {
          this.assignExactRule(ruleDto, referenceStack);
        }

        if (this.isIntervalStack() === true) {
          this.assignIntervalRule(ruleDto, referenceStack);
        }

        if (change.type === DevExtremeGridNames.changeTypeInsert) this.currentStackMutation.Rules.push(ruleDto);
        else this.currentStackMutation.Rules[referenceStack.index] = ruleDto;
      }
    }

    this.variantService.saveExistingStackMutation(this.currentStackMutation).subscribe(
      (stackMutationDto: StackMutationDto) => {
        if (stackMutationDto) {
          ObjectUtils.keys(stackMutationDto).forEach((propertyName) => {
            this.currentStackMutation[propertyName] = stackMutationDto[propertyName];
          });
          this.clearPopupSettings();
          this.getRules(stackMutationDto.Rules);
        }
      },
      (ex) => {
        this.stackRules[this.originalRule.index] = this.originalRule;
        this.translateRulesGridDataToStackCalculationDto(this.originalRule, this.currentStackMutation.Rules[this.originalRule.index]);
        if (ex?.error) {
          alert(ex.error);
        } else {
          console.error(ex);
        }
      },
    );
  }

  private translateRulesGridDataToStackCalculationDto(gridData: any, stackCalculationDto: StackCalculationDto) {
    stackCalculationDto.OutputValue = gridData.output;
    stackCalculationDto.ExactRule.InputValue = gridData.input;
    stackCalculationDto.IntervalRule.LowerBound = gridData.lowerBound;
    stackCalculationDto.IntervalRule.UpperBound = gridData.upperBound;
  }

  private newExcactRuleFor(ruleDto: StackCalculationDto) {
    ruleDto.ExactRule = new StackCalculationExactRuleDto();
    ruleDto.ExactRule.InputValue = 1;

    const takenInputs = this.currentStackMutation.Rules.map((value) => value.ExactRule.InputValue);
    while (takenInputs.includes(ruleDto.ExactRule.InputValue)) {
      ruleDto.ExactRule.InputValue++;
    }
  }

  private newIntervalRuleFor(ruleDto: StackCalculationDto) {
    const takenRanges = [];
    let newRange = 0;
    this.currentStackMutation.Rules.forEach((obj) => {
      if (obj.IntervalRule.LowerBound === null && obj.IntervalRule.UpperBound !== null) takenRanges.push(obj.IntervalRule.UpperBound);
      if (obj.IntervalRule.LowerBound !== null && obj.IntervalRule.UpperBound === null) takenRanges.push(obj.IntervalRule.LowerBound);
      for (let i = obj.IntervalRule.LowerBound; i <= obj.IntervalRule.UpperBound; i++) {
        takenRanges.push(i);
        if (obj.IntervalRule.LowerBound === null || obj.IntervalRule.LowerBound === -1) newRange = obj.IntervalRule.UpperBound + 1;
      }
    });

    while (takenRanges.includes(newRange)) {
      newRange++;
    }

    ruleDto.IntervalRule = new StackCalculationIntervalRuleDto();
    ruleDto.IntervalRule.LowerBound = newRange;
    ruleDto.IntervalRule.UpperBound = newRange;
  }

  onInitNewRow(event: any) {
    const ruleDto = new StackCalculationDto();

    ruleDto.OutputValue = this.currentStackMutation.Rules[0].OutputValue;
    event.data.output = ruleDto.OutputValue;
    if (this.isIntervalStack() === false) {
      this.newExcactRuleFor(ruleDto);
      event.data.input = ruleDto.ExactRule.InputValue;
    }

    if (this.isIntervalStack() === true) {
      this.newIntervalRuleFor(ruleDto);
      event.data.lowerBound = ruleDto.IntervalRule.LowerBound;
      event.data.upperBound = ruleDto.IntervalRule.UpperBound;
    }
    this.rulesGrid.instance.refresh();
  }

  addRule() {
    this.rulesGrid.instance.addRow();
  }

  validateRules() {
    let rows = this.rulesGrid.instance.getVisibleRows();
    const changes = this.rulesGrid.instance.option(DevExtremeGridNames.optionEditingChanges) as IDxGridChange[];

    const newStackMutationDto = CopyingObjects.deepCopy(this.currentStackMutation);
    newStackMutationDto.Rules.length = 0;
    for (let row of rows) {
      if (changes.some((change) => change.type === DevExtremeGridNames.changeTypeRemove && change.key === row.key)) continue;
      let newRule = new StackCalculationDto();
      newRule.OutputValue = row.data.output;
      if (this.isValueStack() || this.isReferenceStack()) this.assignExactRule(newRule, row.data);
      if (this.isIntervalStack()) this.assignIntervalRule(newRule, row.data);
      newStackMutationDto.Rules.push(newRule);
    }

    this.variantService.validateStackRules(newStackMutationDto).subscribe((v) => {
      this.isValidationSuccessfull = v.IsSuccess;
      this.validationMessage = v.Message;
    });
  }
}
