import { PresentableElementDto } from "../../../../common/models/dto/PresentableElement-dto";
import { LongNameShortName } from "../../../longnameshortname/longname-shortname";
import { WizardActionType } from "./wizard-action-types";

const ChartOptionValueKey = "value";
const ChartOptionChoiceOptionsKey = "items";
const ChartOptionChoiceDataSourceKey = "dataSource";

export class ConfigurationWizardAction {
  constructor(actionId: string, type: WizardActionType, order: number, label: string) {
    this.actionId = actionId;
    this.type = type;
    this.order = order;
    this.label = label;
    this.typeOptions = {};
    this.value = "";
  }

  public actionId: string;
  public type: WizardActionType;
  public order: number;
  public label: string;
  public typeOptions: object;
  public isOptional: boolean = false;

  public get value() {
    return this.typeOptions[ChartOptionValueKey];
  }
  public set value(val: any) {
    console.log("set of " + this.actionId + "  value to: ", val);
    this.typeOptions[ChartOptionValueKey] = val;
  }

  public get hasValue() {
    if (this.typeOptions && this.typeOptions[ChartOptionValueKey] !== undefined) {
      const val = this.typeOptions[ChartOptionValueKey];
      if (val === undefined || val === "" || val == null) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  public setTextChoices(items: string[]) {
    delete this.typeOptions[ChartOptionChoiceDataSourceKey];
    this.typeOptions[ChartOptionChoiceOptionsKey] = items;
  }

  public setDataSourceDto<T extends PresentableElementDto>(items: T[]) {
    this.setDataSource("KeyId", LongNameShortName.DisplayField(), items);
  }

  public setDataSource<T>(idPropertyName: string, textPropertyName: string, items: T[]) {
    delete this.typeOptions[ChartOptionChoiceOptionsKey];

    this.typeOptions[ChartOptionChoiceDataSourceKey] = items;
    this.typeOptions["displayExpr"] = textPropertyName;
    this.typeOptions["valueExpr"] = idPropertyName;
  }
}
