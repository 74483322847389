<div class="dashboardSharingData">
  <div>{{ ownerName }}</div>
  <div>{{ sharedWith }}</div>
</div>

<dx-button icon="fas fa-share" type="default" [hint]="language.dashboardShare" [text]="language.dashboardShare" (onClick)="onAssignRolesButtonClicked($event)"></dx-button>

<dx-popup
  [width]="400"
  [height]="450"
  [showTitle]="true"
  [title]="language.dashboardShareWithRolesPopupTitle"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="assignRolesPopupVisible"
>
  <dx-list
    [dataSource]="availableRoles"
    [height]="300"
    [selectionMode]="'multiple'"
    [showSelectionControls]="true"
    [searchEnabled]="true"
    searchMode="contains"
    searchExpr="LongName"
    [showScrollbar]="'onHover'"
    [selectedItems]="assignedRoles"
    keyExpr="KeyId"
    (onSelectionChanged)="onListSelectionChanged($event)"
  >
    <div *dxTemplate="let item of 'item'">
      <span>{{ getRoleDisplayName(item) }}</span>
    </div>
  </dx-list>

  <div class="float-right buttondiv">
    <dx-button [text]="language.ButtonOk" (onClick)="onOkButtonClicked($event)"></dx-button>
    <dx-button [text]="language.ButtonCancel" (onClick)="onCancelButtonClicked($event)"></dx-button>
  </div>
</dx-popup>
