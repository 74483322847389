<div class="dashlet-definition-design">
  <div class="grid grid-template" *ngIf="definitionData">
    <div class="grid-item column-one">
      <app-dashlet-definition-design-list [list]="list" (selectionChanged)="onSelectionChanged($event)"></app-dashlet-definition-design-list>
    </div>
    <div *ngFor="let dashletDefinition of selectedDashletDefinitions" class="grid-item column-two">
      <app-dashlet-definition-designer-factory [dashletDefinition]="dashletDefinition" [metaData]="metaData"></app-dashlet-definition-designer-factory>
    </div>
  </div>
</div>
