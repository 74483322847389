import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ClusterDataFilterDto } from "../../analysis/dto/ClusterDataFilter-dto";
import { DataFilter } from "./models/datafilter";
import { DataFilterList } from "./models/datafilter-list";
import { DataFilterOption } from "./models/datafilteroption";
import { DataFilterOptionList } from "./models/datafilteroption-list";

@Injectable()
export class FilterService extends PlBaseService {
  private endPoint: string = "filter";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveFiltersFromApi() {
    return this.getDataTransformed<ClusterDataFilterDto[]>(this.endPoint);
  }

  public getAllClusters(): Observable<ClusterDataFilterDto[]> {
    const result = this.getDataTransformed<ClusterDataFilterDto[]>(this.endPoint + "/clusters");
    return result;
  }

  public static loadDtoOntoList(jsonFilters: ClusterDataFilterDto[], filterList: DataFilterList) {
    const filters: DataFilter[] = [];

    if (jsonFilters) {
      jsonFilters.forEach((jf) => {
        const options: DataFilterOption[] = [];
        jf.Options.forEach((o) => {
          const option = new DataFilterOption(o.Id, o.Name, o.SortOrder, o.FilterBy === true);
          options.push(option);
        });

        const optionList = new DataFilterOptionList(options);
        const filter = new DataFilter(jf.KeyId, jf.ShortName, jf.LongName, jf.IsAnalysisCluster, jf.SortOrder, optionList);
        filters.push(filter);
      });
    }
    filterList.init(filters);
    return filterList;
  }
}
