import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { of } from "rxjs";

import { inject } from "@angular/core";
import { FilterListService } from "./filter-list-service";
import { DataFilterList } from "./models/datafilter-list";

export const filterListResolver: ResolveFn<DataFilterList> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return of(inject(FilterListService).filterList);
};
