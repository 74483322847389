import { ProcessingStateDto } from "../../../../../../common/models/integrationservices/dto/ProcessingState-dto";
import { ProcessingStateTypeDto } from "../../../../../../common/models/integrationservices/dto/ProcessingStateType-dto";
import { UserReferenceDto } from "../../../../../../common/models/integrationservices/dto/UserReference-dto";
import { ProcessingStateStep } from "./processing-state-step.model";
import { ProcessingGroupedSteps } from "./processing-state-steps-grouped";

export class ProcessingState {
  completedAt: Date;
  queuedAt: Date;
  requestedBy: UserReferenceDto;
  state: ProcessingStateTypeDto;
  steps: ProcessingStateStep[];
  stateSteps: ProcessingGroupedSteps[];

  constructor() {
    this.steps = [];
    this.stateSteps = [];
  }

  public get completedAtDate(): Date {
    const date = new Date(this.completedAt);
    return date && date !== new Date() ? date : undefined;
  }

  public get queuedAtDate(): Date {
    const date = new Date(this.queuedAt);
    return date && date !== new Date() ? date : undefined;
  }

  public get queuedAtString() {
    const date = new Date(this.queuedAt);
    if (date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const dateString = day + "-" + month + "-" + year;
      return dateString;
    }

    return "";
  }

  public get stateString(): string {
    return ProcessingStateTypeDto[this.state];
  }

  copyFrom(dto: ProcessingStateDto): void {
    if (dto === undefined) {
      return;
    }

    this.completedAt = dto.CompletedAt;
    this.queuedAt = dto.QueuedAt;
    this.requestedBy = dto.RequestedBy;
    this.state = dto.SummaryState;

    dto.Steps.forEach((dtos) => {
      const s = new ProcessingStateStep();
      s.copyFrom(dtos);
      this.steps.push(s);
    });

    this.stateSteps = ProcessingGroupedSteps.copyFrom(this.steps);
  }

  public get hasQueuedState() {
    if (this.state) {
      if (this.state === ProcessingStateTypeDto.Queued) {
        return true;
      }
    }

    return false;
  }

  public get hasInProgressSate() {
    if (this.state) {
      if (this.state === ProcessingStateTypeDto.Processing) {
        return true;
      }
    }

    return false;
  }

  public get hasFinishedState() {
    if (this.state) {
      if (this.state === ProcessingStateTypeDto.Completed) {
        return true;
      }
      if (this.state === ProcessingStateTypeDto.Error) {
        return true;
      }
    }

    return false;
  }
}
