import { PresentableElement } from "../../../../../../../common/model/models/presentable-element.model";
import { PresentableElementDto } from "../../../../../../../common/models/dto/PresentableElement-dto";

export class Operator extends PresentableElement {
  public constructor() {
    super();
  }

  public static createFrom(value: PresentableElementDto) {
    const res = new Operator();
    res.copyFromDto(value);
    return res;
  }
}
