import { Component, EventEmitter, Input, Output } from "@angular/core";

import { DashboardDefinition } from "../../services/dashboard";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-edit-dashboard",
  templateUrl: "./edit-dashboard.component.html",
  styleUrls: ["./edit-dashboard.component.scss"],
})
export class EditDashboardComponent {
  constructor(public language: ImagineLanguage) {}

  @Input()
  public dashboard: DashboardDefinition;

  @Output()
  public SortOrderHasChanged: EventEmitter<DashboardDefinition> = new EventEmitter<DashboardDefinition>();

  public newName: string;
  public newSortOrder: number;

  public changeDashboard() {
    this.newName = this.dashboard.name;
    this.newSortOrder = this.dashboard.sortOrder;

    this.popupVisible = true;
  }

  public closePopup(confirm: boolean) {
    if (confirm) {
      const sortOrderChanged: boolean = this.dashboard.sortOrder !== this.newSortOrder;

      this.dashboard.name = this.newName;
      this.dashboard.sortOrder = this.newSortOrder;
      this.dashboard.saveChanges().subscribe(() => {
        if (sortOrderChanged) {
          this.SortOrderHasChanged.emit(this.dashboard);
        }
      });
    }
    this.popupVisible = false;
  }

  public get canConfirmPopup(): boolean {
    return this.newName !== undefined && this.newName !== null && this.newName.length > 0;
  }

  public popupVisible: boolean = false;
}
