import { ModelElement } from "../../../../../common/modelelements";
import { ModelElementDto } from "../../../../../common/models/dto/ModelElementDto-dto";
import { ModelGroupDto } from "../../adapt/dto/ModelGroupDto-dto";

export class ModelsGroup {
  public constructor() {}

  public groupId: number;
  public name: string;
  public elements: ModelElement[] = [];

  public copyFromDto(dto: ModelGroupDto) {
    const newElements: ModelElement[] = [];
    for (let i = 0; i < dto.Elements.length; i++) {
      const dtoElemenet = dto.Elements[i];
      const newElement = new ModelElement();
      newElement.copyFromDto(dtoElemenet);
      newElements.push(newElement);
    }

    this.elements = newElements;
    this.groupId = dto.GroupId;
    this.name = dto.Name;
  }

  public toDto(): ModelGroupDto {
    const newElements: ModelElementDto[] = [];
    this.elements.forEach((r) => {
      newElements.push(r.toDto());
    });

    const dto = new ModelGroupDto();
    dto.Elements = newElements;
    dto.GroupId = this.groupId;
    dto.Name = this.name;

    return dto;
  }

  public delete(element: ModelElement) {
    const deleteIndex = this.elements.findIndex((e) => e === element);
    this.elements.splice(deleteIndex, 1);
  }

  public createCopy(): ModelsGroup {
    const copy = new ModelsGroup();
    copy.name = this.name;
    copy.groupId = this.groupId;
    const newElements: ModelElement[] = [];
    this.elements.forEach((r) => {
      let newElement = new ModelElement();
      newElement.copyFromDto(r.toDto());
      newElements.push(newElement);
    });
    copy.elements = newElements;

    return copy;
  }
}
