<div *ngIf="!importDefinition">
  loading..
</div>
<app-cardview [title]="language.datasupplyHeader" *ngIf="importDefinition">
  <dx-form [colCount]="2">
    <dxi-item *ngIf="importDefinition.options.RequiresDataSetSelection || importDefinition.options.CanSupplyDataSetName"
      [colSpan]="2">
      <div *dxTemplate>
        <app-supply-dataset-selector [importDefinition]="importDefinition" (datasetSelected)="datasetSelected($event)"
          (datasetNamed)="datasetNamed($event)"></app-supply-dataset-selector>
      </div>
    </dxi-item>
    <dxi-item [colSpan]="2" *ngIf="importDefinition.options.CanSupplyDataMutationDate">
      <app-data-supply-period-selector [importDefinition]="importDefinition"
        (mutationDateChanged)="mutationDateChanged($event)"></app-data-supply-period-selector>
    </dxi-item>

    <dxi-item [colSpan]="2" *ngIf="importDefinition.options.RequiresSupplyVariant">
      <div *dxTemplate>
        <app-supply-variant-selector (variantIdChanged)="variantIdChanged($event)"></app-supply-variant-selector>
      </div>
    </dxi-item>

    <dxi-item cssClass="file-container" itemType="group" [caption]="nrOfFiles" [colSpan]="2">
      <dxi-item *ngIf="importDefinition.files.files.length > 0" [colSpan]="2">
        <div *dxTemplate>
          <div class="fileUploaderPerFileDefinition" *ngFor="let import of files;let i = index">
            <app-import-file [fileId]="i" [import]="import" [requestDelete]="requestDelete"></app-import-file>
          </div>
        </div>
      </dxi-item>
    </dxi-item>
    <dxi-item [colSpan]="2">
      <div class="button-container align-right" id="toast-host">
        <dx-button [text]="language.datasupplyStartImport" [disabled]="disableStartImport"
          (onClick)="requestImportRun()"></dx-button>
        <dx-button icon="detailslayout" class="button-icon-m  button-right" [disabled]="detailsDisabled"
          [text]="language.datasupplyShowDetails" [hint]="language.datasupplyShowDetailsHint" (onClick)="goToDetails()">
        </dx-button>
      </div>
    </dxi-item>
  </dx-form>
</app-cardview>
<app-toaster [model]="toasterInput"></app-toaster>