<div *ngIf="event" class="tabpanel-item">
  <dx-form [formData]="event" [colCount]="3">
    <dxi-item itemType="group" [caption]="integrationLanguage.eventTimerSettings" [colSpan]="1">
      <dxi-item dataField="eventType">
        <dxo-label [text]="integrationLanguage.eventTimerSelectedEventType"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [value]="event.eventTypeText" [disabled]="true"> </dx-text-box>
        </div>
      </dxi-item>

      <dxi-item dataField="selectedRecurrenceType">
        <dxo-label [text]="integrationLanguage.eventTimerSettingsRecurrenceType"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [value]="event.selectedRecurrenceTypeText" [disabled]="true"> </dx-text-box>
        </div>
      </dxi-item>
      <dxi-item>
        <dxo-label [text]="integrationLanguage.eventTimerEventStartDate"></dxo-label>
        <div *dxTemplate>
          <dx-date-box [value]="event.startDate" type="date" [disabled]="true"></dx-date-box>
        </div>
      </dxi-item>
      <dxi-item>
        <dxo-label [text]="integrationLanguage.eventTimerEventStartTime"></dxo-label>
        <div *dxTemplate>
          <dx-date-box [value]="event.startTime" type="time" [disabled]="true"></dx-date-box>
        </div>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [caption]="integrationLanguage.eventTimerData" [colSpan]="1" [disabled]="true">
      <dxi-item dataField="eventTimerHistory.nextRunText">
        <dxo-label [text]="integrationLanguage.eventTimerDataNextRun"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [value]="event.eventTimerHistory.nextRunText" [disabled]="true">
            <dxi-button name="info" [options]="infoButtonOptions" location="after"></dxi-button>
          </dx-text-box>
        </div>
      </dxi-item>

      <dxi-item dataField="eventTimerHistory.lastRunText" [disabled]="true">
        <dxo-label [text]="integrationLanguage.eventTimerDataLastRun"></dxo-label>
      </dxi-item>
      <dxi-item dataField="timeRunText" [disabled]="true">
        <dxo-label [text]="integrationLanguage.eventTimerDataTimesRun"></dxo-label>
      </dxi-item>
      <dxi-item dataField="active" [disabled]="true">
        <dxo-label [text]="integrationLanguage.eventTimerDataRunning"></dxo-label>
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" [caption]="integrationLanguage.eventTimerAdjust" [colSpan]="1">
      <dxi-item>
        <div *dxTemplate>
          <dx-button class="eventtimer-action-button" [text]="integrationLanguage.ButtonEdit" icon="save" (onClick)="editEvent()"> </dx-button>
        </div>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-button
            class="eventtimer-action-button"
            [text]="event.active ? integrationLanguage.eventTimerPause : integrationLanguage.eventTimerActivate"
            [icon]="event.active ? 'fas fa-pause' : 'fas fa-play-circle'"
            (onClick)="togglePause()"
          ></dx-button>
        </div>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-button class="eventtimer-action-button" [text]="integrationLanguage.eventTimerRefreshStatus" icon="refresh" (onClick)="refreshTimerStatus()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-button class="eventtimer-action-button" [text]="integrationLanguage.eventTimerExecuteNow" icon="fas fa-rocket" (onClick)="executeTimer()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-button class="eventtimer-action-button" [text]="integrationLanguage.ButtonDelete" icon="trash" (onClick)="deleteEvent()"> </dx-button>
        </div>
      </dxi-item>
    </dxi-item>
  </dx-form>
</div>
