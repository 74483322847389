import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { IDownloadHandler } from "../download-handler";
import { ExportDefinition } from "./export-definition.model";

// Wraps a ExportDefinition and download capability in one class so we can separate definition from download
export class DownloadableExportDefinition {
  private objectUrl: string;
  public constructor(private domSanitizer: DomSanitizer, private downloadHandler: IDownloadHandler, public source: ExportDefinition) {}

  public download() {
    const obs = this.downloadHandler.download(this.source);
    obs.subscribe(b => {
      this.releaseDownloadedData();
      if (b !== undefined && b !== null && b.byteLength > 0) {
        const blob = new Blob([b]);
        this.objectUrl = window.URL.createObjectURL(blob);
        this.downloadUrl = this.domSanitizer.bypassSecurityTrustUrl(this.objectUrl);
        this.fileName = this.source.fileName;
        this.error = false;
      } else {
        this.error = true;
        this.downloadUrl = undefined;
      }
    });
  }

  public error: boolean = false;
  public fileName: string;
  public downloadUrl: SafeUrl;
  public get hasDownloadUrl(): boolean {
    return this.downloadUrl !== undefined && this.downloadUrl !== null;
  }

  public releaseDownloadedData() {
    if (this.objectUrl && this.objectUrl !== null) {
      window.URL.revokeObjectURL(this.objectUrl);
    }
  }
}
