import { DataSetEditDto } from "../../../models/dto/DataSetEditDto-dto";

export class Dataset {
  public static toDto(dataset: Dataset): DataSetEditDto {
    const dto = new DataSetEditDto();
    dto.KeyId = dataset.keyId;
    dto.Locked = dataset.locked;
    dto.LongName = dataset.longName;
    dto.ShortName = dataset.shortName;
    dto.StartTime = new Date(dataset.startTime);
    dto.Connection = dataset.connection;
    dto.KeyFields = dataset.keyFields;
    dto.User = dataset.user;
    dto.Description = dataset.description;
    return dto;
  }

  public shortName: string;
  public longName: string;
  public connection: string;
  public description: string;
  public user: string;
  public startTime: string;
  public keyFields: string;
  public table: string;
  public locked: boolean;
  public keyId: number;

  public fromDto(dto: DataSetEditDto) {
    this.shortName = dto.ShortName;
    this.longName = dto.LongName;
    this.connection = dto.Connection;
    this.description = dto.Description;
    this.user = dto.User;
    this.startTime = new Date(dto.StartTime).toLocaleDateString();
    this.keyFields = dto.KeyFields;
    this.locked = dto.Locked;
    this.keyId = dto.KeyId;
  }
}
