import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { DateFormat } from "../../../angular-common/language/date-format";
import { DateTranslator } from "../../../common/modelelements/date-translator";
import { TimePeriod } from "../../../common/modelelements/timeperiod-model";
import { DateDto } from "../../../common/models/dto/DateDto-dto";

@Component({
  selector: "app-period-date-picker",
  templateUrl: "./period-date-picker-component.html",
  styleUrls: ["./period-date-picker-component.scss"],
})
export class PeriodDatePickerComponent implements OnInit {
  @Input() periods: TimePeriod[];
  @Input() disabledPeriods: TimePeriod[];
  @Input() newStartDate: any;
  @Output() selectDateEvent = new EventEmitter<any>();
  public mutationDatesInUse: any[] = [];
  public dateFormat: any = DateFormat.DateByUserLanguage();
  public maxDate: Date = new Date();
  public minDate: Date = new Date();

  ngOnInit() {
    this.maxDate = TimePeriod.getMaxDate(this.periods);
    this.minDate = TimePeriod.getMinDate(this.periods);
  }

  ngOnChanges(): void {
    this.disablePeriods();
  }

  public onValueChanged(event: any) {
    this.selectDateEvent.emit(event.value);
  }

  disablePeriods() {
    this.mutationDatesInUse.length = 0;
    this.disabledPeriods.forEach((period) => {
      if (ElementHelper.isNullOrUndefined(period) === false) {
        let startDateAsDateDto = new DateDto();
        startDateAsDateDto.DateAsSortableNumber = period.startdate;
        let currentDate = DateTranslator.fromDateDto(startDateAsDateDto);

        let endDateAsDateDto = new DateDto();
        endDateAsDateDto.DateAsSortableNumber = period.enddate;
        let endDate = DateTranslator.fromDateDto(endDateAsDateDto);

        while (currentDate <= endDate) {
          this.mutationDatesInUse.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
    });
  }
}
