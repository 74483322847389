import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";
import { DataObjectDto } from "../../../../../common/models/dto/DataObject-dto";
import { DataMutationsAllDataDto } from "../../adapt/dto/DataMutationsAllData-dto";
import { ClientDataRow } from "./models/client-data-row.model";
import { DataMutationsAllData } from "./models/data-mutations-all-data.model";

@Injectable()
export class DataMutationsClientService extends PlBaseService {
  private endPoint: string = "datamutationsclient";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getAllData(): Observable<DataMutationsAllData> {
    const result = this.getDataTransformed<DataMutationsAllDataDto>(this.endPoint).pipe(map(r => this.transformAllData(r)));
    return result;
  }

  private transformAllData(dto: DataMutationsAllDataDto): DataMutationsAllData {
    if (dto) {
      const result = new DataMutationsAllData();
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public deleteClient(identification: any): Observable<void> {
    const url = this.endPoint + "/" + identification;
    return this.remove(url);
  }

  private extractClient(entry: EntryCreated<DataObjectDto>): ClientDataRow {
    if (entry) {
      const json: DataObjectDto = entry.data;
      const result = new ClientDataRow();
      result.copyFromDto(json);
      return result;
    } else {
      return null;
    }
  }

  public saveNewClient(dto: DataObjectDto): Observable<ClientDataRow> {
    const thisService = this;
    return this.createDataTransformed<DataObjectDto>(this.endPoint, dto).pipe(map(r => this.extractClient(r)));
  }
}
