import { Component, Input } from "@angular/core";
import { DataFilterList } from "../../services/filter";

import { PlUserStorage } from "../../../../../angular-common/baseservice/pluserstorage";
import { DashletView } from "../../services/dashletview";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashlet-data-info",
  templateUrl: "./dashlet-data-info.component.html",
  styleUrls: ["./dashlet-data-info.component.scss"],
})
export class DashletDataInfoComponent {
  @Input()
  showTitle: boolean;

  @Input()
  dashletView: DashletView;

  @Input() filterList: DataFilterList;

  constructor(public language: ImagineLanguage) {
    this.showTitle = true;
  }

  public get title() {
    if (this.showTitle && this.dashletView) {
      return this.dashletView.name;
    }
    return "";
  }

  public reload() {
    if (this.dashletView) {
      this.dashletView.reloadViewData();
    }
    return false;
  }

  public get noData() {
    return this.hasData === false;
  }

  public get hasData() {
    if (this.dashletView && this.dashletView.dashletData) {
      // Charts have data
      if (this.dashletView.dashletData.data && this.dashletView.dashletData.data.length > 0) {
        return true;
      }
      // Details have data
      if (this.dashletView.dashletData.rows && this.dashletView.dashletData.rows.length > 0) {
        return true;
      }
    }
    return false;
  }

  public get showReload() {
    return this.show || PlUserStorage.showDebugTools;
  }

  public get show() {
    if (this.noData) {
      return true;
    }
    if (this.hasDataError) {
      return true;
    }

    return false;
  }

  public get hasDataError() {
    return this.dataErrorText.length > 0;
  }

  public get dataErrorText() {
    if (this.dashletView && this.dashletView.dashletData && this.dashletView.dashletData.result.Succes === false) {
      return this.dashletView.dashletData.result.Reason;
    }
    return "";
  }
}
