<div class="dx-fieldset filters">
  <div class="dx-field">
    <div class="dx-field-value">
      <dx-form [colCount]="3" [showColonAfterLabel]="false">
        <dxi-item [colSpan]="3">
          <dxo-label text="{{ language.modelgroupSelectModel }}"></dxo-label>
          <div *dxTemplate>
            <dx-select-box [items]="modelGroups" displayExpr="name" valueExpr="groupId" (onValueChanged)="selectGroup($event.value)"> </dx-select-box>
          </div>
        </dxi-item>
      </dx-form>
    </div>
  </div>
</div>
