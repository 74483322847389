import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { DxChartComponent, DxDataGridComponent, DxPivotGridComponent } from "devextreme-angular";

import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { Subscription } from "rxjs/internal/Subscription";
import { PlUserStorage } from "../../../../../angular-common";
import { DevExpressExcelExport } from "../../../../../angular-common/devextreme/dev-express-export";
import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { AnalysisRoleNamesDto } from "../../analysis/dto/AnalysisRoleNames-dto";
import { DashletWithData } from "../../services/dashletdata";
import { DashletView } from "../../services/dashletview";
import { DashletViewSettingsService } from "../../services/dashletviewsettings/dashlet-view-settings.service";
import { DashletViewSettings } from "../../services/dashletviewsettings/models/dashlet-view-settings";
import { DataFilterList } from "../../services/filter";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { AggregationTypes } from "./pivot-aggregationtypes";
import { DataTypes } from "./pivot-datatypes";
import { PivotDataField } from "./pivotdata-fields";
import { PivotDataValues } from "./pivotdata-values";

@Component({
  selector: "app-dashlet-pivot",
  styleUrls: ["./dashlet-pivot.component.scss"],
  templateUrl: "./dashlet-pivot.component.html",
})
export class DashletPivotComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  public constructor(public language: ImagineLanguage, private dashletSettingsService: DashletViewSettingsService) {
    this.createNewDataSource();
    this.aggregationTypes = new AggregationTypes(this.language);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private subscriptions: Subscription = new Subscription();

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
    this.createNewDataSource();
  }

  private createNewDataSource() {
    const data2 = new PivotGridDataSource({
      fields: this.getFields(),
      store: this.getRows(),
    });
    this.dataSource = data2;
  }

  public get noData(): boolean {
    if (this.dashletWithData && this.dashletWithData.rows && this.dashletWithData.rows.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.createDefaultFields();
    this.loadData();
    this.dashletViewSettings = this.dashletView.dashletViewSettings;
  }

  @ViewChild(DxPivotGridComponent, { static: false }) pivotGrid: DxPivotGridComponent;
  @ViewChild("drillDownDataGrid", { static: false }) drillDownDataGrid: DxDataGridComponent;
  @ViewChild(DxChartComponent, { static: false }) chart: DxChartComponent;

  public rowData: any[] = [];
  private fields: any[] = [];
  public dataSource: PivotGridDataSource;
  public dashletViewSettings: DashletViewSettings;
  private aggregationTypes: AggregationTypes;
  public pivotPopupVisible = false;
  public pivotPopupTitle = "";
  public drillDownDataSource: any;
  public drillDownFields: any[] = [];
  public showRowTotals: boolean = true;
  public showColumnTotals: boolean = true;
  public showDataFieldHeaders: boolean = false;

  @Input()
  dashletView: DashletView;
  @Input()
  dashletWithData: DashletWithData;

  private _filterList: DataFilterList;
  @Input("filterList")
  public get filterList() {
    return this._filterList;
  }

  public set filterList(filter: DataFilterList) {
    this._filterList = filter;

    this.subscriptions.add(
      this.filterList.onApplyFilter.subscribe(() => {
        this.bindChartToPivotTable();
      }),
    );
  }

  public getRows() {
    return this.rowData;
  }

  public getFields() {
    return this.fields;
  }

  public userHasAnalysisDesignRole() {
    return PlUserStorage.hasRole(AnalysisRoleNamesDto.DataAnalysisDesign);
  }

  loadState = () => {
    return this.dashletSettingsService
      .retrieveSettingsWithId(this.dashletWithData.view.dashletViewId)
      .toPromise()
      .then((result) => {
        if (!ElementHelper.isNullOrUndefined(result.chartLayout)) {
          const state = JSON.parse(result.chartLayout);
          return state;
        } else {
          return undefined;
        }
      });
  };

  saveState = (state) => {
    this.dashletViewSettings.chartLayout = JSON.stringify(state);
    this.dashletSettingsService.saveSettingsFromView(this.dashletViewSettings).subscribe();
  };

  ngAfterViewInit(): void {
    this.bindChartToPivotTable();
  }

  private bindChartToPivotTable() {
    if (this.dashletViewSettings && this.dashletViewSettings.showPivotChart) {
      this.pivotGrid?.instance.bindChart(this.chart.instance, {
        dataFieldsDisplayMode: "splitPanes",
        alternateDataFields: false,
      });
    }
  }

  private createDefaultFields() {
    if (this.fields.length === 0) {
      const defaultSet = { column: false, row: false, data: false };
      if (this.dashletWithData.columns) {
        this.dashletWithData.columns.forEach((column) => {
          let place = "";
          if (defaultSet.column === false && column.IsNumber === false) {
            place = "column";
            defaultSet.column = true;
          } else if (defaultSet.row === false && column.IsNumber === false) {
            place = "row";
            defaultSet.row = true;
          }
          if (defaultSet.data === false && column.IsNumber) {
            place = "data";
            defaultSet.data = true;
          }

          this.fields.push(new PivotDataField(column, place));
        });
      }
    }
  }

  private loadData() {
    this.rowData = [];
    if (this.dashletWithData.rows) {
      this.rowData = this.dashletWithData.rows.map((l) => new PivotDataValues(l, this.dashletWithData.columns));
    }
  }

  contextMenuPreparing(e) {
    var dataSource = e.component.getDataSource(),
      sourceField = e.field;

    if (sourceField) {
      if (!sourceField.groupName || sourceField.groupIndex === 0) {
        e.items.push({
          text: this.language.dashletPivotHideField,
          onItemClick: function () {
            var fieldIndex;
            if (sourceField.groupName) {
              fieldIndex = dataSource.getAreaFields(sourceField.area, true)[sourceField.areaIndex].index;
            } else {
              fieldIndex = sourceField.index;
            }

            dataSource.field(fieldIndex, {
              area: null,
            });
            dataSource.load();
          },
        });
      }
      if (sourceField.dataType === DataTypes.Number) {
        var setSummaryType = function (args) {
            dataSource.field(sourceField.index, {
              summaryType: args.itemData.value,
            });

            dataSource.load();
          },
          menuItems = [];

        e.items.push({ text: this.language.PivotAggregationType, items: menuItems });

        for (let summaryType of this.aggregationTypes.all()) {
          var summaryTypeValue = summaryType.type.toLocaleLowerCase();
          menuItems.push({
            text: summaryType.translation,
            value: summaryType.type.toLowerCase(),
            onItemClick: setSummaryType,
            selected: e.field.summaryType === summaryTypeValue,
          });
        }
      }
    }
  }

  onPivotCellClick(e) {
    if (e.area == "data") {
      var rowPathLength = e.cell.rowPath.length,
        rowPathName = e.cell.rowPath[rowPathLength - 1];
      let selectedFields: any[] = [];
      selectedFields = selectedFields.concat(e.rowFields, e.dataFields, e.columnFields);
      this.drillDownFields = selectedFields;
      this.drillDownDataSource = this.dataSource.createDrillDownDataSource(e.cell);
      this.pivotPopupTitle = this.language.dashboardPivotDrillDown(rowPathName ? rowPathName : this.language.dxPivot_Total);
      this.pivotPopupVisible = true;
    }
  }

  public onExporting(e: any) {
    DevExpressExcelExport.onPivotExporting(e, this.dashletView.name);
  }
}
