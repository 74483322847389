<div class="stack-popup">
  <dx-data-grid
    #mutationsGrid
    id="keyStartDateGridContainer"
    [dataSource]="mutationsToShow"
    [showBorders]="true"
    [width]="200"
    (onRowClick)="onRowClick($event)"
    (onRowPrepared)="onRowPrepared($event)"
  >
    <dxo-editing [allowUpdating]="false" [allowDeleting]="false" [useIcons]="true"></dxo-editing>

    <dxi-column
      dataField="KeyStartDate.DateAsSortableNumber"
      caption="{{language.MutationParamMutationDate}}"
      dataType="date"
      format="{{dateFormat}}"
      [allowEditing]="false"
      [calculateDisplayValue]="selectTimePeriod"
    ></dxi-column>
  </dx-data-grid>

  <div class="grid-and-label">
    <div class="validation-label"><label [class.failure-label]="isValidationSuccessfull===false">{{validationMessage}}</label></div>
    <dx-data-grid
      #rulesGrid
      id="rulesGridContainer"
      [dataSource]="stackRules"
      [showBorders]="true"
      [width]="400"
      (onRowUpdating)="onRowUpdating($event)"
      (onSaved)="onRowSaved($event)"
      (onInitNewRow)="onInitNewRow($event)"
    >
      <dxo-editing [mode]="editingMode" [allowUpdating]="canEdit" [allowDeleting]="canEdit" [useIcons]="true"></dxo-editing>
      <dxi-column dataField="index" caption="Row ID" [visible]="false" dataType="number"></dxi-column>
      <dxi-column *ngIf="isValueStack()" dataField="input" caption="{{language.MutationStackInput}}" dataType="number" [sortOrder]="'asc'" [sortIndex]="0"></dxi-column>
      <dxi-column
        *ngIf="isReferenceStack()"
        dataField="input"
        caption="{{language.MutationStackInput}}"
        [sortOrder]="'asc'"
        [sortIndex]="0"
        [calculateDisplayValue]="stackReferance"
        editCellTemplate="editTemplate"
      >
      </dxi-column>
      <dxi-column
        *ngIf="isIntervalStack()"
        dataField="lowerBound"
        caption="{{language.MutationStackLowerBound}}"
        dataType="number"
        cellTemplate="lowerBoundTemplate"
        [sortOrder]="'asc'"
        [sortIndex]="0"
      ></dxi-column>
      <dxi-column *ngIf="isIntervalStack()" dataField="upperBound" caption="{{language.MutationStackUpperBound}}" dataType="number" cellTemplate="upperBoundTemplate"></dxi-column>
      <dxi-column dataField="output" caption="{{language.MutationStackOutput}}" dataType="number"></dxi-column>

      <div *dxTemplate="let cellInfo of 'lowerBoundTemplate'">{{cellInfo.data.lowerBound === null ? '∞' : cellInfo.data.lowerBound}}</div>
      <div *dxTemplate="let cellInfo of 'upperBoundTemplate'">{{cellInfo.data.upperBound === null ? '∞' : cellInfo.data.upperBound}}</div>

      <div *dxTemplate="let cellInfo of 'editTemplate'">
        <dx-select-box [value]="cellInfo.data.input" [items]="referancesForCurrentCategoryToEdit" displayExpr="Text" valueExpr="Id" (onValueChanged)="cellInfo.setValue($event.value)"> </dx-select-box>
      </div>
      <dxo-toolbar [items]="toolbarItems"> </dxo-toolbar>
    </dx-data-grid>
  </div>
</div>
