
// t4tsCodeFromCs": "0.8.2.0 for file DataType.cs
// Don't adjust manually!
export enum DataTypeDto {
        Unknown = -1,
        Integer = 0,
        Float = 1,
        Percentage = 2,
        Date = 3,
        SmallInt = 4,
        String = 5,
        Reference = 6,
        Email = 7,
        PostalCode = 8,
        IBAN = 9,
        TelephoneNumber = 10,
        Boolean = 11,
        LargeInt = 12,
        Guid = 13,
        Decimal = 14,
        Binary = 15,
}

export class DataTypesDto {
}

export class DataTypeCheckExtensionsDto {
}

