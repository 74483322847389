import { Injectable, OnDestroy } from "@angular/core";

import { Subscription } from "rxjs";
import { AuthorizationTabContent } from "../../../../../../../angular-common/components/authorization/component/tabs/authorization-tabs.tab-content";
import { AuthorizationContentType } from "../../../../../../../angular-common/components/authorization/models/authorization-content.types";
import { ElementHelper } from "../../../../../../../angular-common/helpers/element.helper";
import { Sorter } from "../../../../../../../angular-common/sorting/sorter";
import { ModelElementFactory } from "../../../../../../../common/model/models/model-element-factory";
import { ModelElement } from "../../../../../../../common/model/models/model-element.model";
import { Variant } from "../../../../../../../common/model/models/variant-model";
import { ModelElementsAuthorization } from "../../../../../../../common/modelelements/authorization/model-elements-authorization.model";
import { RoleDto } from "../../../../../../../common/models/dto/RoleDto-dto";
import { VariantDto } from "../../../../../../../common/models/dto/VariantDTO-dto";
import { DataSetFilterViewType } from "../../../../authorization-screen/authorization-content/filters/dataSet-filterviewtype";
import { DataSetFilterDto } from "../../../../models/dto/DataSetFilterDto-dto";
import { DataSetFilterElementDto } from "../../../../models/dto/DataSetFilterElementDto-dto";
import { ImagineLanguage } from "../../../language/imaginelanguage.service";
import { AuthorizationFilterService } from "../authorization-filter.service";
import { CompareType } from "./authorization-compareType-model";
import { AuthorizationFilter } from "./authorization-filter-model";
import { AuthorizationFilters } from "./authorization-filters-model";
import { ExpressionDisplayPresenter } from "./expression-display-presenter";

@Injectable()
export class AuthorizationFilterTabContent extends AuthorizationTabContent implements OnDestroy {
  public filters: AuthorizationFilters;
  public roles: RoleDto[];
  public variants: Variant[];
  public compareTypes: CompareType[];
  public modelElements: ModelElementsAuthorization;
  public displayPresenter: ExpressionDisplayPresenter;
  public dataSetFilters: DataSetFilterDto[];
  public dataSetFiltersWithoutVariant: DataSetFilterDto[];
  public dataSetFilterView: IDataSetFilteritem[];
  public dataSetFilterViewType: DataSetFilterViewType = DataSetFilterViewType.ROLE;
  public metaDataReady = false;
  public subscriptions = new Subscription();
  public filtersWarningMessage: string;

  constructor(public authorizationService: AuthorizationFilterService, private language: ImagineLanguage) {
    super(AuthorizationContentType.FilterRole);
    this.displayPresenter = new ExpressionDisplayPresenter();

    this.saveDataSetFilter = this.saveDataSetFilter.bind(this);
    this.getMetaData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public getMetaData() {
    this.subscriptions.add(
      this.authorizationService.getAuthorizationFilterMetadata().subscribe((metaData) => {
        if (!metaData) {
          return;
        }
        this.dataSetFiltersWithoutVariant = [];

        this.dataSetFilters = metaData.DataSetFilters.map((f) => {
          if (ElementHelper.isNullOrUndefined(f.Variant)) {
            this.dataSetFiltersWithoutVariant.push(f);
            f.Variant = this.createTemporaryVariant();
          }

          return f;
        });
        this.saveDataSetFilter = this.saveDataSetFilter;

        this.filtersWarningMessage = this.getBaseModelWarningText();

        this.variants = metaData.Variants.map((v) => <Variant>ModelElementFactory.create(v));
        this.roles = metaData.Roles;
        Sorter.sortOfDisplayName(this.variants);
        Sorter.sortAlphabetically(this.roles, (r) => r.LongName);

        const newFilters = new AuthorizationFilters();

        this.dataSetFilters.forEach((dsf) => {
          newFilters.add(this.newAuthorizationFilter(dsf));
        });

        this.compareTypes = metaData.CompareTypes.map((compareType) => CompareType.copyFromDto(compareType));
        this.modelElements = new ModelElementsAuthorization(
          metaData.ModelElements.map((dto) => {
            return <ModelElement>ModelElementFactory.create(dto);
          }),
        );
        this.displayPresenter.init(this.compareTypes, this.modelElements);

        this.filters = newFilters;
        this.updateDataSetFilterView();
        this.metaDataReady = true;
      }),
    );
  }

  private createTemporaryVariant(): VariantDto {
    const tempVariant = new VariantDto();
    tempVariant.LongName = this.language.NotFound;
    tempVariant.ShortName = this.language.NotFound;
    tempVariant.DataSetId = -1;
    tempVariant.KeyId = -1;

    return tempVariant;
  }

  public saveDataSetFilter(variant: Variant, role: RoleDto, element?: DataSetFilterElementDto) {
    const variantDto = variant.toVariantDto();
    const dataSetFilter = this.findExistingFilter(variantDto, role);

    if (dataSetFilter) {
      this.updateDataSetFilter(dataSetFilter, element);
    } else {
      this.createDataSetFilter(variantDto, role);
    }
  }

  public deleteElementFromDataSetFilter(variant: VariantDto, role: RoleDto, element: DataSetFilterElementDto): void {
    const dataSetFilter = this.findExistingFilter(variant, role);
    if (dataSetFilter) {
      dataSetFilter.Elements = dataSetFilter.Elements.filter((e) => e.ShortName !== element.ShortName);
      this.updateDataSetFilter(dataSetFilter);
    }
  }

  public deleteDataSetFilter(filterId: number): void {
    const dataSetFilter = this.dataSetFilters.find((x) => x.Id === filterId);

    if (dataSetFilter) {
      this.subscriptions.add(
        this.authorizationService.deleteDataSetFilter(dataSetFilter).subscribe((_) => {
          this.dataSetFilters = this.dataSetFilters.filter((dsf) => dsf.Id !== dataSetFilter.Id);
          this.filters.delete(this.newAuthorizationFilter(dataSetFilter));
          this.updateDataSetFilterView();
        }),
      );
    }
  }

  public updateDataSetFilterViewType(type: DataSetFilterViewType) {
    this.dataSetFilterViewType = type;
    this.updateDataSetFilterView();
  }

  private findExistingFilter(variant: VariantDto, role: RoleDto): DataSetFilterDto {
    if (variant) {
      return this.dataSetFilters.find((x) => x.Variant.KeyId === variant.KeyId && x.Role.KeyId === role.KeyId);
    }
  }

  private createDataSetFilter(variant: VariantDto, role: RoleDto): void {
    const newDataSetFilter = new DataSetFilterDto();
    newDataSetFilter.Variant = variant;
    newDataSetFilter.Role = role;

    this.subscriptions.add(
      this.authorizationService.saveNewDataSetFilter(newDataSetFilter).subscribe((result) => {
        if (result.data !== null) {
          this.dataSetFilters.push(result.data);
          this.updateDataSetFilterView();
          this.filters.add(this.newAuthorizationFilter(result.data));
        }
      }),
    );
  }

  private updateDataSetFilter(dataSetFilter: DataSetFilterDto, element?: DataSetFilterElementDto) {
    if (element) {
      dataSetFilter.Elements = dataSetFilter.Elements.filter((e) => e.ShortName !== element.ShortName);
      dataSetFilter.Elements.push(element);
    }

    this.subscriptions.add(
      this.authorizationService.saveExistingDataSetFilter(dataSetFilter).subscribe((_) => {
        this.updateFilter(dataSetFilter);
        this.updateDataSetFilterView();
      }),
    );
  }

  private updateFilter(dataSetFilter: DataSetFilterDto): void {
    this.filters.addOrReplace(this.newAuthorizationFilter(dataSetFilter));
  }

  private newAuthorizationFilter(filterDto: DataSetFilterDto): AuthorizationFilter {
    const originalVariant = this.variants.find((x) => x.id === filterDto.Variant.KeyId);
    const originalVariantId = originalVariant === undefined ? -1 : originalVariant.id;

    const filter = AuthorizationFilter.newEmptyFilter(filterDto.Role.KeyId, originalVariantId);
    filter.copyFromDto(filterDto, this.compareTypes);
    return filter;
  }

  private updateDataSetFilterView(): void {
    if (this.dataSetFilters) {
      if (this.dataSetFilterViewType === DataSetFilterViewType.ROLE) {
        this.dataSetFilterView = this.createRoleBasedFilterView();
      } else if (this.dataSetFilterViewType === DataSetFilterViewType.VARIANT) {
        this.dataSetFilterView = this.createVariantBasedFilterView();
      } else {
        this.dataSetFilterView = this.createBaseModelBasedFilterView();
      }
    }
  }

  private isVariantBasicModel(variant: VariantDto): boolean {
    return variant.KeyId === 0;
  }

  private createBaseModelBasedFilterView(): IDataSetFilteritem[] {
    const filters: IDataSetFilteritem[] = [];
    this.dataSetFilters.forEach((filter) => {
      const filterId = filter.Id;
      const role = filter.Role;
      const title = filter.Role.ShortName;
      const elements = filter.Elements;
      const variant = filter.Variant;

      if (this.isVariantBasicModel(variant)) {
        filters.push({ title, role, variant: variant, content: [{ filterId, elements, description: "" }] });
      }
    });

    return filters
      .map((filter, index) => {
        const foundExistingRoleIndex = filters.findIndex((f) => f.role.KeyId === filter.role.KeyId);
        if (foundExistingRoleIndex !== index) {
          filter.content.forEach((c) => filters[foundExistingRoleIndex].content.push(c));
          return;
        }

        return filter;
      })
      .filter((filter) => filter !== undefined);
  }

  private createRoleBasedFilterView(): IDataSetFilteritem[] {
    const filters: IDataSetFilteritem[] = [];
    this.dataSetFilters.forEach((filter) => {
      const filterId = filter.Id;
      const role = filter.Role;
      const title = filter.Role.ShortName;
      const description = filter.Variant.ShortName;
      const variant = filter.Variant;
      const elements = filter.Elements;

      filters.push({ title, role, variant: variant, content: [{ filterId, description, elements }] });
    });

    return filters
      .map((filter, index) => {
        const foundExistingRoleIndex = filters.findIndex((f) => f.role.KeyId === filter.role.KeyId);
        if (foundExistingRoleIndex !== index) {
          filter.content.forEach((c) => filters[foundExistingRoleIndex].content.push(c));
          return;
        }

        return filter;
      })
      .filter((filter) => filter !== undefined);
  }

  private createVariantBasedFilterView(): IDataSetFilteritem[] {
    const filters: IDataSetFilteritem[] = this.dataSetFilters.map((filter) => {
      const filterId = filter.Id;
      const title = filter.Variant.ShortName;
      const variant = filter.Variant;
      const description = filter.Role.ShortName;
      const role = filter.Role;
      const elements = filter.Elements;

      return { title, variant, role: role, content: [{ filterId, description, role, elements }] };
    });

    return filters
      .map((filter, index) => {
        const foundExistingVariantIndex = filters.findIndex((f) => f.variant.KeyId === filter.variant.KeyId);
        if (foundExistingVariantIndex !== index) {
          filter.content.forEach((c) => filters[foundExistingVariantIndex].content.push(c));
          return;
        }

        return filter;
      })
      .filter((filter) => filter !== undefined);
  }

  private getVariantsNamesFromFiltersWithoutBaseModel(): string {
    const filtersWithoutBaseModel = this.dataSetFilters.filter((f) => f.Variant.KeyId !== 0);

    return [...new Set(filtersWithoutBaseModel.map((v) => v.Variant.ShortName))].join(", ");
  }

  private getBaseModelWarningText(): string {
    if (this.dataSetFilterViewType === DataSetFilterViewType.BASEMODEL) {
      const variantsNames = this.getVariantsNamesFromFiltersWithoutBaseModel();

      return variantsNames.length > 0 ? `${this.language.authorizationFilterActionsBaseModelWarning(variantsNames)}` : "";
    }
  }
}

export interface IDataSetFilteritem {
  title: string;
  role: RoleDto;
  variant: VariantDto;
  content: IDataSetFilteritemContent[];
}

export interface IDataSetFilteritemContent {
  filterId: number;
  description: string;
  elements: DataSetFilterElementDto[];
}
