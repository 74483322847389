<div class="configuration-main-container">
  <app-cardview>
    <dx-form [colCount]="2">
      <dxi-item [colSpan]="2">
        <dxo-label [text]="language.vismaFileApiConfigurationTenantId"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [(value)]="configuration.TenantId">
            <dx-validator>
              <dxi-validation-rule type="required" [message]="language.vismaFileApiConfigurationValidationTenantId"> </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </dxi-item>
      <dxi-item [colSpan]="2">
        <dxo-label [text]="language.vismaFileApiConfigurationClientId"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [(value)]="configuration.ClientId" [inputAttr]="{ autocomplete: 'new-password' }">
            <dx-validator>
              <dxi-validation-rule type="required" [message]="language.vismaFileApiConfigurationValidationClientId"> </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </dxi-item>
      <dxi-item [colSpan]="2" name="clientSecret">
        <dxo-label [text]="language.vismaFileApiConfigurationClientSecret"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [(value)]="configuration.ClientSecret" mode="password" [inputAttr]="{ autocomplete: 'new-password' }">
            <dx-validator>
              <dxi-validation-rule type="required" [message]="language.vismaFileApiConfigurationValidationClientSecret"> </dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
      </dxi-item>
    </dx-form>
    <div>
      <div class="buttons-bottom-margin align-right">
        <dx-button id="saveButton" icon="save" [text]="language.ButtonSave" (onClick)="onSave()" [disabled]="!allDataPresent" class="config-button"></dx-button>
        <dx-button id="deleteButton" icon="trash" [text]="language.ButtonDelete" (onClick)="onDelete()" [disabled]="!allDataPresent" class="config-button"></dx-button>
      </div>
    </div>
  </app-cardview>
</div>
