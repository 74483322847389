import { IModelWithIdDto } from './IModelWithId-dto';
import { IPresentableElementDto } from './IPresentableElement-dto';

// t4tsCodeFromCs": "0.8.2.0 for file DataSetDefinitionDto.cs
// Don't adjust manually!

export class DataSetDefinitionDto implements IPresentableElementDto {
    public Connection: string;
    public Description: string;
    public StartTime: Date;
    public KeyFields: string;
    public KeyId: number;
    public LongName: string;
    public ShortName: string;
    public SortOrder: number;
}

