import { WizardActionType } from "../../../../../angular-common/components/configwizard/models/wizard-action-types";
import { ConfigurationWizardAction } from "../../../../../angular-common/components/configwizard/models/wizardaction";
import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { IntegrationServiceLanguage } from "../../../../../common/language/integrationservicelanguage.service";
import { EventTimerTypeDto } from "../../../../../common/models/integrationservices/dto/EventTimerType-dto";
import { ImagineFeaturesModel } from "../../imagine-features-model";

export class EventTypeWizardAction extends ConfigurationWizardAction {
  private values: { id: EventTimerTypeDto; text: string }[];
  constructor(id: string, order: number, language: IntegrationServiceLanguage, featureSettings: ImagineFeaturesModel) {
    super(id, WizardActionType.dxSelectBox, order, language.eventTimerEventActionType);

    var items: { id: EventTimerTypeDto; text: string }[] = [];
    items.push({ id: EventTimerTypeDto.Export, text: language.eventTimerEventType_Export });
    items.push({ id: EventTimerTypeDto.Import, text: language.eventTimerEventType_Import });
    items.push({ id: EventTimerTypeDto.ComputeVariant, text: language.eventTimerEventType_ComputeVariant });

    if (featureSettings.data?.SDWorx?.enabled === true) {
      items.push({ id: EventTimerTypeDto.MySdWorxActivation, text: language.eventTimerEventType_MySdWorxActivation });
      items.push({ id: EventTimerTypeDto.MySdWorxSync, text: language.eventTimerEventType_MySdWorxSync });
    }

    this.setDataSource("id", "text", items);
    this.values = items;
  }

  public get eventType() {
    const item = this.values.find((v) => v.id === this.value);
    if (item) {
      return item.id;
    }
  }

  public set eventType(valueType: EventTimerTypeDto) {
    this.value = valueType;
  }

  public isEventActionEnabled(eventType: EventTimerTypeDto): boolean {
    const value = this.values.find((v) => v.id === eventType);

    if (ElementHelper.isNullOrUndefined(value) === false) {
      return true;
    }
    return false;
  }

  public get isImportevent(): boolean {
    return this.value === EventTimerTypeDto.Import;
  }

  public get isExportevent(): boolean {
    return this.value === EventTimerTypeDto.Export;
  }

  public get isMySdWorxActivationEvent(): boolean {
    return this.value === EventTimerTypeDto.MySdWorxActivation;
  }

  public get isMySdWorxSyncEvent(): boolean {
    return this.value === EventTimerTypeDto.MySdWorxSync;
  }

  public get isComputeVariantEvent(): boolean {
    return this.value === EventTimerTypeDto.ComputeVariant;
  }
}
