import { RouterModule, Routes } from "@angular/router";

import { NgModule } from "@angular/core";
import { canActivateRoleGuard } from "../../../angular-common/authentication-guard/role-guard";
import { PaletteModule } from "../../../angular-common/components/palettes/palette-module";
import { Palettes } from "../../../angular-common/components/palettes/palettes";
import { DashboardingRoutesDto } from "./analysis/dto/DashboardingRoutes-dto";
import { ClusterEditScreenComponent } from "./dashboarding-screen/cluster-edit-screen/cluster-edit-screen.component";
import { DashboardingScreenComponent } from "./dashboarding-screen/dashboarding-screen.component";
import { DashboardingScreenModule } from "./dashboarding-screen/dashboarding-screen.module";
import { DashboardingDesignScreenComponent } from "./dashboardingdesign-screen/dashboarding-design-screen.component";
import { DashboardingDesignScreenModule } from "./dashboardingdesign-screen/dashboarding-design-screen.module";
import { DashboardModule } from "./services/dashboard/dashboard.module";
import { DashletDataModule } from "./services/dashletdata/dashlet-data.module";
import { DashletDefinitionModule } from "./services/dashletdefinition/dashlet-definition.module";
import { DashletViewModule } from "./services/dashletview/dashlet-view.module";
import { filterListResolver } from "./services/filter/filterlist.resolver";

const routes: Routes = [
  {
    path: DashboardingRoutesDto.DashboardingScreen + "/:id",
    component: DashboardingScreenComponent,
    canActivate: [canActivateRoleGuard],
    resolve: {
      filterList: filterListResolver,
    },
  },
  {
    path: DashboardingRoutesDto.DashboardingScreen,
    component: DashboardingScreenComponent,
    canActivate: [canActivateRoleGuard],
    resolve: {
      filterList: filterListResolver,
    },
  },
  {
    path: DashboardingRoutesDto.DashboardingDesignScreen,
    component: DashboardingDesignScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
  {
    path: DashboardingRoutesDto.AnalysisClusterEditScreen,
    component: ClusterEditScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), DashboardingScreenModule, DashletDefinitionModule, DashboardingDesignScreenModule, DashboardModule, DashletDataModule, DashletViewModule, PaletteModule],
  providers: [Palettes],
})
export class AppImagineDashboardModule {}
