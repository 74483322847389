import { Component, OnInit } from "@angular/core";
import { ToasterInput } from "../../../../angular-common/components/toaster/toaster-input-model";
import { SDConnectProvisioningMessageStatusDto } from "./dto/SDConnectProvisioningMessageStatusDto-dto";
import { SDWorxProvisioningMessageStatusService } from "./sdworx-provisioning-messagestatus.service";
import { SDWorxLanguage } from "./sdworxlanguage.service";

@Component({
  selector: "app-sdworx-provisioning-messagestatus",
  templateUrl: "./sdworx-provisioning-messagestatus.html",
  styleUrls: ["./sdworx-provisioning-messagestatus.scss"],
})
export class SDWorxProvisioningMessageStatusComponent implements OnInit {
  constructor(public language: SDWorxLanguage, private provisioningMessageStatusService: SDWorxProvisioningMessageStatusService) {
    this.toasterInput = new ToasterInput();

    this._maxDate = new Date();
    this._minDate = new Date();

    this._minDate.setDate(this._maxDate.getDate() - 7);
    this.allMessageStatuses = [];
  }

  ngOnInit(): void {
    this.loadMessagesForSelectedDateRange();
  }

  public toasterInput: ToasterInput;

  loadMessagesForSelectedDateRange(): void {
    this.provisioningMessageStatusService.messageStatusDates(this._minDate, this._maxDate).subscribe((s) => {
      this.allMessageStatuses = s;
    });
  }

  private _minDate: Date;
  public get minDate(): string | number | Date {
    return this._minDate;
  }
  public set minDate(value: string | number | Date) {
    this._minDate = new Date(value);
    this.loadMessagesForSelectedDateRange();
  }

  private _maxDate: Date;
  public get maxDate(): string | number | Date {
    return this._maxDate;
  }
  public set maxDate(value: string | number | Date) {
    this._maxDate = new Date(value);
    this.loadMessagesForSelectedDateRange();
  }

  public refreshMessagesStatus() {
    this.provisioningMessageStatusService.refreshStatusMessages().subscribe(() => {
      this.toasterInput.showSucces(this.language.sdWorxProvisioningMessageStatusRefreshScheduled);
    });
  }

  public allMessageStatuses: SDConnectProvisioningMessageStatusDto[];
}
