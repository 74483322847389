import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DxButtonModule, DxRadioGroupModule } from "devextreme-angular";
import { RadioGroupComponent } from "./radiogroup.component";

@NgModule({
  imports: [CommonModule, DxRadioGroupModule, DxButtonModule],
  declarations: [RadioGroupComponent],
  exports: [RadioGroupComponent],
})
export class RadioGroupModule {}
