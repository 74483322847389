import { ProcessingStateTypeDto } from "../../../../../../common/models/integrationservices/dto/ProcessingStateType-dto";
import { ProcessingStateStep } from "./processing-state-step.model";

export class ProcessingGroupedSteps {
  public static copyFrom(steps: ProcessingStateStep[]): ProcessingGroupedSteps[] {
    const result: ProcessingGroupedSteps[] = [];

    for (const stateType in ProcessingStateTypeDto) {
      if (stateType) {
        const stateSteps = steps.filter(step => step.state === parseInt(stateType, 10));
        if (stateSteps.length === 0) {
          continue;
        }

        const newGroup = new ProcessingGroupedSteps();
        newGroup.state = parseInt(stateType, 10);
        newGroup.steps = stateSteps;

        result.push(newGroup);
      }
    }

    return result;
  }
  state: ProcessingStateTypeDto;
  steps: ProcessingStateStep[];
  constructor() {}
}
