import { ParameterElementWithMutationsDto } from './../../../../../common/models/dto/ParameterElementWithMutationsDto-dto';
import { TimePeriodDto } from './../../../../../common/models/dto/TimePeriodDto-dto';

// t4tsCode gen": "0.8.2.0 for file ParameterSimulation.cs
// Don't adjust manually!

export class ParameterSimulationDto {
    public SimulationVariantId: number;
    public Parameters: ParameterElementWithMutationsDto [] = [];
    public Periods: TimePeriodDto [] = [];
}

