import { ModelElementOption } from "../../../../../../common/modelelements";
import { ElementTypeDto } from "../../../../../../common/models/dto/ElementType-dto";
import { ModelElementOptionDto } from "../../../../../../common/models/dto/ModelElementOptionDto-dto";
import { ParameterElementWithMutationsDto } from "../../../../../../common/models/dto/ParameterElementWithMutationsDto-dto";
import { ParameterMutationDto } from "../../../../../../common/models/dto/ParameterMutationDto-dto";
import { ParameterTypeDto } from "../../../../../../common/models/dto/ParameterType-dto";
import { ParameterMutation } from "./parametermutations";

export class Parameter {
  public static toDto(parameter: Parameter): ParameterElementWithMutationsDto {
    const newDto = new ParameterElementWithMutationsDto();
    newDto.ShortName = parameter.shortName;
    newDto.LongName = parameter.longName;
    newDto.TypeOfElement = parameter.elementType;
    newDto.HasBounds = parameter.hasBounds;
    newDto.UpperBound = parameter.upperbound;
    newDto.LowerBound = parameter.lowerBound;
    newDto.ParameterType = parameter.parameterType;
    newDto.ReferenceCategoryId = parameter.referenceCategoryId;

    const newOptions: ModelElementOptionDto[] = [];
    parameter.options.forEach(option => {
      newOptions.push(ModelElementOption.toDto(option));
    });

    newDto.Options = newOptions;

    const newMutations: ParameterMutationDto[] = [];
    parameter.mutations.forEach(mutation => {
      newMutations.push(ParameterMutation.toDto(mutation));
    });

    newDto.ParameterMutations = newMutations;

    return newDto;
  }

  shortName: string;
  longName: string;
  dataSetId: number;
  options: ModelElementOption[];
  elementType: ElementTypeDto;
  mutations: ParameterMutation[];
  lowerBound: number;
  upperbound: number;
  hasBounds: boolean;
  parameterType: ParameterTypeDto;
  referenceCategoryId: number;

  copyFrom(value: Parameter) {
    const newPara = new Parameter();
    newPara.shortName = value.shortName;
    newPara.longName = value.longName;
    newPara.lowerBound = value.lowerBound;
    newPara.upperbound = value.upperbound;
    newPara.mutations = value.mutations;
    newPara.hasBounds = value.hasBounds;
    newPara.dataSetId = value.dataSetId;
    newPara.options = value.options;
    newPara.elementType = value.elementType;
    newPara.parameterType = value.parameterType;
    newPara.referenceCategoryId = value.referenceCategoryId;

    return newPara;
  }

  fromDto(dto: ParameterElementWithMutationsDto) {
    const options = dto.Options.map(option => new ModelElementOption(option.Id, option.Text));

    this.shortName = dto.ShortName;
    this.longName = dto.LongName;
    this.options = options;
    this.elementType = dto.TypeOfElement;
    this.lowerBound = dto.LowerBound;
    this.upperbound = dto.UpperBound;
    this.hasBounds = dto.HasBounds;
    this.parameterType = dto.ParameterType;
    this.referenceCategoryId = dto.ReferenceCategoryId;

    const newMutations: ParameterMutation[] = [];
    dto.ParameterMutations.forEach(mutation => {
      const newMuta = new ParameterMutation();
      newMuta.fromDto(mutation);
      newMutations.push(newMuta);
    });
    this.mutations = newMutations;
  }
}
