import { PresentableElement } from "../../../../../../../common/model/models/presentable-element.model";
import { PresentableElementDto } from "../../../../../../../common/models/dto/PresentableElement-dto";

export class Cluster extends PresentableElement {
  public constructor() {
    super();
  }

  public static createFrom(value: PresentableElementDto) {
    const res = new Cluster();
    res.copyFromDto(value);
    return res;
  }
}
