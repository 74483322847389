import { Component, Input } from "@angular/core";
import { DataFilterList } from "../../services/filter";

import { DashletDataPoint } from "../../services/dashletdata";
import { DashletView } from "../../services/dashletview";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashlet-statistic",
  templateUrl: "./dashlet-statistic.component.html",
  styleUrls: ["./dashlet-statistic.component.scss"],
})
export class DashletStatisticComponent {
  get divId(): string {
    return "dashlet" + this.dashletView.dashletViewId;
  }

  get isCompareStat(): boolean {
    return this.dashletView.data.filter((v) => DashletStatisticComponent.isCompareToSourceByValue(v, true)).length > 0;
  }

  get clusterNameCurrent(): string {
    return DashletStatisticComponent.firstClusterNameOrEmpty(this.currentDataPoint());
  }

  get clusterValueCurrent(): string {
    return this.currentDataPoint().valueAsText;
  }

  get clusterNameCompare(): string {
    return DashletStatisticComponent.firstClusterNameOrEmpty(this.compareToDataPoint());
  }

  get clusterValueCompare(): string {
    return this.compareToDataPoint().valueAsText;
  }

  get clusterValueCurrentToCompareDifference(): string {
    if (this.currentDataPoint() && this.currentDataPoint().difference) {
      return this.currentDataPoint().difference.valueAsText;
    } else {
      return "";
    }
  }

  get clusterValueCurrentToCompareDifferenceRelative(): string {
    if (this.currentDataPoint() && this.currentDataPoint().differenceRelative) {
      return this.currentDataPoint().differenceRelative.valueAsText;
    } else {
      return "";
    }
  }

  get clusterValueEqual(): boolean {
    return this.compareToDataPoint().value === this.currentDataPoint().value;
  }

  get clusterValueLarger(): boolean {
    return this.currentDataPoint().value > this.compareToDataPoint().value;
  }

  get clusterValueSmaller(): boolean {
    return this.currentDataPoint().value < this.compareToDataPoint().value;
  }

  static firstClusterNameOrEmpty(dataPoint: DashletDataPoint): string {
    if (dataPoint !== undefined && dataPoint.clusteredBy !== undefined && dataPoint.clusteredBy.length > 0) {
      return dataPoint.clusteredBy[0].value;
    }
    return "";
  }
  showToolTipForValue = false;

  public constructor(public language: ImagineLanguage) {}

  @Input() dashletView: DashletView;
  @Input() filterList: DataFilterList;

  toggleTooltip() {
    this.showToolTipForValue = !this.showToolTipForValue;
  }

  public get numberOfDataPoints() {
    if (this.dashletView && this.dashletView.data) {
      return this.dashletView.data.length;
    }
    return 0;
  }

  currentDataPoint(): DashletDataPoint {
    if (this.numberOfDataPoints === 1) {
      return this.dashletView.data[0];
    } else if (this.numberOfDataPoints > 1) {
      const matchingPoints = this.dashletView.data.filter((f) => DashletStatisticComponent.isCompareToSourceByValue(f, false));
      if (matchingPoints.length > 0) {
        return matchingPoints[0];
      }
    }
    return new DashletDataPoint();
  }

  compareToDataPoint(): DashletDataPoint {
    if (this.numberOfDataPoints > 1) {
      const matchingPoints = this.dashletView.data.filter((f) => DashletStatisticComponent.isCompareToSourceByValue(f, true));
      if (matchingPoints.length > 0) {
        return matchingPoints[0];
      }
    }
    return new DashletDataPoint();
  }

  private static isCompareToSourceByValue(dp: DashletDataPoint, isCompareSourceValue: boolean) {
    const sourceClusters = dp.clusteredBy.filter((c) => c.isDataSourceCluster);
    if (sourceClusters.length > 0) {
      if (sourceClusters[0].isDataSourceClusterComparingTo == isCompareSourceValue) {
        return true;
      }
    }
    return false;
  }
}
