
// t4tsCodeFromCs": "0.8.2.0 for file DxGridDataType.cs
// Don't adjust manually!

export class DxGridDataTypeDto {
    public static Numeric: string = "number";
    public static Date: string = "date";
    public static DateTime: string = "datetime";
    public static Boolean: string = "boolean";
    public static String: string = "string";
    public static Object: string = "object";
    public static Lookup: string = "number";
}

