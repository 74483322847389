import { ImportDefinition } from "./import-definition.model";
import { ImportSpecificationDto } from "../../../import/dto/ImportSpecificationDto-dto";

export class ImportDefinitionList {
  private _imports: ImportDefinition[] = [];

  public constructor() {}

  // Get
  public get imports(): ImportDefinition[] {
    return this._imports;
  }

  public copyFromDTO(dto: ImportSpecificationDto[]) {
    if (dto && dto !== null && dto.length > 0) {
      dto.forEach((dd) => {
        const newImportDefinition = new ImportDefinition();
        newImportDefinition.copyFrom(dd);
        this._imports.push(newImportDefinition);
      });
    }
  }
}
