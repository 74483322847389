import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxButtonModule, DxTreeListModule } from "devextreme-angular";

import { ModelTreeComponent } from "./modeltree-component";

@NgModule({
  imports: [CommonModule, DxTreeListModule, DxButtonModule],
  declarations: [ModelTreeComponent],
  exports: [ModelTreeComponent],
})
export class ModelTreeModule {}
