
// t4tsCode gen": "0.8.2.0 for file DashboardingRoutes.cs
// Don't adjust manually!

export class DashboardingRoutesDto {
    public static DashboardingScreen: string = "dashboarding-screen";
    public static DashboardingDesignScreen: string = "dashboarding-design-screen";
    public static AnalysisClusterEditScreen: string = "analysis-clusteredit-screen";
}

