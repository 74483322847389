import { ExportDefinition } from "./export-definition.model";
import { ExportDefinitionDto } from '../dto/ExportDefinition-dto';
import { IDownloadHandler } from "../download-handler";
import { Observable } from "rxjs";
import { Sorter } from "../../../sorting";

export class ExportDefinitions {
  private _all: ExportDefinition[] = [];
  public constructor(private downloadHandler: IDownloadHandler, exportDefinitions: ExportDefinition[]) {
    if (exportDefinitions && exportDefinitions !== null) {
      this._all = exportDefinitions;
    }
  }

  public get all(): ExportDefinition[] {
    return this._all;
  }

  public copyFromDto(dto: ExportDefinitionDto[]) {
    dto.forEach(ed => {
      const newDef = new ExportDefinition();
      newDef.copyFromDto(ed);
      this._all.push(newDef);
    });

    Sorter.sortSortOrderThenAlpha(this._all, ed => ed.longName);
  }

  public get count(): number {
    return this._all.length;
  }

  public download(definition: ExportDefinition): Observable<ArrayBuffer> {
    return this.downloadHandler.download(definition);
  }
}
