<div class="dashboard">
  <div *ngIf="dashboardDefinition">
    <div *ngIf="statisticDashletViews" class="grid grid-template-4cols">
      <app-dashlet *ngFor="let dashletView of statisticDashletViews" [dashletView]="dashletView"
        [filterList]="filterList" class="grid-item"></app-dashlet>
    </div>
    <div *ngIf="nonStatisticDashletViews" class="grid grid-template-2cols">
      <app-dashlet *ngFor="let dashletView of nonStatisticDashletViews" [dashletView]="dashletView"
        [filterList]="filterList" [variants]="variants" class="grid-item"></app-dashlet>
    </div>
    <div *ngIf="detailDashletViews" class="grid .grid-template-1col">
      <app-dashlet *ngFor="let dashletView of detailDashletViews" [dashletView]="dashletView" [filterList]="filterList"
        class="grid-item"></app-dashlet>
    </div>
  </div>
  <!-- No dashlets on dashboard -->
  <div *ngIf="noDashlets" class="dx-datagrid-nodata relative">
    {{ language.noDashlets }}
  </div>
</div>