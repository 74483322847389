import { ISortOrder } from "../../../sorting";
import { ConfigurationWizardAction } from "./wizardaction";

export class ConfigurationWizardStage implements ISortOrder {
  constructor(name: string, order: number, private shouldUse: () => boolean) {
    this.name = name;
    this.sortOrder = order;
  }
  public name: string;
  public actions: ConfigurationWizardAction[] = [];
  public sortOrder: number;

  public addNewAction(action: ConfigurationWizardAction) {
    this.actions.push(action);
  }

  public get includeInWizard(): boolean {
    return this.shouldUse();
  }
}
