import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { confirm } from "devextreme/ui/dialog";
import { ModelTreeChangeType } from "../../../../../../angular-common/components/modeltree/modeltreeservice/models/modeltree-change";
import { ModelTreeChangeModel } from "../../../../../../angular-common/components/modeltree/modeltreeservice/models/modeltree-change-model";
import { ElementHelper } from "../../../../../../angular-common/helpers/element.helper";
import { StringHelper } from "../../../../../../angular-common/helpers/string.helper";
import { LongNameShortName } from "../../../../../../angular-common/longnameshortname/longname-shortname";
import { Variant } from "../../../../../../common/model/models/variant-model";
import { ModelElement } from "../../../../../../common/modelelements";
import { CalculatorPeriodModeWithDatesAvailabilityDto } from "../../../../../../common/models/dto/CalculatorPeriodModeWithDatesAvailabilityDto-dto";
import { ElementTypeDto } from "../../../../../../common/models/dto/ElementType-dto";
import { ModelElementsForVariantDto } from "../../../../../../common/models/dto/ModelElementsForVariantDto-dto";
import { VariantEditInformationDto } from "../../../../../../common/models/dto/VariantEditInformationDto-dto";
import { VariantInformationDto } from "../../../../../../common/models/dto/VariantInformation-dto";
import { VariantUsageDto } from "../../../../../../common/models/dto/VariantUsageDto-dto";
import { ImagineFeaturesModel } from "../../../imagine-features-model";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { VariantEditMetaData, VariantEditService } from "../../../services/variantedit";
import { VariantMutationsComponent } from "../variant-mutations-component/variant-mutations-component";
import { IModeltreeVariantExtension } from "./i-modeltree-variant-extension";
import { VariantChangeMode } from "./variant-changemode";
import { VariantPeriodFacade } from "./variant-period-facade";

@Component({
  selector: "app-variant-edit-component",
  templateUrl: "./variant-edit-component.html",
  styleUrls: ["./variant-edit-component.scss"],
})
export class VariantEditComponent implements OnInit, OnChanges {
  public constructor(public language: ImagineLanguage, private variantEditService: VariantEditService, private imagineFeatures: ImagineFeaturesModel) {
    this.getCategoryDisplayValue = this.getCategoryDisplayValue.bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkCurrentVariantDeleteOption();
    this.selectedModelElements.length = 0;
  }

  ngOnInit() {
    this.variantEditService.getMetadata().subscribe((metaData) => {
      this.variantEditMetaData = metaData;
      this.createPeriodFacades();
    });
  }

  allModelElementsAvailableToAdd: ModelElement[] = [];
  elementNotCurrentVariant: ModelElement[] = [];
  getAllModelElementsAvailableToAdd() {
    this.allModelElementsAvailableToAdd.length = 0;
    if (ElementHelper.isNullOrUndefined(this.variantEditMetaData) || ElementHelper.isNullOrUndefined(this.variantEditInformation)) return;

    this.variantEditMetaData.allExistingModelElements.forEach((modelElement) => {
      let modelElementCopy = new ModelElement();
      modelElementCopy.copyFrom(modelElement);
      modelElementCopy.modelCategoryId = modelElement.modelCategoryId;
      modelElementCopy.options = modelElement.options;
      this.allModelElementsAvailableToAdd.push(modelElementCopy);
    });

    this.elementNotCurrentVariant = this.allModelElementsAvailableToAdd.filter((modelElement) => {
      switch (modelElement.elementType) {
        case ElementTypeDto.Formula:
          return this.variantEditInformation.Mutations.Formulas.findIndex((f) => f.KeyId === modelElement.id) === -1;
        case ElementTypeDto.Parameter:
          return this.variantEditInformation.Mutations.Parameters.findIndex((f) => f.KeyId === modelElement.id) === -1;
        case ElementTypeDto.Stack:
          return this.variantEditInformation.Mutations.Stacks.findIndex((f) => f.KeyId === modelElement.id) === -1;
      }
    });
  }

  public get shouldShowVariantMutations(): boolean {
    return this.imagineFeatures.isShowVariantMutationsEnabled();
  }

  popupVisible: boolean = false;
  typeOfPopup: VariantChangeMode = VariantChangeMode.None;

  public variantEditMetaData: VariantEditMetaData;

  @Output() sendReloadEvent: EventEmitter<ModelTreeChangeModel> = new EventEmitter();

  @Input()
  public get variant(): Variant {
    return this._variant;
  }
  public set variant(newVariant: Variant) {
    this._variant = newVariant;
    if (newVariant) {
      this.variantToEdit = newVariant.getVariantCopy();
      this.createPeriodFacades();
    } else {
      this.variantToEdit = undefined;
    }
  }
  private _variant: Variant;

  public variantPeriodInfo: VariantPeriodFacade;
  public variantEditPeriodInfo: VariantPeriodFacade;

  public variantToEdit: Variant;

  public variantEditInformation: VariantEditInformationDto;

  @Output() sendVariantUsage: EventEmitter<VariantUsageDto[]> = new EventEmitter();

  private createPeriodFacades() {
    this.variantPeriodInfo = new VariantPeriodFacade(this.variantEditMetaData, this.variant);
    this.variantEditPeriodInfo = new VariantPeriodFacade(this.variantEditMetaData, this.variantToEdit);
  }

  public get variantInformations(): VariantInformationDto[] {
    if (this.variantEditInformation) {
      return [this.variantEditInformation.VariantUsage.VariantInformation];
    }
    return [];
  }

  public get canSave(): boolean {
    if (this.variant) {
      return this.variant.valuesNotEqual(this.variantToEdit) && StringHelper.hasNonEmptyText(this.variantEditName);
    }
    return false;
  }

  public get periodText() {
    if (!this.variantToEdit) {
      return "";
    }

    return this.variantToEdit.calculationPeriodConfigurationText(this.language);
  }

  public get variantEditName() {
    return this.variantToEdit.shortName;
  }

  public set variantEditName(newName: string) {
    this.variantToEdit.shortName = newName;
    this.variantToEdit.longName = newName;
  }

  public get canDelete(): boolean {
    if (this.variantEditInformation == undefined || this.variantEditInformation == null) {
      return false;
    }
    return this.variantEditInformation.VariantUsage.CanDelete;
  }
  public checkCurrentVariantDeleteOption() {
    this.variantEditInformation = undefined;
    const dto = this.variantToEdit.toVariantDto();
    this.variantEditService.getVariantState(dto).subscribe((x) => {
      this.variantEditInformation = x;
      var asInterface = <IModeltreeVariantExtension>(<any>this.variant);
      asInterface.tag = x;
      this.sendVariantUsage.emit([x.VariantUsage]);
    });
  }

  public saveVariant() {
    const dto = this.variantToEdit.toVariantDto();
    this.variantEditService.saveExistingVariant(dto).subscribe(() => {
      this.variant.copyFrom(this.variantToEdit);
      var reloadEvent = new ModelTreeChangeModel(ModelTreeChangeType.Update, null);
      this.sendReloadEvent.emit(reloadEvent);
      this.closePopup();
    });
  }

  public okButtonCreateChild(value: string) {
    var variantDto = this.variantToEdit.toVariantDto();
    this.variantEditService.createChildVariant(variantDto, value).subscribe((x) => {
      var reloadEvent = new ModelTreeChangeModel(ModelTreeChangeType.Created, x.KeyId);
      this.sendReloadEvent.emit(reloadEvent);
    });
    this.closePopup();
  }

  public closePopup() {
    this.popupVisible = false;
    this.typeOfPopup = VariantChangeMode.None;
  }

  public deleteVariantPopup() {
    const question = confirm(this.language.confirmDeleteHeader, this.language.confirmDeleteTitle);

    question.then((answer) => {
      if (answer) {
        this.variantEditService.deleteExistingVariant(this.variantToEdit.toVariantDto()).subscribe((x) => {
          var reloadEvent = new ModelTreeChangeModel(ModelTreeChangeType.Removed, x.KeyId);
          this.sendReloadEvent.emit(reloadEvent);
        });
      }
    });
  }

  public getCategoryDisplayValue(rowData: ModelElement) {
    let categoryName = "";
    this.variantEditMetaData.modelCategories.forEach((category) => {
      if (category.keyId === rowData.modelCategoryId) {
        categoryName = category.shortName;
      }
    });
    return categoryName;
  }

  selectedModelElements: ModelElement[] = [];
  public selectModelElementToAdd(event: any) {
    if (this.selectedModelElements.includes(event.data)) {
      let index = this.selectedModelElements.findIndex((modelElement) => modelElement.id === event.data.id);
      this.selectedModelElements.splice(index, 1);
    } else {
      this.selectedModelElements.push(event.data);
    }
    event.component.refresh();
  }

  onRowPrepared(event: any) {
    if (event.rowType === "data") {
      this.selectedModelElements.forEach((modelElement) => {
        if (modelElement.id === event.data.id && modelElement.modelCategoryId === event.data.modelCategoryId) {
          event.rowElement.classList.add("highlighted-row");
        }
      });
    }
  }

  public get canAdd(): boolean {
    if (this.selectedModelElements.length > 0) return true;
    else return false;
  }

  @ViewChild(VariantMutationsComponent) variantMutationsComponent!: VariantMutationsComponent;

  public addModelElementsToVariant() {
    let modelElementsDto: ModelElementsForVariantDto = new ModelElementsForVariantDto();
    modelElementsDto.Variant = this.variantEditInformation.VariantUsage.VariantInformation.Variant;
    this.selectedModelElements.forEach((modelElement) => {
      let newModelElementDto = modelElement.toDto();
      modelElementsDto.ModelElements.push(newModelElementDto);
    });

    this.variantEditService.addModelElementsToVariant(modelElementsDto).subscribe(
      (modelElements) => {
        this.closePopup();
        this.selectedModelElements.length = 0;
        this.variantMutationsComponent.refreshListOfMutations();
      },
      (ex) => {
        this.closePopup();
        this.selectedModelElements.length = 0;
        this.variantMutationsComponent.refreshListOfMutations();
        if (ex?.error) {
          alert(ex.error.Message);
        } else {
          console.error(ex);
        }
      },
    );
  }

  public createChildPopup() {
    this.typeOfPopup = VariantChangeMode.CreateChild;
    this.popupVisible = true;
  }

  public editVariantPopup() {
    this.typeOfPopup = VariantChangeMode.Edit;
    this.popupVisible = true;
  }

  public addModelElementPopup() {
    this.getAllModelElementsAvailableToAdd();
    this.typeOfPopup = VariantChangeMode.AddModelElement;
    this.popupVisible = true;
  }

  public get dataSetNameById(): string {
    var dataSetDefinition = this.variantEditMetaData.dataSetDefinitions.find((x) => x.id == this.variantToEdit.dataSetId);

    if (dataSetDefinition) {
      return dataSetDefinition.longName;
    }
    return "";
  }

  get isEditPopup(): boolean {
    if (this.typeOfPopup == VariantChangeMode.Edit) {
      return true;
    }
    return false;
  }
  get isCreatePopup(): boolean {
    if (this.typeOfPopup == VariantChangeMode.CreateChild) {
      return true;
    }
    return false;
  }
  get isAddModelElementPopup(): boolean {
    if (this.typeOfPopup == VariantChangeMode.AddModelElement) {
      return true;
    }
    return false;
  }

  get isAddButtonDisabled(): boolean {
    if (this.variantToEdit.isBaseModel === true) {
      return true;
    }
    return false;
  }

  public periodModeDisplayName(item: CalculatorPeriodModeWithDatesAvailabilityDto): string {
    return LongNameShortName.NameForDto(item);
  }
}
