import { DataSetDefinitionDto } from './../../../../../common/models/dto/DataSetDefinitionDto-dto';

// t4tsCode gen": "0.8.2.0 for file DataSetEditDto.cs
// Don't adjust manually!

export class DataSetEditDto extends DataSetDefinitionDto {
    public Locked: boolean;
    public User: string;
}

