import { DateTimeDto } from './../../dto/DateTimeDto-dto';

// t4tsCodeFromCs": "0.8.2.0 for file EventTimerHistoryDto.cs
// Don't adjust manually!

export class EventTimerHistoryDto {
    public LastRun: DateTimeDto;
    public NextRun: DateTimeDto;
    public TimesRun: number;
    public Running: boolean;
}

