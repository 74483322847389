import { IMove } from "../../../../../angular-common/components/listselector/imove";
import { ModelElement } from "../../../../../common/modelelements";
import { ModelsGroup } from "../../adapt/models/modelgroups-group.model";

export class ModelGroupMove implements IMove {
  constructor(public editingGroup: ModelsGroup) {}

  moveLeft(selectedItems: ModelElement[]): boolean {
    if (selectedItems && selectedItems.length > 0) {
      this.editingGroup.elements = this.editingGroup.elements.filter((s) => {
        const usedSelect = selectedItems.filter((element: ModelElement) => element.shortName === s.shortName);
        return usedSelect.length === 0;
      });
      return true;
    }
  }

  moveRight(selectedItems: ModelElement[]): boolean {
    if (selectedItems) {
      selectedItems.forEach((s) => {
        this.editingGroup.elements.push(s);
      });
    }
    return true;
  }
}
