import { RouterModule, ROUTES } from "@angular/router";

import { NgModule } from "@angular/core";
import { canActivateRoleGuard } from "../../../../angular-common/authentication-guard/role-guard";
import { ImagineFeaturesModel } from "../imagine-features-model";
import { SDWorxRoutesDto } from "./dto/SDWorxRoutes-dto";
import { SDWorxProvisioningConfigScreenComponent } from "./sdworx-provisioning-config-screen.component";
import { sDWorxProvisioningConfigResolver } from "./sdworx-provisioning-config-screen.resolver";
import { SDWorxProvisioningMessageStatusComponent } from "./sdworx-provisioning-messagestatus";
import { SDWorxProvisioningUserScreenComponent } from "./sdworx-provisioning-user-screen.component";
import { sDWorxProvisioningUserResolver } from "./sdworx-provisioning-user-screen.resolver";
import { SDWorxLanguage } from "./sdworxlanguage.service";

function routesFactory(featuresModel: ImagineFeaturesModel) {
  return [
    {
      path: SDWorxRoutesDto.SDWorxProvisioningConfiguration,
      component: SDWorxProvisioningConfigScreenComponent,
      canActivate: [canActivateRoleGuard],
      resolve: {
        configurationsData: sDWorxProvisioningConfigResolver,
      },
      loadChildren: () => {
        const enabled = featuresModel.IsSDWorxEnabled();
        console.log("sdworx-enabled", enabled);
        return enabled === true ? import("./sdworx-provisioning.module").then((m) => m.SDWorxProvisioningModule) : "";
      },
    },
    {
      path: SDWorxRoutesDto.SDWorxProvisioningMessageStatus,
      component: SDWorxProvisioningMessageStatusComponent,
      canActivate: [canActivateRoleGuard],
      loadChildren: () => {
        const enabled = featuresModel.IsSDWorxEnabled();
        return enabled === true ? import("./sdworx-provisioning.module").then((m) => m.SDWorxProvisioningModule) : "";
      },
    },
    {
      path: SDWorxRoutesDto.SDWorxProvisioningUsers,
      component: SDWorxProvisioningUserScreenComponent,
      canActivate: [canActivateRoleGuard],
      resolve: {
        users: sDWorxProvisioningUserResolver,
      },
      loadChildren: () => {
        const enabled = featuresModel.IsSDWorxEnabled();
        console.log("sdworx-enabled", enabled);
        return enabled === true ? import("./sdworx-provisioning.module").then((m) => m.SDWorxProvisioningModule) : "";
      },
    },
  ];
}

@NgModule({
  imports: [RouterModule.forChild([])],
  providers: [
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
      deps: [ImagineFeaturesModel],
    },
    SDWorxLanguage,
  ],
  exports: [RouterModule],
})
export class SDWorxProvisioningRoutesModule {}
