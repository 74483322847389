import { Component, OnChanges, OnInit, ViewChild } from "@angular/core";

import { DxChartComponent } from "devextreme-angular";
import { Palettes } from "../../../../../angular-common/components/palettes";
import { DashletViewTypeDto } from "../../analysis/dto/DashletViewType-dto";
import { DashletDataPoint } from "../../services/dashletdata";
import { DashletViewSettingsService } from "../../services/dashletviewsettings/dashlet-view-settings.service";
import { DashletViewSettings } from "../../services/dashletviewsettings/models/dashlet-view-settings";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { DashletGraphComponent } from "./dashlet-graph.component";
import { DataSerieItemContent } from "./dataserie-item-content";
import { IContextMenuClick } from "./i-context-menu-click";
import { IOnPointClickArg } from "./i-on-point-click-arg";
import { ITooltipArg } from "./i-tooltip-arg";
import { MenuDataItem } from "./menu-dataitem";

@Component({
  selector: "app-dashlet-chart",
  styleUrls: ["./dashlet-chart.component.scss"],
  templateUrl: "./dashlet-chart.component.html",
})
export class DashletChartComponent extends DashletGraphComponent implements OnInit, OnChanges {
  @ViewChild(DxChartComponent) chart: DxChartComponent;

  public showChartOptions: boolean = false;

  public constructor(palettes: Palettes, language: ImagineLanguage, private dashletSettingsService: DashletViewSettingsService) {
    super(palettes, language);

    this.chartOptions = new DashletViewSettings();
    this.updatedCanFilter = this.updatedCanFilter.bind(this);
    this.customizeTooltip = this.customizeTooltip.bind(this);

    const showChartOptionsMenuItem = new MenuDataItem(this.language.MenuSettings, this, "");
    this.customMenuItems = [showChartOptionsMenuItem];
  }

  ngOnInit(): void {
    this.chartOptions = this.dashletView.dashletViewSettings;
    this.applyChanges();
  }

  public applyChanges() {
    this.loadDataFromView();
    this.chartOptions.update(this.seriesData);
    this.showChartOptions = false;
  }

  public applyAndSaveChanges() {
    this.applyChanges();
    if (this.chartOptions._dashletViewId === -1) {
      this.chartOptions._dashletViewId = this.dashletView.dashletViewId;
    }
    this.dashletSettingsService.saveSettingsFromView(this.chartOptions).subscribe((response) => {
      this.dashletView.dashletViewSettings = response;
    });
  }

  public chartOptions: DashletViewSettings;

  // Make these enums available to the html template:
  public dashletViewType = DashletViewTypeDto;

  public customizeTooltip(arg: ITooltipArg) {
    const context: DataSerieItemContent = arg.point.tag;

    let htmlText = "";

    const variantName = (dp: DashletDataPoint) => {
      const hasVariant = dp.clusteredBy.find((x) => x.isDataSourceCluster === true);
      if (hasVariant) {
        return hasVariant.value;
      } else {
        return dp.name;
      }
    };

    htmlText += "<span style='font-size: 11px !important;' >";
    if (context.dataPoint2 !== undefined) {
      const color1 = this.chart.palette[0];
      let color2 = this.chart.palette[0];
      if (this.chart.palette.length > 1) {
        color2 = this.chart.palette[1];
      }

      htmlText += this.drawSquare(color1);
      htmlText += variantName(context.dataPoint1);
      htmlText += ":  ";
      htmlText += context.dataPoint1.valueAsText;

      htmlText += "<br />";
      htmlText += this.drawSquare(color2);
      htmlText += variantName(context.dataPoint2);
      htmlText += ":  ";
      htmlText += context.dataPoint2.valueAsText;
    } else {
      htmlText += context.dataPoint1.valueAsText;
    }
    return {
      html: htmlText,
    };
  }

  private drawSquare(color: string) {
    return `<svg width='10' height='10'><rect width='10' height='10' style='fill:${color};' /> </svg> &nbsp`;
  }

  contextMenuClick(e: IContextMenuClick) {
    if (e.itemData.text === this.language.dashletBarContextMenuFilterBy) {
      const series = this.chart.instance.getAllSeries();
      const selectedPoints = this.getSelectedPoints(series);
      this.adjustFiltersByPoints(selectedPoints);
      this.filterList.apply();
    } else if (e.itemData.text === this.language.dashletBarContextMenuRemoveFilter) {
      this.clearFilterOptions();
      this.filterList.apply();
    } else if (e.itemData.text === this.language.MenuSettings) {
      this.showChartOptions = true;
    }
  }

  public updatedCanFilter(): boolean {
    if (this.chart) {
      const series = this.chart.instance.getAllSeries();
      const selectedPoints = this.getSelectedPoints(series);
      return selectedPoints.length > 0;
    }
    return false;
  }

  onPointClick(e: IOnPointClickArg) {
    const point = e.target;
    const series = this.chart.instance.getAllSeries();

    this.toggleClick(point, series);

    this.filterByMenuItem.disabled = !this.updatedCanFilter();
  }

  public toggleShowChartOptions() {
    this.showChartOptions = !this.showChartOptions;
  }

  public get dxChartType() {
    if (this.dashletView.viewType === DashletViewTypeDto.AreaChart) {
      return "area";
    }
    if (this.dashletView.viewType === DashletViewTypeDto.LineChart) {
      return "line";
    }
    if (this.dashletView.viewType === DashletViewTypeDto.LineWithoutMarkersChart) {
      return "line";
    }
    if (this.dashletView.viewType === DashletViewTypeDto.SplineChart) {
      return "spline";
    }
    if (this.dashletView.viewType === DashletViewTypeDto.SteplineChart) {
      return "stepLine";
    }
    if (this.dashletView.viewType === DashletViewTypeDto.Grid) {
      return "grid";
    }

    return "bar";
  }

  public get showMarkers(): boolean {
    if (this.dashletView && this.dashletView.viewType === DashletViewTypeDto.LineWithoutMarkersChart) {
      return false;
    }

    return true;
  }
}
