import { Component } from "@angular/core";
import { Subject } from "rxjs";
import { ModelTreeBranch } from "../../../../../angular-common/components/modeltree/modeltreeservice/models/modeltree-branch";
import { ModelTreeChangeType } from "../../../../../angular-common/components/modeltree/modeltreeservice/models/modeltree-change";
import { ModelTreeChangeModel } from "../../../../../angular-common/components/modeltree/modeltreeservice/models/modeltree-change-model";
import { VariantInformationDto } from "../../../../../common/models/dto/VariantInformation-dto";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { IModeltreeVariantExtension } from "./variant-edit-component/i-modeltree-variant-extension";
@Component({
  selector: "app-variant-edit-screen",
  templateUrl: "./variant-edit-screen-component.html",
  styleUrls: ["./variant-edit-screen-component.scss"],
})
export class VariantEditScreenComponent {
  public constructor(public language: ImagineLanguage) {}

  eventsSubject: Subject<ModelTreeChangeModel> = new Subject<ModelTreeChangeModel>();

  notifyReload(event: ModelTreeChangeModel) {
    this.eventsSubject.next(event);
  }

  public refreshModelTree() {
    const event = new ModelTreeChangeModel(ModelTreeChangeType.Update, null);
    this.notifyReload(event);
  }

  public get variantInformations(): VariantInformationDto[] {
    if (this._selectedBranch) {
      let tag = (<IModeltreeVariantExtension>(<any>this.selectedBranch.element)).tag?.VariantUsage?.VariantInformation;
      if (tag) {
        return [tag];
      }
    }
    return [];
  }

  public set variantInformations(value: VariantInformationDto[]) {}

  public get selectedBranch(): ModelTreeBranch {
    return this._selectedBranch;
  }
  public set selectedBranch(newBranch: ModelTreeBranch) {
    this._selectedBranch = newBranch;
  }
  private _selectedBranch: ModelTreeBranch;
}
