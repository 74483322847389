import { ImportSpecificationSettingsDto } from './ImportSpecificationSettingsDto-dto';
import { ImportSuppliedFileDto } from './ImportSuppliedFile-dto';

// t4tsCode gen": "0.8.2.0 for file ImportRunRequest.cs
// Don't adjust manually!

export class ImportRunRequestDto {
    public ImportSpecificationId: number;
    public ImportSettings: ImportSpecificationSettingsDto;
    public Supplied: ImportSuppliedFileDto [] = [];
}

