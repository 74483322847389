import { ElementTypeDto } from "../../models/dto/ElementType-dto";

export class ModelElementIcon {
  public static getByType(modelElementType: ElementTypeDto) {
    switch (modelElementType) {
      case ElementTypeDto.Formula:
        return "fas fa-superscript";
      case ElementTypeDto.Parameter:
        return "fas fa-boxes";
      case ElementTypeDto.Stack:
        return "fas fa-layer-group";
      default:
        return "";
    }
  }
}
