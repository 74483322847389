<div *ngIf="dashletDefinition && metaData">
  <dx-form id="form" [colCount]="2">
    <!-- Variant -->
    <dxi-item dataField="Variant" [colSpan]="2">
      <dxo-label text="{{ language.variantHeader }}"></dxo-label>
      <div *dxTemplate>
        <app-dashlet-variant-selector
          [items]="metaData.dataSources"
          [(sourceId)]="dashletDefinition.changes.sourceId"
          [(sourceType)]="dashletDefinition.changes.sourceType"
          (elementChanged)="dashletDefinition.changes.sourceElementChanged($event)"
        ></app-dashlet-variant-selector>
      </div>
    </dxi-item>

    <!-- Variant to compare to/with -->
    <dxi-item dataField="Vergelijken met" [colSpan]="2">
      <dxo-label text="{{ language.compareToVariantHeader }}"></dxo-label>
      <div *dxTemplate>
        <app-dashlet-variant-selector
          [items]="metaData.dataSources"
          [(sourceId)]="dashletDefinition.changes.sourceIdCompare"
          [(sourceType)]="dashletDefinition.changes.sourceCompareType"
          (elementChanged)="dashletDefinition.changes.compareElementChanged($event)"
        ></app-dashlet-variant-selector>
      </div>
    </dxi-item>

    <!-- Dataset -->
    <dxi-item dataField="dataSetType" [colSpan]="2">
      <dxo-label text="{{ language.datasetHeader }}"></dxo-label>
      <div *dxTemplate>
        <dx-select-box [items]="metaData.dataSetTypes" [searchEnabled]="false" displayExpr="text" valueExpr="dataSetType" [(value)]="dashletDefinition.changes.dataSetType"> </dx-select-box>
      </div>
    </dxi-item>

    <!-- Cluster -->
    <dxi-item dataField="Cluster" [colSpan]="2" [visible]="enableClusterSelection === true">
      <dxo-label text="{{ language.clusterHeader }}"></dxo-label>
      <div *dxTemplate>
        <dx-select-box
          [items]="metaData.clusters"
          [searchEnabled]="true"
          displayExpr="displayName"
          valueExpr="shortName"
          [(value)]="dashletDefinition.changes.cluster"
          showClearButton="true"
          [placeholder]="language.placeholderNone"
        >
        </dx-select-box>
      </div>
    </dxi-item>

    <!-- Available to -->
    <dxi-item dataField="AvailableTo" [colSpan]="2" [visible]="false">
      <dxo-label text="{{ language.availableToHeader }}"></dxo-label>
      <div *dxTemplate>
        <dx-select-box
          [items]="metaData.availableToItems"
          displayExpr="name"
          valueExpr="id"
          [(value)]="dashletDefinition.changes.availableTo"
          showClearButton="true"
          [placeholder]="language.placeholderAvailableToSelf"
        >
        </dx-select-box>
      </div>
    </dxi-item>
    <dxi-item [colSpan]="2">
      <div *dxTemplate>
        <app-list-selector
          [(itemsInUse)]="selectedStatistics"
          [availableItems]="noneSelectedStatistics"
          [displayValue]="displayValue"
          [searchValue]="searchValue"
          [moveImplementation]="moveImplementation"
          (valuesChanged)="inputsChanged()"
          (sortOrderChanged)="sortOrderChanged($event)"
        ></app-list-selector>
      </div>
    </dxi-item>
  </dx-form>

  <div class="button-container bottom-container align-right">
    <span *ngIf="warningMessage !== null" class="savewarning float-left">
      {{ warningMessage }}
    </span>
    <dx-button [text]="language.ButtonSave" icon="save" type="default" [hint]="language.ButtonSave" (onClick)="dashletDefinition.saveChanges()" [disabled]="!dashletDefinition.canSave"></dx-button>
    <dx-button [text]="language.Undo" icon="undo" [hint]="language.Undo" (onClick)="undoChanges()" [disabled]="!dashletDefinition.hasChanges"></dx-button>
  </div>
</div>
