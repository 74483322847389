import { MutationDto } from './MutationDto-dto';

// t4tsCodeFromCs": "0.8.2.0 for file FormulaMutationDto.cs
// Don't adjust manually!

export class FormulaMutationDto extends MutationDto {
    public FormulaText: string;
    public OriginalFormulaText: string;
}

