<div>
  <div *ngIf="authorizationContentType === authorizationContentTypes.FilterRole">
    <app-authorization-content-filter-role></app-authorization-content-filter-role>
  </div>
  <div *ngIf="authorizationContentType === authorizationContentTypes.FilterByVariant">
    <app-authorization-content-filter-variant></app-authorization-content-filter-variant>
  </div>
  <div *ngIf="authorizationContentType === authorizationContentTypes.FilterOnBaseModel">
    <app-authorization-content-filter-base-model></app-authorization-content-filter-base-model>
  </div>
</div>
