import { ModelElementFactory } from "../../../../../../common/model/models/model-element-factory";
import { ModelElement } from "../../../../../../common/modelelements";
import { ModelCategory } from "../../../../../../common/modelelements/categories/model-category";
import { TimePeriod } from "../../../../../../common/modelelements/timeperiod-model";
import { CalculatorPeriodModeWithDatesAvailabilityDto } from "../../../../../../common/models/dto/CalculatorPeriodModeWithDatesAvailabilityDto-dto";
import { ReferenceCategoriesDto } from "../../../../../../common/models/dto/ReferenceCategoriesDto-dto";
import { VariantEditMetaDataDto } from "../../../models/dto/VariantEditMetaDataDto-dto";
import { DataSetDefinition } from "./dataset-definition";

export class VariantEditMetaData {
  public dataSetDefinitions: DataSetDefinition[] = [];
  public periodTypes: CalculatorPeriodModeWithDatesAvailabilityDto[] = [];
  public periods: TimePeriod[] = [];
  public modelCategories: ModelCategory[] = [];
  public referenceCategories: ReferenceCategoriesDto[];
  public allExistingModelElements: ModelElement[] = [];

  public copyFromDto(dto: VariantEditMetaDataDto) {
    const modelCategories: ModelCategory[] = [];
    dto.ModelCategories.forEach((modelCategoryDto) => {
      const modelCategory = ModelElementFactory.createModelCategory(modelCategoryDto);
      modelCategories.push(modelCategory);
    });
    this.modelCategories = modelCategories;

    const definitions: DataSetDefinition[] = [];
    dto.DataSetDefinitions.forEach((dsdDto) => {
      const newDefinition = new DataSetDefinition();
      newDefinition.copyFromDto(dsdDto);
      definitions.push(newDefinition);
    });
    this.dataSetDefinitions = definitions;

    {
      this.periodTypes = dto.CalculatorPeriodModes;
    }

    {
      const periodsList: TimePeriod[] = [];
      dto.Periods.forEach((pDto) => {
        const p = ModelElementFactory.createPeriod(pDto);
        periodsList.push(p);
      });
      this.periods = periodsList;
    }

    this.referenceCategories = dto.ReferenceCategories;
    {
      const modelElementsList: ModelElement[] = [];
      dto.AllModelElements.forEach((meDto) => {
        const me = new ModelElement();
        me.copyFromDto(meDto);
        modelElementsList.push(me);
      });
      this.allExistingModelElements = modelElementsList;
    }
  }
}
