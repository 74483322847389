import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { DownloadableExportDefinition } from "./models/downloadable-export-definition";
import { ExportDefinitions } from "./models/export-definitions.model";
import { ToasterInput } from "../toaster/toaster-input-model";
import { WebLanguage } from "../../../common/language/weblanguage.service";

@Component({
  selector: "app-exporting",
  templateUrl: "./exporting.component.html",
  styleUrls: ["./exporting.component.scss"],
})
export class ExportingComponent implements OnInit, OnDestroy {
  public toasterInput: ToasterInput;
  public constructor(private domSanitizer: DomSanitizer, public language: WebLanguage) {
    this.toasterInput = new ToasterInput();
    this.toasterInput.message = language.exportDownloadStarted;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.hasDefinitions) {
      this.definitions.forEach((def) => {
        def.releaseDownloadedData();
      });
    }
  }

  public get contentLoaded(): boolean {
    return this.definitions !== undefined && this.definitions !== null;
  }

  public get hasDefinitions(): boolean {
    return this.contentLoaded && this.definitions.length > 0;
  }

  @Input()
  public get exportingDefinitions(): ExportDefinitions {
    return this._exportingDefinitions;
  }
  public set exportingDefinitions(v: ExportDefinitions) {
    this._exportingDefinitions = v;
    this.updateDefinitions();
  }
  private _exportingDefinitions: ExportDefinitions;

  public definitions: DownloadableExportDefinition[];

  private updateDefinitions() {
    const newDefinitions: DownloadableExportDefinition[] = [];
    if (this.exportingDefinitions && this.exportingDefinitions !== null) {
      this.exportingDefinitions.all.forEach((ed) => {
        const definition = new DownloadableExportDefinition(this.domSanitizer, this.exportingDefinitions, ed);
        newDefinitions.push(definition);
      });
    }
    this.definitions = newDefinitions;
  }

  public download(definition: DownloadableExportDefinition) {
    this.toasterInput.showSucces(this.language.exportDownloadStarted);
    definition.download();
  }
}
