import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDataGridModule, DxFormModule, DxListModule, DxLookupModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { canActivateRoleGuard } from "../../../../angular-common/authentication-guard/role-guard";
import { CardViewModule } from "../../../../angular-common/components/cardview";
import { ListSelectorModule } from "../../../../angular-common/components/listselector/listselector.module";
import { AdaptRoutesDto } from "../adapt/dto/AdaptRoutes-dto";
import { VariantInformationModule } from "../modeling/variant-info-module/variant-information-module";
import { AdaptLanguage } from "../services/language/adaptlanguage.service";
import { AdaptGroupsEditService } from "./adapt-groups-edit-service";
import { DataMutationsScreenComponent } from "./data-mutations-screen/data-mutations-screen.component";
import { DataMutationsClientService } from "./data-mutations/data-mutations-client-service";
import { DataMutationsService } from "./data-mutations/data-mutations-service";
import { ModelgroupEditFilterComponent } from "./modelgroup-edit-filter/modelgroup-edit-filter.component";
import { ModelsGroupsEditComponent } from "./modelgroups-edit-content/modelgroups-edit.component";
import { ModelsGroupsEditScreenComponent } from "./modelgroups-edit-screen.component";

const routes: Routes = [
  {
    path: AdaptRoutesDto.DataMutationsScreen,
    component: DataMutationsScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
  {
    path: AdaptRoutesDto.DataMutationsEditScreen,
    component: ModelsGroupsEditScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    CardViewModule,
    DxListModule,
    DxLookupModule,
    DxSelectBoxModule,
    DxFormModule,
    DxTextBoxModule,
    DxPopupModule,
    DxScrollViewModule,
    ListSelectorModule,
    VariantInformationModule,
  ],
  providers: [AdaptGroupsEditService, DataMutationsService, DataMutationsClientService, AdaptLanguage],
  declarations: [DataMutationsScreenComponent, ModelsGroupsEditScreenComponent, ModelsGroupsEditComponent, ModelgroupEditFilterComponent],
})
export class AdaptModule {}
