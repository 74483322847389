<div *ngIf="dashletDefinition && metaData">
  <app-cardview>
    <dx-form id="form" [colCount]="2">
      <!-- Name -->
      <dxi-item cssClass="nameBox" dataField="Name" [colSpan]="2">
        <dxo-label text="{{ language.nameHeader }}"></dxo-label>
        <div *dxTemplate>
          <dx-text-box [(value)]="dashletDefinition.changes.name"></dx-text-box>
        </div>
      </dxi-item>

      <!-- Type -->
      <dxi-item cssClass="typeBox" dataField="Type" [colSpan]="2">
        <dxo-label text="{{ language.typeHeader }}"></dxo-label>
        <div *dxTemplate>
          <app-radiogroup [items]="dashletTypeSelector"> </app-radiogroup>
        </div>
      </dxi-item>

      <dxi-item [colSpan]="2">
        <app-dashlet-definition-designer-statistic
          *ngIf="dashletDefinition.changes.type === 2"
          [dashletDefinition]="dashletDefinition"
          [metaData]="metaData"
        ></app-dashlet-definition-designer-statistic>
        <app-dashlet-definition-designer-detail *ngIf="dashletDefinition.changes.type === 1" [dashletDefinition]="dashletDefinition" [metaData]="metaData"></app-dashlet-definition-designer-detail>
        <app-dashlet-definition-designer-chart *ngIf="dashletDefinition.changes.type === 3" [dashletDefinition]="dashletDefinition" [metaData]="metaData"></app-dashlet-definition-designer-chart
      ></dxi-item>
    </dx-form>
  </app-cardview>
</div>
