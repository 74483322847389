import { SDConnectUserDto } from './SDConnectUserDto-dto';

// t4tsCode gen": "0.8.2.0 for file SDConnectUserInformationDto.cs
// Don't adjust manually!

export class SDConnectUserInformationDto {
    public User: SDConnectUserDto;
    public CorrelationId: string;
    public Status: string;
    public StatusDate: Date;
}

