import { Injectable } from "@angular/core";
import { ElementHelper } from "../../../angular-common/helpers/element.helper";
import { FeaturesImagineDto } from "./dto/info/featuresDto";

const globalVariableSpace: any = window;
const pl_hr_f3_featuresSettings = (globalVariableSpace || {}).pl_hr_f3_featuresSettings || {};

@Injectable()
export class ImagineFeaturesModel {
  public data: FeaturesImagineDto;

  constructor() {
    this.data = pl_hr_f3_featuresSettings || {};
  }

  public IsSDWorxEnabled(): boolean {
    const sdworxSettings = this.data?.SDWorx;
    if (ElementHelper.isNullOrUndefined(sdworxSettings)) {
      return false;
    }
    return sdworxSettings.enabled === undefined ? false : this.data.SDWorx.enabled;
  }

  public IsVismaRaetEnabled(): boolean {
    const vismaRaetSettings = this.data?.VismaRaet;
    if (ElementHelper.isNullOrUndefined(vismaRaetSettings)) {
      return false;
    }
    return vismaRaetSettings.enabled === undefined ? false : this.data.VismaRaet.enabled;
  }

  public isAnalysisComputeEnabled(): boolean {
    const analysisSettings = this.data?.Analysis;
    if (ElementHelper.isNullOrUndefined(analysisSettings)) {
      return false;
    }
    return analysisSettings.enabled && analysisSettings.allowComputeVariants;
  }

  public isShowVariantMutationsEnabled(): boolean {
    const modelSettings = this.data?.Model;
    if (ElementHelper.isNullOrUndefined(modelSettings)) {
      return false;
    }
    return modelSettings.enabled && (modelSettings.modeltreeEdit_Formulas || modelSettings.modeltreeEdit_Parameters || modelSettings.modeltreeEdit_Stacks);
  }
}
