import { DataMutationsAllDataDto } from "../../../adapt/dto/DataMutationsAllData-dto";
import { DataMutationsClientData } from "./data-mutations-client-data.model";
import { DataMutationsMetaData } from "./data-mutations-metadata.model";

export class DataMutationsAllData {
  public constructor() {}

  public copyFromDto(dto: DataMutationsAllDataDto) {
    this.data.copyFromDto(dto.Data);
    this.metaData.copyFromDto(dto.MetaData);
  }

  public data: DataMutationsClientData = new DataMutationsClientData();
  public metaData: DataMutationsMetaData = new DataMutationsMetaData();
}
