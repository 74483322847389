
// t4tsCode gen": "0.8.2.0 for file ImportSpecificationSettingsDto.cs
// Don't adjust manually!

export class ImportSpecificationSettingsDto {
    public DataSetId: number;
    public DataSetName: string;
    public MutationDate: Date;
    public VariantId: number;
}

