import { HttpClient, HttpResponse } from "@angular/common/http";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator, Sorter } from "../../../../../angular-common";
import { DashletView, DashletViewService } from "../dashletview";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DashletDetailDataDto } from "../../analysis/dto/DashletDetailData-dto";
import { DashletSeriesDataDto } from "../../analysis/dto/DashletSeriesData-dto";
import { DashletWithDataDto } from "../../analysis/dto/DashletWithData-dto";
import { DataFilterDto } from "../../analysis/dto/DataFilter-dto";
import { DashletViewSettings } from "../dashletviewsettings/models/dashlet-view-settings";
import { ClusterField } from "./models/cluster-field";
import { DashletDataPoint } from "./models/dashlet-data-point";
import { DashletWithData } from "./models/dashlet-data.model";

@Injectable()
export class DashletDataService extends PlBaseService {
  private endPoint: string = "dashletdata";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator, public dashletViewService: DashletViewService) {
    super(http, envSettings, loadIndicator);
  }

  public getDashletWithData(dashletId: number, filter: DataFilterDto[]): Observable<DashletWithData> {
    const thisService = this;
    const url = this.endPoint + "/" + String(dashletId);
    const result = this.postDataTransformed<DashletWithDataDto, DashletWithData>(url, filter, (r) => thisService.extractDashletWithData(thisService, r));
    return result;
  }

  private extractDashletWithData(thisService: DashletDataService, response: HttpResponse<DashletWithDataDto>): DashletWithData {
    const body = response.body;
    if (body) {
      const json: DashletWithDataDto = body;
      const viewsSettings = new DashletViewSettings();
      viewsSettings.fromDto(body.View.ViewSettings);
      const view = new DashletView(
        thisService.dashletViewService,
        json.View.DashletViewId,
        json.View.ViewType,
        json.View.DashletType,
        json.View.SortOrder,
        json.View.Name,
        json.View.PaletteName,
        viewsSettings,
      );

      const jsonDataAsSeries = json.Data as DashletSeriesDataDto;
      const result = this.transformResponseToDashletWithData(jsonDataAsSeries, json, view);

      const detailData = json.Data as DashletDetailDataDto;
      if (detailData.Columns !== undefined) {
        result.columns = detailData.Columns;
      }
      if (detailData.Rows !== undefined) {
        result.rows = detailData.Rows;
      }

      result.missingFunctionalFilterElements = detailData.MissingFunctionalFilterElements;

      return result;
    } else {
      return null;
    }
  }

  private transformResponseToDashletWithData(jsonDataAsSeries: DashletSeriesDataDto, json: DashletWithDataDto, view: DashletView) {
    const dataPoints: DashletDataPoint[] = [];
    if (jsonDataAsSeries !== undefined && jsonDataAsSeries.DataPoints !== undefined) {
      jsonDataAsSeries.DataPoints.forEach((dpDTO) => {
        const dataPoint = new DashletDataPoint();
        dataPoint.copyFromDTO(dpDTO);
        dataPoints.push(dataPoint);
      });
    }
    dataPoints.sort(Sorter.compareSortOrder);
    const clusters: ClusterField[] = [];
    json.Data.ClusteredBy.forEach((clDto) => {
      const clusterItem = new ClusterField();
      clusterItem.copyFromDTO(clDto);
      clusters.push(clusterItem);
    });
    const result = new DashletWithData(view, clusters, dataPoints, jsonDataAsSeries.Result);
    return result;
  }
}
