
// t4tsCode gen": "0.8.2.0 for file VismaFileApiConfigurationDto.cs
// Don't adjust manually!

export class VismaFileApiConfigurationDto {
    public TenantId: string;
    public ClientId: string;
    public ClientSecret: string;
}

