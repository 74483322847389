import { DateTimeDto } from './../../dto/DateTimeDto-dto';
import { EventTimerDataDto } from './EventTimerDataDto-dto';
import { EventTimerHistoryDto } from './EventTimerHistoryDto-dto';
import { EventTimerTypeDto } from './EventTimerType-dto';
import { PresentableElementDto } from './../../dto/PresentableElement-dto';
import { RecurrenceTypeDto } from './RecurrenceType-dto';

// t4tsCodeFromCs": "0.8.2.0 for file EventTimerInformationDto.cs
// Don't adjust manually!

export class EventTimerInformationDto extends PresentableElementDto {
    public EventType: EventTimerTypeDto;
    public AvailableRecurrenceTypes: RecurrenceTypeDto [] = [];
    public SelectedRecurrenceType: RecurrenceTypeDto;
    public EventTimerHistory: EventTimerHistoryDto;
    public Start: DateTimeDto;
    public EventTimerData: EventTimerDataDto;
    public Active: boolean;
}

