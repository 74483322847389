import { IntegrationServiceLanguage } from "../../../../../common/language/integrationservicelanguage.service";
import { EventTimerTypeDto } from "../../../../../common/models/integrationservices/dto/EventTimerType-dto";
import { ValueTextMapper } from "../../../../../common/models/value-text-mapper";
import { ImagineFeaturesModel } from "../../imagine-features-model";

export class EventTypeTranslator extends ValueTextMapper<EventTimerTypeDto> {
  constructor(integrationLanguage: IntegrationServiceLanguage, private featureSettings: ImagineFeaturesModel) {
    super(EventTimerTypeDto.Unknown, "");
    this.addEntry(EventTimerTypeDto.Import, integrationLanguage.eventTimerEventType_Import);
    this.addEntry(EventTimerTypeDto.Export, integrationLanguage.eventTimerEventType_Export);
    this.addEntry(EventTimerTypeDto.ComputeVariant, integrationLanguage.eventTimerEventType_ComputeVariant);

    if (this.featureSettings.data?.SDWorx?.enabled === true) {
      this.addEntry(EventTimerTypeDto.MySdWorxActivation, integrationLanguage.eventTimerEventType_MySdWorxActivation);
      this.addEntry(EventTimerTypeDto.MySdWorxSync, integrationLanguage.eventTimerEventType_MySdWorxSync);
    }
  }
}
