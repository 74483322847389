import { Component, Input } from "@angular/core";

import { DataSupplyRoutesDto } from "../../import/dto/DataSupplyRoutes-dto";
import { ImportDefinition } from "../../services/import/models/import-definition.model";
import { ImportLanguage } from "../../services/language/connectorlanguage.service";
import { RouteNavigation } from "../../../../../angular-common/baseservice/route-navigation";

@Component({
  selector: "app-data-supply-content-item",
  templateUrl: "./data-supply-content.component-item.html",
  styleUrls: ["./data-supply-content.component-item.scss"],
})
export class DataSupplyContentComponentItemComponent {
  constructor(public language: ImportLanguage, private routeNavigation: RouteNavigation) {}

  public goToDetails() {
    if (this.importDefinition) {
      this.routeNavigation.navigate(DataSupplyRoutesDto.DataSupply + "/" + this.importDefinition.id);
    }
  }

  @Input() importDefinition: ImportDefinition;

  public get title() {
    if (this.importDefinition) {
      return this.importDefinition.name;
    }
    return "import";
  }
  public get description() {
    if (this.importDefinition) {
      return this.importDefinition.description;
    }
    return "";
  }
}
