import { Component } from "@angular/core";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { DashletDefinitionDesignPropertiesBase } from "./dashlet-definition-design-props-base";

@Component({
  selector: "app-dashlet-definition-designer-factory",
  templateUrl: "./dashlet-definition-designer-factory.html",
  styleUrls: ["./dashlet-definition-designer-factory.scss"],
})
export class DashletDefinitionDesignerFactoryComponent extends DashletDefinitionDesignPropertiesBase {
  constructor(language: ImagineLanguage) {
    super(language);
  }
}
