import { DashletDetailDataColumnDto } from "../../analysis/dto/DashletDetailDataColumn-dto";
import { DashletValueFormatting } from "./dashlet-value-formatting.component";
import { DataTypes } from "./pivot-datatypes";
import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";

export class PivotDataField {
  constructor(column: DashletDetailDataColumnDto, area: string) {
    this.caption = column.Name;
    this.dataField = column.Name;
    if (!ElementHelper.isNullOrUndefined(column.DisplayFormat)) {
      this.format = DashletValueFormatting.retrieveFormatByDto(column.DisplayFormat);
    }
    this.area = area;
    this.dataType = this.checkDataType(column.IsNumber);
  }

  public caption: string;
  public dataField: string;
  public dataType: string;
  public summaryType: string = "sum";
  public format: string;
  public area: string;

  checkDataType(isNumber: boolean) {
    if (isNumber == true) {
      return DataTypes.Number.toLocaleString();
    }
    return DataTypes.String.toLocaleString();
  }
}
