import { DashletGraphComponent } from "./dashlet-graph.component";

export class MenuDataItem {
  public disabled: boolean;

  constructor(
    public text: string,
    public vm: DashletGraphComponent,
    public target: string,
  ) {
    this.disabled = false;
  }

  public get targetComp() {
    const chartElement = document.getElementById(this.target);
    return chartElement;
  }
}
