<div *ngIf="filterList && selectedDashboardDefinition && parameters && paginatedParameters" class="drawer">
  <dx-button icon="menu" (onClick)="openClose()" *ngIf="enableParameterOptions" style="margin-bottom: 10px"> </dx-button>
  <dx-drawer *ngIf="enableParameterOptions" openedStateMode="shrink" position="left" revealMode="slide" template="template" [(opened)]="isOpen" height="100%">
    <div *dxTemplate="let data of 'template'" class="pl-drawer dx-theme-accent-as-background-color">
      <div class="pl-drawer-content">
        <h4>{{ language.parameterSettingsTitle }}</h4>
        <dxi-item>
          <dx-drop-down-box [(value)]="selectedStrings" [dataSource]="paginatedParameters" displayExpr="longName" valueExpr="shortName" width="300px">
            <dxo-drop-down-options height="400px"></dxo-drop-down-options>
            <div *dxTemplate="let data of 'content'">
              <dx-list
                [dataSource]="parameters"
                displayExpr="longName"
                [searchEnabled]="true"
                searchExpr="longName"
                searchMode="contains"
                selectionMode="multiple"
                showSelectionControls="true"
                [(selectedItemKeys)]="selectedParameters"
                height="80%"
              ></dx-list>
              <br />
              <dx-button [text]="language.ButtonOk" (onClick)="updateSelection()"></dx-button>
            </div>
          </dx-drop-down-box>
        </dxi-item>
        <dxi-item>
          <div *ngIf="initalizationDone">
            <div *ngFor="let exactParameter of exactParameters">
              <app-exact-parameter
                [parameter]="exactParameter"
                [dates]="selectedDashboardDefinition.simulationPeriods"
                [simulationVariantId]="selectedDashboardDefinition.simulationVariantId"
              ></app-exact-parameter>
            </div>
            <div *ngFor="let referenceParameter of referenceParameters">
              <app-reference-parameter
                [parameter]="referenceParameter"
                [referenceCategories]="referenceCategories"
                [dates]="selectedDashboardDefinition.simulationPeriods"
                [simulationVariantId]="selectedDashboardDefinition.simulationVariantId"
              ></app-reference-parameter>
            </div>
          </div>
        </dxi-item>
        <dxi-item>
          <dx-button [text]="language.ButtonApply" [hint]="language.ButtonApply" icon="refresh" (onClick)="updateParameters()"></dx-button>
        </dxi-item>
      </div>
    </div>
    <div *dxTemplate="let data of 'content'">
      <div [ngClass]="isOpen ? 'pl-content' : ''" style="height: 100%">
        <app-dashboard [dashboardDefinition]="selectedDashboardDefinition" [filterList]="filterList"></app-dashboard>
      </div>
    </div>
  </dx-drawer>
  <div *ngIf="!enableParameterOptions">
    <app-dashboard [dashboardDefinition]="selectedDashboardDefinition" [filterList]="filterList"></app-dashboard>
  </div>
</div>
