import { exportDataGrid, exportPivotGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

export class DevExpressExcelExport {
  public static onDataGridExporting(e: any, fileName: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sheet");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(() => this.saveFile(workbook, fileName));
  }

  public static onPivotExporting(e: any, fileName: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sheet");
    exportPivotGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(() => this.saveFile(workbook, fileName));
  }

  private static saveFile(workBook: Workbook, fileName: string) {
    workBook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
      saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName + ".xlsx");
    });
  }
}
