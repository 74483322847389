import { DataResultDto } from "../../../../../../common/models/dto/DataResult-dto";
import { DashletDetailDataColumnDto } from "../../../analysis/dto/DashletDetailDataColumn-dto";
import { DashletDetailDataEntryDto } from "../../../analysis/dto/DashletDetailDataEntry-dto";
import { DashletView } from "../../dashletview";
import { ClusterField } from "./cluster-field";
import { DashletDataPoint } from "./dashlet-data-point";

export class DashletWithData {
  public columns: DashletDetailDataColumnDto[];
  public rows: DashletDetailDataEntryDto[];
  public missingFunctionalFilterElements: string[];

  public constructor(public view: DashletView, public clusteredBy: ClusterField[], public data: DashletDataPoint[], public result: DataResultDto) {
    if (!data || data === null) {
      data = [];
    }
    if (!clusteredBy || clusteredBy === null) {
      clusteredBy = [];
    }
  }
}
