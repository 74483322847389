import { Component, OnInit } from "@angular/core";
import { confirm } from "devextreme/ui/dialog";
import { DatasetService } from "../../services/dataset/dataset.service";
import { Dataset } from "../../services/dataset/models/dataset";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dataset-screen",
  templateUrl: "./dataset-screen.component.html",
  styleUrls: ["./dataset-screen.component.scss"],
})
export class DataSetScreenComponent implements OnInit {
  public datasets: Dataset[];

  public constructor(private datasetService: DatasetService, public language: ImagineLanguage) {}

  ngOnInit(): void {
    this.createDatasets();
  }

  private createDatasets() {
    this.datasetService.getDatasets().subscribe((m) => {
      this.datasets = m;
    });
  }

  deleteRow(dataset: Dataset) {
    if (dataset.locked === false) {
      confirm(this.language.confirmDeleteHeader, this.language.confirmDeleteTitle).then((result) => {
        if (result) {
          this.datasetService.deleteDataset(dataset).subscribe((m) => {
            this.datasets.splice(
              this.datasets.findIndex((x) => x.keyId === dataset.keyId),
              1,
            );
          });
        }
      });
    }
  }

  onRowUpdated(value: { key: Dataset }) {
    const dataset = value.key;
    this.datasetService.updateDataset(dataset).subscribe((m) => {});
  }

  isLocked(value) {
    return value.locked;
  }
}
