<div class="pl-sliderContainer">
  <h5>{{ settings.title }}</h5>
  <div class="pl-slider-contentContainer">
    <div class="pl-slider-editOptions">
      <div *ngIf="!editMode">
        <dx-slider height="50px" class="pl-slider" [min]="settings.minValue" [max]="settings.maxValue" [(value)]="settings.value" [step]="1" (onValueChanged)="onValueChanged($event)">
          <dxo-tooltip [enabled]="true" showMode="OnHover" position="bottom"></dxo-tooltip>
        </dx-slider>
      </div>
      <div *ngIf="editMode">
        <dx-number-box height="50px" [(value)]="settings.value" [showSpinButtons]="true" [min]="settings.minValue" [max]="settings.maxValue" (onValueChanged)="onValueChanged($event)"> </dx-number-box>
      </div>
    </div>
    <p class="toggleButton" (click)="toggleEditMode()"><i class="dx-icon-background"></i></p>
  </div>
</div>
