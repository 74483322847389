import { Component, Input } from "@angular/core";
import { IAuthorizationContent } from "../../../../../../../angular-common/components/authorization/component/tabs/authorization-tabs/IAuthorizationContent";
import { AuthorizationContentType } from "../../../../../../../angular-common/components/authorization/models/authorization-content.types";

@Component({
  selector: "app-authorization-filter-content",
  templateUrl: "./authorization-content.component.html",
  styleUrls: ["./authorization-content.component.scss"],
})
export class AuthorizationFilterContentComponent implements IAuthorizationContent {
  @Input() authorizationContentType: AuthorizationContentType;
  public authorizationContentTypes = AuthorizationContentType;
}
