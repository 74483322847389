import { ExportDataStateDto } from '../dto/ExportDataState-dto';
import { ExportDefinitionDto } from '../dto/ExportDefinition-dto';
import { ISortOrder } from "../../../sorting";

export class ExportDefinition implements ISortOrder {
  public constructor() {}

  public shortName: string;
  public longName: string;
  public fileName: string;
  public exportDataState: ExportDataStateDto;
  public sortOrder: number;

  public copyFromDto(dto: ExportDefinitionDto) {
    this.shortName = dto.ShortName;
    this.longName = dto.LongName;
    this.fileName = dto.FileName;
    this.exportDataState = dto.ExportDataState;
    this.sortOrder = dto.SortOrder;
  }

  public get exportIsReady(): boolean {
    return this.exportDataState === ExportDataStateDto.Ready;
  }

  public get exportOutOfDate(): boolean {
    return this.exportDataState === ExportDataStateDto.OutOfDate;
  }

  public get exportIsNotReady(): boolean {
    return this.exportIsReady === false;
  }
}
