import { Component, Input } from "@angular/core";

import { DataSupplyRoutesDto } from "../../import/dto/DataSupplyRoutes-dto";
import { ImportLanguage } from "../../services/language/connectorlanguage.service";
import { ImportProcessingState } from "../../services/import/models/import-processing-state.model";
import { RouteNavigation } from "../../../../../angular-common/baseservice/route-navigation";
import { TextPopupInteraction } from "../../../../../angular-common/components/textpopup/textpopup-interaction";

@Component({
  selector: "app-data-supply-status-summary",
  templateUrl: "./data-supply-status-summary.html",
  styleUrls: ["./data-supply-status-summary.scss"],
})
export class DataSupplyDetailSummaryComponent {
  public popupInteraction: TextPopupInteraction;

  constructor(public language: ImportLanguage, private routeNavigation: RouteNavigation) {}

  public goToDetails() {
    this.routeNavigation.navigate(DataSupplyRoutesDto.DataSupplyDetail, this.state.messageState.TaskId);
  }

  public get description() {
    let description = "Import";

    if (this.state) {
      if (this.state.specificationName) {
        description += ' "' + this.state.specificationName + '"';
      }

      if (this.state.queuedAt) {
        description += " " + this.state.queuedAtString;
      }
    }

    return description;
  }

  public get requestedBy() {
    let name = "";
    if (this.state && this.state.requestedBy) {
      name = this.state.requestedBy.Name;
    }
    return this.language.datasupplyDetailsRequestedBy + " " + name;
  }

  public get currentStateText() {
    if (this.state) {
      return this.language.datasupplyStateInfo + this.state.stateString;
    }
    return "";
  }

  public get hasQueuedState() {
    if (this.state) {
      return this.state.hasQueuedState;
    }

    return false;
  }

  public get hasInProgressSate() {
    if (this.state) {
      return this.state.hasInProgressSate;
    }

    return false;
  }

  public get hasFinishedState() {
    if (this.state) {
      return this.state.hasFinishedState;
    }

    return false;
  }

  @Input() state: ImportProcessingState;
}
