import { RouterModule, Routes } from "@angular/router";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { canActivateRoleGuard } from "../../../angular-common/authentication-guard/role-guard";
import { CardViewModule } from "../../../angular-common/components/cardview";
import { ExportingModule } from "../../../angular-common/components/exporting";
import { ExportRoutesDto } from "../../../angular-common/components/exporting/dto/ExportRoutes-dto";
import { ExportingScreenComponent } from "./exporting-screen/exporting-screen.component";

const routes: Routes = [
  {
    path: ExportRoutesDto.ExportScreen,
    component: ExportingScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule, ExportingModule, CardViewModule],
  exports: [ExportingScreenComponent],
  declarations: [ExportingScreenComponent],
})
export class AppImagineExportModule {}
