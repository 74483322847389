import { ColumnItems } from "./column-items.model";
import { DataMutationsKeyFields } from "./data-mutations-keyfields.model";
import { DataMutationsMetaDataDto } from "../../../adapt/dto/DataMutationsMetaData-dto";
import { DataResultDto } from "../../../../../../common/models/dto/DataResult-dto";
import { VariantInformationDto } from "../../../../../../common/models/dto/VariantInformation-dto";

export class DataMutationsMetaData {
  public constructor() {}

  public copyFromDto(dto: DataMutationsMetaDataDto) {
    this.keyFields.copyFromDto(dto.KeyFields);
    this.result = dto.Result;
    this.columns.copyFromDto(dto.Columns);
    this.mutationColumns.copyFromDto(dto.MutationColumns);
    this.variantsInfos = dto.Variants;
  }

  public keyFields: DataMutationsKeyFields = new DataMutationsKeyFields();
  public columns: ColumnItems = new ColumnItems();
  public mutationColumns: ColumnItems = new ColumnItems();
  public variantsInfos: VariantInformationDto[] = [];
  public result: DataResultDto;
}
