import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { ModelPresentableElement } from "../../../common/model/models/model-presentablel-element-model";
import { ElementTypeDto } from "../../../common/models/dto/ElementType-dto";
import { ModelTreeBranch } from "./modeltreeservice/models/modeltree-branch";
import { ModelTreeChangeType } from "./modeltreeservice/models/modeltree-change";
import { ModelTreeChangeModel } from "./modeltreeservice/models/modeltree-change-model";
import { ModelTreeService } from "./modeltreeservice/modeltree-service";

@Component({
  selector: "app-modeltree",
  templateUrl: "./modeltree-component.html",
  styleUrls: ["./modeltree-component.scss"],
})
export class ModelTreeComponent implements OnInit {
  @Input()
  public excludeBaseModel: boolean;

  private _allowedTypes: ElementTypeDto[];
  @Input()
  public set allowedTypes(value: ElementTypeDto[]) {
    if (value) {
      this._allowedTypes = value;
    }
  }
  public get allowedTypes() {
    return this._allowedTypes;
  }
  @Input()
  public set allowedType(value: ElementTypeDto) {
    if (value) {
      this._allowedTypes = [];
      this._allowedTypes.push(value);
    }
  }

  private _selectedModelElementType = ElementTypeDto.Unknown;
  private _selectedModelElementIds: number[] = [];

  public constructor(public language: WebLanguage, private modelTreeService: ModelTreeService) {
    this.excludeBaseModel = false;
    this._allowedTypes = [];
    this._allowedTypes.push(ElementTypeDto.Variant);
  }

  @Input()
  set treeUpdateEvent(event: ModelTreeChangeModel) {
    this.loadModelTreeOnBranch(event);
  }

  @Output() revalidateOnSelectionChanged: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.loadModelTree();
  }

  onSelectionChanged(): void {
    if (this.revalidateOnSelectionChanged.observers.length > 0) {
      this.revalidateOnSelectionChanged.emit();
    }
  }

  loadModelTreeOnBranch(event: ModelTreeChangeModel) {
    const here = this;
    this.modelTreeService.getModelTree().subscribe((tree) => {
      let nodeToSelect;
      if (this.selectedBranch) {
        if (event.type == ModelTreeChangeType.Created) {
          nodeToSelect = this.selectedBranch.id;
          here.loadNewTree(tree);
          this.selectedBranch = this.findByModelId(nodeToSelect);
          nodeToSelect = Math.max(...this.selectedBranch.children.map((value) => value.id));
          this.selectedBranch = this.findByModelId(nodeToSelect);
        } else if (event.type == ModelTreeChangeType.Removed) {
          nodeToSelect = this.selectedBranch.parentId;
          here.loadNewTree(tree);
          this.selectedBranch = this.findByModelId(nodeToSelect);
        } else if (event.type == ModelTreeChangeType.Update) {
          nodeToSelect = this.selectedBranch.id;
          here.loadNewTree(tree);
          this.selectedBranch = this.findByModelId(nodeToSelect);
        }
      }
    });
  }

  private show(value: ModelTreeBranch) {
    if (value) {
      if (this.allowedTypes.find((aet) => aet === value.element.elementType)) {
        return true;
      }
    }
    return false;
  }

  public filterByType(values: ModelTreeBranch[]) {
    let roots = values.filter((f) => this.show(f));
    roots.forEach((r) => {
      r.children = this.filterByType(r.children);
    });
    return roots;
  }

  public loadNewTree(newTree: ModelTreeBranch) {
    let newTreeAsPlural = [];
    if (this.excludeBaseModel && newTree.element.elementType === ElementTypeDto.Variant && newTree.element.id === 0) {
      newTreeAsPlural = newTree.children;
    } else {
      newTreeAsPlural.push(newTree);
    }

    const newBranches = this.filterByType(newTreeAsPlural);

    const newExpanded: number[] = [];
    this.expandedRowKeys.forEach((expanded) => {
      if (newTree.getWithId(expanded)) {
        newExpanded.push(expanded);
      }
    });

    const newSelected: number[] = [];
    this._selectedModelElementIds.forEach((mid) => {
      const selectedBranch = this.findByModelId(mid);
      if (selectedBranch) {
        newSelected.push(selectedBranch.id);
      }
    });

    this.branches = newBranches;
    this.expandedRowKeys = newExpanded;
    this._selectedRowKeys = newSelected;
  }

  private findByRowKey(id: number) {
    if (this.branches) {
      for (let b of this.branches) {
        const res = b.getWithId(id);
        if (res) {
          return res;
        }
      }
    }
  }

  private findByModelId(id: number) {
    if (this.branches) {
      for (let b of this.branches) {
        const res = b.getByModelId(id, this._selectedModelElementType);
        if (res) {
          return res;
        }
      }
    }
  }

  public get selectedRowKeys(): number[] {
    return this._selectedRowKeys;
  }
  public set selectedRowKeys(newKeys: number[]) {
    this._selectedRowKeys = newKeys;
    if (newKeys && newKeys !== null && newKeys.length > 0) {
      const selectedBranches = newKeys.map((k) => this.findByRowKey(k)).filter((f) => f !== undefined && f !== null);

      if (selectedBranches.length > 0) {
        const singleBranch = selectedBranches[0];
        this._selectedModelElementType = singleBranch.element.elementType;
        this.emitSingleBranchSelected(singleBranch);
        this.emitSingleElementSelected(singleBranch);
      }

      const selectedElements = selectedBranches.map((b) => b.element);
      this._selectedModelElementIds = selectedElements.map((e) => e.id);

      this.emitMultipleElementsSelected(selectedElements);
    }
  }
  private _selectedRowKeys: number[] = [];

  public expandedRowKeys: number[] = [];
  public branches: ModelTreeBranch[] = [];

  private emitSingleElementSelected(singleBranch: ModelTreeBranch) {
    this.selectedElementChange.emit(singleBranch.element);
  }

  private emitMultipleElementsSelected(selectedElements: ModelPresentableElement[]) {
    this.selectedElementsChange.emit(selectedElements);
  }

  private emitSingleBranchSelected(singleBranch: ModelTreeBranch) {
    this.selectedBranchChange.emit(singleBranch);
  }

  // The following are for 'outside' setting of the branches/models

  @Input()
  public set selectedElement(value: ModelPresentableElement) {
    if (value) {
      this._selectedModelElementType = value.elementType;
      this._selectedModelElementIds = [];
      this._selectedModelElementIds.push(value.id);
    }
  }
  public get selectedElement() {
    let se: ModelPresentableElement = null;
    const branches = this.selectedElements;
    if (branches.length > 0) {
      se = branches[0];
    }
    return se;
  }

  @Input()
  public get selectedBranch(): ModelTreeBranch {
    const selectedBranches = this.selectedBranches;
    if (selectedBranches.length > 0) {
      return selectedBranches[0];
    }
    return undefined;
  }
  public set selectedBranch(newBranch: ModelTreeBranch) {
    let branches = [];
    if (newBranch) {
      branches.push(newBranch);
    }
    this.selectedBranches = branches;
  }

  public get selectedElements(): ModelPresentableElement[] {
    return this.selectedBranches.map((t) => t.element);
  }

  @Input()
  public get selectedBranches(): ModelTreeBranch[] {
    let result = [];

    this.selectedRowKeys.forEach((rk) => {
      const branch = this.findByRowKey(rk);
      if (branch) {
        result.push(branch);
      }
    });

    return result;
  }
  public set selectedBranches(values: ModelTreeBranch[]) {
    if (values) {
      this._selectedRowKeys = values.map((b) => b.id);
      this._selectedModelElementIds = values.map((b) => b.element.id);
      if (values.length > 0) {
        this._selectedModelElementType = values[0].element.elementType;
      }
    }
  }

  loadModelTree() {
    const here = this;
    var s = this.modelTreeService.getModelTree().subscribe((tree) => {
      here.loadNewTree(tree);
    });
  }

  @Output()
  public selectedBranchChange = new EventEmitter<ModelTreeBranch>();

  @Output()
  public selectedElementsChange = new EventEmitter<ModelPresentableElement[]>();

  @Output()
  public selectedElementChange = new EventEmitter<ModelPresentableElement>();
}
