import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Sorter } from "../../../../../../angular-common";
import { DashletViewDto } from "../../../analysis/dto/DashletViewDto-dto";
import { DashletViewSettings } from "../../dashletviewsettings/models/dashlet-view-settings";
import { DashletViewService } from "../dashlet-view.service";
import { DashletView } from "./dashlet-view.model";

export class DashletViewList {
  private _views: DashletView[] = [];

  public constructor(private dashletViewService: DashletViewService) {}

  public get views(): DashletView[] {
    return this._views;
  }

  public copyFromDTO(dto: DashletViewDto[]) {
    this._views.splice(0, this._views.length);

    if (dto && dto !== null && dto.length > 0) {
      dto.forEach((dd) => {
        const vs = new DashletViewSettings();
        vs.fromDto(dd.ViewSettings);
        const newDashletView = new DashletView(this.dashletViewService, dd.DashletViewId, dd.ViewType, dd.DashletType, dd.SortOrder, dd.Name, dd.PaletteName, vs);
        this._views.push(newDashletView);
      });
      this.sort();
    }
  }

  public sort() {
    this._views.sort(Sorter.compareSortOrder);
  }

  public toDTO(): DashletViewDto[] {
    const result: DashletViewDto[] = [];
    this._views.forEach((v) => {
      result.push(v.toDTO());
    });
    return result;
  }

  public addView(dashletView: DashletView) {
    this._views.push(dashletView);
    this.sort();
  }

  public delete(dashletView: DashletView): Observable<void> {
    const idx = this._views.indexOf(dashletView);
    let result = dashletView.delete();

    result = result.pipe(
      map(() => {
        this._views.splice(idx, 1);
        return;
      }),
    );

    return result;
  }
}
