import { RouterModule, ROUTES } from "@angular/router";

import { NgModule } from "@angular/core";
import { canActivateRoleGuard } from "../../../../angular-common/authentication-guard/role-guard";
import { ImagineFeaturesModel } from "../imagine-features-model";
import { VismaRaetConfigurationComponent } from "./config-screen/vismaraet-config.component";
import { VismaRaetRoutesDto } from "./dto/VismaRaetRoutes-dto";
import { vismaRaetConfigurationResolver } from "./vismaraet-config.resolver";
import { VismaRaetLanguage } from "./vismaraetlanguage.service";

function routesFactory(featuresModel: ImagineFeaturesModel) {
  return [
    {
      path: VismaRaetRoutesDto.VismaRaetConfiguration,
      component: VismaRaetConfigurationComponent,
      canActivate: [canActivateRoleGuard],
      resolve: {
        configurationsData: vismaRaetConfigurationResolver,
      },
      loadChildren: () => {
        const enabled = featuresModel.IsVismaRaetEnabled();
        console.log("vismaraet-enabled", enabled);
        return enabled === true ? import("./vismaraet-config.module").then((m) => m.VismaRaetConfigurationModule) : "";
      },
    },
  ];
}

@NgModule({
  imports: [RouterModule.forChild([])],
  providers: [
    VismaRaetLanguage,
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
      deps: [ImagineFeaturesModel],
    },
    VismaRaetLanguage,
  ],
  exports: [RouterModule],
})
export class VismaRaetFeatureLoaderModule {}
