
// t4tsCode gen": "0.8.2.0 for file DashletViewSettingsDto.cs
// Don't adjust manually!

export class DashletViewSettingsDto {
    public DashletViewId: number;
    public RotateAxisValues: boolean;
    public ShowScrollbar: boolean;
    public MaximumVisibleItems: number;
    public ShowPivotChart: boolean;
    public ChartLayout: string;
}

