import { Component, Input } from "@angular/core";

import { ToasterInput } from "./toaster-input-model";

@Component({
  selector: "app-toaster",
  templateUrl: "./toaster-component.html",
  styleUrls: ["./toaster-component.scss"],
  providers: [],
})
export class ToasterComponent {
  @Input()
  public model: ToasterInput;

  constructor() {
    this.model = new ToasterInput();
  }

  public get visible() {
    if (this.model) {
      return this.model.visible;
    }
    return false;
  }
  public set visible(value: boolean) {
    if (this.model) {
      this.model.visible = value;
    }
  }

  public get type() {
    if (this.model) {
      return this.model.state;
    }
    return "";
  }

  public get message() {
    if (this.model) {
      return this.model.message;
    }
    return "empty";
  }
}
