import { ImportSpecificationFileDto } from "../../../import/dto/ImportSpecificationFile-dto";
import { FileDefinition } from "./file-definition.model";

export class FileDefinitionList {
  private _files: FileDefinition[];

  public constructor() {
    this._files = [];
  }

  public get files(): FileDefinition[] {
    return this._files;
  }

  public copyFromDTO(dto: ImportSpecificationFileDto[]) {
    this._files.splice(0, this._files.length);

    if (dto && dto !== null && dto.length > 0) {
      dto.forEach(dd => {
        const newFileDefinition = new FileDefinition();
        newFileDefinition.copyFrom(dd);
        this._files.push(newFileDefinition);
      });
    }
  }

  public addFile(file: FileDefinition) {
    this._files.push(file);
  }
}
