import { ImportFile } from "./import-file.model";
import { ImportProcessingStateDto } from "../../../import/dto/ImportProcessingState-dto";
import { ProcessingMessageStateDto } from "../../../../../../common/models/integrationservices/dto/ProcessingMessageState-dto";
import { ProcessingState } from "./processing-state.model";

export class ImportProcessingState extends ProcessingState {
  specificationName: string;
  importedFiles: ImportFile[];
  messageState: ProcessingMessageStateDto;

  constructor() {
    super();
    this.importedFiles = [];
  }

  copyFrom(dto: ImportProcessingStateDto): void {
    super.copyFrom(dto);
    this.specificationName = dto.SpecificationName;
    this.messageState = dto.MessageState;

    dto.ImportedFiles.forEach((dtos) => {
      const file = new ImportFile();
      file.copyFrom(dtos);
      this.importedFiles.push(file);
    });
  }
}
