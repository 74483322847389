import { DateTranslator } from "../../../../../common/modelelements/date-translator";
import { EventTimerHistoryDto } from "../../../../../common/models/integrationservices/dto/EventTimerHistoryDto-dto";
import { IntegrationServiceLanguage } from "../../../../../common/language/integrationservicelanguage.service";

export class EventTimerHistory {
  public static toDto(eventHistory: EventTimerHistory): EventTimerHistoryDto {
    const dto = new EventTimerHistoryDto();

    dto.LastRun = DateTranslator.toDto(eventHistory.lastRun);
    dto.NextRun = DateTranslator.toDto(eventHistory.nextRun);
    dto.TimesRun = eventHistory.timesRun;
    dto.Running = eventHistory.running;

    return dto;
  }
  private lastRun: Date;
  private nextRun: Date;

  public lastRunText: string = "";
  public nextRunText: string = "";
  public timesRun: number = 0;
  public running: boolean = true;

  public copyFromDto(dto: EventTimerHistoryDto, langauge: IntegrationServiceLanguage) {
    this.lastRun = DateTranslator.fromDto(dto.LastRun);
    this.lastRunText = DateTranslator.toTextDate(this.lastRun, langauge.eventTimerEventRecurrence_NotYet);

    this.nextRun = DateTranslator.fromDto(dto.NextRun);
    this.nextRunText = DateTranslator.toTextDate(this.nextRun, langauge.eventTimerEventRecurrence_Unknown);

    this.timesRun = dto.TimesRun;
    this.running = dto.Running;
  }
}
