import { Component, OnInit } from "@angular/core";
import { Tab, Tabs } from "../../../../angular-common";

import { ImagineLanguage } from "../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashboarding-design",
  templateUrl: "./dashboarding-design.component.html",
  styleUrls: ["./dashboarding-design.component.scss"],
})
export class DashboardingDesignComponent implements OnInit {
  public tabs: Tabs = new Tabs();

  public readonly dashletDefinitionTabId: number = 1;
  public readonly dashboardTabId: number = 2;
  public readonly dataSupplyId: number = 2;

  constructor(public language: ImagineLanguage) {}
  ngOnInit() {
    this.tabs.selectedItemChanged.subscribe(this.tabChanged);

    const dashletDefinitionDesignTab = this.tabs.addNew(this.dashletDefinitionTabId, this.language.dashboarddesignDashletsTab, "spinup", null);
    this.tabs.addNew(this.dashboardTabId, this.language.dashboarddesignDashboardsTab, "spinup", null);
    this.tabs.selectedTab = dashletDefinitionDesignTab;
  }

  private tabChanged(newTab: Tab) {}
}
