import { Component, OnChanges, ViewChild } from "@angular/core";

import { DxPieChartComponent } from "devextreme-angular";
import { Palettes } from "../../../../../angular-common/components/palettes";
import { DashletViewTypeDto } from "../../analysis/dto/DashletViewType-dto";
import { DashletDataPoint } from "../../services/dashletdata";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { DashletGraphComponent } from "./dashlet-graph.component";
import { DataSerieItemContent } from "./dataserie-item-content";
import { IContextMenuClick } from "./i-context-menu-click";
import { IOnPointClickArg } from "./i-on-point-click-arg";
import { IPoint } from "./i-point";
import { ITooltipArg } from "./i-tooltip-arg";

@Component({
  selector: "app-dashlet-pie",
  templateUrl: "./dashlet-pie.component.html",
})
export class DashletPieComponent extends DashletGraphComponent implements OnChanges {
  @ViewChild(DxPieChartComponent) pieChart: DxPieChartComponent;

  public constructor(palettes: Palettes, language: ImagineLanguage) {
    super(palettes, language);
  }

  // Make these enums available to the html template:
  public dashletViewType = DashletViewTypeDto;

  public customizeTooltip(arg: ITooltipArg) {
    const context: DataSerieItemContent = arg.point.tag;
    let htmlText = "";

    let dataPoint: DashletDataPoint;

    if (context.dataPoint2 && context.dataPoint2.clusteredBy.filter((x) => x.value === arg.argument || x.value === arg.seriesName).length === 2) {
      dataPoint = context.dataPoint2;
    } else {
      dataPoint = context.dataPoint1;
    }

    htmlText += "<span style='font-size: 11px !important;' >";

    if (arg.seriesName === "variant1") {
      htmlText += dataPoint.valueAsText;
    } else {
      htmlText += arg.seriesName;
      htmlText += ":  ";
      htmlText += dataPoint.valueAsText;
    }

    return {
      html: htmlText,
    };
  }

  contextMenuClick(e: IContextMenuClick) {
    if (e.itemData.text === this.language.dashletBarContextMenuFilterBy) {
      const series = this.pieChart.instance.getAllSeries();
      const selectedPoints = this.getSelectedPoints(series);
      this.adjustFiltersByPoints(selectedPoints);
      this.filterList.apply();
    } else if (e.itemData.text === this.language.dashletBarContextMenuRemoveFilter) {
      this.clearFilterOptions();
      this.filterList.apply();
    }
  }

  public updatedCanFilter(): boolean {
    if (this.pieChart) {
      const series = this.pieChart.instance.getAllSeries();
      const selectedPoints = this.getSelectedPoints(series);
      return selectedPoints.length > 0;
    }
    return false;
  }

  customizeLabel(arg) {
    return arg.argument;
  }

  legendClickHandler(e) {
    const arg = e.target;
    const point: IPoint = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
    const series = this.pieChart.instance.getAllSeries();

    this.toggleClick(point, series);
  }

  onPointClick(e: IOnPointClickArg) {
    const point = e.target;
    const series = this.pieChart.instance.getAllSeries();

    this.toggleClick(point, series);

    this.filterByMenuItem.disabled = !this.updatedCanFilter();
  }
}
