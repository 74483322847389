import { ModelElementOption } from "../../../../../../common/modelelements";
import { ReferenceCategoriesDto } from "../../../../../../common/models/dto/ReferenceCategoriesDto-dto";

export class ReferenceCategorie {
  public shortName: string;
  public categoryId: number;
  public options: ModelElementOption[];

  fromDto(dto: ReferenceCategoriesDto) {
    const options = dto.Options.map(option => new ModelElementOption(option.Id, option.Text));

    this.categoryId = dto.CategoryId;
    this.shortName = dto.ShortName;
    this.options = options;
  }
}
