import { SelectableItemsBase } from "../..";
import { SelectBoxItem } from "./selectbox-item";

export class SelectBoxItems extends SelectableItemsBase<SelectBoxItem> {
  public addNew(id: number, text: string, icon: string): SelectBoxItem {
    const result = new SelectBoxItem(id, text);
    result.icon = icon;
    result.hint = text;
    this.addItem(result);
    return result;
  }
}
