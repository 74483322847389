import { Component, Input } from "@angular/core";

import { DataSupplyRoutesDto } from "../../import/dto/DataSupplyRoutes-dto";
import { DataSupplyTabContent } from "../data-supply-tabs/data-supply-tab-content";
import { ImportDefinition } from "../../services/import/models/import-definition.model";
import { RouteNavigation } from "../../../../../angular-common/baseservice/route-navigation";

@Component({
  selector: "app-data-supply-content",
  templateUrl: "./data-supply-content.component.html",
  styleUrls: ["./data-supply-content.component.scss"],
})
export class DataSupplyContentComponent {
  constructor(private routeNavigation: RouteNavigation) {}

  @Input() tabContent: DataSupplyTabContent;

  public goToDetails(def: ImportDefinition) {
    if (def) {
      this.routeNavigation.navigate(DataSupplyRoutesDto.DataSupply, def.id);
    }
    return false;
  }
}
