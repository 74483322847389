<div class="cluster-container">
  <div class="buttons align-right">
    <p class="savewarning" *ngIf="hasChanges">*Click to save changes</p>
    <dx-button id="saveButton" icon="save" [hint]="language.ButtonSave" (click)="saveSelection()"> </dx-button>
  </div>
  <app-list-selector
    [itemsInUse]="editingList"
    [availableItems]="availableList"
    [displayValue]="displayValue"
    [searchValue]="searchValue"
    [moveImplementation]="moveImplementation"
    (valuesChanged)="groupChanged()"
    (sortOrderChanged)="listingOrderChanged($event)"
  >
  </app-list-selector>
</div>
