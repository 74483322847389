import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ComputeVariantDto } from "../../../../../common/models/dto/ComputeVariantDto-dto";
import { VariantDto } from "../../../../../common/models/dto/VariantDTO-dto";
import { VariantInformationDto } from "../../../../../common/models/dto/VariantInformation-dto";

@Injectable()
export class VariantComputeService extends PlBaseService {
  private computeEndpoint: string = "VariantCompute";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public computeVariant(variantDto: ComputeVariantDto): Observable<VariantDto> {
    return this.postData(this.computeEndpoint + "/", variantDto);
  }

  public retrieveVariantState(variantId: number[]): Observable<VariantInformationDto[]> {
    var idsAsText = variantId.join("-");
    return this.getData(this.computeEndpoint + "/" + idsAsText);
  }
}
