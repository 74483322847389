import { PatternRuleDto } from "../../../../../../common/models/dto/PatternRule-dto";
import { ValidationRule } from "./validation-rule.model";

export class ValidationRulePattern extends ValidationRule {
  public constructor() {
    super();
  }

  public copyFromDto(dto: PatternRuleDto) {
    super.copyFromDto(dto);

    this.pattern = dto.pattern;
  }

  public pattern: string;
}
