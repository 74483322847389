import {
  DxButtonModule,
  DxCheckBoxModule,
  DxContextMenuModule,
  DxDrawerModule,
  DxDropDownBoxModule,
  DxFormModule,
  DxListModule,
  DxNumberBoxModule,
  DxPivotGridModule,
  DxPopoverModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxSliderModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTemplateModule,
  DxToolbarModule,
  DxTooltipModule,
} from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CardViewModule } from "../../../../angular-common/components/cardview";
import { PlPeriodPickerModule } from "../../../../angular-common/components/periodpicker/pl-periodpicker.module";
import { RadioGroupModule } from "../../../../angular-common/components/radiogroup/radiogroup.module";
import { PlSliderModule } from "../../../../angular-common/components/slider/pl-slider.module";
import { VariantInformationModule } from "../modeling/variant-info-module/variant-information-module";
import { FilterServiceModule } from "../services/filter";
import { FilterModule } from "../shared/filter/filter.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashletViewEditModule } from "./dashboard/dashletviewedit/dashlet-edit.module";
import { DashboardingScreenComponent } from "./dashboarding-screen.component";
import { DashboardingTabsComponent } from "./dashboarding-tabs/dashboarding-tabs.component";
import { DashboardingViewComponent } from "./dashboarding-view/dashboarding-view.component";
import { DashletModule } from "./dashlet/dashlet.module";
import { ExactParameterComponent } from "./parametertoolbar/exact-parameter/exact-parameter.component";
import { ParameterToolBarComponent } from "./parametertoolbar/parameter-tool-bar.component";
import { ReferenceParameterComponent } from "./parametertoolbar/reference-parameter/reference-parameter.component";

@NgModule({
  imports: [
    CommonModule,
    DxTabsModule,
    DxTooltipModule,
    DxListModule,
    DxContextMenuModule,
    DxDrawerModule,
    DxToolbarModule,
    DxPopupModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    CardViewModule,
    FilterModule,
    FilterServiceModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxTemplateModule,
    DxSliderModule,
    PlSliderModule,
    DxTabPanelModule,
    DxSelectBoxModule,
    PlPeriodPickerModule,
    DxFormModule,
    DxPopoverModule,
    DxPivotGridModule,
    VariantInformationModule,
    RadioGroupModule,
    DashletModule,
    DashletViewEditModule,
  ],
  declarations: [
    DashboardingScreenComponent,
    DashboardingViewComponent,
    DashboardingTabsComponent,
    DashboardComponent,
    ParameterToolBarComponent,
    ExactParameterComponent,
    ReferenceParameterComponent,
  ],
})
export class DashboardingScreenModule {}
