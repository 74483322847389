import { Component, OnInit } from "@angular/core";
import { ImportDefinitionList, ImportService } from "../../services/import";

import { ActivatedRoute } from "@angular/router";
import { DataSupplyRoutesDto } from "../../import/dto/DataSupplyRoutes-dto";
import { DataSupplyTabContent } from "./data-supply-tab-content";
import { ImportLanguage } from "../../services/language/connectorlanguage.service";
import { RouteNavigation } from "../../../../../angular-common/baseservice/route-navigation";
import { Tabs } from "../../../../../angular-common";

@Component({
  selector: "app-data-supply-tabs",
  templateUrl: "./data-supply-tabs.component.html",
  styleUrls: ["./data-supply-tabs.component.scss"],
})
export class DataSupplyTabsComponent implements OnInit {
  public dataSupplyTabs: ImportDefinitionList;

  public tabs: Tabs;
  public selectedTab: number;

  public files: any[];

  public get getSelectedTab(): number {
    return this.selectedTab;
  }

  private dataSupplyListingTab: DataSupplyTabContent;
  private static tabIdListing = 0;
  public static tabUploadStatus = 1;

  public constructor(private routeNavigation: RouteNavigation, private route: ActivatedRoute, private importService: ImportService, private langauge: ImportLanguage) {
    this.tabs = new Tabs();

    this.dataSupplyListingTab = new DataSupplyTabContent(true);
    this.tabs.addNew(DataSupplyTabsComponent.tabIdListing, this.langauge.menuDataSupplyImports, "spinup", this.dataSupplyListingTab);
    this.tabs.addNew(DataSupplyTabsComponent.tabUploadStatus, this.langauge.menuDataSupplyImportStatus, "spinup", new DataSupplyTabContent(false));
  }

  ngOnInit() {
    this.importService.getImports().subscribe((imports) => {
      this.dataSupplyTabs = imports;
      this.dataSupplyListingTab.imports = imports;
      this.navigateToItemFromUri(this.tabs);
    });
  }

  private navigateToItemFromUri(allTabs: Tabs) {
    if (allTabs.items.length > 0) {
      allTabs.selectedTab = allTabs.items[0];
    }
    this.tabs = allTabs;
    if (this.tabs.items.length > 0) {
      this.route.params.subscribe((params) => {
        if ("id" in params) {
          const paramsId = parseInt(params.id, 10);
          if (paramsId > 0) {
            allTabs.selectedTab = allTabs.items[0];
          }
          if (this.tabs.items.find((tab) => tab.id === paramsId)) {
            this.selectedTab = paramsId;
            const selectedTab = this.tabs.items.find((x) => x.id === paramsId);
            this.tabs.selectedTab = selectedTab;
          } else {
            this.selectedTab = this.tabs.items[0].id;
          }
        }
      });
      if (this.selectedTab === undefined || this.selectedTab === null) {
        this.navigateToTabId(this.tabs.items[0].id);
      }
    }
  }

  selectTab(e) {
    const tabData = e.itemData;
    this.navigateToTabId(tabData.id);
  }

  private navigateToTabId(idOfDataSupplyTab: number) {
    this.routeNavigation.navigateWithParameterId(DataSupplyRoutesDto.DataSupplyScreen, idOfDataSupplyTab);
    this.selectedTab = idOfDataSupplyTab;
  }
}
