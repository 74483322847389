import { Component, Input } from "@angular/core";
import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { LongNameShortName } from "../../../../../angular-common/longnameshortname/longname-shortname";
import { CopyingObjects } from "../../../../../angular-common/utils/copying-objects";
import { RoleDto } from "../../../../../common/models/dto/RoleDto-dto";
import { UserPublicInformationDto } from "../../../../../common/models/dto/UserPublicInformationDto-dto";
import { DashboardDefinition } from "../../services/dashboard";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashboard-design-authorization",
  templateUrl: "./dashboard-design-authorization.component.html",
  styleUrls: ["./dashboard-design-authorization.component.scss"],
})
export class DashboardDesignAuthorizationComponent {
  private currentDashboard: DashboardDefinition;

  public assignedRoles: RoleDto[];
  public availableRoles: RoleDto[];
  public dashboardOwner: UserPublicInformationDto;
  public assignRolesPopupVisible = false;

  @Input() set dashboard(value: DashboardDefinition) {
    this.currentDashboard = value;
    this.dashboardOwner = value.authorization.Owner;
    this.assignedRoles = CopyingObjects.deepCopy(value.authorization.AssignedRoles);
  }

  @Input() set allRoles(value: RoleDto[]) {
    this.availableRoles = value;
  }

  public constructor(public language: ImagineLanguage) {}

  public get sharedWith() {
    if (this.currentDashboard.authorization.AssignedRoles.length > 0) {
      const rolesNames = this.currentDashboard.authorization.AssignedRoles.map((r) => r.LongName).join(", ");
      return this.language.dashboardSharedWithRoles(rolesNames);
    }

    return this.language.dashboardNotShared;
  }

  public get ownerName() {
    if (this.currentDashboard.userId === undefined || this.currentDashboard.userId === null) {
      return this.language.dashboardOwnerNameUndefined;
    }

    if (ElementHelper.isNullOrUndefined(this.dashboardOwner.Name) == false) {
      return this.language.dashboardOwnerName(this.dashboardOwner.Name);
    }

    return this.language.dashboardOwnerNameUndefined;
  }

  public getRoleDisplayName(role: RoleDto) {
    return LongNameShortName.NameForDto(role);
  }

  public onListSelectionChanged(e: any) {
    if (e === undefined || e === null) {
      return;
    }

    this.addRolesIfNotAssigned(e.addedItems);
    this.removeRolesIfAssigned(e.removedItems);
  }

  private addRolesIfNotAssigned(addedRoles: any) {
    if (addedRoles === undefined || addedRoles === null || !Array.isArray(addedRoles)) {
      return;
    }

    addedRoles.forEach((addedRole) => {
      const role = addedRole as RoleDto;
      if (this.assignedRoles.find((r) => r.KeyId === role.KeyId) === undefined) {
        this.assignedRoles.push(role);
      }
    });
  }

  private removeRolesIfAssigned(removedRoles: any) {
    if (removedRoles === undefined || removedRoles === null || !Array.isArray(removedRoles)) {
      return;
    }

    removedRoles.forEach((removedRole) => {
      const role = removedRole as RoleDto;
      var index = this.assignedRoles.findIndex((r) => r.KeyId === role.KeyId);
      if (index > -1) {
        this.assignedRoles.splice(index, 1);
      }
    });
  }

  public onOkButtonClicked(e: any) {
    this.currentDashboard.authorization.AssignedRoles = CopyingObjects.deepCopy(this.assignedRoles);
    this.currentDashboard.saveChanges().subscribe();
    this.assignRolesPopupVisible = false;
  }

  public onCancelButtonClicked(e: any) {
    this.assignRolesPopupVisible = false;
  }

  public onAssignRolesButtonClicked(e: any) {
    this.assignedRoles = CopyingObjects.deepCopy(this.currentDashboard.authorization.AssignedRoles);
    this.assignRolesPopupVisible = true;
  }
}
