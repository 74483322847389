import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DashletDefinition, DashletDefinitionList } from "../../services/dashletdefinition";

import { confirm } from "devextreme/ui/dialog";
import { forkJoin } from "rxjs";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashlet-definition-design-list",
  templateUrl: "./dashlet-definition-design-list.component.html",
  styleUrls: ["./dashlet-definition-design-list.component.scss"],
})
export class DashletDefinitionDesignListComponent {
  constructor(public language: ImagineLanguage) {}

  @Input()
  public showButtons: boolean = true;

  @Input()
  public listHeight: number;

  @Input()
  public get list(): DashletDefinitionList {
    return this._list;
  }
  public set list(newList: DashletDefinitionList) {
    this._list = newList;
    if (this._list && this._list.dashletDefinitions && this._list.dashletDefinitions.length > 0) {
      this.selectedItems.push(this._list.dashletDefinitions[0]);
    }
  }
  private _list: DashletDefinitionList;

  public get selectedItems(): DashletDefinition[] {
    return this._selectedItems;
  }
  public set selectedItems(newItems: DashletDefinition[]) {
    this._selectedItems = newItems;
    this.selectionChanged.emit(newItems);
  }
  private _selectedItems: DashletDefinition[] = [];

  public get hasSelectedItems(): boolean {
    return this.selectedItems && this.selectedItems !== null && this.selectedItems.length > 0;
  }

  @Output()
  public selectionChanged: EventEmitter<DashletDefinition[]> = new EventEmitter<DashletDefinition[]>(true);

  itemAdd(e) {
    const newDefinition = this.list.addNew();
    if (this._list.dashletDefinitions.length === 1) {
      this._selectedItems.push(newDefinition);
    } else {
      this._selectedItems = [newDefinition];
    }
  }

  itemClone(e) {
    const copies: DashletDefinition[] = [];
    this.selectedItems.forEach((i) => {
      const copy = this.list.addCopy(i);
      copy.name = this.language.dashletDefinitionDesignCopyOf + copy.name;
      copies.push(copy);
    });
    this.selectedItems = copies;
  }

  itemDelete(e) {
    confirm(this.language.confirmDeleteHeader, this.language.confirmDeleteTitle).then((result) => {
      if (result) {
        this.deleteSelectedDashletDefinitions();
      }
    });
  }

  private deleteSelectedDashletDefinitions() {
    const itemsToDelete = this.selectedItems.slice();
    this.selectedItems = [];

    const obs = itemsToDelete.map((dd) => this.list.delete(dd));
    forkJoin(obs).subscribe(() => {
      if (this.list.dashletDefinitions.length > 0) {
        this.selectedItems = [this.list.dashletDefinitions[0]];
      }
    });
  }
}
