import { Component, Input } from "@angular/core";

import { DataFilter } from "../../../services/filter";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";

@Component({
  selector: "app-filteroption-list",
  templateUrl: "./filteroption-list.component.html",
  styleUrls: ["./filteroption-list.component.scss"],
})
export class FilterOptionListComponent {
  public constructor(public language: ImagineLanguage) {}

  @Input()
  public filter: DataFilter;

  @Input()
  public showTitle: boolean = true;

  public get selectedIds(): number[] {
    const result: number[] = [];
    for (const option of this.filter.options.options) {
      if (option.filterBy) {
        result.push(option.id);
      }
    }
    return result;
  }
  public set selectedIds(newIds: number[]) {
    for (const option of this.filter.options.options) {
      if (newIds === undefined || newIds === null) {
        option.filterBy = false;
      } else {
        option.filterBy = newIds.includes(option.id);
      }
    }
  }
}
