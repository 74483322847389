import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DashletDataService } from "./dashlet-data.service";

@NgModule({
  imports: [CommonModule],
  providers: [DashletDataService],
  declarations: [],
})
export class DashletDataModule {}
