<div class="user-management-container">
  <div>
    <dx-button id="newUserButton" [text]="language.sdWorxProvisioningUsersAddUser" icon="fas fa-plus" (onClick)="onAddUser()"></dx-button>
    <dx-button id="syncUsersButton" [text]="language.sdWorxProvisioningUsersScheduleActivationButton" icon="fas fa-sync" (onClick)="onSync()" class="button-sync"></dx-button>
  </div>
  <div>
    <div class="user-type-selection-container">
      <span class="text">{{ language.sdWorxProvisioningUsersSelectUserTypeLabel }}</span>
      <dx-select-box [(value)]="selectedUserType" [dataSource]="userTypeSelectionItems" displayExpr="name" (onItemClick)="onUserTypeSelected($event)"> </dx-select-box>
    </div>
  </div>
</div>

<div>
  <app-toaster [model]="toasterInput"></app-toaster>
</div>
<div>
  <dx-data-grid [dataSource]="users" (onRowUpdated)="onUpdate($event.data.User)" (onCellPrepared)="disableEditingForEmployeeTypeOfUser($event)" [columnAutoWidth]="true">
    <dxo-editing [allowUpdating]="true" useIcons="true"></dxo-editing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column [caption]="language.sdWorxProvisioningConfigPayGroup" dataField="User.PayGroup" [sortIndex]="0" sortOrder="asc" [allowEditing]="false"> </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningUsersShortName" dataField="User.ShortName" [allowEditing]="false"> </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningUsersFirstName" dataField="User.FirstName" [allowEditing]="false"> </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningUsersLastName" dataField="User.LastName" [allowEditing]="false"> </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningUsersEmail" dataField="User.Email" [allowEditing]="false"> </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningUsersUserType" dataField="Calculated" [calculateCellValue]="calculateUserTypeCellValue" [allowEditing]="false"> </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningUsersStartDate" dataField="User.Period.From" [format]="periodDisplayFormat" dataType="date">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningUsersEndDate" dataField="User.Period.Until" [format]="periodDisplayFormat" dataType="date">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningMessageStatusCorrelationId" dataField="CorrelationId" [allowEditing]="false"> </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningMessageStatusLastKnownStatus" dataField="Status" [allowEditing]="false"> </dxi-column>

    <dxi-column [caption]="language.sdWorxProvisioningMessageStatusDate" dataField="StatusDate" [allowEditing]="false" [format]="statusDateDisplayFormat" dataType="date"> </dxi-column>
  </dx-data-grid>
</div>

<dx-popup
  [width]="500"
  [height]="500"
  [showTitle]="true"
  [title]="language.sdWorxProvisioningUsersAddUser"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="addNewUserPopUpVisible"
>
  <dx-form [colCount]="2" [formData]="newUserData" (onFieldDataChanged)="onFormFieldDataChanged($event)" [showRequiredMark]="false">
    <dxi-item dataField="PayGroup" [colSpan]="2" [isRequired]="true"> <dxo-label [text]="language.sdWorxProvisioningConfigPayGroup"></dxo-label> </dxi-item>
    <dxi-item dataField="ShortName" [colSpan]="2" [isRequired]="true"> <dxo-label [text]="language.sdWorxProvisioningUsersShortName"></dxo-label> </dxi-item>
    <dxi-item dataField="FirstName" [colSpan]="2" [isRequired]="true"> <dxo-label [text]="language.sdWorxProvisioningUsersFirstName"></dxo-label> </dxi-item>
    <dxi-item dataField="LastName" [colSpan]="2" [isRequired]="true"> <dxo-label [text]="language.sdWorxProvisioningUsersLastName"></dxo-label> </dxi-item>
    <dxi-item dataField="Email" [colSpan]="2" [isRequired]="true"> <dxo-label [text]="language.sdWorxProvisioningUsersEmail"></dxo-label> </dxi-item>
    <dxi-item dataField="StartDate" [colSpan]="2" [isRequired]="true" editorType="dxDateBox" [editorOptions]="{ dateSerializationFormat: dateSerializationFormat, displayFormat: periodDisplayFormat }">
      <dxo-label [text]="language.sdWorxProvisioningUsersStartDate"></dxo-label>
    </dxi-item>
    <dxi-item dataField="EndDate" [colSpan]="2" [isRequired]="true" editorType="dxDateBox" [editorOptions]="{ dateSerializationFormat: dateSerializationFormat, displayFormat: periodDisplayFormat }">
      <dxo-label [text]="language.sdWorxProvisioningUsersEndDate"></dxo-label>
    </dxi-item>
  </dx-form>

  <div class="float-right">
    <dx-button class="button-form" [text]="language.sdWorxProvisioningUsersAddUser" (onClick)="onStoreUser()" [disabled]="canStoreUser == false"></dx-button>
    <dx-button class="button-form" [text]="language.ButtonCancel" (onClick)="onCancel()"></dx-button>
  </div>
</dx-popup>
