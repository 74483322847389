import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouteDataProvider } from "../../../angular-common/components/routedata";
import { DashboardingRoutesDto } from "./analysis/dto/DashboardingRoutes-dto";
import { ImagineLanguage } from "./services/language/imaginelanguage.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "body",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public constructor(private route: ActivatedRoute, private routeDataProvider: RouteDataProvider, public language: ImagineLanguage) {
    this.routeDataProvider.dataChanged.subscribe((d) => {
      this.isLoginPage = d && d.isLoginPage;
      this.isDashboardingScreen = d && d.path === DashboardingRoutesDto.DashboardingScreen;
    });

    document.title = language.applicationTitle;
  }

  public isLoginPage: boolean = false;
  public isDashboardingScreen: boolean = false;
}
