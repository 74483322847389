import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Sorter } from "../../../../../../../angular-common";
import { DashletDefinitionDto } from "../../../../analysis/dto/DashletDefinitionDTO-dto";
import { DashletTypeDto } from "../../../../analysis/dto/DashletType-dto";
import { ImagineLanguage } from "../../../language/imaginelanguage.service";
import { DashletDefinitionService } from "../../dashlet-definition-service";
import { DashletDefinition } from "./dashlet-definition.model";

export class DashletDefinitionList {
  private _dashletDefinitions: DashletDefinition[] = [];

  public constructor(private dashletDefinitionService: DashletDefinitionService, public language: ImagineLanguage) {}

  public get dashletDefinitions(): DashletDefinition[] {
    if (this.dashletTypeFilter.length === 0) {
      return this._dashletDefinitions;
    }

    const result: DashletDefinition[] = [];
    for (const definition of this._dashletDefinitions) {
      if (this.dashletTypeFilter.includes(definition.type)) {
        result.push(definition);
      }
    }

    return result;
  }

  public set dashletDefinitions(newDashletDefinitions: DashletDefinition[]) {
    this._dashletDefinitions = newDashletDefinitions;
  }

  public dashletTypeFilter: DashletTypeDto[] = [];

  public copyFromDTO(dashletDefinitionsDto: DashletDefinitionDto[]) {
    if (dashletDefinitionsDto && dashletDefinitionsDto !== null && dashletDefinitionsDto.length > 0) {
      dashletDefinitionsDto.forEach((dto) => {
        const newDashletDefinition = new DashletDefinition(this.dashletDefinitionService, dto.Id, dto.Name, dto.Type);
        newDashletDefinition.copyFromDTO(dto);
        this.addToList(newDashletDefinition);
      });

      this.sort(this._dashletDefinitions);
    }
  }

  private sort(list: DashletDefinition[]) {
    Sorter.sortSortOrderThenAlpha(list, (x) => x.name);
  }

  public addNew(): DashletDefinition {
    const newDefinition = new DashletDefinition(this.dashletDefinitionService, undefined, this.language.dashboarddesignNewDashletName, DashletTypeDto.Statistic);
    this.addToList(newDefinition);
    return newDefinition;
  }

  public addCopy(source: DashletDefinition): DashletDefinition {
    const result = source.getCopyAsNew();
    this.addToList(result);
    return result;
  }

  private addToList(newDefinition: DashletDefinition) {
    this._dashletDefinitions.push(newDefinition);
    newDefinition.setAfterSave(() => this.sort(this._dashletDefinitions));
  }

  public delete(dashletDefinition: DashletDefinition): Observable<void> {
    const idx = this._dashletDefinitions.indexOf(dashletDefinition);
    let result = dashletDefinition.delete();

    result = result.pipe(
      map(() => {
        this._dashletDefinitions.splice(idx, 1);
        return;
      }),
    );

    return result;
  }
}
