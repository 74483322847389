import { Component } from "@angular/core";

@Component({
  selector: "app-dashboarding-design-screen",
  templateUrl: "./dashboarding-design-screen.component.html",
  styleUrls: ["./dashboarding-design-screen.component.scss"],
})
export class DashboardingDesignScreenComponent {
  constructor() {}
}
