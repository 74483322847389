import { ImportDefinitionList } from "../../services/import/models/import-definition-list.model";

export class DataSupplyTabContent {
  imports: ImportDefinitionList;
  constructor(isImportListing: boolean) {
    this.isImportList = isImportListing;
  }

  public isImportList = false;
}
