import { CompareType } from ".";
import { DataSetFilterElementDto } from "../../../../models/dto/DataSetFilterElementDto-dto";
import { AuthorizationFilterElement } from "./authorization-filter-element-model";

export class AuthorizationFilterElements {
  private _elements: AuthorizationFilterElement[] = [];

  public constructor() {}

  public get all(): AuthorizationFilterElement[] {
    return this._elements;
  }

  public addFromDto(elementDto: DataSetFilterElementDto, compareTypes: CompareType[]) {
    const newElement = new AuthorizationFilterElement();
    newElement.copyFrom(elementDto, compareTypes);
    this._elements.push(newElement);
  }
}
