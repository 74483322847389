
// t4tsCode gen": "0.8.2.0 for file DataSupplyRoutes.cs
// Don't adjust manually!

export class DataSupplyRoutesDto {
    public static DataSupplyScreen: string = "data-supply-screen";
    public static DataSupply: string = "data-supply";
    public static DataSupplyDetail: string = "data-supply-detail";
}

