import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DashletViewSettingsService } from "../dashletviewsettings/dashlet-view-settings.service";
import { DashboardService } from "./dashboard.service";

@NgModule({
  imports: [CommonModule],
  providers: [DashboardService, DashletViewSettingsService],
  declarations: [],
})
export class DashboardModule {}
