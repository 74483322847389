import { NgModule } from "@angular/core";
import { AngularCommonConfigurationModule } from "../../../angular-common/angular-common-config-module";
import { CommonAppConfigurationModule } from "../../../angular-common/common-app-config-module";
import { CommonAuthConfigurationModule } from "../../../angular-common/common-auth-config-module";
import { AdaptModule } from "./adapt/adapt.module";
import { AppImagineExportModule } from "./app-export-module";
import { AppImagineModule } from "./app-imagine.module";
import { AppComponent } from "./app.component";
import { AppImagineDashboardModule } from "./dashboard.module";
import { ClusterEditModule } from "./dashboarding-screen/cluster-edit-screen/cluster-edit.module";
import { DataSupplyScreenModule } from "./data-supply-screen/data-supply-screen.module";
import { FilterModule } from "./shared/filter/filter.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularCommonConfigurationModule,
    CommonAuthConfigurationModule,
    CommonAppConfigurationModule,
    AppImagineDashboardModule,
    AppImagineExportModule,
    DataSupplyScreenModule,
    AdaptModule,
    AppImagineModule,
    FilterModule,
    ClusterEditModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
