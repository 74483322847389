import { ClusterField } from "./cluster-field";
import { DashletDataPointDto } from "../../../analysis/dto/DashletDataPoint-dto";
import { DashletDataPointWithDifferencesDto } from "../../../analysis/dto/DashletDataPointWithDifferences-dto";
import { DashletDataValue } from "./dashlet-data-value";
import { DashletDetailDataColumnFormatDto } from "../../../analysis/dto/DashletDetailDataColumnFormat-dto";
import { ISortOrder } from "../../../../../../angular-common";

export class DashletDataPoint implements ISortOrder {
  public constructor() {}

  public name: string;
  public value: number;
  public valueAsText: string;
  public sortOrder: number;
  public clusteredBy: ClusterField[] = [];
  public difference: DashletDataValue;
  public differenceRelative: DashletDataValue;
  public displayFormat: DashletDetailDataColumnFormatDto;

  public copyFromDTO(dto: DashletDataPointDto) {
    this.name = dto.Name;
    this.value = dto.Value;
    this.valueAsText = dto.ValueAsText;
    this.sortOrder = dto.SortOrder;
    const withDiff = dto as DashletDataPointWithDifferencesDto;
    if (withDiff.Difference !== undefined) {
      this.difference = new DashletDataValue().copyFromDTO(withDiff.Difference);
    }
    if (withDiff.DifferenceRelative !== undefined) {
      this.differenceRelative = new DashletDataValue().copyFromDTO(
        withDiff.DifferenceRelative,
      );
    }

    if (dto.ClusteredBy !== undefined && dto.ClusteredBy !== null) {
      dto.ClusteredBy.forEach(clusterFieldDto => {
        const clusterField = new ClusterField();
        clusterField.copyFromDTO(clusterFieldDto);
        this.clusteredBy.push(clusterField);
      });
    }

    this.displayFormat = dto.DisplayFormat;
  }
}
