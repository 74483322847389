import { AfterViewInit, Component } from "@angular/core";

import { ModelElementFactory } from "../../../../../../common/model/models/model-element-factory";
import { Variant } from "../../../../../../common/model/models/variant-model";
import { DataSetFilterElementDto } from "../../../models/dto/DataSetFilterElementDto-dto";
import { AuthorizationFilterService } from "../../../services/authorization/filters/authorization-filter.service";
import { IDataSetFilteritem, IDataSetFilteritemContent } from "../../../services/authorization/filters/models/authorization-tabs.tab-content-filter";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { AuthorizationFilterComponent } from "./authorization-content.component-filter";
import { DataSetFilterViewType } from "./dataSet-filterviewtype";

@Component({
  selector: "app-authorization-content-filter-role",
  templateUrl: "./authorization-content.component-filter.html",
  styleUrls: ["./authorization-content.component-filter.scss"],
})
export class AuthorizationFilterRoleComponent extends AuthorizationFilterComponent implements AfterViewInit {
  public get addFilterTitle(): string {
    return this.language.authorizationFilterActionsAddRole;
  }

  public constructor(language: ImagineLanguage, filtersService: AuthorizationFilterService) {
    super(language, filtersService);
  }

  ngAfterViewInit() {
    this.tabContent.updateDataSetFilterViewType(DataSetFilterViewType.ROLE);
  }

  public openNewTargetModal(filter: IDataSetFilteritem): void {
    this.clearFields();
    this.role = filter.role;
    this.addingVariant = true;
    this.addingFilter = true;
  }

  public openNewRoleModal(filter): void {
    console.error("filter can not be eddited as role", filter);
    return;
  }

  public openNewElementModal(filter: IDataSetFilteritem): void {
    this.configureEditorInternal(filter);
    this.editingElement = undefined;
    this.elementFormPopupEnabled = true;
  }

  public openEditElementModal(filter: IDataSetFilteritem, element: DataSetFilterElementDto): void {
    this.configureEditorInternal(filter);
    this.expressionModel.assignFrom(element);
    this.editingElement = element;
    this.elementFormPopupEnabled = true;
  }

  public deleteElement(filter: IDataSetFilteritem, element: DataSetFilterElementDto): void {
    this.clearFields();
    this.variant = <Variant>ModelElementFactory.create(filter.variant);
    this.role = filter.role;
    this.tabContent.deleteElementFromDataSetFilter(this.variant.toVariantDto(), this.role, element);
  }

  public deleteRoleTarget(item: IDataSetFilteritemContent): void {
    this.tabContent.deleteDataSetFilter(item.filterId);
  }

  public getDescriptions(filter: IDataSetFilteritem): string {
    return filter.content.map((c) => c.description).join(", ");
  }
}
