import { DataFilterOption } from "./datafilteroption";
import { DataFilterOptionDto } from "../../../analysis/dto/DataFilterOption-dto";
import { Sorter } from "../../../../../../angular-common";

export class DataFilterOptionList {
  public get options(): DataFilterOption[] {
    return this._options;
  }

  public static createCopy(list: DataFilterOptionList): DataFilterOptionList {
    const newOptions: DataFilterOption[] = [];
    list.options.forEach((option) => {
      const newOption = new DataFilterOption(option.id, option.text, option.sortOrder, option.filterBy);
      newOptions.push(newOption);
    });

    const newList = new DataFilterOptionList(newOptions);

    return newList;
  }

  public constructor(options: DataFilterOption[]) {
    if (options) {
      options.forEach((element) => {
        this._options.push(element);
      });
      this.sort();
    }
  }

  private sort() {
    Sorter.sortSortOrderThenAlpha<DataFilterOption>(this._options, (x) => x.text);
  }

  public deactivateOptions() {
    this.options.forEach((o) => {
      o.filterBy = false;
    });
  }
  private _options: DataFilterOption[] = [];

  public getActiveOptions(): DataFilterOptionDto[] {
    const result: DataFilterOptionDto[] = [];
    this.options.forEach((o) => {
      if (o.filterBy) {
        result.push({
          Id: o.id,
          Name: o.text,
          SortOrder: o.sortOrder,
          FilterBy: o.filterBy,
        });
      }
    });
    return result;
  }
}
