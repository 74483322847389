<div div *ngIf="disablePageHeader === true">
  <div id="pageheaderSmall" class="page-header grid grid-template">
    <div class="grid-item"></div>
    <div class="grid-item align-right menu-right">
      <i id="unhideButton" class="dx-icon dx-icon-spindown" title="{{ language.UnHideToolbar }}" (click)="toggleTopbar()"></i>
    </div>
  </div>
</div>

<div *ngIf="disablePageHeader === false">
  <div class="page-header grid grid-template">
    <div class="grid-item">
      <div class="logo-container">
        <div>
          <a (click)="goHome()">
            <img class="logo" src="assets/images/logo.png" />
          </a>
        </div>
        <div class="sesssion-preferred-model-name">
          {{ preferredModelFromSession }}
        </div>
      </div>
    </div>
    <div class="grid-item align-right menu-right">
      <div id="popup">
        <ul id="utility-links" class="list-unstyled">
          <li *ngIf="hasMultipleMenuItems">
            <dx-button icon="fas fa-bars" [hint]="language.menuPageSelection" (onClick)="showPopup(PopupContextType.PageSelector)"> </dx-button>
          </li>
          <li *ngIf="hasUserInfo">
            <dx-button icon="user" [hint]="language.menuProfile" (onClick)="showPopup(PopupContextType.User)"> </dx-button>
          </li>
          <li>
            <dx-button class="hide-unhide-button" [icon]="toggleHideIcon()" [hint]="language.HideToolbar" (onClick)="toggleTopbar()"> </dx-button>
          </li>
        </ul>
        <dx-popup
          [width]="350"
          [height]="'auto'"
          [showTitle]="false"
          [title]="popupTitle"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [(visible)]="popupVisible"
          [position]="{ my: 'right top', at: 'right bottom', of: '#utility-links' }"
        >
          <div *dxTemplate="let data of 'content'" [ngSwitch]="popupContext" class="hide-scrollbar">
            <div *ngSwitchCase="PopupContextType.User">
              <div>
                <dx-text-box class="textBox" [width]="270" [disabled]="!editMode" [hoverStateEnabled]="editMode" [(value)]="currentUser.LongName">
                  <dx-validator>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
                <dx-button [disabled]="invalid" [icon]="buttonIcon" stylingMode="text" (onClick)="editButton()"> </dx-button>
              </div>
              <div class="logoutButton">
                <dx-button class="buttonClaims" [text]="language.menuClaims" (onClick)="showClaims()"></dx-button>
                <dx-button [text]="language.menuLogout" (onClick)="logOut()"></dx-button>
              </div>
              <p>
                <app-language-selection></app-language-selection>
              </p>
            </div>
            <div *ngSwitchCase="PopupContextType.PageSelector">
              <ul class="icon-list">
                <li *ngFor="let menuItem of menuItems" class="icon-list menuitem">
                  <a class="button-link" (click)="switchToPage(menuItem)">
                    <dx-button [icon]="menuItem.icon"></dx-button>
                    <div class="menuitemtext">{{ menuItem.displayTitle }}</div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </dx-popup>
      </div>
    </div>
  </div>
  <div class="pageIndicator">
    <div class="header-bar-content">
      <ng-content></ng-content>
    </div>
    <span class="header-bar-title">{{ currentMenuItem }}</span>
    <div class="headerLineDivider"></div>
  </div>
</div>
