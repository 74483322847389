import { Component, Input } from "@angular/core";

import { Palettes } from "../../../../../angular-common/components/palettes";
import { DashletTypeDto } from "../../analysis/dto/DashletType-dto";
import { DashletViewTypeDto } from "../../analysis/dto/DashletViewType-dto";
import { DashletView } from "../../services/dashletview";
import { DataFilterList } from "../../services/filter";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashlet",
  templateUrl: "./dashlet.component.html",
  styleUrls: ["./dashlet.component.scss"],
})
export class DashletComponent {
  public constructor(public palettes: Palettes, public language: ImagineLanguage) {}

  @Input() filterList: DataFilterList;

  @Input() dashletView: DashletView;

  // Make these enums available to the html template:
  public dashletViewType = DashletViewTypeDto;
  public dashletType = DashletTypeDto;

  public get isPieChartType() {
    return this.dashletView.dashletType === DashletTypeDto.Chart && this.dashletView.viewType === DashletViewTypeDto.PieChart;
  }

  public get isGridType() {
    return this.dashletView.dashletType === DashletTypeDto.Chart && this.dashletView.viewType === DashletViewTypeDto.Grid;
  }

  public get isBarChart() {
    if (this.isPieChartType || this.isGridType) {
      return false;
    } else {
      return this.dashletView.dashletType === DashletTypeDto.Chart;
    }
  }
}
