import { DashletDefinition, DashletDefinitionMetaData } from "../../../services/dashletdefinition";
import { Directive, Input } from "@angular/core";

import { DashletTypeDto } from "../../../analysis/dto/DashletType-dto";
import { DataSetTypeDto } from "../../../../../../common/models/dto/DataSetType-dto";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { RadioGroupItems } from "../../../../../../angular-common/components/radiogroup";
import { SelectableItems } from "../../../../../../angular-common";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class DashletDefinitionDesignPropertiesBase {
  constructor(public language: ImagineLanguage) {}

  public ensureAtLeastOneStatisticPresent() {
    if (this.dashletDefinition.changes.statistics.length == 0) {
      const firstStatistic = this.metaData?.statistics[0];
      if (firstStatistic !== undefined) {
        this.dashletDefinition.changes.statistics.push(firstStatistic);
      }
    }
  }

  @Input()
  public get dashletDefinition(): DashletDefinition {
    return this._dashletDefinition;
  }
  public set dashletDefinition(newDashletDefinition: DashletDefinition) {
    this._dashletDefinition = newDashletDefinition;
    this.initDashletTypeSelector();
    this.inputsChanged();
  }
  private _dashletDefinition: DashletDefinition;

  @Input()
  public get metaData(): DashletDefinitionMetaData {
    return this._metaData;
  }
  public set metaData(newMetaData: DashletDefinitionMetaData) {
    this._metaData = newMetaData;
    this.inputsChanged();
  }
  private _metaData: DashletDefinitionMetaData;

  public dashletTypeSelector: RadioGroupItems;
  public dataSetTypeSelector: SelectableItems;

  public inputsChanged() {}

  // Make these enums available to the html template:
  public dashletType = DashletTypeDto;
  public dataSetType = DataSetTypeDto;

  private initDashletTypeSelector() {
    if (!this.dashletDefinition || this.dashletDefinition === null) {
      return;
    }

    const newDashletTypeSelector = new RadioGroupItems();
    newDashletTypeSelector.addNew(DashletTypeDto.Statistic, this.language.DashletTypeStatistic, "fas fa-equals");
    newDashletTypeSelector.addNew(DashletTypeDto.Chart, this.language.DashletTypeChart, "fas fa-chart-bar");
    newDashletTypeSelector.addNew(DashletTypeDto.Detail, this.language.DashletTypeDetail, "fas fa-list");
    newDashletTypeSelector.selectedItem = newDashletTypeSelector.items.find((e) => e.id === this.dashletDefinition.changes.type);
    this.dashletTypeSelector = newDashletTypeSelector;
    this.dashletTypeSelector.selectedItemChanged.subscribe((x) => {
      if (x !== undefined) {
        this.dashletDefinition.changes.type = x.id;
      }
    });
  }

  public get warningMessage(): string {
    if (!this.dashletDefinition.changes.typeIsValid) {
      return this.language.dashletDefinitionDesignSelectType;
    }
    if (!this.dashletDefinition.changes.nameIsValid) {
      return this.language.dashletDefinitionDesignInputName;
    }
    if (!this.dashletDefinition.changes.variantIsValid) {
      return this.language.dashletDefinitionDesignSelectVariant;
    }
    if (!this.dashletDefinition.changes.dataSetTypeIsValid) {
      return this.language.dashletDefinitionDesignSelectDataSetType;
    }

    if (this.dashletDefinition.canSave) {
      return this.language.saveChanges;
    }

    return null;
  }
}
