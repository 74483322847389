import { Observable, of } from "rxjs";

import { map } from "rxjs/operators";
import { ISortOrder } from "../../../../../../angular-common";
import { ParameterElementWithMutationsDto } from "../../../../../../common/models/dto/ParameterElementWithMutationsDto-dto";
import { TimePeriodDto } from "../../../../../../common/models/dto/TimePeriodDto-dto";
import { UserPublicInformationDto } from "../../../../../../common/models/dto/UserPublicInformationDto-dto";
import { DashboardAuthorizationDto } from "../../../analysis/dto/DashboardAuthorizationDto-dto";
import { DashboardDefinitionDto } from "../../../analysis/dto/DashboardDefinitionDto-dto";
import { ParameterSimulationDto } from "../../../analysis/dto/ParameterSimulation-dto";
import { DashletDefinition } from "../../dashletdefinition";
import { DashletViewService } from "../../dashletview";
import { DashletViewList } from "../../dashletview/models/dashlet-view-list.model";
import { DashboardService } from "../dashboard.service";
import { Parameter } from "./parameter";

export class DashboardDefinition implements ISortOrder {
  public get isNew(): boolean {
    return this.id === undefined || this.id === null || this.id === -1;
  }

  public static addNew(dashboardService: DashboardService, dashletViewService: DashletViewService, name: string): DashboardDefinition {
    const newDefinition = new DashboardDefinition(dashboardService, dashletViewService);
    newDefinition.id = -1;
    newDefinition.userId = -1;
    newDefinition.name = name;
    newDefinition.sortOrder = 0;
    newDefinition.simulationVariantId = null;
    newDefinition.views = new DashletViewList(dashletViewService);
    newDefinition.parameters = [];
    newDefinition.simulationPeriods = [];
    newDefinition.authorization = new DashboardAuthorizationDto();
    newDefinition.authorization.Owner = new UserPublicInformationDto();

    return newDefinition;
  }

  public id: number;
  public name: string;
  public userId: number;
  public sortOrder: number;
  public views: DashletViewList;
  public parameters: Parameter[] = [];
  public simulationVariantId: number;
  public simulationPeriods: TimePeriodDto[] = [];
  public allowSimulation: boolean;
  public authorization: DashboardAuthorizationDto;

  public constructor(private dashboardService: DashboardService, private dashletViewService: DashletViewService) {
    this.allowSimulation = false;
  }

  public fromDto(dto: DashboardDefinitionDto) {
    this.id = dto.Id;
    this.name = dto.Name;
    this.userId = dto.UserId;
    this.sortOrder = dto.SortOrder;
    this.authorization = dto.Authorization;

    const newParameters: Parameter[] = [];
    if (dto.ParameterSimulation !== undefined && dto.ParameterSimulation !== null) {
      this.simulationVariantId = dto.ParameterSimulation.SimulationVariantId;
      this.simulationPeriods = dto.ParameterSimulation.Periods;

      dto.ParameterSimulation.Parameters.forEach((parameter) => {
        const newPara = new Parameter();
        newPara.fromDto(parameter);
        newParameters.push(newPara);
      });

      this.allowSimulation = this.simulationPeriods.length > 0;
    }

    const newViews = new DashletViewList(this.dashletViewService);
    newViews.copyFromDTO(dto.Views);

    this.views = newViews;

    this.parameters = newParameters;
  }

  public toDTO(): DashboardDefinitionDto {
    const newDto = new DashboardDefinitionDto();

    newDto.Id = this.id;
    newDto.Name = this.name;
    newDto.UserId = this.userId;
    newDto.SortOrder = this.sortOrder;
    newDto.Authorization = this.authorization;
    newDto.Views = this.views.toDTO();
    const newParameters: ParameterElementWithMutationsDto[] = [];
    this.parameters.forEach((p) => {
      newParameters.push(Parameter.toDto(p));
    });
    if (this.parameters.length > 0) {
      const sim = new ParameterSimulationDto();
      newDto.ParameterSimulation = sim;
      sim.Parameters = newParameters;
      sim.SimulationVariantId = this.simulationVariantId;
      sim.Periods = this.simulationPeriods;
    } else {
      newDto.ParameterSimulation = new ParameterSimulationDto();
    }

    return newDto;
  }

  public addDashlets(definitions: DashletDefinition[]) {
    definitions.forEach((def) => {
      this.dashletViewService.addNewDashletView(this.id, def.id).subscribe((dtoReturned) => {
        this.views.addView(dtoReturned);
      });
    });
  }

  public saveChanges(): Observable<void> {
    const dtoToSend = this.toDTO();
    if (this.isNew) {
      console.log("saving new dashboard");
      return this.dashboardService.saveNewDashboardDefinition(dtoToSend).pipe(
        map((ec) => {
          this.id = ec.id;
          this.userId = ec.userId;
          this.authorization = ec.authorization;
          return;
        }),
      );
    } else {
      return this.dashboardService.saveExistingDashboardDefinition(dtoToSend);
    }
  }

  public delete(): Observable<void> {
    let result: Observable<void>;
    if (!this.isNew) {
      result = this.dashboardService.deleteDashboardDefinition(this);
    } else {
      result = of(null);
    }
    return result;
  }
}
