import { JsDataTypeDto } from './../../../../../common/models/dto/JsDataType-dto';
import { PresentableElementDto } from './../../../../../common/models/dto/PresentableElement-dto';

// t4tsCode gen": "0.8.2.0 for file StatisticDTO.cs
// Don't adjust manually!

export class StatisticDto extends PresentableElementDto {
    public OperatorId: number;
    public DataType: JsDataTypeDto;
}

