<app-cardview *ngIf="!noData">
  <div id="pivotgrid">
    <div class="pivot-title">{{ dashletView.name }}</div>
    <dx-chart id="pivot-chart" *ngIf="dashletViewSettings.showPivotChart">
      [palette]="palettes.getPaletteByName(dashletView.paletteName).colors"
      <dxo-common-series-settings type="bar"></dxo-common-series-settings>
      <dxo-size [height]="200"></dxo-size>
      <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
    </dx-chart>
    <dx-check-box class="totalsCheckBox" [(value)]="showRowTotals" [text]="language.dashboardPivotShowRowTotals"> </dx-check-box>
    <dx-check-box class="totalsCheckBox" [(value)]="showColumnTotals" [text]="language.dashboardPivotShowColumnTotals"> </dx-check-box>
    <dx-check-box class="totalsCheckBox" [(value)]="showDataFieldHeaders" [text]="language.dashboardPivotShowDataFieldHeaders"> </dx-check-box>
    <dx-pivot-grid
      id="gridContainer"
      [allowFiltering]="false"
      [showBorders]="true"
      [allowSortingBySummary]="true"
      [showColumnGrandTotals]="showColumnTotals"
      [showRowGrandTotals]="showRowTotals"
      [dataFieldArea]="showDataFieldHeaders ? 'row' : 'column'"
      [showRowTotals]="false"
      [showColumnTotals]="false"
      [allowExpandAll]="true"
      [dataSource]="dataSource"
      (onContextMenuPreparing)="contextMenuPreparing($event)"
      (onCellClick)="onPivotCellClick($event)"
      (onExporting)="onExporting($event)"
    >
      <dxo-field-chooser [enabled]="userHasAnalysisDesignRole()" [height]="400"></dxo-field-chooser>
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-export [enabled]="true" [fileName]="dashletView.name"></dxo-export>
      <dxo-state-storing [enabled]="true" type="custom" [customLoad]="loadState" [customSave]="saveState"> </dxo-state-storing>
    </dx-pivot-grid>

    <dx-popup [(visible)]="pivotPopupVisible" [title]="pivotPopupTitle" [width]="750" [height]="550">
      <div *dxTemplate="let data of 'content'">
        <dx-data-grid id="drillDownDataGrid" [width]="700" [height]="450" [dataSource]="drillDownDataSource" [columns]="drillDownFields"> </dx-data-grid>
      </div>
    </dx-popup>
    <app-dashlet-warning [dashletView]="dashletView"></app-dashlet-warning>
  </div>
</app-cardview>
