import { ProcessingStateTypeDto } from "../../../../../../common/models/integrationservices/dto/ProcessingStateType-dto";
import { ProcessingStepStateDto } from "../../../../../../common/models/integrationservices/dto/ProcessingStepState-dto";

export class ProcessingStateStep {
  public get shortDateTimeText() {
    if (this.dateTime) {
      const dateText = this.dateTime.getDate() + "-" + (this.dateTime.getMonth() + 1) + "-" + this.dateTime.getFullYear();
      const timeText = ProcessingStateStep.withLeadingZero(this.dateTime.getHours()) + ":" + ProcessingStateStep.withLeadingZero(this.dateTime.getMinutes());
      const result = dateText + " at " + timeText;
      return result;
    }
    return "-";
  }

  private static withLeadingZero(nr: number): string {
    if (nr >= 0 && nr < 10) {
      return "0" + nr.toString();
    } else {
      return nr.toString();
    }
  }
  state: ProcessingStateTypeDto;
  dateTime: Date;
  description: string;
  constructor() {}

  public copyFrom(dto: ProcessingStepStateDto) {
    if (dto === undefined) {
      return;
    }

    this.dateTime = new Date(dto.DateTime);
    this.description = dto.Description;
    this.state = dto.State;
  }
}
