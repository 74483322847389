import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { AdaptGroupsEditService } from "../adapt-groups-edit-service";
import { AdaptLanguage } from "../../services/language/adaptlanguage.service";
import { ModelElement } from "../../../../../common/modelelements";
import { ModelGroupMove } from "./modelgroup-move";
import { ModelsGroup } from "../../adapt/models/modelgroups-group.model";

@Component({
  selector: "app-modelsgroups-edit",
  templateUrl: "./modelgroups-edit.component.html",
  styleUrls: ["./modelgroups-edit.component.scss"],
})
export class ModelsGroupsEditComponent implements OnChanges {
  selectAllModeValue: string = "page";
  selectionModeValue: string = "all";
  public searchValue = "longName";
  public displayValue = "longName";
  public availableElements: ModelElement[] = [];
  constructor(public language: AdaptLanguage, private modelGroupsEditService: AdaptGroupsEditService) {
    this.moveImplementation = new ModelGroupMove(new ModelsGroup());
  }

  ngOnChanges(changes: SimpleChanges) {
    this.availableElements = this.filterAvailableOptions();
    this.groupChanged();
  }

  public moveImplementation: ModelGroupMove;

  public listingValuesChanged() {
    this.groupChanged();
  }

  public listingOrderChanged(selectedItems: ModelElement[]) {
    if (selectedItems) {
      selectedItems.forEach((element, i) => {
        element.sortOrder = i;
      });

      this.moveImplementation.editingGroup.elements = selectedItems;
      this.groupChanged();
    }
  }

  private groupChanged() {
    this.hasChanges = this.computeHasChangesInGroup();
  }

  private filterAvailableOptions(): ModelElement[] {
    if (this.modelElements === undefined) {
      return [];
    }

    const allOptions = this.modelElements || [];
    const usedElements = this.editGroup.elements;
    const availableOptions: ModelElement[] = [];

    allOptions.forEach((option) => {
      const el = usedElements.find((element) => element.shortName === option.shortName);
      if (el === undefined) {
        availableOptions.push(option);
      }
    });
    return availableOptions;
  }

  public hasChanges: boolean;

  private computeHasChangesInGroup(): boolean {
    const edittingGroup = this.moveImplementation.editingGroup;
    if (!edittingGroup || !this._editGroupOriginal) {
      return false;
    }

    if (edittingGroup.elements.length !== this._editGroupOriginal.elements.length) {
      return true;
    } else {
      for (let i = 0; i < edittingGroup.elements.length; i++) {
        const currentElement = edittingGroup.elements[i];
        const originalElement = this._editGroupOriginal.elements[i];
        if (currentElement.shortName !== originalElement.shortName) {
          return true;
        }
      }
      return false;
    }
  }

  public _ModelElements: ModelElement[];
  private _editGroupOriginal: ModelsGroup;

  public get editGroup(): ModelsGroup {
    return this.moveImplementation.editingGroup;
  }

  @Input()
  public set editGroup(v: ModelsGroup) {
    this.moveImplementation.editingGroup = v;
    this._editGroupOriginal = v.createCopy();
  }

  @Input()
  public modelElements: ModelElement[];

  public saveModelGroups() {
    this.modelGroupsEditService.saveModelGroups(this.moveImplementation.editingGroup).subscribe((g) => {
      this.editGroup = g;
      this.groupChanged();
    });
  }
}
