import { Component, Input, OnInit } from "@angular/core";

import { confirm } from "devextreme/ui/dialog";
import { PeriodPickerInteraction } from "../../../../../../angular-common/components/periodpicker/periodpicker-interaction";
import { PlSliderSettings } from "../../../../../../angular-common/components/slider/plslidersettings";
import { TimePeriodDto } from "../../../../../../common/models/dto/TimePeriodDto-dto";
import { Parameter } from "../../../services/dashboard/models/parameter";
import { ParameterMutation } from "../../../services/dashboard/models/parametermutations";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { ParameterEditService } from "../../../services/parameteredit/parameteredit.service";

@Component({
  selector: "app-exact-parameter",
  templateUrl: "./exact-parameter.component.html",
  styleUrls: ["./exact-parameter.component.scss"],
})
export class ExactParameterComponent implements OnInit {
  public mutations: PlSliderSettings[] = [];
  public datePicker: PeriodPickerInteraction;
  constructor(private language: ImagineLanguage, private parameterService: ParameterEditService) {
    this.getTitle = this.getTitle.bind(this);
  }

  ngOnInit(): void {
    this.addSliders();
    this.datePicker = new PeriodPickerInteraction(this.parameter.longName, this.dates);
    this.datePicker.confirmPeriod.subscribe((x) => this.addNewMutation());
  }

  newMutationPopup() {
    this.datePicker.isVisible = true;
  }

  public removeMutation(slider: PlSliderSettings) {
    if (this.parameter.mutations.length <= 1) {
      return;
    }
    const mutation: ParameterMutation = this.findMutationWithId(slider.id);
    confirm(this.language.confirmDeleteTitle, this.language.confirmDeleteHeader).then((answer) => {
      if (answer === true) {
        this.parameterService.deleteParameterMutation(this.simulationVariantId, this.parameter, mutation).subscribe(() => {
          this.parameter.mutations.splice(
            this.parameter.mutations.findIndex((x) => x.keyStartDate === mutation.keyStartDate),
            1,
          );
        });
      }
    });
  }

  addNewMutation() {
    const date = this.datePicker.selectedPeriod;
    if (this.parameter.mutations.length === 0) {
      this.parameter.mutations.push(ParameterMutation.createDefault());
    }
    const copy = this.parameter.mutations[this.parameter.mutations.length - 1].copyWithNewDate(date);
    this.parameter.mutations.push(copy);
  }

  public getTitle(data: PlSliderSettings) {
    const title = this.findMutationWithId(data.id).startDateText;
    if (title !== null) {
      return title;
    } else {
      return "";
    }
  }

  findMutationWithId(id: number): ParameterMutation {
    return this.parameter.mutations.find((x) => x.keyStartDate === id);
  }

  onValueChanged(data: PlSliderSettings) {
    this.findMutationWithId(data.id).value = data.value;
  }

  get lowerBound() {
    if (this.parameter.hasBounds) {
      return this.parameter.lowerBound;
    } else {
      return 0;
    }
  }

  get upperBound() {
    if (this.parameter.hasBounds) {
      return this.parameter.upperbound;
    } else {
      return 100;
    }
  }

  addSliders() {
    this.parameter.mutations.forEach((mutation) => {
      this.mutations.push(this.newSlider(mutation));
    });
  }

  private newSlider(mutation: ParameterMutation): PlSliderSettings {
    return new PlSliderSettings(mutation.keyStartDate, this.lowerBound, this.upperBound, mutation.value, this.parameter.longName);
  }

  @Input() parameter: Parameter;
  @Input() dates: TimePeriodDto[];
  @Input() simulationVariantId: number;
}
