import { StackCalculationExactRuleDto } from './StackCalculationExactRuleDto-dto';
import { StackCalculationIntervalRuleDto } from './StackCalculationIntervalRuleDto-dto';

// t4tsCodeFromCs": "0.8.2.0 for file StackCalculationDto.cs
// Don't adjust manually!

export class StackCalculationDto {
    public OutputValue: number = 0;
    public ExactRule: StackCalculationExactRuleDto;
    public IntervalRule: StackCalculationIntervalRuleDto;
}

