export class ModelTreeIdGenerator {
  public constructor() {}

  public generateNewId(): number {
    const result = this._current;
    this._current += 1;
    return result;
  }
  private _current: number = 1; // Do not start with 0, DevExpress treelist component does not understand that...
}
