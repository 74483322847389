import { DashboardAuthorizationDto } from './DashboardAuthorizationDto-dto';
import { DashletViewDto } from './DashletViewDto-dto';
import { ParameterSimulationDto } from './ParameterSimulation-dto';

// t4tsCode gen": "0.8.2.0 for file DashboardDefinitionDto.cs
// Don't adjust manually!

export class DashboardDefinitionDto {
    public Id: number;
    public Name: string;
    public SortOrder: number;
    public UserId: number;
    public Views: DashletViewDto [] = [];
    public ParameterSimulation: ParameterSimulationDto;
    public Authorization: DashboardAuthorizationDto;
}

