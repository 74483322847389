import { AnalysisCluster } from "./analysis-cluster";
import { IMove } from "../../../../../angular-common/components/listselector/imove";

export class AnalysisClusterMove implements IMove {
  constructor(public clusters: AnalysisCluster[]) {}

  moveLeft(selectedItems: AnalysisCluster[]): boolean {
    if (selectedItems && selectedItems.length > 0) {
      this.clusters = this.clusters.filter((s) => {
        const usedSelect = selectedItems.filter((element) => element.shortName === s.shortName);
        return usedSelect.length === 0;
      });
      return true;
    }
  }

  moveRight(selectedItems: AnalysisCluster[]): boolean {
    if (selectedItems) {
      selectedItems.forEach((s) => {
        this.clusters.push(s);
      });
    }
    return true;
  }
}
