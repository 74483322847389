import { RouterModule, Routes } from "@angular/router";
import { DxButtonModule, DxDateBoxModule, DxFileManagerModule, DxFormModule, DxPopupModule, DxSelectBoxModule, DxTabPanelModule, DxTextBoxModule, DxValidatorModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { canActivateRoleGuard } from "../../../../angular-common/authentication-guard/role-guard";
import { ToasterModule } from "../../../../angular-common/components/toaster/toaster-module";
import { IntegrationServiceLanguage } from "../../../../common/language/integrationservicelanguage.service";
import { EventTimerRoutesDto } from "../../../../common/models/integrationservices/dto/EventTimerRoutes-dto";
import { ConfigurationWizardModule } from "./../../../../angular-common/components/configwizard/configuration-wizard.module";
import { EventTimerDetailsComponent } from "./details/event-timer-details.component";
import { EventTimerScreenComponent } from "./event-timer-screen.component";

const routes: Routes = [
  {
    path: EventTimerRoutesDto.EventTimerScreen,
    component: EventTimerScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
];

@NgModule({
  imports: [
    ConfigurationWizardModule,
    CommonModule,
    RouterModule.forChild(routes),
    DxTabPanelModule,
    DxSelectBoxModule,
    DxFormModule,
    DxTextBoxModule,
    DxButtonModule,
    DxDateBoxModule,
    DxPopupModule,
    DxFileManagerModule,
    DxValidatorModule,
    ToasterModule,
  ],
  exports: [],
  declarations: [EventTimerScreenComponent, EventTimerDetailsComponent],
  providers: [EventTimerScreenComponent, IntegrationServiceLanguage],
})
export class EventTimerModule {}
