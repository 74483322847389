<app-dashlet-data-info [dashletView]="dashletView" [filterList]="filterList"></app-dashlet-data-info>

<div *ngIf="dashletView && dashletView.loaded && dashletView.data" class="dashlet">
  <!-- bar chart -->
  <div *ngIf="isBarChart">
    <app-dashlet-chart [dashletWithData]="dashletView.dashletData" [dashletView]="dashletView" [filterList]="filterList"> </app-dashlet-chart>
  </div>

  <!-- pie chart -->
  <div *ngIf="isPieChartType">
    <app-dashlet-pie [dashletWithData]="dashletView.dashletData" [dashletView]="dashletView" [filterList]="filterList"> </app-dashlet-pie>
  </div>

  <!-- data grid -->
  <div *ngIf="isGridType">
    <app-dashlet-grid [dashletWithData]="dashletView.dashletData" [dashletView]="dashletView" [filterList]="filterList"> </app-dashlet-grid>
  </div>

  <!-- statistic -->
  <div *ngIf="dashletView.dashletType === dashletType.Statistic">
    <app-dashlet-statistic [dashletView]="dashletView" [filterList]="filterList"> </app-dashlet-statistic>
  </div>

  <!-- details -->
  <!-- details = 5 -->
  <div *ngIf="dashletView.dashletType === dashletType.Detail && dashletView.viewType === dashletViewType.Grid">
    <app-dashlet-details [dashletWithData]="dashletView.dashletData" [dashletView]="dashletView" [filterList]="filterList"></app-dashlet-details>
  </div>

  <div *ngIf="dashletView.dashletType === dashletType.Detail && dashletView.viewType === dashletViewType.Pivot">
    <app-dashlet-pivot [dashletWithData]="dashletView.dashletData" [dashletView]="dashletView" [filterList]="filterList"></app-dashlet-pivot>
  </div>
</div>
