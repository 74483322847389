import { Component, Input, OnInit } from "@angular/core";
import { RadioGroupItems } from "./radiogroup-items";

@Component({
  selector: "app-radiogroup",
  templateUrl: "./radiogroup.component.html",
  styleUrls: ["./radiogroup.component.scss"],
})
export class RadioGroupComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  @Input()
  public items: RadioGroupItems;
}
