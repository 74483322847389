import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataObjectDto } from "../../../../../common/models/dto/DataObject-dto";
import { VariantInformationDto } from "../../../../../common/models/dto/VariantInformation-dto";
import { DataMutationsMutationData } from "../../adapt/data-mutations/models/data-mutations-mutation-data.model";
import { MutationDataRow } from "../../adapt/data-mutations/models/mutation-data-row.model";
import { DataMutationsMutationDataDto } from "../../adapt/dto/DataMutationsMutationData-dto";

@Injectable()
export class DataMutationsService extends PlBaseService {
  private computeEndpoint: string = "VariantCompute";

  private endPoint: string = "datamutations";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getMutations(identification: any): Observable<DataMutationsMutationData> {
    const result = this.getDataTransformed<DataMutationsMutationDataDto>(this.endPoint + "/" + identification).pipe(map((r) => this.extractMutations(r)));
    return result;
  }

  private extractMutations(dto: DataMutationsMutationDataDto): DataMutationsMutationData {
    if (dto) {
      const result = new DataMutationsMutationData();
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  private extractMutation(entry: EntryCreated<DataObjectDto>): MutationDataRow {
    if (entry) {
      const json: DataObjectDto = entry.data;
      const result = new MutationDataRow();
      result.copyFromDto(json);
      return result;
    } else {
      return null;
    }
  }

  public saveNewMutation(dto: DataObjectDto): Observable<MutationDataRow> {
    const thisService = this;
    return this.createDataTransformed<DataObjectDto>(this.endPoint, dto).pipe(map((r) => thisService.extractMutation(r)));
  }

  public saveExistingMutation(mutation: MutationDataRow): Observable<any> {
    const url = this.endPoint + "/" + mutation.identification;
    return this.update(mutation.toDto(), url);
  }

  public deleteMutation(mutation: MutationDataRow): Observable<any> {
    const url = this.endPoint + "/" + mutation.identification + "?mutationId=" + mutation.mutationid;
    return this.remove(url);
  }

  public retrieveVariantState(): Observable<VariantInformationDto[]> {
    return this.getData(this.computeEndpoint + "/");
  }
}
