import { ColumnItemDto } from "../../../../../../common/models/dto/ColumnItem-dto";
import { ColumnItem } from "./column-item.model";

export class ColumnItems {
  public constructor() {}

  public get all(): ColumnItem[] {
    return this._all;
  }
  private _all: ColumnItem[] = [];

  public copyFromDto(dto: ColumnItemDto[]) {
    const newItems: ColumnItem[] = [];

    dto.forEach(cd => {
      const newColumn = new ColumnItem();
      newColumn.copyFromDto(cd);
      newItems.push(newColumn);
    });

    this._all = newItems;
  }
}
