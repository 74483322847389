<div>
  <dx-data-grid [dataSource]="allConfigurations" (onRowUpdated)="onUpdate($event)" (onEditingStart)="onEditingStart($event)" (onRowInserted)="onInserted($event)" [columnAutoWidth]="true">
    <dxo-editing [allowUpdating]="true" [allowAdding]="true" useIcons="true"></dxo-editing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column caption="{{ language.sdWorxProvisioningConfigPayGroup }}" dataField="SA" [sortIndex]="0" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column caption="{{ language.sdWorxProvisioningConfigClientId }}" dataField="ClientId">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column caption="{{ language.sdWorxProvisioningConfigClientSecret }}" dataField="ClientSecret" [allowFiltering]="false" cellTemplate="clientSecretCellTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <div *dxTemplate="let clientSecretCell of 'clientSecretCellTemplate'">
      <dx-text-box disabled="true" mode="password" [value]="clientSecretCell.value"></dx-text-box>
    </div>

    <dxi-column type="butons" cellTemplate="buttonTemplate"></dxi-column>

    <div *dxTemplate="let d of 'buttonTemplate'">
      <dx-button id="deleteButton" icon="fas fa-trash" (onClick)="onDelete(d.data)"></dx-button>
    </div>
  </dx-data-grid>
</div>
