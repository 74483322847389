import { Component, Input, OnInit } from "@angular/core";

import { DataSupplyTabContent } from "../data-supply-tabs/data-supply-tab-content";
import { ImportLanguage } from "../../services/language/connectorlanguage.service";
import { ImportStateService } from "../../services/import/import-state.service";
import { ProcessingState } from "../../services/import/models/processing-state.model";

@Component({
  selector: "app-data-supplies-status",
  templateUrl: "./data-supplies-status.html",
  styleUrls: ["./data-supplies-status.scss"],
})
export class DataSuppliesStatusComponent implements OnInit {
  public states: ProcessingState[];

  constructor(private stateService: ImportStateService, public language: ImportLanguage) {}

  @Input() tabContent: DataSupplyTabContent;

  ngOnInit() {
    this.stateService.getStates().subscribe((s) => {
      const sortedStates = s.sort((a, b) => b.queuedAtDate.getTime() - a.queuedAtDate.getTime());
      this.states = sortedStates;
    });
  }
}
