import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { PlUserStorage } from "../../../../../../angular-common/baseservice/pluserstorage";
import { Palettes } from "../../../../../../angular-common/components/palettes/palettes";
import { SelectBoxItems } from "../../../../../../angular-common/components/selectbox/selectbox-items";
import { AnalysisRoleNamesDto } from "../../../analysis/dto/AnalysisRoleNames-dto";
import { DashletTypeDto } from "../../../analysis/dto/DashletType-dto";
import { DashletViewTypeDto } from "../../../analysis/dto/DashletViewType-dto";
import { DashboardDesignListBuilder } from "../../../dashboardingdesign-screen/dashboarddesign/dashboard-design-list-builder";
import { DashletDataService } from "../../../services/dashletdata/dashlet-data.service";
import { DashletView } from "../../../services/dashletview/models/dashlet-view.model";
import { DashletViewSettings } from "../../../services/dashletviewsettings/models/dashlet-view-settings";
import { FilterListService } from "../../../services/filter/filter-list-service";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashlet-edit-view",
  styleUrls: ["./dashlet-edit.component.scss"],
  templateUrl: "./dashlet-edit.component.html",
})
export class DashletEditComponent implements OnInit, OnDestroy {
  private _dashletViewCopy: DashletView;

  private _originalDashletView: DashletView;

  @Input()
  public get dashletView(): DashletView {
    return this._dashletViewCopy;
  }
  public set dashletView(value: DashletView) {
    this.onDashletLoaded(value);
  }

  private _visible: boolean;

  @Input() get visible(): boolean {
    return this._visible;
  }

  public set visible(value: boolean) {
    this._visible = value;
  }

  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private filterSubscribtion: Subscription;

  public get filterList() {
    return this.filterListService.filterList;
  }

  public constructor(private dashletDataService: DashletDataService, private filterListService: FilterListService, public language: ImagineLanguage, public palettes: Palettes) {
    this.chartOptions = new DashletViewSettings();
  }

  public chartOptions: DashletViewSettings;

  public ngOnInit(): void {
    this.filterSubscribtion = this.filterListService.filterList.onApplyFilter.subscribe(() => {
      if (this.dashletView) {
        this.dashletView.reloadViewData();
      }
    });
  }

  private onDashletLoaded(newDashletView: DashletView) {
    this._originalDashletView = newDashletView;
    this._dashletViewCopy = newDashletView.getCopy();
    this._dashletViewCopy.setRequestDataLoadCallBack(() => this._dashletViewCopy.loadData(this.dashletDataService, this.filterList.getActiveFilterNoLoading()).subscribe());
    this.dashletView.reloadViewData();
    this.initTypeSelector();
  }

  public save(): void {
    this._dashletViewCopy.copyTo(this._originalDashletView.changes);
    this._originalDashletView.saveChanges();
    this.dashletViewSaved.emit(this._originalDashletView);
  }

  public get canSave(): boolean {
    return this._dashletViewCopy.isSameAs(this._originalDashletView) === false;
  }

  @Output()
  public dashletViewSaved = new EventEmitter<DashletView>();

  public close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  public ngOnDestroy(): void {
    this.filterSubscribtion.unsubscribe();
  }

  public get showTypeSelector(): boolean {
    if (this.dashletView) {
      return this.dashletView.dashletType === DashletTypeDto.Chart || this.dashletView.dashletType === DashletTypeDto.Detail;
    } else {
      return false;
    }
  }

  public get showColorSelector(): boolean {
    if (this.dashletView) {
      return this.dashletView.dashletType === DashletTypeDto.Chart;
    } else {
      return false;
    }
  }

  public get showPivotChartSelector(): boolean {
    if (this.dashletView) {
      return this.dashletView.viewType === DashletViewTypeDto.Pivot;
    } else {
      return false;
    }
  }

  private initTypeSelector() {
    if (this.showTypeSelector === false) {
      return;
    }

    const newTypeSelector = DashboardDesignListBuilder.viewtypelistSelectBox(this.language, this.dashletView.dashletType);

    newTypeSelector.selectedItem = newTypeSelector.items.find((e) => e.id === this.dashletView.viewType);
    this.typeSelector = newTypeSelector;
    this.typeSelector.selectedItemChanged.subscribe((item) => {
      if (item !== undefined && item !== null) {
        this.dashletView.viewType = item.id;
      }
    });
  }

  public get userWithDataAnalysisDesignRole(): boolean {
    return PlUserStorage.hasRole(AnalysisRoleNamesDto.DataAnalysisDesign);
  }

  public typeSelector: SelectBoxItems;
}
