import { loadMessages, locale } from "devextreme/localization";

export class DevExpressLanguageLoader {
  public static loadDevExpressLanguage(applicationLanguage: string) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const germanMessages = require("./dx_de_language.json");
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const dutchMessages = require("./dx_nl_language.json");
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const frenchMessages = require("./dx_fr_language.json");
    const applicationLanguageLower = applicationLanguage.toLowerCase();

    if (germanMessages[applicationLanguageLower]) {
      loadMessages(germanMessages);
      locale(applicationLanguageLower);
    }
    if (dutchMessages[applicationLanguageLower]) {
      loadMessages(dutchMessages);
      locale(applicationLanguage.toLowerCase());
    }
    if (frenchMessages[applicationLanguageLower]) {
      loadMessages(frenchMessages);
      locale(applicationLanguage.toLowerCase());
    }
  }
}
