import { Observable, of } from "rxjs";

import { ElementHelper } from "../../../../../../../angular-common/helpers/element.helper";
import { ISortOrder } from "../../../../../../../angular-common/sorting/sortorder";
import { ModelPresentableElement } from "../../../../../../../common/model/models/model-presentablel-element-model";
import { DataSetTypeDto } from "../../../../../../../common/models/dto/DataSetType-dto";
import { ElementTypeDto } from "../../../../../../../common/models/dto/ElementType-dto";
import { DashletAnalysisSourceDto } from "../../../../analysis/dto/DashletAnalysisSource-dto";
import { DashletDefinitionDto } from "../../../../analysis/dto/DashletDefinitionDTO-dto";
import { DashletTypeDto } from "../../../../analysis/dto/DashletType-dto";
import { DashletDefinitionService } from "../../dashlet-definition-service";
import { Statistic } from "../metadata/statistic.model";

export class DashletDefinition implements ISortOrder {
  public constructor(private dashletDefinitionService: DashletDefinitionService, public id: number, public name: string, public type: DashletTypeDto) {
    this._statistics = [];
  }

  public sortOrder: number = 0;
  private _variantId: number;
  public get sourceId(): number {
    return this._variantId;
  }
  public set sourceId(value: number) {
    this._variantId = value;
  }
  public sourceType = ElementTypeDto.Variant;
  public dataSetType: DataSetTypeDto;

  public sourceIdCompare: number;
  public sourceCompareType = ElementTypeDto.Variant;
  public cluster: string;
  public availableTo: number;

  private _statistics: Statistic[];
  public get statistics(): Statistic[] {
    return this._statistics;
  }
  public set statistics(value: Statistic[]) {
    if (value) {
      this._statistics = value;
    }
  }

  public get statistic(): Statistic {
    if (this.statistics && this.statistics.length > 0) {
      return this.statistics[0];
    }
    return null;
  }
  public set statistic(value: Statistic) {
    if (value) {
      this.statistics.splice(
        this.statistics.findIndex((x) => x.shortName === value.shortName),
        1,
      );
      this.statistics.push(value);
    }
  }

  public deleteStatistic(stat: Statistic) {
    if (stat === null || stat === undefined) {
      return;
    }
    this.changes.statistics.splice(
      this.changes.statistics.findIndex((x) => x.shortName === stat.shortName),
      1,
    );
  }

  public copyFromDTO(dto: DashletDefinitionDto) {
    this.name = dto.Name;
    this.type = dto.Type;
    this.id = dto.Id;
    this.sortOrder = dto.SortOrder;
    if (dto.Source) {
      this.sourceId = dto.Source.KeyId;
      this.sourceType = dto.Source.TypeOfElement;
    }
    if (dto.ComparisonSource) {
      this.sourceIdCompare = dto.ComparisonSource.KeyId;
      this.sourceCompareType = dto.ComparisonSource.TypeOfElement;
    }
    this.dataSetType = dto.DataSetType;
    this.statistics = dto.Statistics.map(Statistic.createFromDto);
    this.cluster = dto.Cluster;
    this.availableTo = dto.AvailableTo;
  }

  public toDTO(): DashletDefinitionDto {
    const dto = new DashletDefinitionDto();
    dto.Name = this.name;
    dto.Type = this.type;
    dto.Id = this.id;
    dto.SortOrder = this.sortOrder;

    dto.Source = new DashletAnalysisSourceDto();
    dto.Source.KeyId = this.sourceId;
    dto.Source.TypeOfElement = this.sourceType;

    dto.ComparisonSource = new DashletAnalysisSourceDto();
    dto.ComparisonSource.KeyId = this.sourceIdCompare;
    dto.ComparisonSource.TypeOfElement = this.sourceCompareType;

    dto.DataSetType = this.dataSetType;
    dto.Statistics = this.statistics.map((x) => x.toDto());
    dto.Cluster = this.cluster;
    dto.AvailableTo = this.availableTo;

    return dto;
  }

  public getCopy(): DashletDefinition {
    const result = new DashletDefinition(this.dashletDefinitionService, this.id, this.name, this.type);
    this.copyTo(result);
    return result;
  }

  public getCopyAsNew(): DashletDefinition {
    const result = this.getCopy();
    result.id = null;
    return result;
  }

  private copyTo(target: DashletDefinition) {
    target.name = this.name;
    target.type = this.type;
    target.sortOrder = this.sortOrder;
    target.sourceId = this.sourceId;
    target.sourceType = this.sourceType;
    target.sourceIdCompare = this.sourceIdCompare;
    target.sourceCompareType = this.sourceCompareType;
    target.dataSetType = this.dataSetType;
    target.statistics = this.statistics.map((s) => s.getCopy());
    target.cluster = this.cluster;
    target.availableTo = this.availableTo;
  }

  public get changes(): DashletDefinition {
    if (!this._changes || this._changes === null) {
      this._changes = this.getCopy();
    }
    return this._changes;
  }
  private _changes: DashletDefinition;

  public saveChanges() {
    if (this.validationDto(this.changes)) {
      this.changes.copyTo(this);
      const dtoToSend = this.toDTO();
      if (this.isNew) {
        this.dashletDefinitionService.saveNewDashletDefinition(dtoToSend).subscribe((ec) => {
          this.id = ec.id;
          this.resetChanges();
          this.executeAfterSave();
        });
      } else {
        this.dashletDefinitionService.saveExistingDashletDefinition(dtoToSend).subscribe((x) => {
          this.resetChanges();
          this.executeAfterSave();
        });
      }
    } else {
      this.undoChanges();
    }
  }

  validationDto(dtoToSend: DashletDefinition): boolean {
    let result = true;
    if (dtoToSend.changes.statistics.length > 0) {
      dtoToSend.changes.statistics.forEach((s) => {
        if (ElementHelper.isNullOrUndefined(s.shortName)) {
          result = false;
        }
      });
    }
    return result;
  }

  public sourceElementChanged(value: ModelPresentableElement) {
    if (value) {
      this.sourceId = value.id;
      this.sourceType = value.elementType;
    } else {
      this.sourceId = undefined;
      this.sourceType = ElementTypeDto.Unknown;
    }
  }

  public compareElementChanged(value: ModelPresentableElement) {
    if (value) {
      this.sourceIdCompare = value.id;
      this.sourceCompareType = value.elementType;
    } else {
      this.sourceIdCompare = undefined;
      this.sourceCompareType = ElementTypeDto.Unknown;
    }
  }

  private resetChanges() {
    this._changes = null;
  }

  public undoChanges() {
    this.resetChanges();
  }

  public get isNew(): boolean {
    return this.id === undefined || this.id === null;
  }

  public get canSave(): boolean {
    return (this.isNew || this.hasChanges) && this.changes.isValid;
  }

  public get hasChanges(): boolean {
    return (
      this.name !== this.changes.name ||
      this.type !== this.changes.type ||
      this.sortOrder !== this.changes.sortOrder ||
      this.sourceId !== this.changes.sourceId ||
      this.sourceType !== this.changes.sourceType ||
      this.dataSetType !== this.changes.dataSetType ||
      this.sourceIdCompare !== this.changes.sourceIdCompare ||
      this.sourceCompareType !== this.changes.sourceCompareType ||
      this.statisticsAreTheSame() === false ||
      this.availableTo !== this.changes.availableTo ||
      this.cluster !== this.changes.cluster
    );
  }

  private statisticsAreTheSame(): boolean {
    if (this.statistics.length !== this.changes.statistics.length) {
      return false;
    }
    for (let i = 0; i < this.statistics.length; i++) {
      const leftShortName = this.statistics[i].shortName;
      const rightShortName = this.changes.statistics[i].shortName;
      const leftOperatorId = this.statistics[i].operatorId;
      const rightOperatorId = this.changes.statistics[i].operatorId;
      const leftSortOrder = this.statistics[i].sortOrder;
      const rightSortOrder = this.changes.statistics[i].sortOrder;
      if (leftShortName !== rightShortName || leftOperatorId !== rightOperatorId || leftSortOrder !== rightSortOrder) {
        return false;
      }
    }
    return true;
  }

  public delete(): Observable<void> {
    let result: Observable<void>;
    if (!this.isNew) {
      result = this.dashletDefinitionService.deleteDashletDefinition(this);
    } else {
      result = of(null);
    }
    return result;
  }

  public get typeIsValid(): boolean {
    return this.type !== undefined && this.type !== null;
  }
  public get nameIsValid(): boolean {
    return this.name !== undefined && this.name !== null && this.name.length > 0;
  }
  public get variantIsValid(): boolean {
    return this.sourceId !== undefined && this.sourceId !== null;
  }
  public get dataSetTypeIsValid(): boolean {
    return this.dataSetType !== undefined && this.dataSetType !== null;
  }
  public get statisticIsValid(): boolean {
    return this.statistics !== null && this.statistics !== undefined;
  }

  public get isValid(): boolean {
    return this.typeIsValid && this.nameIsValid && this.variantIsValid && this.dataSetTypeIsValid && this.statisticIsValid;
  }

  // Usually we use a rxjs Subscription to implement events however then we have to remember the subscription and unsubscribe
  // when removing a dashlet. In this case we choose this simpler method without the extra overhead.
  public setAfterSave(newAfterSave: () => void) {
    this.afterSave = newAfterSave;
  }
  private afterSave: () => void;
  private executeAfterSave() {
    if (this.afterSave && this.afterSave !== null) {
      this.afterSave();
    }
  }
}
