<dx-button class="refresh-btn" *ngIf="!show && showReload" icon="refresh" (onClick)="reload()"> </dx-button>

<app-cardview *ngIf="show" [title]="title">
  <dx-button class="refresh-btn" *ngIf="showReload" icon="refresh" (onClick)="reload()"> </dx-button>
  <div class="nodata-text">
    <div *ngIf="noData" class="dx-datagrid-nodata relative">
      {{ language.noData }}
    </div>
    <div *ngIf="hasDataError" class="dx-datagrid-nodata relative">
      {{ dataErrorText }}
    </div>
  </div>
  <app-dashlet-warning [dashletView]="dashletView"></app-dashlet-warning>
</app-cardview>
