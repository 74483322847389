import { RequiredRuleDto } from "../../../../../../common/models/dto/RequiredRule-dto";
import { ValidationRule } from "./validation-rule.model";

export class ValidationRuleRequired extends ValidationRule {
  public constructor() {
    super();
  }

  public copyFromDto(dto: RequiredRuleDto) {
    super.copyFromDto(dto);
  }
}
