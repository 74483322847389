import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-text-area-with-new-line-handler",
  templateUrl: "./text-area-with-new-line-handler.component.html",
  styleUrls: ["./text-area-with-new-line-handler.component.scss"],
})
export class TextAreaWithNewLineHandlerComponent {
  @Input() cssClass: string;
  @Input() valueChangeEvent: string;
  @Input() value: string;
  @Output() onValueChangedEvent = new EventEmitter<any>();

  emitOnValueChanged(event: any) {
    if (this.value.includes("\r\n") === false) {
      this.onValueChangedEvent.emit(this.value.replace(/\n/g, "\r\n"));
    }
  }
}
