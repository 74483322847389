import { Observable, of } from "rxjs";
import { DashletDataPoint, DashletDataService, DashletWithData } from "../../dashletdata";

import { map } from "rxjs/operators";
import { ISortOrder } from "../../../../../../angular-common";
import { Palettes } from "../../../../../../angular-common/components/palettes";
import { DashletTypeDto } from "../../../analysis/dto/DashletType-dto";
import { DashletViewDto } from "../../../analysis/dto/DashletViewDto-dto";
import { DashletViewTypeDto } from "../../../analysis/dto/DashletViewType-dto";
import { DataFilterDto } from "../../../analysis/dto/DataFilter-dto";
import { ClusterField } from "../../dashletdata/models/cluster-field";
import { DashletViewSettings } from "../../dashletviewsettings/models/dashlet-view-settings";
import { DashletViewService } from "../dashlet-view.service";

export class DashletView implements ISortOrder {
  public constructor(
    private dashletViewService: DashletViewService,
    public dashletViewId: number,
    public viewType: DashletViewTypeDto,
    public dashletType: DashletTypeDto,
    public sortOrder: number,
    public name: string,
    public paletteName: string,
    public dashletViewSettings: DashletViewSettings,
  ) {
    if (this.paletteName === undefined || this.paletteName === null || this.paletteName === "") {
      this.paletteName = Palettes.defaultPaletteName;
    }
  }

  public static createFromDto(json: DashletViewDto, service: DashletViewService) {
    const settings = new DashletViewSettings();
    settings.fromDto(json.ViewSettings);
    const result = new DashletView(service, json.DashletViewId, json.ViewType, json.DashletType, json.SortOrder, json.Name, json.PaletteName, settings);
    return result;
  }

  public toDTO(): DashletViewDto {
    this.dashletViewSettings._dashletViewId = this.dashletViewId;
    return {
      DashletViewId: this.dashletViewId,
      ViewType: this.viewType,
      SortOrder: this.sortOrder,
      Name: this.name,
      DashletType: this.dashletType,
      PaletteName: this.paletteName,
      ViewSettings: this.dashletViewSettings.toDto(),
    };
  }

  public reloadViewData() {
    if (this.requestReload) {
      this.requestReload();
    } else {
      console.group("Could not reload the view; we have never loaded yet.");
    }
  }

  private requestReload: () => void;

  public setRequestDataLoadCallBack(requestReload: () => void) {
    this.requestReload = requestReload;
  }

  // Loads data from the server, this is an explicit call:
  // - We don't want to inject the dashletDataService automatically in this class.
  // - We only want to load the data when we need it.
  public loadData(dashletDataService: DashletDataService, filter: DataFilterDto[]): Observable<any> {
    const result = dashletDataService.getDashletWithData(this.dashletViewId, filter).pipe(
      map((dwd) => {
        this.dashletWithData = dwd;
        this.loaded = true;
        return of(null);
      }),
    );

    return result;
  }

  private dashletWithData: DashletWithData;
  public loaded: boolean = false;

  public get data(): DashletDataPoint[] {
    if (this.dashletWithData) {
      return this.dashletWithData.data;
    }

    return undefined;
  }

  public get dashletData(): DashletWithData {
    return this.dashletWithData;
  }

  public get clusters(): ClusterField[] {
    if (this.dashletWithData) {
      return this.dashletWithData.clusteredBy;
    }

    return undefined;
  }

  public get isNew(): boolean {
    return this.dashletViewId === undefined || this.dashletViewId === null;
  }

  public delete(): Observable<void> {
    let result: Observable<void>;
    if (!this.isNew) {
      result = this.dashletViewService.deleteDashletView(this);
    } else {
      result = of(null);
    }
    return result;
  }

  public getCopy(): DashletView {
    const result = new DashletView(this.dashletViewService, this.dashletViewId, this.viewType, this.dashletType, this.sortOrder, this.name, this.paletteName, this.dashletViewSettings.getCopy());
    return result;
  }

  public copyTo(target: DashletView) {
    target.name = this.name;
    target.sortOrder = this.sortOrder;
    target.dashletViewId = this.dashletViewId;
    target.dashletType = this.dashletType;
    target.viewType = this.viewType;
    target.paletteName = this.paletteName;
    target.dashletViewSettings = this.dashletViewSettings;
  }

  public get changes(): DashletView {
    if (!this._changes || this._changes === null) {
      this._changes = this.getCopy();
    }
    return this._changes;
  }
  private _changes: DashletView;

  public saveChanges() {
    this.changes.copyTo(this);
    const dtoToSend = this.toDTO();

    // We don't check for 'isnew' here. New dashletviews are saved immediately so a call to 'savechanges' is only made
    // for existing views
    this.dashletViewService.saveExistingDashletView(dtoToSend).subscribe(() => {
      this.resetChanges();
    });
  }

  private resetChanges() {
    this._changes = null;
  }

  public undoChanges() {
    this.resetChanges();
  }

  public get canSave(): boolean {
    return (this.isNew || this.hasChanges) && this.changes.isValid;
  }

  public get hasChanges(): boolean {
    return (
      this.name !== this.changes.name ||
      this.sortOrder !== this.changes.sortOrder ||
      this.viewType !== this.changes.viewType ||
      this.dashletType !== this.changes.dashletType ||
      this.paletteName !== this.changes.paletteName ||
      this.dashletViewId !== this.changes.dashletViewId ||
      this.dashletViewSettings.showPivotChart !== this.changes.dashletViewSettings.showPivotChart
    );
  }

  public isSameAs(target: DashletView): boolean {
    return (
      this.name === target.name &&
      this.sortOrder === target.sortOrder &&
      this.viewType === target.viewType &&
      this.dashletType === target.dashletType &&
      this.paletteName === target.paletteName &&
      this.dashletViewId === target.dashletViewId &&
      this.dashletViewSettings.showPivotChart === target.dashletViewSettings.showPivotChart
    );
  }

  public get nameIsValid(): boolean {
    return this.name !== null && this.name.length > 0;
  }

  public get isValid(): boolean {
    return this.nameIsValid;
  }
}
