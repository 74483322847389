import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { DashletViewSettings } from "./models/dashlet-view-settings";
import { DashletViewSettingsDto } from "../../analysis/dto/DashletViewSettingsDto-dto";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class DashletViewSettingsService extends PlBaseService {
  private endPoint: string = "dashletviewsettings";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveSettingsWithId(id: number): Observable<DashletViewSettings> {
    const url = this.endPoint + "/" + id;
    return this.getDataTransformed<DashletViewSettingsDto>(url).pipe(map(r => this.translateSettings(r)));
  }

  private translateSettings(dto: DashletViewSettingsDto): DashletViewSettings {
    if (dto) {
      const settings = new DashletViewSettings();
      settings.fromDto(dto);
      return settings;
    } else {
      return null;
    }
  }

  public saveSettingsFromView(viewSettings: DashletViewSettings): Observable<DashletViewSettings> {
    return this.updateTransformedResponse<DashletViewSettingsDto>(viewSettings.toDto(), this.endPoint).pipe(map(r => this.translateSettings(r)));
  }
}
