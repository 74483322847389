
// t4tsCodeFromCs": "0.8.2.0 for file CompareType.cs
// Don't adjust manually!
export enum CompareTypeDto {
        Equals = 0,
        NotEquals = 1,
        Less = 2,
        Greater = 3,
        NotNull = 4,
        Null = 5,
        LessOrEqual = 6,
        GreaterOrEqual = 7,
        Like = 8,
        NotLike = 9,
}

