import { DashletAnalysisSourceDto } from './DashletAnalysisSource-dto';
import { DashletTypeDto } from './DashletType-dto';
import { DataSetTypeDto } from './../../../../../common/models/dto/DataSetType-dto';
import { StatisticDto } from './StatisticDTO-dto';

// t4tsCode gen": "0.8.2.0 for file DashletDefinitionDTO.cs
// Don't adjust manually!

export class DashletDefinitionDto {
    public Id: number;
    public Type: DashletTypeDto;
    public Name: string;
    public Source: DashletAnalysisSourceDto;
    public DataSetType: DataSetTypeDto;
    public ComparisonSource: DashletAnalysisSourceDto;
    public Statistics: StatisticDto [] = [];
    public Cluster: string;
    public AvailableTo: number;
    public SortOrder: number;
}

