import { Component, EventEmitter, Input, Output } from "@angular/core";

import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { IntegrationServiceLanguage } from "../../../../../common/language/integrationservicelanguage.service";
import { ConnectorSpecificationDto } from "../../../../../common/models/integrationservices/dto/ConnectorSpecification-dto";
import { EventTimerDataWellknownKeysDto } from "../../../../../common/models/integrationservices/dto/EventTimerDataWellknownKeys-dto";
import { EventTimerService } from "../event-timer.service";
import { EventTimerInformation } from "../models/event-timer-information";

@Component({
  selector: "app-event-timer-details",
  templateUrl: "event-timer-details.component.html",
  styleUrls: ["event-timer-details.component.scss"],
})
export class EventTimerDetailsComponent {
  constructor(public integrationLanguage: IntegrationServiceLanguage, private eventTimerService: EventTimerService) {}
  @Input() event: EventTimerInformation;
  @Input() connectors: ConnectorSpecificationDto[];

  public informationButtonVisible: boolean = false;
  public formValid = false;

  @Output() saveEmitter = new EventEmitter<{ message: string; succes: boolean; eventInformation: EventTimerInformation }>(true);
  @Output() deleteEmitter = new EventEmitter<boolean>(true);
  @Output() editEmitter = new EventEmitter<boolean>(true);

  public saveEvent() {
    this.eventTimerService.saveEventTimer(this.event).subscribe((r) => {
      this.handleEventSaved(r);
    });
  }

  public get connectorName() {
    if (this.connectors.length > 0) {
      const id = Number(this.event.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedConnectorSpecification]);
      const name = this.connectors.find((x) => x.KeyId === id)?.LongName || "";
      return name;
    }
  }

  public validated(e) {
    this.formValid = e.isValid;
  }

  public deleteEvent() {
    this.eventTimerService.deleteEventTimer(this.event.keyId).subscribe((r) => {
      this.deleteEmitter.emit();
    });
  }

  public editEvent() {
    this.editEmitter.emit();
  }

  public togglePause() {
    const dtoEvent = this.event.toDto();
    dtoEvent.Active = !dtoEvent.Active;

    this.eventTimerService.updateEventFrom(dtoEvent).subscribe((r) => {
      this.handleEventSaved(r);
    });
  }

  public executeTimer() {
    this.eventTimerService.executeTimer(this.event).subscribe((r) => {
      this.handleEventTimerExecuted(r);
    });
  }

  public refreshTimerStatus() {
    this.eventTimerService.retrieveEventWithId(this.event.keyId).subscribe((r) => {
      if (ElementHelper.isNullOrUndefined(r) === false) {
        this.event = r;
      }
    });
  }

  infoButtonOptions = {
    icon: "fas fa-info",
    stylingMode: "text",
    hint: this.integrationLanguage.eventTimerDataNextRunInformation,
    disabled: false,
    visible: this.informationButtonVisible,
  };

  private handleEventTimerExecuted(r: EventTimerInformation) {
    if (ElementHelper.isNullOrUndefined(r) === false) {
      this.event = r;
      this.saveEmitter.emit({ message: this.integrationLanguage.eventTimerQueuedSuccessfully, succes: true, eventInformation: this.event });
    } else {
      this.saveEmitter.emit({ message: this.integrationLanguage.eventTimerQueuingFailed, succes: false, eventInformation: this.event });
    }
  }

  private handleEventSaved(r: EventTimerInformation) {
    if (ElementHelper.isNullOrUndefined(r) === false) {
      this.event = r;
      this.saveEmitter.emit({ message: this.integrationLanguage.eventTimerSaveSucces, succes: true, eventInformation: this.event });
    } else {
      this.saveEmitter.emit({ message: this.integrationLanguage.eventTimerSaveFailed, succes: false, eventInformation: this.event });
    }
  }
}
