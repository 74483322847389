import { Component, Input, OnInit } from "@angular/core";
import { SelectBoxItems } from "./selectbox-items";

@Component({
  selector: "app-selectbox",
  templateUrl: "./selectbox.component.html",
  styleUrls: ["./selectbox.component.scss"],
})
export class SelectBoxComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  @Input()
  public items: SelectBoxItems;
}
