import { NgModule } from "@angular/core";
import { DxTextAreaModule } from "devextreme-angular";
import { TextAreaWithNewLineHandlerComponent } from "./text-area-with-new-line-handler.component";

@NgModule({
  declarations: [TextAreaWithNewLineHandlerComponent],
  imports: [DxTextAreaModule],
  exports: [TextAreaWithNewLineHandlerComponent],
})
export class TextAreaWithNewLineHandlerModule {}
