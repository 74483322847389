import { ValueTextMapper } from "../../../../../common/models/value-text-mapper";
import { SDConnectUserTypeDto } from "../dto/SDConnectUserType-dto";
import { SDWorxLanguage } from "../sdworxlanguage.service";

export class SDConnectUserTypeTranslator extends ValueTextMapper<SDConnectUserTypeDto> {
  constructor(language: SDWorxLanguage) {
    super(SDConnectUserTypeDto.Unknown, language.sdWorxProvisioningUsersUserTypeUnknown);
    this.addEntry(SDConnectUserTypeDto.User, language.sdWorxProvisioningUsersUserTypeUser);
    this.addEntry(SDConnectUserTypeDto.Employee, language.sdWorxProvisioningUsersUserTypeEmployee);
  }
}
