import { ParameterMutationDto } from "../../../../../../common/models/dto/ParameterMutationDto-dto";
import { TimePeriodDto } from "../../../../../../common/models/dto/TimePeriodDto-dto";

export class ParameterMutation {
  public static toDto(mutation: ParameterMutation): ParameterMutationDto {
    const newMuta = new ParameterMutationDto();
    newMuta.KeyId = mutation.keyId;
    newMuta.KeyStartDate.DateAsSortableNumber = mutation.keyStartDate;
    newMuta.KeyVariantId = mutation.keyVariantId;
    newMuta.Value = mutation.value;
    newMuta.StartDateText = mutation.startDateText;

    return newMuta;
  }

  public static createDefault(): ParameterMutation {
    const newMuta = new ParameterMutation();
    newMuta.keyId = -1;
    newMuta.keyStartDate = 1;
    newMuta.keyVariantId = -1;
    newMuta.startDateText = "1980-01";
    newMuta.value = 0;

    return newMuta;
  }

  public keyId: number;
  public keyVariantId: number;
  public keyStartDate: number;
  public value: number;
  public startDateText: string;

  fromDto(dto: ParameterMutationDto) {
    this.keyId = dto.KeyId;
    this.keyStartDate = dto.KeyStartDate.DateAsSortableNumber;
    this.keyVariantId = dto.KeyVariantId;
    this.value = dto.Value;
    this.startDateText = dto.StartDateText;
  }

  public copyWithNewDate(timePeriod: TimePeriodDto): ParameterMutation {
    const newMutation = new ParameterMutation();
    newMutation.keyId = this.keyId;
    newMutation.keyStartDate = timePeriod.TimePeriodStartDate;
    newMutation.keyVariantId = this.keyVariantId;
    newMutation.startDateText = timePeriod.YearPeriodText;
    newMutation.value = this.value;

    return newMutation;
  }
}
