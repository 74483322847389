import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginLandingPageComponent, LoginLandingPageModule, PlLoginComponent, PlUserStorage } from "../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { canActivateRoleGuard } from "../../../angular-common/authentication-guard/role-guard";
import { canActivateUserInformationGuard } from "../../../angular-common/authentication-guard/userinformation.guard";
import { UserInformationService } from "../../../angular-common/authentication/user-information.service";
import { AuditLogsModule } from "../../../angular-common/components/auditing/audit-logs.module";
import { ModelTreeServiceModule } from "../../../angular-common/components/modeltree/modeltreeservice/modeltree-service.module";
import { PageHeaderModule } from "../../../angular-common/components/page-header/page-header.module";
import { PreferredModelModule } from "../../../angular-common/components/preferred-model/preferred-model.module";
import { ApplicationAboutInformationComponent } from "../../../angular-common/components/technicalmaintainer/applicationinformation/applicationinformation.component";
import { LogDownloadComponent } from "../../../angular-common/components/technicalmaintainer/logdownload/log-download.component";
import { TechnicalMaintainerModule } from "../../../angular-common/components/technicalmaintainer/technicalmaintainer.module";
import { DevExpressLanguageLoader } from "../../../angular-common/language/devexpress.language";
import { PlLanguageService } from "../../../angular-common/language/pllanguage.service";
import { CommonRoutesDto } from "../../../common/models/dto/CommonRoutes-dto";
import { TechnicalManagementRoutesDto } from "../../../common/models/dto/TechnicalManagementRoutes-dto";
import { AdaptModule } from "./adapt/adapt.module";
import { AuthorizationScreenModule } from "./authorization-screen/authorization-screen.module";
import { EventTimerModule } from "./event-timer-screen/event-timer-screen.module";
import { ImagineFeaturesModel } from "./imagine-features-model";
import { ModelEditScreenComponent } from "./model-edit-screen/model-edit-screen.component";
import { ModelEditScreenModule } from "./model-edit-screen/model-edit-screen.module";
import { ModelRoutesDto } from "./models/dto/ModelRoutes-dto";
import { SDWorxProvisioningRoutesModule } from "./sdworx-provisioning/sdworx-provisioning-routing.module";
import { DatasetServiceModule } from "./services/dataset/dataset.service.module";
import { FileModule } from "./services/file";
import { ImportModule } from "./services/import";
import { ImagineLanguage } from "./services/language/imaginelanguage.service";
import { ParameterEditServiceModule } from "./services/parameteredit/parameteredit.module";
import { VariantEditServiceModule } from "./services/variantedit";
import { ImportFileModule } from "./shared/import-file/import-file.module";
import { VismaRaetFeatureLoaderModule } from "./vismaraet/vismaraet-feature-loader-module";

// This function needs to be here and be exported. The language is loaded before other modules.
export function initializeLanguage(imagineLanguageService: ImagineLanguage, plLanguageService: PlLanguageService) {
  return () => {
    imagineLanguageService
      .loadStaticLanguage()
      .then((l) => {
        DevExpressLanguageLoader.loadDevExpressLanguage(l.MetaData.Language);
      })
      .catch((e) => console.log("Error loading language"));

    PlUserStorage.userChanged.subscribe((user) => {
      plLanguageService.loadMetaData().then((result) => {
        imagineLanguageService.basicLanguage.setLanguageMetadata(result.Languages);
      });
    });
  };
}

export function checkUserLoginState(statusService: UserInformationService) {
  return () => statusService.checkUserStatus().catch((e) => console.log("Error validating user status", e));
}

const routes: Routes = [
  { path: "", component: LoginLandingPageComponent, pathMatch: "full", canActivate: [canActivateUserInformationGuard] },
  { path: CommonRoutesDto.Login, component: PlLoginComponent },
  {
    path: ModelRoutesDto.ModelEditScreen,
    component: ModelEditScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
  {
    path: TechnicalManagementRoutesDto.LogDownload,
    component: LogDownloadComponent,
    canActivate: [canActivateRoleGuard],
  },
  {
    path: TechnicalManagementRoutesDto.ApplicationInformation,
    component: ApplicationAboutInformationComponent,
    canActivate: [canActivateRoleGuard],
  },
  { path: "**", redirectTo: CommonRoutesDto.LandingPage },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    PageHeaderModule,
    AuthorizationScreenModule,
    ImportFileModule,
    ImportModule,
    AdaptModule,
    FileModule,
    ModelTreeServiceModule,
    VariantEditServiceModule,
    ModelEditScreenModule,
    DatasetServiceModule,
    LoginLandingPageModule,
    ParameterEditServiceModule,
    TechnicalMaintainerModule,
    AuditLogsModule,
    EventTimerModule,
    SDWorxProvisioningRoutesModule,
    PreferredModelModule,
    VismaRaetFeatureLoaderModule,
  ],
  exports: [PageHeaderModule],
  providers: [
    UserInformationService,
    PlLanguageService,
    ImagineLanguage,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLanguage,
      deps: [ImagineLanguage, PlLanguageService, HttpClient],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: checkUserLoginState,
      deps: [UserInformationService],
      multi: true,
    },
    ImagineFeaturesModel,
  ],
})
export class AppImagineModule {}
