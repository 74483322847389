import { ModelElementDto } from './ModelElementDto-dto';
import { VariantDto } from './VariantDTO-dto';

// t4tsCodeFromCs": "0.8.2.0 for file ModelElementsForVariantDto.cs
// Don't adjust manually!

export class ModelElementsForVariantDto {
    public ModelElements: ModelElementDto [] = [];
    public Variant: VariantDto;
}

