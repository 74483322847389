<div *ngIf="dataMutationsAllData">
  <app-variant-information id="variant-info-control" [variants]="variants"
    [showVariantCalculationInput]="false"></app-variant-information>

  <div [ngClass]="{'result-warning': hasError}" class="result-warning" *ngIf="hasErrorMessage">{{ errorMessage }}</div>

  <div class="inner-container fix-height-l">
    <dx-data-grid id="masterGrid" (onInitialized)="onMasterGridInitialized($event)"
      [dataSource]="dataMutationsAllData.data.rows" [columnAutoWidth]="true"
      [masterDetail]="{ enabled: true, template: 'detailTemplate' }" (onRowExpanding)="selectMasterRow($event)"
      keyExpr="identification" (onEditingStart)="editMasterRow($event)" (onInitNewRow)="initializeNewMasterRow($event)"
      (onRowInserted)="saveMasterRow($event)">
      <dxo-editing mode="batch" [allowUpdating]="!hasError" [allowAdding]="!hasError" [allowDeleting]="false"
        useIcons="true">
        <dxo-texts [addRow]="language.dataMutationsActionsAddRecord"></dxo-texts>
      </dxo-editing>
      <dxo-selection model="single"></dxo-selection>
      <dxo-search-panel visible="true" width="300"></dxo-search-panel>

      <dxi-column dataField="copyControlsColumn" caption="" cellTemplate="copyControlsColumnCellTemplate"
        [allowEditing]="false" alignment="center" width="60"></dxi-column>
      <div *dxTemplate="let cell of 'copyControlsColumnCellTemplate'">
        <dx-button icon="far fa-copy" (click)="copyMasterRow(cell.data)" hint="{{ language.dataMutationsActionsCopy }}">
        </dx-button>
      </div>

      <div *dxTemplate="let detail of 'detailTemplate'" class="mutations-table">
        <div class="mutations-header">
          <label class="mutations-header-label">{{ language.dataMutationsMutations }}</label>
        </div>
        <div *ngIf="detailData">
          <dx-data-grid [dataSource]="detailData.rows" (onInitialized)="onDetailGridInitialized($event)"
            [columnAutoWidth]="true" (onInitNewRow)="initializeNewDetailRow($event)"
            (onRowInserted)="saveNewDetailRow($event)" (onRowUpdating)="onUpdatingDetailRow($event)"
            (onRowUpdated)="onUpdatedDetailRow($event)" (onEditorPreparing)="onEditorPreparing($event)"
            (onRowRemoved)="confirmDeleteDetailRow($event)">
            <dxo-editing mode="batch" [allowUpdating]="true" [allowAdding]="true" useIcons="true">
              <dxo-texts [addRow]="language.dataMutationsActionsAddMutation"></dxo-texts>
            </dxo-editing>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-sorting mode="multiple"></dxo-sorting>

            <dxi-column dataField="deleteControlsColumn" caption="" cellTemplate="deleteControlsColumnCellTemplate"
              [allowEditing]="false" alignment="center" width="100"></dxi-column>
            <div *dxTemplate="let cell of 'deleteControlsColumnCellTemplate'">
              <div *ngIf="cell.data.canDelete === true">
                <div *ngIf="cell.row.removed">
                  <dx-button icon="undo" hint="{{ language.dataMutationsActionsUndelete }}"
                    (click)="unDeleteDetailRow(cell)"> </dx-button>
                </div>
                <div *ngIf="!cell.row.removed">
                  <dx-button icon="fas fa-trash" hint="{{ language.dataMutationsActionsDelete }}"
                    (click)="deleteDetailRow(cell)"></dx-button>
                </div>
              </div>
            </div>
          </dx-data-grid>
        </div>
      </div>
    </dx-data-grid>
  </div>
</div>