import { RoleDto } from './../../../../../common/models/dto/RoleDto-dto';
import { UserPublicInformationDto } from './../../../../../common/models/dto/UserPublicInformationDto-dto';

// t4tsCode gen": "0.8.2.0 for file DashboardAuthorizationDto.cs
// Don't adjust manually!

export class DashboardAuthorizationDto {
    public AssignedRoles: RoleDto [] = [];
    public Owner: UserPublicInformationDto;
}

