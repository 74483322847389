import { Component, OnInit } from "@angular/core";
import { Tab, Tabs, TextPopupInteraction } from "../../../../../angular-common";
import { DashboardDefinition, DashboardDefinitionList, DashboardService } from "../../services/dashboard";
import { DashletDefinitionService, DashletDefinitions } from "../../services/dashletdefinition";

import { RoleDto } from "../../../../../common/models/dto/RoleDto-dto";
import { DashletTypeDto } from "../../analysis/dto/DashletType-dto";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashboard-design",
  templateUrl: "./dashboard-design.component.html",
  styleUrls: ["./dashboard-design.component.scss"],
})
export class DashboardDesignComponent implements OnInit {
  public dashboards: DashboardDefinitionList;
  public tabs: Tabs;
  public definitionDataStatistic: DashletDefinitions;
  public definitionDataChart: DashletDefinitions;
  public definitionDataDetail: DashletDefinitions;
  public availableRoles: RoleDto[];

  constructor(private dashboardService: DashboardService, private dashletDefinitionService: DashletDefinitionService, public language: ImagineLanguage) {
    this.addDashboardPopup = new TextPopupInteraction(language.dashboarddesignAddDashboardHeader, language.dashboarddesignAddDashboardTitle, language.ButtonOk, language.ButtonCancel);
    this.addDashboardPopup.textInput = language.dashboarddesignNewDashboardName;
    this.addDashboardPopup.showTextInput = true;
    this.addDashboardPopup.confirmedChanged.subscribe((x) => {
      this.addNewDashboard(this.addDashboardPopup.textInput);
    });

    this.deleteDashboardPopup = new TextPopupInteraction(language.confirmDeleteHeader, language.confirmDeleteTitle, language.ButtonOk, language.ButtonCancel);
    this.deleteDashboardPopup.confirmedChanged.subscribe((x) => this.deleteDashboardConfirmed(this.deleteDashboardPopup.context));
  }

  ngOnInit() {
    this.dashletDefinitionService.getDashletDefinitions().subscribe((definitions) => {
      if (definitions === undefined || definitions === null) {
        return;
      }

      this.definitionDataStatistic = definitions.createFiltered(this.dashletType.Statistic);
      this.definitionDataChart = definitions.createFiltered(this.dashletType.Chart);
      this.definitionDataDetail = definitions.createFiltered(this.dashletType.Detail);
    });

    this.dashboardService.getDashboards().subscribe((dashboards) => {
      this.dashboards = dashboards.dashboardDefinitions;
      this.availableRoles = dashboards.availableRoles;

      // Add tabs
      const t = new Tabs();
      this.dashboards.dashboards.forEach((db) => {
        this.addTab(t, db);
      });

      // Select first tab by default
      if (t.items.length > 0) {
        t.selectedTab = t.items[0];
        this.selectedDashboardDefinition = t.items[0].content;
      }

      // Subscribe to tab selection
      t.selectedItemChanged.subscribe((t2) => {
        if (t2 && t2 !== null) {
          this.selectedDashboardDefinition = t2.content;
        }
      });

      t.sort();
      this.tabs = t;
    });
  }

  private addTab(tabs: Tabs, dashboard: DashboardDefinition): Tab {
    const t = tabs.addNew(dashboard.id, dashboard.name, "spinup", dashboard);
    t.sortOrder = dashboard.sortOrder;
    return t;
  }

  public selectedDashboardDefinition: DashboardDefinition;

  public addDashboard() {
    this.addDashboardPopup.textInput = this.language.dashboarddesignNewDashboardName;
    this.addDashboardPopup.isVisible = true;
  }

  public deleteDashboard(dashboard: DashboardDefinition) {
    this.deleteDashboardPopup.context = dashboard;
    this.deleteDashboardPopup.isVisible = true;
  }

  public addDashboardPopup: TextPopupInteraction;
  public deleteDashboardPopup: TextPopupInteraction;

  private addNewDashboard(name: string) {
    this.dashboards.addNew(name).subscribe((newDashboard) => {
      this.tabs.selectedTab = this.addTab(this.tabs, newDashboard);
    });
  }

  private deleteDashboardConfirmed(dashboard: DashboardDefinition) {
    if (this.selectedDashboardDefinition === dashboard) {
      this.tabs.selectedTab = null;
    }

    this.dashboards.delete(dashboard).subscribe(() => {
      this.tabs.deleteItemById(dashboard.id);
      if (this.tabs.items.length > 0) {
        this.tabs.selectedTab = this.tabs.items[0];
      }
    });
  }

  public dashbordSortOrderChanged(dashboard: DashboardDefinition) {
    const tabForDashboard = this.tabs.items.find((t) => t.content === dashboard);
    if (tabForDashboard !== undefined && tabForDashboard !== null) {
      this.tabs.setSortOrder(tabForDashboard, dashboard.sortOrder);
    }
  }

  public dashletType = DashletTypeDto;
}
