<div>
  <div class="buttons align-right">
    <p class="savewarning" *ngIf="hasChanges">*Click to save changes</p>
    <dx-button id="saveButton" icon="save" [hint]="language.ButtonSave" (click)="saveModelGroups()"> </dx-button>
  </div>
  <div *ngIf="editGroup && availableElements">
    <app-list-selector [itemsInUse]="editGroup.elements" [availableItems]="availableElements"
      [displayValue]="displayValue" [searchValue]="searchValue" [moveImplementation]="moveImplementation"
      (valuesChanged)="listingValuesChanged()" (sortOrderChanged)="listingOrderChanged($event)"></app-list-selector>
  </div>
</div>