import { Component, OnInit } from "@angular/core";

import { DashletTypeDto } from "../../../analysis/dto/DashletType-dto";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { DashletDefinitionDesignPropertiesBase } from "./dashlet-definition-design-props-base";

@Component({
  selector: "app-dashlet-definition-designer-chart",
  templateUrl: "./dashlet-definition-designer-chart.component.html",
  styleUrls: ["./dashlet-definition-designer-chart.component.scss"],
})
export class DashletDefinitionDesignerChartComponent extends DashletDefinitionDesignPropertiesBase implements OnInit {
  constructor(language: ImagineLanguage) {
    super(language);
  }

  ngOnInit(): void {
    this.ensureAtLeastOneStatisticPresent();
  }

  public get enableStatisticSelection(): boolean {
    return this.dashletDefinition.changes.type !== DashletTypeDto.Detail;
  }

  public get enableClusterSelection(): boolean {
    return this.dashletDefinition.changes.type === DashletTypeDto.Chart;
  }

  public get statisticWarningMessage(): string {
    if (this.dashletDefinition.changes.statistics.length > 1) {
      return this.language.dashletViewDesignMultipuleStatisticWarning;
    }
  }

  public get warningMessage(): string {
    if (this.dashletDefinition.canSave) {
      return this.language.dashletDefinitionDesignSaveChanges;
    }

    if (!this.dashletDefinition.changes.typeIsValid) {
      return this.language.dashletDefinitionDesignSelectType;
    }
    if (!this.dashletDefinition.changes.nameIsValid) {
      return this.language.dashletDefinitionDesignInputName;
    }
    if (!this.dashletDefinition.changes.variantIsValid) {
      return this.language.dashletDefinitionDesignSelectVariant;
    }
    if (!this.dashletDefinition.changes.statisticIsValid) {
      return this.language.dashletDefinitionDesignSelectStatisticAndOperator;
    }
    if (!this.dashletDefinition.changes.dataSetTypeIsValid) {
      return this.language.dashletDefinitionDesignSelectDataSetType;
    }

    return null;
  }
}
