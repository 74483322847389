<div>
  <app-cardview>
    <div id="variantInfoHeader">
      <div id="variantInfoHeader-dataset">{{ datasetUsedText }}</div>
      <app-variant-information id="variantInfoHeader-buttons" [variants]="variants"
        [showVariantCalculationInput]="false">
      </app-variant-information>
    </div>

    <app-modelgroup-edit-filter id="modelSelector" [modelGroups]='groups' (groupSelected)="onGroupSelected($event)">
    </app-modelgroup-edit-filter>
    <div *ngIf="selectedGroup">
      <app-modelsgroups-edit [editGroup]="selectedGroup" [modelElements]='modelElements'></app-modelsgroups-edit>
    </div>
  </app-cardview>
</div>