<div *ngIf="datasets" class="dataEditing-tabs">
  <div class="inner-container fix-height-l">
    <dx-data-grid [dataSource]="datasets" (onRowUpdated)="onRowUpdated($event)" columnAutoWidth="true">
      <dxo-editing [allowUpdating]="true" useIcons="true"></dxo-editing>
      <dxi-column cellTemplate="lockedTemplate" width="50"></dxi-column>

      <div *dxTemplate="let d of 'lockedTemplate'">
        <div *ngIf="isLocked(d.data)">
          <img src="../../assets/images/lock.png" />
        </div>
      </div>

      <dxi-column caption="{{ language.datasetLongname }}" dataField="longName"></dxi-column>
      <dxi-column cssClass="cannotEdit" caption="{{ language.datasetTable }}" calculateDisplayValue="shortName"> </dxi-column>
      <dxi-column cssClass="cannotEdit" caption="{{ language.datasetConnection }}" calculateDisplayValue="connection"> </dxi-column>
      <dxi-column cssClass="cannotEdit" caption="{{ language.datasetUser }}" calculateDisplayValue="user"></dxi-column>
      <dxi-column cssClass="cannotEdit" caption="{{ language.datasetDescription }}" calculateDisplayValue="description"> </dxi-column>
      <dxi-column cssClass="cannotEdit" caption="{{ language.datasetDatasetCreated }}" calculateDisplayValue="startTime" dataType="date"> </dxi-column>
      <dxi-column cssClass="cannotEdit" caption="{{ language.datasetKeyField }}" calculateDisplayValue="keyFields"> </dxi-column>
      <dxi-column type="butons" cellTemplate="buttonTemplate"></dxi-column>

      <div *dxTemplate="let d of 'buttonTemplate'">
        <dx-button id="deleteButton" [disabled]="isLocked(d.data)" icon="fas fa-trash" (onClick)="deleteRow(d.data)" [hint]="language.ButtonDelete"></dx-button>
      </div>
    </dx-data-grid>
  </div>
</div>
