import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SDConnectProvisioningMessageStatusDto } from "./dto/SDConnectProvisioningMessageStatusDto-dto";

@Injectable({ providedIn: "root" })
export class SDWorxProvisioningMessageStatusService extends PlBaseService {
  readonly endpoint = "sdprovisioningmessagestatus";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveAllMessageStatus(): Observable<SDConnectProvisioningMessageStatusDto[]> {
    return this.getData<SDConnectProvisioningMessageStatusDto[]>(this.endpoint);
  }

  public refreshStatusMessages(): Observable<any> {
    return this.postData(this.endpoint + "/refreshMessagesStatus", null);
  }

  public messageStatusDates(startDate: Date, endDate: Date) {
    var startDateInDateTimeFormat = startDate.toISOString();
    var endDateinDateTimeFormat = endDate.toISOString();
    return this.getData<SDConnectProvisioningMessageStatusDto[]>(`${this.endpoint}/?startDate=${startDateInDateTimeFormat}&endDate=${endDateinDateTimeFormat}`);
  }
}
