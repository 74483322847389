import { AdaptConfigurationDto } from "../dto/AdaptConfiguration-dto";
import { ModelElement } from "../../../../../common/modelelements";
import { ModelGroupingDto } from "../../adapt/dto/ModelGroupingDto-dto";
import { ModelsGroup } from "./modelgroups-group.model";
import { VariantInformationDto } from "../../../../../common/models/dto/VariantInformation-dto";

export class ModelGrouping {
  public constructor() {}

  public copyFromConfigDto(dto: AdaptConfigurationDto) {
    this.copyFromDto(dto.ModelGrouping);
    this.Variants = dto.Variants;
    this.dataSetName = dto.DataSetName;
  }

  public copyFromDto(dto: ModelGroupingDto) {
    const newModelGroupList: ModelsGroup[] = [];
    dto.GroupList.forEach((x) => {
      const newModelGroup = new ModelsGroup();
      newModelGroup.copyFromDto(x);
      newModelGroupList.push(newModelGroup);
    });

    const newModelElements: ModelElement[] = [];
    dto.ModelElements.forEach((e) => {
      const newModelElement = new ModelElement();
      newModelElement.copyFromDto(e);
      newModelElements.push(newModelElement);
    });

    this.modelElements = newModelElements;
    this.groupList = newModelGroupList;
  }

  public groupList: ModelsGroup[] = [];
  public modelElements: ModelElement[] = [];
  public Variants: VariantInformationDto[] = [];
  public dataSetName: string = "";
}
