import { ConfigurationWizardAction } from "../../../../../angular-common/components/configwizard/models/wizardaction";
import { EventTimerSpecificationSourceDto } from "../../../../../common/models/integrationservices/dto/EventTimerSpecificationSource-dto";
import { EventTimerTypeDto } from "../../../../../common/models/integrationservices/dto/EventTimerType-dto";
import { WizardActionType } from "../../../../../angular-common/components/configwizard/models/wizard-action-types";

export class EventSourceWizardAction extends ConfigurationWizardAction {
  constructor(id: string, order: number, label: string, private values: EventTimerSpecificationSourceDto[]) {
    super(id, WizardActionType.dxSelectBox, order, label);
    this.setDataSourceDto(values);
  }

  public get eventType() {
    const item = this.values.find((v) => v.KeyId === this.value);
    if (item) {
      return item.TimerType;
    }
  }

  public set eventType(valueType: EventTimerTypeDto) {
    this.value = valueType;
  }
}
