import { Component, Input, OnInit } from "@angular/core";
import { FileDefinition, FileSupplied } from "../../services/file";

import { ActivatedRoute } from "@angular/router";
import { RouteNavigation } from "../../../../../angular-common/baseservice/route-navigation";
import { ToasterInput } from "../../../../../angular-common/components/toaster/toaster-input-model";
import { ProcessingMessageStateDto } from "../../../../../common/models/integrationservices/dto/ProcessingMessageState-dto";
import { ProcessingStateTypeDto } from "../../../../../common/models/integrationservices/dto/ProcessingStateType-dto";
import { DataSupplyRoutesDto } from "../../import/dto/DataSupplyRoutes-dto";
import { ImportDefinition } from "../../services/import";
import { ImportStateService } from "../../services/import/import-state.service";
import { ImportService } from "../../services/import/import.service";
import { ImportSpecificationSettings } from "../../services/import/models/import-specification-settings.model";
import { ImportLanguage } from "../../services/language/connectorlanguage.service";
import { IFileContent } from "../../shared/import-file/i-file-content";

@Component({
  selector: "app-data-supply-content-import",
  templateUrl: "./data-supply-content.component-import.html",
  styleUrls: ["./data-supply-content.component-import.scss"],
})
export class DataSupplyContentComponentImportComponent implements OnInit {
  private hasBeenSent: boolean;
  private datasetId: number = 0;
  private datasetName: string = "";
  private mutationDate: Date = new Date();
  public toasterInput: ToasterInput = new ToasterInput();
  private lastState: ProcessingMessageStateDto;

  constructor(
    public language: ImportLanguage,
    private routeNavigation: RouteNavigation,
    private route: ActivatedRoute,
    private importService: ImportService,
    private stateService: ImportStateService,
  ) {
    this.hasBeenSent = false;
    this.requestDelete = this.requestDelete.bind(this);
  }

  public ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if ("id" in params) {
        const paramsId = parseInt(params.id, 10);
        this.importService.getImport(paramsId).subscribe((r) => {
          this.importDefinition = r;
        });
      }
    });
  }

  private get datasetIdSet() {
    return this.datasetId > 0;
  }

  private get datasetNameSet() {
    return this.datasetName !== "" || this.datasetName !== undefined;
  }

  public get files(): FileDefinition[] {
    if (this.importDefinition) {
      return this.importDefinition.files.files;
    }
    return [];
  }

  @Input() importDefinition: ImportDefinition;

  private importDefinitionExpectsFiles(): boolean {
    return this.importDefinition.files.files.length > 0;
  }

  public requestImportRun(): void {
    const filesSupplied: FileSupplied[] = [];

    this.importDefinition.files.files.forEach((f) => {
      f.supplied.forEach((fs) => {
        filesSupplied.push(fs);
      });
    });

    if (filesSupplied.length === 0 && this.importDefinitionExpectsFiles()) {
      this.toasterInput.showError(this.language.ImportErrorDataSourceNotFullyConfigured);
      return;
    }

    if (this.importDefinition.options.RequiresDataSetSelection === true && !this.datasetIdSet) {
      this.toasterInput.showError(this.language.datasupplyDatasetNoDatasetSelected);
      return;
    }

    if (this.importDefinition.options.CanSupplyDataSetName === true && !this.datasetNameSet) {
      this.toasterInput.showWarning(this.language.datasupplyDatasetNoDatasetName);
    }

    if (this.importDefinition.options.RequiresSupplyVariant === true && !this.selectedVariantId) {
      this.toasterInput.showError(this.language.ComputeVariantSelect);
      return;
    }

    const importSettings = new ImportSpecificationSettings(this.datasetId, this.datasetName, this.mutationDate, this.selectedVariantId);
    this.stateService.place(this.importDefinition.id, filesSupplied, importSettings).subscribe((f) => {
      this.lastState = f.MessageState;
      switch (f.SummaryState) {
        case ProcessingStateTypeDto.Queued:
          this.toasterInput.showSucces(this.language.datasupplyDatasetQueueSuccesfull);
          break;
        case ProcessingStateTypeDto.Error:
          this.toasterInput.showError(this.language.ImportErrorDataSourceNotFullyConfigured);
          break;
        case ProcessingStateTypeDto.Completed:
          this.toasterInput.showSucces(this.language.datasupplyStartImport);
          break;
        default:
          break;
      }

      return f;
    });
    this.hasBeenSent = true;
    this.files.forEach((file) => {
      file.supplied = [];
      file.filesForUpload = [];
    });
  }

  public requestDelete(file: IFileContent): void {
    if (this.importDefinition === undefined || this.importDefinition.files.files.length === 0) {
      return;
    }

    this.importDefinition.files.files.forEach((f) => {
      const foundItem = f.supplied.find((supply) => supply.name === file.name);
      if (foundItem) {
        this.stateService.deleteData(foundItem.id).subscribe();
        const index = f.supplied.indexOf(foundItem);
        f.supplied.splice(index, 1);
      }
    });
  }

  public get name() {
    if (this.importDefinition) {
      return this.importDefinition.name;
    }
    return "No definition assigned";
  }

  public get description() {
    if (this.importDefinition) {
      return this.importDefinition.description;
    }
    return "the file";
  }

  public get nrOfFiles() {
    if (this.importDefinition.files.files.length > 0) {
      if (this.importDefinition.files.files.length === 1) {
        return this.language.datasupplyFileExpected;
      }
      return this.language.datasupplyNumberOfFilesExpected(this.importDefinition.files.files.length);
    }
    return this.language.datasupplyNoFilesExpected;
  }

  public get disableStartImport() {
    if (this.hasBeenSent) {
      return true;
    }

    if (this.importDefinitionExpectsFiles() === false) {
      return false;
    }

    this.files.forEach((f) => {
      const fAsLoaded = f as any;
      if (fAsLoaded.uploadId === undefined) {
        return true;
      }
    });

    if (this.files.length === 0) {
      return true;
    }

    let suppliedFilesFound = true;
    this.files.forEach((f) => {
      if (f.filesForUpload.length > 0) {
        suppliedFilesFound = false;
      }
    });

    return suppliedFilesFound;
  }

  private selectedVariantId: number;
  public variantIdChanged(value) {
    console.log("variant id changed to:", value);
    this.selectedVariantId = value;
  }

  mutationDateChanged(value) {
    this.mutationDate = value;
  }

  datasetSelected(value) {
    this.datasetId = value;
  }

  datasetNamed(value) {
    this.datasetName = value;
  }

  public get detailsDisabled() {
    if (this.lastState) {
      return false;
    }
    return true;
  }

  public goToDetails() {
    this.routeNavigation.navigate(DataSupplyRoutesDto.DataSupplyDetail, this.lastState.TaskId);
  }
}
