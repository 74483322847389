import { DxButtonModule, DxCheckBoxModule, DxDataGridModule, DxListModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxToolbarModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { VariantInformationModule } from "../../modeling/variant-info-module/variant-information-module";
import { FilterComponent } from "./filter.component";
import { FilterOptionListComponent } from "./filteroptionlist/filteroption-list.component";
import { FilterSummaryComponent } from "./filtersummary/filtersummary.component";

@NgModule({
  imports: [CommonModule, DxSelectBoxModule, DxDataGridModule, DxButtonModule, DxPopupModule, DxListModule, DxToolbarModule, DxScrollViewModule, DxCheckBoxModule, VariantInformationModule],
  declarations: [FilterComponent, FilterOptionListComponent, FilterSummaryComponent],
  exports: [FilterComponent],
})
export class FilterModule {}
