import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthorizationFilterService } from "./authorization-filter.service";

@NgModule({
  imports: [CommonModule],
  providers: [AuthorizationFilterService],
  declarations: [],
})
export class AuthorizationFilterDataModule {}
