import { DataValueDto } from "../../../analysis/dto/DataValue-dto";

export class DashletDataValue {
    public constructor() {
    }

    public value: number;
    public valueAsText: string;

    public copyFromDTO(dto: DataValueDto): DashletDataValue {
        if (dto !== null && dto !== undefined) {
            this.value = dto.Value;
            this.valueAsText = dto.ValueAsText;
        }
        return this;
    }
}
