
// t4tsCodeFromCs": "0.8.2.0 for file RecurrenceType.cs
// Don't adjust manually!
export enum RecurrenceTypeDto {
        Unknown = 0,
        Hourly = 1,
        Daily = 2,
        Weekly = 3,
        Monthly = 4,
        Yearly = 5,
}

