import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VismaFileApiConfigurationDto } from "./dto/VismaFileApiConfigurationDto-dto";

@Injectable({ providedIn: "root" })
export class VismaRaetConfigurationService extends PlBaseService {
  readonly endpoint = "vimsaraetconfiguration";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieve(): Observable<VismaFileApiConfigurationDto> {
    return this.getData<VismaFileApiConfigurationDto>(this.endpoint);
  }

  public store(config: VismaFileApiConfigurationDto): Observable<VismaFileApiConfigurationDto> {
    return this.postData<VismaFileApiConfigurationDto>(this.endpoint, config);
  }

  public delete(): Observable<any> {
    return this.remove(this.endpoint);
  }
}
