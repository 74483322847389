import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../../angular-common";
import { DataSetFilterDto } from "../../../models/dto/DataSetFilterDto-dto";
import { DataSetFilterMetaDataDto } from "../../../models/dto/DataSetFilterMetaData-dto";

@Injectable()
export class AuthorizationFilterService extends PlBaseService {
  private endPoint: string = "AuthorizationFilter";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getAuthorizationFilterMetadata(): Observable<DataSetFilterMetaDataDto> {
    return this.getData<DataSetFilterMetaDataDto>(this.endPoint);
  }

  public saveExistingDataSetFilter(dto: DataSetFilterDto): Observable<void> {
    const url = this.endPoint + "/" + dto.Id;
    return this.update(dto, url);
  }

  public deleteDataSetFilter(dto: DataSetFilterDto): Observable<void> {
    const url = this.endPoint + "/" + dto.Id;
    return this.remove(url);
  }

  public saveNewDataSetFilter(dto: DataSetFilterDto): Observable<EntryCreated<DataSetFilterDto>> {
    return this.createData<DataSetFilterDto>(this.endPoint, dto);
  }
}
