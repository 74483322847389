import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { confirm } from "devextreme/ui/dialog";
import { StringHelper } from "../../../../../angular-common/helpers/string.helper";

import { VismaFileApiConfigurationDto } from "../dto/VismaFileApiConfigurationDto-dto";
import { VismaRaetConfigurationService } from "../vismaraet-config.service";
import { VismaRaetLanguage } from "../vismaraetlanguage.service";

@Component({
  selector: "app-vismaraet-config-screen",
  templateUrl: "./vismaraet-config.component.html",
  styleUrls: ["./vismaraet-config.component.scss"],
})
export class VismaRaetConfigurationComponent {
  constructor(private route: ActivatedRoute, public language: VismaRaetLanguage, private vismaRaetConfigService: VismaRaetConfigurationService) {
    this.route.data.subscribe((data: { configurationsData: VismaFileApiConfigurationDto }) => {
      this.configuration = data.configurationsData;
    });
  }

  public configuration: VismaFileApiConfigurationDto;

  public onSave() {
    this.vismaRaetConfigService.store(this.configuration).subscribe();
  }

  public onDelete() {
    confirm(this.language.vismaFileApiConfigurationConfirmDelete, this.language.AppName).then((answer) => {
      if (answer) {
        this.vismaRaetConfigService.delete().subscribe(() => {
          this.configuration = new VismaFileApiConfigurationDto();
        });
      }
    });
  }

  public get allDataPresent(): boolean {
    return StringHelper.hasNonEmptyText(this.configuration.ClientId) && StringHelper.hasNonEmptyText(this.configuration.ClientSecret) && StringHelper.hasNonEmptyText(this.configuration.TenantId);
  }
}
