import { PatternRuleDto } from "../../../../../../common/models/dto/PatternRule-dto";
import { RangeRuleDto } from "../../../../../../common/models/dto/RangeRule-dto";
import { RequiredRuleDto } from "../../../../../../common/models/dto/RequiredRule-dto";
import { ValidationRuleDto } from "../../../../../../common/models/dto/ValidationRule-dto";
import { ValidationRulePattern } from "./validation-rule-pattern.model";
import { ValidationRuleMinMax } from "./validation-rule-range.model";
import { ValidationRuleRequired } from "./validation-rule-required.model";
import { ValidationRule } from "./validation-rule.model";

export class ValidationRuleBuilder {
  public static buildFromDto(dto: ValidationRuleDto): ValidationRule {
    let result: ValidationRule = null;

    switch (dto.type.toLocaleLowerCase()) {
      case "range": {
        result = new ValidationRuleMinMax();
        result.copyFromDto(dto as RangeRuleDto);
        break;
      }
      case "required": {
        result = new ValidationRuleRequired();
        result.copyFromDto(dto as RequiredRuleDto);
        break;
      }
      case "pattern": {
        result = new ValidationRulePattern();
        result.copyFromDto(dto as PatternRuleDto);
        break;
      }
    }

    return result;
  }
}
