
// t4tsCode gen": "0.8.2.0 for file ImportSuppliedFile.cs
// Don't adjust manually!

export class ImportSuppliedFileDto {
    public ImportSpecificationSourceId: number;
    public FileName: string;
    public EntryKeyId: string;
}

