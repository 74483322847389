import { MutationDto } from './MutationDto-dto';
import { StackCalculationDto } from './StackCalculationDto-dto';
import { StackTypeDto } from './StackType-dto';

// t4tsCodeFromCs": "0.8.2.0 for file StackMutationDto.cs
// Don't adjust manually!

export class StackMutationDto extends MutationDto {
    public Rules: StackCalculationDto [] = [];
    public StackType: StackTypeDto;
    public ExactReferanceStackCategoryId: number;
}

