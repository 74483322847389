import { AnalysisCluster } from "./analysis-cluster";
import { AnalysisClusterDto } from "../../analysis/dto/AnalysisClusterDto-dto";
import { EnvironmentSettings } from "../../../../../angular-common/baseservice/environment-settings";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlBaseService } from "../../../../../angular-common/baseservice/plbase.service";
import { PlLoadIndicator } from "../../../../../angular-common/loadindicator/loadindicator";
import { map } from "rxjs/operators";

@Injectable()
export class AnalysisClusterService extends PlBaseService {
  private endPoint: string = "analysiscluster";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveAll(): Observable<AnalysisClusterDto[]> {
    const result = this.getData<AnalysisClusterDto[]>(this.endPoint);
    return result;
  }

  public updateAnalysisClusters(analysisClusters: AnalysisCluster[]): Observable<AnalysisCluster[]> {
    return this.updateTransformedResponse<AnalysisClusterDto[]>(this.convertoToDto(analysisClusters), this.endPoint).pipe(map((r) => this.convertFromDto(r)));
  }

  private convertoToDto(clusters: AnalysisCluster[]): AnalysisClusterDto[] {
    if (clusters) {
      const dtos: AnalysisClusterDto[] = [];
      clusters.forEach((c) => {
        const dto = c.toDto();
        dtos.push(dto);
      });
      return dtos;
    }
  }

  private convertFromDto(dtos: AnalysisClusterDto[]): AnalysisCluster[] {
    if (dtos) {
      const clusters: AnalysisCluster[] = [];
      dtos.forEach((dto) => {
        const c = new AnalysisCluster();
        c.copyFromDto(dto);
        clusters.push(c);
      });
      return clusters;
    }
  }
}
