<div class="dataEditing-tabs">
  <div class="dataEditing-tabs dx-fieldset">
    <div class="grid grid-template">
      <div class="grid-item column-one">
        <app-cardview [title]="language.editVariantSelectVariant">
          <div class="refreshButton-position">
            <app-variant-information [variants]="variantInformations" [computeChilderenAvailable]="true"></app-variant-information>
            <dx-button icon="fas fa-sync-alt" (onClick)="refreshModelTree()"></dx-button>
          </div>

          <app-modeltree class="modeltree-in-variantedit" [treeUpdateEvent]="eventsSubject | async" [(selectedBranch)]="selectedBranch"></app-modeltree>
        </app-cardview>
      </div>
      <div class="grid-item column-two" *ngIf="selectedBranch">
        <div>
          <app-variant-edit-component [(sendVariantUsage)]="variantInformations" (sendReloadEvent)="notifyReload($event)" [variant]="selectedBranch.element"></app-variant-edit-component>
        </div>
      </div>
    </div>
  </div>
</div>
