<div *ngIf="dashletDefinition && metaData">
  <dx-form id="form" [colCount]="2">
    <!-- Variant -->
    <dxi-item dataField="Variant" [colSpan]="2">
      <dxo-label text="{{ language.variantHeader }}"></dxo-label>
      <div *dxTemplate>
        <app-dashlet-variant-selector
          [items]="metaData.dataSources"
          [(sourceId)]="dashletDefinition.changes.sourceId"
          [(sourceType)]="dashletDefinition.changes.sourceType"
          (elementChanged)="dashletDefinition.changes.sourceElementChanged($event)"
        ></app-dashlet-variant-selector>
      </div>
    </dxi-item>

    <!-- Variant to compare to/with -->
    <dxi-item dataField="Vergelijken met" [colSpan]="2">
      <dxo-label text="{{ language.compareToVariantHeader }}"></dxo-label>
      <div *dxTemplate>
        <app-dashlet-variant-selector
          [items]="metaData.dataSources"
          [(sourceId)]="dashletDefinition.changes.sourceIdCompare"
          [(sourceType)]="dashletDefinition.changes.sourceCompareType"
          (elementChanged)="dashletDefinition.changes.compareElementChanged($event)"
        ></app-dashlet-variant-selector>
      </div>
    </dxi-item>

    <!-- Statistic -->
    <dxi-item [disabled]="enableStatisticSelection === false" [colSpan]="2">
      <dxo-label text="{{ language.statisticHeader }}"></dxo-label>
      <div *dxTemplate>
        <div class="pl-statistics" *ngFor="let statistic of dashletDefinition.changes.statistics">
          <app-statistic-selector [metaData]="metaData" [selectStatistic]="statistic" [dashletDefinition]="dashletDefinition"></app-statistic-selector>
        </div>
        <dx-button [hint]="language.buttonNew" [text]="language.buttonNew" [visible]="false" icon="fas fa-plus" (onClick)="dashletDefinition.newStatistic()"></dx-button>
      </div>
    </dxi-item>

    <!-- Dataset -->
    <dxi-item dataField="dataSetType" [colSpan]="2">
      <dxo-label text="{{ language.datasetHeader }}"></dxo-label>
      <div *dxTemplate>
        <dx-select-box [items]="metaData.dataSetTypes" [searchEnabled]="false" displayExpr="text" valueExpr="dataSetType" [(value)]="dashletDefinition.changes.dataSetType"> </dx-select-box>
      </div>
    </dxi-item>

    <!-- Cluster -->
    <dxi-item dataField="cluster" [colSpan]="2" [visible]="enableClusterSelection === true">
      <dxo-label text="{{ language.clusterHeader }}"></dxo-label>
      <div *dxTemplate>
        <dx-select-box
          [items]="metaData.clusters"
          displayExpr="dsiplayName"
          valueExpr="shortName"
          [(value)]="dashletDefinition.changes.cluster"
          showClearButton="true"
          [placeholder]="language.placeholderNone"
        >
        </dx-select-box>
      </div>
    </dxi-item>

    <!-- Available to -->
    <dxi-item dataField="availableTo" [colSpan]="2" [visible]="false">
      <dxo-label text="{{ language.availableToHeader }}"></dxo-label>
      <div *dxTemplate>
        <dx-select-box
          [items]="metaData.availableToItems"
          displayExpr="name"
          valueExpr="id"
          [(value)]="dashletDefinition.changes.availableTo"
          showClearButton="true"
          [placeholder]="language.placeholderAvailableToSelf"
        >
        </dx-select-box>
      </div>
    </dxi-item>
  </dx-form>
  <div class="button-container bottom-container align-right">
    <span *ngIf="warningMessage !== null" class="savewarning float-left">
      {{ warningMessage }}
    </span>
    <dx-button [text]="language.ButtonSave" icon="save" type="default" [hint]="language.ButtonSave" (onClick)="dashletDefinition.saveChanges()" [disabled]="!dashletDefinition.canSave"></dx-button>
    <dx-button [text]="language.Undo" icon="undo" [hint]="language.Undo" (onClick)="dashletDefinition.undoChanges()" [disabled]="!dashletDefinition.hasChanges"></dx-button>
  </div>
</div>
<div class="button-container bottom-container align-right">
  <span class="savewarning float-left">
    {{ statisticWarningMessage }}
  </span>
</div>
