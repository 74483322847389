<div class="formula-popup">
  <dx-data-grid
    id="gridContainer"
    [dataSource]="mutationsToShow"
    [showBorders]="true"
    [width]="200"
    (onRowClick)="onRowClick($event)"
    (onRowRemoved)="emitOnRowRemoved($event)"
    (onRowPrepared)="onRowPrepared($event)"
  >
    <dxo-editing [allowUpdating]="false" [allowDeleting]="canEdit" [useIcons]="true"></dxo-editing>

    <dxi-column dataField="KeyStartDate.DateAsSortableNumber" caption="{{language.MutationParamMutationDate}}" [allowEditing]="false" [calculateDisplayValue]="selectTimePeriod"> </dxi-column>
  </dx-data-grid>
  <div class="text-and-label">
    <app-text-area-with-new-line-handler
      [cssClass]="'edit-formula'"
      [valueChangeEvent]="'keyup'"
      [value]="initialFormulaMutationValueToEdit"
      (onValueChangedEvent)="onValueChanged($event)"
    ></app-text-area-with-new-line-handler>
    <div class="validation-label"><label [class.failure-label]="isValidationSuccessfull===false">{{validationMessage}}</label></div>
  </div>
  <div class="buttons">
    <dx-button icon="check" (onClick)="validateFormulaMutationButton()"></dx-button>
    <dx-button icon="save" (onClick)="saveFormulaMutationButton()" [disabled]="!isFormulaChanged"></dx-button>
  </div>
</div>
