import { ImportedFileDto } from "../../../import/dto/ImportedFile-dto";

export class ImportFile {
  filename: string;
  shortname: string;

  copyFrom(dto: ImportedFileDto): void {
    this.filename = dto.Filename;
    this.shortname = dto.Shortname;
  }
}
