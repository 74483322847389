import { ActivatedRoute, Params } from "@angular/router";

import { CardViewButton } from "../../../../../angular-common/components/cardview/cardview-button";
import { Component } from "@angular/core";
import { DataSupplyRoutesDto } from "../../import/dto/DataSupplyRoutes-dto";
import { DataSupplyTabsComponent } from "../data-supply-tabs/data-supply-tabs.component";
import { DevExtremeIconsNames } from "../../../../../angular-common/devextreme/dev-extreme-icons";
import { ImportLanguage } from "../../services/language/connectorlanguage.service";
import { ImportProcessingState } from "../../services/import/models/import-processing-state.model";
import { ImportStateService } from "../../services/import/import-state.service";
import { ProcessingMessageStateDto } from "../../../../../common/models/integrationservices/dto/ProcessingMessageState-dto";
import { ProcessingStateTypeDto } from "../../../../../common/models/integrationservices/dto/ProcessingStateType-dto";
import { RouteNavigation } from "../../../../../angular-common/baseservice/route-navigation";
import { ToasterInput } from "../../../../../angular-common/components/toaster/toaster-input-model";
import { confirm } from "devextreme/ui/dialog";

@Component({
  selector: "app-data-supply-status-detail",
  templateUrl: "./data-supply-status-detail.html",
  styleUrls: ["./data-supply-status-detail.scss"],
})
export class DataSupplyStatusDetailComponent {
  public actionButtons: CardViewButton[];

  private static iconProcessRunning = "fas fa-play";
  private static iconProcessPauze = "fas fa-pause";

  private stateId: number;
  public state: ImportProcessingState;
  public toasterInput: ToasterInput = new ToasterInput();

  private buttonRefresh: CardViewButton;
  private buttonToggleStopStart: CardViewButton;
  private buttonDelete: CardViewButton;

  constructor(public language: ImportLanguage, route: ActivatedRoute, private stateService: ImportStateService, private routeNavigation: RouteNavigation) {
    route.params.subscribe((params: Params) => {
      this.stateId = params["id"];
      this.reloadData();
    });

    this.buttonRefresh = {
      id: 1,
      icon: DevExtremeIconsNames.refresh,
      hint: language.datasupplyRefreshHint,
    };
    this.buttonToggleStopStart = {
      id: 2,
      icon: DataSupplyStatusDetailComponent.iconProcessPauze,
      hint: language.datasupplyStopProcessing,
    };
    this.buttonDelete = {
      id: 3,
      icon: DevExtremeIconsNames.delete,
      hint: language.ButtonDelete,
    };
    this.setOptionBasedOnState(new ProcessingMessageStateDto());
  }

  public onButtonClicked(btn: CardViewButton) {
    if (btn.id === this.buttonRefresh.id) {
      this.reloadData();
    } else if (btn.id === this.buttonToggleStopStart.id) {
      this.togglePauzeStart();
    } else if (btn.id === this.buttonDelete.id) {
      this.deleteTask();
    }
  }

  private deleteTask() {
    confirm(this.language.datasupplyRemoveTask, this.language.confirmDeleteHeader).then((answer) => {
      if (answer === true) {
        this.stateService.delete(this.state.messageState.TaskId).subscribe((r) => {
          this.routeNavigation.navigateWithParameterId(DataSupplyRoutesDto.DataSupplyScreen, DataSupplyTabsComponent.tabUploadStatus);
        });
      }
    });
  }

  private togglePauzeStart() {
    this.state.messageState.Blocked = this.buttonToggleStopStart.icon === DataSupplyStatusDetailComponent.iconProcessPauze;

    this.stateService.updateState(this.state.messageState).subscribe((r) => {
      this.setOptionBasedOnState(r);
      if (r.Blocked) {
        this.toasterInput.showSucces(this.language.datasupplyStopProcessing);
      } else {
        this.toasterInput.showSucces(this.language.datasupplyContinueProcessing);
      }
    });
  }

  private setOptionBasedOnState(state: ProcessingMessageStateDto) {
    if (state.Blocked) {
      this.buttonToggleStopStart.icon = DataSupplyStatusDetailComponent.iconProcessRunning;
      this.buttonToggleStopStart.hint = this.language.datasupplyContinueProcessing;
    } else {
      this.buttonToggleStopStart.icon = DataSupplyStatusDetailComponent.iconProcessPauze;
      this.buttonToggleStopStart.hint = this.language.datasupplyStopProcessing;
    }

    this.actionButtons = [];
    this.actionButtons.push(this.buttonRefresh);
    if (state.Present) {
      this.actionButtons.push(this.buttonToggleStopStart);
    }
    this.actionButtons.push(this.buttonDelete);
  }

  private reloadData() {
    this.stateService.getState(this.stateId).subscribe((r) => {
      this.state = r;
      this.setOptionBasedOnState(r.messageState);
    });
  }

  public get description() {
    let description = "Import";

    if (this.state) {
      if (this.state.specificationName) {
        description += ' "' + this.state.specificationName + '"';
      }

      if (this.state.queuedAt) {
        description += " " + this.state.queuedAtString;
      }

      if (this.state.state) {
        description += " (" + this.state.stateString + ")";
      }
    }

    return description;
  }

  public get requestedBy() {
    let name = "";
    if (this.state && this.state.requestedBy) {
      name = this.state.requestedBy.Name;
    }
    return this.language.datasupplyDetailsRequestedBy + " " + name;
  }

  public get importedFilesNotEmpty() {
    return this.importedFiles.length !== 0;
  }

  public get importedFiles() {
    if (this.state) {
      return this.state.importedFiles;
    }
    return [];
  }

  public get hasQueuedState() {
    if (this.state) {
      return this.state.hasQueuedState;
    }

    return false;
  }

  public get hasInProgressSate() {
    if (this.state) {
      return this.state.hasInProgressSate;
    }

    return false;
  }

  public get hasFinishedState() {
    if (this.state) {
      return this.state.hasFinishedState;
    }

    return false;
  }

  public get stateAsMultiple(): ImportProcessingState[] {
    const item: ImportProcessingState[] = [];
    if (this.state) {
      item.push(this.state);
    }
    return item;
  }

  public stateText(stateId: ProcessingStateTypeDto): string {
    switch (stateId) {
      case ProcessingStateTypeDto.Queued:
        return this.language.datasupplyStateQueuedHeader;
      case ProcessingStateTypeDto.Processing:
        return this.language.datasupplyStateProcessingHeader;
      case ProcessingStateTypeDto.Completed:
        return this.language.datasupplyStateDoneHeader;
      case ProcessingStateTypeDto.Error:
        return this.language.datasupplyStateErrorHeader;
      default:
        return "state: " + stateId;
    }
  }
}
