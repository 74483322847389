import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { PlUserStorage } from "../..";
import { MenuItem, MenuService } from "../../menus";

import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { CommonRoutesDto } from "../../../common/models/dto/CommonRoutes-dto";
import { UserInformationValuesDto } from "../../../common/models/dto/UserInformationValues-dto";
import { UserInformationService } from "../../authentication/user-information.service";
import { RouteNavigation } from "../../baseservice/route-navigation";
import { MenuFilterService } from "../../menus/filters/MenuFilterService";
import { MenuFunctions } from "../../menus/menu-functions";
import { WarningProvider } from "../../warning-provider/warning-provider";
import { WarningType } from "../../warning-provider/warning-type";

enum PopupContextType {
  PageSelector = 1,
  User = 2,
}

@Component({
  selector: "app-page-header",
  templateUrl: "page-header.component.html",
  styleUrls: ["page-header.component.scss"],
})
export class PageHeaderComponent implements OnInit, OnDestroy, AfterContentChecked {
  private userSubscription: Subscription = null;
  private manuServiceSubscription: Subscription = new Subscription();
  public disablePageHeader = false;
  public popupVisible = false;
  public popupContext: PopupContextType;
  public PopupContextType = PopupContextType;

  public currentUser: UserInformationValuesDto;
  public menuItems: MenuItem[] = [];

  public hasMultipleMenuItems: boolean;
  public hasUserInfo: boolean;

  public editMode = false;

  public buttonIcon = "edit";

  public editButton() {
    if (!this.editMode) {
      this.editMode = true;
      this.buttonIcon = "save";
    } else if (this.editMode) {
      this.editMode = false;
      this.buttonIcon = "edit";
      this.updateUser();
    }
  }

  private updateUser() {
    if (this.currentUser.LongName !== "") {
      this.userInfoServer.updateUserInformation(this.currentUser).subscribe();
    }
  }

  public get invalid() {
    return this.currentUser.LongName === "";
  }

  constructor(
    private menuService: MenuService,
    public language: WebLanguage,
    private router: Router,
    public routeNavigation: RouteNavigation,
    private titleService: Title,
    private userInfoServer: UserInformationService,
    private changeDetector: ChangeDetectorRef,
    private menuFilterService: MenuFilterService,
    private warningProvider: WarningProvider,
  ) {
    this.hasUserInfo = false;
    this.hasMultipleMenuItems = false;
    this.userSubscription = PlUserStorage.userChanged.subscribe((_) => this.onUserChanged());
  }

  ngOnInit() {
    this.checkDisabledPageheader();
    this.onUserChanged();
  }

  private checkDisabledPageheader() {
    PlUserStorage.hideTopbarChanged.subscribe((x) => {
      this.disablePageHeader = x;
    });
  }

  ngAfterContentChecked() {
    this.toggleHideIcon();
    this.changeDetector.detectChanges();
  }

  toggleTopbar() {
    if (PlUserStorage.hideTopbar) {
      PlUserStorage.hideTopbar = false;
    } else {
      PlUserStorage.hideTopbar = true;
    }
  }

  toggleHideIcon(): string {
    if (this.disablePageHeader) {
      return "spindown";
    }
    return "spinup";
  }

  private onUserChanged() {
    this.hasUserInfo = PlUserStorage.hasUser();
    this.currentUser = PlUserStorage.user;
    this.manuServiceSubscription = this.menuService.getMenuItems().subscribe((items) => {
      const newMenuItems = [];
      items.forEach((item) => {
        if (this.menuFilterService.shouldShow(item)) {
          newMenuItems.push(item);
        }
      });
      this.menuItems = newMenuItems;
      this.hasMultipleMenuItems = this.menuItems.length > 0;
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.manuServiceSubscription) {
      this.manuServiceSubscription.unsubscribe();
    }
  }

  public switchToPage(menuItem: MenuItem) {
    this.hidePopup();
    this.titleService.setTitle(` ${this.language.applicationTitle} - ${menuItem.displayTitle}`);
    this.routeNavigation.navigate(menuItem.route);
  }

  public get currentMenuItem(): string {
    const url = this.router.parseUrl(this.router.url).root.children["primary"];
    if (url && url.segments.length > 0) {
      const menuItem = MenuFunctions.findMenuItem(url.segments[0].path, this.menuItems);
      if (menuItem) {
        return menuItem.displayTitle;
      }
    }
    return null;
  }

  public goHome() {
    this.titleService.setTitle(this.language.applicationTitle);
    this.routeNavigation.goToRoot();
  }

  showPopup(popupContextToShow) {
    this.popupContext = popupContextToShow;
    this.popupVisible = true;
  }

  public logOut() {
    this.titleService.setTitle(`${this.language.applicationTitle} - ${this.language.loginInfoTitle}`);
    this.routeNavigation.navigate(CommonRoutesDto.SignOff);
  }

  public showClaims() {
    this.routeNavigation.navigate(CommonRoutesDto.Claims);
  }

  public hidePopup() {
    this.popupVisible = false;
  }

  public get popupTitle(): string {
    if (this.popupContext === PopupContextType.PageSelector) {
      return this.language.menuPageSelection;
    } else {
      return this.language.menuProfile;
    }
  }

  public get preferredModelFromSession(): string {
    return this.warningProvider.warningFor(WarningType.SessionModelActive);
  }
}
