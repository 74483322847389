import { Component, Input, OnInit } from "@angular/core";
import { EnvironmentSettings, PlUserStorage } from "../../../../../angular-common";
import { FileDefinition, FileService, FileSupplied } from "../../services/file";

import { ImportSuppliedFileDto } from "../../import/dto/ImportSuppliedFile-dto";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { IFileContent } from "./i-file-content";
import { IFileSpecForUsage } from "./i-file-spec-for-usage";
import { IOnUpload } from "./i-on-upload";
import { IOnValueChange } from "./i-on-value-change";
import { IUploadFileContent } from "./i-upload-file-content";

@Component({
  selector: "app-import-file",
  templateUrl: "./import-file.component.html",
  styleUrls: ["./import-file.component.scss"],
})
export class ImportFileComponent implements OnInit {
  public get fileSpecification() {
    return (this.import as any) as IFileSpecForUsage;
  }

  @Input()
  import: FileDefinition;

  @Input()
  fileId: number;

  @Input()
  requestDelete: (file: IFileContent) => void;

  public filesForUpload: IUploadFileContent[];
  public hoverOver = false;

  constructor(public language: ImagineLanguage, private fileService: FileService, private envSettings: EnvironmentSettings) {
    this.import = new FileDefinition();
    this.filesForUpload = [];
  }

  public get maxFileSizeBytes() {
    return this.envSettings.maxUploadSizeMb * 1024;
  }

  public get maxFileSizeWarningMessage() {
    return this.language.fileUploadFileTooLarge(this.envSettings.maxUploadSizeMb);
  }

  ngOnInit() {
    if (this.import) {
      this.filesForUpload = this.import.filesForUpload;

      const uri = this.fileService.createUploadUriFor(this.import.id);
      const asInterface = (this.import as any) as IFileSpecForUsage;
      asInterface.uploadUri = uri;

      const token = PlUserStorage.token;
      if (token !== undefined && token !== null) {
        const authHeaderValue = token.token_type + " " + token.access_token;
        asInterface.header = { Authorization: authHeaderValue };
      }
    }
  }

  public toggleHover() {
    this.hoverOver = !this.hoverOver;
  }

  public onUploaded(e: IOnUpload) {
    if (e.request && e.request.response) {
      const files = JSON.parse(e.request.response) as ImportSuppliedFileDto[];

      this.addUploadedFileRegistrations(files);
    }
  }

  public onValueChanged(e: IOnValueChange) {
    this.import.filesForUpload = this.filesForUpload;
    const deletedFiles = this.getDeletedFiles(e.value, e.previousValue);
    if (deletedFiles.length > 0) {
      deletedFiles.forEach((file) => this.requestDelete(file));
    }
  }

  private getDeletedFiles(value: IUploadFileContent[], previousValue: IUploadFileContent[]): IUploadFileContent[] {
    const deletedFiles: IUploadFileContent[] = [];

    previousValue.forEach((file) => {
      const foundValue = value.find((x) => x.name === file.name);

      if (foundValue === undefined || foundValue === null) {
        deletedFiles.push(file);
      }
    });

    return deletedFiles;
  }

  private addUploadedFileRegistrations(files: ImportSuppliedFileDto[]) {
    if (files !== undefined) {
      files.forEach((dto) => {
        if (this.import.supplied.find((x) => x.name === dto.FileName)) {
          return;
        }

        const f = new FileSupplied();
        f.copyFrom(dto);
        f.definitionId = this.import.id;
        this.import.supplied.push(f);
      });
    }
  }
}
