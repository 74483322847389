<dx-button icon="plus" [text]="addFilterTitle" (click)="openFilterModal()"></dx-button>
<span class="baseModelWarning">{{tabContent.filtersWarningMessage}}</span>

<dx-popup [showTitle]="false" [width]="550" height="auto" [visible]="isFilterWithNoVariant && isPopUpVisible" [hideOnOutsideClick]="false" [showCloseButton]="false">
  <div>
    <label>{{language.authorizationFilterNotExistingVariantMessage}}</label>
    <br />
    <ul>
      <li *ngFor="let filter of tabContent.dataSetFiltersWithoutVariant"><b>{{language.role}}:</b> {{filter.Role.ShortName}}, <b>{{language.filter}}:</b> {{filter.Name}}</li>
    </ul>
  </div>
  <div class="buttons">
    <dx-button class="button" icon="fas fa-trash" [hint]="language.ButtonDelete" [text]="language.ButtonDelete" (onClick)="removeFiltersWithNoVariants()"></dx-button>
    <dx-button class="button" [hint]="language.ButtonCancel" [text]="language.ButtonCancel" (onClick)="closeFilterWithNoVariantsPopup()"></dx-button>
  </div>
</dx-popup>

<dx-accordion [dataSource]="tabContent.dataSetFilterView" [collapsible]="true" [multiple]="false" [animationDuration]="300">
  <div *dxTemplate="let filter of 'title'">
    <h3>{{ filter.title }}</h3>
    <span>{{ language.authorizationFilterFilterContentDescription }} {{getDescriptions(filter)}}</span>
  </div>
  <div *dxTemplate="let filter of 'item'">
    <dx-button *ngIf="isRoleTab" icon="plus" [text]="addFilterTitle" (click)="openNewTargetModal(filter)"></dx-button>
    <dx-button *ngIf="isVariantTab" icon="plus" [text]="addFilterTitle" (click)="openNewRoleModal(filter)"></dx-button>
    <div class="target" *ngFor="let item of filter.content">
      <strong>{{item.description}}</strong>&nbsp;

      <dx-button icon="fas fa-trash" [text]="language.authorizationFilterActionsDelete" (click)="deleteRoleTarget(item)"></dx-button>
      <dx-button icon="plus" [text]="language.authorizationFilterActionsAddExpression" (click)="openNewElementModal(filter)"></dx-button>
      <p *ngIf="!item.elements.length">{{ language.noData }}</p>

      <table *ngIf="item.elements.length">
        <thead>
          <tr>
            <th><strong>{{language.authorizationFilterColumnModelElementName}}</strong></th>
            <th><strong>{{language.authorizationFilterColumnComparison}}</strong></th>
            <th><strong>{{language.authorizationFilterColumnValue}}</strong></th>
            <th class="actions"><strong>{{language.authorizationFilterColumnActions}}</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let element of item.elements">
            <td>{{tabContent.displayPresenter.getDisplayName(element)}}</td>
            <td>{{tabContent.displayPresenter.getCompareTypeName(element)}}</td>
            <td>{{tabContent.displayPresenter.getOptionText(element)}}</td>
            <td class="actions">
              <dx-button icon="edit" (click)="openEditElementModal(filter, element)"></dx-button>
              <dx-button icon="fas fa-trash" (click)="deleteElement(filter, element)"></dx-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</dx-accordion>

<dx-popup [height]="'auto'" [showTitle]="true" [title]="addFilterTitle" [dragEnabled]="false" [hideOnOutsideClick]="true" [(visible)]="addingFilter" maxWidth="40%">
  <form *ngIf="addingFilter" (submit)="addFilter($event);">
    <div class="dx-fieldset filters">
      <div class="dx-field" *ngIf="!addingVariant">
        <div class="dx-field-label">{{ language.authorizationFilterSelectRole }}</div>
        <div class="dx-field-value">
          <dx-select-box [dataSource]="tabContent.roles" displayExpr="ShortName" valueExpre="KeyId" [(value)]="role" [disabled]="!dataLoaded">
            <dx-validator>
              <dxi-validation-rule type="required" [message]="language.authorizationFilterValidateRoleRequired"> </dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </div>
      <div class="dx-field" *ngIf="!addingRole && !addingBaseModelFilter">
        <dx-validation-group name="variantsSelectionValidation">
          <div class="dx-field-label">{{ language.authorizationFilterSelectTarget }}</div>
          <div class="dx-field-value">
            <app-modeltree
              class="modeltree-in-authorizationFilters"
              [allowedType]="modelTreeAllowedElementType"
              [(selectedElement)]="selectedVariant"
              (revalidateOnSelectionChanged)="revalidateVariantsSelection()"
            >
              <dx-validator [adapter]="variantsValidationAdapterConfig">
                <dxi-validation-rule type="custom" [message]="language.authorizationFilterValidateTargetRequired" [validationCallback]="variantsValidationAdapterConfig.getValue" [reevaluate]="true">
                </dxi-validation-rule>
              </dx-validator>
            </app-modeltree>
          </div>
        </dx-validation-group>
      </div>
    </div>
    <div class="dx-fieldset filters div-action-buttons">
      <dx-validation-summary id="summary"></dx-validation-summary>
      <dx-button [text]="language.authorizationFilterActionsCancel" (click)="addingFilter = false"></dx-button>
      <dx-button icon="save" [text]="language.authorizationFilterActionsSave" [useSubmitBehavior]="true"></dx-button>
    </div>
  </form>
</dx-popup>

<dx-popup
  [height]="'auto'"
  [showTitle]="true"
  [title]="editingElement ? language.authorizationFilterActionsEditExisting : language.authorizationFilterActionsEditNew"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [(visible)]="elementFormPopupEnabled"
  maxWidth="40%"
>
  <div *dxTemplate="let data of 'content'">
    <div class="dx-fieldset">
      <form *ngIf="elementFormPopupEnabled" (submit)="saveElement($event)">
        <div class="dx-field">
          <div class="dx-field-label">{{ language.authorizationFilterColumnModelElementName }}</div>
          <div class="dx-field-value">
            <dx-lookup [items]="expressionModel.modelElements.all" [(value)]="expressionModel.modelElementShortName" [showTitle]="false" displayExpr="longName" valueExpr="shortName">
              <dx-validator>
                <dxi-validation-rule type="required" [message]="language.authorizationFilterValidateModelElementRequired"></dxi-validation-rule>
              </dx-validator>
            </dx-lookup>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">{{ language.authorizationFilterColumnComparison }}</div>
          <div class="dx-field-value">
            <dx-lookup [items]="tabContent.compareTypes" [(value)]="expressionModel.compareType" [showTitle]="false" displayExpr="name" valueExpr="id">
              <dx-validator>
                <dxi-validation-rule type="required" [message]="language.authorizationFilterValidateCompareTypeRequired"></dxi-validation-rule>
              </dx-validator>
            </dx-lookup>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">{{ language.authorizationFilterColumnValue }}</div>
          <div class="dx-field-value">
            <div *ngIf="!expressionModel.requiredValuePresent" class="dx-field-value-static">-</div>
            <dx-lookup
              *ngIf="expressionModel.elementHasOptions"
              [items]="expressionModel.valueOptions"
              [(value)]="expressionModel.valueAsReferenceId"
              [showTitle]="false"
              displayExpr="text"
              valueExpr="id"
            >
              <dx-validator>
                <dxi-validation-rule type="required" [message]="language.authorizationFilterValidateCompareValueRequired"></dxi-validation-rule>
              </dx-validator>
            </dx-lookup>
            <dx-text-box *ngIf="expressionModel.requiredValuePresent && !expressionModel.elementHasOptions" [(value)]="expressionModel.value"> </dx-text-box>
          </div>
        </div>

        <div class="dx-fieldset filters div-action-buttons">
          <dx-validation-summary id="summaryElement"></dx-validation-summary>
          <dx-button [text]="language.ButtonCancel" (click)="elementFormPopupEnabled = false"></dx-button>
          <dx-button icon="save" [text]="language.ButtonSave" [useSubmitBehavior]="true"></dx-button>
        </div>
      </form>
    </div>
  </div>
</dx-popup>
