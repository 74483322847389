import { CommonModule } from "@angular/common";
import { DxButtonModule } from "devextreme-angular";
import { ExportingComponent } from "./exporting.component";
import { ExportingService } from "./exporting.service";
import { NgModule } from "@angular/core";
import { ToasterModule } from "../toaster/toaster-module";

@NgModule({
  declarations: [ExportingComponent],
  imports: [CommonModule, DxButtonModule, ToasterModule],
  providers: [ExportingService],
  exports: [ExportingComponent],
})
export class ExportingModule {}
