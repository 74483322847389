<div *ngIf="branches">
  <dx-tree-list
    class="dx-tree-list"
    [dataSource]="branches"
    keyExpr="id"
    parentIdExpr="parentId"
    itemsExpr="children"
    dataStructure="tree"
    [(selectedRowKeys)]="selectedRowKeys"
    [(expandedRowKeys)]="expandedRowKeys"
    [showColumnHeaders]="false"
    (onSelectionChanged)="onSelectionChanged()"
  >
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-selection mode="single"></dxo-selection>
    <dxi-column dataField="name"></dxi-column>
    <dxo-scrolling [useNative]="false" [scrollByContent]="true" [scrollByThumb]="true" showScrollbar="always"> </dxo-scrolling>
  </dx-tree-list>
</div>
