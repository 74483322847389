import { DashletDataPoint } from "../../dashletdata";
import { ClusterField } from "../../dashletdata/models/cluster-field";

export class DataItemByNonVariantCluster {
  public point1: DashletDataPoint;
  public variant1: ClusterField;
  public point2: DashletDataPoint;
  public variant2: ClusterField;

  constructor(public cluster: ClusterField) {}
}
