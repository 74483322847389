import { DateSpanDto } from './../../../../../common/models/dto/DateSpanDto-dto';
import { SDConnectUserTypeDto } from './SDConnectUserType-dto';

// t4tsCode gen": "0.8.2.0 for file SDConnectUserDto.cs
// Don't adjust manually!

export class SDConnectUserDto {
    public Id: number;
    public PayGroup: string;
    public ShortName: string;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public Period: DateSpanDto;
    public LastChanged: Date;
    public UserType: SDConnectUserTypeDto;
}

