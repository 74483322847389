import { ElementTypeDto } from "../../../../../common/models/dto/ElementType-dto";
import { LongNameShortName } from "../../../../../angular-common/longnameshortname/longname-shortname";
import { ModelPresentableElement } from "../../../../../common/model/models/model-presentablel-element-model";
import { ModelTreeBranchDto } from "../../../../../common/models/dto/ModelTreeBranch-dto";

export class ModelTreeBranch {
  public id: number;
  public parentId: number;
  public element: ModelPresentableElement;

  public get children(): ModelTreeBranch[] {
    return this._children;
  }
  public set children(value: ModelTreeBranch[]) {
    this._children = value;
  }
  private _children: ModelTreeBranch[] = [];

  public get name() {
    if (this.element) {
      return LongNameShortName.NameFor(this.element);
    }
    return "No element";
  }
  public get type() {
    if (this.element) {
      return this.element.elementType;
    }
    return ElementTypeDto.Unknown;
  }

  constructor() {
    this.id = -1;
    this.parentId = -1;
    this.element = new ModelPresentableElement(ElementTypeDto.Unknown);
  }

  public addChild(child: ModelTreeBranch) {
    if (child && child !== null) {
      child.parentId = this.id;
      this._children.push(child);
    }
  }

  public copyFromDto(dto: ModelTreeBranchDto) {
    this.element.copyFromDto(dto.Element);
  }

  public getWithId(id: number): ModelTreeBranch {
    if (this.id === id) {
      return this;
    }

    for (var value of this.children) {
      const childResult = value.getWithId(id);
      if (childResult) {
        return childResult;
      }
    }
  }

  public getByModelId(id: number, type: ElementTypeDto): ModelTreeBranch {
    if (this.id === id && this.element.elementType === type) {
      return this;
    }

    for (var value of this.children) {
      const childResult = value.getWithId(id);
      if (childResult) {
        return childResult;
      }
    }
  }
}
