import { PlLanguageService } from '../../../../angular-common';
import { Injectable } from '@angular/core';
import { WebLanguage } from '../../../../common/language/weblanguage.service'

@Injectable()
//t4Languages": "0.8.2.0
export class SDWorxLanguage extends WebLanguage {
  public constructor(plLanguageService: PlLanguageService) {
    super(plLanguageService);
  }

  /**
   * menuSDWorxProvisioningConfiguration from SDWorx.csv (in EN: SDWorx provisioning configuration )
   */
  public key_menuSDWorxProvisioningConfiguration = "menuSDWorxProvisioningConfiguration";
  /**
   * menuSDWorxProvisioningConfiguration from SDWorx.csv (in EN: SDWorx provisioning configuration )
   */
  public get menuSDWorxProvisioningConfiguration(): string { return this.getStringDef(this.key_menuSDWorxProvisioningConfiguration, "SDWorx provisioning configuration"); }

  /**
   * menuSDWorxProvisioningMessageStatus from SDWorx.csv (in EN: SDWorx provisioning message status )
   */
  public key_menuSDWorxProvisioningMessageStatus = "menuSDWorxProvisioningMessageStatus";
  /**
   * menuSDWorxProvisioningMessageStatus from SDWorx.csv (in EN: SDWorx provisioning message status )
   */
  public get menuSDWorxProvisioningMessageStatus(): string { return this.getStringDef(this.key_menuSDWorxProvisioningMessageStatus, "SDWorx provisioning message status"); }

  /**
   * menuSDWorxProvisioningUsers from SDWorx.csv (in EN: SDWorx users provisioning )
   */
  public key_menuSDWorxProvisioningUsers = "menuSDWorxProvisioningUsers";
  /**
   * menuSDWorxProvisioningUsers from SDWorx.csv (in EN: SDWorx users provisioning )
   */
  public get menuSDWorxProvisioningUsers(): string { return this.getStringDef(this.key_menuSDWorxProvisioningUsers, "SDWorx users provisioning"); }

  /**
   * sdWorxProvisioningConfigPayGroup from SDWorx.csv (in EN: Pay group )
   */
  public key_sdWorxProvisioningConfigPayGroup = "sdWorxProvisioningConfigPayGroup";
  /**
   * sdWorxProvisioningConfigPayGroup from SDWorx.csv (in EN: Pay group )
   */
  public get sdWorxProvisioningConfigPayGroup(): string { return this.getStringDef(this.key_sdWorxProvisioningConfigPayGroup, "Pay group"); }

  /**
   * sdWorxProvisioningConfigClientId from SDWorx.csv (in EN: Client ID )
   */
  public key_sdWorxProvisioningConfigClientId = "sdWorxProvisioningConfigClientId";
  /**
   * sdWorxProvisioningConfigClientId from SDWorx.csv (in EN: Client ID )
   */
  public get sdWorxProvisioningConfigClientId(): string { return this.getStringDef(this.key_sdWorxProvisioningConfigClientId, "Client ID"); }

  /**
   * sdWorxProvisioningConfigClientSecret from SDWorx.csv (in EN: Client secret )
   */
  public key_sdWorxProvisioningConfigClientSecret = "sdWorxProvisioningConfigClientSecret";
  /**
   * sdWorxProvisioningConfigClientSecret from SDWorx.csv (in EN: Client secret )
   */
  public get sdWorxProvisioningConfigClientSecret(): string { return this.getStringDef(this.key_sdWorxProvisioningConfigClientSecret, "Client secret"); }

  /**
   * sdWorxProvisioningMessageStatusTaskId from SDWorx.csv (in EN: Task ID )
   */
  public key_sdWorxProvisioningMessageStatusTaskId = "sdWorxProvisioningMessageStatusTaskId";
  /**
   * sdWorxProvisioningMessageStatusTaskId from SDWorx.csv (in EN: Task ID )
   */
  public get sdWorxProvisioningMessageStatusTaskId(): string { return this.getStringDef(this.key_sdWorxProvisioningMessageStatusTaskId, "Task ID"); }

  /**
   * sdWorxProvisioningMessageStatusActionType from SDWorx.csv (in EN: Action Type )
   */
  public key_sdWorxProvisioningMessageStatusActionType = "sdWorxProvisioningMessageStatusActionType";
  /**
   * sdWorxProvisioningMessageStatusActionType from SDWorx.csv (in EN: Action Type )
   */
  public get sdWorxProvisioningMessageStatusActionType(): string { return this.getStringDef(this.key_sdWorxProvisioningMessageStatusActionType, "Action Type"); }

  /**
   * sdWorxProvisioningMessageStatusActionId from SDWorx.csv (in EN: Action ID )
   */
  public key_sdWorxProvisioningMessageStatusActionId = "sdWorxProvisioningMessageStatusActionId";
  /**
   * sdWorxProvisioningMessageStatusActionId from SDWorx.csv (in EN: Action ID )
   */
  public get sdWorxProvisioningMessageStatusActionId(): string { return this.getStringDef(this.key_sdWorxProvisioningMessageStatusActionId, "Action ID"); }

  /**
   * sdWorxProvisioningMessageStatusCorrelationId from SDWorx.csv (in EN: Correlation ID )
   */
  public key_sdWorxProvisioningMessageStatusCorrelationId = "sdWorxProvisioningMessageStatusCorrelationId";
  /**
   * sdWorxProvisioningMessageStatusCorrelationId from SDWorx.csv (in EN: Correlation ID )
   */
  public get sdWorxProvisioningMessageStatusCorrelationId(): string { return this.getStringDef(this.key_sdWorxProvisioningMessageStatusCorrelationId, "Correlation ID"); }

  /**
   * sdWorxProvisioningMessageStatusLastKnownStatus from SDWorx.csv (in EN: Last known status )
   */
  public key_sdWorxProvisioningMessageStatusLastKnownStatus = "sdWorxProvisioningMessageStatusLastKnownStatus";
  /**
   * sdWorxProvisioningMessageStatusLastKnownStatus from SDWorx.csv (in EN: Last known status )
   */
  public get sdWorxProvisioningMessageStatusLastKnownStatus(): string { return this.getStringDef(this.key_sdWorxProvisioningMessageStatusLastKnownStatus, "Last known status"); }

  /**
   * sdWorxProvisioningMessageStatusDate from SDWorx.csv (in EN: Status date )
   */
  public key_sdWorxProvisioningMessageStatusDate = "sdWorxProvisioningMessageStatusDate";
  /**
   * sdWorxProvisioningMessageStatusDate from SDWorx.csv (in EN: Status date )
   */
  public get sdWorxProvisioningMessageStatusDate(): string { return this.getStringDef(this.key_sdWorxProvisioningMessageStatusDate, "Status date"); }

  /**
   * messageFilterStartDate from SDWorx.csv (in EN: Start date )
   */
  public key_messageFilterStartDate = "messageFilterStartDate";
  /**
   * messageFilterStartDate from SDWorx.csv (in EN: Start date )
   */
  public get messageFilterStartDate(): string { return this.getStringDef(this.key_messageFilterStartDate, "Start date"); }

  /**
   * messageFilterEndDate from SDWorx.csv (in EN: End date )
   */
  public key_messageFilterEndDate = "messageFilterEndDate";
  /**
   * messageFilterEndDate from SDWorx.csv (in EN: End date )
   */
  public get messageFilterEndDate(): string { return this.getStringDef(this.key_messageFilterEndDate, "End date"); }

  /**
   * sdWorxProvisioningUsersScheduleActivationQuestion from SDWorx.csv (in EN: Schedule users activation? )
   */
  public key_sdWorxProvisioningUsersScheduleActivationQuestion = "sdWorxProvisioningUsersScheduleActivationQuestion";
  /**
   * sdWorxProvisioningUsersScheduleActivationQuestion from SDWorx.csv (in EN: Schedule users activation? )
   */
  public get sdWorxProvisioningUsersScheduleActivationQuestion(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersScheduleActivationQuestion, "Schedule users activation?"); }

  /**
   * sdWorxProvisioningUsersActivationScheduled from SDWorx.csv (in EN: Users activation queued successfully )
   */
  public key_sdWorxProvisioningUsersActivationScheduled = "sdWorxProvisioningUsersActivationScheduled";
  /**
   * sdWorxProvisioningUsersActivationScheduled from SDWorx.csv (in EN: Users activation queued successfully )
   */
  public get sdWorxProvisioningUsersActivationScheduled(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersActivationScheduled, "Users activation queued successfully"); }

  /**
   * sdWorxProvisioningUsersShortName from SDWorx.csv (in EN: Short name )
   */
  public key_sdWorxProvisioningUsersShortName = "sdWorxProvisioningUsersShortName";
  /**
   * sdWorxProvisioningUsersShortName from SDWorx.csv (in EN: Short name )
   */
  public get sdWorxProvisioningUsersShortName(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersShortName, "Short name"); }

  /**
   * sdWorxProvisioningUsersFirstName from SDWorx.csv (in EN: First name )
   */
  public key_sdWorxProvisioningUsersFirstName = "sdWorxProvisioningUsersFirstName";
  /**
   * sdWorxProvisioningUsersFirstName from SDWorx.csv (in EN: First name )
   */
  public get sdWorxProvisioningUsersFirstName(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersFirstName, "First name"); }

  /**
   * sdWorxProvisioningUsersLastName from SDWorx.csv (in EN: Last name )
   */
  public key_sdWorxProvisioningUsersLastName = "sdWorxProvisioningUsersLastName";
  /**
   * sdWorxProvisioningUsersLastName from SDWorx.csv (in EN: Last name )
   */
  public get sdWorxProvisioningUsersLastName(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersLastName, "Last name"); }

  /**
   * sdWorxProvisioningUsersEmail from SDWorx.csv (in EN: Email )
   */
  public key_sdWorxProvisioningUsersEmail = "sdWorxProvisioningUsersEmail";
  /**
   * sdWorxProvisioningUsersEmail from SDWorx.csv (in EN: Email )
   */
  public get sdWorxProvisioningUsersEmail(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersEmail, "Email"); }

  /**
   * sdWorxProvisioningUsersStartDate from SDWorx.csv (in EN: Start date )
   */
  public key_sdWorxProvisioningUsersStartDate = "sdWorxProvisioningUsersStartDate";
  /**
   * sdWorxProvisioningUsersStartDate from SDWorx.csv (in EN: Start date )
   */
  public get sdWorxProvisioningUsersStartDate(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersStartDate, "Start date"); }

  /**
   * sdWorxProvisioningUsersEndDate from SDWorx.csv (in EN: End date )
   */
  public key_sdWorxProvisioningUsersEndDate = "sdWorxProvisioningUsersEndDate";
  /**
   * sdWorxProvisioningUsersEndDate from SDWorx.csv (in EN: End date )
   */
  public get sdWorxProvisioningUsersEndDate(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersEndDate, "End date"); }

  /**
   * sdWorxProvisioningUsersAddUser from SDWorx.csv (in EN: Add user )
   */
  public key_sdWorxProvisioningUsersAddUser = "sdWorxProvisioningUsersAddUser";
  /**
   * sdWorxProvisioningUsersAddUser from SDWorx.csv (in EN: Add user )
   */
  public get sdWorxProvisioningUsersAddUser(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersAddUser, "Add user"); }

  /**
   * sdWorxProvisioningUsersScheduleActivationButton from SDWorx.csv (in EN: Schedule activation )
   */
  public key_sdWorxProvisioningUsersScheduleActivationButton = "sdWorxProvisioningUsersScheduleActivationButton";
  /**
   * sdWorxProvisioningUsersScheduleActivationButton from SDWorx.csv (in EN: Schedule activation )
   */
  public get sdWorxProvisioningUsersScheduleActivationButton(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersScheduleActivationButton, "Schedule activation"); }

  /**
   * sdWorxProvisioningUsersUserTypeAll from SDWorx.csv (in EN: All )
   */
  public key_sdWorxProvisioningUsersUserTypeAll = "sdWorxProvisioningUsersUserTypeAll";
  /**
   * sdWorxProvisioningUsersUserTypeAll from SDWorx.csv (in EN: All )
   */
  public get sdWorxProvisioningUsersUserTypeAll(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersUserTypeAll, "All"); }

  /**
   * sdWorxProvisioningUsersUserTypeUnknown from SDWorx.csv (in EN: Unknown )
   */
  public key_sdWorxProvisioningUsersUserTypeUnknown = "sdWorxProvisioningUsersUserTypeUnknown";
  /**
   * sdWorxProvisioningUsersUserTypeUnknown from SDWorx.csv (in EN: Unknown )
   */
  public get sdWorxProvisioningUsersUserTypeUnknown(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersUserTypeUnknown, "Unknown"); }

  /**
   * sdWorxProvisioningUsersUserTypeUser from SDWorx.csv (in EN: User )
   */
  public key_sdWorxProvisioningUsersUserTypeUser = "sdWorxProvisioningUsersUserTypeUser";
  /**
   * sdWorxProvisioningUsersUserTypeUser from SDWorx.csv (in EN: User )
   */
  public get sdWorxProvisioningUsersUserTypeUser(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersUserTypeUser, "User"); }

  /**
   * sdWorxProvisioningUsersUserTypeEmployee from SDWorx.csv (in EN: Employee )
   */
  public key_sdWorxProvisioningUsersUserTypeEmployee = "sdWorxProvisioningUsersUserTypeEmployee";
  /**
   * sdWorxProvisioningUsersUserTypeEmployee from SDWorx.csv (in EN: Employee )
   */
  public get sdWorxProvisioningUsersUserTypeEmployee(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersUserTypeEmployee, "Employee"); }

  /**
   * sdWorxProvisioningUsersUserType from SDWorx.csv (in EN: Type )
   */
  public key_sdWorxProvisioningUsersUserType = "sdWorxProvisioningUsersUserType";
  /**
   * sdWorxProvisioningUsersUserType from SDWorx.csv (in EN: Type )
   */
  public get sdWorxProvisioningUsersUserType(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersUserType, "Type"); }

  /**
   * sdWorxProvisioningUsersSelectUserTypeLabel from SDWorx.csv (in EN: Show data for: )
   */
  public key_sdWorxProvisioningUsersSelectUserTypeLabel = "sdWorxProvisioningUsersSelectUserTypeLabel";
  /**
   * sdWorxProvisioningUsersSelectUserTypeLabel from SDWorx.csv (in EN: Show data for: )
   */
  public get sdWorxProvisioningUsersSelectUserTypeLabel(): string { return this.getStringDef(this.key_sdWorxProvisioningUsersSelectUserTypeLabel, "Show data for:"); }

  /**
   * sdWorxProvisioningMessageStatusRefreshScheduled from SDWorx.csv (in EN: Messages status request queued successfully )
   */
  public key_sdWorxProvisioningMessageStatusRefreshScheduled = "sdWorxProvisioningMessageStatusRefreshScheduled";
  /**
   * sdWorxProvisioningMessageStatusRefreshScheduled from SDWorx.csv (in EN: Messages status request queued successfully )
   */
  public get sdWorxProvisioningMessageStatusRefreshScheduled(): string { return this.getStringDef(this.key_sdWorxProvisioningMessageStatusRefreshScheduled, "Messages status request queued successfully"); }

  /**
   * sdWorxProvisioningMessageStatusRefreshButton from SDWorx.csv (in EN: Schedule status refresh )
   */
  public key_sdWorxProvisioningMessageStatusRefreshButton = "sdWorxProvisioningMessageStatusRefreshButton";
  /**
   * sdWorxProvisioningMessageStatusRefreshButton from SDWorx.csv (in EN: Schedule status refresh )
   */
  public get sdWorxProvisioningMessageStatusRefreshButton(): string { return this.getStringDef(this.key_sdWorxProvisioningMessageStatusRefreshButton, "Schedule status refresh"); }


}
