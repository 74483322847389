import { PlLanguageService } from '../../../../../angular-common';
import { Injectable } from '@angular/core';
import { WebLanguage } from '../../../../../common/language/weblanguage.service'

@Injectable()
//t4Languages": "0.8.2.0
export class AdaptLanguage extends WebLanguage {
  public constructor(plLanguageService: PlLanguageService) {
    super(plLanguageService);
  }

  /**
   * moduleAdaptTitle from f3Adapt.csv (in EN: Imagine adapt )
   */
  public key_moduleAdaptTitle = "moduleAdaptTitle";
  /**
   * moduleAdaptTitle from f3Adapt.csv (in EN: Imagine adapt )
   */
  public get moduleAdaptTitle(): string { return this.getStringDef(this.key_moduleAdaptTitle, "Imagine adapt"); }

  /**
   * applicationSubTitle from f3Adapt.csv (in EN: part of the Pointlogic HR Imagine suite )
   */
  public key_applicationSubTitle = "applicationSubTitle";
  /**
   * applicationSubTitle from f3Adapt.csv (in EN: part of the Pointlogic HR Imagine suite )
   */
  public get applicationSubTitle(): string { return this.getStringDef(this.key_applicationSubTitle, "part of the Pointlogic HR Imagine suite"); }

  /**
   * menuMutations from f3Adapt.csv (in EN: Mutations )
   */
  public key_menuMutations = "menuMutations";
  /**
   * menuMutations from f3Adapt.csv (in EN: Mutations )
   */
  public get menuMutations(): string { return this.getStringDef(this.key_menuMutations, "Mutations"); }

  /**
   * menuFilters from f3Adapt.csv (in EN: Filters )
   */
  public key_menuFilters = "menuFilters";
  /**
   * menuFilters from f3Adapt.csv (in EN: Filters )
   */
  public get menuFilters(): string { return this.getStringDef(this.key_menuFilters, "Filters"); }

  /**
   * norightsTitle from f3Adapt.csv (in EN: No rights )
   */
  public key_norightsTitle = "norightsTitle";
  /**
   * norightsTitle from f3Adapt.csv (in EN: No rights )
   */
  public get norightsTitle(): string { return this.getStringDef(this.key_norightsTitle, "No rights"); }

  /**
   * helpInfo from f3Adapt.csv (in EN: Info )
   */
  public key_helpInfo = "helpInfo";
  /**
   * helpInfo from f3Adapt.csv (in EN: Info )
   */
  public get helpInfo(): string { return this.getStringDef(this.key_helpInfo, "Info"); }

  /**
   * loginInfo from f3Adapt.csv (in EN: Welcome to F3 me, the application, with which you can create your requests for individual choices. By trading labour conditions (sources) for other (goals)  you choose for your own customized benefits package! )
   */
  public key_loginInfo = "loginInfo";
  /**
   * loginInfo from f3Adapt.csv (in EN: Welcome to F3 me, the application, with which you can create your requests for individual choices. By trading labour conditions (sources) for other (goals)  you choose for your own customized benefits package! )
   */
  public get loginInfo(): string { return this.getStringDef(this.key_loginInfo, "Welcome to F3 me, the application, with which you can create your requests for individual choices. By trading labour conditions (sources) for other (goals)  you choose for your own customized benefits package!"); }

  /**
   * mutationsReporttitle from f3Adapt.csv (in EN: Edit mutations and vacancies )
   */
  public key_mutationsReporttitle = "mutationsReporttitle";
  /**
   * mutationsReporttitle from f3Adapt.csv (in EN: Edit mutations and vacancies )
   */
  public get mutationsReporttitle(): string { return this.getStringDef(this.key_mutationsReporttitle, "Edit mutations and vacancies"); }

  /**
   * filtermanagementReporttitle from f3Adapt.csv (in EN: Edit filters )
   */
  public key_filtermanagementReporttitle = "filtermanagementReporttitle";
  /**
   * filtermanagementReporttitle from f3Adapt.csv (in EN: Edit filters )
   */
  public get filtermanagementReporttitle(): string { return this.getStringDef(this.key_filtermanagementReporttitle, "Edit filters"); }

  /**
   * filtermanagementSelectRole from f3Adapt.csv (in EN: Select role: )
   */
  public key_filtermanagementSelectRole = "filtermanagementSelectRole";
  /**
   * filtermanagementSelectRole from f3Adapt.csv (in EN: Select role: )
   */
  public get filtermanagementSelectRole(): string { return this.getStringDef(this.key_filtermanagementSelectRole, "Select role:"); }

  /**
   * filtermanagementSelectcombinetype from f3Adapt.csv (in EN: Select combine type: )
   */
  public key_filtermanagementSelectcombinetype = "filtermanagementSelectcombinetype";
  /**
   * filtermanagementSelectcombinetype from f3Adapt.csv (in EN: Select combine type: )
   */
  public get filtermanagementSelectcombinetype(): string { return this.getStringDef(this.key_filtermanagementSelectcombinetype, "Select combine type:"); }

  /**
   * menuDataMutations from f3Adapt.csv (in EN: Mutations )
   */
  public key_menuDataMutations = "menuDataMutations";
  /**
   * menuDataMutations from f3Adapt.csv (in EN: Mutations )
   */
  public get menuDataMutations(): string { return this.getStringDef(this.key_menuDataMutations, "Mutations"); }

  /**
   * menuAdaptGroups from f3Adapt.csv (in EN: Configure data mutations )
   */
  public key_menuAdaptGroups = "menuAdaptGroups";
  /**
   * menuAdaptGroups from f3Adapt.csv (in EN: Configure data mutations )
   */
  public get menuAdaptGroups(): string { return this.getStringDef(this.key_menuAdaptGroups, "Configure data mutations"); }

  /**
   * dataMutationsMutations from f3Adapt.csv (in EN: Mutations: )
   */
  public key_dataMutationsMutations = "dataMutationsMutations";
  /**
   * dataMutationsMutations from f3Adapt.csv (in EN: Mutations: )
   */
  public get dataMutationsMutations(): string { return this.getStringDef(this.key_dataMutationsMutations, "Mutations:"); }

  /**
   * dataMutationsActionsDelete from f3Adapt.csv (in EN: Delete )
   */
  public key_dataMutationsActionsDelete = "dataMutationsActionsDelete";
  /**
   * dataMutationsActionsDelete from f3Adapt.csv (in EN: Delete )
   */
  public get dataMutationsActionsDelete(): string { return this.getStringDef(this.key_dataMutationsActionsDelete, "Delete"); }

  /**
   * dataMutationsActionsUndelete from f3Adapt.csv (in EN: Undelete )
   */
  public key_dataMutationsActionsUndelete = "dataMutationsActionsUndelete";
  /**
   * dataMutationsActionsUndelete from f3Adapt.csv (in EN: Undelete )
   */
  public get dataMutationsActionsUndelete(): string { return this.getStringDef(this.key_dataMutationsActionsUndelete, "Undelete"); }

  /**
   * dataMutationsActionsCopy from f3Adapt.csv (in EN: Copy )
   */
  public key_dataMutationsActionsCopy = "dataMutationsActionsCopy";
  /**
   * dataMutationsActionsCopy from f3Adapt.csv (in EN: Copy )
   */
  public get dataMutationsActionsCopy(): string { return this.getStringDef(this.key_dataMutationsActionsCopy, "Copy"); }

  /**
   * dataMutationsActionsAddRecord from f3Adapt.csv (in EN: Add record )
   */
  public key_dataMutationsActionsAddRecord = "dataMutationsActionsAddRecord";
  /**
   * dataMutationsActionsAddRecord from f3Adapt.csv (in EN: Add record )
   */
  public get dataMutationsActionsAddRecord(): string { return this.getStringDef(this.key_dataMutationsActionsAddRecord, "Add record"); }

  /**
   * dataMutationsActionsAddMutation from f3Adapt.csv (in EN: Add mutation )
   */
  public key_dataMutationsActionsAddMutation = "dataMutationsActionsAddMutation";
  /**
   * dataMutationsActionsAddMutation from f3Adapt.csv (in EN: Add mutation )
   */
  public get dataMutationsActionsAddMutation(): string { return this.getStringDef(this.key_dataMutationsActionsAddMutation, "Add mutation"); }

  /**
   * searchPlaceholder from f3Adapt.csv (in EN: Search... )
   */
  public key_searchPlaceholder = "searchPlaceholder";
  /**
   * searchPlaceholder from f3Adapt.csv (in EN: Search... )
   */
  public get searchPlaceholder(): string { return this.getStringDef(this.key_searchPlaceholder, "Search..."); }

  /**
   * AuthorizationFilterDisable from f3Adapt.csv (in EN: Authorization filter(s) {0} are not available on the dataset. )
   */
  public key_AuthorizationFilterDisable = "AuthorizationFilterDisable";
  /**
   * AuthorizationFilterDisable from f3Adapt.csv (in EN: Authorization filter(s) {0} are not available on the dataset. )
   */
  public AuthorizationFilterDisable(val1): string { return this.getStringDef("AuthorizationFilterDisable", "Authorization filter(s) {0} are not available on the dataset.").replace("{0}", val1); }

  /**
   * copyRow from f3Adapt.csv (in EN: Copy )
   */
  public key_copyRow = "copyRow";
  /**
   * copyRow from f3Adapt.csv (in EN: Copy )
   */
  public get copyRow(): string { return this.getStringDef(this.key_copyRow, "Copy"); }

  /**
   * deleteRow from f3Adapt.csv (in EN: Delete )
   */
  public key_deleteRow = "deleteRow";
  /**
   * deleteRow from f3Adapt.csv (in EN: Delete )
   */
  public get deleteRow(): string { return this.getStringDef(this.key_deleteRow, "Delete"); }

  /**
   * undeleteRow from f3Adapt.csv (in EN: Undelete )
   */
  public key_undeleteRow = "undeleteRow";
  /**
   * undeleteRow from f3Adapt.csv (in EN: Undelete )
   */
  public get undeleteRow(): string { return this.getStringDef(this.key_undeleteRow, "Undelete"); }

  /**
   * addMutation from f3Adapt.csv (in EN: Add mutation )
   */
  public key_addMutation = "addMutation";
  /**
   * addMutation from f3Adapt.csv (in EN: Add mutation )
   */
  public get addMutation(): string { return this.getStringDef(this.key_addMutation, "Add mutation"); }

  /**
   * saveAllChanges from f3Adapt.csv (in EN: Save changes )
   */
  public key_saveAllChanges = "saveAllChanges";
  /**
   * saveAllChanges from f3Adapt.csv (in EN: Save changes )
   */
  public get saveAllChanges(): string { return this.getStringDef(this.key_saveAllChanges, "Save changes"); }

  /**
   * cancelAllChanges from f3Adapt.csv (in EN: Cancel changes )
   */
  public key_cancelAllChanges = "cancelAllChanges";
  /**
   * cancelAllChanges from f3Adapt.csv (in EN: Cancel changes )
   */
  public get cancelAllChanges(): string { return this.getStringDef(this.key_cancelAllChanges, "Cancel changes"); }

  /**
   * mutations from f3Adapt.csv (in EN: Mutations: )
   */
  public key_mutations = "mutations";
  /**
   * mutations from f3Adapt.csv (in EN: Mutations: )
   */
  public get mutations(): string { return this.getStringDef(this.key_mutations, "Mutations:"); }

  /**
   * FilterGridExogenous from f3Adapt.csv (in EN: Exogenous )
   */
  public key_FilterGridExogenous = "FilterGridExogenous";
  /**
   * FilterGridExogenous from f3Adapt.csv (in EN: Exogenous )
   */
  public get FilterGridExogenous(): string { return this.getStringDef(this.key_FilterGridExogenous, "Exogenous"); }

  /**
   * FilterGridCompareType from f3Adapt.csv (in EN: Compare )
   */
  public key_FilterGridCompareType = "FilterGridCompareType";
  /**
   * FilterGridCompareType from f3Adapt.csv (in EN: Compare )
   */
  public get FilterGridCompareType(): string { return this.getStringDef(this.key_FilterGridCompareType, "Compare"); }

  /**
   * FilterGridValue from f3Adapt.csv (in EN: Value )
   */
  public key_FilterGridValue = "FilterGridValue";
  /**
   * FilterGridValue from f3Adapt.csv (in EN: Value )
   */
  public get FilterGridValue(): string { return this.getStringDef(this.key_FilterGridValue, "Value"); }

  /**
   * modelgroupSelectModel from f3Adapt.csv (in EN: Select model: )
   */
  public key_modelgroupSelectModel = "modelgroupSelectModel";
  /**
   * modelgroupSelectModel from f3Adapt.csv (in EN: Select model: )
   */
  public get modelgroupSelectModel(): string { return this.getStringDef(this.key_modelgroupSelectModel, "Select model:"); }


}
