import { Component, EventEmitter, Input, Output } from "@angular/core";

import { DataFilter } from "../../../services/filter";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";

@Component({
  selector: "app-filtersummary",
  templateUrl: "./filtersummary.component.html",
  styleUrls: ["./filtersummary.component.scss"],
})
export class FilterSummaryComponent {
  public _filterPopupVisible = false;
  public filterCopy: DataFilter;

  @Output()
  public filtersRemoved = new EventEmitter<boolean>(true);
  @Output()
  public filtersChanged = new EventEmitter<boolean>(true);

  public constructor(public language: ImagineLanguage) {}

  @Input()
  public filter: DataFilter;

  public get filterPopupVisible() {
    return this._filterPopupVisible;
  }

  public set filterPopupVisible(value: boolean) {
    if (value === true) {
      this.filterCopy = this.filter.createCopy(this.filter);
      this._filterPopupVisible = true;
    } else {
      this.filter.assignFrom(this.filterCopy);
      this._filterPopupVisible = false;
    }
  }

  public changeFilter() {
    this.filterCopy.assignFrom(this.filter);
    this.filterPopupVisible = false;
    this.filtersChanged.emit(true);
  }

  public removeFilter(event) {
    this.filter.options.deactivateOptions();
    this.filtersRemoved.emit(true);
  }

  public undo() {
    this.filter.assignFrom(this.filterCopy);
    this.filterPopupVisible = false;
  }

  toggleFilterPopup(event) {
    if (event !== null) {
      if (event.element !== undefined) {
        this.popupPosition = {
          my: "left top",
          at: "left bottom",
          of: event.element,
          offset: "0 14",
        };
      }
      if (event.currentTarget !== undefined) {
        this.popupPosition = {
          my: "left top",
          at: "left bottom",
          of: event.currentTarget,
          offset: "0 14",
        };
      }
    }

    this.filterPopupVisible = !this.filterPopupVisible;
  }

  // We have multiple add buttons on the screen so the position of the popup needs to be computed at runtime:
  public popupPosition: any;
}
