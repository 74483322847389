import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { PlUserStorage } from "../../../../../angular-common/baseservice/pluserstorage";
import { AnalysisRoleNamesDto } from "../../analysis/dto/AnalysisRoleNames-dto";
import { ClusterNamingDto } from "../../analysis/dto/ClusterNaming-dto";
import { ImagineFeaturesModel } from "../../imagine-features-model";
import { FilterListService } from "../../services/filter/filter-list-service";
import { DataFilterList } from "../../services/filter/models/datafilter-list";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit, OnDestroy {
  private _filterPopupVisible = false;
  public maxFilterAmount = 4;
  public checkboxShowAll = false;
  public filterList: DataFilterList;

  private preferredModelChangedSubscription: Subscription = null;

  public get variantInformations() {
    return this.filterListService.variants;
  }

  constructor(public language: ImagineLanguage, private filterListService: FilterListService, private featureSettings: ImagineFeaturesModel) {
    this.filterList = filterListService.filterList;
  }

  ngOnInit(): void {
    this.subscribeToPreferredModelChanges();
  }

  ngOnDestroy(): void {
    if (this.preferredModelChangedSubscription) {
      this.preferredModelChangedSubscription.unsubscribe();
    }
  }

  private subscribeToPreferredModelChanges() {
    this.preferredModelChangedSubscription = PlUserStorage.preferredModelChanged.subscribe((hasPreferredModelChanged) => {
      if (hasPreferredModelChanged === true) {
        this.filterListService.reloadFiltersFromApi();
      }
    });
  }

  public get allowComputeState() {
    return this.featureSettings.isAnalysisComputeEnabled();
  }

  public get userIsDashletDesigner() {
    return PlUserStorage.hasRole(AnalysisRoleNamesDto.DataAnalysisDesign);
  }

  public get defaultPeriodFilterEnabled(): boolean {
    const isEnabled = this.featureSettings.data?.Analysis?.defaultPeriodFilterEnabled;

    if (isEnabled === undefined) {
      return true;
    }

    return isEnabled;
  }

  public get hasData() {
    return this.filterList && this.filterList.filtersBySortOrder && this.filterList.filtersBySortOrder.length > 0;
  }

  public set filterPopupVisible(value: boolean) {
    this._filterPopupVisible = value;
  }

  public get filterPopupVisible() {
    return this._filterPopupVisible;
  }

  public get showAllFilterText() {
    if (this.checkboxShowAll) {
      return this.language.clusteringShowAll;
    } else {
      return this.language.clusteringShowAll + " (" + this.filtersListToShow.length + " / " + this.filterList.filtersBySortOrder.length + ")";
    }
  }

  public get filtersListToShow() {
    if (this.checkboxShowAll) {
      return this.filtersForEdit.filtersBySortOrder;
    } else {
      return this.filtersForEdit.filtersBySortOrder.filter((f) => f.isAnalysisCluster);
    }
  }
  private filtersForEdit: DataFilterList;

  public toggleFilterPopup() {
    let newShowValue = !this.filterPopupVisible;

    if (newShowValue) {
      this.checkboxShowAll = this.filterList.filtersBySortOrder.filter((f) => f.isAnalysisCluster && f.shortName !== ClusterNamingDto.CumalativePeridod).length == 0;
      this.filtersForEdit = DataFilterList.createCopy(this.filterList);
    } else {
      this.filtersForEdit = undefined;
    }

    this.filterPopupVisible = newShowValue;
  }

  public applyAllFilters() {
    this.filterList.filtersBySortOrder.forEach((f) => {
      const match = this.filtersForEdit.filtersBySortOrder.find((x) => x.shortName === f.shortName);
      f.assignFrom(match);
    });
    this.applyFilters();
  }

  public filtersRemoved() {
    this.filterList.apply();
  }

  public undo() {
    this.filterPopupVisible = false;
  }

  public getAmountOfActiveFilters() {
    const activefilters = this.filterList.filtersBySortOrder.filter((x) => x.hasSelectedOptions);
    return activefilters.length;
  }

  public threshHoldReached() {
    return this.getAmountOfActiveFilters() > this.maxFilterAmount;
  }

  public getFirstFilters() {
    if (this.getAmountOfActiveFilters() > this.maxFilterAmount) {
      const activefilters = this.filterList.filtersBySortOrder.filter((x) => x.hasSelectedOptions);

      return activefilters.slice(0, this.maxFilterAmount);
    } else {
      return this.filterList.filtersBySortOrder.filter((x) => x.hasSelectedOptions);
    }
  }

  public applyFilters() {
    this.filterPopupVisible = false;
    this.filterList.apply();
  }

  public get datePeriod(): string {
    if (this.filterList === undefined) {
      return "";
    } else {
      return this.filterList.periodSelectionText;
    }
  }
}
