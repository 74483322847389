import { SelectableItemsBase } from "../..";
import { RadioGroupItem } from "./radiogroup-item";

export class RadioGroupItems extends SelectableItemsBase<RadioGroupItem> {
  public addNew(id: number, text: string, icon: string): RadioGroupItem {
    const result = new RadioGroupItem(id, text);
    result.icon = icon;
    result.hint = text;
    this.addItem(result);
    return result;
  }
}
