import { Component, Input, OnInit } from "@angular/core";
import { DashboardDefinition, DashboardDefinitionList } from "../../services/dashboard";

import { ActivatedRoute } from "@angular/router";
import { DashboardingRoutesDto } from "../../analysis/dto/DashboardingRoutes-dto";
import { DataFilterList } from "../../services/filter";
import { RouteNavigation } from "../../../../../angular-common/baseservice/route-navigation";
import { Tabs } from "../../../../../angular-common";

@Component({
  selector: "app-dashboarding-tabs",
  templateUrl: "./dashboarding-tabs.component.html",
  styleUrls: ["./dashboarding-tabs.component.scss"],
})
export class DashboardingTabsComponent implements OnInit {
  @Input() dashboardTabs: DashboardDefinitionList;

  public selectedDashboardDefinition: DashboardDefinition;
  public tabs: Tabs;

  public constructor(private routeNavigation: RouteNavigation, private route: ActivatedRoute) {}

  ngOnInit() {
    const allTabs = new Tabs();
    this.dashboardTabs.dashboards.forEach((dashboard, index) => {
      allTabs.addNew(dashboard.id, dashboard.name, "spinup", dashboard);
    });

    if (allTabs.items.length > 0) {
      allTabs.selectedTab = allTabs.items[0];
    }
    this.tabs = allTabs;

    this.route.params.subscribe((params) => {
      if ("id" in params) {
        const requestedId = params["id"];
        for (let i = 0; i < this.dashboardTabs.dashboards.length; i++) {
          const dashb = this.dashboardTabs.dashboards[i];
          if (dashb.id === requestedId || dashb.id.toString() === requestedId) {
            this.selectedDashboardDefinition = dashb;
            this.tabs.selectedTab = allTabs.items[i];
          }
        }
      }
      if (this.selectedDashboardDefinition === undefined) {
        console.log("Current dasboard id was not set: falling back to first");
        this.selectedDashboardDefinition = this.dashboardTabs.dashboards[0];
      }
    });
  }

  selectTab(e) {
    const dashboardId = this.dashboardTabs.dashboards[e.itemIndex] ? this.dashboardTabs.dashboards[e.itemIndex].id : null;
    if (dashboardId !== (this.selectedDashboardDefinition ? this.selectedDashboardDefinition.id : -1)) {
      // Pass along the id if available
      this.routeNavigation.navigateWithParameterId(DashboardingRoutesDto.DashboardingScreen, dashboardId);
    }
  }

  @Input()
  public filterList: DataFilterList;
}
