import { Component, OnInit } from "@angular/core";
import { ModelElement } from "../../../../common/modelelements";
import { VariantInformationDto } from "../../../../common/models/dto/VariantInformation-dto";
import { ModelsGroup } from "../adapt/models/modelgroups-group.model";
import { ModelGrouping } from "../adapt/models/modelgroups-grouping.model";
import { AdaptLanguage } from "../services/language/adaptlanguage.service";
import { AdaptGroupsEditService } from "./adapt-groups-edit-service";

@Component({
  selector: "app-modelsgroups-screen",
  templateUrl: "./modelgroups-edit-screen.component.html",
  styleUrls: ["./modelgroups-edit-screen.component.scss"],
})
export class ModelsGroupsEditScreenComponent implements OnInit {
  public groups: ModelsGroup[];
  public modelElements: ModelElement[];

  public variants: VariantInformationDto[];

  public modelGrouping: ModelGrouping;

  private datasetName: string = "";

  constructor(public language: AdaptLanguage, private modelGroupEditService: AdaptGroupsEditService) {
    this.groups = [];
    this.variants = [];
  }

  public get datasetUsedText() {
    return this.language.VariantDataSetUsed(this.datasetName);
  }

  public selectedGroup: ModelsGroup;

  public onGroupSelected(newSelectedGroup: ModelsGroup) {
    this.selectedGroup = newSelectedGroup;
  }

  ngOnInit() {
    this.modelGroupEditService.getModelGroups().subscribe((m) => {
      this.createModelGroups(m);
      this.createModelElements(m);
      this.modelGrouping = m;
      this.variants = m.Variants;
      this.datasetName = m.dataSetName;
    });
  }
  createModelElements(m: ModelGrouping) {
    this.modelElements = m.modelElements;
  }

  private createModelGroups(m: ModelGrouping) {
    this.groups = m.groupList;
  }
}
