import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { FileService } from "../file";
import { HttpClient } from "@angular/common/http";
import { ImportDefinition } from "./models/import-definition.model";
import { ImportDefinitionList } from "./models/import-definition-list.model";
import { ImportSpecificationDto } from "../../import/dto/ImportSpecificationDto-dto";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ImportService extends PlBaseService {
  private endPoint: string = "datasupply";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator, public fileService: FileService) {
    super(http, envSettings, loadIndicator);
  }

  public getImport(keyId: number): Observable<ImportDefinition> {
    // const route = this.endPoint + "/" + keyId;
    // const result = this.getDataTransformed<ImportSpecificationDto>(route).pipe(map((r) => this.transformDefinition(r)));
    // return result;

    return this.getDataTransformed<ImportSpecificationDto[]>(this.endPoint).pipe(map((r) => this.transformDefinitions(r).imports.find((x) => x.id === keyId)));
  }

  public getImports(): Observable<ImportDefinitionList> {
    const result = this.getDataTransformed<ImportSpecificationDto[]>(this.endPoint).pipe(map((r) => this.transformDefinitions(r)));
    return result;
  }

  private transformDefinitions(dto: ImportSpecificationDto[]): ImportDefinitionList {
    if (dto) {
      const result = new ImportDefinitionList();
      result.copyFromDTO(dto);
      return result;
    } else {
      return null;
    }
  }

  // private transformDefinition(dto: ImportSpecificationDto): ImportDefinition {
  //   if (dto) {
  //     const result = new ImportDefinition();
  //     result.copyFrom(dto);
  //     return result;
  //   } else {
  //     return null;
  //   }
  // }

  private extractImportDefinition(entryCreated: EntryCreated<ImportSpecificationDto>): ImportDefinition {
    const dto = entryCreated.data;
    if (dto) {
      const result = new ImportDefinition();
      result.copyFrom(dto);
      return result;
    } else {
      return null;
    }
  }

  public saveExistingImportDefinition(dto: ImportSpecificationDto): Observable<void> {
    const url = this.endPoint + "/" + dto.Id;
    return this.update(dto, url);
  }

  public saveNewImportDefinition(dto: ImportSpecificationDto): Observable<ImportDefinition> {
    return this.createDataTransformed<ImportSpecificationDto>(this.endPoint, dto).pipe(map((r) => this.extractImportDefinition(r)));
  }

  public deleteImportDefinition(importDefinition: ImportDefinition): Observable<void> {
    const url = this.endPoint + "/" + importDefinition.id;
    return this.remove(url);
  }
}
