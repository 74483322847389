import { Component, OnInit } from "@angular/core";
import {
  DashletDefinition,
  DashletDefinitionList,
  DashletDefinitionMetaData,
  DashletDefinitionService,
} from "../../services/dashletdefinition";
import { DashletDefinitions } from "../../services/dashletdefinition";

@Component({
  selector: "app-dashlet-definition-design",
  templateUrl: "./dashlet-definition-design.component.html",
  styleUrls: ["./dashlet-definition-design.component.scss"],
})
export class DashletDefinitionDesignComponent implements OnInit {
  constructor(private dashletDefinitionService: DashletDefinitionService) {}

  ngOnInit() {
    this.dashletDefinitionService
      .getDashletDefinitions()
      .subscribe(definitions => {
        this.definitionData = definitions;
      });
  }

  public definitionData: DashletDefinitions;

  public get list(): DashletDefinitionList {
    if (this.definitionData) {
      return this.definitionData.list;
    }
    return undefined;
  }

  public get metaData(): DashletDefinitionMetaData {
    if (this.definitionData) {
      return this.definitionData.metaData;
    }
    return undefined;
  }

  public selectedDashletDefinitions: DashletDefinition[];

  public onSelectionChanged(dashletDefinitions: DashletDefinition[]) {
    this.selectedDashletDefinitions = dashletDefinitions;
  }
}
