import { DataMutationsClientDataDto } from "../../../adapt/dto/DataMutationsClientData-dto";
import { ClientDataRow } from "./client-data-row.model";

export class DataMutationsClientData {
  public constructor() {}

  public copyFromDto(dto: DataMutationsClientDataDto) {
    const newRows: ClientDataRow[] = [];
    dto.Rows.forEach(r => {
      const newRow = new ClientDataRow();
      newRow.copyFromDto(r);
      newRows.push(newRow);
    });

    this.rows = newRows;
  }

  public rows: ClientDataRow[] = [];
}
