
// t4tsCodeFromCs": "0.8.2.0 for file ProcessingMessageState.cs
// Don't adjust manually!

export class ProcessingMessageStateDto {
    public Blocked: boolean;
    public Present: boolean;
    public TaskId: number;
    public MessageId: number;
}

