<app-cardview *ngIf="!noData">
  <div class="inner-container fix-height-l">
    <dx-pie-chart
      id="{{ divId }}"
      [palette]="palettes.getPaletteByName(dashletView.paletteName).colors"
      id="pie"
      [title]=""
      [dataSource]="seriesData"
      (onPointClick)="onPointClick($event)"
      (onLegendClick)="legendClickHandler($event)"
      redrawOnResize="true"
      pointSelectionMode="multiple"
    >
      <dxo-title [text]="dashletView.name" horizontalAlignment="left">
        <dxo-font size="22px"></dxo-font>
      </dxo-title>
      <dxi-series argumentField="clusterName" valueField="value1" tagField="context" [name]="dataInspection.variant1Name"></dxi-series>
      <dxi-series argumentField="clusterName" valueField="value2" tagField="context" [name]="dataInspection.variant2Name" *ngIf="dataInspection.isVariantComparison"></dxi-series>
      <dxo-size width="auto" height="350"></dxo-size>
      <dxo-legend [visible]="true" horizontalAlignment="left" verticalAlignment="top"></dxo-legend>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"> </dxo-tooltip>
      <dxo-common-series-settings type="doughnut"></dxo-common-series-settings>
    </dx-pie-chart>
    <dx-context-menu [dataSource]="menuItems" [width]="200" target="#{{ divId }}" (onItemClick)="contextMenuClick($event)"> </dx-context-menu>
  </div>
  <app-dashlet-warning [dashletView]="dashletView"></app-dashlet-warning>
</app-cardview>
