import { Component, OnInit } from "@angular/core";

import { WizardResult } from "../../../../angular-common/components/configwizard/models/wizard-result";
import { ToasterInput } from "../../../../angular-common/components/toaster/toaster-input-model";
import { IntegrationServiceLanguage } from "../../../../common/language/integrationservicelanguage.service";
import { ConnectorSpecificationDto } from "../../../../common/models/integrationservices/dto/ConnectorSpecification-dto";
import { EventTimerSpecificationSourceDto } from "../../../../common/models/integrationservices/dto/EventTimerSpecificationSource-dto";
import { ImagineFeaturesModel } from "../imagine-features-model";
import { EventTimerMetaDataService } from "./event-timer-meta-data.service";
import { EventTimerService } from "./event-timer.service";
import { EventTimerInformation } from "./models/event-timer-information";
import { EventTimerWizard } from "./wizard/event-timer-wizard";

@Component({
  selector: "app-event-timer-screen",
  templateUrl: "event-timer-screen.component.html",
  styleUrls: ["event-timer-screen.component.scss"],
})
export class EventTimerScreenComponent implements OnInit {
  constructor(
    private eventTimerService: EventTimerService,
    public language: IntegrationServiceLanguage,
    private eventTimerMetaDataService: EventTimerMetaDataService,
    private featureSettings: ImagineFeaturesModel,
  ) {
    this.toasterInput = new ToasterInput();
  }
  public events: EventTimerInformation[] = [];
  private eventSources: EventTimerSpecificationSourceDto[] = [];
  private connectors: ConnectorSpecificationDto[] = [];

  public showWizard: boolean = false;
  public popupTitle: string = "title of popup";
  public wizardData: EventTimerWizard;

  public toasterInput: ToasterInput;

  ngOnInit() {
    this.retrieveMetaData();
    this.retrieveEvents();
  }

  retrieveMetaData() {
    this.eventTimerMetaDataService.retrieveMetaData().subscribe((r) => {
      this.eventSources = r.Sources;
      this.connectors = r.Connectors;
    });
  }

  public newEvent() {
    const data = new EventTimerWizard("EventWizard", this.language, this.eventSources, this.connectors, this.featureSettings);
    this.showWizardInfo(data);
  }

  public readResults(result: WizardResult) {
    let isNew = false;
    let event: EventTimerInformation;
    if (this.wizardData.keyId && this.wizardData.keyId > -1) {
      event = this.events.find((x) => x.keyId === this.wizardData.keyId);
    }
    if (event === undefined) {
      isNew = true;
      event = new EventTimerInformation(this.language, this.featureSettings);
    }

    this.wizardData.readValuesInto(event);

    this.eventTimerService.saveEventTimer(event).subscribe((r) => {
      if (isNew) {
        this.events.push(r);
      } else {
        this.findAndUpdateEventData(r);
      }
      this.showWizard = false;
    });
  }

  private findAndUpdateEventData(eventTimerInformation: EventTimerInformation) {
    const updatedEventIndex = this.events.findIndex((e) => e.keyId === eventTimerInformation.keyId);

    if (updatedEventIndex > -1) {
      this.events[updatedEventIndex] = eventTimerInformation;
    }
  }

  public deleteEvent(event: EventTimerInformation) {
    const index = this.events.findIndex((x) => x.keyId === event.keyId);
    if (index !== undefined) {
      this.events.splice(index, 1);
    }
  }

  public editEvent(event: EventTimerInformation) {
    const data = new EventTimerWizard(this.language.eventTimerWizard_TitleEdit(event.name), this.language, this.eventSources, this.connectors, this.featureSettings);
    data.loadValues(event);
    this.showWizardInfo(data);
  }

  private showWizardInfo(wizard: EventTimerWizard) {
    this.wizardData = wizard;
    this.popupTitle = this.wizardData.title;

    this.showWizard = true;
  }

  public popupClose() {
    this.showWizard = false;
  }

  public showToast(event: { message: string; succes: boolean; eventInformation: EventTimerInformation }) {
    if (event) {
      this.findAndUpdateEventData(event.eventInformation);
      if (event.succes) {
        this.toasterInput.showSucces(event.message);
      } else {
        this.toasterInput.showError(event.message);
      }
    }
  }

  private retrieveEvents() {
    this.eventTimerService.retrieveAllEvents().subscribe((r) => {
      r.forEach((event) => {
        this.events.push(event);
      });
    });
  }
}
