import { Component, Input } from "@angular/core";

import { confirm } from "devextreme/ui/dialog";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import { LongNameShortName } from "../../../../../angular-common/longnameshortname/longname-shortname";
import { WebLanguage } from "../../../../../common/language/weblanguage.service";
import { ModelElementFactory } from "../../../../../common/model/models/model-element-factory";
import { Variant } from "../../../../../common/model/models/variant-model";
import { ComputeVariantDto } from "../../../../../common/models/dto/ComputeVariantDto-dto";
import { VariantCalculationStateTypeDto } from "../../../../../common/models/dto/VariantCalculationStateType-dto";
import { VariantInformationDto } from "../../../../../common/models/dto/VariantInformation-dto";
import { VariantComputeService } from "./variant-compute-service";

@Component({
  selector: "app-variant-information",
  templateUrl: "./variant-info-component.html",
  styleUrls: ["./variant-info-component.scss"],
})
export class VariantInformationComponent {
  constructor(public language: WebLanguage, private variantComputeService: VariantComputeService) {}

  private _variants: VariantInformationDto[] = [];

  public get variants() {
    return this._variants;
  }
  @Input()
  public set variants(value: VariantInformationDto[]) {
    if (value) {
      this._variants = [];
      this.setNewVariantInformation(value);
    }
  }

  @Input()
  public showVariantCalculationInput = true;

  @Input()
  public computeChilderenAvailable = false;

  public get variantCalculationStatusMessage() {
    if (this._variants.length === 0) {
      return this.language.NoVariants;
    }

    if (this._variants.length == 1) {
      return "";
    }

    const calculatedVariantsCount = this._variants.filter((v) => v.State.State === VariantCalculationStateTypeDto.Calculated).length;
    return this.language.VariantCalculationProgressSummary(calculatedVariantsCount, this._variants.length);
  }

  public get variantInfoHint() {
    let text = "";
    if (this.variants.length > 0) {
      this.variants.forEach((v) => {
        if (text.length > 0) {
          text += ", ";
        }
        if (v.Variant && v.State) {
          text += v.Variant.ShortName + ": " + v.State.Description;
        } else {
          console.log("not valid variant info", v);
        }
      });
    } else {
      text = this.language.modelVariant;
    }
    return text;
  }

  public variantCalculationDataConfigurationText(variantInfo: VariantInformationDto) {
    var variant = ModelElementFactory.createVariant(variantInfo.Variant);
    const dsText = LongNameShortName.NameForDto(variantInfo.DataSetDefinition);

    const variantPeriodtext = variant.calculationPeriodConfigurationText(this.language);
    return this.language.VariantCalculationDataConfiguration(variantPeriodtext, dsText);
  }

  public infoVariantAvailable = false;
  public isTooltipVisible = false;

  public onHidingVariantInformation(e: any) {
    this.isTooltipVisible = false;
  }

  public refreshVariantInformation() {
    var ids = this._variants.map((v) => v.Variant.KeyId);

    this.updateVariants(ids, () => {
      this.isTooltipVisible = true;
    });
  }

  private updateVariants(ids: number[], afterNewItems: () => void) {
    this.infoVariantAvailable = false;
    this.variantComputeService.retrieveVariantState(ids).subscribe((vs) => {
      this.setNewVariantInformation(vs);
      afterNewItems();
    });
  }

  private setNewVariantInformation(newVariants: VariantInformationDto[]) {
    newVariants.forEach((vn) => {
      const existing = this._variants.find((v) => v.Variant.KeyId == vn.Variant.KeyId);
      if (existing) {
        existing.State = vn.State;
        existing.Variant = vn.Variant;
      } else {
        this._variants.push(vn);
      }
    });

    this.infoVariantAvailable = newVariants.length > 0;
    this.canComputeVariant = newVariants.length > 0;
  }

  public computeVariantIncludingDescendants() {
    this.sendComputeVariant((v) => (v.IncludeChilderen = true));
  }

  public computeVariant() {
    this.sendComputeVariant((v) => {});
  }

  public canComputeVariant = false;
  private sendComputeVariant(setProps: (variantDto: ComputeVariantDto) => void) {
    let forceCompute = false;
    var variantsToCalculate = this.variants.filter((v) => v.State.State != VariantCalculationStateTypeDto.Calculated);
    if (variantsToCalculate.length == 0) {
      if (confirm(this.language.VariantCalculatedConfirmForce, this.language.applicationTitle)) {
        variantsToCalculate = this.variants;
        forceCompute = true;
      }
    }

    if (variantsToCalculate.length > 0) {
      this.canComputeVariant = false;
      variantsToCalculate.forEach((v) => {
        var variantDto = this.computeVariantDtoFor(v);
        if (setProps) {
          setProps(variantDto);
        }
        variantDto.ForceCompute = forceCompute;

        this.variantComputeService.computeVariant(variantDto).subscribe((v) => {
          of(1)
            .pipe(delay(5000))
            .subscribe((_) => {
              this.canComputeVariant = true;
            });
        });
      });
    } else {
      console.log("No new variants to retrieve");
    }
  }

  private computeVariantDtoFor(v: VariantInformationDto) {
    const variantInfo = new Variant();
    variantInfo.copyFromDto(v.Variant);
    var variantDto = <ComputeVariantDto>variantInfo.toVariantDto();
    return variantDto;
  }
}
