<div *ngIf="tabs" class="dashboard-design-tabs">
  <dx-tabs
    #dashboardTabs
    class="sub-tabs"
    [dataSource]="tabs.items"
    [keyExpr]="tabs.tabKeyExpr"
    [scrollByContent]="true"
    [showNavButtons]="true"
    [(selectedItemKeys)]="tabs.selectedTabKeys"
    itemTemplate="tabItemTemplate"
  >
    <div *dxTemplate="let itemData of 'tabItemTemplate'">
      <div *ngIf="itemData.content">
        <div class="sub-tabs-text-container">
          <i class="dx-icon" [ngClass]="'dx-icon-' + itemData.icon"></i>
          <span class="sub-tabs-text">{{ itemData.content.name }}</span>
          <div class="button-container" *ngIf="tabs.selectedTabKeys == itemData.id">
            <app-edit-dashboard [dashboard]="itemData.content" (SortOrderHasChanged)="dashbordSortOrderChanged($event)" class="float-left"></app-edit-dashboard>
            <dx-button icon="fas fa-trash" class="button-icon-m float-left" [hint]="language.dashboarddesignDeleteDashboard" (onClick)="deleteDashboard(itemData.content)"> </dx-button>
          </div>
        </div>
      </div>
    </div>
  </dx-tabs>

  <dx-button icon="fas fa-plus" class="button-icon add-dashboard absolute" [hint]="language.dashboarddesignAddDashboardHeader" (onClick)="addDashboard()"></dx-button>
</div>
<div>
  <div class="content content-subtabs" *ngIf="selectedDashboardDefinition && selectedDashboardDefinition !== null">
    <app-cardview>
      <div>
        <i class="fas fa-people-carry iconStyling"></i>
        <h3>{{ language.dashboardSharing }}</h3>
        <app-dashboard-design-authorization [dashboard]="selectedDashboardDefinition" [allRoles]="availableRoles"></app-dashboard-design-authorization>
      </div>
    </app-cardview>

    <app-cardview>
      <div>
        <i class="fas fa-equals iconStyling"></i>
        <h3>{{ language.dashboarddesignSingleValues }}</h3>
      </div>
      <app-dashboard-design-dashboard [dashboard]="selectedDashboardDefinition" [definitionData]="definitionDataStatistic" [dashletTypeFilter]="[dashletType.Statistic]">
      </app-dashboard-design-dashboard>
    </app-cardview>

    <app-cardview>
      <div>
        <i class="fas fa-chart-bar iconStyling"></i>
        <h3>{{ language.dashboarddesignChartsGrids }}</h3>
      </div>
      <app-dashboard-design-dashboard [dashboard]="selectedDashboardDefinition" [definitionData]="definitionDataChart" [dashletTypeFilter]="[dashletType.Chart]"></app-dashboard-design-dashboard>
    </app-cardview>

    <app-cardview>
      <div>
        <i class="fas fa-list iconStyling"></i>
        <h3>{{ language.dashboarddesignDetailGrids }}</h3>
      </div>
      <app-dashboard-design-dashboard [dashboard]="selectedDashboardDefinition" [definitionData]="definitionDataDetail" [dashletTypeFilter]="[dashletType.Detail]"></app-dashboard-design-dashboard>
    </app-cardview>
  </div>
  <app-textpopup-component [interaction]="addDashboardPopup"></app-textpopup-component>
  <app-textpopup-component [interaction]="deleteDashboardPopup"></app-textpopup-component>
</div>
