<div class="dashboard-design-dashboard" *ngIf="dashboard">
  <div class="grid grid-template grid-remove-ie" [class.grid-template-4cols]="dashletTypeOfTypeStatistic === true">
    <div *ngFor="let view of dashletViews" class="grid-item">
      <app-dashboard-design-dashletview [view]="view" [dashboard]="dashboard"></app-dashboard-design-dashletview>
    </div>
    <div>
      <app-dashboard-design-add-dashlet [dashboard]="dashboard" [definitionData]="definitionData">
      </app-dashboard-design-add-dashlet>
    </div>
  </div>
</div>