import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DashboardDefinitionDto } from "../../analysis/dto/DashboardDefinitionDto-dto";
import { DashboardsDto } from "../../analysis/dto/DashboardsDto-dto";
import { DashletViewService } from "../dashletview";
import { DashletViewList } from "../dashletview/models/dashlet-view-list.model";
import { FilterListService } from "../filter/filter-list-service";
import { DashboardDefinition } from "./models/dashboard-definition.model";
import { Dashboards } from "./models/dashboards";

@Injectable()
export class DashboardService extends PlBaseService {
  private endPoint: string = "dashboard";

  public constructor(
    http: HttpClient,
    envSettings: EnvironmentSettings,
    public loadIndicator: PlLoadIndicator,
    public dashletViewService: DashletViewService,
    private filterListService: FilterListService,
  ) {
    super(http, envSettings, loadIndicator);
  }

  public getDashboards(): Observable<Dashboards> {
    const result = this.getDataTransformed<DashboardsDto>(this.endPoint).pipe(map((r) => this.transformDefinitions(r)));
    return result;
  }

  private transformDefinitions(dto: DashboardsDto): Dashboards {
    if (dto) {
      const result = new Dashboards(this, this.dashletViewService, this.filterListService);
      result.fromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  private extractDashboardDefinition(entry: EntryCreated<DashboardDefinitionDto>): DashboardDefinition {
    if (entry) {
      const json: DashboardDefinitionDto = entry.data;
      const viewList = new DashletViewList(this.dashletViewService);
      viewList.copyFromDTO(json.Views);
      const result = new DashboardDefinition(this, this.dashletViewService);
      result.fromDto(json);
      return result;
    } else {
      return null;
    }
  }

  public saveExistingDashboardDefinition(dto: DashboardDefinitionDto): Observable<void> {
    const url = this.endPoint + "/" + dto.Id;
    return this.update(dto, url);
  }

  public saveNewDashboardDefinition(dto: DashboardDefinitionDto): Observable<DashboardDefinition> {
    return this.createDataTransformed<DashboardDefinitionDto>(this.endPoint, dto).pipe(map((r) => this.extractDashboardDefinition(r)));
  }

  public deleteDashboardDefinition(dashboardDefinition: DashboardDefinition): Observable<void> {
    const url = this.endPoint + "/" + dashboardDefinition.id;
    return this.remove(url);
  }
}
