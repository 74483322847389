export class ToasterInput {
  public visible: boolean;
  public message: string;
  public state: "error" | "success" | "warning" | "info";

  constructor() {
    this.state = "info";
    this.visible = false;
    this.message = "no message supplied";
  }

  public showError(message: string) {
    this.showToast("error", message);
  }

  public showWarning(message: string) {
    this.showToast("warning", message);
  }

  public showSucces(message: string) {
    this.showToast("success", message);
  }

  public showInformation(message: string) {
    this.showToast("info", message);
  }

  private showToast(state: "error" | "success" | "warning" | "info", msg: string) {
    console.log("toaster (" + state + "):" + msg);
    this.message = msg;
    this.state = state;
    this.visible = true;
  }
}
