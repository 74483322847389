import { DataTypeDto } from './../../dto/DataType-dto';

// t4tsCodeFromCs": "0.8.2.0 for file EventTimerDataWellknownKeys.cs
// Don't adjust manually!

export class EventTimerDataWellknownKeysDto {
    public static SelectedConnectorSpecification: string = "ConnectorSpecification";
    public static SelectedImportSpecification: string = "SelectedImportSpecification";
    public static SelectedExportSpecification: string = "SelectedExportSpecification";
    public static SelectedMySdWorxActivationSpecification: string = "SelectedMySdWorxActivationSpecification";
    public static SelectedMySdWorxSyncSpecification: string = "SelectedMySdWorxSyncSpecification";
    public static SelectedMySdWorxNewHireSpecification: string = "SelectedMySdWorxNewHireSpecification";
    public static SelectedComputeVariant: string = "SelectedComputeVariant";
    public static SelectedComputeVariantIncludeChildren: string = "SelectedComputeVariantIncludeChildren";
    public static Keys: EventTimerDataKeyEntryDto [] = [];
}

export class EventTimerDataKeyEntryDto {
    public Name: string;
    public DataType: DataTypeDto;
    public Collection: boolean;
}

