import { ModelElementDto } from './ModelElementDto-dto';
import { ParameterMutationDto } from './ParameterMutationDto-dto';
import { ParameterTypeDto } from './ParameterType-dto';

// t4tsCodeFromCs": "0.8.2.0 for file ParameterElementWithMutationsDto.cs
// Don't adjust manually!

export class ParameterElementWithMutationsDto extends ModelElementDto {
    public HasBounds: boolean;
    public LowerBound: number;
    public UpperBound: number;
    public ParameterMutations: ParameterMutationDto [] = [];
    public ParameterType: ParameterTypeDto;
}

