import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DateSpanDto } from "../../../../common/models/dto/DateSpanDto-dto";
import { SDConnectUserDto } from "./dto/SDConnectUserDto-dto";
import { SDConnectUserInformationDto } from "./dto/SDConnectUserInformationDto-dto";

@Injectable({ providedIn: "root" })
export class SDWorxProvisioningUserService extends PlBaseService {
  readonly endpoint = "sdconnectusers";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveAll(): Observable<SDConnectUserInformationDto[]> {
    return this.getData<SDConnectUserInformationDto[]>(this.endpoint);
  }

  public retrieveUsers(): Observable<SDConnectUserInformationDto[]> {
    return this.getData<SDConnectUserInformationDto[]>(this.endpoint + "/users");
  }

  public retrievEemployees(): Observable<SDConnectUserInformationDto[]> {
    return this.getData<SDConnectUserInformationDto[]>(this.endpoint + "/employees");
  }

  public storeUser(user: SDConnectUserDto): Observable<SDConnectUserDto> {
    return this.postData<SDConnectUserDto>(this.endpoint, user);
  }

  public updatePeriod(userId: number, period: DateSpanDto): Observable<SDConnectUserDto> {
    return this.update<SDConnectUserDto>(period, this.endpoint + "/" + userId);
  }

  public sync(): Observable<any> {
    return this.postData(this.endpoint + "/sync", null);
  }
}
