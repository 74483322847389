import { DashletDetailDataColumnDto } from "../../analysis/dto/DashletDetailDataColumn-dto";
import { DashletDetailDataEntryDto } from "../../analysis/dto/DashletDetailDataEntry-dto";

export class DataRowValues {
  constructor(public row: DashletDetailDataEntryDto, columns: DashletDetailDataColumnDto[]) {
    const basic = this as any;
    for (let i = 0; i < columns.length; i++) {
      const value = row.Values[i];
      const column = columns[i];
      basic[column.Name] = value.ValueAsText;
    }
  }
}
