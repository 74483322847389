<div *ngIf="list">
  <div class="buttons-bottom-margin align-right" *ngIf="showButtons === true">
    <dx-button id="addButton" icon="fas fa-plus" [hint]="language.dashletDefinitionDesignAddDashlet"
      (click)="itemAdd($event)"></dx-button>
    <dx-button id="cloneButton" icon="far fa-copy" [hint]="language.Copy" [disabled]="!hasSelectedItems"
      (click)="itemClone($event)"></dx-button>
    <dx-button id="deleteButton" icon="fas fa-trash" [hint]="language.ButtonDelete" [disabled]="!hasSelectedItems"
      (click)="itemDelete($event)"></dx-button>
  </div>

  <dx-list [dataSource]="list.dashletDefinitions" [selectionMode]="'single'" [showSelectionControls]="true"
    [(selectedItems)]="selectedItems" [searchEnabled]="true" searchExpr="name" searchMode="contains"
    [nextButtonText]="language.listMore" [height]="listHeight" [showScrollbar]="'onHover'" pageLoadMode="nextButton">

    <div *dxTemplate="let data of 'item'">
      <div class="truncate" [attr.title]="data.name">{{data.name}}</div>
    </div>
  </dx-list>
</div>