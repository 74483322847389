import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DatasetService } from "../../services/dataset/dataset.service";
import { Dataset } from "../../services/dataset/models/dataset";
import { ImportDefinition } from "../../services/import";
import { ImportLanguage } from "../../services/language/connectorlanguage.service";

@Component({
  selector: "app-supply-dataset-selector",
  templateUrl: "data-supply-dataset-selector.component.html",
})
export class DataSupplyDatasetSelectorComponent implements OnInit {
  constructor(public language: ImportLanguage, private datasetService: DatasetService) {}
  public selectedDataset: number;
  public datasetName: string;
  public datasets: Dataset[] = [];
  @Output()
  private datasetSelected = new EventEmitter<number>(true);
  @Output()
  private datasetNamed = new EventEmitter<string>(true);

  ngOnInit() {
    this.datasetService.getDatasets().subscribe(d => {
      this.datasets = d;
    });
  }

  @Input() importDefinition: ImportDefinition;

  public onDatasetSelected() {
    this.datasetSelected.emit(this.selectedDataset);
  }

  public onDatasetNamed() {
    this.datasetNamed.emit(this.datasetName);
  }
}
