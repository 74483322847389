import { PresentableElement } from "../../../../../../common/model/models/presentable-element.model";
import { Variant } from "../../../../../../common/model/models/variant-model";
import { PresentableElementDto } from "../../../../../../common/models/dto/PresentableElement-dto";
import { VariantEditMetaData } from "../../../services/variantedit";

export class VariantPeriodFacade {
  constructor(private variantEditMetaData: VariantEditMetaData, private variantToEdit: Variant) {}

  public get periods() {
    return this.variantEditMetaData.periods;
  }

  public get variantEditPeriodModeId() {
    if (this.variantToEdit && this.variantToEdit.periodMode) {
      return this.variantToEdit.periodMode.id;
    }
    return undefined;
  }
  public set variantEditPeriodModeId(value: number) {
    if (this.variantToEdit) {
      this.variantToEdit.periodMode = this.getVariantPeriodModeById(value);
    }
  }

  public get variantEditStartPeriodId() {
    if (this.variantToEdit && this.variantToEdit.periodStart) {
      return this.variantToEdit.periodStart.id;
    }
    return undefined;
  }
  public set variantEditStartPeriodId(value: number) {
    if (this.variantToEdit) {
      this.variantToEdit.periodStart = this.variantEditMetaData.periods.find((p) => p.id === value);
    }
  }
  public get canEditPeriodStart() {
    const preiodModelWithDateSelectionAvailablility = this.variantEditMetaData.periodTypes.find((p) => p.KeyId === this.variantToEdit.periodMode.id);
    return preiodModelWithDateSelectionAvailablility.CanEditStartDate;
  }

  public get variantEditEndPeriodId() {
    if (this.variantToEdit && this.variantToEdit.periodEnd) {
      return this.variantToEdit.periodEnd.id;
    }
    return this.variantEditStartPeriodId;
  }
  public set variantEditEndPeriodId(value: number) {
    if (this.variantToEdit) {
      this.variantToEdit.periodEnd = this.variantEditMetaData.periods.find((p) => p.id === value);
    }
  }
  public get canEditPeriodEnd() {
    const preiodModelWithDateSelectionAvailablility = this.variantEditMetaData.periodTypes.find((p) => p.KeyId === this.variantToEdit.periodMode.id);
    return preiodModelWithDateSelectionAvailablility.CanEditEndDate;
  }

  private getVariantPeriodModeById(periodModeId: number): PresentableElement {
    const periodModeDto = this.variantEditMetaData.periodTypes.find((p) => p.KeyId === periodModeId) as PresentableElementDto;
    const periodMode = new PresentableElement();
    periodMode.copyFromDto(periodModeDto);

    return periodMode;
  }
}
