import { ImportSpecificationSettingsDto } from "../../../import/dto/ImportSpecificationSettingsDto-dto";

export class ImportSpecificationSettings {
  constructor(public datasetId: number, public datasetName: string, public mutationDate: Date, public variantId: number) {}

  public toDo() {
    const dto = new ImportSpecificationSettingsDto();
    dto.DataSetId = this.datasetId;
    dto.DataSetName = this.datasetName;
    dto.MutationDate = this.mutationDate;
    dto.VariantId = this.variantId;

    return dto;
  }
}
