import { Injectable } from "@angular/core";
import { VariantInformationDto } from "../../../../../common/models/dto/VariantInformation-dto";
import { ImagineLanguage } from "../language/imaginelanguage.service";
import { FilterService } from "./filter.service";
import { DataFilterList } from "./models/datafilter-list";

@Injectable({
  providedIn: "root",
})
export class FilterListService {
  public filterList: DataFilterList;

  public variants: VariantInformationDto[] = [];

  constructor(private filterService: FilterService, language: ImagineLanguage) {
    this.filterList = new DataFilterList(language);

    this.reloadFiltersFromApi();
  }

  public reloadFiltersFromApi() {
    this.filterService.retrieveFiltersFromApi().subscribe((fDto) => {
      FilterService.loadDtoOntoList(fDto, this.filterList);
    });
  }
}
