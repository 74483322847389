import { ImportSpecificationDto } from "../../../import/dto/ImportSpecificationDto-dto";
import { ImportSpecificationOptionsDto } from "../../../import/dto/ImportSpecificationOptionsDto-dto";
import { FileDefinitionList } from "../../file";

export class ImportDefinition {
  public id: number;
  public sortOrder: number;
  public name: string;
  public description: string;
  public options: ImportSpecificationOptionsDto;
  public files: FileDefinitionList;

  public constructor() {
    this.files = new FileDefinitionList();
  }

  public copyFrom(dto: ImportSpecificationDto) {
    this.id = dto.Id;
    this.name = dto.Name;
    this.sortOrder = dto.SortOrder;
    this.description = dto.Description;
    this.options = dto.Options;
    this.files.copyFromDTO(dto.Files);
  }

  public get isNew(): boolean {
    return this.id === undefined || this.id === null;
  }
}
