import { DxButtonModule, DxPopupModule, DxScrollViewModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { VariantComputeService } from "./variant-compute-service";
import { VariantInformationComponent } from "./variant-info-component";

@NgModule({
  imports: [CommonModule, DxButtonModule, DxPopupModule, DxScrollViewModule],
  exports: [VariantInformationComponent],
  providers: [VariantComputeService],
  declarations: [VariantInformationComponent],
})
export class VariantInformationModule {}
