<div *ngIf="events" class="tab-panel">
  <div>
    <dx-button class="newEventButton" icon="fas fa-plus" [text]="language.eventTimerNewEvent" (onClick)="newEvent()"> </dx-button>
    <dx-tab-panel [dataSource]="events" height="450px">
      <div *dxTemplate="let event of 'title'">
        {{ event.name }}
      </div>
      <div *dxTemplate="let event of 'item'">
        <app-event-timer-details
          [event]="event"
          [connectors]="connectors"
          (saveEmitter)="showToast($event)"
          (editEmitter)="editEvent(event)"
          (deleteEmitter)="deleteEvent(event)"
        ></app-event-timer-details>
      </div>
    </dx-tab-panel>
  </div>
</div>

<div *ngIf="showWizard">
  <app-configuratation-wizard [(showWizard)]="showWizard" (popUpClose)="popupClose()" [configurationSettings]="wizardData" (results)="readResults($event)"></app-configuratation-wizard>
</div>

<app-toaster [model]="toasterInput"></app-toaster>
