import { IntegrationServiceLanguage } from "../../../../../common/language/integrationservicelanguage.service";
import { RecurrenceTypeDto } from "../../../../../common/models/integrationservices/dto/RecurrenceType-dto";
import { ValueTextMapper } from "../../../../../common/models/value-text-mapper";

export class RecurrenceTypeTranslator extends ValueTextMapper<RecurrenceTypeDto> {
  constructor(integrationLanguage: IntegrationServiceLanguage) {
    super(RecurrenceTypeDto.Unknown, "");
    this.addEntry(RecurrenceTypeDto.Hourly, integrationLanguage.eventTimerEventRecurrence_Hourly);
    this.addEntry(RecurrenceTypeDto.Daily, integrationLanguage.eventTimerEventRecurrence_Daily);
    this.addEntry(RecurrenceTypeDto.Weekly, integrationLanguage.eventTimerEventRecurrence_Weekly);
    this.addEntry(RecurrenceTypeDto.Monthly, integrationLanguage.eventTimerEventRecurrence_Monthly);
    this.addEntry(RecurrenceTypeDto.Yearly, integrationLanguage.eventTimerEventRecurrence_Yearly);
  }
}
