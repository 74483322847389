import { ElementHelper } from "./element.helper";
export class BooleanHelper {
  public static fromString(value: string): boolean {
    if (ElementHelper.isNullOrUndefined(value)) {
      return false;
    }

    return value.toLowerCase() === "true" ? true : false;
  }
}
