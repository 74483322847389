
// t4tsCodeFromCs": "0.8.2.0 for file EventTimerType.cs
// Don't adjust manually!
export enum EventTimerTypeDto {
        Unknown = 0,
        Import = 1,
        Export = 2,
        MySdWorxActivation = 3,
        MySdWorxSync = 4,
        ComputeVariant = 5,
        MySdWorxNewHire = 6,
}

