import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ElementHelper } from "../../../../../../../angular-common/helpers/element.helper";
import { DateFormat } from "../../../../../../../angular-common/language/date-format";
import { TimePeriod } from "../../../../../../../common/modelelements/timeperiod-model";
import { FormulaMutationDto } from "../../../../../../../common/models/dto/FormulaMutationDto-dto";
import { MutationDto } from "../../../../../../../common/models/dto/MutationDto-dto";
import { ImagineLanguage } from "../../../../services/language/imaginelanguage.service";
import { VariantEditService } from "../../../../services/variantedit/variantedit-service";

@Component({
  selector: "app-formula-mutations-component",
  templateUrl: "./formula-mutations-component.html",
  styleUrls: ["./formula-mutations-component.scss"],
})
export class FormulaMutationsComponent {
  @Input() mutationsToShow: MutationDto[] = [];
  @Input() periodsForPeriodSelection: TimePeriod[];
  initialFormulaMutationValueToEdit: string = "";
  formulaMutationToEdit: string = "";
  @Input() canEdit: boolean;
  @Output() rowRemovedEvent = new EventEmitter<any>();
  @Output() refreshEvent = new EventEmitter<any>();

  private _currentFormulaMutation: FormulaMutationDto = new FormulaMutationDto();

  @Input() set currentFormulaMutation(value: FormulaMutationDto) {
    this._currentFormulaMutation = value;
    if (ElementHelper.isNullOrUndefined(value) === false) {
      this.initialFormulaMutationValueToEdit = value.OriginalFormulaText;
      this.formulaMutationToEdit = value.OriginalFormulaText;
    }
  }

  public get currentFormulaMutation() {
    return this._currentFormulaMutation;
  }

  public isFormulaChanged: boolean = false;
  isValidationSuccessfull: boolean;
  validationMessage: string = " ";
  OriginalFormulaTextCopy: string = "";

  public dateFormat: any = DateFormat.DateByUserLanguage();

  constructor(private variantService: VariantEditService, public language: ImagineLanguage) {
    this.selectTimePeriod = this.selectTimePeriod.bind(this);
  }

  emitOnRowRemoved(event: any) {
    this.rowRemovedEvent.emit(event);
  }

  selectTimePeriod(gridRow: any) {
    return TimePeriod.TimePeriodDisplayNameSelector(gridRow, this.periodsForPeriodSelection);
  }

  onRowPrepared(event: any) {
    if (event.rowType === "data" && event.data.StartDateText === this.currentFormulaMutation.StartDateText) {
      event.rowElement.classList.add("highlighted-row");
    }
  }

  onRowClick(event: any) {
    if (event.rowType === "data") {
      if (this.currentFormulaMutation.StartDateText !== event.data.StartDateText) {
        event.component.refresh();
      }
      this.currentFormulaMutation = event.data;
      this.formulaMutationToEdit = event.data.OriginalFormulaText;
      this.initialFormulaMutationValueToEdit = event.data.OriginalFormulaText;
      this.validationMessage = " ";
      this.isFormulaChanged = false;
    }
  }

  onValueChanged(formulafromTextArea: string) {
    this.formulaMutationToEdit = formulafromTextArea;
    if (this.formulaMutationToEdit === this.currentFormulaMutation.OriginalFormulaText) {
      this.isFormulaChanged = false;
    } else {
      this.isFormulaChanged = true;
    }
  }

  saveFormulaMutationButton() {
    this.currentFormulaMutation.OriginalFormulaText = this.formulaMutationToEdit;
    const data: FormulaMutationDto = this.currentFormulaMutation;

    if (ElementHelper.isNullOrUndefined(data) === false) {
      this.variantService.saveExistingFormulaMutation(data).subscribe((v) => {
        if (v) {
          data.DisplayValue = v.DisplayValue;
          data.OriginalFormulaText = v.OriginalFormulaText;
          data.StartDateText = v.StartDateText;
          this.isFormulaChanged = false;
        }
      });
    }
  }

  validateFormulaMutationButton() {
    this.OriginalFormulaTextCopy = this.currentFormulaMutation.OriginalFormulaText;
    this.currentFormulaMutation.OriginalFormulaText = this.formulaMutationToEdit;
    const data: FormulaMutationDto = this.currentFormulaMutation;

    if (ElementHelper.isNullOrUndefined(data) === false) {
      this.variantService.validateFormulaMutation(data).subscribe((v) => {
        this.isValidationSuccessfull = v.IsSuccess;
        this.validationMessage = v.Message;
      });
    }
    this.currentFormulaMutation.OriginalFormulaText = this.OriginalFormulaTextCopy;
  }
}
