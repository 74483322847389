import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Sorter } from "../../../../../../angular-common";
import { VariantInformationDto } from "../../../../../../common/models/dto/VariantInformation-dto";
import { DashboardDefinitionDto } from "../../../analysis/dto/DashboardDefinitionDto-dto";
import { DashletViewService } from "../../dashletview";
import { DashletViewList } from "../../dashletview/models/dashlet-view-list.model";
import { FilterListService } from "../../filter/filter-list-service";
import { DashboardService } from "../dashboard.service";
import { DashboardDefinition } from "./dashboard-definition.model";

export class DashboardDefinitionList {
  private _dashboards: DashboardDefinition[] = [];

  public constructor(private dashboardService: DashboardService, private dashletViewService: DashletViewService, private filterListService: FilterListService) {}

  public get dashboards(): DashboardDefinition[] {
    return this._dashboards;
  }

  public copyFromDTO(dto: DashboardDefinitionDto[], variantsDto: VariantInformationDto[]) {
    if (dto && dto !== null && dto.length > 0) {
      dto.forEach((dd) => {
        const dashletViewList = new DashletViewList(this.dashletViewService);
        dashletViewList.copyFromDTO(dd.Views);
        const newDashboardDefinition = new DashboardDefinition(this.dashboardService, this.dashletViewService);
        newDashboardDefinition.fromDto(dd);
        this.filterListService.variants = variantsDto;
        this._dashboards.push(newDashboardDefinition);
      });
      this.sort();
    }
  }

  public sort() {
    this._dashboards.sort(Sorter.compareSortOrder);
  }

  public addNew(name: string): Observable<DashboardDefinition> {
    const newDefinition = DashboardDefinition.addNew(this.dashboardService, this.dashletViewService, name);
    const obs = newDefinition.saveChanges();
    return obs.pipe(
      map(() => {
        this._dashboards.push(newDefinition);
        return newDefinition;
      }),
    );
  }

  public delete(dashboardDefinition: DashboardDefinition): Observable<void> {
    const idx = this._dashboards.indexOf(dashboardDefinition);
    let result = dashboardDefinition.delete();

    result = result.pipe(
      map(() => {
        this._dashboards.splice(idx, 1);
        return;
      }),
    );

    return result;
  }
}
