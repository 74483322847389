import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DashletDefinitionDto } from "../../analysis/dto/DashletDefinitionDTO-dto";
import { DashletDefinitionsDto } from "../../analysis/dto/DashletDefinitionsDTO-dto";
import { ImagineLanguage } from "../language/imaginelanguage.service";
import { DashletDefinition } from "./models/dashlet-definition/dashlet-definition.model";
import { DashletDefinitions } from "./models/dashlet-definitions.model";

@Injectable()
export class DashletDefinitionService extends PlBaseService {
  private endPoint: string = "dashletdefinition";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator, public language: ImagineLanguage) {
    super(http, envSettings, loadIndicator);
  }

  public getDashletDefinitions(): Observable<DashletDefinitions> {
    const result = this.getDataTransformed<DashletDefinitionsDto>(this.endPoint).pipe(map((r) => this.transformDefinitions(r)));
    return result;
  }

  private transformDefinitions(dto: DashletDefinitionsDto): DashletDefinitions {
    if (dto) {
      const result = new DashletDefinitions(this, this.language);
      result.copyFromDTO(dto);
      return result;
    } else {
      return null;
    }
  }

  public saveNewDashletDefinition(dto: DashletDefinitionDto): Observable<DashletDefinition> {
    return this.createDataTransformed<DashletDefinitionDto>(this.endPoint, dto).pipe(map((r) => this.extractDashletDefinition(r)));
  }

  private extractDashletDefinition(entry: EntryCreated<DashletDefinitionDto>): DashletDefinition {
    if (entry) {
      const json: DashletDefinitionDto = entry.data;
      const result = new DashletDefinition(this, json.Id, json.Name, json.Type);
      result.copyFromDTO(json);
      return result;
    } else {
      return null;
    }
  }

  public saveExistingDashletDefinition(dto: DashletDefinitionDto): Observable<any> {
    const url = this.endPoint + "/" + dto.Id;
    return this.update(dto, url);
  }

  public deleteDashletDefinition(dashletDefinition: DashletDefinition): Observable<any> {
    const url = this.endPoint + "/" + dashletDefinition.id;
    return this.remove(url);
  }
}
