<div *ngIf="parameter">
  <div class="pl-referenceContainer">
    <dx-button icon="fas fa-plus" (onClick)="newMutationPopup()"></dx-button>
    <dx-tab-panel #tabPanel [dataSource]="parameter.mutations" [selectedIndex]="0" [animationEnabled]="true">
      <div *dxTemplate="let mutation of 'title'">
        <span> {{ mutation.startDateText }} </span>
      </div>
      <div *dxTemplate="let mutation of 'item'">
        <h5>{{ parameter.longName }}</h5>
        <dx-button [disabled]="parameter.mutations.length <= 1" icon="fas fa-trash"
          (onClick)="removeMutation(mutation)"> </dx-button>
        <dx-select-box [(value)]="mutation.value" [dataSource]="getReferences(parameter)" displayExpr="text"
          valueExpr="id"></dx-select-box>
      </div>
    </dx-tab-panel>
  </div>
  <app-pl-periodpicker [interaction]="datePicker"></app-pl-periodpicker>
</div>