<div *ngIf="tabs && tabs.items.length > 0" class="tabs">
  <div id="tabs">
    <dx-tabs [dataSource]="tabs.items" [keyExpr]="tabs.tabKeyExpr" [showNavButtons]="false"
      [(selectedItemKeys)]="tabs.selectedTabKeys" (onItemClick)="selectTab($event)"></dx-tabs>
  </div>
</div>
<div class="content dx-fieldset">
  <div *ngFor="let tab of tabs.items">
    <app-data-supply-content *ngIf="tab.id === getSelectedTab" [tabContent]="tab.content"></app-data-supply-content>
  </div>
</div>