import { LookUpItemDto } from "../../../../../../common/models/dto/LookUpItem-dto";

export class LookupItem {
  public constructor() {}

  public id: number;
  public name: string;
  public dataType: string;
  public lookUp: any;

  public copyFromDto(dto: LookUpItemDto) {
    this.id = dto.ID;
    this.name = dto.Name;
    this.dataType = dto.DataType;
    this.lookUp = dto.LookUp;
  }
}
