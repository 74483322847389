import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxNumberBoxModule, DxSliderModule, DxTooltipModule } from "devextreme-angular";
import { PlSliderComponent } from "./pl-slider.component";

@NgModule({
  imports: [CommonModule, DxSliderModule, DxTooltipModule, DxNumberBoxModule],
  exports: [PlSliderComponent],
  declarations: [PlSliderComponent],
  providers: [],
})
export class PlSliderModule {}
