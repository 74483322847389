export class DevExtremeGridNames {
  public static changeTypeUpdate = "update";
  public static changeTypeInsert = "insert";
  public static changeTypeRemove = "remove";
  public static rowEditMode = "row";
  public static batchEditMode = "batch";
  public static batchEditModeSaveButton = "saveButton";
  public static batchEditModeRevertButton = "revertButton";
  public static optionEditingChanges = "editing.changes";
}
