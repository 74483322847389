import { Component, Input, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { confirm } from "devextreme/ui/dialog";
import { CardViewButton } from "../../../../../angular-common/components/cardview";
import { Palettes } from "../../../../../angular-common/components/palettes";
import { SelectBoxItems } from "../../../../../angular-common/components/selectbox/selectbox-items";
import { DashletTypeDto } from "../../analysis/dto/DashletType-dto";
import { DashletViewTypeDto } from "../../analysis/dto/DashletViewType-dto";
import { DashboardDefinition } from "../../services/dashboard";
import { DashletView } from "../../services/dashletview";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { DashboardDesignListBuilder } from "./dashboard-design-list-builder";

@Component({
  selector: "app-dashboard-design-dashletview",
  templateUrl: "./dashboard-design-dashletview.component.html",
  styleUrls: ["./dashboard-design-dashletview.component.scss"],
})
export class DashboardDesignDashletViewComponent implements OnInit {
  constructor(public language: ImagineLanguage, public palettes: Palettes, private router: Router) {}

  ngOnInit() {
    const deleteText = this.language.ButtonDelete;
    const deleteButton = new CardViewButton(1, "fas fa-trash", deleteText);
    this.actions.push(deleteButton);
    this.initTypeSelector();
  }

  @Input()
  public view: DashletView;

  @Input()
  public dashboard: DashboardDefinition;

  public buttonClick(btn: CardViewButton) {
    const header = this.language.confirmDeleteHeader;
    const title = this.language.confirmDeleteTitle;
    confirm(header, title).then((result) => {
      if (result) {
        this.delete();
      }
    });
  }

  public shouldShowViewPopup: boolean = false;

  public actions: CardViewButton[] = [];

  private delete() {
    this.dashboard.views.delete(this.view).subscribe();
  }

  // A view has a save method however after saving we want to re-sort the views in case the sort order changed. Javascript
  // has poor event handling (observer pattern) so we choose the simple solution and save here:
  public save() {
    this.view.saveChanges();
    this.dashboard.views.sort();
  }

  public goToView() {
    this.shouldShowViewPopup = true;
  }

  public get showTypeSelector(): boolean {
    return this.view !== null && (this.view.dashletType === DashletTypeDto.Chart || this.view.dashletType === DashletTypeDto.Detail);
  }

  public get showColorSelector(): boolean {
    return this.view !== null && this.view.dashletType === DashletTypeDto.Chart;
  }

  public get showPivotChartSelector(): boolean {
    return this.view !== null && this.view.viewType === DashletViewTypeDto.Pivot;
  }

  private initTypeSelector() {
    if (!this.showTypeSelector) {
      return;
    }

    const newTypeSelector = DashboardDesignListBuilder.viewtypelistSelectBox(this.language, this.view.changes.dashletType);
    newTypeSelector.selectedItem = newTypeSelector.items.find((e) => e.id === this.view.changes.viewType);
    this.typeSelector = newTypeSelector;
    this.typeSelector.selectedItemChanged.subscribe((x) => {
      if (x !== undefined && x !== null) {
        this.view.changes.viewType = x.id;
      }
    });
  }

  public typeSelector: SelectBoxItems;

  public get warningMessage(): string {
    if (this.view.canSave) {
      return this.language.dashletViewDesignSaveChanges;
    }

    if (!this.view.changes.nameIsValid) {
      return this.language.dashletViewDesignInputName;
    }

    return null;
  }

  public onDashletViewSaved(event: DashletView) {
    this.view = event;
    this.typeSelector.selectedItem = this.typeSelector.items.find((e) => e.id === event.viewType);
    this.shouldShowViewPopup = false;
  }
}
