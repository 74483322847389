export class ObjectUtils {
  public static keys(obj: any): string[] {
    if (obj === undefined || obj === null) {
      return [];
    }

    return Object.getOwnPropertyNames(obj);
  }

  public static values(obj: any): any[] {
    if (obj === undefined || obj === null) {
      return [];
    }
    const result = [];
    this.keys(obj).forEach(k => {
      result.push(obj[k]);
    });
    return result;
  }
}
