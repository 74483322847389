import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { RouteNavigation } from "../../../../../../angular-common/baseservice/route-navigation";
import { AuthorizationTabsBaseDirective } from "../../../../../../angular-common/components/authorization/component/tabs/authorization-tabs/authorization-tabs-base.component";
import { AuthorizationContentType } from "../../../../../../angular-common/components/authorization/models/authorization-content.types";
import { Tabs } from "../../../../../../angular-common/tabs/tabs";
import { ImagineRoutesDto } from "../../../models/dto/ImagineRoutes-dto";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { AuthorizationFilterContentComponent } from "./content/authorization-content.component";

@Component({
  selector: "app-authorization-filter-tabs",
  templateUrl: "../../../../../../angular-common/components/authorization/component/tabs/authorization-tabs/authorization-tabs-base.component.html",
  styleUrls: ["../../../../../../angular-common/components/authorization/component/tabs/authorization-tabs/authorization-tabs-base.component.scss"],
  providers: [],
})
export class AuthorizationFilterTabsComponent extends AuthorizationTabsBaseDirective {
  public authorizationContentTypes = AuthorizationContentType;

  public constructor(routeNavigation: RouteNavigation, route: ActivatedRoute, private language: ImagineLanguage) {
    super(ImagineRoutesDto.AuthorizationFilters, routeNavigation, route);
  }

  readonly componentToPush = AuthorizationFilterContentComponent;

  protected createTabs(tabstoBuild: Tabs) {
    tabstoBuild.addNew(AuthorizationContentType.FilterRole, this.language.authorizationFilterTitleRole, "spinup", AuthorizationContentType.FilterRole);
    tabstoBuild.addNew(AuthorizationContentType.FilterByVariant, this.language.authorizationFilterTitleTarget, "spinup", AuthorizationContentType.FilterByVariant);
    tabstoBuild.addNew(AuthorizationContentType.FilterOnBaseModel, this.language.authorizationFilterTitleBaseModel, "spinup", AuthorizationContentType.FilterOnBaseModel);
  }
}
