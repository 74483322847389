<div class="filtered-data" *ngIf="filter && filter.hasSelectedOptions">
  <span (click)="toggleFilterPopup($event)" [title]="filter.description">{{ filter.text }}: {{ filter.summary }}</span>
  <dx-button class="dx-button-filter button-icon-s" icon="chevrondown" (onClick)="toggleFilterPopup($event)">
  </dx-button>
  <dx-button class="dx-button-filter button-icon-s closeButton" icon="close" (onClick)="removeFilter($event)">
  </dx-button>
</div>

<dx-popup class="popup-filter" [maxWidth]="400" height="auto" [showTitle]="true" [title]="filter.text"
  [dragEnabled]="true" [hideOnOutsideClick]="false" [showCloseButton]="true" [(visible)]="filterPopupVisible"
  [position]="popupPosition">
  <div *dxTemplate="let data of 'content'">
    <div class="widget-container">
      <app-filteroption-list [filter]="filter" [showTitle]="false"></app-filteroption-list>
      <dx-button class="filterButtons" [text]="language.ButtonApply" (click)="changeFilter()"></dx-button>
      <dx-button class="filterButtons" [text]="language.ButtonCancel" (click)="undo()"></dx-button>
    </div>
  </div>
</dx-popup>