
// t4tsCodeFromCs": "0.8.2.0 for file JsDataType.cs
// Don't adjust manually!
export enum JsDataTypeDto {
        Unknown = -1,
        Number = 0,
        Boolean = 1,
        String = 2,
        Date = 3,
}

