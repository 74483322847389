import { ValidationRuleDto } from "../../../../../../common/models/dto/ValidationRule-dto";

export class ValidationRule {
  public constructor() {}

  public copyFromDto(dto: ValidationRuleDto) {
    this.type = dto.type;
    this.message = dto.message;
  }

  public type: string;
  public message: string;
}
