<div *ngIf="items" class="radiogroup-icons">
  <dx-radio-group layout="horizontal" [items]="items.items" [(value)]="items.selectedItem" itemTemplate="radioGroupItemTemplate">
    <div *dxTemplate="let itemData of 'radioGroupItemTemplate'">
      <!-- custom button to avoid losing focus when click outside -->
      <div class="dx-button dx-button-normal radioButton" [ngClass]="{ 'dx-state-focused': itemData === items.selectedItem }" [title]="itemData.hint">
        <div class="dx-button-content">
          <i [class]="'dx-icon ' + itemData.icon"></i>
          <span style="margin-left: 5px; vertical-align: middle;"> {{ itemData.hint }} </span>
        </div>
      </div>
    </div>
  </dx-radio-group>
</div>
