import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImportLanguage } from "../language/connectorlanguage.service";
import { ImportStateService } from "./import-state.service";
import { ImportService } from "./import.service";

@NgModule({
  imports: [CommonModule],
  providers: [ImportService, ImportStateService, ImportLanguage],
})
export class ImportModule {}
