import { ModelElement } from "../../model/models/model-element.model";
import { ElementTypeDto } from "../../models/dto/ElementType-dto";
import { ModelElements } from "../model-elements.model";

export class ModelElementsAuthorization extends ModelElements<ModelElement> {
  public constructor(_modelElements: ModelElement[]) {
    super(_modelElements);
  }

  public filterFor(dataSetId: number): ModelElements<ModelElement> {
    const handleAsBaseModel = dataSetId === 0 || dataSetId === undefined;

    if (handleAsBaseModel === false) {
      return this.filterForDataSetIdValue(dataSetId);
    } else {
      return this.zipItemsByShortName();
    }
  }

  private zipItemsByShortName() {
    let filteredByShortName: ModelElement[] = [];
    this.all.forEach((m) => {
      const matchingItems = filteredByShortName.filter((x) => x.shortName === m.shortName);
      if (matchingItems.length === 0) {
        filteredByShortName.push(m);
        return;
      }
      const itemToCheckOptionOn = matchingItems[0];
      if (m.options === undefined || m.options.length === 0) {
        return;
      }
      if (itemToCheckOptionOn.options === undefined) {
        itemToCheckOptionOn.options = [];
      }
      if (itemToCheckOptionOn.referenceCategoryId !== m.referenceCategoryId) {
        const newReferenceCategoryId = Math.max(itemToCheckOptionOn.referenceCategoryId || -1, m.referenceCategoryId || -1);
        console.log(
          `Mismatching reference category ${itemToCheckOptionOn.shortName}(${itemToCheckOptionOn.referenceCategoryId}) and ${m.shortName}(${m.referenceCategoryId}) becomes ${newReferenceCategoryId}`,
        );
        itemToCheckOptionOn.referenceCategoryId = newReferenceCategoryId;
      }

      m.options.forEach((mo) => {
        if (itemToCheckOptionOn.options.find((v) => v.text === mo.text)) {
        } else {
          itemToCheckOptionOn.options.push(mo);
        }
      });
    });

    return new ModelElements<ModelElement>(filteredByShortName);
  }

  private filterForDataSetIdValue(dataSetId: number) {
    const foundElements = this.all.filter((m) => {
      if (m.elementType === ElementTypeDto.Formula) {
        return true;
      } else if (m.elementType === ElementTypeDto.Exogenous) {
        return m.dataSetId === dataSetId;
      }
      return false;
    });

    return new ModelElements<ModelElement>(foundElements);
  }
}
