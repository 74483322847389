import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxDateBoxModule } from "devextreme-angular";
import { PeriodDatePickerComponent } from "./period-date-picker-component";

@NgModule({
  imports: [CommonModule, DxDateBoxModule],
  exports: [PeriodDatePickerComponent],
  declarations: [PeriodDatePickerComponent],
  providers: [],
})
export class PeriodDatePickerModule {}
