import { Component, OnInit } from "@angular/core";

import DxDataGrid from "devextreme/ui/data_grid";
import { StringHelper } from "../../../../../angular-common/helpers/string.helper";
import { VariantInformationDto } from "../../../../../common/models/dto/VariantInformation-dto";
import { AdaptLanguage } from "../../services/language/adaptlanguage.service";
import { DataMutationsClientService } from "../data-mutations/data-mutations-client-service";
import { DataMutationsService } from "../data-mutations/data-mutations-service";
import { ClientDataRow } from "../data-mutations/models/client-data-row.model";
import { ColumnItems } from "../data-mutations/models/column-items.model";
import { DataMutationsAllData } from "../data-mutations/models/data-mutations-all-data.model";
import { DataMutationsMutationData } from "../data-mutations/models/data-mutations-mutation-data.model";
import { MutationDataRow } from "../data-mutations/models/mutation-data-row.model";

@Component({
  selector: "app-data-mutations-screen",
  templateUrl: "./data-mutations-screen.component.html",
  styleUrls: ["./data-mutations-screen.component.scss"],
})
export class DataMutationsScreenComponent implements OnInit {
  constructor(public language: AdaptLanguage, private dataMutationsService: DataMutationsService, private dataMutationsClientService: DataMutationsClientService) {}

  ngOnInit() {
    this.refresh();
  }

  public get hasError() {
    if (this.dataMutationsAllData && this.dataMutationsAllData.metaData && this.dataMutationsAllData.metaData.result) {
      return this.dataMutationsAllData.metaData.result.Succes === false;
    }
    return false;
  }

  public get hasErrorMessage() {
    return StringHelper.hasNonEmptyText(this.errorMessage);
  }

  public get errorMessage() {
    return this.dataMutationsAllData?.metaData?.result?.Reason ?? "";
  }

  public variants: VariantInformationDto[] = [];

  private refresh() {
    this.dataMutationsClientService.getAllData().subscribe((m) => {
      this.dataMutationsAllData = m;
      this.variants = m.metaData.variantsInfos;
      this.initializeMasterGrid();
    });
  }

  private masterGridInitialized = false;
  private masterGrid: DxDataGrid;
  private detailedGrid: DxDataGrid;
  public dataMutationsAllData: DataMutationsAllData;

  onMasterGridInitialized(e: any) {
    this.masterGrid = e.component as DxDataGrid;
    this.initializeMasterGrid();
  }

  onDetailGridInitialized(e: any) {
    this.detailedGrid = e.component as DxDataGrid;
    this.initializeDetailGrid();
  }

  private initializeColumns(grid: DxDataGrid, columns: ColumnItems) {
    columns.all.forEach((column) => {
      let lookup: any;
      if (column.lookup && column.lookup !== null && column.lookup.dataSource.length > 0) {
        lookup = {
          dataSource: column.lookup.dataSource,
          displayExpr: column.lookup.displayExpr,
          valueExpr: column.lookup.valueExpr,
        };
      }

      grid.addColumn({
        caption: column.caption,
        visible: column.visible,
        dataField: column.dataField,
        sortIndex: column.sortIndex,
        allowEditing: column.allowEditing,
        allowSearch: true,
        format: column.format,
        dataType: column.dataType,
        allowFiltering: column.allowFiltering,
        lookup,
        sortOrder: column.sortOrder,
      });
    });
  }

  private initializeDetailGrid() {
    const columns = this.dataMutationsAllData.metaData.mutationColumns;
    this.initializeColumns(this.detailedGrid, columns);

    this.detailedGrid.columnOption("deleteControlsColumn", "visibleIndex", columns.all.length + 1);
  }

  private initializeMasterGrid() {
    if (this.masterGridInitialized || this.masterGrid === undefined || this.masterGrid === null || this.dataMutationsAllData === undefined || this.dataMutationsAllData === null) {
      return;
    }

    const columns = this.dataMutationsAllData.metaData.columns;
    this.initializeColumns(this.masterGrid, columns);

    this.masterGrid.columnOption("copyControlsColumn", "visibleIndex", columns.all.length + 2);
    this.masterGridInitialized = true;
  }

  public currentClientKey: any;
  public detailData: DataMutationsMutationData;
  public bubbleExpandPreventionMarker = false;

  public selectMasterRow(e) {
    if (this.bubbleExpandPreventionMarker === true) {
      return;
    }
    this.bubbleExpandPreventionMarker = true;

    if (this.currentClientKey) {
      this.masterGrid.collapseRow(this.currentClientKey);
    }
    this.detailData = undefined;

    const newMasterKey = e.key;
    this.dataMutationsService.getMutations(newMasterKey).subscribe((newDetailData) => {
      newDetailData.adjustInvalidReferences(this.dataMutationsAllData.metaData.mutationColumns);
      this.detailData = newDetailData;
      this.currentClientKey = newMasterKey;
      this.bubbleExpandPreventionMarker = false;
    });
  }

  private performOnCurrentRowInDetailGrid(e, func: (grid, rowIdx) => void) {
    const grid = e.component as DxDataGrid;
    const rowIndex = grid.getRowIndexByKey(e.key);
    func(grid, rowIndex);
  }

  public deleteDetailRow(e) {
    this.performOnCurrentRowInDetailGrid(e, (grid, rowIdx) => grid.deleteRow(rowIdx));
  }

  public unDeleteDetailRow(e) {
    this.performOnCurrentRowInDetailGrid(e, (grid, rowIdx) => grid.undeleteRow(rowIdx));
  }

  public editMasterRow(e) {
    // Edit only newly inserted records
    const client = e.data as ClientDataRow;
    e.cancel = client.identification !== undefined;
  }

  public copyMasterRow(e) {
    const source = e as ClientDataRow;
    const target = new ClientDataRow();

    ClientDataRow.copyProperties(source, target);
    this.resetNewClient(target);

    this.postClientAndRefresh(target);
  }

  private resetNewClient(client: ClientDataRow) {
    client.isVacancy = true;
    client.identification = undefined;
    ClientDataRow.setFieldValues(client, this.dataMutationsAllData.metaData.keyFields.all, 0);
  }

  private postClientAndRefresh(client: ClientDataRow) {
    this.dataMutationsClientService.saveNewClient(client.toDto()).subscribe((c) => {
      this.refresh();
    });
  }

  public initializeNewMasterRow(e) {
    const target: ClientDataRow = e.data as ClientDataRow;
    this.resetNewClient(target);
  }

  public saveMasterRow(e) {
    const target = new ClientDataRow();
    ClientDataRow.copyProperties(e.data, target);
    this.postClientAndRefresh(target);
  }

  public saveNewDetailRow(e) {
    const newMutation = e.data as MutationDataRow;
    this.dataMutationsService.saveNewMutation(newMutation.toDto()).subscribe((newMutationFromServer) => {
      newMutation.startDateText = newMutationFromServer.startDateText;
    });
  }

  public initializeNewDetailRow(e) {
    const grid: DxDataGrid = e.component as DxDataGrid;
    const currentData: MutationDataRow[] = [];
    grid.getVisibleRows().forEach((r) => {
      if (r.rowType === "data") {
        currentData.push(r.data);
      }
    });

    const newMutation: MutationDataRow = DataMutationsMutationData.createNewRow(this.currentClientKey, currentData);
    e.data = newMutation;
  }

  public onUpdatingDetailRow(e) {
    const oldData: MutationDataRow = e.oldData as MutationDataRow;
    const newData: MutationDataRow = e.newData as MutationDataRow;
    newData.mutationid = oldData.mutationid;
    newData.identification = oldData.identification;
  }

  public onUpdatedDetailRow(e) {
    const mutation = new MutationDataRow();
    MutationDataRow.copyProperties(e.data, mutation);
    this.dataMutationsService.saveExistingMutation(mutation).subscribe();
  }

  public confirmDeleteDetailRow(e) {
    const grid: DxDataGrid = e.component as DxDataGrid;
    const mutation = e.data as MutationDataRow;
    this.dataMutationsService.deleteMutation(mutation).subscribe(() => {
      if (grid.getVisibleRows().length === 0) {
        this.refresh();
      }
    });
  }

  public onEditorPreparing(e) {
    const mutation = e.row.data as MutationDataRow;
    if (e.row.inserted || mutation.canEdit) {
      e.editorOptions.disabled = false;
    } else {
      e.editorOptions.disabled = true;
      if (this.detailedGrid.hasEditData() === false) {
        this.detailedGrid.cancelEditData();
      }
    }
  }
}
