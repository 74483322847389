
// t4tsCodeFromCs": "0.8.2.0 for file VariantCalculationStateType.cs
// Don't adjust manually!
export enum VariantCalculationStateTypeDto {
        Unknown = 0,
        NotYetCalculated = 1,
        Calculating = 2,
        OutOfDate = 3,
        Calculated = 4,
}

