import { Component, Input } from "@angular/core";
import { Observable, forkJoin } from "rxjs";

import { VariantInformationDto } from "../../../../../common/models/dto/VariantInformation-dto";
import { DashletTypeDto } from "../../analysis/dto/DashletType-dto";
import { DashboardDefinition } from "../../services/dashboard";
import { DashletDataService } from "../../services/dashletdata";
import { DashletView } from "../../services/dashletview";
import { DataFilterList } from "../../services/filter";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  @Input()
  public get dashboardDefinition(): DashboardDefinition {
    return this._dashboardDefinition;
  }
  public set dashboardDefinition(newDefinition: DashboardDefinition) {
    this._dashboardDefinition = newDefinition;
    if (!newDefinition) {
      return;
    }
    this.loadInitialData();
  }

  @Input()
  public variants: VariantInformationDto[] = [];

  private _dashboardDefinition: DashboardDefinition;

  public get noDashlets(): boolean {
    if (this.dashboardDefinition.views.views.length === 0) {
      return true;
    }
  }

  constructor(public language: ImagineLanguage, private dashletDataService: DashletDataService) {}

  public get statisticDashletViews(): DashletView[] {
    return this.dashboardDefinition.views.views.filter((d) => d.loaded && d.dashletType === DashletTypeDto.Statistic);
  }

  public get nonStatisticDashletViews(): DashletView[] {
    return this.dashboardDefinition.views.views.filter((d) => d.loaded && d.dashletType === DashletTypeDto.Chart);
  }

  public get detailDashletViews(): DashletView[] {
    return this.dashboardDefinition.views.views.filter((d) => d.loaded && d.dashletType === DashletTypeDto.Detail);
  }

  @Input()
  public get filterList(): DataFilterList {
    return this._filterList;
  }
  public set filterList(newList: DataFilterList) {
    this._filterList = newList;
    if (this._filterList) {
      this._filterList.onApplyFilter.subscribe(() => this.reload());
    }
    this.loadInitialData();
  }
  private _filterList: DataFilterList;

  private loadInitialData() {
    if (this.filterList !== undefined && this.filterList !== null && this.filterList !== undefined && this.filterList !== null) {
      this.reload();
    }
  }

  private reload() {
    this.filterList.getActiveFilter().subscribe((filter) => {
      const obs: Observable<any>[] = [];
      this.dashboardDefinition.views.views.forEach((element) => {
        element.setRequestDataLoadCallBack(() => element.loadData(this.dashletDataService, filter).subscribe());
        obs.push(element.loadData(this.dashletDataService, filter));
      });
      forkJoin(obs).subscribe();
    });
  }
}
