import { ImportSuppliedFileDto } from "../../../import/dto/ImportSuppliedFile-dto";

export class FileSupplied {
  public id: string;
  public name: string;
  public definitionId: number;

  constructor() {}

  public copyFrom(dto: ImportSuppliedFileDto) {
    if (dto !== undefined && dto !== null) {
      this.id = dto.EntryKeyId;
      this.name = dto.FileName;
      this.definitionId = dto.ImportSpecificationSourceId;
    }
  }
}
