export class DataMutationsKeyFields {
  public constructor() {}

  public get all(): string[] {
    return this._all;
  }
  private _all: string[];

  public copyFromDto(dto: string[]) {
    this._all = dto;
  }
}
