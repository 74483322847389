export class ValueTextMapper<T> {

    public values: ValueTextEntry<T>[];

    constructor(private defaultValue: T, private defaultText: string){
        this.values = [];
    }

    public addEntry(value: T, text: string) {
        this.values.push(new ValueTextEntry<T>(text, value));
    }

    public toValue(text: string) {
        for(const valueOfValues of this.values) {
            if (valueOfValues.text === text) {
                return valueOfValues.value;
            }
        }
        return this.defaultValue;
    }

    public toText(value: T) {
        for(const valueOfValues of this.values) {
            if (valueOfValues.value === value) {
                return valueOfValues.text;
            }
        }
        return this.defaultText;
    }
}

class ValueTextEntry<T>{
    constructor(public text: string, public value: T){
    }
}