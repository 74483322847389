<app-cardview *ngIf="hasData">
  <div class="inner-container fix-height-l">
    <dx-data-grid
      id="gridContainer"
      [dataSource]="rows"
      redrawOnResize="true"
      [rowAlternationEnabled]="true"
      height="100%"
      [title]="dashletView.name"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      (onExporting)="onExporting($event)"
    >
      <dxo-scrolling mode="virtual"></dxo-scrolling>

      <dxi-column dataField="name" dataType="string" [caption]="language.dashletGridHeaderName"></dxi-column>
      <dxi-column dataField="point1" dataType="number" [format]="formatForValues" [caption]="valueColumn1"></dxi-column>
      <dxi-column dataField="point2" dataType="number" [format]="formatForValues" [caption]="variantName2" *ngIf="dataInspection.isVariantComparison"></dxi-column>
      <dxi-column dataField="difference" dataType="number" [format]="formatForValues" [caption]="language.dashletGridHeaderValueDifference" *ngIf="dataInspection.isVariantComparison"></dxi-column>
      <dxi-column dataField="differenceRelative" dataType="number" [format]="percentageFormat" [caption]="language.dashletGridHeaderValueRelativeDifference" *ngIf="dataInspection.isVariantComparison">
      </dxi-column>
      <dxo-export [enabled]="true" [fileName]="dashletView.name" [customizeExcelCell]="configureExport" [allowExportSelectedData]="false"></dxo-export>
    </dx-data-grid>
  </div>
  <app-dashlet-warning [dashletView]="dashletView"></app-dashlet-warning>
</app-cardview>
