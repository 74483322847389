import {
  DxButtonModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxListModule,
  DxLookupModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
} from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxiValidationRuleModule } from "devextreme-angular/ui/nested";
import { CardViewModule } from "../../../../angular-common/components/cardview";
import { ModelTreeModule } from "../../../../angular-common/components/modeltree/modeltree.module";
import { PeriodDatePickerModule } from "../../../../angular-common/components/period-date-picker/period-date-picker.module";
import { TextAreaWithNewLineHandlerModule } from "../../../../employee-common/components/text-area-with-new-line-handler/text-area-with-new-line-handler.module";
import { VariantInformationModule } from "../modeling/variant-info-module/variant-information-module";
import { DatasetServiceModule } from "../services/dataset/dataset.service.module";
import { DataSetScreenComponent } from "./dataset-screen/dataset-screen.component";
import { ModelEditScreenComponent } from "./model-edit-screen.component";
import { VariantEditComponent } from "./variant-edit-screen/variant-edit-component/variant-edit-component";
import { VariantEditScreenComponent } from "./variant-edit-screen/variant-edit-screen-component";
import { FormulaMutationsComponent } from "./variant-edit-screen/variant-mutations-component/formula-mutations-component/formula-mutations-component";
import { StackMutationsComponent } from "./variant-edit-screen/variant-mutations-component/stack-mutations-component/stack-mutations-component";
import { VariantMutationsComponent } from "./variant-edit-screen/variant-mutations-component/variant-mutations-component";

@NgModule({
  imports: [
    CommonModule,
    DxTabsModule,
    DxButtonModule,
    DxFormModule,
    DxSelectBoxModule,
    ModelTreeModule,
    CardViewModule,
    CommonModule,
    DxDataGridModule,
    DatasetServiceModule,
    DxPopupModule,
    DxTextBoxModule,
    DxTextAreaModule,
    VariantInformationModule,
    DxValidatorModule,
    DxiValidationRuleModule,
    DxListModule,
    DxTemplateModule,
    TextAreaWithNewLineHandlerModule,
    DxDateBoxModule,
    DxLookupModule,
    PeriodDatePickerModule,
  ],
  declarations: [
    ModelEditScreenComponent,
    VariantEditComponent,
    VariantEditScreenComponent,
    VariantEditComponent,
    DataSetScreenComponent,
    VariantMutationsComponent,
    FormulaMutationsComponent,
    StackMutationsComponent,
  ],
  exports: [DataSetScreenComponent],
})
export class ModelEditScreenModule {}
