import { Component, OnInit } from "@angular/core";
import { Tab, Tabs } from "../../../../angular-common";

import { ImagineLanguage } from "../services/language/imaginelanguage.service";

@Component({
  selector: "app-data-edit-screen",
  templateUrl: "./model-edit-screen.component.html",
  styleUrls: ["./model-edit-screen.component.scss"],
})
export class ModelEditScreenComponent implements OnInit {
  public tabs: Tabs = new Tabs();

  public readonly variantEditScreenId: number = 1;
  public readonly datasetEditScreenId: number = 2;

  constructor(private language: ImagineLanguage) {}

  ngOnInit(): void {
    this.tabs.selectedItemChanged.subscribe(this.tabChanged);

    const dataEditScreenTab = this.tabs.addNew(this.variantEditScreenId, this.language.dataEditVariantTabHeader, "spinup", null);
    this.tabs.addNew(this.datasetEditScreenId, this.language.dataEditDatasetTabHeader, "spinup", null);

    this.tabs.selectedTab = dataEditScreenTab;
  }

  private tabChanged(newTab: Tab) {}
}
