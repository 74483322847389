import { ModelTreeIdGenerator } from "../../../../../angular-common/components/modeltree/modeltreeservice/modeltree-id-generator";
import { ModelElementFactory } from "../../../../../common/model/models/model-element-factory";
import { ModelTreeBranchDto } from "../../../../../common/models/dto/ModelTreeBranch-dto";
import { ModelTreeService } from "../modeltree-service";
import { ModelTreeBranch } from "./modeltree-branch";

export class ModelTreeBuilder {
  public constructor(public modelTreeService: ModelTreeService) {}

  private idGenerator: ModelTreeIdGenerator = new ModelTreeIdGenerator();

  public buildTree(dto: ModelTreeBranchDto): ModelTreeBranch {
    const result = this.buildBranch(dto);

    if (result) {
      this.addChildren(dto, result);
    }

    return result;
  }

  private buildBranch(dto: ModelTreeBranchDto): ModelTreeBranch {
    const newBranch = new ModelTreeBranch();
    newBranch.element = ModelElementFactory.create(dto.Element);
    newBranch.id = this.idGenerator.generateNewId();
    return newBranch;
  }

  private addChildren(dto: ModelTreeBranchDto, target: ModelTreeBranch) {
    dto.Children.forEach(childDto => {
      const child = this.buildTree(childDto);
      if (child) {
        target.addChild(child);
      }
    });
  }
}
