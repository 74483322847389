import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DashletViewContextDto } from "../../analysis/dto/DashletViewContextDto-dto";
import { DashletViewDto } from "../../analysis/dto/DashletViewDto-dto";
import { DashletView } from "./models/dashlet-view.model";

@Injectable()
export class DashletViewService extends PlBaseService {
  private endPoint: string = "dashletview";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public addNewDashletView(dashboardId: number, dashletDefinitionId: number): Observable<DashletView> {
    const url = this.endPoint;

    const dashletViewContextDto = new DashletViewContextDto();
    dashletViewContextDto.DashletDefinitionId = dashletDefinitionId;
    dashletViewContextDto.DashboardId = dashboardId;

    return this.createDataTransformed<DashletViewDto>(url, dashletViewContextDto).pipe(map((r) => this.extractDashletView(r)));
  }

  private extractDashletView(entry: EntryCreated<DashletViewDto>): DashletView {
    if (entry) {
      const json: DashletViewDto = entry.data;
      const settings = DashletView.createFromDto(json, this);
      return settings;
    } else {
      return null;
    }
  }

  public deleteDashletView(dashletView: DashletView): Observable<void> {
    const url = this.endPoint + "/" + dashletView.dashletViewId;
    return this.remove(url);
  }

  public saveExistingDashletView(dto: DashletViewDto): Observable<any> {
    const url = this.endPoint + "/" + dto.DashletViewId;
    return this.update(dto, url);
  }
}
