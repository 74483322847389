import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { WebLanguage } from "../../../../common/language/weblanguage.service";
import { ElementHelper } from "../../../helpers/element.helper";
import { Sorter } from "../../../sorting";
import { Tabs } from "../../../tabs/tabs";
import { IConfigurationWizard } from "../interfaces/i-wizard-configuration";
import { WizardResult } from "../models/wizard-result";
import { ConfigurationWizardStage } from "../models/wizardstage";

@Component({
  selector: "app-configuratation-wizard",
  templateUrl: "configuration-wizard-screen.component.html",
  styleUrls: ["configuration-wizard-screen.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationWizardScreenComponent implements OnInit {
  constructor(public language: WebLanguage) {
    this.format = this.format.bind(this);
  }

  public currentTabIndex: number = 0;
  private previousStageIndex: number;
  public tabs: Tabs;
  public maxHeight: number = 650;
  public isFinalStage = false;

  public nextStageAllowed: boolean = false;

  public get maxValue(): number {
    return this.configurationSettings.stages.length;
  }

  public actionsValidated(value: boolean) {
    this.nextStageAllowed = value;
    this.checkForFinalStage();
  }

  @Input() configurationSettings: IConfigurationWizard;
  @Input() showWizard: boolean = false;
  @Output() popUpClose = new EventEmitter<boolean>();
  @Output() results = new EventEmitter<WizardResult>(true);

  private checkForFinalStage() {
    this.isFinalStage = this.nextSteps().length === 0;
  }

  ngOnInit() {
    this.createTabs();
  }

  public get currentStage(): ConfigurationWizardStage {
    const result = this.configurationSettings.stages.find((x) => x.sortOrder === this.currentTabIndex);
    if (ElementHelper.isNullOrUndefined(result) === true) {
      return this.configurationSettings.stages[0];
    }
    return result;
  }

  public format(ratio, value) {
    return this.language.wizardCurrentStage(value, value / ratio);
  }

  public goToNextStage() {
    this.previousStageIndex = this.currentTabIndex;
    for (let index = this.currentTabIndex + 1; index < this.tabs.items.length; index++) {
      const element = this.tabs.items[index];
      if (element.content.shouldUse() == true) {
        this.currentTabIndex = index;
        this.checkForFinalStage();
        break;
      }
    }
  }

  public goToPreviousStage() {
    if (this.currentTabIndex > 0) {
      this.currentTabIndex = this.previousStageIndex;
      this.checkForFinalStage();
    }
  }

  public saveResults() {
    const r = new WizardResult();

    this.configurationSettings.stages.forEach((stage) => {
      stage.actions.forEach((action) => {
        r.results[action.actionId] = action.value;
      });
    });

    this.results.emit(r);
  }

  public nextSteps() {
    if (this.tabs === undefined) {
      return [];
    }
    const nextTabs = [];
    for (let index = this.currentTabIndex + 1; index < this.tabs.items.length; index++) {
      const element = this.tabs.items[index];
      if (element.content.shouldUse() == true) {
        nextTabs.push(element);
      }
    }
    return nextTabs;
  }

  onHidden() {
    this.popUpClose.emit(this.showWizard);
  }

  private createTabs() {
    const allTabs = new Tabs();
    Sorter.sortSortOrderThenAlpha(this.configurationSettings.stages, (x) => x.name);
    this.configurationSettings.stages.forEach((stage) => {
      allTabs.addNew(stage.sortOrder, stage.name, "spinup", stage);
    });

    this.tabs = allTabs;
  }
}
