import { AfterViewInit, Component } from "@angular/core";
import { IDataSetFilteritem, IDataSetFilteritemContent } from "../../../services/authorization/filters/models/authorization-tabs.tab-content-filter";

import { DataSetFilterElementDto } from "../../../models/dto/DataSetFilterElementDto-dto";
import { AuthorizationFilterService } from "../../../services/authorization/filters/authorization-filter.service";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";
import { AuthorizationFilterComponent } from "./authorization-content.component-filter";
import { DataSetFilterViewType } from "./dataSet-filterviewtype";

@Component({
  selector: "app-authorization-content-filter-base-model",
  templateUrl: "./authorization-content.component-filter.html",
  styleUrls: ["./authorization-content.component-filter.scss"],
})
export class AuthorizationFilterBaseModelComponent extends AuthorizationFilterComponent implements AfterViewInit {
  public constructor(language: ImagineLanguage, filtersService: AuthorizationFilterService) {
    super(language, filtersService);
  }

  ngAfterViewInit() {
    this.tabContent.updateDataSetFilterViewType(DataSetFilterViewType.BASEMODEL);
    this.addingBaseModelFilter = true;
  }

  public get addFilterTitle(): string {
    return this.language.authorizationFilterActionsAddBaseModel;
  }

  public deleteElement(filter: IDataSetFilteritem, element: DataSetFilterElementDto): void {
    this.clearFields();
    this.role = filter.role;
    //const variantFromContent = filter.content.variant;
    this.tabContent.deleteElementFromDataSetFilter(filter.variant, this.role, element);
    return;
  }

  public deleteRoleTarget(item: IDataSetFilteritemContent): void {
    this.tabContent.deleteDataSetFilter(item.filterId);
    return;
  }

  public getDescriptions(filter: IDataSetFilteritem): string {
    const elementsNames = filter.content
      .map((c) => {
        return c.elements.map((e) => this.tabContent.displayPresenter.getDisplayName(e)).join(", ");
      })
      .join();

    return elementsNames;
  }

  public openEditElementModal(filter: IDataSetFilteritem, element: DataSetFilterElementDto): void {
    this.configureEditorInternal(filter);
    this.expressionModel.assignFrom(element);
    this.editingElement = element;
    this.elementFormPopupEnabled = true;
    return;
  }

  public openNewElementModal(filter: IDataSetFilteritem): void {
    this.configureEditorInternal(filter);
    this.editingElement = undefined;
    this.elementFormPopupEnabled = true;
    return;
  }

  public openNewRoleModal(filter: IDataSetFilteritem): void {
    console.error("filter can not be eddited as role", filter);
    return;
  }

  public openNewTargetModal(filter: IDataSetFilteritem): void {
    console.error("filter can not be eddited as variant", filter);
    return;
  }
}
