import { ValidationRuleDto } from "../../../../../../common/models/dto/ValidationRule-dto";
import { ValidationRuleBuilder } from "./validation-rule-builder";
import { ValidationRule } from "./validation-rule.model";

export class ValidationRules {
  public constructor() {}

  public get all(): ValidationRule[] {
    return this._all;
  }
  private _all: ValidationRule[] = [];

  public copyFromDto(dto: ValidationRuleDto[]) {
    const newList: ValidationRule[] = [];
    dto.forEach(v => {
      const newRule = ValidationRuleBuilder.buildFromDto(v);
      if (newRule && newRule !== null) {
        newList.push(newRule);
      }
    });
    this._all = newList;
  }
}
