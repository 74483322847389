import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataSetEditDto } from "../../models/dto/DataSetEditDto-dto";
import { Dataset } from "./models/dataset";

@Injectable()
export class DatasetService extends PlBaseService {
  private endpointRead = "dataset";
  private endpointEdit = "datasetEdit";
  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getDatasets(): Observable<Dataset[]> {
    const result = this.getDataTransformed<DataSetEditDto[]>(this.endpointRead).pipe(map((r) => this.transformDatasets(r)));
    return result;
  }

  private transformDatasets(dto: DataSetEditDto[]): Dataset[] {
    if (dto) {
      const result: Dataset[] = [];
      dto.forEach((dataset) => {
        const newDaset = new Dataset();
        newDaset.fromDto(dataset);
        result.push(newDaset);
      });
      return result;
    } else {
      return null;
    }
  }

  public deleteDataset(dataset: Dataset): Observable<any> {
    const url = this.endpointEdit + "/" + dataset.keyId;
    return this.remove(url);
  }

  public updateDataset(dataset: Dataset): Observable<any> {
    return this.update(Dataset.toDto(dataset), this.endpointEdit);
  }
}
