import { LookUpDto } from "../../../../../../common/models/dto/LookUp-dto";
import { LookupItem } from "./lookup-item.model";

export class Lookup {
  public constructor() {}

  public get isValidLookup(): boolean {
    return this.dataSource.length > 0;
  }

  public getLookupItem(id: number) {
    return this.dataSource.find(li => li.id === id);
  }

  public copyFromDto(dto: LookUpDto) {
    if (dto === undefined || dto === null) {
      return;
    }

    // There correspond to fields in the LookupItem class so they are hardcoded and not taken from the dto. The dto does not 'know'
    // what these properties are named client-side anyway so making them configurable makes no sense.
    this.displayExpr = "name";
    this.valueExpr = "id";

    const newDataSource: LookupItem[] = [];
    if (dto.dataSource && dto.dataSource !== null) {
      dto.dataSource.forEach(l => {
        const newLookupItem = new LookupItem();
        newLookupItem.copyFromDto(l);
        newDataSource.push(newLookupItem);
      });
    }
    this._dataSource = newDataSource;
  }

  public displayExpr: string;
  public valueExpr: string;

  public get dataSource(): LookupItem[] {
    return this._dataSource;
  }
  private _dataSource: LookupItem[] = [];
}
