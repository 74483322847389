import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";
import { ApiResultDto } from "../../../../../common/models/dto/ApiResult-dto";
import { FormulaMutationDto } from "../../../../../common/models/dto/FormulaMutationDto-dto";
import { ModelElementsForVariantDto } from "../../../../../common/models/dto/ModelElementsForVariantDto-dto";
import { MutationDto } from "../../../../../common/models/dto/MutationDto-dto";
import { ParameterMutationDto } from "../../../../../common/models/dto/ParameterMutationDto-dto";
import { StackMutationDto } from "../../../../../common/models/dto/StackMutationDto-dto";
import { VariantDto } from "../../../../../common/models/dto/VariantDTO-dto";
import { VariantEditInformationDto } from "../../../../../common/models/dto/VariantEditInformationDto-dto";
import { VariantEditMetaDataDto } from "../../models/dto/VariantEditMetaDataDto-dto";
import { VariantEditMetaData } from "./models/variantedit-metadata";

@Injectable()
export class VariantEditService extends PlBaseService {
  private endPoint: string = "variantedit";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getMetadata(): Observable<VariantEditMetaData> {
    const result = this.getDataTransformed<VariantEditMetaDataDto>(this.endPoint).pipe(map((r) => this.transformData(r)));
    return result;
  }

  private transformData(response: VariantEditMetaDataDto): VariantEditMetaData {
    if (response) {
      const json: VariantEditMetaDataDto = response;
      const result = new VariantEditMetaData();
      result.copyFromDto(json);
      return result;
    } else {
      return null;
    }
  }

  public saveExistingVariant(dto: VariantDto): Observable<any> {
    const url = this.endPoint + "/";
    return this.update(dto, url);
  }

  public createChildVariant(parentDto: VariantDto, name: string): Observable<VariantDto> {
    var params = new HttpParams();
    params = params.append("name", name);
    return this.http.post<VariantDto>(this.envSettings.getUri(this.endPoint), parentDto, { params: params });
  }

  public getVariantState(dto: VariantDto): Observable<VariantEditInformationDto> {
    const url = this.endPoint + "/" + dto.KeyId + "/state";
    return this.http.get<VariantEditInformationDto>(this.envSettings.getUri(url));
  }

  public addModelElementsToVariant(dto: ModelElementsForVariantDto): Observable<ModelElementsForVariantDto> {
    const url = this.endPoint + "/addmodelelement";
    return this.http.post<ModelElementsForVariantDto>(this.envSettings.getUri(url), dto);
  }

  public deleteExistingVariant(dto: VariantDto): Observable<VariantDto> {
    return this.http.request<VariantDto>("delete", this.envSettings.getUri(this.endPoint), { body: dto });
  }

  public addNewFormulaMutation(dto: FormulaMutationDto): Observable<FormulaMutationDto> {
    const url = this.endPoint + "/addformulamutation";
    return this.http.post<FormulaMutationDto>(this.envSettings.getUri(url), dto);
  }

  public addNewParameterMutation(dto: ParameterMutationDto): Observable<ParameterMutationDto> {
    const url = this.endPoint + "/addparametermutation";
    return this.http.post<ParameterMutationDto>(this.envSettings.getUri(url), dto);
  }

  public saveExistingParameterMutation(dto: ParameterMutationDto): Observable<ParameterMutationDto> {
    const url = this.endPoint + "/parametermutation";
    return this.update(dto, url);
  }

  public deleteExistingMutation(dto: MutationDto): Observable<MutationDto> {
    const url = this.endPoint + "/deletemutation";
    return this.http.request<MutationDto>("delete", this.envSettings.getUri(url), { body: dto });
  }

  public saveExistingFormulaMutation(dto: FormulaMutationDto): Observable<FormulaMutationDto> {
    const url = this.endPoint + "/formulamutation";
    return this.update(dto, url);
  }

  public validateFormulaMutation(dto: FormulaMutationDto): Observable<ApiResultDto> {
    const url = this.endPoint + "/validateformula";
    return this.http.post<ApiResultDto>(this.envSettings.getUri(url), dto);
  }

  public saveExistingStackMutation(dto: StackMutationDto): Observable<StackMutationDto> {
    const url = this.endPoint + "/stackmutation";
    return this.update(dto, url);
  }

  public validateStackRules(dto: StackMutationDto): Observable<ApiResultDto> {
    const url = this.endPoint + "/validatestacks";
    return this.http.post<ApiResultDto>(this.envSettings.getUri(url), dto);
  }
}
