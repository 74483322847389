import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ElementHelper } from "../../../../angular-common/helpers/element.helper";
import { CopyingObjects } from "../../../../angular-common/utils/copying-objects";
import { SDConnectProvisioningConfigurationDto } from "./dto/SDConnectProvisioningConfigurationDto-dto";
import { SDWorxProvisioningConfigService } from "./sdworx-provisioning-config.service";
import { SDWorxLanguage } from "./sdworxlanguage.service";

@Component({
  selector: "app-sdworx-provisioning-config-screen",
  templateUrl: "./sdworx-provisioning-config-screen.component.html",
  styleUrls: ["./sdworx-provisioning-config-screen.component.scss"],
})
export class SDWorxProvisioningConfigScreenComponent {
  constructor(private route: ActivatedRoute, public language: SDWorxLanguage, private provisioningService: SDWorxProvisioningConfigService) {
    this.route.data.subscribe((data: { configurationsData: SDConnectProvisioningConfigurationDto[] }) => {
      this.allConfigurations = data.configurationsData;
    });
  }

  private editedConfig: SDConnectProvisioningConfigurationDto;

  public allConfigurations: SDConnectProvisioningConfigurationDto[];

  onInserted(value: { key: SDConnectProvisioningConfigurationDto }) {
    this.provisioningService.createConfig(value.key).subscribe(() => {
      this.provisioningService.retrieveAllConfigs().subscribe((data) => {
        this.allConfigurations = data;
      });
    });
  }

  onEditingStart(value: { key: SDConnectProvisioningConfigurationDto }) {
    this.editedConfig = CopyingObjects.deepCopy(value.key);
  }

  onUpdate(value: { key: SDConnectProvisioningConfigurationDto }) {
    this.provisioningService.updateConfig(this.editedConfig.SA, value.key).subscribe((updatedConfig) => {
      const updatedConfigIndex = this.findConfigIndexBy(value.key.SA);
      if (updatedConfigIndex !== -1) {
        this.allConfigurations[updatedConfigIndex] = updatedConfig;
        this.editedConfig = undefined;
      }
    });
  }

  onDelete(value: SDConnectProvisioningConfigurationDto) {
    this.provisioningService.deleteConfig(value.SA).subscribe(() => {
      const deletedConfigIndex = this.findConfigIndexBy(value.SA);
      if (deletedConfigIndex !== -1) {
        this.allConfigurations.splice(deletedConfigIndex, 1);
      }
    });
  }

  private findConfigIndexBy(sa: string): number {
    if (ElementHelper.isNullOrUndefined(sa)) {
      return -1;
    }
    return this.allConfigurations.findIndex((c) => c.SA === sa);
  }
}
