<div *ngIf="items">
  <dx-select-box [items]="items.items" displayExpr="hint" [(value)]="items.selectedItem" itemTemplate="selectBoxItemTemplate">
    <div *dxTemplate="let itemData of 'selectBoxItemTemplate'">
      <div class="content">
        <i [class]="'dx-icon ' + itemData.icon"></i>
        <span style="margin-left: 5px; vertical-align: middle"> {{ itemData.hint }} </span>
      </div>
    </div>
  </dx-select-box>
</div>
