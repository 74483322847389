import { DataRow } from "./data-row.model";

export class MutationDataRow extends DataRow {
  public identification: any;
  public persontype: number;
  public startDateText: string;
  public mutationid: number;
  public startdate: Date;
  public canEdit: boolean;
  public canDelete: boolean;
}
