import { RangeRuleDto } from "../../../../../../common/models/dto/RangeRule-dto";
import { ValidationRule } from "./validation-rule.model";

export class ValidationRuleMinMax extends ValidationRule {
  public constructor() {
    super();
  }

  public copyFromDto(dto: RangeRuleDto) {
    super.copyFromDto(dto);

    this.min = dto.min;
    this.max = dto.max;
  }

  public min: number;
  public max: number;
}
