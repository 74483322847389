<dx-form [colCount]="12">
  <dxi-item [colSpan]="10">
    <div>
      <dx-text-box [value]="selectedVariantName" readOnly="true"></dx-text-box>
    </div>
  </dxi-item>
  <dxi-item [colSpan]="2">
    <dx-button icon="clear" [disabled]="!itemIsSelected" (click)="clearSelection()"> </dx-button>
    <dx-button icon="edit" [hint]="language.variantHeader" (click)="popupVariantToggle()"> </dx-button>
  </dxi-item>
</dx-form>

<dx-popup [width]="500" [height]="600" [showTitle]="true" [title]="language.variantHeader" [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="popupVariantVisible">
  <div *dxTemplate="let data of 'content'">
    <div class="buttonContainer">
      <dx-button icon="save" (click)="saveVariant()" [hint]="language.ButtonSave"></dx-button>
    </div>
    <app-modeltree class="modeltree-in-popup" [allowedTypes]="allowedElementTypes" [(selectedElement)]="elementFordit"
      [excludeBaseModel]="true"></app-modeltree>
  </div>
</dx-popup>