import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";
import { ParameterElementWithMutationsDto } from "../../../../../common/models/dto/ParameterElementWithMutationsDto-dto";
import { Parameter } from "../dashboard/models/parameter";
import { ParameterMutation } from "../dashboard/models/parametermutations";
import { DashletViewService } from "../dashletview";

@Injectable()
export class ParameterEditService extends PlBaseService {
  private endpoint = "parameter";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator, public dashletViewService: DashletViewService) {
    super(http, envSettings, loadIndicator);
  }

  createUrlWithVariantId(variantId: number) {
    return this.endpoint + "/" + String(variantId);
  }

  createUrlForDeletion(variantId: number, shortname: string) {
    return this.endpoint + "/" + String(variantId) + "/" + encodeURI(shortname);
  }

  public addNewParameter(variantId: number, parameter: Parameter): Observable<Parameter> {
    const thisService = this;
    const result = this.postDataTransformed<ParameterElementWithMutationsDto, Parameter>(this.createUrlWithVariantId(variantId), Parameter.toDto(parameter), r =>
      thisService.extractParameterWithMutations(thisService, r),
    );
    return result;
  }

  private extractParameterWithMutations(thisService: ParameterEditService, response: HttpResponse<ParameterElementWithMutationsDto>): Parameter {
    if (response.body) {
      const json: ParameterElementWithMutationsDto = response.body;
      const parameter = new Parameter();
      parameter.fromDto(json);

      return parameter;
    }
  }

  public updateParameter(variantId: number, parameter: Parameter): Observable<any> {
    return this.update(Parameter.toDto(parameter), this.createUrlWithVariantId(variantId));
  }

  public deleteAllParameterMutations(variantId: number, parameter: Parameter): Observable<any> {
    const url = this.createUrlForDeletion(variantId, parameter.shortName);
    return this.remove(url);
  }

  public deleteParameterMutation(variantId: number, parameter: Parameter, mutation: ParameterMutation): Observable<any> {
    const url = this.createUrlForDeletion(variantId, parameter.shortName) + "/" + mutation.keyStartDate;
    return this.remove(url);
  }
}
