import { ElementHelper } from "../../../../../../../angular-common/helpers/element.helper";
import { Sorter } from "../../../../../../../angular-common/sorting/sorter";
import { ModelElement } from "../../../../../../../common/model/models/model-element.model";
import { ModelElements } from "../../../../../../../common/modelelements/model-elements.model";
import { CompareTypeDto } from "../../../../../../../common/models/dto/CompareType-dto";
import { DataTypeDto } from "../../../../../../../common/models/dto/DataType-dto";
import { DataSetFilterElementDto } from "../../../../models/dto/DataSetFilterElementDto-dto";

export class AuthorizationExpressionModel {
  public modelElements: ModelElements<ModelElement> = new ModelElements<ModelElement>([]);

  public modelElementShortName: string;
  public compareType: CompareTypeDto;
  public value: string;

  public get valueAsReferenceId() {
    if (ElementHelper.isNullOrUndefined(this.value) || this.value.length == 0) {
      return null;
    }
    return parseInt(this.value, 10);
  }
  public set valueAsReferenceId(newValue: number) {
    if (newValue) {
      this.value = newValue.toString();
    }
  }

  public init(newModelElements: ModelElements<ModelElement>) {
    if (newModelElements) {
      this.modelElements = newModelElements;
    }
  }

  public clear() {
    this.modelElementShortName = undefined;
    this.compareType = undefined;
    this.value = undefined;
  }

  public assignFrom(element: DataSetFilterElementDto) {
    this.clear();
    if (element) {
      this.modelElementShortName = element.ShortName;
      this.compareType = element.CompareType;
      this.value = element.Value;
    }
  }

  public assignValueTo(element: DataSetFilterElementDto) {
    if (element) {
      element.ShortName = this.modelElementShortName;
      element.CompareType = this.compareType;

      if (this.compareTypeRequiresValue) {
        element.Value = this.value;
        element.ValueAsText = this.value;

        if (this.elementHasOptions) {
          const optionUsed = this.valueOptions.find((x) => x.id == this.valueAsReferenceId);
          if (optionUsed) {
            element.ValueAsText = optionUsed.text;
          }
        }
      }
    } else {
      element.Value = "";
      element.ValueAsText = "";
    }
  }

  public get requiredValuePresent() {
    if (this.compareType !== undefined) {
      return this.compareType !== CompareTypeDto.Null && this.compareType !== CompareTypeDto.NotNull;
    }
    return false;
  }
  public get elementHasOptions() {
    if (this.requiredValuePresent) {
      const modelElement = this.findCurrentElement();
      if (modelElement) {
        if (modelElement.dataType === DataTypeDto.Reference) {
          return true;
        } else {
          const vOptions = this.valueOptions;
          return vOptions && vOptions.length > 0;
        }
      }
    }
    return false;
  }

  private findCurrentElement(): ModelElement {
    if (this.modelElementShortName) {
      return this.modelElements.find(this.modelElementShortName);
    }
    return undefined;
  }

  public get valueOptions() {
    const modelElement = this.findCurrentElement();
    if (modelElement && modelElement.options) {
      Sorter.sortAlphabetically(modelElement.options, (option) => option.text);
      return modelElement.options;
    }

    return [];
  }

  private get compareTypeRequiresValue() {
    if (this.compareType === CompareTypeDto.Null) {
      return false;
    }
    if (this.compareType === CompareTypeDto.NotNull) {
      return false;
    }
    return true;
  }
}
