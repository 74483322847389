import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";
import { HttpClient, HttpResponse } from "@angular/common/http";

import { FileSupplied } from "../file/models/file-supplied";
import { ImportProcessingState } from "./models/import-processing-state.model";
import { ImportProcessingStateDto } from "../../import/dto/ImportProcessingState-dto";
import { ImportRunRequestDto } from "../../import/dto/ImportRunRequest-dto";
import { ImportSpecificationSettings } from "./models/import-specification-settings.model";
import { ImportSuppliedFileDto } from "../../import/dto/ImportSuppliedFile-dto";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProcessingMessageStateDto } from "../../../../../common/models/integrationservices/dto/ProcessingMessageState-dto";
import { map } from "rxjs/operators";

@Injectable()
export class ImportStateService extends PlBaseService {
  private endPoint: string = "import";
  private endPointFile: string = "importfile";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public delete(id: number): Observable<boolean> {
    const route = this.endPoint + "/" + id;
    return this.remove(route);
  }

  public getState(id: number): Observable<ImportProcessingState> {
    const thisService = this;
    const result = this.getDataTransformed<ImportProcessingStateDto>(this.endPoint + "/" + id).pipe(map((r) => this.extractSingle(r)));
    return result;
  }

  public getStates(): Observable<ImportProcessingState[]> {
    const thisService = this;
    const result = this.getDataTransformed<ImportProcessingStateDto[]>(this.endPoint).pipe(map((r) => this.extractMultiple(r)));
    return result;
  }

  public updateState(messageState: ProcessingMessageStateDto): Observable<ProcessingMessageStateDto> {
    return this.update<ProcessingMessageStateDto>(messageState, this.endPoint + "/" + messageState.TaskId);
  }

  private extractSingle(dto: ImportProcessingStateDto): ImportProcessingState {
    const result = new ImportProcessingState();
    if (dto) {
      result.copyFrom(dto);
    }
    return result;
  }

  private extractMultiple(dto: ImportProcessingStateDto[]): ImportProcessingState[] {
    if (dto) {
      const result: ImportProcessingState[] = [];
      if (dto !== undefined) {
        dto.forEach((d) => {
          const s = new ImportProcessingState();
          s.copyFrom(d);
          result.push(s);
        });
      }
      return result;
    } else {
      return [];
    }
  }

  public place(importSpecificationId: number, files: FileSupplied[], importSettings: ImportSpecificationSettings): Observable<ImportProcessingStateDto> {
    const dto = this.createDtoForRequest(importSpecificationId, files, importSettings);

    const thisService = this;
    const url = this.endPoint;
    const result = this.postDataTransformed<ImportProcessingStateDto, ImportProcessingStateDto>(url, dto, (r) => thisService.extractDashletWithData(thisService, r));
    return result;
  }

  public deleteData(fileGuid: string) {
    const url = this.endPointFile + "/" + fileGuid;
    return this.remove(url);
  }

  private createDtoForRequest(importSpecificationId: number, files: FileSupplied[], importSettings: ImportSpecificationSettings) {
    const dto = new ImportRunRequestDto();
    dto.ImportSpecificationId = importSpecificationId;
    dto.Supplied = [];
    if (dto.ImportSettings === undefined || dto.ImportSettings.DataSetId > 0) {
      dto.ImportSettings = importSettings.toDo();
    }

    files.forEach((f) => {
      const fDto = new ImportSuppliedFileDto();
      fDto.FileName = f.name;
      fDto.ImportSpecificationSourceId = f.definitionId;
      fDto.EntryKeyId = f.id;
      dto.Supplied.push(fDto);
    });
    return dto;
  }

  private extractDashletWithData(thisService: ImportStateService, response: HttpResponse<ImportProcessingStateDto>): ImportProcessingStateDto {
    if (response.body) {
      const json: ImportProcessingStateDto = response.body;

      return json;
    } else {
      return null;
    }
  }
}
