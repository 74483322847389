import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EntryCreated, EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../../angular-common";
import { ImportSpecificationFileDto } from "../../import/dto/ImportSpecificationFile-dto";
import { FileDefinition } from "./models/file-definition.model";

@Injectable()
export class FileService extends PlBaseService {
  private endPoint: string = "importfile";

  public constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public addNewFile(importName: string): Observable<FileDefinition> {
    const url = this.endPoint;
    const dto = {
      ImportName: importName,
    };
    return this.createDataTransformed<ImportSpecificationFileDto>(url, dto).pipe(map(r => this.extractFile(r)));
  }

  private extractFile(file: EntryCreated<ImportSpecificationFileDto>): FileDefinition {
    if (file) {
      const json: ImportSpecificationFileDto = file.data;
      const result = new FileDefinition();
      result.copyFrom(json);
      return result;
    } else {
      return null;
    }
  }

  public deleteFileDefinition(fileDefinition: FileDefinition): Observable<void> {
    const url = this.endPoint + "/" + encodeURI(fileDefinition.name);
    return this.remove(url);
  }

  public createUploadUriFor(id: number) {
    const fullEndPoint = this.envSettings.combinePath(this.envSettings.baseUri(), this.endPoint);
    return this.envSettings.combinePath(fullEndPoint, id.toString());
  }
}
