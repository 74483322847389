import { IConfigurationWizard } from "../../../../../angular-common/components/configwizard/interfaces/i-wizard-configuration";
import { WizardActionType } from "../../../../../angular-common/components/configwizard/models/wizard-action-types";
import { ConfigurationWizardAction } from "../../../../../angular-common/components/configwizard/models/wizardaction";
import { ConfigurationWizardActionDate } from "../../../../../angular-common/components/configwizard/models/wizardaction-date";
import { ConfigurationWizardStage } from "../../../../../angular-common/components/configwizard/models/wizardstage";
import { BooleanHelper } from "../../../../../angular-common/helpers/boolean.helper";
import { ElementHelper } from "../../../../../angular-common/helpers/element.helper";
import { IntegrationServiceLanguage } from "../../../../../common/language/integrationservicelanguage.service";
import { ConnectorSpecificationDto } from "../../../../../common/models/integrationservices/dto/ConnectorSpecification-dto";
import { EventTimerDataWellknownKeysDto } from "../../../../../common/models/integrationservices/dto/EventTimerDataWellknownKeys-dto";
import { EventTimerSpecificationSourceDto } from "../../../../../common/models/integrationservices/dto/EventTimerSpecificationSource-dto";
import { EventTimerTypeDto } from "../../../../../common/models/integrationservices/dto/EventTimerType-dto";
import { ImagineFeaturesModel } from "../../imagine-features-model";
import { EventTimerInformation } from "../models/event-timer-information";
import { RecurrenceTypeTranslator } from "../models/recurrence-type-translator";
import { EventSourceWizardAction } from "./event-source-wizard-action";
import { EventTypeWizardAction } from "./event-type-wizard-action";

const shortNameKey = "shortName";
const longNameKey = "longName";
const eventTypeKey = "eventType";
const recurrenceTypeKey = "recurrenceType";
const startDateKey = "startDate";
const startTimeKey = "startTime";

export class EventTimerWizard implements IConfigurationWizard {
  constructor(
    name: string,
    language: IntegrationServiceLanguage,
    eventSources: EventTimerSpecificationSourceDto[],
    private connectors: ConnectorSpecificationDto[],
    private featureSettings: ImagineFeaturesModel,
  ) {
    this.name = name;
    this.language = language;
    this.title = language.eventTimerWizard_Title;
    this.eventSources = eventSources;
    this.createStages();
    this.keyId = -1;
  }

  public eventSources: EventTimerSpecificationSourceDto[];
  public stages: ConfigurationWizardStage[];
  public title: string;
  public name: string;
  public keyId: number;

  private language: IntegrationServiceLanguage;

  /// the stages will be fetched from the api, for dummy purpose we make them here.
  private createStages() {
    this.stages = this.fetchStages();
  }

  private getStageOrder(currentStages: ConfigurationWizardStage[]): number {
    if (ElementHelper.isNullOrUndefined(currentStages)) {
      return 0;
    }
    return currentStages.length;
  }

  private fetchStages(): ConfigurationWizardStage[] {
    const allStages: ConfigurationWizardStage[] = [];

    let eventTypeSelectionAction: EventTypeWizardAction;
    {
      const eventTypeSelectionStage = new ConfigurationWizardStage(this.language.eventTimerWizardStepType, this.getStageOrder(allStages), () => (this.keyId ?? -1) !== -1);
      eventTypeSelectionAction = new EventTypeWizardAction(eventTypeKey, 0, this.language, this.featureSettings);
      eventTypeSelectionStage.addNewAction(eventTypeSelectionAction);
      allStages.push(eventTypeSelectionStage);
    }
    {
      const namingStage = new ConfigurationWizardStage(this.language.eventTimerWizardStepNaming, this.getStageOrder(allStages), () => true);
      namingStage.addNewAction(new ConfigurationWizardAction(longNameKey, WizardActionType.dxTextBox, 1, this.language.eventTimerEventLongName));
      namingStage.addNewAction(new ConfigurationWizardAction(shortNameKey, WizardActionType.dxTextBox, 0, this.language.eventTimerEventShortName));
      allStages.push(namingStage);
    }

    const connectorAction = new ConfigurationWizardAction(EventTimerDataWellknownKeysDto.SelectedConnectorSpecification, WizardActionType.dxSelectBox, 3, this.language.eventTimerEvent_Connector);
    connectorAction.setDataSourceDto(this.connectors);

    {
      const exportStage = new ConfigurationWizardStage(this.language.eventTimerWizardStepExport, this.getStageOrder(allStages), () => eventTypeSelectionAction.eventType === EventTimerTypeDto.Export);
      exportStage.addNewAction(connectorAction);

      const sourceToUse = this.eventSources.filter((e) => e.TimerType == EventTimerTypeDto.Export);
      const exportSpec = new EventSourceWizardAction(EventTimerDataWellknownKeysDto.SelectedExportSpecification, 4, this.language.eventTimerDataExportSpec, sourceToUse);
      exportStage.addNewAction(exportSpec);
      allStages.push(exportStage);
    }

    {
      const importStage = new ConfigurationWizardStage(this.language.eventTimerWizardStepImport, this.getStageOrder(allStages), () => eventTypeSelectionAction.eventType === EventTimerTypeDto.Import);
      importStage.addNewAction(connectorAction);

      const sourceToUse = this.eventSources.filter((e) => e.TimerType == EventTimerTypeDto.Import);
      const importSpec = new EventSourceWizardAction(EventTimerDataWellknownKeysDto.SelectedImportSpecification, 4, this.language.eventTimerDataImportSpec, sourceToUse);
      importStage.addNewAction(importSpec);
      allStages.push(importStage);
    }

    {
      const computeVariantStage = new ConfigurationWizardStage(
        this.language.eventTimerWizardStepComputeVariant,
        this.getStageOrder(allStages),
        () => eventTypeSelectionAction.eventType === EventTimerTypeDto.ComputeVariant,
      );

      const sourceToUse = this.eventSources.filter((e) => e.TimerType == EventTimerTypeDto.ComputeVariant);
      const computeVariantSpec = new EventSourceWizardAction(EventTimerDataWellknownKeysDto.SelectedComputeVariant, 4, this.language.eventTimerEventType_ComputeVariantSelectVariant, sourceToUse);
      computeVariantStage.addNewAction(computeVariantSpec);

      const includeChildrenAction = new ConfigurationWizardAction(
        EventTimerDataWellknownKeysDto.SelectedComputeVariantIncludeChildren,
        WizardActionType.dxCheckBox,
        4,
        this.language.eventTimerEventType_ComputeVariantIncludeChildren,
      );
      includeChildrenAction.isOptional = true;
      computeVariantStage.addNewAction(includeChildrenAction);
      allStages.push(computeVariantStage);
    }

    {
      if (eventTypeSelectionAction.isEventActionEnabled(EventTimerTypeDto.MySdWorxActivation)) {
        const mySdWorxActivationStage = new ConfigurationWizardStage(
          this.language.eventTimerWizardStepMySDWorxActivation,
          this.getStageOrder(allStages),
          () => eventTypeSelectionAction.eventType === EventTimerTypeDto.MySdWorxActivation,
        );
        mySdWorxActivationStage.addNewAction(connectorAction);

        const sourceToUse = this.eventSources.filter((e) => e.TimerType == EventTimerTypeDto.Export);
        const exportSpec = new EventSourceWizardAction(EventTimerDataWellknownKeysDto.SelectedMySdWorxActivationSpecification, 4, this.language.eventTimerDataExportSpec, sourceToUse);
        mySdWorxActivationStage.addNewAction(exportSpec);
        allStages.push(mySdWorxActivationStage);
      }
    }

    {
      if (eventTypeSelectionAction.isEventActionEnabled(EventTimerTypeDto.MySdWorxSync)) {
        const mySdWorxSyncStage = new ConfigurationWizardStage(
          this.language.eventTimerWizardStepMySDWorxSync,
          this.getStageOrder(allStages),
          () => eventTypeSelectionAction.eventType === EventTimerTypeDto.MySdWorxSync,
        );
        mySdWorxSyncStage.addNewAction(connectorAction);

        const sourceToUse = this.eventSources.filter((e) => e.TimerType == EventTimerTypeDto.Export);
        const exportSpec = new EventSourceWizardAction(EventTimerDataWellknownKeysDto.SelectedMySdWorxSyncSpecification, 4, this.language.eventTimerDataExportSpec, sourceToUse);
        mySdWorxSyncStage.addNewAction(exportSpec);
        allStages.push(mySdWorxSyncStage);
      }
    }

    {
      const recurranceStage = new ConfigurationWizardStage(this.language.eventTimerWizardStepRecurrance, this.getStageOrder(allStages), () => true);

      {
        let tanslator = new RecurrenceTypeTranslator(this.language);
        const recurrenceAction = new ConfigurationWizardAction(recurrenceTypeKey, WizardActionType.dxSelectBox, 3, this.language.eventTimerEventRecurrence);
        recurrenceAction.setDataSource("value", "text", tanslator.values);
        recurranceStage.addNewAction(recurrenceAction);
      }

      {
        const startDateAction = new ConfigurationWizardActionDate(startDateKey, 4, this.language.eventTimerEventStartDate);
        startDateAction.includeTime = false;
        startDateAction.includeDate = true;
        recurranceStage.addNewAction(startDateAction);
      }

      {
        const startTimeAction = new ConfigurationWizardActionDate(startTimeKey, 5, this.language.eventTimerEventStartTime);
        startTimeAction.includeTime = true;
        startTimeAction.includeDate = false;
        recurranceStage.addNewAction(startTimeAction);
      }

      allStages.push(recurranceStage);
    }

    return allStages;
  }

  public loadValues(eventTimerData: EventTimerInformation) {
    const lookupData = this.allWizardActions();

    this.keyId = eventTimerData.keyId;
    const eventTypeAction = lookupData[eventTypeKey] as EventTypeWizardAction;
    eventTypeAction.eventType = eventTimerData.eventType;

    lookupData[shortNameKey].value = eventTimerData.shortName;
    lookupData[longNameKey].value = eventTimerData.longName;
    lookupData[recurrenceTypeKey].value = eventTimerData.selectedRecurrenceType;

    lookupData[startDateKey].value = eventTimerData.startDate;
    lookupData[startTimeKey].value = eventTimerData.startTime;

    lookupData[EventTimerDataWellknownKeysDto.SelectedConnectorSpecification].value = EventTimerWizard.numberOrValue(
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedConnectorSpecification],
    );
    lookupData[EventTimerDataWellknownKeysDto.SelectedImportSpecification].value = EventTimerWizard.numberOrValue(
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedImportSpecification],
    );
    lookupData[EventTimerDataWellknownKeysDto.SelectedExportSpecification].value = EventTimerWizard.numberOrValue(
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedExportSpecification],
    );
    lookupData[EventTimerDataWellknownKeysDto.SelectedComputeVariant].value = EventTimerWizard.numberOrValue(
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedComputeVariant],
    );
    lookupData[EventTimerDataWellknownKeysDto.SelectedComputeVariantIncludeChildren].value = EventTimerWizard.toCheckboxSelection(
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedComputeVariantIncludeChildren],
    );

    if (eventTypeAction.isMySdWorxActivationEvent) {
      lookupData[EventTimerDataWellknownKeysDto.SelectedMySdWorxActivationSpecification].value = EventTimerWizard.numberOrValue(
        eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedMySdWorxActivationSpecification],
      );
    }

    if (eventTypeAction.isMySdWorxSyncEvent) {
      lookupData[EventTimerDataWellknownKeysDto.SelectedMySdWorxSyncSpecification].value = EventTimerWizard.numberOrValue(
        eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedMySdWorxSyncSpecification],
      );
    }
  }

  private static numberOrValue(value: string) {
    const parsed = parseInt(value);
    if (isNaN(parsed)) {
      return value;
    } else {
      return parsed;
    }
  }

  private static toCheckboxSelection(value: string) {
    return BooleanHelper.fromString(value) === true ? "True" : "";
  }

  public readValuesInto(eventTimerData: EventTimerInformation) {
    const lookupData = this.allWizardActions();
    eventTimerData.keyId = this.keyId;

    const eventTypeAction = lookupData[eventTypeKey] as EventTypeWizardAction;
    eventTimerData.eventType = eventTypeAction.eventType;

    eventTimerData.shortName = lookupData[shortNameKey].value;
    eventTimerData.longName = lookupData[longNameKey].value;

    eventTimerData.selectedRecurrenceType = lookupData[recurrenceTypeKey].value;
    eventTimerData.startDate = lookupData[startDateKey].value;
    eventTimerData.startTime = lookupData[startTimeKey].value;

    eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedConnectorSpecification] = lookupData[EventTimerDataWellknownKeysDto.SelectedConnectorSpecification].value;
    if (eventTypeAction.isImportevent) {
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedImportSpecification] = lookupData[EventTimerDataWellknownKeysDto.SelectedImportSpecification].value;
    } else if (eventTypeAction.isExportevent) {
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedExportSpecification] = lookupData[EventTimerDataWellknownKeysDto.SelectedExportSpecification].value;
    } else if (eventTypeAction.isComputeVariantEvent) {
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedComputeVariant] = lookupData[EventTimerDataWellknownKeysDto.SelectedComputeVariant].value;
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedComputeVariantIncludeChildren] =
        lookupData[EventTimerDataWellknownKeysDto.SelectedComputeVariantIncludeChildren].value;
    } else if (eventTypeAction.isMySdWorxActivationEvent) {
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedMySdWorxActivationSpecification] =
        lookupData[EventTimerDataWellknownKeysDto.SelectedMySdWorxActivationSpecification].value;
    } else if (eventTypeAction.isMySdWorxSyncEvent) {
      eventTimerData.eventTimerData.values[EventTimerDataWellknownKeysDto.SelectedMySdWorxSyncSpecification] = lookupData[EventTimerDataWellknownKeysDto.SelectedMySdWorxSyncSpecification].value;
    }
  }

  private allWizardActions(): IWizardActionsByID {
    var lookupData: IWizardActionsByID = {};
    for (const stage of this.stages) {
      for (const act of stage.actions) {
        lookupData[act.actionId] = act;
      }
    }
    return lookupData;
  }
}

interface IWizardActionsByID {
  [key: string]: ConfigurationWizardAction;
}
