import { CompareType } from ".";
import { DataSetFilterDto } from "../../../../models/dto/DataSetFilterDto-dto";
import { AuthorizationFilterElements } from "./authorization-filter-elements-model";

export class AuthorizationFilter {
  public static newEmptyFilter(roleId: number, targetId: number): AuthorizationFilter {
    const result = new AuthorizationFilter();

    result.roleId = roleId;
    result.targetId = targetId;

    return result;
  }
  public roleId: number;
  public targetId: number;
  public elements: AuthorizationFilterElements;

  public constructor() {
    this.elements = new AuthorizationFilterElements();
  }

  public copyFromDto(filterDto: DataSetFilterDto, compareTypes: CompareType[]) {
    this.roleId = filterDto.Role.KeyId;
    this.targetId = filterDto.Variant.KeyId;

    filterDto.Elements.forEach((e) => {
      this.elements.addFromDto(e, compareTypes);
    });
  }
}
