<app-cardview *ngIf="numberOfDataPoints > 0">
  <div class="inner-container">
    <div class="dxc-title">{{ dashletView.name }}</div>

    <div *ngIf="isCompareStat">
      <p class="dx-font-xs">{{ clusterNameCurrent }} {{ language.dashletCompareVersus }} {{ clusterNameCompare }}</p>
      <div class="lighten dx-datagrid">
        <h2 class="font-size-xl" id="{{ divId }}" (mouseenter)="toggleTooltip()" (mouseleave)="toggleTooltip()">{{ clusterValueCurrentToCompareDifference }}</h2>
      </div>
    </div>
    <div *ngIf="!isCompareStat">
      <p class="dx-font-xs">&nbsp;</p>
      <h2 *ngIf="clusterValueCurrent" class="font-size-xl" id="{{ divId }}">{{ clusterValueCurrent }}</h2>
    </div>

    <dx-popover [minWidth]="500" target="#{{ divId }}" [(visible)]="showToolTipForValue" [showTitle]="true" titleTemplate="title">
      <div *dxTemplate="let data = data; of: 'content'">
        <dx-form [colCount]="2">
          <dxi-item [colSpan]="2"> <dxo-label [text]="clusterNameCurrent"></dxo-label> {{ clusterValueCurrent }} </dxi-item>
          <dxi-item [colSpan]="2"> <dxo-label [text]="clusterNameCompare"></dxo-label> {{ clusterValueCompare }} </dxi-item>
          <dxi-item [colSpan]="2"> <dxo-label [text]="language.dashletCompareDifference"></dxo-label> {{ clusterValueCurrentToCompareDifference }} </dxi-item>
          <dxi-item [colSpan]="2"> <dxo-label [text]="language.dashletCompareDifferenceRelative"></dxo-label> {{ clusterValueCurrentToCompareDifferenceRelative }} </dxi-item>
        </dx-form>
      </div>
      <div *dxTemplate="let data of 'title'">
        <div class="titleStyle">
          <strong>
            <p>{{ clusterNameCurrent }} {{ language.dashletCompareVersus }} {{ clusterNameCompare }}</p>
          </strong>
        </div>
      </div>
    </dx-popover>
    <app-dashlet-warning [dashletView]="dashletView"></app-dashlet-warning>
  </div>
</app-cardview>
