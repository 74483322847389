import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvironmentSettings, PlBaseService, PlLoadIndicator } from "../../../../angular-common";
import { EventTimerMetaDataDto } from "../../../../common/models/integrationservices/dto/EventTimerMetaDataDto-dto";

@Injectable({ providedIn: "root" })
export class EventTimerMetaDataService extends PlBaseService {
  endpoint = "eventtimermetadata";

  constructor(http: HttpClient, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public retrieveMetaData(): Observable<EventTimerMetaDataDto> {
    return this.getData(this.endpoint);
  }
}
