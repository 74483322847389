
// t4tsCode gen": "0.8.2.0 for file DashletViewType.cs
// Don't adjust manually!
export enum DashletViewTypeDto {
        Unknown = 0,
        BarChart = 1,
        Grid = 2,
        PieChart = 3,
        RotatedBarChart = 4,
        SingleValue = 5,
        Pivot = 6,
        LineChart = 7,
        SplineChart = 8,
        SteplineChart = 9,
        LineWithoutMarkersChart = 10,
        AreaChart = 11,
}

export class DashletViewTypeExtensionsDto {
}

