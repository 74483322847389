import { ClusterNamingDto } from "../../../analysis/dto/ClusterNaming-dto";
import { ImagineLanguage } from "../../language/imaginelanguage.service";
import { DataFilter } from "./datafilter";
import { DataFilterOption } from "./datafilteroption";

export class DataFilterPeriodTextBuilder {
  public constructor(private language: ImagineLanguage) {}

  public getPeriodSelectionText(filters: DataFilter[]): string {
    let notFoundText = "not found";
    let noPeriodOptionsFound = "no periods";
    if (this.language) {
      notFoundText = this.language.periodFilterNotFound;
      noPeriodOptionsFound = this.language.periodFilterNotFound;
    }

    return DataFilterPeriodTextBuilder.getPeriodSelectionTextWithLanguage(filters, notFoundText, noPeriodOptionsFound);
  }

  public static getPeriodSelectionTextWithLanguage(filters: DataFilter[], noPeriodFilter: string, noPeriodFilterOptions: string): string {
    if (filters.length === 0) {
      return noPeriodFilter;
    }

    const periodFilter = filters.find((x) => x.shortName === ClusterNamingDto.CumalativePeridod);
    if (periodFilter === undefined) {
      return noPeriodFilter;
    }

    if (periodFilter.options === undefined || periodFilter.options.options === undefined || periodFilter.options.options.length === 0) {
      return noPeriodFilterOptions;
    }

    const periodFilterOptions = periodFilter.options.options;
    const selectedFilters = periodFilterOptions.filter((x) => x.filterBy);

    if (selectedFilters.length === 0) {
      return DataFilterPeriodTextBuilder.getPeriodFromOptions(periodFilterOptions);
    } else {
      return DataFilterPeriodTextBuilder.getPeriodFromOptions(selectedFilters);
    }
  }

  private static getPeriodFromOptions(filterOptions: DataFilterOption[]): string {
    if (filterOptions.length === 1) {
      return filterOptions[0].text;
    }

    const periodFilterIds = filterOptions.map((x) => x.id);
    const minValue = filterOptions.find((x) => x.id === Math.min(...periodFilterIds));
    const maxValue = filterOptions.find((x) => x.id === Math.max(...periodFilterIds));

    return minValue.text + " - " + maxValue.text;
  }
}
