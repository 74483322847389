<dx-form [colCount]="16">
  <dxi-item [colSpan]="2">
    <div>
      <div>{{ language.ComputeVariantSelect }}</div>
    </div>
  </dxi-item>
  <dxi-item [colSpan]="3">
    <div>
      <dx-text-box [value]="selectedVariantName" readOnly="true"></dx-text-box>
    </div>
  </dxi-item>
  <dxi-item [colSpan]="1">
    <dx-button icon="edit" [hint]="language.ComputeVariantSelect" (click)="popupVariantToggle()"> </dx-button>
  </dxi-item>
</dx-form>

<dx-popup [width]="500" [height]="600" [showTitle]="true" [title]="language.ComputeVariantSelect" [dragEnabled]="true" [hideOnOutsideClick]="false" [(visible)]="popupVariantVisible">
  <div *dxTemplate="let data of 'content'">
    <div class="buttonContainer">
      <dx-button icon="save" (click)="saveVariant()" [hint]="language.ButtonSave"></dx-button>
    </div>
    <app-modeltree class="modeltree-in-dataSupply" [allowedType]="allowedElementType" [(selectedElement)]="variantForEdit" [excludeBaseModel]="true"> </app-modeltree>
  </div>
</dx-popup>
