import { Component, Input } from "@angular/core";
import { DashletDefinition, DashletDefinitionList, DashletDefinitions } from "../../services/dashletdefinition";

import { DashboardDefinition } from "../../services/dashboard";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashboard-design-add-dashlet",
  templateUrl: "./dashboard-design-add-dashlet.component.html",
  styleUrls: ["./dashboard-design-add-dashlet.component.scss"],
})
export class DashboardDesignAddDashletComponent {
  constructor(public language: ImagineLanguage) {}

  @Input()
  public definitionData: DashletDefinitions;

  @Input()
  public dashboard: DashboardDefinition;

  public togglePopup(event) {
    if (event !== null && event.element) {
      this.popupPosition = {
        my: "left top",
        at: "left bottom",
        of: event.element,
      };
    }
    this.popupVisible = !this.popupVisible;
  }

  // We have multiple add buttons on the screen so the position of the popup needs to be computed at runtime:
  public popupPosition: any;

  public popupVisible: boolean = false;

  public get list(): DashletDefinitionList {
    if (this.definitionData) {
      return this.definitionData.list;
    }
    return undefined;
  }

  public selectedDashletDefinitions: DashletDefinition[];

  public onSelectionChanged(dashletDefinitions: DashletDefinition[]) {
    this.selectedDashletDefinitions = dashletDefinitions;
  }

  public addDashlets() {
    if (this.selectedDashletDefinitions && this.selectedDashletDefinitions.length > 0) {
      this.dashboard.addDashlets(this.selectedDashletDefinitions);
    }

    this.togglePopup(null);
  }
}
