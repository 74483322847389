import { ColumnItemDto } from "../../../../../../common/models/dto/ColumnItem-dto";
import { Lookup } from "./lookup.model";
import { ValidationRules } from "./validation-rules.model";

export class ColumnItem {
  public constructor() {}

  public copyFromDto(dto: ColumnItemDto) {
    this.dataField = dto.dataField;
    this.dataType = dto.dataType as "string";
    this.caption = dto.caption;
    this.visible = dto.visible;
    this.allowEditing = dto.allowEditing;
    this.allowFiltering = dto.allowFiltering;
    this.format = dto.format;
    this.cellTemplate = dto.cellTemplate;
    this.sortIndex = dto.sortIndex;
    this.sortOrder = dto.sortOrder as "asc" | "desc";
    this.lookup.copyFromDto(dto.lookup);
    this.validationRules.copyFromDto(dto.validationRules);
  }

  public dataField: string;
  public dataType: "string" | "number" | "date" | "boolean" | "object" | "datetime";
  public caption: string;
  public visible: boolean;
  public allowEditing: boolean;
  public allowFiltering: boolean;
  public format: string;
  public sortOrder: "asc" | "desc";
  public sortIndex: number;
  public cellTemplate: any;

  public lookup: Lookup = new Lookup();
  public validationRules: ValidationRules = new ValidationRules();
}
