import { ModelElement } from "../../../../../../../common/model/models/model-element.model";
import { ModelElements } from "../../../../../../../common/modelelements/model-elements.model";
import { CompareTypeDto } from "../../../../../../../common/models/dto/CompareType-dto";
import { DataSetFilterElementDto } from "../../../../models/dto/DataSetFilterElementDto-dto";
import { CompareType } from "./authorization-compareType-model";

export class ExpressionDisplayPresenter {
  public compareTypes: CompareType[] = [];
  private modelElements: ModelElements<ModelElement>;

  constructor() {}

  public init(newCompareTypes: CompareType[], newModelElements: ModelElements<ModelElement>) {
    this.compareTypes = newCompareTypes;
    this.modelElements = newModelElements;
  }

  public getDisplayName(element: DataSetFilterElementDto): string {
    const modelElement = this.matchingModelElement(element);
    if (modelElement) {
      return modelElement.displayName;
    }
    return "";
  }

  public getCompareTypeName(element: DataSetFilterElementDto): string {
    if (element) {
      const compareType = this.compareTypes.find((x) => x.id === element.CompareType);
      if (compareType) {
        return compareType.name;
      } else {
        return DataSetFilterElementDto[element.CompareType];
      }
    }
    return "";
  }

  private matchingModelElement(element: DataSetFilterElementDto) {
    if (element) {
      return this.modelElements.all.find((m) => m.shortName === element.ShortName);
    }

    return undefined;
  }

  public getOptionText(element: DataSetFilterElementDto): string {
    if ((element || {}).CompareType === CompareTypeDto.Null) {
      return "";
    }

    const modelElement = this.matchingModelElement(element);
    if (modelElement) {
      if (modelElement.hasReferenceCategory) {
        const option = modelElement.options.find((o) => o.id === parseInt(element.Value, 10));
        if (option) {
          return option.text;
        }
      }
    }
    return element.ValueAsText;
  }
}
