<div class="dx-toolbar-items-container">
  <div class="float-right buttondiv">
    <dx-button id="infobutton" icon="info" [hint]="variantInfoHint" [disabled]="!infoVariantAvailable"
      (click)="refreshVariantInformation()"></dx-button>

    <dx-button id="computevariant" icon="greater" [hint]="language.CalculateVariant" [disabled]="!canComputeVariant"
      (click)="computeVariant()"> </dx-button>

    <dx-button *ngIf="computeChilderenAvailable" id="computevariantwithchilderen" icon="greaterorequal"
      [hint]="language.CalculateVariantIncDescendants" [disabled]="!canComputeVariant"
      (click)="computeVariantIncludingDescendants()">
    </dx-button>
  </div>
</div>

<dx-popup [width]="500" [height]="280" [showTitle]="true" [title]="language.VariantStatusOverview" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [showCloseButton]="true" container=".dx-viewport" [(visible)]="isTooltipVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view showScrollbar="always">
      <span>{{variantCalculationStatusMessage}}</span>
      <ul class="variantDetailList">
        <li *ngFor="let variantInfo of variants">
          <p><b>{{variantInfo.Variant.ShortName}}:</b></p>
          <p>{{variantInfo.State.Description}}</p>
          <p *ngIf="showVariantCalculationInput">{{variantCalculationDataConfigurationText(variantInfo)}}</p>
        </li>
      </ul>
    </dx-scroll-view>
  </div>
</dx-popup>