import { ModelElementDto } from './../../../../../common/models/dto/ModelElementDto-dto';

// t4tsCode gen": "0.8.2.0 for file ModelGroupDto.cs
// Don't adjust manually!

export class ModelGroupDto {
    public GroupId: number;
    public Name: string;
    public Elements: ModelElementDto [] = [];
}

