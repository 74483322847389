import { Observable, Subject, of } from "rxjs";
import { Sorter } from "../../../../../../angular-common";

import { BrowserStorage } from "../../../../../../angular-common/storage/browser-storage";
import { DataFilterDto } from "../../../analysis/dto/DataFilter-dto";
import { ImagineLanguage } from "../../language/imaginelanguage.service";
import { DataFilter } from "./datafilter";
import { DataFilterPeriodTextBuilder } from "./datafilter-periodtext-builder";
import { DataFilterOption } from "./datafilteroption";

export class DataFilterList {
  public static idCounter = 0;
  private _hasBeenInitialized: boolean;

  private subjectOnFiltersLoaded: Subject<DataFilterDto[]>;

  public get filters(): DataFilter[] {
    return this._filters;
  }

  private _filtersBySortOrder: DataFilter[] = [];

  public get filtersBySortOrder(): DataFilter[] {
    return this._filtersBySortOrder;
  }

  private static readonly storageKey: string = "Filter";

  public static createCopy(dataFilterList: DataFilterList): DataFilterList {
    const newFilters: DataFilter[] = [];
    if (dataFilterList && dataFilterList.filters) {
      dataFilterList.filters.forEach((f) => {
        const newFilter = f.createCopy(f);
        newFilters.push(newFilter);
      });
    }

    const newDataFilterList = new DataFilterList(dataFilterList.language);
    newDataFilterList.init(newFilters);
    return newDataFilterList;
  }

  public init(filters: DataFilter[]) {
    if (filters) {
      this._filters.length = 0;
      filters.forEach((element) => {
        this._filters.push(element);
      });
      this.sort();
    }

    this.restoreFromLocalStorage();

    this.recomputedComputedProperties();

    this.filters.forEach((f) => {
      f.options.options.forEach((o) => {
        o.onFilterByChanged.subscribe((o2) => this.onOptionFilterByChanged(o2));
      });
    });
    this._hasBeenInitialized = true;
    this.subjectOnFiltersLoaded.next(DataFilterList.createFilterDtoFromFilterItems(this.filters));
  }

  public id: number;

  public constructor(private language: ImagineLanguage) {
    this.id = DataFilterList.idCounter = DataFilterList.idCounter + 1;
    this._hasBeenInitialized = false;
    this.subjectOnFiltersLoaded = new Subject<DataFilterDto[]>();
    this._filters = [];
  }

  private recomputedComputedProperties() {
    this.periodSelectionText = new DataFilterPeriodTextBuilder(this.language).getPeriodSelectionText(this.filters);
  }

  private sort() {
    Sorter.sortAlphabetically<DataFilter>(this._filters, (x) => x.text);
    this._filtersBySortOrder = this._filters.sort(Sorter.compareSortOrder);
  }
  private _filters: DataFilter[] = [];

  private onOptionFilterByChanged(option: DataFilterOption) {
    this.recomputedComputedProperties();
  }

  public getActiveFilter(): Observable<DataFilterDto[]> {
    if (this._hasBeenInitialized === false) {
      return this.subjectOnFiltersLoaded;
    } else {
      return of(this.getActiveFilterNoLoading());
    }
  }

  public periodSelectionText: string;

  public getActiveFilterNoLoading() {
    return DataFilterList.createFilterDtoFromFilterItems(this.filters);
  }

  private static createFilterDtoFromFilterItems(filterToCreate: DataFilter[]) {
    const result: DataFilterDto[] = [];
    filterToCreate.forEach((f, i) => {
      if (f.hasSelectedOptions) {
        result.push({
          KeyId: f.id,
          ShortName: f.shortName,
          LongName: f.text,
          SortOrder: i,
          Options: f.options.getActiveOptions(),
        });
      }
    });
    return result;
  }

  public apply() {
    this.storeToLocalStorage();
    this._onApplyFilter.next();
  }

  private _onApplyFilter: Subject<void> = new Subject<void>();
  public onApplyFilter = this._onApplyFilter.asObservable();

  private storeToLocalStorage() {
    const dataToStore = this.getActiveFilterNoLoading();
    BrowserStorage.setItem(DataFilterList.storageKey, JSON.stringify(dataToStore));
  }

  private restoreFromLocalStorage() {
    const json = BrowserStorage.getItem(DataFilterList.storageKey);
    if (json === undefined || json === null || json.length === 0) {
      return;
    }

    const dto: DataFilterDto[] = JSON.parse(json);
    if (dto === undefined || dto === null) {
      return;
    }

    dto.forEach((filterDTO) => {
      filterDTO.Options.forEach((optionDTO) => {
        const dataFilter = this.filters.find((f) => f.id === filterDTO.KeyId);
        if (dataFilter !== null && dataFilter !== undefined) {
          const dataOption = dataFilter.options.options.find((o) => o.id === optionDTO.Id);
          if (dataOption !== null && dataOption !== undefined) {
            dataOption.filterBy = optionDTO.FilterBy === true;
          }
        }
      });
    });
  }
}
