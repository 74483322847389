import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthorizationFilterDataModule } from "../services/authorization";

import { RouterModule, Routes } from "@angular/router";
import {
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFormModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxLookupModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxTreeViewModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule,
} from "devextreme-angular";
import { TextPopupModule } from "../../../../angular-common/";
import { canActivateRoleGuard } from "../../../../angular-common/authentication-guard/role-guard";
import { AuthorizationEditModule } from "../../../../angular-common/components/authorization/authorization-edit.module";
import { CardViewModule } from "../../../../angular-common/components/cardview";
import { ModelTreeModule } from "../../../../angular-common/components/modeltree/modeltree.module";
import { ModelTreeServiceModule } from "../../../../angular-common/components/modeltree/modeltreeservice/modeltree-service.module";
import { ImagineRoutesDto } from "../models/dto/ImagineRoutes-dto";
import { AuthorizationFilterBaseModelComponent } from "./authorization-content/filters/authorization-content-filter-base-model.component";
import { AuthorizationFilterRoleComponent } from "./authorization-content/filters/authorization-content-filter-role.component";
import { AuthorizationFilterVariantComponent } from "./authorization-content/filters/authorization-content-filter-variant.component";
import { AuthorizationFilterTabsComponent } from "./authorization-content/filters/authorization-filters-tabs.component";
import { AuthorizationFilterContentComponent } from "./authorization-content/filters/content/authorization-content.component";
import { AuthorizationFilterScreenComponent } from "./authorization-filter-screen.component";

const routes: Routes = [
  {
    path: ImagineRoutesDto.AuthorizationFilters,
    component: AuthorizationFilterScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    DxSelectBoxModule,
    DxLoadIndicatorModule,
    DxTemplateModule,
    DxDataGridModule,
    DxButtonModule,
    DxNumberBoxModule,
    DxToolbarModule,
    DxTabsModule,
    DxListModule,
    DxTreeViewModule,
    DxFormModule,
    DxTextBoxModule,
    CardViewModule,
    DxValidatorModule,
    DxAccordionModule,
    DxValidationGroupModule,
    DxRadioGroupModule,
    DxValidationSummaryModule,
    DxPopupModule,
    DxLookupModule,
    DxCheckBoxModule,
    TextPopupModule,
    AuthorizationFilterDataModule,
    ModelTreeModule,
    ModelTreeServiceModule,
    AuthorizationEditModule,
  ],
  declarations: [
    AuthorizationFilterScreenComponent,
    AuthorizationFilterTabsComponent,
    AuthorizationFilterRoleComponent,
    AuthorizationFilterVariantComponent,
    AuthorizationFilterBaseModelComponent,
    AuthorizationFilterContentComponent,
  ],
  providers: [],
})
export class AuthorizationScreenModule {}
