import { DashletValueFormatting } from "./dashlet-value-formatting.component";
import { DataItemByNonVariantCluster } from "../../services/dashletview";

export class DashletGridComponentRow {
  public get name() {
    if (this.clusterValueAvailable()) {
      return this.data.cluster.value;
    } else if (this.data.point1 && this.data.point1.name) {
      return this.data.point1.name;
    } else if (this.data.point2 && this.data.point2.name) {
      return this.data.point2.name;
    }
    return "";
  }

  public get point1(): number {
    return this.data.point1.value;
  }

  public get point2(): number {
    if (this.data.point2) {
      return this.data.point2.value;
    }
    return;
  }

  public get formatString(): string {
    return DashletValueFormatting.retrieveFormatByDto(this.data.point1.displayFormat);
  }

  public get difference() {
    return this.getCorrectPointAttrValue("difference");
  }

  public get differenceRelative() {
    return this.getCorrectPointAttrValue("differenceRelative");
  }

  constructor(private data: DataItemByNonVariantCluster) {}

  public clusterValueAvailable() {
    return this.data && this.data.cluster && this.data.cluster.isDataSourceCluster === false && this.data.cluster.value;
  }

  public getCorrectPoint(pointName: string) {
    return this.retrievePointValue(pointName);
  }

  public getCorrectPointAttrValue(requiredAttribute: string) {
    const val = this.retrievePointValue("point1", requiredAttribute);
    return val ? val : this.retrievePointValue("point2", requiredAttribute);
  }

  private retrievePointValue(point: string, attribute?: string) {
    if (this.data[point]) {
      if (attribute) {
        const val = this.data[point][attribute];
        if (val) {
          return val.value;
        }
      } else {
        return this.data[point];
      }
    }
  }
}
