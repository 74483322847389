import { LongNameShortName } from "../../../../../angular-common/longnameshortname/longname-shortname";
import { ILongNameShortName } from "../../../../../angular-common/longnameshortname/longname-shortname.interface";
import { IntegrationServiceLanguage } from "../../../../../common/language/integrationservicelanguage.service";
import { DateTranslator } from "../../../../../common/modelelements/date-translator";
import { DateTimeDto } from "../../../../../common/models/dto/DateTimeDto-dto";
import { EventTimerInformationDto } from "../../../../../common/models/integrationservices/dto/EventTimerInformationDto-dto";
import { EventTimerTypeDto } from "../../../../../common/models/integrationservices/dto/EventTimerType-dto";
import { RecurrenceTypeDto } from "../../../../../common/models/integrationservices/dto/RecurrenceType-dto";
import { ImagineFeaturesModel } from "../../imagine-features-model";
import { EventTimerData } from "./event-timer-data";
import { EventTimerHistory } from "./event-timer-history";
import { EventTypeTranslator } from "./event-type-translator";
import { RecurrenceTypeTranslator } from "./recurrence-type-translator";

export class EventTimerInformation implements ILongNameShortName {
  public keyId: number;
  public longName: string;
  public shortName: string;
  public eventType: EventTimerTypeDto;
  public availableRecurrenceTypes: string[] = [];
  public selectedRecurrenceType: RecurrenceTypeDto;
  public startDate: Date;
  public startTime: Date;
  public active: boolean;
  public eventTimerHistory: EventTimerHistory = new EventTimerHistory();
  public eventTimerData: EventTimerData = new EventTimerData();

  private recurranceTranslator: RecurrenceTypeTranslator;
  private eventTypeTranslator: EventTypeTranslator;

  public get name() {
    return LongNameShortName.NameFor(this);
  }

  public get isNew() {
    return this.keyId === -1;
  }

  public get eventTypeText() {
    return this.eventTypeTranslator.toText(this.eventType);
  }

  public get selectedRecurrenceTypeText() {
    return this.recurranceTranslator.toText(this.selectedRecurrenceType);
  }

  public get timeRunText() {
    if (this.eventTimerHistory && this.eventTimerHistory.timesRun > 0) {
      return this.eventTimerHistory.timesRun;
    }

    return "";
  }

  constructor(private integrationLanguage: IntegrationServiceLanguage, featureSettings: ImagineFeaturesModel) {
    this.recurranceTranslator = new RecurrenceTypeTranslator(integrationLanguage);
    this.eventTypeTranslator = new EventTypeTranslator(integrationLanguage, featureSettings);
    this.keyId = -1;
  }

  public toDto(): EventTimerInformationDto {
    const dto = new EventTimerInformationDto();

    dto.KeyId = this.keyId;
    dto.AvailableRecurrenceTypes = this.availableRecurrenceTypes.map((t) => this.recurranceTranslator.toValue(t));
    dto.LongName = this.longName;
    dto.ShortName = this.shortName;
    dto.SelectedRecurrenceType = this.selectedRecurrenceType;
    dto.EventType = this.eventTypeTranslator.toValue(this.eventTypeText);
    dto.Start = new DateTimeDto();
    dto.Start.DateAsSortableNumber = DateTranslator.toDto(this.startDate).DateAsSortableNumber;
    dto.Start.TimeAsSortableNumber = DateTranslator.toDto(this.startTime).TimeAsSortableNumber;
    dto.Active = this.active;

    dto.EventTimerHistory = EventTimerHistory.toDto(this.eventTimerHistory);
    dto.EventTimerData = EventTimerData.toDto(this.eventTimerData);
    return dto;
  }

  public fromDto(dto: EventTimerInformationDto) {
    this.keyId = dto.KeyId;
    this.availableRecurrenceTypes = dto.AvailableRecurrenceTypes.map((r) => this.recurranceTranslator.toText(r));
    this.selectedRecurrenceType = dto.SelectedRecurrenceType;
    this.longName = dto.LongName;
    this.shortName = dto.ShortName;
    this.eventType = dto.EventType;

    this.active = dto.Active;
    this.startDate = DateTranslator.fromDateDto(dto.Start);
    this.startTime = DateTranslator.fromDto(dto.Start);

    const history = new EventTimerHistory();
    history.copyFromDto(dto.EventTimerHistory, this.integrationLanguage);

    this.eventTimerHistory = history;

    const data = new EventTimerData();
    data.fromDto(dto.EventTimerData);

    this.eventTimerData = data;
  }
}
