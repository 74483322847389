import { ISortOrder } from "../../../../../../angular-common";
import { Subject } from "rxjs";

export class DataFilterOption implements ISortOrder {
  public constructor(public id: number, public text: string, public sortOrder: number, private _filterBy: boolean) {}

  public get filterBy(): boolean {
    return this._filterBy;
  }
  public set filterBy(newValue: boolean) {
    const trigger: boolean = newValue !== this._filterBy;
    this._filterBy = newValue;
    if (trigger) {
      this._onFilterByChanged.next(this);
    }
  }

  private _onFilterByChanged: Subject<DataFilterOption> = new Subject<DataFilterOption>();
  public onFilterByChanged = this._onFilterByChanged.asObservable();
}
