import { Component, Input } from "@angular/core";
import { DashletView } from "../../services/dashletview";
import { FilterListService } from "../../services/filter/filter-list-service";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashlet-warning",
  templateUrl: "./dashlet-warning.component.html",
  styleUrls: ["./dashlet-warning.component.scss"],
})
export class DashletWarningComponent {
  public constructor(public language: ImagineLanguage, private filterListService: FilterListService) {}

  @Input() dashletView: DashletView;

  public get shouldShowWarning(): boolean {
    if (this.dashletView && this.dashletView.dashletData) {
      return this.dashletView.dashletData.missingFunctionalFilterElements.length > 0;
    }
    return false;
  }

  public get dashletWarningMessage(): string {
    const filtersDisplayNames = this.getFiltersDisplayNames();
    return this.language.dashletMissingFunctionalFilterElementsWarning(filtersDisplayNames.join(", "));
  }

  private getFiltersDisplayNames(): string[] {
    return [...new Set(this.dashletView.dashletData.missingFunctionalFilterElements.map((m) => this.getFilterTextByShortName(m)))];
  }

  private getFilterTextByShortName(shortName: string): string {
    const filter = this.filterListService.filterList.filters.find((f) => f.shortName === shortName);

    if (filter === undefined) {
      return shortName;
    }

    return filter.text;
  }
}
