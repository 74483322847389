import { SelectBoxItems } from "../../../../../angular-common/components/selectbox/selectbox-items";
import { DashletTypeDto } from "../../analysis/dto/DashletType-dto";
import { DashletViewTypeDto } from "../../analysis/dto/DashletViewType-dto";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";

export class DashboardDesignListBuilder {
  public static viewtypelistSelectBox(language: ImagineLanguage, dashletType: DashletTypeDto) {
    const newTypeSelector = new SelectBoxItems();
    if (dashletType === DashletTypeDto.Chart) {
      newTypeSelector.addNew(DashletViewTypeDto.BarChart, language.viewTypeBarChart, "fas fa-chart-bar");
      newTypeSelector.addNew(DashletViewTypeDto.PieChart, language.viewTypePieChart, "fas fa-chart-pie");
      newTypeSelector.addNew(DashletViewTypeDto.RotatedBarChart, language.viewTypeRotatedBarChart, "fas fa-chart-bar fa-rotate-90");
      newTypeSelector.addNew(DashletViewTypeDto.LineChart, language.viewTypeLineChart, "fas fa-chart-line");
      newTypeSelector.addNew(DashletViewTypeDto.SplineChart, language.viewTypeSplineChart, "fas fa-chart-line");
      newTypeSelector.addNew(DashletViewTypeDto.SteplineChart, language.viewTypeSteplineChart, "fas fa-chart-line");
      newTypeSelector.addNew(DashletViewTypeDto.AreaChart, language.viewTypeAreaChart, "fas fa-chart-area");
      newTypeSelector.addNew(DashletViewTypeDto.LineWithoutMarkersChart, language.viewTypeLineWithoutMarkersChart, "fas fa-chart-line");
      newTypeSelector.addNew(DashletViewTypeDto.Grid, language.viewTypeGrid, "fas fa-list");
    }
    if (dashletType === DashletTypeDto.Detail) {
      newTypeSelector.addNew(DashletViewTypeDto.Grid, language.viewTypeGrid, "fas fa-list");
      newTypeSelector.addNew(DashletViewTypeDto.Pivot, language.viewTypePivot, "fas fa-table");
    }
    return newTypeSelector;
  }
}
