
// t4tsCode gen": "0.8.2.0 for file DashletType.cs
// Don't adjust manually!
export enum DashletTypeDto {
        Unknown = 0,
        Detail = 1,
        Statistic = 2,
        Chart = 3,
}

export class DashletTypeExtensionsDto {
}

