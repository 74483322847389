import { Component, EventEmitter, Input, Output } from "@angular/core";

import { AdaptLanguage } from "../../services/language/adaptlanguage.service";
import { ModelsGroup } from "../models/modelgroups-group.model";

@Component({
  selector: "app-modelgroup-edit-filter",
  templateUrl: "./modelgroup-edit-filter.component.html",
  styleUrls: ["./modelgroup-edit-filter.component.css"],
})
export class ModelgroupEditFilterComponent {
  constructor(public language: AdaptLanguage) {
    this.groupSelected = new EventEmitter<ModelsGroup>();
  }

  @Input()
  public modelGroups: ModelsGroup[];

  public selectGroup(groupId: number) {
    this.modelGroups.forEach((r) => {
      if (groupId === r.groupId) {
        this.groupSelected.emit(r);
      }
    });
  }

  @Output()
  public groupSelected: EventEmitter<ModelsGroup>;
}
