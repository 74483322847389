import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DataFilterList } from "../../services/filter";

import { Palettes } from "../../../../../angular-common/components/palettes";
import { DevExpressExcelExport } from "../../../../../angular-common/devextreme/dev-express-export";
import { DashletWithData } from "../../services/dashletdata/models/dashlet-data.model";
import { DashletView } from "../../services/dashletview";
import { DashletDataInspection } from "../../services/dashletview/models/dashlet-data-inspection";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { DashletGridComponentRow } from "./dashlet-grid-component-row";
import { DashletValueFormatting } from "./dashlet-value-formatting.component";

@Component({
  selector: "app-dashlet-grid",
  templateUrl: "./dashlet-grid.component.html",
})
export class DashletGridComponent implements OnChanges {
  public rows: DashletGridComponentRow[];
  public rowDetails: DashletGridComponentRow[];
  public cols: any[];

  public dataInspection: DashletDataInspection;

  public get headerName() {
    if (this.dataInspection.dashletWithData.clusteredBy.length > 0) {
      return this.dataInspection.dashletWithData.clusteredBy[0].name;
    } else {
      return this.language.dashletGridHeaderName;
    }
  }

  public get valueColumn1() {
    if (this.dataInspection.isVariantComparison) {
      return this.dataInspection.variant1Name;
    } else {
      return this.language.dashletGridHeaderValue;
    }
  }

  public get variantName2() {
    if (this.dataInspection.isVariantComparison) {
      return this.dataInspection.variant2Name;
    } else {
      return "";
    }
  }

  public constructor(public palettes: Palettes, public language: ImagineLanguage) {
    this.rows = [];
    this.rowDetails = [];
    this.dataInspection = new DashletDataInspection(undefined);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadData();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: this.dashletView.name,
    });
  }

  @Input() dashletView: DashletView;
  @Input() dashletWithData: DashletWithData;
  @Input() filterList: DataFilterList;

  private loadData() {
    this.dataInspection = new DashletDataInspection(this.dashletWithData);
    const reshaped = this.dataInspection.retrieveDataWithPerVariantData();
    const seriesResult = [];
    reshaped.forEach((r) => {
      const barItem = new DashletGridComponentRow(r);
      seriesResult.push(barItem);
    });
    this.rows = seriesResult;
    this.rowDetails = seriesResult;
  }

  public get hasData() {
    if (this.rows && this.rows.length > 0) {
      return true;
    }
    return false;
  }

  configureExport(options) {
    if (options.gridCell.rowType === "data") {
      const column = options.gridCell.column;

      if (column === undefined) {
        return;
      }

      const cellData: DashletGridComponentRow = options.gridCell.data;
      const dataFieldName = column.dataField;

      options.numberFormat = column.format;

      if (dataFieldName === "point1" || dataFieldName === "point2") {
        const foundPoint = cellData.getCorrectPoint(dataFieldName);
        options.value = foundPoint.value;
      } else if (dataFieldName === "name") {
        options.value = cellData[dataFieldName];
      } else {
        const foundPoint = cellData.getCorrectPointAttrValue(dataFieldName);
        options.value = foundPoint;
      }
    }
  }

  public get percentageFormat() {
    // Two for precision and two because we do some division by 100 or multiply stuff with percentages...
    const precision = 2 + 2;
    return DashletValueFormatting.retrieveFormat(DashletValueFormatting.percentageType, precision);
  }

  public get formatForValues() {
    for (const row of this.rowDetails) {
      if (row.formatString !== null && row.formatString !== undefined) {
        return row.formatString;
      }
    }

    return DashletValueFormatting.retrieveFormat(DashletValueFormatting.currencyType, 2);
  }

  public onExporting(e: any) {
    DevExpressExcelExport.onDataGridExporting(e, this.dashletView.name);
  }
}
