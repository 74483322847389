import { DataObjectDto } from "../../../../../../common/models/dto/DataObject-dto";

export class DataRow {

  public static copyProperties(source: any, target: any) {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  }

  public static setFieldValues(target: DataRow, fields: string[], value: any) {
    fields.forEach(field => {
      target[field] = value;
    });
  }
  public constructor() {}

  public copyFromDto(dto: DataObjectDto) {
    DataRow.copyProperties(dto, this);
  }

  public toDto(): DataObjectDto {
    const result = new DataObjectDto();
    DataRow.copyProperties(this, result);
    return result;
  }

  public copyFrom(source: DataRow) {
    DataRow.copyProperties(source, this);
  }
}
