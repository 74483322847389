<div *ngIf="tabs" class="dashboarding-design">
  <div class="dashboarding-tabs">
    <dx-tabs #dashboardTabs [dataSource]="tabs.items" [keyExpr]="tabs.tabKeyExpr" [(selectedItemKeys)]="tabs.selectedTabKeys"></dx-tabs>
  </div>

  <div>
    <app-dashlet-definition-design *ngIf="tabs.selectedTab && tabs.selectedTab.id === dashletDefinitionTabId"></app-dashlet-definition-design>
    <app-dashboard-design *ngIf="tabs.selectedTab && tabs.selectedTab.id === dashboardTabId"></app-dashboard-design>
  </div>
</div>
