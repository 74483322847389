
// t4tsCodeFromCs": "0.8.2.0 for file ProcessingStateType.cs
// Don't adjust manually!
export enum ProcessingStateTypeDto {
        None = 0,
        Queued = 1,
        Processing = 2,
        Completed = 3,
        Error = 4,
}

export class ProcessingStateTypeExtensionsDto {
}

