import { RouterModule, Routes } from "@angular/router";
import {
  DxAccordionModule,
  DxButtonModule,
  DxCalendarModule,
  DxCheckBoxModule,
  DxDateBoxModule,
  DxFormModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTextBoxModule,
  DxToastModule,
  DxValidatorModule,
} from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { canActivateRoleGuard } from "../../../../angular-common/authentication-guard/role-guard";
import { CardViewModule } from "../../../../angular-common/components/cardview";
import { ModelTreeModule } from "../../../../angular-common/components/modeltree/modeltree.module";
import { ModelTreeServiceModule } from "../../../../angular-common/components/modeltree/modeltreeservice/modeltree-service.module";
import { ToasterModule } from "../../../../angular-common/components/toaster/toaster-module";
import { DataSupplyRoutesDto } from "../import/dto/DataSupplyRoutes-dto";
import { ImportFileModule } from "../shared/import-file/import-file.module";
import { DataSupplyContentComponent } from "./data-supply-content/data-supply-content.component";
import { DataSupplyContentComponentImportComponent } from "./data-supply-content/data-supply-content.component-import";
import { DataSupplyContentComponentItemComponent } from "./data-supply-content/data-supply-content.component-item";
import { DataSupplyDatasetSelectorComponent } from "./data-supply-content/data-supply-dataset-selector.component";
import { DataSupplyPeriodSelectorComponent } from "./data-supply-content/data-supply-period-selector.component";
import { DataSupplyVariantSelectorComponent } from "./data-supply-content/data-supply-variantselection.component";
import { DataSupplyScreenComponent } from "./data-supply-screen.component";
import { DataSuppliesStatusComponent } from "./data-supply-status/data-supplies-status";
import { DataSupplyStatusDetailComponent } from "./data-supply-status/data-supply-status-detail";
import { DataSupplyDetailSummaryComponent } from "./data-supply-status/data-supply-status-summary";
import { DataSupplyTabsComponent } from "./data-supply-tabs/data-supply-tabs.component";

const routes: Routes = [
  {
    path: DataSupplyRoutesDto.DataSupplyScreen,
    component: DataSupplyScreenComponent,
    canActivate: [canActivateRoleGuard],
  },
  {
    path: DataSupplyRoutesDto.DataSupplyDetail + "/:id",
    component: DataSupplyStatusDetailComponent,
    canActivate: [canActivateRoleGuard],
  },
  {
    path: DataSupplyRoutesDto.DataSupply + "/:id",
    component: DataSupplyContentComponentImportComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    DxTabsModule,
    CardViewModule,
    ImportFileModule,
    DxButtonModule,
    DxAccordionModule,
    DxFormModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxToastModule,
    DxCalendarModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    DxPopupModule,
    ModelTreeModule,
    ModelTreeServiceModule,
    ToasterModule,
  ],
  declarations: [
    DataSupplyScreenComponent,
    DataSupplyTabsComponent,
    DataSupplyContentComponent,
    DataSupplyContentComponentImportComponent,
    DataSuppliesStatusComponent,
    DataSupplyStatusDetailComponent,
    DataSupplyDetailSummaryComponent,
    DataSupplyDatasetSelectorComponent,
    DataSupplyPeriodSelectorComponent,
    DataSupplyVariantSelectorComponent,
    DataSupplyContentComponentItemComponent,
  ],
  providers: [],
})
export class DataSupplyScreenModule {}
