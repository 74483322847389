import { DataSetFilterElementDto } from './DataSetFilterElementDto-dto';
import { RoleDto } from './../../../../../common/models/dto/RoleDto-dto';
import { VariantDto } from './../../../../../common/models/dto/VariantDTO-dto';

// t4tsCode gen": "0.8.2.0 for file DataSetFilterDto.cs
// Don't adjust manually!

export class DataSetFilterDto {
    public Id: number;
    public Name: string;
    public Role: RoleDto;
    public Elements: DataSetFilterElementDto [] = [];
    public Variant: VariantDto;
}

