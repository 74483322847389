import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { WebLanguage } from "../../../../common/language/weblanguage.service";
import { ElementHelper } from "../../../helpers/element.helper";
import { ConfigurationWizardAction } from "../models/wizardaction";
import { ConfigurationWizardStage } from "../models/wizardstage";

@Component({
  selector: "app-configuration-wizard-stage",
  templateUrl: "config-wizard-stage-screen.component.html",
  styleUrls: ["config-wizard-stage-screen.component.scss"],
})
export class ConfigWizardStageScreenComponent implements OnChanges {
  constructor(public language: WebLanguage) {}

  ngOnChanges(): void {
    this.actions = [];
    this.createItems();
  }

  public actions: any = [];

  public get isReady() {
    if (this.stage && this.actions) {
      if (this.stage.actions.length > 0 && this.actions.length > 0) {
        return true;
      }
    }
    return false;
  }

  @Input() stage: ConfigurationWizardStage;
  @Output() actionsValidated = new EventEmitter<boolean>(true);

  public actionValueChanged(e: { dataField: string; value: any }) {
    const action = this.findAction(e.dataField);
    if (ElementHelper.isNullOrUndefined(e.value) === false && action !== undefined) {
      action.value = e.value;
    }

    const notValidatedActions: number[] = [];
    this.stage.actions.forEach((a) => {
      if (a.hasValue === false && a.isOptional === false) {
        notValidatedActions.push(a.order);
      }
    });

    if (notValidatedActions.length === 0) {
      this.actionsValidated.emit(true);
    } else {
      this.actionsValidated.emit(false);
    }
  }

  private createItems() {
    this.stage.actions.forEach((action) => {
      var a = {
        dataField: action.actionId,
        editorType: action.type.toString(),
        label: {
          text: action.label,
        },
        editorOptions: action.typeOptions,
      };
      this.actions.push(a);
    });
  }

  private findAction(id: string): ConfigurationWizardAction {
    const result = this.stage.actions.find((x) => x.actionId === id);
    if (ElementHelper.isNullOrUndefined(result) === false) {
      return result;
    } else {
      return undefined;
    }
  }
}
