import { DxCheckBoxModule, DxFileUploaderModule, DxFormModule, DxPopoverModule, DxSelectBoxModule } from "devextreme-angular";

import { CardViewModule } from "../../../../../angular-common/components/cardview";
import { CommonModule } from "@angular/common";
import { ImportFileComponent } from "./import-file.component";
import { LanguageSelectionModule } from "../../../../../angular-common/components/languageselection/language-selection.module";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [CommonModule, DxCheckBoxModule, DxFileUploaderModule, DxSelectBoxModule, RouterModule, LanguageSelectionModule, CardViewModule, DxFormModule, DxPopoverModule],
  declarations: [ImportFileComponent],
  exports: [ImportFileComponent],
})
export class ImportFileModule {}
