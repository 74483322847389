import { ISortOrder } from "../../../../../../angular-common/sorting/sortorder";
import { DataFilterOptionList } from "./datafilteroption-list";

export class DataFilter implements ISortOrder {
  public constructor(public id: number, public shortName: string, public text: string, public isAnalysisCluster: boolean, public sortOrder: number, public options: DataFilterOptionList) {}

  public get hasSelectedOptions(): boolean {
    let result = false;
    this.options.options.filter((element) => (element.filterBy === true ? (result = true) : null));
    return result;
  }

  public assignFrom(inputFilter: DataFilter) {
    if (inputFilter) {
      this.isAnalysisCluster = inputFilter.isAnalysisCluster;
      this.options.options.forEach((o) => {
        o.filterBy = inputFilter.options.options.find((x) => x.id === o.id).filterBy;
      });
    }
  }

  public summaryMaxChars = 50;

  public get description(): string {
    let result = "";

    for (const option of this.options.options) {
      if (option.filterBy === true) {
        if (result === "") {
          result = option.text;
        } else {
          result = result + ", " + option.text;
        }
      }
    }

    return result;
  }

  public get summary(): string {
    const result = this.description;
    if (result.length > this.summaryMaxChars) {
      return result.substring(0, this.summaryMaxChars - 3) + "...";
    }
    return result;
  }

  public createCopy(filter: DataFilter): DataFilter {
    const newFilter = new DataFilter(this.id, this.shortName, this.text, this.isAnalysisCluster, this.sortOrder, DataFilterOptionList.createCopy(this.options));
    return newFilter;
  }
}
