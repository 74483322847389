import { EnvironmentSettings } from "../../baseservice/environment-settings";
import { ExportDefinition } from "./models/export-definition.model";
import { ExportDefinitionDto } from './dto/ExportDefinition-dto';
import { ExportDefinitions } from "./models/export-definitions.model";
import { HttpClient } from "@angular/common/http";
import { IDownloadHandler } from "./download-handler";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlBaseService } from "../../baseservice/plbase.service";
import { PlLoadIndicator } from "../../loadindicator/loadindicator";
import { WebLanguage } from "../../../common/language/weblanguage.service";
import { map } from "rxjs/operators";

@Injectable()
export class ExportingService extends PlBaseService implements IDownloadHandler {
  private endPoint: string = "ExportDefinition";

  public constructor(http: HttpClient, private language: WebLanguage, envSettings: EnvironmentSettings, public loadIndicator: PlLoadIndicator) {
    super(http, envSettings, loadIndicator);
  }

  public getExportDefinitions(): Observable<ExportDefinitions> {
    const result = this.getDataTransformed<ExportDefinitionDto[]>(this.endPoint).pipe(map(r => this.transformDefinitions(r)));
    return result;
  }

  private transformDefinitions(dto: ExportDefinitionDto[]): ExportDefinitions {
    if (dto) {
      const result = new ExportDefinitions(this, []);
      result.copyFromDto(dto);
      return result;
    } else {
      return null;
    }
  }

  public download(definition: ExportDefinition): Observable<ArrayBuffer> {
    const url = this.endPoint + "/" + definition.shortName;
    return this.getDataAsArrayBuffer(url);
  }
}
