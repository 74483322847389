import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";

import { Sorter } from "../../../../../angular-common";
import { LongNameShortName } from "../../../../../angular-common/longnameshortname/longname-shortname";
import { ClusterDataFilterDto } from "../../analysis/dto/ClusterDataFilter-dto";
import { FilterListService } from "../../services/filter/filter-list-service";
import { FilterService } from "../../services/filter/filter.service";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { AnalysisCluster } from "./analysis-cluster";
import { AnalysisClusterMove } from "./analysis-cluster-move";
import { AnalysisClusterService } from "./analysis-cluster.service";

@Component({
  selector: "app-cluster-edit-screen",
  templateUrl: "./cluster-edit-screen.component.html",
  styleUrls: ["./cluster-edit-screen.component.scss"],
})
export class ClusterEditScreenComponent implements OnChanges, OnInit {
  public displayValue = "shortName";
  public searchValue = "shortName";

  public moveImplementation: AnalysisClusterMove;
  private filtersList: ClusterDataFilterDto[] = [];
  public originalListing: AnalysisCluster[] = [];
  public availableList: AnalysisCluster[] = [];

  constructor(public language: ImagineLanguage, private analysisClusterService: AnalysisClusterService, private filterService: FilterService, private filterListService: FilterListService) {
    this.moveImplementation = new AnalysisClusterMove([]);

    this.displayValue = LongNameShortName.DisplayFieldPresentable();
  }

  public get editingList(): AnalysisCluster[] {
    return this.moveImplementation.clusters;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.availableList = this.determineAvailableOptions();
    this.groupChanged();
  }

  ngOnInit(): void {
    this.filterService.getAllClusters().subscribe((fs) => {
      this.filtersList = fs;
      this.loadClusters(fs.filter((c) => c.IsAnalysisCluster).map((fe) => ClusterEditScreenComponent.createFromFilter(fe)));
      this.availableList = this.determineAvailableOptions();
      this.groupChanged();
    });
  }

  public loadClusters(values: AnalysisCluster[]) {
    const editingNew: AnalysisCluster[] = [];

    values.forEach((org) => {
      editingNew.push(ClusterEditScreenComponent.createCopy(org));
    });
    this.originalListing = values;
    Sorter.sortSortOrderThenAlpha(editingNew, (r) => r.shortName);
    this.moveImplementation.clusters = editingNew;
  }

  static createCopy(org: AnalysisCluster): AnalysisCluster {
    var item = new AnalysisCluster();

    if (org) {
      item.shortName = org.shortName;
      item.id = org.id;
      item.sortOrder = org.sortOrder;
      item.longName = org.longName;
    }

    return item;
  }
  static createFromFilter(org: ClusterDataFilterDto): AnalysisCluster {
    var item = new AnalysisCluster();

    if (org) {
      item.shortName = org.ShortName;
      item.id = org.KeyId;
      item.sortOrder = org.SortOrder;
      item.longName = org.LongName;
    }

    return item;
  }

  public listingValuesChanged() {
    this.groupChanged();
  }

  public listingOrderChanged(selectedItems: AnalysisCluster[]) {
    if (selectedItems) {
      selectedItems.forEach((element, i) => {
        element.sortOrder = i;
      });

      this.moveImplementation.clusters = selectedItems;
      this.groupChanged();
    }
  }

  public groupChanged() {
    this.hasChanges = this.computeHasChangesInGroup();
  }

  private determineAvailableOptions(): AnalysisCluster[] {
    if (this.filtersList === undefined) {
      return [];
    }

    const allOptions = this.filtersList || [];
    const usedElements = this.editingList;
    const availableOptions: AnalysisCluster[] = [];

    allOptions.forEach((option) => {
      const el = usedElements.find((element) => element.shortName === option.ShortName);
      if (el === undefined) {
        var newDefinition = ClusterEditScreenComponent.createFromFilter(option);
        availableOptions.push(newDefinition);
      }
    });
    return availableOptions;
  }

  public hasChanges: boolean;

  private computeHasChangesInGroup(): boolean {
    const edittingGroup = this.moveImplementation.clusters;
    if (!edittingGroup || !this.editingList) {
      return false;
    }

    if (edittingGroup.length !== this.editingList.length) {
      return true;
    } else {
      for (let i = 0; i < edittingGroup.length; i++) {
        const currentElement = edittingGroup[i];
        const originalElement = this.editingList[i];
        if (currentElement.shortName !== originalElement.shortName) {
          return true;
        }
      }
      return false;
    }
  }

  public saveSelection() {
    this.analysisClusterService.updateAnalysisClusters(this.moveImplementation.clusters).subscribe((g) => {
      this.loadClusters(g);
      this.groupChanged();
      this.filterListService.reloadFiltersFromApi();
    });
  }
}
