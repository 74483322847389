import { DashletViewSettingsDto } from "../../../analysis/dto/DashletViewSettingsDto-dto";
import { DataSerieItem } from "../../../dashboarding-screen/dashlet/dataserie-item";
import { ElementHelper } from "../../../../../../angular-common/helpers/element.helper";

export class DashletViewSettings {
  private _rotateAxisValues = false;
  private _maximumVisibleItems = 10;
  private _showScrollbar = false;
  private _currentClusterNames: string[] = [];
  public _dashletViewId = -1;
  private _showPivotChart = false;
  private _chartLayout: string = "";

  public get chartLayout(): string {
    return this._chartLayout;
  }

  public set chartLayout(v: string) {
    this._chartLayout = v;
  }

  public get showPivotChart(): boolean {
    return this._showPivotChart;
  }

  public set showPivotChart(v: boolean) {
    this._showPivotChart = v;
  }

  public get rotateAxisValues(): boolean {
    return this._rotateAxisValues;
  }
  public set rotateAxisValues(newValue: boolean) {
    this._rotateAxisValues = newValue;
  }

  public get maximumVisibleItems(): number {
    return this._maximumVisibleItems;
  }
  public set maximumVisibleItems(newValue: number) {
    if (newValue > 0 && newValue !== this._maximumVisibleItems) {
      this._maximumVisibleItems = newValue;
      this.updateScrollRange();
    }
  }

  public get showScrollbar(): boolean {
    return this._showScrollbar;
  }
  public set showScrollbar(newValue: boolean) {
    if (this._showScrollbar !== newValue) {
      this._showScrollbar = newValue;
      this.updateScrollRange();
    }
  }

  public scrollRange: string[] = [];

  public update(data: DataSerieItem[]) {
    if (data !== undefined && data !== null && data.length > 0) {
      // Get distinct cluster names
      const newClusterNames = Array.from(new Set(data.map((d) => d.clusterName)));
      let isSame = newClusterNames.length === this._currentClusterNames.length;
      let i = 0;
      while (isSame && i < newClusterNames.length) {
        isSame = newClusterNames[i] === this._currentClusterNames[i];
        i++;
      }

      if (!isSame) {
        this._currentClusterNames = newClusterNames;
        this.updateScrollRange();
      }
    } else {
      if (this._currentClusterNames.length > 0) {
        this._currentClusterNames = [];
        this.updateScrollRange();
      }
    }
  }

  private updateScrollRange() {
    if (this.showScrollbar && this._currentClusterNames.length > this.maximumVisibleItems) {
      this.scrollRange = [this._currentClusterNames[0], this._currentClusterNames[this.maximumVisibleItems - 1]];
    } else {
      this.showScrollbar = false;
      this.scrollRange = [];
    }
  }

  public getCopy() {
    const copy = new DashletViewSettings();
    copy._currentClusterNames = this._currentClusterNames;
    copy._dashletViewId = this._dashletViewId;
    copy._maximumVisibleItems = this._maximumVisibleItems;
    copy._rotateAxisValues = this._rotateAxisValues;
    copy._showPivotChart = this._showPivotChart;
    copy._showScrollbar = this._showScrollbar;
    copy._chartLayout = this._chartLayout;

    return copy;
  }

  public fromDto(dto: DashletViewSettingsDto) {
    if (ElementHelper.isNullOrUndefined(dto) == false) {
      this._maximumVisibleItems = dto.MaximumVisibleItems;
      this._rotateAxisValues = dto.RotateAxisValues;
      this._showScrollbar = dto.ShowScrollbar;
      this._dashletViewId = dto.DashletViewId;
      this._showPivotChart = dto.ShowPivotChart;
      this._chartLayout = dto.ChartLayout;
    }
  }

  public toDto(): DashletViewSettingsDto {
    var dto = new DashletViewSettingsDto();
    dto.DashletViewId = this._dashletViewId;
    dto.RotateAxisValues = this._rotateAxisValues;
    dto.ShowScrollbar = this._showScrollbar;
    dto.MaximumVisibleItems = this._maximumVisibleItems;
    dto.ShowPivotChart = this._showPivotChart;
    dto.ChartLayout = this._chartLayout;
    return dto;
  }
}
