import { WizardActionType } from "./wizard-action-types";
import { ConfigurationWizardAction } from "./wizardaction";

export class ConfigurationWizardActionDate extends ConfigurationWizardAction {
    private _mode: DxDateTimeType;

    private get timeFormatType(): DxDateTimeType {
        return this._mode;
    }
    private set timeFormatType(val: DxDateTimeType) {
        this._mode = val;
        this.typeOptions["type"] = val.toString();
    }

    constructor(actionId: string, order: number, label: string){
        super(actionId, WizardActionType.dxDateBox, order, label);

        this.timeFormatType = DxDateTimeType.dateTime;
        this.value = new Date();
    }

    public get includeTime() {
        return this.timeFormatType === DxDateTimeType.timeOnly || this.timeFormatType === DxDateTimeType.dateTime;
    }

    public set includeTime(value: boolean) {
        this.setMode(value, this.includeDate);
    }

    public get includeDate() {
        return this.timeFormatType === DxDateTimeType.dateOnly || this.timeFormatType === DxDateTimeType.dateTime;
    }

    public set includeDate(value: boolean) {
        this.setMode(this.includeTime, value);
    }    

    private setMode(includeTime: boolean, includeDate: boolean) {
        if (includeTime) {
            if (includeDate === false) {
                this.timeFormatType = DxDateTimeType.timeOnly;
            } else {
                this.timeFormatType = DxDateTimeType.dateTime;
            }
        } else {
            this.timeFormatType = DxDateTimeType.dateOnly;
        }
    }
}

export enum DxDateTimeType {
    dateTime = "datetime",
    timeOnly = "time",
    dateOnly = "date",
}