import { Component, EventEmitter, Input, Output } from "@angular/core";

import { ElementHelper } from "../../../../../../angular-common/helpers/element.helper";
import { ModelPresentableElement } from "../../../../../../common/model/models/model-presentablel-element-model";
import { ElementTypeDto } from "../../../../../../common/models/dto/ElementType-dto";
import { ImagineLanguage } from "../../../services/language/imaginelanguage.service";

@Component({
  selector: "app-dashlet-variant-selector",
  templateUrl: "dashlet-definition-variant-selection.component.html",
  styleUrls: ["dashlet-definition-variant-selection.component.scss"],
})
export class DataSupplyVariantSelectorComponent {
  constructor(public language: ImagineLanguage) {
    this.allowedElementTypes = [];
    this.allowedElementTypes.push(ElementTypeDto.Variant);
    this.allowedElementTypes.push(ElementTypeDto.Aggregation);
  }

  public allowedElementTypes: ElementTypeDto[];

  public elementFordit: ModelPresentableElement;

  public elementSelected: ModelPresentableElement;

  public get itemIsSelected() {
    if (this.elementSelected) {
      return true;
    }
    return false;
  }

  @Output()
  private elementChanged = new EventEmitter<ModelPresentableElement>(true);

  private _items: ModelPresentableElement[] = null;
  private _sourceId: number = null;
  private _sourceType: ElementTypeDto = ElementTypeDto.Variant;

  @Input()
  public set items(value: ModelPresentableElement[]) {
    this._items = value;
    this.findElementForEdit();
  }
  public get items() {
    return this._items;
  }

  @Input()
  public set sourceId(value: number) {
    this._sourceId = value;
    this.findElementForEdit();
  }
  public get sourceId() {
    return this._sourceId;
  }
  @Input()
  public set sourceType(value: ElementTypeDto) {
    this._sourceType = value;
    this.findElementForEdit();
  }
  public get sourceType() {
    return this._sourceType;
  }

  private findElementForEdit() {
    if (ElementHelper.isNullOrUndefined(this._items) == false && ElementHelper.isNullOrUndefined(this._sourceId) == false) {
      let res = this._items.find((el) => el.id === this.sourceId && el.elementType === this.sourceType);
      if (res) {
        this.elementSelected = res;
      }
    }
  }

  public get selectedVariantName() {
    if (this.elementSelected) {
      return this.elementSelected.displayName;
    }
    return "";
  }

  public clearSelection() {
    this.elementSelected = undefined;
    this.elementChanged.next(undefined);
  }

  public saveVariant() {
    var newSelection = this.elementFordit;
    if (newSelection) {
      this._sourceType = newSelection.elementType;
      this._sourceId = newSelection.id;
      this.elementSelected = newSelection;
      this.elementChanged.next(newSelection);
    }
    this.popupVariantVisible = false;
  }

  public popupVariantVisible = false;

  public popupVariantToggle() {
    if (ElementHelper.isNullOrUndefined(this.elementFordit)) {
      this.elementFordit = this.elementSelected;
    }
    this.popupVariantVisible = true;
  }
}
