<div *ngIf="contentLoaded">
  <div *ngIf="hasDefinitions">
    <ul class="download-list">
      <li *ngFor="let definition of definitions" class="download-item">
        <div class="download-button-area">
          <dx-button icon="download" [text]="language.exportStartDownload" (onClick)="download(definition)"
            [disabled]="definition.source.exportIsNotReady"> </dx-button>
          <div *ngIf="definition.hasDownloadUrl" class="download-link-area-succes">
            <a [attr.href]="definition.downloadUrl" [download]="definition.fileName">{{ definition.fileName }}</a>
          </div>
          <div *ngIf="definition.error" class="download-link-area-error" [title]="language.errorMessage">
            {{ language.exportNothingDownloaded }}
          </div>
        </div>
        <div class="download-hint-area">
          <span *ngIf="definition.source.exportIsReady">{{ language.exportDataIsReady }}</span>
          <span *ngIf="definition.source.exportOutOfDate">{{ language.exportDataIsOutOfDate }}</span>
          <span *ngIf="definition.source.exportIsNotReady" class="download-hint-area-not-ready"
            [title]="language.exportDataIsNotReadyHint">{{ language.exportDataIsNotReady }}</span>
        </div>
        <div class="download-name-area">
          <span>{{ definition.source.longName }}</span>
        </div>
      </li>
    </ul>
    <app-toaster [model]="toasterInput"></app-toaster>
  </div>
  <span *ngIf="!hasDefinitions">
    {{ language.exportHeaderNoDownloads }}
  </span>
</div>