import { DxToastModule } from "devextreme-angular";
import { NgModule } from "@angular/core";
import { ToasterComponent } from "./toaster-component";

@NgModule({
  declarations: [ToasterComponent],
  imports: [DxToastModule],
  providers: [],
  exports: [ToasterComponent],
  schemas: [],
})
export class ToasterModule {}
