import { PlLanguageService } from '../../../../../angular-common';
import { Injectable } from '@angular/core';
import { WebLanguage } from '../../../../../common/language/weblanguage.service'

@Injectable()
//t4Languages": "0.8.2.0
export class ImagineLanguage extends WebLanguage {
  public constructor(plLanguageService: PlLanguageService) {
    super(plLanguageService);
  }

  /**
   * ImagineAppName from ImagineWebAPI.csv (in EN: Insights )
   */
  public key_ImagineAppName = "ImagineAppName";
  /**
   * ImagineAppName from ImagineWebAPI.csv (in EN: Insights )
   */
  public get ImagineAppName(): string { return this.getStringDef(this.key_ImagineAppName, "Insights"); }

  /**
   * applicationTitle from ImagineWebAPI.csv (in EN: Imagine of client )
   */
  public key_applicationTitle = "applicationTitle";
  /**
   * applicationTitle from ImagineWebAPI.csv (in EN: Imagine of client )
   */
  public get applicationTitle(): string { return this.getStringDef(this.key_applicationTitle, "Imagine of client"); }

  /**
   * listMore from ImagineWebAPI.csv (in EN: More... )
   */
  public key_listMore = "listMore";
  /**
   * listMore from ImagineWebAPI.csv (in EN: More... )
   */
  public get listMore(): string { return this.getStringDef(this.key_listMore, "More..."); }

  /**
   * dashboarddesignDashletsTab from ImagineWebAPI.csv (in EN: Dashlets )
   */
  public key_dashboarddesignDashletsTab = "dashboarddesignDashletsTab";
  /**
   * dashboarddesignDashletsTab from ImagineWebAPI.csv (in EN: Dashlets )
   */
  public get dashboarddesignDashletsTab(): string { return this.getStringDef(this.key_dashboarddesignDashletsTab, "Dashlets"); }

  /**
   * dashboarddesignDashboardsTab from ImagineWebAPI.csv (in EN: Dashboards )
   */
  public key_dashboarddesignDashboardsTab = "dashboarddesignDashboardsTab";
  /**
   * dashboarddesignDashboardsTab from ImagineWebAPI.csv (in EN: Dashboards )
   */
  public get dashboarddesignDashboardsTab(): string { return this.getStringDef(this.key_dashboarddesignDashboardsTab, "Dashboards"); }

  /**
   * saveChanges from ImagineWebAPI.csv (in EN: Click to save changes: )
   */
  public key_saveChanges = "saveChanges";
  /**
   * saveChanges from ImagineWebAPI.csv (in EN: Click to save changes: )
   */
  public get saveChanges(): string { return this.getStringDef(this.key_saveChanges, "Click to save changes:"); }

  /**
   * inputName from ImagineWebAPI.csv (in EN: Enter a valid name )
   */
  public key_inputName = "inputName";
  /**
   * inputName from ImagineWebAPI.csv (in EN: Enter a valid name )
   */
  public get inputName(): string { return this.getStringDef(this.key_inputName, "Enter a valid name"); }

  /**
   * nameHeader from ImagineWebAPI.csv (in EN: Name )
   */
  public key_nameHeader = "nameHeader";
  /**
   * nameHeader from ImagineWebAPI.csv (in EN: Name )
   */
  public get nameHeader(): string { return this.getStringDef(this.key_nameHeader, "Name"); }

  /**
   * sortOrderHeader from ImagineWebAPI.csv (in EN: Sort order )
   */
  public key_sortOrderHeader = "sortOrderHeader";
  /**
   * sortOrderHeader from ImagineWebAPI.csv (in EN: Sort order )
   */
  public get sortOrderHeader(): string { return this.getStringDef(this.key_sortOrderHeader, "Sort order"); }

  /**
   * colorHeader from ImagineWebAPI.csv (in EN: Colors )
   */
  public key_colorHeader = "colorHeader";
  /**
   * colorHeader from ImagineWebAPI.csv (in EN: Colors )
   */
  public get colorHeader(): string { return this.getStringDef(this.key_colorHeader, "Colors"); }

  /**
   * typeHeader from ImagineWebAPI.csv (in EN: Type )
   */
  public key_typeHeader = "typeHeader";
  /**
   * typeHeader from ImagineWebAPI.csv (in EN: Type )
   */
  public get typeHeader(): string { return this.getStringDef(this.key_typeHeader, "Type"); }

  /**
   * variantHeader from ImagineWebAPI.csv (in EN: Variant )
   */
  public key_variantHeader = "variantHeader";
  /**
   * variantHeader from ImagineWebAPI.csv (in EN: Variant )
   */
  public get variantHeader(): string { return this.getStringDef(this.key_variantHeader, "Variant"); }

  /**
   * compareToVariantHeader from ImagineWebAPI.csv (in EN: Compare to )
   */
  public key_compareToVariantHeader = "compareToVariantHeader";
  /**
   * compareToVariantHeader from ImagineWebAPI.csv (in EN: Compare to )
   */
  public get compareToVariantHeader(): string { return this.getStringDef(this.key_compareToVariantHeader, "Compare to"); }

  /**
   * statisticHeader from ImagineWebAPI.csv (in EN: Statistic )
   */
  public key_statisticHeader = "statisticHeader";
  /**
   * statisticHeader from ImagineWebAPI.csv (in EN: Statistic )
   */
  public get statisticHeader(): string { return this.getStringDef(this.key_statisticHeader, "Statistic"); }

  /**
   * datasetHeader from ImagineWebAPI.csv (in EN: Dataset )
   */
  public key_datasetHeader = "datasetHeader";
  /**
   * datasetHeader from ImagineWebAPI.csv (in EN: Dataset )
   */
  public get datasetHeader(): string { return this.getStringDef(this.key_datasetHeader, "Dataset"); }

  /**
   * clusterHeader from ImagineWebAPI.csv (in EN: Cluster )
   */
  public key_clusterHeader = "clusterHeader";
  /**
   * clusterHeader from ImagineWebAPI.csv (in EN: Cluster )
   */
  public get clusterHeader(): string { return this.getStringDef(this.key_clusterHeader, "Cluster"); }

  /**
   * availableToHeader from ImagineWebAPI.csv (in EN: Available to )
   */
  public key_availableToHeader = "availableToHeader";
  /**
   * availableToHeader from ImagineWebAPI.csv (in EN: Available to )
   */
  public get availableToHeader(): string { return this.getStringDef(this.key_availableToHeader, "Available to"); }

  /**
   * placeholderNone from ImagineWebAPI.csv (in EN: None )
   */
  public key_placeholderNone = "placeholderNone";
  /**
   * placeholderNone from ImagineWebAPI.csv (in EN: None )
   */
  public get placeholderNone(): string { return this.getStringDef(this.key_placeholderNone, "None"); }

  /**
   * placeholderNoCompare from ImagineWebAPI.csv (in EN: Don't compare )
   */
  public key_placeholderNoCompare = "placeholderNoCompare";
  /**
   * placeholderNoCompare from ImagineWebAPI.csv (in EN: Don't compare )
   */
  public get placeholderNoCompare(): string { return this.getStringDef(this.key_placeholderNoCompare, "Don't compare"); }

  /**
   * placeholderSelectVariant from ImagineWebAPI.csv (in EN: select variant )
   */
  public key_placeholderSelectVariant = "placeholderSelectVariant";
  /**
   * placeholderSelectVariant from ImagineWebAPI.csv (in EN: select variant )
   */
  public get placeholderSelectVariant(): string { return this.getStringDef(this.key_placeholderSelectVariant, "select variant"); }

  /**
   * placeholderAvailableToSelf from ImagineWebAPI.csv (in EN: Private )
   */
  public key_placeholderAvailableToSelf = "placeholderAvailableToSelf";
  /**
   * placeholderAvailableToSelf from ImagineWebAPI.csv (in EN: Private )
   */
  public get placeholderAvailableToSelf(): string { return this.getStringDef(this.key_placeholderAvailableToSelf, "Private"); }

  /**
   * filter from ImagineWebAPI.csv (in EN: Filter )
   */
  public key_filter = "filter";
  /**
   * filter from ImagineWebAPI.csv (in EN: Filter )
   */
  public get filter(): string { return this.getStringDef(this.key_filter, "Filter"); }

  /**
   * role from ImagineWebAPI.csv (in EN: Role )
   */
  public key_role = "role";
  /**
   * role from ImagineWebAPI.csv (in EN: Role )
   */
  public get role(): string { return this.getStringDef(this.key_role, "Role"); }

  /**
   * noDashlets from ImagineWebAPI.csv (in EN: No dashlets )
   */
  public key_noDashlets = "noDashlets";
  /**
   * noDashlets from ImagineWebAPI.csv (in EN: No dashlets )
   */
  public get noDashlets(): string { return this.getStringDef(this.key_noDashlets, "No dashlets"); }

  /**
   * noDashboards from ImagineWebAPI.csv (in EN: No dashboards )
   */
  public key_noDashboards = "noDashboards";
  /**
   * noDashboards from ImagineWebAPI.csv (in EN: No dashboards )
   */
  public get noDashboards(): string { return this.getStringDef(this.key_noDashboards, "No dashboards"); }

  /**
   * noData from ImagineWebAPI.csv (in EN: No data )
   */
  public key_noData = "noData";
  /**
   * noData from ImagineWebAPI.csv (in EN: No data )
   */
  public get noData(): string { return this.getStringDef(this.key_noData, "No data"); }

  /**
   * periodFilterNotFound from ImagineWebAPI.csv (in EN: Period filter not found )
   */
  public key_periodFilterNotFound = "periodFilterNotFound";
  /**
   * periodFilterNotFound from ImagineWebAPI.csv (in EN: Period filter not found )
   */
  public get periodFilterNotFound(): string { return this.getStringDef(this.key_periodFilterNotFound, "Period filter not found"); }

  /**
   * periodFilterOptionsNotFound from ImagineWebAPI.csv (in EN: No period data found )
   */
  public key_periodFilterOptionsNotFound = "periodFilterOptionsNotFound";
  /**
   * periodFilterOptionsNotFound from ImagineWebAPI.csv (in EN: No period data found )
   */
  public get periodFilterOptionsNotFound(): string { return this.getStringDef(this.key_periodFilterOptionsNotFound, "No period data found"); }

  /**
   * periodFilterCurrentPeriod from ImagineWebAPI.csv (in EN: Period(s): )
   */
  public key_periodFilterCurrentPeriod = "periodFilterCurrentPeriod";
  /**
   * periodFilterCurrentPeriod from ImagineWebAPI.csv (in EN: Period(s): )
   */
  public get periodFilterCurrentPeriod(): string { return this.getStringDef(this.key_periodFilterCurrentPeriod, "Period(s):"); }

  /**
   * dashboarddesignSingleValues from ImagineWebAPI.csv (in EN: Indicator values )
   */
  public key_dashboarddesignSingleValues = "dashboarddesignSingleValues";
  /**
   * dashboarddesignSingleValues from ImagineWebAPI.csv (in EN: Indicator values )
   */
  public get dashboarddesignSingleValues(): string { return this.getStringDef(this.key_dashboarddesignSingleValues, "Indicator values"); }

  /**
   * dashboarddesignChartsGrids from ImagineWebAPI.csv (in EN: Charts [and] grids )
   */
  public key_dashboarddesignChartsGrids = "dashboarddesignChartsGrids";
  /**
   * dashboarddesignChartsGrids from ImagineWebAPI.csv (in EN: Charts [and] grids )
   */
  public get dashboarddesignChartsGrids(): string { return this.getStringDef(this.key_dashboarddesignChartsGrids, "Charts & grids"); }

  /**
   * dashboarddesignDetailGrids from ImagineWebAPI.csv (in EN: Detail grids )
   */
  public key_dashboarddesignDetailGrids = "dashboarddesignDetailGrids";
  /**
   * dashboarddesignDetailGrids from ImagineWebAPI.csv (in EN: Detail grids )
   */
  public get dashboarddesignDetailGrids(): string { return this.getStringDef(this.key_dashboarddesignDetailGrids, "Detail grids"); }

  /**
   * dashboarddesignNewDashletName from ImagineWebAPI.csv (in EN: New dashlet )
   */
  public key_dashboarddesignNewDashletName = "dashboarddesignNewDashletName";
  /**
   * dashboarddesignNewDashletName from ImagineWebAPI.csv (in EN: New dashlet )
   */
  public get dashboarddesignNewDashletName(): string { return this.getStringDef(this.key_dashboarddesignNewDashletName, "New dashlet"); }

  /**
   * dashboarddesignAddDashlet from ImagineWebAPI.csv (in EN: Add dashlet )
   */
  public key_dashboarddesignAddDashlet = "dashboarddesignAddDashlet";
  /**
   * dashboarddesignAddDashlet from ImagineWebAPI.csv (in EN: Add dashlet )
   */
  public get dashboarddesignAddDashlet(): string { return this.getStringDef(this.key_dashboarddesignAddDashlet, "Add dashlet"); }

  /**
   * dashboarddesignAddDashboardHeader from ImagineWebAPI.csv (in EN: Add dashboard )
   */
  public key_dashboarddesignAddDashboardHeader = "dashboarddesignAddDashboardHeader";
  /**
   * dashboarddesignAddDashboardHeader from ImagineWebAPI.csv (in EN: Add dashboard )
   */
  public get dashboarddesignAddDashboardHeader(): string { return this.getStringDef(this.key_dashboarddesignAddDashboardHeader, "Add dashboard"); }

  /**
   * dashboarddesignAddDashboardTitle from ImagineWebAPI.csv (in EN: Dashboard name )
   */
  public key_dashboarddesignAddDashboardTitle = "dashboarddesignAddDashboardTitle";
  /**
   * dashboarddesignAddDashboardTitle from ImagineWebAPI.csv (in EN: Dashboard name )
   */
  public get dashboarddesignAddDashboardTitle(): string { return this.getStringDef(this.key_dashboarddesignAddDashboardTitle, "Dashboard name"); }

  /**
   * dashboarddesignEditDashboardHeader from ImagineWebAPI.csv (in EN: Change dashboard )
   */
  public key_dashboarddesignEditDashboardHeader = "dashboarddesignEditDashboardHeader";
  /**
   * dashboarddesignEditDashboardHeader from ImagineWebAPI.csv (in EN: Change dashboard )
   */
  public get dashboarddesignEditDashboardHeader(): string { return this.getStringDef(this.key_dashboarddesignEditDashboardHeader, "Change dashboard"); }

  /**
   * dashboarddesignEditDashboardTitle from ImagineWebAPI.csv (in EN: Dashboard name )
   */
  public key_dashboarddesignEditDashboardTitle = "dashboarddesignEditDashboardTitle";
  /**
   * dashboarddesignEditDashboardTitle from ImagineWebAPI.csv (in EN: Dashboard name )
   */
  public get dashboarddesignEditDashboardTitle(): string { return this.getStringDef(this.key_dashboarddesignEditDashboardTitle, "Dashboard name"); }

  /**
   * dashboarddesignDeleteDashboard from ImagineWebAPI.csv (in EN: Delete dashboard )
   */
  public key_dashboarddesignDeleteDashboard = "dashboarddesignDeleteDashboard";
  /**
   * dashboarddesignDeleteDashboard from ImagineWebAPI.csv (in EN: Delete dashboard )
   */
  public get dashboarddesignDeleteDashboard(): string { return this.getStringDef(this.key_dashboarddesignDeleteDashboard, "Delete dashboard"); }

  /**
   * dashboarddesignNewDashboardName from ImagineWebAPI.csv (in EN: { new } )
   */
  public key_dashboarddesignNewDashboardName = "dashboarddesignNewDashboardName";
  /**
   * dashboarddesignNewDashboardName from ImagineWebAPI.csv (in EN: { new } )
   */
  public get dashboarddesignNewDashboardName(): string { return this.getStringDef(this.key_dashboarddesignNewDashboardName, "< new >"); }

  /**
   * dashletBarContextMenuFilterBy from ImagineWebAPI.csv (in EN: Only filter by ... )
   */
  public key_dashletBarContextMenuFilterBy = "dashletBarContextMenuFilterBy";
  /**
   * dashletBarContextMenuFilterBy from ImagineWebAPI.csv (in EN: Only filter by ... )
   */
  public get dashletBarContextMenuFilterBy(): string { return this.getStringDef(this.key_dashletBarContextMenuFilterBy, "Only filter by ..."); }

  /**
   * dashletBarContextMenuRemoveFilter from ImagineWebAPI.csv (in EN: Remove filter )
   */
  public key_dashletBarContextMenuRemoveFilter = "dashletBarContextMenuRemoveFilter";
  /**
   * dashletBarContextMenuRemoveFilter from ImagineWebAPI.csv (in EN: Remove filter )
   */
  public get dashletBarContextMenuRemoveFilter(): string { return this.getStringDef(this.key_dashletBarContextMenuRemoveFilter, "Remove filter"); }

  /**
   * dashletDefinitionDesignSaveChanges from ImagineWebAPI.csv (in EN: Click to save changes: )
   */
  public key_dashletDefinitionDesignSaveChanges = "dashletDefinitionDesignSaveChanges";
  /**
   * dashletDefinitionDesignSaveChanges from ImagineWebAPI.csv (in EN: Click to save changes: )
   */
  public get dashletDefinitionDesignSaveChanges(): string { return this.getStringDef(this.key_dashletDefinitionDesignSaveChanges, "Click to save changes:"); }

  /**
   * dashletDefinitionDesignSelectType from ImagineWebAPI.csv (in EN: Select a valid type )
   */
  public key_dashletDefinitionDesignSelectType = "dashletDefinitionDesignSelectType";
  /**
   * dashletDefinitionDesignSelectType from ImagineWebAPI.csv (in EN: Select a valid type )
   */
  public get dashletDefinitionDesignSelectType(): string { return this.getStringDef(this.key_dashletDefinitionDesignSelectType, "Select a valid type"); }

  /**
   * dashletDefinitionDesignInputName from ImagineWebAPI.csv (in EN: Enter a valid name )
   */
  public key_dashletDefinitionDesignInputName = "dashletDefinitionDesignInputName";
  /**
   * dashletDefinitionDesignInputName from ImagineWebAPI.csv (in EN: Enter a valid name )
   */
  public get dashletDefinitionDesignInputName(): string { return this.getStringDef(this.key_dashletDefinitionDesignInputName, "Enter a valid name"); }

  /**
   * dashletDefinitionDesignSelectVariant from ImagineWebAPI.csv (in EN: Select a variant )
   */
  public key_dashletDefinitionDesignSelectVariant = "dashletDefinitionDesignSelectVariant";
  /**
   * dashletDefinitionDesignSelectVariant from ImagineWebAPI.csv (in EN: Select a variant )
   */
  public get dashletDefinitionDesignSelectVariant(): string { return this.getStringDef(this.key_dashletDefinitionDesignSelectVariant, "Select a variant"); }

  /**
   * dashletDefinitionDesignSelectDataSetType from ImagineWebAPI.csv (in EN: Select a dataset type )
   */
  public key_dashletDefinitionDesignSelectDataSetType = "dashletDefinitionDesignSelectDataSetType";
  /**
   * dashletDefinitionDesignSelectDataSetType from ImagineWebAPI.csv (in EN: Select a dataset type )
   */
  public get dashletDefinitionDesignSelectDataSetType(): string { return this.getStringDef(this.key_dashletDefinitionDesignSelectDataSetType, "Select a dataset type"); }

  /**
   * dashletDefinitionDesignSelectStatisticAndOperator from ImagineWebAPI.csv (in EN: Select a statistic and operator )
   */
  public key_dashletDefinitionDesignSelectStatisticAndOperator = "dashletDefinitionDesignSelectStatisticAndOperator";
  /**
   * dashletDefinitionDesignSelectStatisticAndOperator from ImagineWebAPI.csv (in EN: Select a statistic and operator )
   */
  public get dashletDefinitionDesignSelectStatisticAndOperator(): string { return this.getStringDef(this.key_dashletDefinitionDesignSelectStatisticAndOperator, "Select a statistic and operator"); }

  /**
   * dashletDefinitionDesignSelectStatistic from ImagineWebAPI.csv (in EN: Select a statistic )
   */
  public key_dashletDefinitionDesignSelectStatistic = "dashletDefinitionDesignSelectStatistic";
  /**
   * dashletDefinitionDesignSelectStatistic from ImagineWebAPI.csv (in EN: Select a statistic )
   */
  public get dashletDefinitionDesignSelectStatistic(): string { return this.getStringDef(this.key_dashletDefinitionDesignSelectStatistic, "Select a statistic"); }

  /**
   * dashletDefinitionDesignAddDashlet from ImagineWebAPI.csv (in EN: Add )
   */
  public key_dashletDefinitionDesignAddDashlet = "dashletDefinitionDesignAddDashlet";
  /**
   * dashletDefinitionDesignAddDashlet from ImagineWebAPI.csv (in EN: Add )
   */
  public get dashletDefinitionDesignAddDashlet(): string { return this.getStringDef(this.key_dashletDefinitionDesignAddDashlet, "Add"); }

  /**
   * dashletDefinitionDesignCopyOf from ImagineWebAPI.csv (in EN: Copy of )
   */
  public key_dashletDefinitionDesignCopyOf = "dashletDefinitionDesignCopyOf";
  /**
   * dashletDefinitionDesignCopyOf from ImagineWebAPI.csv (in EN: Copy of )
   */
  public get dashletDefinitionDesignCopyOf(): string { return this.getStringDef(this.key_dashletDefinitionDesignCopyOf, "Copy of"); }

  /**
   * dashletViewDesignSaveChanges from ImagineWebAPI.csv (in EN: Click to save changes: )
   */
  public key_dashletViewDesignSaveChanges = "dashletViewDesignSaveChanges";
  /**
   * dashletViewDesignSaveChanges from ImagineWebAPI.csv (in EN: Click to save changes: )
   */
  public get dashletViewDesignSaveChanges(): string { return this.getStringDef(this.key_dashletViewDesignSaveChanges, "Click to save changes:"); }

  /**
   * dashletViewDesignMultipuleStatisticWarning from ImagineWebAPI.csv (in EN: Only one statistic will be shown )
   */
  public key_dashletViewDesignMultipuleStatisticWarning = "dashletViewDesignMultipuleStatisticWarning";
  /**
   * dashletViewDesignMultipuleStatisticWarning from ImagineWebAPI.csv (in EN: Only one statistic will be shown )
   */
  public get dashletViewDesignMultipuleStatisticWarning(): string { return this.getStringDef(this.key_dashletViewDesignMultipuleStatisticWarning, "Only one statistic will be shown"); }

  /**
   * dashletViewDesignView from ImagineWebAPI.csv (in EN: View )
   */
  public key_dashletViewDesignView = "dashletViewDesignView";
  /**
   * dashletViewDesignView from ImagineWebAPI.csv (in EN: View )
   */
  public get dashletViewDesignView(): string { return this.getStringDef(this.key_dashletViewDesignView, "View"); }

  /**
   * dashletViewDesignInputName from ImagineWebAPI.csv (in EN: Enter a valid name )
   */
  public key_dashletViewDesignInputName = "dashletViewDesignInputName";
  /**
   * dashletViewDesignInputName from ImagineWebAPI.csv (in EN: Enter a valid name )
   */
  public get dashletViewDesignInputName(): string { return this.getStringDef(this.key_dashletViewDesignInputName, "Enter a valid name"); }

  /**
   * paletteDevexpressPastel from ImagineWebAPI.csv (in EN: Pastel )
   */
  public key_paletteDevexpressPastel = "paletteDevexpressPastel";
  /**
   * paletteDevexpressPastel from ImagineWebAPI.csv (in EN: Pastel )
   */
  public get paletteDevexpressPastel(): string { return this.getStringDef(this.key_paletteDevexpressPastel, "Pastel"); }

  /**
   * paletteDevexpressVintage from ImagineWebAPI.csv (in EN: Vintage )
   */
  public key_paletteDevexpressVintage = "paletteDevexpressVintage";
  /**
   * paletteDevexpressVintage from ImagineWebAPI.csv (in EN: Vintage )
   */
  public get paletteDevexpressVintage(): string { return this.getStringDef(this.key_paletteDevexpressVintage, "Vintage"); }

  /**
   * paletteClient from ImagineWebAPI.csv (in EN: Default )
   */
  public key_paletteClient = "paletteClient";
  /**
   * paletteClient from ImagineWebAPI.csv (in EN: Default )
   */
  public get paletteClient(): string { return this.getStringDef(this.key_paletteClient, "Default"); }

  /**
   * viewTypeBarChart from ImagineWebAPI.csv (in EN: Bar chart )
   */
  public key_viewTypeBarChart = "viewTypeBarChart";
  /**
   * viewTypeBarChart from ImagineWebAPI.csv (in EN: Bar chart )
   */
  public get viewTypeBarChart(): string { return this.getStringDef(this.key_viewTypeBarChart, "Bar chart"); }

  /**
   * viewTypePieChart from ImagineWebAPI.csv (in EN: Pie chart )
   */
  public key_viewTypePieChart = "viewTypePieChart";
  /**
   * viewTypePieChart from ImagineWebAPI.csv (in EN: Pie chart )
   */
  public get viewTypePieChart(): string { return this.getStringDef(this.key_viewTypePieChart, "Pie chart"); }

  /**
   * viewTypeLineChart from ImagineWebAPI.csv (in EN: Line chart )
   */
  public key_viewTypeLineChart = "viewTypeLineChart";
  /**
   * viewTypeLineChart from ImagineWebAPI.csv (in EN: Line chart )
   */
  public get viewTypeLineChart(): string { return this.getStringDef(this.key_viewTypeLineChart, "Line chart"); }

  /**
   * viewTypeSplineChart from ImagineWebAPI.csv (in EN: Spline chart )
   */
  public key_viewTypeSplineChart = "viewTypeSplineChart";
  /**
   * viewTypeSplineChart from ImagineWebAPI.csv (in EN: Spline chart )
   */
  public get viewTypeSplineChart(): string { return this.getStringDef(this.key_viewTypeSplineChart, "Spline chart"); }

  /**
   * viewTypeSteplineChart from ImagineWebAPI.csv (in EN: Stepline chart )
   */
  public key_viewTypeSteplineChart = "viewTypeSteplineChart";
  /**
   * viewTypeSteplineChart from ImagineWebAPI.csv (in EN: Stepline chart )
   */
  public get viewTypeSteplineChart(): string { return this.getStringDef(this.key_viewTypeSteplineChart, "Stepline chart"); }

  /**
   * viewTypeAreaChart from ImagineWebAPI.csv (in EN: Area chart )
   */
  public key_viewTypeAreaChart = "viewTypeAreaChart";
  /**
   * viewTypeAreaChart from ImagineWebAPI.csv (in EN: Area chart )
   */
  public get viewTypeAreaChart(): string { return this.getStringDef(this.key_viewTypeAreaChart, "Area chart"); }

  /**
   * viewTypeLineWithoutMarkersChart from ImagineWebAPI.csv (in EN: Line without markers chart )
   */
  public key_viewTypeLineWithoutMarkersChart = "viewTypeLineWithoutMarkersChart";
  /**
   * viewTypeLineWithoutMarkersChart from ImagineWebAPI.csv (in EN: Line without markers chart )
   */
  public get viewTypeLineWithoutMarkersChart(): string { return this.getStringDef(this.key_viewTypeLineWithoutMarkersChart, "Line without markers chart"); }

  /**
   * viewTypeRotatedBarChart from ImagineWebAPI.csv (in EN: Bar chart (rotated) )
   */
  public key_viewTypeRotatedBarChart = "viewTypeRotatedBarChart";
  /**
   * viewTypeRotatedBarChart from ImagineWebAPI.csv (in EN: Bar chart (rotated) )
   */
  public get viewTypeRotatedBarChart(): string { return this.getStringDef(this.key_viewTypeRotatedBarChart, "Bar chart (rotated)"); }

  /**
   * viewTypeGrid from ImagineWebAPI.csv (in EN: Grid )
   */
  public key_viewTypeGrid = "viewTypeGrid";
  /**
   * viewTypeGrid from ImagineWebAPI.csv (in EN: Grid )
   */
  public get viewTypeGrid(): string { return this.getStringDef(this.key_viewTypeGrid, "Grid"); }

  /**
   * viewTypePivot from ImagineWebAPI.csv (in EN: Pivot grid )
   */
  public key_viewTypePivot = "viewTypePivot";
  /**
   * viewTypePivot from ImagineWebAPI.csv (in EN: Pivot grid )
   */
  public get viewTypePivot(): string { return this.getStringDef(this.key_viewTypePivot, "Pivot grid"); }

  /**
   * dashletCompareVersus from ImagineWebAPI.csv (in EN: vs )
   */
  public key_dashletCompareVersus = "dashletCompareVersus";
  /**
   * dashletCompareVersus from ImagineWebAPI.csv (in EN: vs )
   */
  public get dashletCompareVersus(): string { return this.getStringDef(this.key_dashletCompareVersus, "vs"); }

  /**
   * dashletCompareEqual from ImagineWebAPI.csv (in EN: no difference )
   */
  public key_dashletCompareEqual = "dashletCompareEqual";
  /**
   * dashletCompareEqual from ImagineWebAPI.csv (in EN: no difference )
   */
  public get dashletCompareEqual(): string { return this.getStringDef(this.key_dashletCompareEqual, "no difference"); }

  /**
   * dashletCompareDifference from ImagineWebAPI.csv (in EN: difference )
   */
  public key_dashletCompareDifference = "dashletCompareDifference";
  /**
   * dashletCompareDifference from ImagineWebAPI.csv (in EN: difference )
   */
  public get dashletCompareDifference(): string { return this.getStringDef(this.key_dashletCompareDifference, "difference"); }

  /**
   * dashletCompareDifferenceRelative from ImagineWebAPI.csv (in EN: relative difference )
   */
  public key_dashletCompareDifferenceRelative = "dashletCompareDifferenceRelative";
  /**
   * dashletCompareDifferenceRelative from ImagineWebAPI.csv (in EN: relative difference )
   */
  public get dashletCompareDifferenceRelative(): string { return this.getStringDef(this.key_dashletCompareDifferenceRelative, "relative difference"); }

  /**
   * dashletGridHeaderValueDifference from ImagineWebAPI.csv (in EN: Difference )
   */
  public key_dashletGridHeaderValueDifference = "dashletGridHeaderValueDifference";
  /**
   * dashletGridHeaderValueDifference from ImagineWebAPI.csv (in EN: Difference )
   */
  public get dashletGridHeaderValueDifference(): string { return this.getStringDef(this.key_dashletGridHeaderValueDifference, "Difference"); }

  /**
   * dashletGridHeaderValueRelativeDifference from ImagineWebAPI.csv (in EN: Relative difference )
   */
  public key_dashletGridHeaderValueRelativeDifference = "dashletGridHeaderValueRelativeDifference";
  /**
   * dashletGridHeaderValueRelativeDifference from ImagineWebAPI.csv (in EN: Relative difference )
   */
  public get dashletGridHeaderValueRelativeDifference(): string { return this.getStringDef(this.key_dashletGridHeaderValueRelativeDifference, "Relative difference"); }

  /**
   * dashletGridHeaderName from ImagineWebAPI.csv (in EN: Name )
   */
  public key_dashletGridHeaderName = "dashletGridHeaderName";
  /**
   * dashletGridHeaderName from ImagineWebAPI.csv (in EN: Name )
   */
  public get dashletGridHeaderName(): string { return this.getStringDef(this.key_dashletGridHeaderName, "Name"); }

  /**
   * dashletGridHeaderValue from ImagineWebAPI.csv (in EN: Value )
   */
  public key_dashletGridHeaderValue = "dashletGridHeaderValue";
  /**
   * dashletGridHeaderValue from ImagineWebAPI.csv (in EN: Value )
   */
  public get dashletGridHeaderValue(): string { return this.getStringDef(this.key_dashletGridHeaderValue, "Value"); }

  /**
   * dashletRotateAxisLabels from ImagineWebAPI.csv (in EN: Rotate axis labels )
   */
  public key_dashletRotateAxisLabels = "dashletRotateAxisLabels";
  /**
   * dashletRotateAxisLabels from ImagineWebAPI.csv (in EN: Rotate axis labels )
   */
  public get dashletRotateAxisLabels(): string { return this.getStringDef(this.key_dashletRotateAxisLabels, "Rotate axis labels"); }

  /**
   * dashletShowScrollbar from ImagineWebAPI.csv (in EN: Show scrollbar )
   */
  public key_dashletShowScrollbar = "dashletShowScrollbar";
  /**
   * dashletShowScrollbar from ImagineWebAPI.csv (in EN: Show scrollbar )
   */
  public get dashletShowScrollbar(): string { return this.getStringDef(this.key_dashletShowScrollbar, "Show scrollbar"); }

  /**
   * dashletMaxNumberOfBars from ImagineWebAPI.csv (in EN: Show number of bars: )
   */
  public key_dashletMaxNumberOfBars = "dashletMaxNumberOfBars";
  /**
   * dashletMaxNumberOfBars from ImagineWebAPI.csv (in EN: Show number of bars: )
   */
  public get dashletMaxNumberOfBars(): string { return this.getStringDef(this.key_dashletMaxNumberOfBars, "Show number of bars:"); }

  /**
   * dashletShowPivotChart from ImagineWebAPI.csv (in EN: Show pivot chart )
   */
  public key_dashletShowPivotChart = "dashletShowPivotChart";
  /**
   * dashletShowPivotChart from ImagineWebAPI.csv (in EN: Show pivot chart )
   */
  public get dashletShowPivotChart(): string { return this.getStringDef(this.key_dashletShowPivotChart, "Show pivot chart"); }

  /**
   * dashletPivotHideField from ImagineWebAPI.csv (in EN: Hide field )
   */
  public key_dashletPivotHideField = "dashletPivotHideField";
  /**
   * dashletPivotHideField from ImagineWebAPI.csv (in EN: Hide field )
   */
  public get dashletPivotHideField(): string { return this.getStringDef(this.key_dashletPivotHideField, "Hide field"); }

  /**
   * dashletMissingFunctionalFilterElementsWarning from ImagineWebAPI.csv (in EN: The following filters were not applied: {0} )
   */
  public key_dashletMissingFunctionalFilterElementsWarning = "dashletMissingFunctionalFilterElementsWarning";
  /**
   * dashletMissingFunctionalFilterElementsWarning from ImagineWebAPI.csv (in EN: The following filters were not applied: {0} )
   */
  public dashletMissingFunctionalFilterElementsWarning(val1): string { return this.getStringDef("dashletMissingFunctionalFilterElementsWarning", "The following filters were not applied: {0}").replace("{0}", val1); }

  /**
   * authorizationFilterTitle from ImagineWebAPI.csv (in EN: Data filters )
   */
  public key_authorizationFilterTitle = "authorizationFilterTitle";
  /**
   * authorizationFilterTitle from ImagineWebAPI.csv (in EN: Data filters )
   */
  public get authorizationFilterTitle(): string { return this.getStringDef(this.key_authorizationFilterTitle, "Data filters"); }

  /**
   * authorizationFilterTitleRole from ImagineWebAPI.csv (in EN: Role data filters )
   */
  public key_authorizationFilterTitleRole = "authorizationFilterTitleRole";
  /**
   * authorizationFilterTitleRole from ImagineWebAPI.csv (in EN: Role data filters )
   */
  public get authorizationFilterTitleRole(): string { return this.getStringDef(this.key_authorizationFilterTitleRole, "Role data filters"); }

  /**
   * authorizationFilterTitleTarget from ImagineWebAPI.csv (in EN: Target data filters )
   */
  public key_authorizationFilterTitleTarget = "authorizationFilterTitleTarget";
  /**
   * authorizationFilterTitleTarget from ImagineWebAPI.csv (in EN: Target data filters )
   */
  public get authorizationFilterTitleTarget(): string { return this.getStringDef(this.key_authorizationFilterTitleTarget, "Target data filters"); }

  /**
   * authorizationFilterTitleBaseModel from ImagineWebAPI.csv (in EN: Default data filters )
   */
  public key_authorizationFilterTitleBaseModel = "authorizationFilterTitleBaseModel";
  /**
   * authorizationFilterTitleBaseModel from ImagineWebAPI.csv (in EN: Default data filters )
   */
  public get authorizationFilterTitleBaseModel(): string { return this.getStringDef(this.key_authorizationFilterTitleBaseModel, "Default data filters"); }

  /**
   * authorizationFilterSelectRole from ImagineWebAPI.csv (in EN: Select role )
   */
  public key_authorizationFilterSelectRole = "authorizationFilterSelectRole";
  /**
   * authorizationFilterSelectRole from ImagineWebAPI.csv (in EN: Select role )
   */
  public get authorizationFilterSelectRole(): string { return this.getStringDef(this.key_authorizationFilterSelectRole, "Select role"); }

  /**
   * authorizationFilterSelectTarget from ImagineWebAPI.csv (in EN: Select filter target )
   */
  public key_authorizationFilterSelectTarget = "authorizationFilterSelectTarget";
  /**
   * authorizationFilterSelectTarget from ImagineWebAPI.csv (in EN: Select filter target )
   */
  public get authorizationFilterSelectTarget(): string { return this.getStringDef(this.key_authorizationFilterSelectTarget, "Select filter target"); }

  /**
   * authorizationFilterFilterContentDescription from ImagineWebAPI.csv (in EN: Content )
   */
  public key_authorizationFilterFilterContentDescription = "authorizationFilterFilterContentDescription";
  /**
   * authorizationFilterFilterContentDescription from ImagineWebAPI.csv (in EN: Content )
   */
  public get authorizationFilterFilterContentDescription(): string { return this.getStringDef(this.key_authorizationFilterFilterContentDescription, "Content"); }

  /**
   * authorizationFilterColumnModelElementName from ImagineWebAPI.csv (in EN: Model element )
   */
  public key_authorizationFilterColumnModelElementName = "authorizationFilterColumnModelElementName";
  /**
   * authorizationFilterColumnModelElementName from ImagineWebAPI.csv (in EN: Model element )
   */
  public get authorizationFilterColumnModelElementName(): string { return this.getStringDef(this.key_authorizationFilterColumnModelElementName, "Model element"); }

  /**
   * authorizationFilterColumnComparison from ImagineWebAPI.csv (in EN: Comparison )
   */
  public key_authorizationFilterColumnComparison = "authorizationFilterColumnComparison";
  /**
   * authorizationFilterColumnComparison from ImagineWebAPI.csv (in EN: Comparison )
   */
  public get authorizationFilterColumnComparison(): string { return this.getStringDef(this.key_authorizationFilterColumnComparison, "Comparison"); }

  /**
   * authorizationFilterColumnValue from ImagineWebAPI.csv (in EN: Value )
   */
  public key_authorizationFilterColumnValue = "authorizationFilterColumnValue";
  /**
   * authorizationFilterColumnValue from ImagineWebAPI.csv (in EN: Value )
   */
  public get authorizationFilterColumnValue(): string { return this.getStringDef(this.key_authorizationFilterColumnValue, "Value"); }

  /**
   * authorizationFilterColumnActions from ImagineWebAPI.csv (in EN: Actions )
   */
  public key_authorizationFilterColumnActions = "authorizationFilterColumnActions";
  /**
   * authorizationFilterColumnActions from ImagineWebAPI.csv (in EN: Actions )
   */
  public get authorizationFilterColumnActions(): string { return this.getStringDef(this.key_authorizationFilterColumnActions, "Actions"); }

  /**
   * authorizationFilterFilterEntryName from ImagineWebAPI.csv (in EN: expression )
   */
  public key_authorizationFilterFilterEntryName = "authorizationFilterFilterEntryName";
  /**
   * authorizationFilterFilterEntryName from ImagineWebAPI.csv (in EN: expression )
   */
  public get authorizationFilterFilterEntryName(): string { return this.getStringDef(this.key_authorizationFilterFilterEntryName, "expression"); }

  /**
   * authorizationFilterActionsAdd from ImagineWebAPI.csv (in EN: Add a filter )
   */
  public key_authorizationFilterActionsAdd = "authorizationFilterActionsAdd";
  /**
   * authorizationFilterActionsAdd from ImagineWebAPI.csv (in EN: Add a filter )
   */
  public get authorizationFilterActionsAdd(): string { return this.getStringDef(this.key_authorizationFilterActionsAdd, "Add a filter"); }

  /**
   * authorizationFilterActionsAddVariant from ImagineWebAPI.csv (in EN: Add a variant filter )
   */
  public key_authorizationFilterActionsAddVariant = "authorizationFilterActionsAddVariant";
  /**
   * authorizationFilterActionsAddVariant from ImagineWebAPI.csv (in EN: Add a variant filter )
   */
  public get authorizationFilterActionsAddVariant(): string { return this.getStringDef(this.key_authorizationFilterActionsAddVariant, "Add a variant filter"); }

  /**
   * authorizationFilterActionsAddRole from ImagineWebAPI.csv (in EN: Add a role filter )
   */
  public key_authorizationFilterActionsAddRole = "authorizationFilterActionsAddRole";
  /**
   * authorizationFilterActionsAddRole from ImagineWebAPI.csv (in EN: Add a role filter )
   */
  public get authorizationFilterActionsAddRole(): string { return this.getStringDef(this.key_authorizationFilterActionsAddRole, "Add a role filter"); }

  /**
   * authorizationFilterActionsAddBaseModel from ImagineWebAPI.csv (in EN: Add a default filter )
   */
  public key_authorizationFilterActionsAddBaseModel = "authorizationFilterActionsAddBaseModel";
  /**
   * authorizationFilterActionsAddBaseModel from ImagineWebAPI.csv (in EN: Add a default filter )
   */
  public get authorizationFilterActionsAddBaseModel(): string { return this.getStringDef(this.key_authorizationFilterActionsAddBaseModel, "Add a default filter"); }

  /**
   * authorizationFilterActionsBaseModelWarning from ImagineWebAPI.csv (in EN: More granular filters configured on {0} )
   */
  public key_authorizationFilterActionsBaseModelWarning = "authorizationFilterActionsBaseModelWarning";
  /**
   * authorizationFilterActionsBaseModelWarning from ImagineWebAPI.csv (in EN: More granular filters configured on {0} )
   */
  public authorizationFilterActionsBaseModelWarning(val1): string { return this.getStringDef("authorizationFilterActionsBaseModelWarning", "More granular filters configured on {0}").replace("{0}", val1); }

  /**
   * authorizationFilterActionsCancelAll from ImagineWebAPI.csv (in EN: Discard changes )
   */
  public key_authorizationFilterActionsCancelAll = "authorizationFilterActionsCancelAll";
  /**
   * authorizationFilterActionsCancelAll from ImagineWebAPI.csv (in EN: Discard changes )
   */
  public get authorizationFilterActionsCancelAll(): string { return this.getStringDef(this.key_authorizationFilterActionsCancelAll, "Discard changes"); }

  /**
   * authorizationFilterActionsCancel from ImagineWebAPI.csv (in EN: Cancel )
   */
  public key_authorizationFilterActionsCancel = "authorizationFilterActionsCancel";
  /**
   * authorizationFilterActionsCancel from ImagineWebAPI.csv (in EN: Cancel )
   */
  public get authorizationFilterActionsCancel(): string { return this.getStringDef(this.key_authorizationFilterActionsCancel, "Cancel"); }

  /**
   * authorizationFilterActionsDeleteConfirm from ImagineWebAPI.csv (in EN: Are you sure you want to delete this expression? )
   */
  public key_authorizationFilterActionsDeleteConfirm = "authorizationFilterActionsDeleteConfirm";
  /**
   * authorizationFilterActionsDeleteConfirm from ImagineWebAPI.csv (in EN: Are you sure you want to delete this expression? )
   */
  public get authorizationFilterActionsDeleteConfirm(): string { return this.getStringDef(this.key_authorizationFilterActionsDeleteConfirm, "Are you sure you want to delete this expression?"); }

  /**
   * authorizationFilterActionsDeleteConfirmTitle from ImagineWebAPI.csv (in EN:   )
   */
  public key_authorizationFilterActionsDeleteConfirmTitle = "authorizationFilterActionsDeleteConfirmTitle";
  /**
   * authorizationFilterActionsDeleteConfirmTitle from ImagineWebAPI.csv (in EN:   )
   */
  public get authorizationFilterActionsDeleteConfirmTitle(): string { return this.getStringDef(this.key_authorizationFilterActionsDeleteConfirmTitle, " "); }

  /**
   * authorizationFilterActionsDelete from ImagineWebAPI.csv (in EN: Delete )
   */
  public key_authorizationFilterActionsDelete = "authorizationFilterActionsDelete";
  /**
   * authorizationFilterActionsDelete from ImagineWebAPI.csv (in EN: Delete )
   */
  public get authorizationFilterActionsDelete(): string { return this.getStringDef(this.key_authorizationFilterActionsDelete, "Delete"); }

  /**
   * authorizationFilterActionsEdit from ImagineWebAPI.csv (in EN: Edit )
   */
  public key_authorizationFilterActionsEdit = "authorizationFilterActionsEdit";
  /**
   * authorizationFilterActionsEdit from ImagineWebAPI.csv (in EN: Edit )
   */
  public get authorizationFilterActionsEdit(): string { return this.getStringDef(this.key_authorizationFilterActionsEdit, "Edit"); }

  /**
   * authorizationFilterActionsSaveAll from ImagineWebAPI.csv (in EN: Save changes )
   */
  public key_authorizationFilterActionsSaveAll = "authorizationFilterActionsSaveAll";
  /**
   * authorizationFilterActionsSaveAll from ImagineWebAPI.csv (in EN: Save changes )
   */
  public get authorizationFilterActionsSaveAll(): string { return this.getStringDef(this.key_authorizationFilterActionsSaveAll, "Save changes"); }

  /**
   * authorizationFilterActionsSave from ImagineWebAPI.csv (in EN: Save )
   */
  public key_authorizationFilterActionsSave = "authorizationFilterActionsSave";
  /**
   * authorizationFilterActionsSave from ImagineWebAPI.csv (in EN: Save )
   */
  public get authorizationFilterActionsSave(): string { return this.getStringDef(this.key_authorizationFilterActionsSave, "Save"); }

  /**
   * authorizationFilterActionsUndeleteRow from ImagineWebAPI.csv (in EN: Undelete )
   */
  public key_authorizationFilterActionsUndeleteRow = "authorizationFilterActionsUndeleteRow";
  /**
   * authorizationFilterActionsUndeleteRow from ImagineWebAPI.csv (in EN: Undelete )
   */
  public get authorizationFilterActionsUndeleteRow(): string { return this.getStringDef(this.key_authorizationFilterActionsUndeleteRow, "Undelete"); }

  /**
   * authorizationFilterActionsValidationCancelChanges from ImagineWebAPI.csv (in EN: Cancel changes )
   */
  public key_authorizationFilterActionsValidationCancelChanges = "authorizationFilterActionsValidationCancelChanges";
  /**
   * authorizationFilterActionsValidationCancelChanges from ImagineWebAPI.csv (in EN: Cancel changes )
   */
  public get authorizationFilterActionsValidationCancelChanges(): string { return this.getStringDef(this.key_authorizationFilterActionsValidationCancelChanges, "Cancel changes"); }

  /**
   * authorizationFilterActionsEditNew from ImagineWebAPI.csv (in EN: Add filter )
   */
  public key_authorizationFilterActionsEditNew = "authorizationFilterActionsEditNew";
  /**
   * authorizationFilterActionsEditNew from ImagineWebAPI.csv (in EN: Add filter )
   */
  public get authorizationFilterActionsEditNew(): string { return this.getStringDef(this.key_authorizationFilterActionsEditNew, "Add filter"); }

  /**
   * authorizationFilterActionsEditExisting from ImagineWebAPI.csv (in EN: Edit filter )
   */
  public key_authorizationFilterActionsEditExisting = "authorizationFilterActionsEditExisting";
  /**
   * authorizationFilterActionsEditExisting from ImagineWebAPI.csv (in EN: Edit filter )
   */
  public get authorizationFilterActionsEditExisting(): string { return this.getStringDef(this.key_authorizationFilterActionsEditExisting, "Edit filter"); }

  /**
   * authorizationFilterActionsAddExpression from ImagineWebAPI.csv (in EN: Add expression )
   */
  public key_authorizationFilterActionsAddExpression = "authorizationFilterActionsAddExpression";
  /**
   * authorizationFilterActionsAddExpression from ImagineWebAPI.csv (in EN: Add expression )
   */
  public get authorizationFilterActionsAddExpression(): string { return this.getStringDef(this.key_authorizationFilterActionsAddExpression, "Add expression"); }

  /**
   * authorizationFilterValidateRoleRequired from ImagineWebAPI.csv (in EN: Role is required )
   */
  public key_authorizationFilterValidateRoleRequired = "authorizationFilterValidateRoleRequired";
  /**
   * authorizationFilterValidateRoleRequired from ImagineWebAPI.csv (in EN: Role is required )
   */
  public get authorizationFilterValidateRoleRequired(): string { return this.getStringDef(this.key_authorizationFilterValidateRoleRequired, "Role is required"); }

  /**
   * authorizationFilterValidateTargetRequired from ImagineWebAPI.csv (in EN: Target is required )
   */
  public key_authorizationFilterValidateTargetRequired = "authorizationFilterValidateTargetRequired";
  /**
   * authorizationFilterValidateTargetRequired from ImagineWebAPI.csv (in EN: Target is required )
   */
  public get authorizationFilterValidateTargetRequired(): string { return this.getStringDef(this.key_authorizationFilterValidateTargetRequired, "Target is required"); }

  /**
   * authorizationFilterValidateModelElementRequired from ImagineWebAPI.csv (in EN: Model elment is required )
   */
  public key_authorizationFilterValidateModelElementRequired = "authorizationFilterValidateModelElementRequired";
  /**
   * authorizationFilterValidateModelElementRequired from ImagineWebAPI.csv (in EN: Model elment is required )
   */
  public get authorizationFilterValidateModelElementRequired(): string { return this.getStringDef(this.key_authorizationFilterValidateModelElementRequired, "Model elment is required"); }

  /**
   * authorizationFilterValidateCompareTypeRequired from ImagineWebAPI.csv (in EN: Model elment is required )
   */
  public key_authorizationFilterValidateCompareTypeRequired = "authorizationFilterValidateCompareTypeRequired";
  /**
   * authorizationFilterValidateCompareTypeRequired from ImagineWebAPI.csv (in EN: Model elment is required )
   */
  public get authorizationFilterValidateCompareTypeRequired(): string { return this.getStringDef(this.key_authorizationFilterValidateCompareTypeRequired, "Model elment is required"); }

  /**
   * authorizationFilterValidateCompareValueRequired from ImagineWebAPI.csv (in EN: Model elment is required )
   */
  public key_authorizationFilterValidateCompareValueRequired = "authorizationFilterValidateCompareValueRequired";
  /**
   * authorizationFilterValidateCompareValueRequired from ImagineWebAPI.csv (in EN: Model elment is required )
   */
  public get authorizationFilterValidateCompareValueRequired(): string { return this.getStringDef(this.key_authorizationFilterValidateCompareValueRequired, "Model elment is required"); }

  /**
   * authorizationFilterNotExistingVariantMessage from ImagineWebAPI.csv (in EN: Filters without existing target have been found. Delete filters with incorrect assignement now or close this window. )
   */
  public key_authorizationFilterNotExistingVariantMessage = "authorizationFilterNotExistingVariantMessage";
  /**
   * authorizationFilterNotExistingVariantMessage from ImagineWebAPI.csv (in EN: Filters without existing target have been found. Delete filters with incorrect assignement now or close this window. )
   */
  public get authorizationFilterNotExistingVariantMessage(): string { return this.getStringDef(this.key_authorizationFilterNotExistingVariantMessage, "Filters without existing target have been found. Delete filters with incorrect assignement now or close this window."); }

  /**
   * editVariantSelectVariant from ImagineWebAPI.csv (in EN: Select variant )
   */
  public key_editVariantSelectVariant = "editVariantSelectVariant";
  /**
   * editVariantSelectVariant from ImagineWebAPI.csv (in EN: Select variant )
   */
  public get editVariantSelectVariant(): string { return this.getStringDef(this.key_editVariantSelectVariant, "Select variant"); }

  /**
   * editVariantSelectDataSet from ImagineWebAPI.csv (in EN: Select dataset )
   */
  public key_editVariantSelectDataSet = "editVariantSelectDataSet";
  /**
   * editVariantSelectDataSet from ImagineWebAPI.csv (in EN: Select dataset )
   */
  public get editVariantSelectDataSet(): string { return this.getStringDef(this.key_editVariantSelectDataSet, "Select dataset"); }

  /**
   * menuAuthorizationFilters from ImagineWebAPI.csv (in EN: Authorization filters )
   */
  public key_menuAuthorizationFilters = "menuAuthorizationFilters";
  /**
   * menuAuthorizationFilters from ImagineWebAPI.csv (in EN: Authorization filters )
   */
  public get menuAuthorizationFilters(): string { return this.getStringDef(this.key_menuAuthorizationFilters, "Authorization filters"); }

  /**
   * menuModelEdit from ImagineWebAPI.csv (in EN: Edit Models )
   */
  public key_menuModelEdit = "menuModelEdit";
  /**
   * menuModelEdit from ImagineWebAPI.csv (in EN: Edit Models )
   */
  public get menuModelEdit(): string { return this.getStringDef(this.key_menuModelEdit, "Edit Models"); }

  /**
   * datasetLongname from ImagineWebAPI.csv (in EN: Long name )
   */
  public key_datasetLongname = "datasetLongname";
  /**
   * datasetLongname from ImagineWebAPI.csv (in EN: Long name )
   */
  public get datasetLongname(): string { return this.getStringDef(this.key_datasetLongname, "Long name"); }

  /**
   * datasetTable from ImagineWebAPI.csv (in EN: Physical table )
   */
  public key_datasetTable = "datasetTable";
  /**
   * datasetTable from ImagineWebAPI.csv (in EN: Physical table )
   */
  public get datasetTable(): string { return this.getStringDef(this.key_datasetTable, "Physical table"); }

  /**
   * datasetConnection from ImagineWebAPI.csv (in EN: Connection )
   */
  public key_datasetConnection = "datasetConnection";
  /**
   * datasetConnection from ImagineWebAPI.csv (in EN: Connection )
   */
  public get datasetConnection(): string { return this.getStringDef(this.key_datasetConnection, "Connection"); }

  /**
   * datasetUser from ImagineWebAPI.csv (in EN: User )
   */
  public key_datasetUser = "datasetUser";
  /**
   * datasetUser from ImagineWebAPI.csv (in EN: User )
   */
  public get datasetUser(): string { return this.getStringDef(this.key_datasetUser, "User"); }

  /**
   * datasetDescription from ImagineWebAPI.csv (in EN: Description )
   */
  public key_datasetDescription = "datasetDescription";
  /**
   * datasetDescription from ImagineWebAPI.csv (in EN: Description )
   */
  public get datasetDescription(): string { return this.getStringDef(this.key_datasetDescription, "Description"); }

  /**
   * datasetDatasetCreated from ImagineWebAPI.csv (in EN: Created )
   */
  public key_datasetDatasetCreated = "datasetDatasetCreated";
  /**
   * datasetDatasetCreated from ImagineWebAPI.csv (in EN: Created )
   */
  public get datasetDatasetCreated(): string { return this.getStringDef(this.key_datasetDatasetCreated, "Created"); }

  /**
   * datasetKeyField from ImagineWebAPI.csv (in EN: Keyfield(s) )
   */
  public key_datasetKeyField = "datasetKeyField";
  /**
   * datasetKeyField from ImagineWebAPI.csv (in EN: Keyfield(s) )
   */
  public get datasetKeyField(): string { return this.getStringDef(this.key_datasetKeyField, "Keyfield(s)"); }

  /**
   * parameterSettingsTitle from ImagineWebAPI.csv (in EN: Parameter Settings )
   */
  public key_parameterSettingsTitle = "parameterSettingsTitle";
  /**
   * parameterSettingsTitle from ImagineWebAPI.csv (in EN: Parameter Settings )
   */
  public get parameterSettingsTitle(): string { return this.getStringDef(this.key_parameterSettingsTitle, "Parameter Settings"); }

  /**
   * dataEditVariantTabHeader from ImagineWebAPI.csv (in EN: Variants )
   */
  public key_dataEditVariantTabHeader = "dataEditVariantTabHeader";
  /**
   * dataEditVariantTabHeader from ImagineWebAPI.csv (in EN: Variants )
   */
  public get dataEditVariantTabHeader(): string { return this.getStringDef(this.key_dataEditVariantTabHeader, "Variants"); }

  /**
   * dataEditDatasetTabHeader from ImagineWebAPI.csv (in EN: Datasets )
   */
  public key_dataEditDatasetTabHeader = "dataEditDatasetTabHeader";
  /**
   * dataEditDatasetTabHeader from ImagineWebAPI.csv (in EN: Datasets )
   */
  public get dataEditDatasetTabHeader(): string { return this.getStringDef(this.key_dataEditDatasetTabHeader, "Datasets"); }

  /**
   * newVariantName from ImagineWebAPI.csv (in EN: New Variant )
   */
  public key_newVariantName = "newVariantName";
  /**
   * newVariantName from ImagineWebAPI.csv (in EN: New Variant )
   */
  public get newVariantName(): string { return this.getStringDef(this.key_newVariantName, "New Variant"); }

  /**
   * changeName from ImagineWebAPI.csv (in EN: Change name )
   */
  public key_changeName = "changeName";
  /**
   * changeName from ImagineWebAPI.csv (in EN: Change name )
   */
  public get changeName(): string { return this.getStringDef(this.key_changeName, "Change name"); }

  /**
   * addChild from ImagineWebAPI.csv (in EN: Add Child )
   */
  public key_addChild = "addChild";
  /**
   * addChild from ImagineWebAPI.csv (in EN: Add Child )
   */
  public get addChild(): string { return this.getStringDef(this.key_addChild, "Add Child"); }

  /**
   * dashboardSharing from ImagineWebAPI.csv (in EN: Dashboard sharing )
   */
  public key_dashboardSharing = "dashboardSharing";
  /**
   * dashboardSharing from ImagineWebAPI.csv (in EN: Dashboard sharing )
   */
  public get dashboardSharing(): string { return this.getStringDef(this.key_dashboardSharing, "Dashboard sharing"); }

  /**
   * dashboardOwnerName from ImagineWebAPI.csv (in EN: Owner name: {0} )
   */
  public key_dashboardOwnerName = "dashboardOwnerName";
  /**
   * dashboardOwnerName from ImagineWebAPI.csv (in EN: Owner name: {0} )
   */
  public dashboardOwnerName(val1): string { return this.getStringDef("dashboardOwnerName", "Owner name: {0}").replace("{0}", val1); }

  /**
   * dashboardOwnerNameUndefined from ImagineWebAPI.csv (in EN: Owner not defined )
   */
  public key_dashboardOwnerNameUndefined = "dashboardOwnerNameUndefined";
  /**
   * dashboardOwnerNameUndefined from ImagineWebAPI.csv (in EN: Owner not defined )
   */
  public get dashboardOwnerNameUndefined(): string { return this.getStringDef(this.key_dashboardOwnerNameUndefined, "Owner not defined"); }

  /**
   * dashboardShare from ImagineWebAPI.csv (in EN: Share with... )
   */
  public key_dashboardShare = "dashboardShare";
  /**
   * dashboardShare from ImagineWebAPI.csv (in EN: Share with... )
   */
  public get dashboardShare(): string { return this.getStringDef(this.key_dashboardShare, "Share with..."); }

  /**
   * dashboardSharedWithRoles from ImagineWebAPI.csv (in EN: Shared with: {0} )
   */
  public key_dashboardSharedWithRoles = "dashboardSharedWithRoles";
  /**
   * dashboardSharedWithRoles from ImagineWebAPI.csv (in EN: Shared with: {0} )
   */
  public dashboardSharedWithRoles(val1): string { return this.getStringDef("dashboardSharedWithRoles", "Shared with: {0}").replace("{0}", val1); }

  /**
   * dashboardShareWithRolesPopupTitle from ImagineWebAPI.csv (in EN: Share dashboard with others )
   */
  public key_dashboardShareWithRolesPopupTitle = "dashboardShareWithRolesPopupTitle";
  /**
   * dashboardShareWithRolesPopupTitle from ImagineWebAPI.csv (in EN: Share dashboard with others )
   */
  public get dashboardShareWithRolesPopupTitle(): string { return this.getStringDef(this.key_dashboardShareWithRolesPopupTitle, "Share dashboard with others"); }

  /**
   * dashboardNotShared from ImagineWebAPI.csv (in EN: Dashboard not shared with anyone )
   */
  public key_dashboardNotShared = "dashboardNotShared";
  /**
   * dashboardNotShared from ImagineWebAPI.csv (in EN: Dashboard not shared with anyone )
   */
  public get dashboardNotShared(): string { return this.getStringDef(this.key_dashboardNotShared, "Dashboard not shared with anyone"); }

  /**
   * modelEditElementsWithoutCategory from ImagineWebAPI.csv (in EN: Model elements without category )
   */
  public key_modelEditElementsWithoutCategory = "modelEditElementsWithoutCategory";
  /**
   * modelEditElementsWithoutCategory from ImagineWebAPI.csv (in EN: Model elements without category )
   */
  public get modelEditElementsWithoutCategory(): string { return this.getStringDef(this.key_modelEditElementsWithoutCategory, "Model elements without category"); }

  /**
   * modelEditNoMutations from ImagineWebAPI.csv (in EN: None )
   */
  public key_modelEditNoMutations = "modelEditNoMutations";
  /**
   * modelEditNoMutations from ImagineWebAPI.csv (in EN: None )
   */
  public get modelEditNoMutations(): string { return this.getStringDef(this.key_modelEditNoMutations, "None"); }

  /**
   * mutationComponentEditElement from ImagineWebAPI.csv (in EN: Edit model element )
   */
  public key_mutationComponentEditElement = "mutationComponentEditElement";
  /**
   * mutationComponentEditElement from ImagineWebAPI.csv (in EN: Edit model element )
   */
  public get mutationComponentEditElement(): string { return this.getStringDef(this.key_mutationComponentEditElement, "Edit model element"); }

  /**
   * mutationHeader from ImagineWebAPI.csv (in EN: Mutations )
   */
  public key_mutationHeader = "mutationHeader";
  /**
   * mutationHeader from ImagineWebAPI.csv (in EN: Mutations )
   */
  public get mutationHeader(): string { return this.getStringDef(this.key_mutationHeader, "Mutations"); }

  /**
   * modelEditAddMutation from ImagineWebAPI.csv (in EN: Add mutation )
   */
  public key_modelEditAddMutation = "modelEditAddMutation";
  /**
   * modelEditAddMutation from ImagineWebAPI.csv (in EN: Add mutation )
   */
  public get modelEditAddMutation(): string { return this.getStringDef(this.key_modelEditAddMutation, "Add mutation"); }

  /**
   * placeholderSelectFormulaMutation from ImagineWebAPI.csv (in EN: Click on mutation to edit )
   */
  public key_placeholderSelectFormulaMutation = "placeholderSelectFormulaMutation";
  /**
   * placeholderSelectFormulaMutation from ImagineWebAPI.csv (in EN: Click on mutation to edit )
   */
  public get placeholderSelectFormulaMutation(): string { return this.getStringDef(this.key_placeholderSelectFormulaMutation, "Click on mutation to edit"); }

  /**
   * Cluster_Period from f3Analysis.csv (in EN: Period )
   */
  public key_Cluster_Period = "Cluster_Period";
  /**
   * Cluster_Period from f3Analysis.csv (in EN: Period )
   */
  public get Cluster_Period(): string { return this.getStringDef(this.key_Cluster_Period, "Period"); }

  /**
   * Cluster_Varianten from f3Analysis.csv (in EN: Variants )
   */
  public key_Cluster_Varianten = "Cluster_Varianten";
  /**
   * Cluster_Varianten from f3Analysis.csv (in EN: Variants )
   */
  public get Cluster_Varianten(): string { return this.getStringDef(this.key_Cluster_Varianten, "Variants"); }

  /**
   * DrillDownAsPercentage from f3Analysis.csv (in EN: {0}% )
   */
  public key_DrillDownAsPercentage = "DrillDownAsPercentage";
  /**
   * DrillDownAsPercentage from f3Analysis.csv (in EN: {0}% )
   */
  public DrillDownAsPercentage(val1): string { return this.getStringDef("DrillDownAsPercentage", "{0}%").replace("{0}", val1); }

  /**
   * ModelBoundaryBounded from f3Analysis.csv (in EN: {0} { {1} )
   */
  public key_ModelBoundaryBounded = "ModelBoundaryBounded";
  /**
   * ModelBoundaryBounded from f3Analysis.csv (in EN: {0} { {1} )
   */
  public ModelBoundaryBounded(val1, val2): string { return this.getStringDef("ModelBoundaryBounded", "{0} < {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * ModelBoundaryOnlyUpperbound from f3Analysis.csv (in EN: smaller then {0} )
   */
  public key_ModelBoundaryOnlyUpperbound = "ModelBoundaryOnlyUpperbound";
  /**
   * ModelBoundaryOnlyUpperbound from f3Analysis.csv (in EN: smaller then {0} )
   */
  public ModelBoundaryOnlyUpperbound(val1): string { return this.getStringDef("ModelBoundaryOnlyUpperbound", "smaller then {0}").replace("{0}", val1); }

  /**
   * ModelBoundaryOnlyLowerbound from f3Analysis.csv (in EN: greather then {0} )
   */
  public key_ModelBoundaryOnlyLowerbound = "ModelBoundaryOnlyLowerbound";
  /**
   * ModelBoundaryOnlyLowerbound from f3Analysis.csv (in EN: greather then {0} )
   */
  public ModelBoundaryOnlyLowerbound(val1): string { return this.getStringDef("ModelBoundaryOnlyLowerbound", "greather then {0}").replace("{0}", val1); }

  /**
   * Difference from f3Analysis.csv (in EN: Difference )
   */
  public key_Difference = "Difference";
  /**
   * Difference from f3Analysis.csv (in EN: Difference )
   */
  public get Difference(): string { return this.getStringDef(this.key_Difference, "Difference"); }

  /**
   * RelativeDifference from f3Analysis.csv (in EN: Relative difference )
   */
  public key_RelativeDifference = "RelativeDifference";
  /**
   * RelativeDifference from f3Analysis.csv (in EN: Relative difference )
   */
  public get RelativeDifference(): string { return this.getStringDef(this.key_RelativeDifference, "Relative difference"); }

  /**
   * DifferenceFormat from f3Analysis.csv (in EN: Difference {0} )
   */
  public key_DifferenceFormat = "DifferenceFormat";
  /**
   * DifferenceFormat from f3Analysis.csv (in EN: Difference {0} )
   */
  public DifferenceFormat(val1): string { return this.getStringDef("DifferenceFormat", "Difference {0}").replace("{0}", val1); }

  /**
   * RelativeDifferenceFormat from f3Analysis.csv (in EN: Relative difference {0} )
   */
  public key_RelativeDifferenceFormat = "RelativeDifferenceFormat";
  /**
   * RelativeDifferenceFormat from f3Analysis.csv (in EN: Relative difference {0} )
   */
  public RelativeDifferenceFormat(val1): string { return this.getStringDef("RelativeDifferenceFormat", "Relative difference {0}").replace("{0}", val1); }

  /**
   * ValueOfModelElementInVariant from f3Analysis.csv (in EN: {0} in {1} )
   */
  public key_ValueOfModelElementInVariant = "ValueOfModelElementInVariant";
  /**
   * ValueOfModelElementInVariant from f3Analysis.csv (in EN: {0} in {1} )
   */
  public ValueOfModelElementInVariant(val1, val2): string { return this.getStringDef("ValueOfModelElementInVariant", "{0} in {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * AnalysisValueNull from f3Analysis.csv (in EN: Null value )
   */
  public key_AnalysisValueNull = "AnalysisValueNull";
  /**
   * AnalysisValueNull from f3Analysis.csv (in EN: Null value )
   */
  public get AnalysisValueNull(): string { return this.getStringDef(this.key_AnalysisValueNull, "Null value"); }

  /**
   * ReferenceMissingPrefix from f3Analysis.csv (in EN: Reference is missing for: )
   */
  public key_ReferenceMissingPrefix = "ReferenceMissingPrefix";
  /**
   * ReferenceMissingPrefix from f3Analysis.csv (in EN: Reference is missing for: )
   */
  public get ReferenceMissingPrefix(): string { return this.getStringDef(this.key_ReferenceMissingPrefix, "Reference is missing for:"); }

  /**
   * ReferenceMissing from f3Analysis.csv (in EN: Reference is missing for: '{0}' )
   */
  public key_ReferenceMissing = "ReferenceMissing";
  /**
   * ReferenceMissing from f3Analysis.csv (in EN: Reference is missing for: '{0}' )
   */
  public ReferenceMissing(val1): string { return this.getStringDef("ReferenceMissing", "Reference is missing for: '{0}'").replace("{0}", val1); }

  /**
   * VariantMissingPrefix from f3Analysis.csv (in EN: Variant missing:  )
   */
  public key_VariantMissingPrefix = "VariantMissingPrefix";
  /**
   * VariantMissingPrefix from f3Analysis.csv (in EN: Variant missing:  )
   */
  public get VariantMissingPrefix(): string { return this.getStringDef(this.key_VariantMissingPrefix, "Variant missing: "); }

  /**
   * DataSourceMissingPrefix from f3Analysis.csv (in EN: Data source name missing:  )
   */
  public key_DataSourceMissingPrefix = "DataSourceMissingPrefix";
  /**
   * DataSourceMissingPrefix from f3Analysis.csv (in EN: Data source name missing:  )
   */
  public get DataSourceMissingPrefix(): string { return this.getStringDef(this.key_DataSourceMissingPrefix, "Data source name missing: "); }

  /**
   * ModelBoundaryUnbounded from f3Analysis.csv (in EN: Unbounded )
   */
  public key_ModelBoundaryUnbounded = "ModelBoundaryUnbounded";
  /**
   * ModelBoundaryUnbounded from f3Analysis.csv (in EN: Unbounded )
   */
  public get ModelBoundaryUnbounded(): string { return this.getStringDef(this.key_ModelBoundaryUnbounded, "Unbounded"); }

  /**
   * VariantDetailCompareWithName from f3Analysis.csv (in EN: Compare with start situation )
   */
  public key_VariantDetailCompareWithName = "VariantDetailCompareWithName";
  /**
   * VariantDetailCompareWithName from f3Analysis.csv (in EN: Compare with start situation )
   */
  public get VariantDetailCompareWithName(): string { return this.getStringDef(this.key_VariantDetailCompareWithName, "Compare with start situation"); }

  /**
   * FormulaResultOperatorWithFormulaName from f3Analysis.csv (in EN: {0} {1} )
   */
  public key_FormulaResultOperatorWithFormulaName = "FormulaResultOperatorWithFormulaName";
  /**
   * FormulaResultOperatorWithFormulaName from f3Analysis.csv (in EN: {0} {1} )
   */
  public FormulaResultOperatorWithFormulaName(val1, val2): string { return this.getStringDef("FormulaResultOperatorWithFormulaName", "{0} {1}").replace("{1}", val2).replace("{0}", val1); }

  /**
   * ClusterNotFound from f3Analysis.csv (in EN: Cluster {0} was not found )
   */
  public key_ClusterNotFound = "ClusterNotFound";
  /**
   * ClusterNotFound from f3Analysis.csv (in EN: Cluster {0} was not found )
   */
  public ClusterNotFound(val1): string { return this.getStringDef("ClusterNotFound", "Cluster {0} was not found").replace("{0}", val1); }

  /**
   * ClusterNotFoundInAllData from f3Analysis.csv (in EN: Cluster {0} is not present in the data, this can occur when the data being analyzed does not (all) contain the cluster(s). )
   */
  public key_ClusterNotFoundInAllData = "ClusterNotFoundInAllData";
  /**
   * ClusterNotFoundInAllData from f3Analysis.csv (in EN: Cluster {0} is not present in the data, this can occur when the data being analyzed does not (all) contain the cluster(s). )
   */
  public ClusterNotFoundInAllData(val1): string { return this.getStringDef("ClusterNotFoundInAllData", "Cluster {0} is not present in the data, this can occur when the data being analyzed does not (all) contain the cluster(s).").replace("{0}", val1); }

  /**
   * DashletTypeUnknown from f3Analysis.csv (in EN: Unknown )
   */
  public key_DashletTypeUnknown = "DashletTypeUnknown";
  /**
   * DashletTypeUnknown from f3Analysis.csv (in EN: Unknown )
   */
  public get DashletTypeUnknown(): string { return this.getStringDef(this.key_DashletTypeUnknown, "Unknown"); }

  /**
   * DashletTypeDetail from f3Analysis.csv (in EN: Detail )
   */
  public key_DashletTypeDetail = "DashletTypeDetail";
  /**
   * DashletTypeDetail from f3Analysis.csv (in EN: Detail )
   */
  public get DashletTypeDetail(): string { return this.getStringDef(this.key_DashletTypeDetail, "Detail"); }

  /**
   * DashletTypeStatistic from f3Analysis.csv (in EN: Statistic )
   */
  public key_DashletTypeStatistic = "DashletTypeStatistic";
  /**
   * DashletTypeStatistic from f3Analysis.csv (in EN: Statistic )
   */
  public get DashletTypeStatistic(): string { return this.getStringDef(this.key_DashletTypeStatistic, "Statistic"); }

  /**
   * DashletTypeChart from f3Analysis.csv (in EN: Chart )
   */
  public key_DashletTypeChart = "DashletTypeChart";
  /**
   * DashletTypeChart from f3Analysis.csv (in EN: Chart )
   */
  public get DashletTypeChart(): string { return this.getStringDef(this.key_DashletTypeChart, "Chart"); }

  /**
   * DashletTypeNotAvailableInCorporate from f3Analysis.csv (in EN: Dashlet type {0} is not available in corporate )
   */
  public key_DashletTypeNotAvailableInCorporate = "DashletTypeNotAvailableInCorporate";
  /**
   * DashletTypeNotAvailableInCorporate from f3Analysis.csv (in EN: Dashlet type {0} is not available in corporate )
   */
  public DashletTypeNotAvailableInCorporate(val1): string { return this.getStringDef("DashletTypeNotAvailableInCorporate", "Dashlet type {0} is not available in corporate").replace("{0}", val1); }

  /**
   * DashletInvalidNoStatistics from f3Analysis.csv (in EN: No statistics selected )
   */
  public key_DashletInvalidNoStatistics = "DashletInvalidNoStatistics";
  /**
   * DashletInvalidNoStatistics from f3Analysis.csv (in EN: No statistics selected )
   */
  public get DashletInvalidNoStatistics(): string { return this.getStringDef(this.key_DashletInvalidNoStatistics, "No statistics selected"); }

  /**
   * dashboardPivotDrillDown from f3Analysis.csv (in EN: Drill down data of: {0} )
   */
  public key_dashboardPivotDrillDown = "dashboardPivotDrillDown";
  /**
   * dashboardPivotDrillDown from f3Analysis.csv (in EN: Drill down data of: {0} )
   */
  public dashboardPivotDrillDown(val1): string { return this.getStringDef("dashboardPivotDrillDown", "Drill down data of: {0}").replace("{0}", val1); }

  /**
   * dashboardPivotShowRowTotals from f3Analysis.csv (in EN: Show row totals; )
   */
  public key_dashboardPivotShowRowTotals = "dashboardPivotShowRowTotals";
  /**
   * dashboardPivotShowRowTotals from f3Analysis.csv (in EN: Show row totals; )
   */
  public get dashboardPivotShowRowTotals(): string { return this.getStringDef(this.key_dashboardPivotShowRowTotals, "Show row totals;"); }

  /**
   * dashboardPivotShowColumnTotals from f3Analysis.csv (in EN: Show column totals; )
   */
  public key_dashboardPivotShowColumnTotals = "dashboardPivotShowColumnTotals";
  /**
   * dashboardPivotShowColumnTotals from f3Analysis.csv (in EN: Show column totals; )
   */
  public get dashboardPivotShowColumnTotals(): string { return this.getStringDef(this.key_dashboardPivotShowColumnTotals, "Show column totals;"); }

  /**
   * dashboardPivotShowDataFieldHeaders from f3Analysis.csv (in EN: Data field headers in rows )
   */
  public key_dashboardPivotShowDataFieldHeaders = "dashboardPivotShowDataFieldHeaders";
  /**
   * dashboardPivotShowDataFieldHeaders from f3Analysis.csv (in EN: Data field headers in rows )
   */
  public get dashboardPivotShowDataFieldHeaders(): string { return this.getStringDef(this.key_dashboardPivotShowDataFieldHeaders, "Data field headers in rows"); }

  /**
   * menuDashboard from f3Analysis.csv (in EN: Dashboard )
   */
  public key_menuDashboard = "menuDashboard";
  /**
   * menuDashboard from f3Analysis.csv (in EN: Dashboard )
   */
  public get menuDashboard(): string { return this.getStringDef(this.key_menuDashboard, "Dashboard"); }

  /**
   * menuDashboardManagement from f3Analysis.csv (in EN: Dashboard management )
   */
  public key_menuDashboardManagement = "menuDashboardManagement";
  /**
   * menuDashboardManagement from f3Analysis.csv (in EN: Dashboard management )
   */
  public get menuDashboardManagement(): string { return this.getStringDef(this.key_menuDashboardManagement, "Dashboard management"); }

  /**
   * Role_Analysis_longname from f3Analysis.csv (in EN: Data analysis )
   */
  public key_Role_Analysis_longname = "Role_Analysis_longname";
  /**
   * Role_Analysis_longname from f3Analysis.csv (in EN: Data analysis )
   */
  public get Role_Analysis_longname(): string { return this.getStringDef(this.key_Role_Analysis_longname, "Data analysis"); }

  /**
   * Role_DashboardDesign_longname from f3Analysis.csv (in EN: Dashboard design )
   */
  public key_Role_DashboardDesign_longname = "Role_DashboardDesign_longname";
  /**
   * Role_DashboardDesign_longname from f3Analysis.csv (in EN: Dashboard design )
   */
  public get Role_DashboardDesign_longname(): string { return this.getStringDef(this.key_Role_DashboardDesign_longname, "Dashboard design"); }

  /**
   * Role_Data_longname from f3Analysis.csv (in EN: Data access )
   */
  public key_Role_Data_longname = "Role_Data_longname";
  /**
   * Role_Data_longname from f3Analysis.csv (in EN: Data access )
   */
  public get Role_Data_longname(): string { return this.getStringDef(this.key_Role_Data_longname, "Data access"); }

  /**
   * menuEditClusterTitle from f3Analysis.csv (in EN: Edit analysis fields )
   */
  public key_menuEditClusterTitle = "menuEditClusterTitle";
  /**
   * menuEditClusterTitle from f3Analysis.csv (in EN: Edit analysis fields )
   */
  public get menuEditClusterTitle(): string { return this.getStringDef(this.key_menuEditClusterTitle, "Edit analysis fields"); }

  /**
   * clusteringShowAll from f3Analysis.csv (in EN: Show all clusters )
   */
  public key_clusteringShowAll = "clusteringShowAll";
  /**
   * clusteringShowAll from f3Analysis.csv (in EN: Show all clusters )
   */
  public get clusteringShowAll(): string { return this.getStringDef(this.key_clusteringShowAll, "Show all clusters"); }

  /**
   * PivotAggregationType from f3Analysis.csv (in EN: Aggregation type )
   */
  public key_PivotAggregationType = "PivotAggregationType";
  /**
   * PivotAggregationType from f3Analysis.csv (in EN: Aggregation type )
   */
  public get PivotAggregationType(): string { return this.getStringDef(this.key_PivotAggregationType, "Aggregation type"); }

  /**
   * PivotAggregationTypeHeader from f3Analysis.csv (in EN: Aggregation type )
   */
  public key_PivotAggregationTypeHeader = "PivotAggregationTypeHeader";
  /**
   * PivotAggregationTypeHeader from f3Analysis.csv (in EN: Aggregation type )
   */
  public get PivotAggregationTypeHeader(): string { return this.getStringDef(this.key_PivotAggregationTypeHeader, "Aggregation type"); }

  /**
   * PivotAggregationTypeSum from f3Analysis.csv (in EN: Sum )
   */
  public key_PivotAggregationTypeSum = "PivotAggregationTypeSum";
  /**
   * PivotAggregationTypeSum from f3Analysis.csv (in EN: Sum )
   */
  public get PivotAggregationTypeSum(): string { return this.getStringDef(this.key_PivotAggregationTypeSum, "Sum"); }

  /**
   * PivotAggregationTypeAverage from f3Analysis.csv (in EN: Average )
   */
  public key_PivotAggregationTypeAverage = "PivotAggregationTypeAverage";
  /**
   * PivotAggregationTypeAverage from f3Analysis.csv (in EN: Average )
   */
  public get PivotAggregationTypeAverage(): string { return this.getStringDef(this.key_PivotAggregationTypeAverage, "Average"); }

  /**
   * PivotAggregationTypeMinimum from f3Analysis.csv (in EN: Minimum )
   */
  public key_PivotAggregationTypeMinimum = "PivotAggregationTypeMinimum";
  /**
   * PivotAggregationTypeMinimum from f3Analysis.csv (in EN: Minimum )
   */
  public get PivotAggregationTypeMinimum(): string { return this.getStringDef(this.key_PivotAggregationTypeMinimum, "Minimum"); }

  /**
   * PivotAggregationTypeMaximum from f3Analysis.csv (in EN: Maximum )
   */
  public key_PivotAggregationTypeMaximum = "PivotAggregationTypeMaximum";
  /**
   * PivotAggregationTypeMaximum from f3Analysis.csv (in EN: Maximum )
   */
  public get PivotAggregationTypeMaximum(): string { return this.getStringDef(this.key_PivotAggregationTypeMaximum, "Maximum"); }


}
