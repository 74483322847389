import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { DevExpressExcelExport } from "../../../../../angular-common/devextreme/dev-express-export";
import { DashletDetailDataColumnDto } from "../../analysis/dto/DashletDetailDataColumn-dto";
import { DataPointValueDto } from "../../analysis/dto/DataPointValue-dto";
import { DashletWithData } from "../../services/dashletdata/models/dashlet-data.model";
import { DashletView } from "../../services/dashletview";
import { ImagineLanguage } from "../../services/language/imaginelanguage.service";
import { DashletValueFormatting } from "./dashlet-value-formatting.component";
import { DataRowValues } from "./datarow-values";
import { IDxGridComponent } from "./i-dx-grid-component";
import { IDxHeaderColumn } from "./i-dx-header-column";
import { IOnInitialized } from "./i-on-initialized";

@Component({
  selector: "app-dashlet-details",
  templateUrl: "./dashlet-details.component.html",
})
export class DashletDetailsComponent implements OnChanges {
  public rowData: DataRowValues[];

  public constructor(public language: ImagineLanguage) {
    this.rowData = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadData();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: this.dashletView.name,
    });
  }

  @Input()
  dashletView: DashletView;
  @Input()
  dashletWithData: DashletWithData;

  public get hasData() {
    if (this.dashletWithData && this.dashletWithData.rows && this.dashletWithData.rows.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private loadData() {
    if (this.dashletWithData.rows) {
      this.rowData = this.dashletWithData.rows.map((l) => new DataRowValues(l, this.dashletWithData.columns));
    }
  }

  onInitialized(e: IOnInitialized) {
    if (this.dashletWithData) {
      this.addColumnToGridForeachColumnInData(this.dashletWithData.columns, e.component);
    }
  }

  hideDummyColumn(columns: IDxHeaderColumn[]) {
    columns.forEach((columnToCheck) => {
      if (columnToCheck.dataField === "ThisColumnIsOnlyToForceGeneration") {
        columnToCheck.visible = false;
      }
    });
  }

  private addColumnToGridForeachColumnInData(columns: DashletDetailDataColumnDto[], grid: IDxGridComponent) {
    if (columns === undefined || grid === undefined) {
      return;
    }

    const currentColumns = grid.getVisibleColumns();

    // Do not create a new array but set to 0 otherwise we get a Angular error since this method is called
    // from the onInitialize method.
    this._gridTotalColumns.length = 0;

    for (let ci = 0; ci < columns.length; ci++) {
      const columnToAdd = columns[ci];
      if (currentColumns.filter((v) => v.dataField === columnToAdd.ShortName).length === 0) {
        const columnType = DashletValueFormatting.retrieveDataType(columnToAdd.DisplayFormat);
        const columnValue = (r) => this.selectValueForCell(ci, r, columnType);
        const columnFormat = DashletValueFormatting.retrieveFormatByDto(columnToAdd.DisplayFormat);
        const newColumn: IDxHeaderColumn = {
          caption: columnToAdd.Name,
          dataField: columnToAdd.Name,
          allowSearch: true,
          name: "column" + ci,
          calculateCellValue: (r) => DashletValueFormatting.retrieveConvertedValue(columnValue(r), columnType),
          dataIndex: ci,
          dataType: columnType,
          format: columnFormat,
        } as any;

        if (columnToAdd.DisplayFormat && columnToAdd.DisplayFormat !== null && DashletValueFormatting.canCalculateTotals(columnToAdd.DisplayFormat)) {
          this._gridTotalColumns.push({
            column: newColumn.name,
            summaryType: "sum",
            valueFormat: newColumn.format,
            displayFormat: "{0}",
          });
        }

        grid.addColumn(newColumn);
      }
    }
  }

  public get gridTotalColumns(): any {
    return this._gridTotalColumns;
  }
  private _gridTotalColumns: any = [];

  public configureExport(options: any) {
    if (options.gridCell.rowType === "data") {
      const column = options.gridCell.column;

      if (column === undefined) {
        return;
      }

      const cell: DataPointValueDto = options.gridCell.data.row.Values[column.dataIndex];

      if (cell === undefined) {
        return;
      }

      options.numberFormat = column.format;
      options.value = DashletValueFormatting.retrieveColumnValueByType(column.dataType, cell);
    }
  }

  private selectValueForCell(columnIndex: number, rowData: DataRowValues, columnType: string) {
    if (rowData && rowData.row && rowData.row.Values) {
      const valueForCell = rowData.row.Values[columnIndex];
      if (valueForCell) {
        return DashletValueFormatting.retrieveColumnValueByType(columnType, valueForCell);
      }
    }
    return "";
  }

  public onExporting(e: any) {
    DevExpressExcelExport.onDataGridExporting(e, this.dashletView.name);
  }
}
