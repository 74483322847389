import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxButtonModule, DxPopupModule, DxSelectBoxModule } from "devextreme-angular";
import { PlPeriodPickerComponent } from "./pl-periodpicker.component";

@NgModule({
  imports: [CommonModule, DxButtonModule, DxPopupModule, DxSelectBoxModule],
  exports: [PlPeriodPickerComponent],
  declarations: [PlPeriodPickerComponent],
  providers: [],
})
export class PlPeriodPickerModule {}
