import { DxButtonModule, DxDropDownBoxModule, DxFormModule, DxPopupModule, DxSelectBoxModule, DxTemplateModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RadioGroupModule } from "../../../../../../angular-common/components/radiogroup";
import { SelectBoxModule } from "../../../../../../angular-common/components/selectbox/selectbox.module";
import { FilterServiceModule } from "../../../services/filter";
import { FilterModule } from "../../../shared/filter/filter.module";
import { DashletModule } from "../../dashlet/dashlet.module";
import { DashletEditComponent } from "./dashlet-edit.component";

@NgModule({
  imports: [
    CommonModule,
    FilterModule,
    FilterServiceModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxTemplateModule,
    DxFormModule,
    DxSelectBoxModule,
    RadioGroupModule,
    DxPopupModule,
    DashletModule,
    SelectBoxModule,
  ],
  declarations: [DashletEditComponent],
  exports: [DashletEditComponent],
})
export class DashletViewEditModule {}
