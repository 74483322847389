<dx-button icon="fas fa-plus" type="default" [hint]="language.dashboarddesignAddDashlet"
    [text]="language.dashboarddesignAddDashlet" (onClick)="togglePopup($event)"></dx-button>

<dx-popup height="auto" maxWidth="400" [showTitle]="true" [title]="language.dashboarddesignAddDashboardHeader"
    [dragEnabled]="true" [hideOnOutsideClick]="true" [(visible)]="popupVisible" [position]="popupPosition">
    <div *dxTemplate="let data of 'content'">
        <div class="widget-container">
            <app-dashlet-definition-design-list [listHeight]="300" [list]="list"
                (selectionChanged)="onSelectionChanged($event)" [showButtons]="'false'">
            </app-dashlet-definition-design-list>

            <div class="button-container align-right">
                <dx-button type="default" [text]="language.ButtonOk" class="align-right" (onClick)="addDashlets()">
                </dx-button>
            </div>
        </div>
    </div>
</dx-popup>