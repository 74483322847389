<div class="popupcontainer">
  <dx-popup [width]="800" [height]="400" [showTitle]="true" [title]="getTitle()" [dragEnabled]="true"
    [hideOnOutsideClick]="false" [(visible)]="interaction.isVisible">
    <div *dxTemplate="let data of 'content'">
      <dx-select-box [dataSource]="interaction.availableDates" [(value)]="interaction.selectedPeriod"
        displayExpr="YearPeriodText"></dx-select-box>
      <div class="button-container bottom-container align-right">
        <dx-button [text]="language.ButtonOk" (click)="interaction.confirmDate(true)"></dx-button>
        <dx-button [text]="language.ButtonCancel" (click)="interaction.confirmDate(false)"></dx-button>
      </div>
    </div>
  </dx-popup>
</div>